import React, { Component, createRef } from "react";
import { Grid, withStyles, WithStyles, IconButton } from "@material-ui/core";
import { Publish, Filter, RotateLeft } from "@material-ui/icons";
import ImageLibrary from "../dialogs/ImageLibrary";
import ILibraryImage from "../../interfaces/ILibraryImage";

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

const styles = ({
    root: {
        marginBottom: 25
    },
    preview: {
        border: "1px dashed #ceb8b3",
        width: 165,
        height: 195,
        backgroundColor: "#f2f2f2",
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        overflow: "hidden"
    },
    button: {
        color: "#FFFFFF",
        backgroundColor: "#007D7A",
        padding: 6,
        marginRight: 5,
        pointerEvents: "none" as "none"
    },
    label: {
        color: "#007D7A",
        cursor: "pointer",
        marginBottom: 10,
        display: "block",
        "& input": {
            display: "none"
        },
        "&:hover button": {
            backgroundColor: "#FFF",
            border: "1px solid #007D7A",
            color: "#007D7A",
            padding: 5
        }
    },
    formats: {
        marginLeft: 40,
        fontSize: 12
    }
});

interface IImageUpload {
    name?: string,
    imageKey?: string;
    dataMapping: any,
    fileUploaded: (formData: FormData) => void,
    modalState?: any,
    imageSelected: (image: ILibraryImage) => void,
    clearImage?: () => void,
    hideImageLibrary?: boolean
    width?: number;
    height?: number;
}

interface ImageUploadState {
    previewImage?: any
    imageLibraryOpen: boolean
}

class ImageUpload extends Component<IImageUpload & WithStyles, ImageUploadState> {
    constructor(props: any) {
        super(props);

        this.state = {
            previewImage: undefined,
            imageLibraryOpen: false
        }
    }

    handleUpload = (ev: any) => {
        //Generate preview image before uploading
        var reader = new FileReader();

        var component = this;
        reader.onload = function (e) {
            if (e.loaded) {
                var modalState = component.props.modalState;
                if (modalState) {
                    const newState = modalState.editableState;
                    newState[`${component.props.name ?? "file"}preview`] = e?.target?.result;
                    modalState.onSetState({ editableState: newState });
                } else
                    component.setState({ previewImage: e?.target?.result });
            }
        }

        //Only upload if there's files
        if (ev.target.files.length > 0) {
            reader.readAsDataURL(ev.target.files[0]);

            //Pass on
            var formData = new FormData();
            formData.append("file", ev.target.files[0]);
            this.props.fileUploaded(formData);

            //Reset input
            ev.target.value = '';
        }
    }

    openImageLibrary = () => {
        var modalState = this.props.modalState;
        if (modalState)
            modalState.onSetState({ editableState: { ...modalState.editableState, imageLibraryOpen: true } });
        else
            this.setState({ imageLibraryOpen: true });
    }

    closeImageLibrary = () => {
        var modalState = this.props.modalState;
        if (modalState)
            modalState.onSetState({ editableState: { ...modalState.editableState, imageLibraryOpen: false } });
        else
            this.setState({ imageLibraryOpen: false });
    }

    resetImage = () => {
        //Reset preview image
        var modalState = this.props.modalState;

        if (modalState) {
            const newState = modalState.editableState;
            newState[`${this.props.name ?? "file"}preview`] = undefined;
            modalState.onSetState({ editableState: newState });
        } else
            this.setState({ previewImage: undefined });

        //Clear image in data
        this.props?.clearImage();
    }

    onReady = (ev: any) => {
        const imageElement: any = ev.target;
        const cropper: any = imageElement?.cropper;
        const contData = cropper.getContainerData();

        cropper.setCropBoxData({
            left: 5,
            top: 5,
            width: contData.width,
            height: contData.height
        });
    }

    onCrop = (ev: any) => {
        const imageElement: any = ev.target;
        const cropper: any = imageElement?.cropper;

        const previewImage = this.props.modalState ? this.props.modalState.editableState[`${this.props.name ?? "file"}preview`] : this.state.previewImage;
        const hasImage = previewImage || this.props.dataMapping[this.props.imageKey ?? "image"];

        if (cropper && hasImage) {
            cropper.getCroppedCanvas({
                maxWidth: 2000,
                maxHeight: 2000
            }).toBlob((blob: Blob) => {
                if (blob) {
                    var formData = new FormData();
                    //server needs an extension, cropper outputs png
                    formData.append("file", blob, "blob.png");
                    this.props.fileUploaded(formData);
                }
            });
        }
    }

    render() {
        const { imageKey, name, classes, dataMapping, modalState, imageSelected, hideImageLibrary, width, height } = this.props;
        const { imageLibraryOpen } = modalState ? modalState.editableState : this.state;
        const previewImage = modalState ? modalState.editableState[`${name ?? "file"}preview`] : this.state.previewImage;

        const hasImage = previewImage || dataMapping[imageKey ?? "image"];

        return <Grid container spacing={2} className={classes.root}>
            <Grid item xs={"auto"}>
                {width && height ? <>
                    <Cropper
                        src={previewImage ?? dataMapping[imageKey ?? "image"] ?? "#"}
                        //Don't go out of bounds of the modal
                        style={{ height: Math.min(710 / (width / height), height * 2), width: Math.min(705, width * 2) }}
                        className={classes.preview}
                        initialAspectRatio={width / height}
                        guides={false}
                        crop={this.onCrop}
                        ready={this.onReady}
                        viewMode={0}
                    />
                </>: <div className={classes.preview} style={{ backgroundImage: hasImage ? `url(${previewImage ?? dataMapping[imageKey ?? "image"]})` : undefined }} />}

            </Grid>

            <Grid item xs={4}>
                <Grid container>
                    {!hideImageLibrary && <Grid item xs={12}>
                        <label className={classes.label} onClick={this.openImageLibrary}>
                            <IconButton className={classes.button}>
                                <Filter />
                            </IconButton>

                            Browse our image library
                        </label>
                    </Grid>}

                    <Grid item xs={12}>
                        <label htmlFor={`${name}fileUpload`} className={classes.label}>
                            <IconButton className={classes.button}>
                                <Publish />
                            </IconButton>

                            Upload own image
                            <input id={`${name}fileUpload`} name={name ?? "file"} type="file" onChange={this.handleUpload} />
                        </label>

                        <ImageLibrary
                            show={imageLibraryOpen as boolean}
                            onSelect={(img: ILibraryImage) => {
                                //Set as preview and pass on
                                this.setState({ previewImage: img.filePath });
                                imageSelected(img)
                            }}
                            onClose={this.closeImageLibrary} />
                    </Grid>

                    {hasImage && this.props.clearImage && <Grid item xs={12}>
                        <label className={classes.label} onClick={this.resetImage}>
                            <IconButton className={classes.button}>
                                <RotateLeft />
                            </IconButton>

                            Reset uploaded image
                        </label>
                    </Grid>}

                    <Grid item xs={12}>
                        <div className={classes.formats}>
                            File formats: JPG or PNG<br/>
                            Maximum file size: 5Mb
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    }
}

export default withStyles(styles)(ImageUpload);
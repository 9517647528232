import React from "react"
import { Paper, Grid } from "@material-ui/core"
import Components from "./";
import WithCustomTheme from "../../interfaces/WithCustomTheme";
import Droppable from "../decorators/Droppable";
import Draggable from "../decorators/Draggable";

import { connect } from "react-redux";
import { IMenuItem, menuActions } from "../../store/menu";
import { Dispatch } from "redux";

interface IContainerProps {
    items: IMenuItem[]
    id: string,
    moveItem: any
}

class HorizontalContainer extends React.Component<WithCustomTheme & IContainerProps> {
    moveItem = (item: any, droppable: any) => {
        if (item.id === droppable.id)
            return;

        this.props.moveItem(droppable, item);
    }

    render() {
        const { items, id } = this.props;

        return <Droppable id={id}>
            {(connectDropTarget: any) => (
                <Paper elevation={0} ref={connectDropTarget}>
                    <Grid container spacing={2}>
                        {items.map((item: IMenuItem, index: number) => {
                            var Component = (Components as any)[item.component];

                            return Component && <Grid xs={6} item>
                                <Droppable index={index} key={item.props.id} id={id + " " + item.props.id}>
                                    {(connectDropTarget: any) => (
                                        <Draggable index={index} id={id + " " + item.props.id} end={this.moveItem}>
                                            {(connectDragTarget: any, dragging: boolean) => (
                                                <div ref={node => connectDragTarget(connectDropTarget(node))}
                                                    style={{ opacity: dragging ? 0 : 1 }}>
                                                    <Component {...item.props} />
                                                </div>
                                            )}
                                        </Draggable>
                                    )}
                                </Droppable>
                            </Grid>
                        })}
                    </Grid>
                </Paper>
            )}
        </Droppable>
    }
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        moveItem: (to: any, from: any) => dispatch(menuActions.moveItem(to, from))
    }
}

export default connect(state => state, mapDispatchToProps)(HorizontalContainer)
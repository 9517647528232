import React, { useEffect, useState } from "react";
import TripleStack from "./TripleStack";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from "react-redux";

import PosButton from "../posButton/PosButton";
import Validation from "../../../utils/validationFunctions"
import LoadingSpinner from "../LoadingSpinner";
import Field, { FormikForm } from "../field/Field";
import { AppState } from "../../../store";

function CustomerDetails (props: CustomerDetailsProps) {

    let r = props.customerDetails;


    //const globalState = useSelector((s: AppState) => s.global);


    if (r.billingAddress != null) {
        r = {
            ...r,
            title: r.billingAddress.title,
            firstName: r.billingAddress.firstName,
            lastName: r.billingAddress.lastName,
            addressLine1: r.billingAddress.address1,
            addressLine2: r.billingAddress.address2,
            addressLine3: r.billingAddress.address3,
            mobile: r.billingAddress.mobile,
            postcode: r.billingAddress.postcode,
            city: r.billingAddress.city,
            county: r.billingAddress.county,
            country: 'UK',
            telephone: r.billingAddress.telephone
        }
    }

    for (const key of Object.keys(r)) {
        if (r[key] === null) {
            r[key] = ''
        }
    }

    //const [userRoles, setUserRoles] = useState();

    /*useEffect(() => {

        fetch(`${process.env.PUBLIC_URL}/api/customer/roles`).then(async (response) => {

            const jsonResponse = await response.json();

            setUserRoles(jsonResponse);
        })

    }, [])*/


    const customerDetailsSchema = Yup.object().shape({
        userId: Yup.string().required(Validation.errorMessages.required),
        title: Yup.string(),
        firstName: Yup.string().required(Validation.errorMessages.required),
        lastName: Yup.string().required(Validation.errorMessages.required),
        company: Yup.string().required(Validation.errorMessages.required),
        addressLine1: Yup.string().required(Validation.errorMessages.required),
        addressLine2: Yup.string(),
        addressLine3: Yup.string(),
        email: Yup.string().email(Validation.errorMessages.email).required(Validation.errorMessages.required),
        mobile: Yup.string().matches(Validation.phoneRegExp, Validation.errorMessages.telephone),
        city: Yup.string().required(Validation.errorMessages.required),
        county: Yup.string(),
        country: Yup.string().max(2, 'Use a code shorter than 2 letters.'),
        postcode: Yup.string().matches(Validation.postCodeRegExp, Validation.errorMessages.postcode).required(Validation.errorMessages.required),
        telephone: Yup.string().matches(Validation.phoneRegExp, Validation.errorMessages.telephone)
    });

    const formik = useFormik({
        initialValues: r,
        onSubmit: values => {

            if (!formik.isValid) {
                return alert("Fill it in right.")
            }

            console.log(values);

            props.save(values);
        },
        enableReinitialize: true,
        validationSchema: customerDetailsSchema,
        validateOnChange: true,
        validateOnMount: true
    });

    const form: FormikForm = {
        ...formik,
        allDisabled: props.isPending
    }

    return (
        <>
            <form onSubmit={(e) => { formik.handleSubmit(e); console.log(formik.errors) }}>

                <TripleStack>
                    <h3 style={{ margin: 0, paddingBottom: 30 }}>Billing address</h3>

                    <table style={{ width: "100%", padding: "0 30px" }}><tbody>

                        <Field id="userId" title="Customer #" specialType="readonly" form={form}/>

                        {/*<Field id="role" isDisabled={globalState.user.userId === r.userId} form={form} specialType="select">

                            {
                                userRoles !== undefined && <>

                                    {userRoles.map(role => <option key={role.roleId} value={role.roleId}>{formatRoleTitle(role.role)}</option>)}


                                </>
                            }

                        </Field>

                        {globalState.user.userId === r.userId && <tr>
                            <td></td>
                            <td></td>
                            <td>
                                <i style={{ display: "block", marginBottom: 15 }}>You cannot change your own role.</i>
                            </td>
                        </tr>}
                        */}
                        <Field id="title" specialType="select" form={form}>
                            <option value="">None</option>
                            <option value="Mr">Mr.</option>
                            <option value="Mrs">Mrs.</option>
                            <option value="Miss">Miss.</option>
                            <option value="Ms">Ms.</option>
                            <option value="Dr">Dr.</option>
                            <option value="Rev">Rev.</option>
                            <option value="Prof">Prof.</option>

                        </Field>

                        <Field id="firstName" isRequired form={form} />

                        <Field id="lastName" isRequired form={form} />


                        <Field id="company" isRequired form={form} />
                        <Field id="email" isRequired form={form} />
                        <Field id="telephone" form={form} />



                    </tbody></table>

                    <table style={{ width: "100%", padding: "0 30px" }}><tbody>

                        <Field id="addressLine1" isRequired form={form} />
                        <Field id="addressLine2" form={form} />
                        <Field id="addressLine3" form={form} />
                        {/*<Field id="mobile" form={form} />*/}
                        <Field id="city" isRequired form={form} />
                        <Field id="county" form={form} />
                        <Field id="country" specialType="readonly" form={form} />
                        <Field id="postcode" isRequired form={form} />

                        <tr>
                            <td></td>
                            <td></td>
                            <td>
                                <br />
                                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", maxWidth: 350 }}>
                                    <LoadingSpinner isFinished={!props.isPending} style={{width: "intial"}} width={45} />
                                    <PosButton isDisabled={props.isPending || !form.isValid} text="Save" type="submit" style={{ float: "right", marginRight: 0 }}></PosButton>
                                </div>

                            </td>
                        </tr>


                    </tbody></table>

                </TripleStack>
            </form>


        </>
    )
}

function formatRoleTitle(title: string) {

    title = title.toLowerCase();

    let characters = title.replace(/_/g, ' ').split('')

    for (let i = 0; i < characters.length; i++) {

        if (i === characters.length - 1)
            continue;

        if (i === 0 || characters[i - 1] === ' ') {
            characters[i] = characters[i].toUpperCase()
        }
    }

    return characters.join('');


}

interface CustomerDetailsProps {
    customerDetails: any;
    isPending: boolean;
    save: (data: any) => void
}



export default CustomerDetails;
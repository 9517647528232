
import { createTheme, CssBaseline, jssPreset, ServerStyleSheets, ThemeProvider, withStyles } from "@material-ui/core";
import { create } from "jss";
import React, { useMemo, useState } from "react";
import { Provider, useSelector } from "react-redux";
import { AppState } from "../store";
import Store from "./store";
import Components from "../components/menu";
import { TemplateFormat } from "../store/menu";
import ICustomTheme from "../interfaces/ICustomTheme";
import ReactDOMServer from "react-dom/server";
import { useLocation } from "react-router-dom";
import { FormatModelPartial } from "../pages/PlaceOrderWithFormats";

/*
    isExporting: boolean

    generateHTML

*/

export interface GenerateFormatPreviewRequest {
    menuId: number
    format?: string
    width: number
    height: number
    HTML: string
}

const useGeneratePdf = () => {

    const { menu: menuState } = useSelector((state: AppState) => state);
    const { theme: themeState, menu, menuData } = menuState;
    const { availableFormats } = menuData ?? {};

    const theme = useMemo(() => { return createTheme(themeState) as ICustomTheme }, [themeState]);

    const [isExporting, setIsExporting] = useState(false);
    const location = useLocation();

    const generateHTML = (format?: FormatModelPartial) => {
        //Set renderer to null so styles are not inserted
        //https://github.com/mui-org/material-ui/issues/17850
        const jss = create({ ...jssPreset(), Renderer: null });
        const sheets = new ServerStyleSheets({ jss });

        const width = format?.widthPx ?? menuData?.width;
        const height = format?.heightPx ?? menuData?.height;

        var collect = sheets.collect(
            <div style={{ width: width, height: height, margin: 0, pageBreakInside: "avoid"}}>
                <Provider store={Store}>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        {menu?.map((page, index: number) => {
                            var RootComponent = (Components as any)[page.component];

                            

                            const styles = {
                                root: {
                                    width: width,
                                    height: height,
                                    ...theme.root,
                                }
                            } as any;

                            var FixStyle = withStyles(styles)((props: { classes: any }) =>
                                <RootComponent 
                                    key={index} 
                                    className={`${props.classes.root} page-${index}`} 
                                    formatSize={format?.formatKey}
                                    scale={1}
                                    context={"PRINT"}
                                    {...page.props} 
                                />
                            );
                            return <FixStyle />
                        })}
                    </ThemeProvider>
                </Provider>
            </div>
        );

        var html = ReactDOMServer.renderToString(collect).replaceAll("&quot;", "");

        //console.log("Generating HTML with width", _width, "height", _height, "offset", offset, "zoom", zoom)
        var htmlDoc = `<!DOCTYPE html>
        <html>
            <head>
                <base href="${window.location.origin}">
                <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
                <style id="jss-export">${sheets.toString()}</style>
            </head>
            <body style="width:${width}px;height:${height}px;">${html}</body>
        </html>`;

        return htmlDoc;
    }

    const exportTemplate = (format?: FormatModelPartial) => {
        setIsExporting(true);
        
        if(!format && availableFormats){
            format = availableFormats[0];
        } else {
            console.log("Error exporting template, no format provided and no available formats to fallback on");
        }

        if(menuData){

           
            const request = getTemplate(format);
            const body = JSON.stringify(request);
            if(false){
                setIsExporting(false);
                return;
            }

            
            fetch("/api/export/preview", {
                cache: "no-cache",
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body
            }).then((res: Response) => {
                if (res.status === 201) {
                    console.log(`Stored ${format?.formatKey} against storeKey `);
                    return;
                }
                return res.blob().then((blob: Blob) => {
                    var header = res.headers.get("content-disposition");
                    if (header) {
                        var filename = (header.match(/filename=(.*);/) ?? [])[1];
                        return { filename, blob };
                    }
                });
            }).then((data: { filename: string, blob: Blob } | undefined) => {
                if (data) {
                    var a = document.createElement("a");
                    a.href = URL.createObjectURL(data.blob);
                    a.setAttribute("download", data.filename);
                    a.click();
                }
            }).catch((err: any) => {
                console.log(err.message);
            })
            .finally(() => {
                setIsExporting(false);
            });
        } else {
            console.log("menuData is undefined");
            setIsExporting(false);
        }
    }

    const createMenuPreviewTemplate = (format?: FormatModelPartial):Promise<true| false | void|undefined> => {  
        if (menuData) { 
            const request = getTemplate(format);
            const body = JSON.stringify(request);
            return fetch("/api/export/createMenuPreview", {
                cache: "no-cache",
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body
            }).then((res: Response) => {
                if (res.status === 200) return true;
            }).catch((err: any) => {
                console.log(err.message);
                return false;
            })
            .finally(() => {
                
            });
        } else {
            console.log("menuData is undefined");
            return new Promise((resolve) => {
                resolve(false);
            });
        }
    }

    const getTemplate = (format?: FormatModelPartial): GenerateFormatPreviewRequest | null => {



        const HTML = generateHTML(format);
        if (!HTML || HTML.length < 10) {
            throw new Error("An error occured while exporting your menu");
        }
        if (menuData) {
            const width = format?.widthPx ?? menuData?.width ?? 0;
            const height = format?.heightPx ?? menuData?.height ?? 0;
            const request: GenerateFormatPreviewRequest = {
                format: format?format?.formatKey:menuData.formatKey,
                width,
                height,
                HTML,
                menuId: menuData?.id,
            }

            return request;
        }
        return null;
    }

    return {
        getTemplate,
        createMenuPreviewTemplate,
        exportTemplate,
        isExporting
    }

}

export default useGeneratePdf;
import React from "react";
import { Container, Grid, makeStyles, Theme } from "@material-ui/core";
import logo from "../../assets/britvic_white_logo.png";

const useStyles = makeStyles((theme: Theme) => ({
    logo: {
        marginTop: theme.spacing(1),
        maxWidth: 150,
        width: "100%",
        height: "auto",
        [theme.breakpoints.down("sm")]: {
            maxWidth: 100
        }
    },
    copyright: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        fontSize: 9,
        color: "#fff",
    },
    version: {
        color: "#FFF",
        fontSize: 12,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            fontSize: 10,
        }
    }
}));

const Footer = () => {
    const classes = useStyles();

    return <div style={{ background: "#202020" }}>
        <Container maxWidth={false}>
            <Grid container wrap="nowrap" alignItems="flex-end" justify="space-between">
                <Grid item>
                    <img src={logo} className={classes.logo} alt="Britvic logo" />

                    <div className={classes.copyright}>
                        © 2021-{new Date().getFullYear()} Britvic PLC. All rights reserved.
                    </div>
                </Grid>

                <Grid item className={classes.version}>
                    v0.10068.0
                </Grid>
            </Grid>
        </Container>
    </div>
}

export default Footer;
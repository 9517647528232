import React from "react";
import { CloseRounded } from "@material-ui/icons";

export default function DialogClose(props: any) {
    const style = {
        position: "absolute" as "absolute",
        right: 0,
        top: 0,
        width: 52,
        height: 52,
        border: "2px solid #FFF",
        borderRadius: "50%",
        backgroundColor: "#007D7A",
        textAlign: "center" as "center",
        lineHeight: "52px",
        color: "#FFF",
        transform: "translate(50%,-50%)",
        cursor: "pointer",
        "& svg": {
            width: 32,
            height: "100%"
        }
    };

    return <div onClick={props.onClose} style={style}>
        <CloseRounded style={{
            width: 32,
            height: "100%"
        }} />
    </div>
};
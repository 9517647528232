import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect, useLocation } from "react-router-dom";
import { Box, Container, makeStyles, Theme, createStyles, Dialog, DialogTitle, DialogActions, DialogContent, Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import bg from "./assets/background.png";

import { connect } from "react-redux";
import { AppState } from "./store";
import { IMyMenuState } from "./store/myMenu";

//Pages
import MenuBuilder from "./pages/MenuBuilder";
import NotFound from "./pages/NotFound";
import MyMenus from "./pages/MyMenus";
import ChooseAStyle from './pages/ChooseAStyle';
import PlaceOrder from './pages/PlaceOrder';
import ChooseAFormat from './pages/ChooseAFormat';
import OrderHistory from './pages/OrderHistory';
import MenuCreator from './pages/MenuCreator';

import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import SubHeader from './components/layout/SubHeader';

//Printpal
import PrintPalHeader from './components/layout/PrintPalHeader';
import PrintPalFooter from './components/layout/PrintPalFooter';
import PrintPalSubHeader from './components/layout/PrintPalSubHeader';
import PrintPalPayment from "./pages/PrintPalPayment";
import PaymentProcessed from "./pages/PaymentProcessed";
import { globalActions, globalOperations, IGlobalState } from './store/global';
import DialogClose from './components/dialogs/DialogClose';
import Terms from "./pages/Terms";

import ReactGA from 'react-ga';
import PosLayout from './components/POS/PosLayout';
import ReportingLayout from './components/reporting/ReportingLayout';
import OrdersPage from './pages/POS/OrdersPage';
import PosRoute from './components/POS/PosRoute';
import ReportingRoute from './components/reporting/ReportingRoute';
import PlaceOrderWithFormats from './pages/PlaceOrderWithFormats';
import LoginPrompt from './components/layout/LoginPrompt';
import fetchIntercept from 'fetch-intercept';
import PreviewFormats from './pages/PreviewFormats';
ReactGA.initialize('UA-137050479-1');
ReactGA.pageview(window.location.pathname + window.location.search);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        "background": {
            background: `url(${bg}) center`
        },
        "printPalBackground": {
            backgroundColor: "#FFF"
        },
        loading: {
            paddingTop: 100,
            paddingBottom: 100,
            height: "calc(100vh - 252px)"
        },
        "rootContainer": {
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh"
        }
    }),
);

interface IProps {
    myMenu: IMyMenuState;
    global: IGlobalState;
    clearError: any;
    getMe: any;
    loadSessionDetails: any;
    getToken: any;
}

function App(props: IProps) {
    const { myMenu, global, clearError, getMe, loadSessionDetails, getToken } = props;
    const classes = useStyles();
    const [isPosPage, setIsPosPage] = useState(false);
    const location = useLocation();

    const [hasBeenLoggedOut, setHasBeenLoggedOut] = React.useState(false);
    const [tokenFetched, setTokenFetched] = React.useState(false);

    useEffect(() => {
        setIsPosPage(((location.pathname.indexOf("/pos/") !== -1) || (location.pathname.indexOf("/reporting/") !== -1)) ? true : false);
    }, [location.pathname]);

    useEffect(() => {
        if (location.search) {
            const params = new URLSearchParams(location.search);
            const code = params.get('code');
            if (code && !tokenFetched) {
                setTokenFetched(true);
                getToken(code);
                return;
            }
        }

        if (!global.loading && !global.user) {
            loadSessionDetails();
        }
        //getMe();

        const unregister = fetchIntercept.register({

            response: function (response) {
                if (response.status === 401 && !response.request.url.endsWith("/me")) {
                    console.log("Logged out...");
                    setHasBeenLoggedOut(true);

                }
                return response;
            },
        });
        return () => {
            unregister();
        }
    }, []);

    //useEffect(() => {
    //    console.log(hasBeenLoggedOut);
    //}), [hasBeenLoggedOut];

    let showingLoginPrompt = (!global.loading && !global.user) || hasBeenLoggedOut;
    let showHeader = location?.pathname.indexOf("/preview-formats/") > -1 ? false : true

    return <React.Fragment>
        <div className={classes.rootContainer}>
            {
                !isPosPage && !showingLoginPrompt && showHeader &&
                <>
                    {myMenu.printPalPage ? <PrintPalHeader /> : <Header />}
                </>
            }
            {
                !isPosPage && !showingLoginPrompt && showHeader &&
                <>
                    {myMenu.printPalPage ? <PrintPalSubHeader /> : <SubHeader />}
                </>
            }
            <Box style={{ flex: 1, display: "flex", flexDirection: "column" }} className={myMenu.printPalPage || isPosPage ? classes.printPalBackground : classes.background}>

                {
                    global.loading && <CircularProgress />
                }

                {global.session &&
                    <>
                        {showingLoginPrompt &&
                            <LoginPrompt
                                externalLogin={global.session?.useExternalLogin}
                                onSuccess={() => { setHasBeenLoggedOut(false); getMe(); }}
                            />
                        }
                    </>
                }

                {/*It is safe to just hide the content if we've been logged out as they can't make an API calls anyway, its just a visual thing*/}
                {(global.user) && <Container maxWidth={false} style={{ display: hasBeenLoggedOut ? "none" : "initial" }}>

                    <Switch>
                        <Route exact path="/"><MyMenus /></Route>
                        <Route path="/choose-style"><ChooseAStyle /></Route>
                        <Route path="/choose-format"><ChooseAFormat /></Route>
                        <Route path="/menu-builder"><MenuBuilder /></Route>
                        <Route path="/place-order"><PlaceOrder /></Route>
                        <Route path="/place-order-formats"><PlaceOrderWithFormats /></Route>
                        <Route path="/preview-formats/:id"><PreviewFormats /></Route>
                        <Route path="/order-history"><OrderHistory /></Route>
                        <Route path="/order-payment"><PrintPalPayment /></Route>
                        <Route path="/payment-processed"><PaymentProcessed /></Route>
                        <Route path="/menu-creator"><MenuCreator /></Route>
                        <Route path="/screenprint-terms"><Terms /></Route>
                        <PosRoute path="/pos" component={PosLayout}> </PosRoute>
                        <ReportingRoute path="/reporting" component={ReportingLayout}> </ReportingRoute>
                        <Route path="/"><NotFound /></Route>
                    </Switch>

                </Container>
                }
            </Box>
            {!showingLoginPrompt && showHeader && (myMenu.printPalPage ? <PrintPalFooter /> : <Footer />)}

            <Dialog open={global.error !== undefined}>
                <DialogClose onClose={clearError} />
                <DialogTitle>
                    {global.error?.title}
                </DialogTitle>

                <DialogContent>
                    {global.error?.error}
                </DialogContent>

                <DialogActions>
                    <Button onClick={clearError}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    </React.Fragment>
}

const mapStateToProps = (state: AppState) => ({
    myMenu: state.myMenu,
    global: state.global
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        clearError: () => dispatch(globalActions.showError(undefined)),
        getMe: () => dispatch(globalOperations.getMe()),
        loadSessionDetails: () => dispatch(globalOperations.loadSessionDetails()),
        getToken: (code: string) => dispatch(globalOperations.getToken(code))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);

import { Button, Grid, InputLabel, Input, Typography, makeStyles, createStyles, Theme } from "@material-ui/core";
import React from "react";
import logo from "../../assets/britvic_black_logo.png";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            backgroundColor: 'white',
            padding: 25,
            maxWidth: "400px",
            width: "80%",
            minWidth: "500px",
            marginBottom: 200,
            fontFamily: "inherit"
        },
        title: {
            fontSize: 29,
            margin: "20px 0 25px 0",
        },
        input: {
            width: "100%",
            padding: 6,
            borderRadius: 0,
            border: "1px grey solid",
            fontFamily: "inherit"

        },
        label: {
            marginBottom: 5,
            margin: "15px 0 5px 0",
        },
        button: {
            color: "#fff"
        },
        error: {
            display: "block",
            height: 30,
            color: "red",
            marginTop: 10,
            margin: "10px 0 10px 0"
        }
    })
);

interface LoginPromptProps {
    externalLogin?: boolean;
    onSuccess: () => void;
}

export default function LoginPrompt(props: LoginPromptProps) {
    const [getUN, setUN] = React.useState("");
    const [getPW, setPW] = React.useState(""); //defaultpassword

    const [loading, setLoading] = React.useState(false);

    const [error, setError] = React.useState("");

    const classes = useStyles();

    async function submit(e: React.FormEvent) {

        e.preventDefault();

        setLoading(true);
        console.log(getPW, getUN);
        const pw = await hash(getPW);
        const response = await fetch("/api/customer/authenticate", {
            method: "PUT",
            body: JSON.stringify({
                email: getUN,
                passwordHash: pw
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });

        if (response.ok) {
            setError("");
            props.onSuccess();
        } else {
            setError("Incorrect email address or password");
            setLoading(false);
        }

    }

    return <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{minHeight: '100vh'}}
    >
        <Grid item xs={4} className={classes.container}>
            {props.externalLogin
                ?
                    <>
                        <img src={logo} height={60} />
                        <Typography className={classes.title}>Log In to Meal Deal Designer </Typography>
                        <Typography className={classes.label}>Processing your login...</Typography>
                    </>
                :
                    <form onSubmit={submit}>
                        <img src={logo} height={60} />

                        <Typography className={classes.title}>Log In to Meal Deal Designer </Typography>

                        <InputLabel className={classes.label}>Email address</InputLabel>
                        <Input className={classes.input} disableUnderline value={getUN} onChange={e => setUN(e.target.value)} />

                        <InputLabel className={classes.label}>Password</InputLabel>
                        <Input type="password" className={classes.input} disableUnderline value={getPW} onChange={e => setPW(e.target.value)} />

                        <Typography className={classes.error}>{error}</Typography>

                        <Button variant="outlined" disabled={loading} style={{
                            backgroundColor: loading ? "lightgrey" : "#007D7A",
                            border: loading ? "1px solid lightgrey" : "1px solid #007D7A",

                        }} type="submit" className={classes.button}>Log in</Button>
                    </form>
            }
        </Grid>

    </Grid>
}

async function hash(password: string) {
    const utf8 = new TextEncoder().encode(password);
    const buffer = await crypto.subtle.digest('SHA-256', utf8)
    const hashArray = Array.from(new Uint8Array(buffer));
    const hashHex = hashArray
        .map((bytes) => bytes.toString(16).padStart(2, '0'))
        .join('');
    return hashHex;
}

import { Grid, Typography, WithStyles, FormControlLabel, RadioGroup, Radio, Divider, Checkbox} from "@material-ui/core";
import React from "react";
import Editable from "../decorators/Editable";
import StyleInjector from "../decorators/StyleInjector";
import TextField from "../form/TextField";
import ImageUpload from "../form/ImageUpload";
import { menuOperations } from "../../store/menu";
import { connect } from "react-redux";
import ILibraryImage from "../../interfaces/ILibraryImage";
import ICustomTheme from "../../interfaces/ICustomTheme";

interface ISimpleCoverProps {
    id: string,
    title: string,
    logoImage: string,
    bottomText?: string,
    bottomNumber?: string,
    desc?: string,
    uploadFile: any,
    coverOption: string,
    whiteText: boolean,
    theme: ICustomTheme
}

const styles = ({  
});

function Appearance(props: { onChange: any, dataMapping: any }) {
    const { onChange, dataMapping } = props;

    return <Grid container style={{ marginBottom: 30 }}>

        <Grid item xs={12} style={{marginTop: 15, marginBottom: 15}}>
            <span style={{ fontSize: 14 }}>Select your front cover type:</span>
        </Grid>

        <RadioGroup name="coverOption" defaultValue={dataMapping.coverOption} aria-label="cover option" onChange={(ev: any) => dataMapping.coverOption = ev.target.value }>
            <Grid container>
                <Grid item>
                    <FormControlLabel value="1" label={<span style={{ fontSize: 14 }}>{dataMapping.theme.simpleCover?.menuText}</span>}  control={<Radio />} labelPlacement="end"/>
                </Grid>

                <Grid item>
                    <FormControlLabel value={dataMapping.theme.simpleCover?.altCoverOption} label={<span style={{ fontSize: 14 }}>{dataMapping.theme.simpleCover?.altMenuText}</span>}  control={<Radio />} labelPlacement="end"/>
                </Grid>
            </Grid>
        </RadioGroup>

        {dataMapping.theme.simpleCover?.allowWhiteText &&
        <Grid item xs={12} style={{marginTop: 10}}>
            <Grid container>
                <Grid item>
                    <FormControlLabel 
                        name="whiteText" 
                        onChange={onChange} 
                        checked={dataMapping["whiteText"]} 
                        label={<span style={{ fontSize: 14 }}>Use white text (address only)</span>} 
                        labelPlacement="end"
                        control={<Checkbox />} />
                </Grid>
            </Grid>
        </Grid>}

        <Grid item xs={12}>
            <Divider style={{ marginTop: 15}} />
        </Grid>
    </Grid>
}

function SimpleCover(props: ISimpleCoverProps & WithStyles) {
    const { id, logoImage, title, bottomText, bottomNumber, classes, uploadFile, coverOption, whiteText, desc, theme } = props;    

    const data = {
        id,
        name: "Cover page",
        initialValues: {
            logoImage,
            title,
            bottomText,
            bottomNumber,
            desc,
            coverOption,
            whiteText,
            theme
        },
        state: {
            formData: undefined,
            logoFormData: undefined,
            error: false,
            logoError: false
        },
        fields: [
            {                    
                label: "Appearance",
                render: (onChange: any, dataMapping: any) => {
                return <Appearance onChange={onChange} dataMapping={dataMapping}/>
                }
            },
            {
                label: "Title",
                required: true,
                render: (onChange: any, dataMapping: any) => {
                    return <TextField name="title" value={dataMapping["title"]} onChange={onChange} />
                }
            },
            {
                label: "Address",
                required: true,
                render: (onChange: any, dataMapping: any) => {
                    return <TextField fullWidth multiline rows={4} name="bottomText" value={dataMapping["bottomText"]} onChange={onChange} />
                }
            },
            {
                label: "Contact details",
                required: true,
                render: (onChange: any, dataMapping: any) => {
                    return <TextField fullWidth name="bottomNumber" value={dataMapping["bottomNumber"]} onChange={onChange} />
                }
            },
                desc && {
                label: "Description",
                render: (onChange: any, dataMapping: any) => {
                    return <TextField fullWidth name="desc" value={dataMapping["desc"]} onChange={onChange} />
                }
            },
            {
                label: "Logo",
                render: (onChange: any, dataMapping: any, state: { onSetState: any, editableState: any }) => {
                    return <React.Fragment>
                        {state.editableState.logoError && <Typography variant="body2" gutterBottom color="error">Image upload failed, please make sure your image is under 5mb and is a jpg or png</Typography>}

                        <ImageUpload
                            name="logo"
                            imageKey="logoImage"
                            dataMapping={dataMapping}
                            fileUploaded={(formData: FormData) => {
                                state.onSetState({
                                    editableState: { ...state.editableState, logoFormData: formData, logoError: false }
                                });
                            }}
                            imageSelected={(image: ILibraryImage) => {
                                state.onSetState({
                                    dataMapping: { ...dataMapping, logoImage: image.filePath },
                                    editableState: { ...state.editableState, logoFormData: null, logoError: false }
                                });
                            }} />
                    </React.Fragment>
                }
            }
        ],
        onSaved: (dataMapping: any, state: any) => {
            return new Promise<void>((resolve: any, reject: any) => {
                if (!state.editableState.logoFormData) {
                    resolve();
                    return;
                }

                //On save upload the file to the server
                uploadFile(state.editableState.logoFormData).then((file: any) => {
                    let url = window.location.origin + "/api/Files/" + file.fileId;
                    dataMapping.logoImage = url;
                    resolve();
                }).catch(() => {
                    console.error("File upload error");
                    reject();
                });

                //Reset form data
                state.onSetState({
                    editableState: { ...state.editableState, logoFormData: undefined }
                });
            });
        }
    }; 

    return <Editable data={data}>
        {coverOption === "1" // concept 2/4/5
        ?
        <Grid container className={`${classes.fullHeight} ${classes.root} ${classes.border} ${classes.background}`} alignItems="center" direction="column" justify="flex-start">
            <Grid item className={classes.image}>
                {logoImage ? <img alt="logo" src={logoImage} className={classes.sizedLogo}/> : <span className={`${classes.circle} ${classes.bg} ${classes.fg}`}>Logo<br/>here</span>}
            </Grid>
            
            {classes.background 
            ?
            <React.Fragment>
                {classes.addressContainer 
                    ?
                    <Grid className={classes.addressContainer}>
                        <Grid className={classes.address}>
                            <Typography variant="h2" className={classes.title}>{title}</Typography>
                            {desc && <Typography variant="h3"className={`${classes.desc} ${whiteText ? classes.desc : ""}`}>{desc}</Typography>}
                        </Grid>
                    </Grid>
                    :
                    <Grid className={classes.address}>
                        <Typography variant="h2" className={classes.title}>{title}</Typography>
                        {desc && <Typography variant="h3"className={`${classes.desc} ${whiteText ? classes.desc : ""}`}>{desc}</Typography>}
                    </Grid>
                }
                <Grid item>
                    <Typography variant="h2" className={`${classes.bottomText} ${whiteText ? classes.whiteText : ""}`}>{bottomText}</Typography>
                    <Typography variant="h2" className={`${classes.bottomNumber} ${whiteText ? classes.whiteText : ""}`}>{bottomNumber}</Typography>
                </Grid>
            </React.Fragment>
            :
            <React.Fragment>
                <Grid className={classes.address}>
                    <Typography variant="h2" className={classes.title}>{title}</Typography>
                    {desc && <Typography variant="h3"className={`${classes.desc} ${whiteText ? classes.desc : ""}`}>{desc}</Typography>}
                    <Typography variant="h2" className={`${classes.bottomText} ${whiteText ? classes.whiteText : ""}`}>{bottomText}</Typography>
                    <Typography variant="h2" className={`${classes.bottomNumber} ${whiteText ? classes.whiteText : ""}`}>{bottomNumber}</Typography>
                </Grid>

                <Grid item>
                    <div className={classes.cover}/>
                </Grid>
            </React.Fragment>
            }
        </Grid>
        :
        coverOption === "2" // concept 4
        ?
        <Grid container className={`${classes.fullHeight} ${classes.root}`} alignItems="center" direction="column" justify="flex-start">
            <Grid container className={classes.altBackground} alignItems="center" direction="column" justify="flex-start">
                <Grid item className={classes.altImage}>
                    {logoImage ? <img alt="logo" src={logoImage} className={classes.sizedLogo}/> : <span className={`${classes.circle} ${classes.bg} ${classes.fg}`}>Logo<br/>here</span>}
                </Grid>
                <Grid item className={classes.altTitle}>
                    <Typography variant="h2" className={classes.title}>{title}</Typography>
                </Grid>
            </Grid>

            <Grid item>
                <div className={classes.altCover}/>
            </Grid>

            <Grid item className={classes.altAddress}>
                <Typography variant="h2" className={`${classes.bottomText} ${whiteText ? classes.whiteText : ""}`}>{bottomText}</Typography>
                <Typography variant="h2" className={`${classes.bottomNumber} ${whiteText ? classes.whiteText : ""}`}>{bottomNumber}</Typography>
            </Grid>
        </Grid>
        :         
        coverOption === "6" // concept 7
        ?
        <div className={classes.half}>
            <Grid container className={`${classes.fullHeight} ${classes.altBorder} ${classes.root} ${classes.altBackground}`} alignItems="center" direction="column" justify="flex-start">
                <Grid item className={classes.altImage}>
                    {logoImage ? <img alt="logo" src={logoImage} className={classes.sizedLogo}/> : <span className={`${classes.circle} ${classes.bg} ${classes.fg}`}>Logo<br/>here</span>}
                </Grid>

                <Grid item className={classes.altTitle}>
                    <Typography variant="h2" className={classes.title}>{title}</Typography>
                    {desc && <Typography variant="h3"className={`${classes.desc} ${whiteText ? classes.desc : ""}`}>{desc}</Typography>}                    
                </Grid>

                <Grid item className={classes.altAddress}>
                    <Typography variant="h2" className={`${classes.bottomText} ${whiteText ? classes.whiteText : ""}`}>{bottomText}</Typography>
                    <Typography variant="h2" className={`${classes.bottomNumber} ${whiteText ? classes.whiteText : ""}`}>{bottomNumber}</Typography>
                </Grid>
            </Grid>
        </div>
        :
        // concept 2 & 5
        <Grid container className={`${classes.fullHeight} ${classes.root}`} alignItems="center" direction="column" justify="flex-start">
            <Grid container className={classes.altBackground} alignItems="center" direction="column" justify="flex-start">
                <Grid item className={classes.altImage}>
                    {logoImage ? <img alt="logo" src={logoImage} className={classes.sizedLogo}/> : <span className={`${classes.circle} ${classes.bg} ${classes.fg}`}>Logo<br/>here</span>}
                </Grid>
                <div className={classes.altCover}>
                    { coverOption === "3" // concept 2
                    ?
                    <Grid item className={classes.altAddress}>                        
                        <Typography variant="h2" className={classes.altTitle}>{title}</Typography>
                        <Typography variant="h2" className={`${classes.bottomText} ${whiteText ? classes.whiteText : ""}`}>{bottomText}</Typography>
                        <Typography variant="h2" className={`${classes.bottomNumber} ${whiteText ? classes.whiteText : ""}`}>{bottomNumber}</Typography>                    
                    </Grid>
                    :
                    <Grid item className={classes.altAddress}>   
                        <Typography variant="h2" className={classes.altTitle}>{title}</Typography>                     
                        <Typography variant="h3"className={`${classes.desc} ${whiteText ? classes.desc : ""}`}>{desc}</Typography>                   
                    </Grid>                    
                    }
                </div>
                { coverOption === "3" // concept 2
                ?
                <Grid item className={classes.footer}>
                    <Typography variant="h3"className={`${classes.desc} ${whiteText ? classes.desc : ""}`}>{desc}</Typography>
                </Grid>
                :
                <Grid item className={classes.footer}>
                    <Typography variant="h2" className={`${classes.bottomText} ${whiteText ? classes.whiteText : ""}`}>{bottomText}</Typography>
                    <Typography variant="h2" className={`${classes.bottomNumber} ${whiteText ? classes.whiteText : ""}`}>{bottomNumber}</Typography>   
                </Grid>
                }
            </Grid>
        </Grid>         
        }
    </Editable>;
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        uploadFile: (file: any) => dispatch(menuOperations.uploadFile(file))
    }
}

export default connect(null, mapDispatchToProps)(StyleInjector(styles, "simpleCover", true)(SimpleCover));
import React, { useEffect, useState } from "react";
import PosButton from "../../components/POS/posButton/PosButton";
import TableWrapper from "../../components/POS/table/Table";
import LoadingSpinner from "../../components/POS/LoadingSpinner";
import VoucherOverlay from "../../components/POS/voucher/VoucherOverlay";
import Filter, { FilterPropertiesState } from "../../components/POS/Filters";
import Tick from "../../assets/tick.png"

function VouchersPage() {

    const [currentPopupState, setCurrentPopupState] = useState<PopupState | undefined>();

    const [vouchersData, setVouchersData] = useState<VoucherTableRow[]>();

    const [filterState, setFilterState] = useState<FilterPropertiesState>({ filterSelection: '', searchBar: '', limit: 20 });

    const getAllVouchers = () => {
        fetch(`${process.env.PUBLIC_URL}/api/vouchers`).then(async (response) => {

            const jsonResponse: VoucherDTO[] = await response.json();

            const cleanData = jsonResponse.map(voucher => {
                return createVoucherRow(voucher);
            });

            setVouchersData(cleanData);

        })
    };

    const setVoucher = (originalResponse: VoucherOverlayResponse) => {

        const editedVoucherIndex = vouchersData.findIndex(voucher => originalResponse.voucherId === voucher.voucherId);

        const vouchersCopy = [...vouchersData];

        if (editedVoucherIndex === -1) {
            vouchersCopy.push(createVoucherRow(originalResponse));
        } else {
            vouchersCopy[editedVoucherIndex] = createVoucherRow(originalResponse);

        }

        setVouchersData(vouchersCopy);

    }

    useEffect(getAllVouchers, []);

    if (currentPopupState !== undefined) {
        document.body.classList.add('noscroll');
    } else {
        document.body.classList.remove('noscroll');
    }

    if (vouchersData === undefined) {
        return <LoadingSpinner/>
    }


    return <>

        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>

            <Filter
                filterValue={filterState.filterSelection}
                searchValue={filterState.searchBar}
                limitValue={filterState.limit}

                filterChange={(e) => { setFilterState({ ...filterState, filterSelection: e.target.value }) }}
                searchChange={(e) => { setFilterState({ ...filterState, searchBar: e.target.value }) }}
                limitChange={(e) => { setFilterState({ ...filterState, limit: parseInt(e.target.value) }) }}

                searchPlaceholder="Search by voucher code, assignment or description"


                title="Vouchers"

                subtitle="All vouchers supplied to customers are single use."

                showFilters={false}

                showPageSize

                pageSizeOptions={[10, 20, 30, 50]}

            />

            <PosButton text="New voucher" style={{marginLeft: 20}} onClick={() => setCurrentPopupState({ voucher: undefined, isNew: false })} />



        </div>
            
        <br/>
        <TableWrapper

            data={vouchersData}
            keys={["code", "startDate", "endDate", "description", "assignedToList", "used", "inUse", "isPending", "validFrom", "validTo", "isInUse"]}
            headings={["Voucher", "Start date", "End date", "Description", "Assigned to", "Used", "In use", "", "Raw valid from", "Raw valid to", "is in use raw"]}
            columnWidths={[1,1,1,2,1.5,0.5,0.5, 0.5, 0, 0]}

            searchableColumns={["code", "assignedToList", "description"]}

            searchTerm={filterState.searchBar}

            pageSize={filterState.limit}

            onRowClick={(data: VoucherTableRow) => { setCurrentPopupState({ voucher: data, isNew: false }); }}

            noArrowColumns={["isPending"]}

            disableIfColumnNotNull="isPending"

            customSortValues={[
                {
                    visibleColumn: "startDate",
                    hiddenColumn: "validFrom"
                },
                {
                    visibleColumn: "endDate",
                    hiddenColumn: "validTo"
                },
                {
                    visibleColumn: "inUse",
                    hiddenColumn: "isInUse"
                }
            ]}
        />

        {
            currentPopupState !== undefined && (
                <VoucherOverlay
                    voucher={currentPopupState.voucher}
                    cancel={() => setCurrentPopupState(undefined)}
                    save={setVoucher}
                    existingCodes={vouchersData.map(voucher => voucher.code)}
                />
            )
        }

            
    </>
}

interface PopupState {
    voucher: VoucherDTO | undefined;
    isNew: boolean;
}

export interface VoucherDTO {
    voucherId: number,
    code: string,
    validFrom: string,
    validTo: string | null,
    amount: number,
    description: string | null,
    disabled: boolean | null,
    assignedTo: { id: number, title: string }[]
}

export interface VoucherTableRow extends VoucherDTO {
    startDate: string;
    endDate: string;
    assignedToList: string,
    inUse: JSX.Element | null,
    isInUse: string,
    isPending: JSX.Element | null
}

export interface VoucherOverlayResponse extends VoucherDTO {
    pending: boolean;
}

export interface VoucherOverlayForm extends VoucherDTO {
    htmlValidFrom: string;
    htmlValidTo: string;
}



function createVoucherRow(voucher: VoucherDTO | VoucherOverlayResponse): VoucherTableRow {
    const row: VoucherTableRow = {
        ...voucher,
        assignedToList: voucher.assignedTo.map(tag => tag.title).join(', '),
        inUse: !voucher.disabled ? <img src={Tick} /> : null,

        disabled: !!voucher.disabled,

        isPending: null,

        startDate: cleanDate(voucher.validFrom),
        endDate: voucher.validTo === null ? '' : cleanDate(voucher.validTo),
        isInUse: !!voucher.disabled ? "A" : "B"

    }

    if ('pending' in voucher) {
        row.isPending = voucher.pending ? <LoadingSpinner width={50} /> : null;
    }

    return row;
}

function cleanDate(date: string): string {
    const d = new Date(date);

    return `${d.getDate().toString().padStart(2, '0')}/${(d.getMonth() + 1).toString().padStart(2, '0') }/${d.getFullYear()}`;
}

export default VouchersPage;
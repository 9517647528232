import React from 'react';
import { Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { Typography, Card, CardMedia } from '@material-ui/core';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../chooseAStyle/slick.css'

const styles = (theme: Theme) => ({
    container: {
        background: "#FFF",
        paddingLeft: 15,
        "& h2": {
            marginBottom: theme.spacing(3)
        },
        "& h3": {
            marginBottom: theme.spacing(2)
        },
        [theme.breakpoints.down("sm")]: {
            paddingLeft: theme.spacing(2),
        }
    },
    navButton: {
        height: 35,
        "&:hover": {
            cursor: "pointer",
        },
        [theme.breakpoints.down("sm")]: {
            height: 20,
        }
    },
    navButtonContainer: {
        textAlign: "right" as "right",
        marginRight: theme.spacing(5.5),
        paddingBottom: theme.spacing(2.5),
        paddingTop: theme.spacing(2.5)
    },
    searchIconOverlay: {
        top: 15,
        left: "87%",
        color: "#FFF",
        backgroundColor: "transparent",
        [theme.breakpoints.down("sm")]: {
            top: 8
        }
    },
    titleOverlay: {
        top: "15px",
        left: "15px",
        color: "#FFF",
        backgroundColor: "transparent",
        [theme.breakpoints.down("sm")]: {
            top: "10px",
            left: "10px",
        }
    },
    media: {
        height: 295,
        width: "auto",
        [theme.breakpoints.down("sm")]: {
            height: 180,
        }
    },
    card: {
        border: "3px solid transparent",
        boxShadow: "none",
        "&:hover": {
            boxShadow: "none",
            cursor: "pointer",
            border: "3px solid #007D7A",
        }
    },
    title: {
        fontSize: 20,
        [theme.breakpoints.down("sm")]: {
            fontSize: 14
        }
    },
    searchImg: {
        [theme.breakpoints.down("sm")]: {
            height: 25,
            cursor: "pointer"
        }
    }
});

interface IState {
    isMobileApp: boolean;
}

class FeaturedStyles extends React.PureComponent<WithStyles & any, IState> {
    private sliderRef: any;
    private mounted: boolean = false;

    constructor(props: any) {
        super(props);
        this.handleNext = this.handleNext.bind(this);
        this.handlePrevious = this.handlePrevious.bind(this);
        this.sliderRef = React.createRef();

        this.state = { isMobileApp: false };
    }

    componentDidMount() {
        this.mounted = true;
        this.setResponsiveness();
        window.addEventListener("resize", () => this.setResponsiveness());
    }

    componentWillUnmount() {
        this.mounted = false;
        window.removeEventListener('resize', () => this.setResponsiveness());
    }

    setResponsiveness = () => {
        if (this.mounted)
            return window.innerWidth < 900
                ? this.setState((prevState) => ({ ...prevState, isMobileApp: true }))
                : this.setState((prevState) => ({ ...prevState, isMobileApp: false }));
    };

    handleNext() {
        this.sliderRef.current.slickNext();
    }

    handlePrevious() {
        this.sliderRef.current.slickPrev();
    }

    handleOpenStyleInfoPopup = (event: any) => {
        sessionStorage.setItem('styleId', event.currentTarget.id);
        this.props.openPopup();
    }

    render() {
        const { classes, styleData: { featuredStyles } } = this.props;

        const settings = {
            dots: true,
            infinite: false,
            speed: 700,
            slidesToShow: 3,
            slidesToScroll: 3,
            arrows: false,
            dotsClass: "button__bar",
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2.5,
                    }
                },
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 2,
                        initialSlide: 1,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        initialSlide: 1,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        dots: false
                    }
                }
            ]
        };

        //debugger

        return (
            <div className={classes.container}>
                <Typography style={{ marginLeft: 45 }} variant={this.state.isMobileApp ? "h3" : "h2"}><strong>Popular styles</strong></Typography>
                <div className={classes.root}>
                    <Slider ref={this.sliderRef} {...settings}>
                        {featuredStyles && featuredStyles.map((style: any) => {
                            let separateImages = style.featuredImgUrl.split(",");
                            return separateImages.map((imUrl: string) => {
                                return <div key={style.id}>
                                    <Card
                                        id={style.id}
                                        raised={false}
                                        style={{ marginRight: 10, position: "relative" }}
                                        key={style.id}
                                        onClick={this.handleOpenStyleInfoPopup}
                                        className={classes.card}>
                                        <CardMedia className={classes.media} image={`./assets/styles/featured/${imUrl}`} key={style.id}  />
                                        <div className={classes.searchIconOverlay} style={{ position: "absolute" }}>
                                            <img src={`./assets/search.svg`} id={style.id} key={style.id} alt="open style popup" className={classes.searchImg} />
                                        </div>
                                    </Card>
                                </div>
                            })
                        }
                        )}
                    </Slider>
                    <div className={classes.navButtonContainer}>
                        <img src={`./assets/prev_arrow.svg`} onClick={this.handlePrevious} className={classes.navButton} style={{ marginRight: 50 }} alt="previous arrow" />
                        <img src={`./assets/prev_arrow.svg`} onClick={this.handleNext} className={classes.navButton} style={{ transform: "rotate(180deg)" }} alt="next arrow" />
                    </div>
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(FeaturedStyles);
export default class Validation {

    public static readonly phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    public static readonly postCodeRegExp = /^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i

    public static errorMessages: { [key: string]: string } = {
        required: "This field cannot be empty",
        email: "Invalid email address",
        telephone: "Invalid telephone number",
        postcode: "Invalid postcode",
        pickone: "Select one",
        number: "Invalid number",
        date: "Invalid date"
    }

}
import React from "react";
import { Grid, TextField, Typography, WithStyles, FormControlLabel, Checkbox, Divider, RadioGroup, Radio } from "@material-ui/core";
import Editable from "../decorators/Editable";
import StyleInjector from "../decorators/StyleInjector";
import EditableProducts from "../layout/EditableProducts";
import IProduct from "../../interfaces/IProduct";
import ICustomTheme from "../../interfaces/ICustomTheme";
import { formatPrice } from "../../utils/common";

interface IProductListProps {
    id: string,
    title: string,
    products?: IProduct[],
    theme: ICustomTheme,
    hideDescription: boolean,
    border: boolean,
    whiteText: boolean,
    smallerTitle: boolean,
    hasBackground: boolean,
    hasTitleUnderline: boolean,
    hideDashedLine: boolean,
    hideTitle: boolean,
    hideDelete: boolean,
    hideDropdownOptions: boolean,
    hideAddProducts: boolean,
    productLimit?: number    
}

const styles = ({
    productList: {
        paddingLeft: 5,
        paddingRight: 5,
        "& p": {
            whiteSpace: "pre-line" as "pre-line"
        }
    },
    dashedSingleLine:{
        borderTop: "1px dashed",
        marginTop: 10,
        marginRight: 5,
        marginLeft: 5,
        topBorderColor: "#000"
    },
});

function Appearance(props: { onChange: any, dataMapping: any }) {
    const { onChange, dataMapping } = props;

    return <Grid container style={{ marginBottom: 30 }}>

        <Grid item xs={12} style={{marginTop: 10}}>
            <Grid container>
                <Grid item>
                    <FormControlLabel 
                        name="hideDescription" 
                        onChange={onChange} 
                        checked={dataMapping["hideDescription"]} 
                        label={<span style={{ fontSize: 14 }}>Show product name only (no description)</span>} 
                        labelPlacement="end"
                        control={<Checkbox />} />
                </Grid>
            </Grid>
        </Grid>

        {dataMapping.theme.productList?.smallerTitle && <Grid item xs={12} style={{marginTop: 10}}>
            <Grid container>
                <Grid item>
                    <FormControlLabel 
                        name="smallerTitle" 
                        onChange={onChange} 
                        checked={dataMapping["smallerTitle"]} 
                        label={<span style={{ fontSize: 14 }}>Use a smaller title font</span>} 
                        labelPlacement="end"
                        control={<Checkbox />} />
                </Grid>
            </Grid>
        </Grid>}

        {dataMapping.theme.productList?.border && <Grid item xs={12} style={{marginTop: 10}}>
            <Grid container>
                <Grid item>
                    <FormControlLabel 
                        name="border" 
                        onChange={onChange} 
                        checked={dataMapping["border"]} 
                        label={<span style={{ fontSize: 14 }}>Show border</span>} 
                        labelPlacement="end"
                        control={<Checkbox />} />
                </Grid>
            </Grid>
        </Grid>}

        <Grid item xs={12} style={{marginTop: 10}}>
            <Grid container>
                <Grid item>
                    <FormControlLabel 
                        name="whiteText" 
                        onChange={onChange} 
                        checked={dataMapping["whiteText"]} 
                        label={<span style={{ fontSize: 14 }}>Use white text</span>} 
                        labelPlacement="end"
                        control={<Checkbox />} />
                </Grid>
            </Grid>
        </Grid>

        {dataMapping.theme.productList?.background && <Grid item xs={12} style={{marginTop: 10}}>
            <Grid container>
                <Grid item>
                    <RadioGroup name="hasBackground" onChange={onChange} value={dataMapping["hasBackground"]?.toString() ?? "false"} defaultValue="false" aria-label="background colour">
                        <Grid container style={{ marginBottom: 15 }}>
                            <Grid item>
                                <FormControlLabel value="false" label="No background colour" control={<Radio />} labelPlacement="end" />
                            </Grid>

                            <Grid item>
                                <FormControlLabel value="true" label="Apply background colour" control={<Radio />} labelPlacement="end" />
                            </Grid>
                        </Grid>
                    </RadioGroup>
                </Grid>
            </Grid>
        </Grid>}

        <Grid item xs={12}>
            <Divider style={{ marginTop: 15}} />
        </Grid>
    </Grid>
}

class ProductList extends React.Component<WithStyles & IProductListProps> {
    static getCSVHeaders(): string {
        return "\"Id\",\"Title\"";
    }

    static renderToCSV(data: IProductListProps): string {
        var title = `"${data.id}","${data.title ?? ""}"\n\n`;

        //Now this is funky, start a sub table of products and offset it by one col so it looks nicer
        const productHeader = "\"\",\"Name\",\"Description\",\"Price\"\n";

        return title + productHeader + data.products?.reduce((prev: string, curr: IProduct) => {
            return prev + `"","${curr.name}","${curr.desc?.replaceAll("\"", "\"\"") ?? ""}","${curr.price ?? 0}"\n`;
        }, "") + "\n";
    }

    static setFromCSV(data: IProductListProps, csv: string[], index: number): boolean {
        if (!data.products)
            return false;

        var product = data.products[index];

        if (!product)
            return false;

        product.name = csv[1];
        product.desc = csv[2];
        product.price = parseFloat(csv[3]);
        return true;
    }

    render() {
        const { id, title, products, classes, hideDescription, border, whiteText, smallerTitle, hasBackground, theme, hasTitleUnderline, hideDashedLine, hideAddProducts, hideDelete, hideDropdownOptions, hideTitle, productLimit, defaultHighlighted } = this.props;
        //console.log(hideAddProducts, hideDelete, hideDropdownOptions, hideTitle, theme.productList);
        const data = {
            name: "Product List",
            id,
            initialValues: {
                title,
                products,
                hideDescription,
                border,
                whiteText,
                smallerTitle,
                hasBackground,
                theme,
                hasTitleUnderline
            },
            state: {
                opened: [0],
                showAddProduct: false,
                showAddBritvicProduct: false,
                showAddGinProduct: false,
                newProduct: {} as IProduct,
                formData: undefined
            },
            fields: [
                !theme.productList?.isTransparent && {                    
                    label: "Appearance",
                    render: (onChange: any, dataMapping: any) => {
                    return <Appearance onChange={onChange} dataMapping={dataMapping}/>
                    }
                }, !hideTitle && {
                    label: "Title text",
                    render: (onChange: any, dataMapping: any) => {
                        return <TextField name="title" value={dataMapping.title} onChange={onChange} InputProps={{ disableUnderline: true }} variant="filled" />
                    }
                }, {
                    render: (onChange: any, dataMapping: any, state: { onSetState: any, editableState: any }) => {
                        return <EditableProducts productLimit={productLimit} hideDescription={hideDescription} hideDropdownOptions={hideDropdownOptions} hideDelete={hideDelete} hideAddProducts={hideAddProducts} showGinProducts={theme.productList?.showGinProducts} hideFeatured onChange={onChange} dataMapping={dataMapping} state={state}/>
                    }
                }
            ],
        };

        return <Editable data={data}>
            <div className={`${classes.root} ${border ? hideDescription ? classes.border : classes.altBorder : hideDescription ? classes.widen : ""} ${whiteText ? classes.whiteText : ""} ${hasBackground ? classes.background : ""}`}>
                {title && <Typography variant={smallerTitle ? "h3" : "h2"} style={{marginLeft: classes.underline ? 0 : 5}}>{title}</Typography>}
                {title && hasTitleUnderline && <div className={classes.underline}/>}                
                {products && products?.map((item: IProduct, index) => {
                    return <div key={index} className={classes.productList}>
                        {!hideDescription &&
                        <Grid container justify="flex-start" key={index}>
                            <Grid item>
                                <Typography variant="h3">{item.name}</Typography>
                            </Grid>
                        </Grid>}
                        <Grid container justify={theme.productList?.closePrice ? theme.productList?.closePrice : "space-between"} key={index}>
                            {
                                theme.productList?.closePrice
                                ?
                                <>
                                    <Grid item>
                                        <Grid container justify="flex-start" style={{paddingRight: 5}} key={index}>
                                            <Typography variant="h4">{hideDescription ? item.name : item.desc}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item>  
                                        <Grid container justify="flex-end" key={index}>     
                                            <Typography variant="h4" className={classes.price ? classes.price : ""}>{formatPrice(item.price instanceof Array ? 0 : item?.price, theme.productList.hideCurrencySymbol, theme.productList.hideTrailingZeros)}</Typography>
                                        </Grid>
                                    </Grid>
                                </>
                                :
                                <>
                                    <Grid item xs={hideDescription ? theme.productList?.hideDashedLine ? 10 : 8 : 10}>
                                        <Grid container justify="flex-start" style={{paddingRight: 5}} key={index}>
                                            <Typography variant="h4">{hideDescription ? item.name : item.desc}</Typography>
                                        </Grid>
                                    </Grid>
                                    {
                                    !theme.productList?.hideDashedLine &&
                                        <Grid item xs={hideDescription ? 2 : 1}>
                                            <div className={classes.dashedSingleLine}/>
                                        </Grid>
                                    }
                                    <Grid item xs={hideDescription ? 2 : 1}>  
                                        <Grid container justify="flex-end" key={index}>     
                                            <Typography variant="h5">{!(item?.price instanceof Array) ? item?.price?.toFixed(2) : "0"}</Typography>
                                        </Grid>
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </div>
                })}
            </div>
        </Editable>
    }
}

export default (StyleInjector(styles, "productList", true)(ProductList));
import React, { useEffect, useState } from "react";
import { AppState } from "../../../store";
import { FieldComponent, FieldEditor } from "../FloatingContentPanel";
import { useSelector } from "react-redux";
import { FormControlLabel, InputAdornment, makeStyles, Radio, RadioGroup, TextField } from "@material-ui/core"

const useStyles = makeStyles({
    label: {
        display: "block"
    }
});

interface IRuledLine{
    rootStyle?: any
    display?: string
}
interface RuledLineSectionConfig {}

interface IRuledLineEditor extends FieldEditor<RuledLineSectionConfig, IRuledLine> {}

export const RuledLineEditor: React.FC<IRuledLineEditor> = ({ config, field, onChangeField }) => {
    const menuState = useSelector((state: AppState) => state.menu);
    const classes = useStyles();
    const [showRuledLine, setShowRuledLine] = useState<string>(field?.data?.display ?? "block")

    useEffect(() => {
        onChangeField("mealDealRuledLine", {
            ...field.data,
            display: showRuledLine
        });
    }, [showRuledLine]);
    return(
        <div>
            <div style={{display: "flex", margin: "12px 0"}}>
                <RadioGroup
                    row
                    value={showRuledLine}
                    onChange={(e) => { 
                        setShowRuledLine(e.target.value as string)
                    }}
                >   
                    {
                        [{label: "Show", value: "block"}, {label: "Hide", value: "none"}].map((row) => {
                            return(
                                <FormControlLabel control={<Radio/>} value={row?.value} label={row?.label} style={{fontWeight: 900}}/>
                            )
                        })

                    }
                </RadioGroup>
            </div>
        </div>
    )
}

interface IRuledLineSection extends FieldComponent<IRuledLine> {}

export const RuledLine: React.FC<IRuledLineSection> = ({field}) => {
    const {rootStyle} = field?.data;
    const menuState = useSelector((state: AppState) => state.menu);
    let background = "#d7ccc6";
    let borderColor = "#000000"
    let display = field?.data?.display;
    // Set background and border same with SubHeading component
    if(menuState){
        if(menuState?.menu && menuState?.menu.length > 0){
            let floatingContentPanel2 = menuState?.menu[0]?.props?.items.filter((row: {props: {[key: string]: any}}) => row?.props?.id === "floatingContentPanel3");
            if(floatingContentPanel2 && floatingContentPanel2.length > 0){
                let fieldsGroups = floatingContentPanel2[0]?.props?.fieldGroups;
                if(fieldsGroups && fieldsGroups.length > 0){
                    let fields = fieldsGroups[0]?.fields;
                    if(fields && fields.length > 0){
                        let subHeading = fields.find((row: {name: string, component: string}) => row.component === "SubHeading");
                        if(subHeading){
                            background = subHeading?.data?.textColor;
                            if(["#0a498a", "#992227", "#2c5f48", "#06080d", "#48484a", "#488769", "#c4323a", "#235f8e"].includes(background)){
                                borderColor = "#FFFFFF";
                            }
                        }
                    }
                }
            }
        }
    }

    return(
        <div style={{
            ...rootStyle,
            border: `0.15em solid ${borderColor}`,
            background: background,
            display: `${display ?? "block"}`
        }}></div>
    )
}
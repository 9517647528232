import { FormControlLabel, InputAdornment, makeStyles, Radio, RadioGroup, TextField } from "@material-ui/core"
import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AppState } from "../../../store"
import { menuActions } from "../../../store/menu"
import { EditableModalContext } from "../../layout/EditableModal"
import { ContainerContext } from "../Container"
import { FieldComponent, FieldEditor } from "../FloatingContentPanel"
import { z, ZodType } from "zod";

/*
    priceMappings: [

    ]
*/

type SelectedInputType = "FREETEXT" | "PRICE" | "BLANK";
interface PriceSectionData {
    selectedInputType?: SelectedInputType 
    value?: string //If price, use root document price, otherwise use this value
    decimalStyle?: any
    currencyStyle?: any
    rootStyle?: any
}

interface PriceSectioConfig {

}

interface IPriceEditor extends FieldEditor<PriceSectioConfig, PriceSectionData> {

}

const useStyles = makeStyles({
    textEditorContainer: {
        height: 100
    },
    errorLabel: {
        
    }
})

export const PriceEditor: React.FC<IPriceEditor> = ({ config, field, onChangeField }) => {

    const menuState = useSelector((state: AppState) => state.menu);
    const price = ((menuState.menu ? (menuState.menu[0].props.price as number | undefined) : 0) ?? 0).toString();
    const { upsertEvent: addEvent, upsertValidation: addValidation } = useContext(EditableModalContext);
    const [priceText, setPriceText] = useState(price);
    const [touched, setTouched] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [selectedInputType, setSelectedInputType] = useState<SelectedInputType>(field.data.selectedInputType ?? "PRICE");


    const [freeText, setFreeText] = useState<string | undefined>(field.data.value);

    const dispatch = useDispatch();

    /*

    */

    useEffect(() => {
        let eventId = addEvent("save", "price-save", () => {
            if(selectedInputType == "PRICE"){
                onTextApply(priceText); //Price is controlled by root document and not the components data so we have to use a custom event
            }            
        })
    }, [priceText, selectedInputType, freeText]);

    useEffect(() => {
        addValidation({
            id: "price",
            currentValue: priceText,
            onInvalid: (message) => { console.log(message); setErrorMessage(message.issues[0].message); },
            onValid: () => { setErrorMessage(undefined); },
            touched: touched,
            schema: z.preprocess(
                (val) => { 
                    const processed = z.string().transform(Number).safeParse(val);
                    return processed.success ? processed.data : val;
                }, z.number({ invalid_type_error: "Invalid price" }).step(0.01, "Invalid price"))   //Will throw an error if price is not divisible evenly by 0.01 (i.e. 2.123)
        })

    }, [priceText, touched])

    useEffect(() => {
        onChangeField("mealDealPrice", {
            ...field.data,
            selectedInputType: selectedInputType,
            value: freeText
        })
    }, [selectedInputType, freeText])


    const onTextApply = (value: string) => {
        /*
            '' = 0
            if whole (2.00), render as 2
            if only 1 decimal, render as 2 decimals
        */
        if(value == ""){
            value = "0";
        }
        let numValue = parseFloat(value);
        value = numValue.toFixed(Number.isInteger(numValue) ? 0 : 2);
        const dataMapping = {
            price: value
        }
        dispatch(menuActions.editSection("document", dataMapping));
    }

    const onPriceTextChange = (value: string) => {
        setTouched(true);
        setPriceText(value);
    }

    const onFreeTextChange = (value: string) => {
        setTouched(true);
        setFreeText(value);
    }

    const classes = useStyles();

    /*
        inputType: "PRICE" | "FREETEXT"
        value: ""

    */

    return(
        <div className={classes.textEditorContainer}>
            <RadioGroup
                row
                value={selectedInputType}
                onChange={(e) => { 
                    setSelectedInputType(e.target.value as any)
                 }}
            >
                <FormControlLabel control={<Radio/>} value={"PRICE"} label={"Price"} />
                <FormControlLabel control={<Radio/>} value={"FREETEXT"} label={"Free text"} />
                <FormControlLabel control={<Radio />} value={"BLANK"} label={"Blank"} />
            </RadioGroup>
            {
                selectedInputType == "PRICE" &&
                <TextField id={field.name} error={errorMessage != undefined} helperText={errorMessage} label={"Price"} value={priceText} onChange={(e) =>{ onPriceTextChange(e.target.value); }} 
                    InputProps={{
                        startAdornment: <InputAdornment position="start">£</InputAdornment>
                    }}
                />
            }
            {
                selectedInputType == "FREETEXT" && 
                <TextField id={field.name} error={errorMessage != undefined} helperText={errorMessage} label={"Free Text"} value={freeText} onChange={(e) =>{ onFreeTextChange(e.target.value); }} />
            }
        </div>
    )
}

interface IPriceSection extends FieldComponent<PriceSectionData> {

}



export const PriceSection: React.FC<IPriceSection> = ({ field, scaledPosition }) => {

    const { currencyStyle, decimalStyle, rootStyle, selectedInputType, value } = field.data;

    const freeTextRef = useRef<HTMLSpanElement>(null);

    const menuState = useSelector((state: AppState) => state.menu);
    const price = ((menuState.menu ? (menuState.menu[0].props.price as number | undefined) : 0) ?? 0).toString();

    const [whole, decimal] = useMemo(() => { return (price ?? "").split("."); }, [price]);

    /*
    useEffect(() => {

            if(freeTextRef.current && value && value.length > 0){
            
                const desiredWidth = scaledPosition.width * 0.70;
                const maximumHeight = scaledPosition.height * .35;
                let fontSize = 1;
                
                let maxFontSize = 100;

                console.log(freeTextRef.current.style.fontSize);


                while(freeTextRef.current.offsetWidth < desiredWidth && freeTextRef.current.offsetHeight < maximumHeight && fontSize < maxFontSize){
                    //fontSize++;
                    //console.log(freeTextRef.current.offsetHeight, freeTextRef.current.offsetWidth, desiredWidth, maximumHeight, scaledPosition.width, scaledPosition.height, fontSize);
                    //freeTextRef.current.style.fontSize = `${fontSize}px`; // This does not work when gerating the PDF
                }
            }
        
    }, [freeTextRef, scaledPosition, value])
    */

    return(
        <div style={{...rootStyle, fontSize: selectedInputType == "FREETEXT" ? "4em" : rootStyle.fontSize}}>
            {
                (selectedInputType == "PRICE" || !selectedInputType) &&
                <>
                    <span style={{...currencyStyle}}>£</span>
                    <span>{whole}</span>
                    {
                        decimal &&
                        <span style={{...decimalStyle}}>.{decimal}</span>
                    }
                </>
            }
            {
                selectedInputType == "FREETEXT" && 
                <>
                    <span style={{
                        whiteSpace: "nowrap",
                        textAlign: "center"
                    }} ref={freeTextRef}>{value}</span>
                </>
            }
        </div>
    )
}
import React from "react";
import { InputAdornment, TextField } from "@material-ui/core";

function PriceField(props: any) {
    const { onChange, ...otherProps } = props;

    const zeroCheck = (ev: any) => {
        //Parse the value and use toString instead of toFixed to chop the zeros off but only if they're useless
        var num = parseFloat(ev.target.value);
        var val = isNaN(num) ? ev.target.value : num.toString();
        ev.target.value = val;
    }

    const priceChange = (ev: any) => {
        if (!onChange) return;

        //Retain dot index
        var dotIndex = ev.target.value.indexOf(".");
        var val = ev.target.value.replace(".", "");

        //User keeps adding numbers and the dotIndex is only at one so move the decimal point up
        if (dotIndex === 1 && val.length >= 4)
            dotIndex++;

        //Limit to 4 and remove decimal place
        val = val.substring(val.length - 4, val.length);

        //Add it back
        if (val.length > 2 || dotIndex !== -1) {
            if (dotIndex === -1)
                dotIndex = 2;

            val = val.substring(0, dotIndex) + "." + val.substring(dotIndex, 4);
        }

        //Parse as a number
        var price = parseFloat(val);

        //Something is wrong
        if (isNaN(price))
            price = 0;

        ev.target.value = dotIndex !== -1 ? val : price.toString();
        onChange(ev);
    }

    return <TextField
        type="number"
        variant="filled"
        hiddenLabel={true}
        InputProps={{
            disableUnderline: true,
            startAdornment: <InputAdornment position="start">£</InputAdornment>,
            inputProps: { min: 0, max: 1000, step: "0.01" }
        }}
        onChange={priceChange}
        //onKeyDown={zeroCheck}
        {...otherProps}
    />
};

export default PriceField;
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'


import './table.css'

import {
    Column,
    Table,
    useReactTable,
    ColumnFiltersState,
    getCoreRowModel,
    getFilteredRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFacetedMinMaxValues,
    getPaginationRowModel,
    sortingFns,
    getSortedRowModel,
    FilterFn,
    SortingFn,
    ColumnDef,
    flexRender,
} from '@tanstack/react-table'

import {
    RankingInfo,
    rankItem,
    compareItems,
} from '@tanstack/match-sorter-utils'

declare module '@tanstack/table-core' {
    interface FilterMeta {
        itemRank: RankingInfo
    }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value)

    // Store the itemRank info
    addMeta({
        itemRank,
    })

    // Return if the item should be filtered in/out
    return itemRank.passed
}

function generateArrow(isDown: boolean, isGrey: boolean) {
    return <svg style={{ transform: isDown ? "rotate(180deg)" : "", margin: "1px 0" }} xmlns="http://www.w3.org/2000/svg" version="1.1" width="10px" height="6px">
        <g transform="matrix(1 0 0 1 -140 -403 )">
            <path d="M 9.814453125 4.864583333333333  C 9.938151041666668 4.996527777777776  10 5.152777777777777  10 5.333333333333333  C 10 5.513888888888888  9.938151041666668 5.670138888888888  9.814453125 5.802083333333333  C 9.690755208333334 5.934027777777779  9.544270833333334 6  9.375 6  L 0.625 6  C 0.45572916666666674 6  0.3092447916666667 5.934027777777779  0.185546875 5.802083333333333  C 0.06184895833333333 5.670138888888888  0 5.513888888888888  0 5.333333333333333  C 0 5.152777777777777  0.06184895833333333 4.996527777777776  0.185546875 4.864583333333333  L 4.560546875 0.19791666666666696  C 4.684244791666668 0.06597222222222143  4.830729166666668 0  5 0  C 5.169270833333334 0  5.315755208333334 0.06597222222222143  5.439453125 0.19791666666666696  L 9.814453125 4.864583333333333  Z " fillRule="nonzero" fill={ isGrey ? "grey" : "black" } stroke="none" transform="matrix(1 0 0 1 140 403 )" />
        </g>
    </svg>
}

function generateArrows(isDown: boolean, isActive: boolean) {
    return <div style={{display: "inline-flex", flexDirection: "column", marginRight: 8} }>
        {generateArrow(false, !isActive || !isDown)}
        {generateArrow(true, !isActive || isDown)}
    </div>
}

function TableWrapper(props: TableProps) {

    const cols: any = [];


    for (let i = 0; i < props.headings.length; i++) {
        cols.push(
            {
                accessorKey: props.keys[i],
                cell: (info: any) => info.getValue() ?? '',
                header: props.headings[i]
            }
        )
    }

    const columns = React.useMemo<ColumnDef<any>[]>(
        () => cols,
        []
    )

    const [data, setData] = React.useState(() => props.data);

    const refreshData = () => { setData((old: any) => props.data); }

    useEffect(() => {
        table.setPageSize(props.pageSize);
    },[props.pageSize]);

    useEffect(() => {
        refreshData();
    })

    const table = useReactTable({
        data,
        columns,
        state: {
            columnFilters: props.columnFilters,
            globalFilter: props.searchTerm,
        },
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        getColumnCanGlobalFilter: col => props.searchableColumns.includes(col.id),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    });

    const totalWidth = props.columnWidths.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0
    );


    return (
        <div>
            <table className="POS-Table">
                <thead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => {
                                return (
                                    <th key={header.id} colSpan={header.colSpan} width={`${(props.columnWidths[props.keys.indexOf(header.column.id)] / totalWidth) * 100}%`}>
                                        {header.isPlaceholder || props.noArrowColumns.includes(header.id) ? null : (
                                            <>
                                                <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                                                    {...{
                                                        //onClick: header.column.getToggleSortingHandler(),
                                                    //onClick: header.getContext().table.getColumn("paperSize").getToggleSortingHandler()
                                                    onClick: props.customSortValues.map(sortPairs => sortPairs.visibleColumn).includes(header.column.id) ?
                                                        header.getContext().table.getColumn(props.customSortValues[props.customSortValues.findIndex(sortPair => sortPair.visibleColumn === header.column.id)].hiddenColumn).getToggleSortingHandler() :
                                                            header.column.getToggleSortingHandler()
        
                                                }}


                                                >

                                                    {{
                                                        asc: generateArrows(true, true),
                                                        desc: generateArrows(false, true),
                                                    }[props.customSortValues.map(sortPairs => sortPairs.visibleColumn).includes(header.column.id) ? header.getContext().table.getColumn(props.customSortValues[props.customSortValues.findIndex(sortPair => sortPair.visibleColumn === header.column.id)].hiddenColumn).getIsSorted() : header.column.getIsSorted() as string] ?? generateArrows(false, false)}

                                                    {flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext()
                                                    )}

                                                  
                                                </div>
                                            </>
                                        )}
                                    </th>
                                )
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map(row => {

                        let className = "";

                        if (props.onRowClick !== undefined) {
                            className += "hasLink"
                        }

                        if(props.type){
                            className += ` ${props.type}`
                        }

                        if (row.original[props.disableIfColumnNotNull] !== null && props.disableIfColumnNotNull !== undefined) {
                            className += " isDisabled"
                        }


                        return (
                            <tr key={row.id}
                                className={className + (props.selectedRow === row.index ? " selected" : "")}
                                onClick={() => { if (props.onRowClick !== undefined && (row.original[props.disableIfColumnNotNull] === null || props.disableIfColumnNotNull === undefined)) { props.onRowClick(row.original, row.index); } }} >
                                {row.getVisibleCells().map(cell => {
                                    return (
                                        <td key={cell.id} >
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            ) ?? null}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}

                    {
                        table.getRowModel().rows.length === 0 && <tr className="empty-row"><td colSpan={1000}>No results matched those parameters.</td></tr>
                    }
                </tbody>
            </table>
            <div />
            <br/>
            <div className="POS-Table-Pagination" style={{display: props.showPagination ? "flex" : "none"}}>

                <div>
                    <button
                        onClick={() => table.setPageIndex(0)}
                        disabled={!table.getCanPreviousPage()}
                    >
                        {'<<'}
                    </button>
                    <button
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                    >
                        {'<'}
                    </button>

                    <span className="page-number">{table.getState().pagination.pageIndex + 1}</span>

                    <button
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}
                    >
                        {'>'}
                    </button>
                    <button
                        onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                        disabled={!table.getCanNextPage()}
                    >
                        {'>>'}
                    </button>
                </div>
                <br />
                <span>
                    Go to page:
                    <input
                        type="number"
                        defaultValue={table.getState().pagination.pageIndex + 1}
                        onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            table.setPageIndex(page)
                        }}
                    /> of <b>{table.getPageCount()}</b>
                </span>

            </div>
            {/*<pre>{JSON.stringify(table.getState(), null, 2)}</pre>*/}
        </div>
    )
}

interface TableProps {
    data: any;
    headings: string[]
    keys: string[];

    searchTerm: string;

    columnFilters: ColumnFiltersState;

    searchableColumns: string[];

    pageSize: number;

    onRowClick: (data: any, index: number) => void | undefined;

    noArrowColumns: string[],

    disableIfColumnNotNull: string | undefined,

    columnWidths: number[],

    customSortValues: { visibleColumn: string, hiddenColumn: string }[]

    selectedRow: number;

    showPagination: boolean;

    type?: string;

}

TableWrapper.defaultProps = {
    searchTerm: "",
    columnFilters: [],
    searchableColumns: [],
    pageSize: 1000000,
    onRowClick: undefined,
    noArrowColumns: [],
    disableIfColumnNotNull: undefined,
    customSortValues: [],
    selectedRow: -1,
    showPagination: true
}

export default TableWrapper;
import React, { CSSProperties, useState } from "react";
import Field, { FormikForm } from "../field/Field";
import Validation from "../../../utils/validationFunctions"
import * as Yup from 'yup';
import { useFormik } from 'formik';
import PosButton from "../posButton/PosButton";

import { VoucherDTO, VoucherOverlayResponse, VoucherOverlayForm } from "../../../pages/POS/VouchersPage";

const outerContainerStyles: CSSProperties = {
    position: "fixed",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: 0,
    right: 0,
    padding: 8,
    boxSizing: "border-box",
    zIndex: 2,
    backgroundColor: "rgba(0, 0, 0, 0.6)"
}

const innerContainerStyles: CSSProperties = {
    maxWidth: 800,
    width: "100%",
    backgroundColor: "white",
    borderRadius: 10,
    boxShadow: "5px 5px 5px 0px rgba(0, 0, 0, 0.35)",
    border: "1px #797979 solid",
    boxSizing: "border-box",
    padding: 40
}

function VoucherOverlay(props: VoucherOverlayProps) {

    let initialValues: VoucherOverlayForm;

    if (props.voucher === undefined) {
        initialValues = {
            voucherId: -1,
            code: '',
            validFrom: '',
            validTo: '',
            amount: 0,
            description: '',
            assignedTo: [],
            disabled: false,
            htmlValidFrom: '',
            htmlValidTo: ''
        }
    } else {
        initialValues = {
            ...props.voucher,
            htmlValidFrom: formatDate(props.voucher.validFrom),
            htmlValidTo: formatDate(props.voucher.validTo),
            description: props.voucher.description ?? ''
        }
    }

    const [isInUse, setIsInUse] = useState(!initialValues.disabled);


    const voucherUpdateSchema = Yup.object().shape({
        code: Yup.string()
            .required(Validation.errorMessages.required)
            .test('code', 'You must use a unique voucher name',
                value => !props.existingCodes.includes(value ?? '') || props.voucher !== undefined),
        htmlValidFrom: Yup.date().required('You must set a start date for this voucher'),
        htmlValidTo: Yup.date()
            .required('You must set an end date for this voucher')
            .test('htmlValidTo', 'Voucher period must end after it has started.', value => new Date(value).getTime() > new Date(formik.values.htmlValidFrom).getTime()),
        description: Yup.string().max(250, 'Description can be no more than 250 characters.').required('Please enter a description for this voucher'),
        assignedTo: Yup.mixed(),
        disabled: Yup.bool(),
    });

    const sendVoucher = (method: "post" | "put", formData: VoucherDTO) => {
        props.save({ pending: true, ...formData });
        props.cancel();

        fetch(`${process.env.PUBLIC_URL}/api/vouchers/`, {
            method: method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },

            body: JSON.stringify(formData)
        }).then(async (result) => {
            props.save(await result.json());
        })
    }

    const handleMultiselect = (value: number[]) => {
        formik.setFieldValue('assignedTo', value);
    }

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: values => {

            const dto: VoucherDTO = {
                voucherId: values.voucherId,
                code: values.code,
                validFrom: new Date(values.htmlValidFrom).toISOString().split('Z')[0],
                validTo: new Date(values.htmlValidTo).toISOString().split('Z')[0],
                amount: values.amount,
                description: values.description === '' ? null : values.description,
                disabled: isInUse ? null : true,
                assignedTo: values.assignedTo
            };

            if (props.voucher === undefined) {
                sendVoucher('post', dto);
            } else {
                sendVoucher('put', dto);

            }
        },
        enableReinitialize: true,
        validationSchema: voucherUpdateSchema,
        validateOnMount: props.voucher !== undefined,
        validateOnChange: true
    });

    const form: FormikForm = {
        ...formik,
        allDisabled: false
    }

    return <div style={outerContainerStyles}>
        <div style={innerContainerStyles}>
            <h2 style={{marginTop: 0, fontSize: 32}}>{props.voucher === undefined ? "Generate new voucher" : "Edit existing voucher"}</h2>
            <p>Vouchers </p>
            <form onSubmit={(e) => { console.log(formik.errors); formik.handleSubmit(e) }}>
                <table style={{width: "100%"}}><tbody>

                    <Field
                        id="code"
                        title="Voucher:"
                        specialType={props.voucher === undefined ? undefined : "readonly"}
                        form={form}
                        
                        leftAlignLabels
                        forceLabelWidth={100 }
                    />
                    <Field forceLabelWidth={100} title="Start date:" leftAlignLabels id="htmlValidFrom" forceMaxWidth={160} type="date" form={form}  />
                    <Field forceLabelWidth={100} title="End date:" leftAlignLabels id="htmlValidTo" forceMaxWidth={160} type="date" form={form} />
                    <Field forceLabelWidth={100} title="Description:" leftAlignLabels id="description" form={form} forceMaxWidth={800} />
                    <Field
                        forceLabelWidth={100}
                        title="Assigned to:"
                        leftAlignLabels
                        id="assignedTo"
                        specialType="multiselect"
                        form={form}
                        onChange={handleMultiselect}
                    />

                    <tr style={{height: 8} }></tr>

                    <tr>
                        <td className="Field-label-container left-align" style={{ maxWidth: 100, verticalAlign: "top", paddingTop: 9}} >
                            <label>In use:</label>
                        </td>

                        <td></td>
                        <td>
                            <input className="Field-input" value="test" type="checkbox" onChange={e => { setIsInUse(!isInUse) }} checked={isInUse} />

                            <div style={{ float: "right", display: "flex", flexWrap: "wrap", justifyContent: "flex-end" }}>
                                
                                <PosButton isSecondary onClick={props.cancel} text="Cancel"></PosButton>
                                <PosButton type="submit" text="Save"></PosButton>
                            </div>
                        </td>
                    </tr>

                </tbody></table>

                
            </form>

        </div>
    </div>
}



interface VoucherOverlayProps {
    voucher: VoucherDTO | undefined;
    cancel: () => void;
    save: (data: VoucherOverlayResponse) => void;
    existingCodes: string[];
}

function formatDate(date: string | null): string {

    if (date === '' || date === null)
        return '';

    const d = new Date(date);

    return `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')}`
}


export default VoucherOverlay;
import React, { useEffect } from "react"
import { Button, Checkbox, Grid, Table, TableBody, TableCell, TableHead, TableRow, withStyles, Tooltip, FormControlLabel, Paper, Chip } from "@material-ui/core";
import { AddCircleOutlined, ExpandLess, ExpandMore, DeleteForever, DragIndicator } from "@material-ui/icons";
import IProduct from "../../interfaces/IProduct";
import TextField from "../form/TextField";
import PriceField from "../form/PriceField";
import ImageUpload from "../form/ImageUpload";
import AddProduct from "../dialogs/AddProduct";
import AddBritvicProduct from "../dialogs/AddBritvicProduct";
import { NotChecked as NotCheckedDrag } from "../decorators/Draggable";
import { NotChecked as NotCheckedDrop } from "../decorators/Droppable";
import ILibraryImage from "../../interfaces/ILibraryImage";
import ICustomTheme from "../../interfaces/ICustomTheme";
import AddGinProduct from "../dialogs/AddGinProduct";
import { useDispatch } from "react-redux";
import { menuOperations } from "../../store/menu";
import { Autocomplete } from "@material-ui/lab";

//Sub products don't have an expandable row
const ExpandableRow = (props: any) => {
    const { connectDragPreview, dragging, over, children, isExpandable } = props;

    return isExpandable ? <TableRow ref={connectDragPreview}>
        <TableCell colSpan={6}>
            <Table style={{ opacity: dragging ? 0.2 : over ? 0 : 1 }}>
                <TableBody>
                    {children}
                </TableBody>
            </Table>
        </TableCell>
    </TableRow> : children;
}

//Reusable product row, bit messy...
const ProductRow = (props: any) => {
    const { classes, onChange, opened, setOpened, onDelete, fileUploaded, hideFeatured, modalState, imageSelected, connectDragPreview, connectDragTarget, dragging, connectDropTarget, product, index,
        lastProduct, over, priceNum, columnAdded, newColumn, onChangeNewColumn, columnDeleted, showSize, showModal, showTooltip, hideTooltip, tooltip,
        featuredAnywhere, hideColumns, hideWithImage, hideDelete, hideDropdownOptions } = props;

    //debugger;

    return <ExpandableRow connectDragPreview={connectDragPreview} isExpandable={opened} dragging={dragging} over={over}>
        <TableRow>
            <TableCell ref={node => connectDragTarget(connectDropTarget(node))} style={{ padding: "10px 0 0 0", cursor: "pointer" }} width="15">
                <Tooltip arrow placement="top-start" title="Click and drag to reorder"><DragIndicator /></Tooltip>
            </TableCell>

            {!hideDelete &&
            <TableCell width="45">
                <Tooltip arrow placement="bottom-start" title="Delete a product"><DeleteForever style={{ cursor: "pointer" }} onClick={() => onDelete(product)} /></Tooltip>
            </TableCell>
            }

            <TableCell>
                <TextField disabled={product.isBritvicProduct || product.isGinProduct} fullWidth onChange={onChange} name={`products.${index}.name`} placeholder={product.placeholder?.name} value={product.name} />
            </TableCell>

            {product.showColumns === true ? <TableCell width="150"></TableCell> :
                priceNum === undefined && <TableCell width="150">
                <PriceField name={`products.${index}.price`} value={product.price} placeholder={product.placeholder?.price} onChange={onChange} />
            </TableCell>}

            {priceNum > 0 && Array(priceNum).fill(0).map((n: any, pIndex: number) => (
                <TableCell width={100}>
                    <PriceField name={`products.${index}.price[${pIndex}]`} value={product.price[pIndex]} placeholder={product.placeholder?.price} onChange={onChange} />
                </TableCell>
            ))}

            {!hideFeatured && <TableCell width="100">
                <Checkbox className={classes.featuredCheck} onChange={onChange} name={`products.${index}.highlighted`} checked={product.highlighted ?? false} />
                {(!hideFeatured && lastProduct && !hideWithImage) && <span className={classes.withImage}>with<br />image</span>}
            </TableCell>}

            {(!hideDropdownOptions && opened) && <TableCell width="60" className={classes.expand}>
                {opened.indexOf(index) === -1 ?
                    <Tooltip open={tooltip === index} onClose={hideTooltip} onOpen={() => showTooltip(index)} arrow placement="left" title="Click to see more"><ExpandMore onClick={() => setOpened(index)} /></Tooltip> :
                    <Tooltip open={tooltip === index} onClose={hideTooltip} onOpen={() => showTooltip(index)} arrow placement="left" title="Click to see less"><ExpandLess onClick={() => setOpened(index, false)} /></Tooltip>}
            </TableCell>}
        </TableRow>

        {(!hideDropdownOptions && opened) && <TableRow className={opened.indexOf(index) === -1 ? classes.hideDesc : ""}>
            <TableCell colSpan={2}></TableCell>
            <TableCell colSpan={hideFeatured ? 2 : 3}>
                <Grid container>
                    {
                        !hideColumns &&                    
                        <Grid item xs={12}>
                            <FormControlLabel classes={{ label: classes.columnsLabel }} control={<Checkbox size="small" onChange={onChange} name={`products.${index}.showColumns`} checked={product.showColumns ?? false} />} label="Show columns?" />
                        </Grid>
                    }

                    {product.showColumns ? <Grid item xs={12}>
                        <label>Columns</label>

                        <Paper component="ul" className={classes.chipContainer}>
                            {product.columns?.map((column: string, index: number) => (
                                <li key={index}>
                                    <Chip
                                        label={column}
                                        className={classes.chip}
                                        onDelete={() => columnDeleted(product, index)} />
                                </li>
                            ))}

                            {(!product.columns || product.columns?.length < 5) && <li className={classes.chipAdd}>
                                <TextField
                                    InputProps={{
                                        disableUnderline: true,
                                        inputProps: { minLength: 1, maxLength: 10 }
                                    }} margin="none" variant="outlined" value={newColumn} onChange={onChangeNewColumn} />
                                <Button startIcon={<AddCircleOutlined />} variant="text" onClick={() => columnAdded(product)}>Add Column</Button>
                            </li>}
                        </Paper>

                        <label style={{ marginTop: "1rem", marginBottom: 0 }}>Sub products</label>

                        <Table className="subProductTable">
                            <TableHead>
                                <TableRow>
                                    <TableCell width="15"></TableCell>
                                    <TableCell width="60"></TableCell>
                                    <TableCell width="auto">Product name *</TableCell>
                                    {product.columns?.map((column: string) => <TableCell>{column}</TableCell>)}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {product.subItems?.map((subProduct: IProduct, subIndex: number) => (
                                    <ProductRow
                                        classes={classes}
                                        key={subIndex}
                                        product={subProduct}
                                        index={subIndex}
                                        lastProduct={false}
                                        modalState={false}
                                        hideFeatured={true}
                                        connectDragPreview={() => { }}
                                        connectDragTarget={() => { }}
                                        dragging={false}
                                        connectDropTarget={() => { }}
                                        over={false}
                                        onChange={(ev: any) => {
                                            //This is a ref so do once
                                            if (ev.target.name.indexOf("subItems") === -1) {
                                                var subItem = ev.target.name.replace("products", "subItems")
                                                ev.target.name = `products.${index}.${subItem}`;
                                            }

                                            onChange(ev);
                                        }}
                                        priceNum={product.columns?.length}
                                    />
                                ))}
                            </TableBody>
                        </Table>

                        <Button className={classes.addProduct} variant="text" startIcon={<AddCircleOutlined />} onClick={(ev: any) => showModal(ev, index)}>Add product</Button>

                    </Grid> : !showSize && <Grid item xs={12}>
                        <label>Product description</label>
                        <TextField disabled={product.isBritvicProduct || product.isGinProduct} fullWidth multiline rows={5} name={`products.${index}.desc`} value={product.desc} placeholder={product.placeholder?.desc} onChange={onChange} />
                    </Grid>}

                    {showSize && <Grid item xs={12}>
                        <label>Product size or %ABV. (Maximum 8 characters.)</label>
                        <TextField name={`products.${index}.size`} value={product.size} placeholder={product.placeholder?.size} onChange={onChange} inputProps={{ maxLength: 8 }}/>
                    </Grid>}

                    {!hideFeatured && lastProduct && product.highlighted && !hideWithImage && <Grid item xs={12}>
                        <label>Featured text</label>
                        <TextField fullWidth name={`products.${index}.highlightedText`} value={product.highlightedText} onChange={onChange} />
                    </Grid>}

                    {!hideFeatured && !hideWithImage && (featuredAnywhere || lastProduct) && product.highlighted && <Grid item xs={12} style={{ marginTop: 10 }}>
                        <ImageUpload
                            modalState={modalState}
                            dataMapping={product}
                            imageSelected={imageSelected}
                            fileUploaded={fileUploaded} />
                    </Grid>}
                </Grid>
            </TableCell>
        </TableRow>}
    </ExpandableRow>
}

const ProductList = (props: any) => {
    const { classes, dataMapping, showModal, showBritvicModal, hideFeatured, moveItem, productLimit, defaultHighlighted, showGinProducts, showGinModal, hideAddProducts, opened, hideDelete, hideDropdownOptions, productsSubtext } = props;
    const products = dataMapping["products"];    

    return <Grid container>
        <Grid item xs={12}>
            <div style={{ display: "flex", flexDirection: "column"}}>
                <div>
                    <strong>Products</strong>&nbsp;(Click and drag <DragIndicator fontSize="small"/> button to reorder products)
                </div>
                {
                    productsSubtext &&
                    <div>
                        {productsSubtext}
                    </div>
                }
            </div>
        </Grid>

        <Grid item xs={12}>
            <Table className={classes.productTable}>
                <TableHead>
                    <TableRow>
                        <TableCell width="15"></TableCell>
                        {
                            !hideDelete &&                        
                            <TableCell width="60"></TableCell>
                        }
                        <TableCell>Product name *</TableCell>
                        <TableCell width="150">Price *</TableCell>
                        {!hideFeatured && <TableCell width="110">
                            Featured?
                            <Tooltip disableFocusListener={true} disableTouchListener={true} arrow placement="top-end" title="A featured product will be highlighted with a background colour or an outline, depending on the chosen style. Use this sparingly to draw attention to specific products on your menu."><div className={classes.help}>?</div></Tooltip>
                        </TableCell>}
                        {opened &&
                        <TableCell width="60"></TableCell>}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {products && products.length > 0 ? products.map((product: IProduct, index: number) => {
                        //Old menus won't have this flag set
                        if (!product.showColumns && product.columns && product.columns.length > 0)
                            product.showColumns = true;

                        return <NotCheckedDrop renderOver={() => { }} index={index} key={index} id={"" + index}>
                            {(connectDropTarget: any, over: boolean) => (
                                <NotCheckedDrag index={index} id={"" + index} end={moveItem}>
                                    {(connectDragTarget: any, connectDragPreview: any, dragging: boolean) => (
                                        <ProductRow
                                            hideColumns={showGinProducts}
                                            hideDelete={hideDelete}
                                            hideWithImage={showGinProducts}
                                            hideDropdownOptions={hideDropdownOptions}
                                            key={index}
                                            index={index}
                                            product={product}
                                            lastProduct={index === (products.length - 1)}
                                            connectDragTarget={connectDragTarget}
                                            connectDropTarget={connectDropTarget}
                                            connectDragPreview={connectDragPreview}
                                            dragging={dragging}
                                            over={over}
                                            opened={opened}
                                            {...props} />
                                    )}
                                </NotCheckedDrag>
                            )}
                        </NotCheckedDrop>
                    }) : <TableRow className={classes.empty}>
                            <TableCell colSpan={5}>
                                No products have been added
                            </TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
        </Grid>

        <Grid item xs={12}>
            {(products ? products.length < productLimit || !productLimit : true) && 
                <React.Fragment>
                    {
                        showGinProducts 
                        ?
                            <Button className={classes.addProduct} variant="text" startIcon={<AddCircleOutlined />} onClick={showGinModal}>Add product</Button>
                        :
                        <>
                            {
                                !hideAddProducts && 
                                <Button className={classes.addProduct} variant="text" startIcon={<AddCircleOutlined />} onClick={showModal}>Add product</Button>
                            }
                            <Button className={classes.addProduct} variant="text" startIcon={<AddCircleOutlined />} onClick={showBritvicModal}>Add Britvic product</Button>
                        </>
                    }
                </React.Fragment>}
        </Grid>
    </Grid>
}

const StyledProductList = withStyles((theme: ICustomTheme) => ({
    productTable: {
        "& thead th": {
            padding: 10
        },
        "& tr > td": {
            padding: 0,
            paddingLeft: 12,
            paddingBottom: 10,
            paddingTop: 10,
            "& table td": {
                borderBottom: "0",
                padding: "10px 10px 0 10px"
            }
        },
        "& td .MuiFormControl-root": {
            marginBottom: 0
        },
        "& .subProductTable tr .MuiFormControl-root": {
            marginBottom: 0
        },
        "& label": {
            display: "block",
            marginBottom: 10
        }
    },
    empty: {
        "& > td": {
            paddingTop: "10px !important",
            textAlign: "center" as "center"
        }
    },
    hideDesc: {
        display: "none"
    },
    addProduct: {
        marginLeft: 10,
        padding: "5px 10px",
        "& svg": {
            fontSize: "35px !important"
        }
    },
    expand: {
        cursor: "pointer" as "pointer",
        "& svg": {
            fontSize: 40
        }
    },
    help: {
        width: 15,
        height: 15,
        color: "#FFF",
        backgroundColor: "#000",
        borderRadius: "50%",
        display: "inline-block",
        marginLeft: 7,
        lineHeight: "15px",
        textAlign: "center"
    },
    withImage: {
        textAlign: "center",
        fontSize: 12,
        display: "inline-block",
        verticalAlign: "middle"
    },
    featuredCheck: {
        "&.Mui-checked + span": {
            color: "#007D7A"
        }
    },
    chipContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
        alignItems: "center"
    },
    chip: {
        margin: theme.spacing(0.5)
    },
    columnsLabel: {
        fontSize: "0.875rem"
    },
    chipAdd: {
        marginLeft: "auto",
        alignItems: "center",
        display: "flex",
        "& input": {
            padding: "10px 10px",
            maxWidth: 100
        },
        "& button": {
            marginLeft: 10,
            marginRight: 10,
            lineHeight: "17px",
            fontSize: 14
        }
    }
}))(ProductList);

const EditableProducts = (props: any) => {
    const { state, onChange, dataMapping, hideFeatured, featuredAnywhere, productLimit,  showGinProducts, ginFilter, hideAddProducts, hideDelete, hideDropdownOptions, hideDescription, productsSubtext, defaultHighlighted } = props;
    const { opened, showAddGinProduct = false, showAddProduct = false, newProduct, showAddBritvicProduct = false, newColumn = "", addingSubProduct = -1, tooltip = -1 } = state.editableState;

    return <React.Fragment>
        <AddProduct addingSubProduct={addingSubProduct} show={showAddProduct} newProduct={newProduct} onClose={() => {
            state.onSetState({ editableState: { ...state.editableState, newProduct: {}, showAddProduct: false, addingSubProduct: -1 } });
        }} onSave={() => {
            //It's an index
            if (addingSubProduct >= 0) {
                dataMapping["products"][addingSubProduct].subItems.push(newProduct);
                //debugger;
                //Hide modal and reset obj
                state.onSetState({
                    editableState: { showAddProduct: false, newProduct: {}, opened, addingSubProduct: -1 },
                    dataMapping
                })
            } else {
                dataMapping["products"].push(newProduct);
                //debugger;
                //Hide modal and reset obj
                state.onSetState({
                    editableState: { showAddProduct: false, newProduct: {}, opened },
                    dataMapping
                })
            }
        }} />

        <AddBritvicProduct hideDescription={hideDescription} state={state} show={showAddBritvicProduct} onClose={() => {
            state.onSetState({ editableState: { ...state.editableState, newProduct: {}, showAddBritvicProduct: false } });
        }} onSave={() => {
            newProduct.isBritvicProduct = true;
            dataMapping["products"].push(newProduct);

            //Hide modal and reset obj
            state.onSetState({
                editableState: { showAddBritvicProduct: false, newProduct: {}, opened },
                dataMapping
            })
        }} />

        <AddGinProduct state={state} show={showAddGinProduct} filter={ ginFilter} onClose={() => {
            state.onSetState({ editableState: { ...state.editableState, newProduct: {}, showAddGinProduct: false }});
        }} onSave={() => {
            //debugger;
            newProduct.isGinProduct = true;
            newProduct.highlighted = defaultHighlighted ;
            dataMapping["products"].push(newProduct);

            state.onSetState({
                editableState: { showAddGinProduct: false, newProduct: {}, opened },
                dataMapping
            })
        }} />

        <StyledProductList
            productsSubtext={productsSubtext}
            hideAddProducts={hideAddProducts}
            hideDelete={hideDelete}
            hideDropdownOptions={hideDropdownOptions}
            onChange={onChange}
            opened={opened}
            hideFeatured={hideFeatured}
            featuredAnywhere={featuredAnywhere}
            dataMapping={dataMapping}
            showGinProducts={showGinProducts}
            tooltip={tooltip}
            showTooltip={(index: number) => {
                state.onSetState({ editableState: { ...state.editableState, tooltip: index } });
            }}
            hideTooltip={() => {
                state.onSetState({ editableState: { ...state.editableState, tooltip: -1 } });
            }}
            showModal={(ev: any, subProduct: number) => {
                state.onSetState({ editableState: { ...state.editableState, showAddProduct: true, addingSubProduct: subProduct } });
            }}
            showBritvicModal={(subProduct: number) => {
                state.onSetState({ editableState: { ...state.editableState, showAddBritvicProduct: true, addingSubProduct: subProduct } });
            }}
            showGinModal={(subProduct: number) => {
                state.onSetState({ editableState: { ...state.editableState, showAddGinProduct: true, addingSubProduct: subProduct}})
            }}
            modalState={state}
            showSize={dataMapping["showSize"]}
            productLimit={productLimit}
            onChangeNewColumn={(ev: any) => {
                //Set new column name text, annoying we can't have a closer state
                state.onSetState({ editableState: { ...state.editableState, newColumn: ev.target.value } });
            }}
            columnAdded={(product: IProduct) => {
                var cols = product.columns ?? [];

                //limit name of column and amount of columns allowed
                if (newColumn && newColumn.length > 0 && newColumn.length <= 10 && cols.length < 5) {
                    cols.push(newColumn);
                    product.columns = cols;

                    //Update state
                    state.onSetState({ editableState: state.editableState, dataMapping });
                }
            }}
            columnDeleted={(product: IProduct, column: number) => {
                product.columns?.splice(column, 1);

                //Delete price
                product.subItems.forEach(sub => {
                    if(sub.price instanceof Array)
                        sub.price.splice(column, 1);
                });

                //Update state
                state.onSetState({ editableState: state.editableState, dataMapping });
            }}
            setOpened={(index: number, set: boolean = true) => {
                if (!set)
                    opened.splice(opened.indexOf(index), 1);
                else
                    opened.push(index);

                state.onSetState({ editableState: { ...state.editableState, opened, tooltip: -1 } });
            }}
            onDelete={(product: IProduct) => {
                dataMapping["products"].splice(dataMapping["products"].indexOf(product), 1);

                state.onSetState({
                    editableState: state.editableState,
                    dataMapping
                });
            }}
            fileUploaded={(formData: FormData) => {
                state.onSetState({
                    editableState: { ...state.editableState, formData }
                })
            }}
            imageSelected={(image: ILibraryImage) => {
                let products = dataMapping["products"];
                products[products.length - 1].image = image.filePath;

                state.onSetState({
                    dataMapping: { ...dataMapping, products },
                    editableState: { ...state.editableState, formData: null, error: false }
                });
            }}
            moveItem={(item: any, droppable: any) => {
                if (item.id === droppable.id)
                    return;

                var prods = dataMapping["products"];

                //Get ref and remove item
                var droppableRef = prods[droppable.index];
                var [add] = prods.splice(item.index, 1);

                //Get opened state and remove
                var openedIndex = opened.indexOf(item.index);
                var isOpen = openedIndex !== -1;

                if(isOpen)
                    opened.splice(openedIndex);

                //Indexes have changed get new index
                var i = prods.indexOf(droppableRef);

                //Only increment if it moved up
                if (droppable.index > item.index) i++;

                prods.splice(i, 0, add);

                //Add new index in
                if(isOpen)
                    opened.push(i);

                state.onSetState({
                    editableState: { ...state.editableState, opened },
                    dataMapping
                });
            }}
            newColumn={newColumn}/>
    </React.Fragment>
}

export default EditableProducts;
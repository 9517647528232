import React, { Component } from "react"
import { Grid, WithStyles } from "@material-ui/core";
import Editable from "../decorators/Editable";
import TextField from "../form/TextField";
import StyleInjector from "../decorators/StyleInjector";
import ICustomTheme from "../../interfaces/ICustomTheme";

interface ISpacerProps {
    id: string,
    placeholder?: ISpacerProps,
    height: number,
    theme: ICustomTheme
}

class Spacer extends Component<ISpacerProps & WithStyles> {
    render() {
        const { id, height, classes, theme } = this.props;

        const data = {
            id,
            name: "Spacer",
            initialValues: {
                height
            },
            fields: [
                {
                    label: "Increase or decrease the height of the spacer",
                    required: true,
                    render: (onChange: any, dataMapping: any) => {
                        return <TextField name="height" value={dataMapping.height} placeholder={dataMapping.placeholder?.height} onChange={onChange} />
                    }
                }
            ]
        };

        return theme.spacer?.disableEdit ?
            <div className={classes.root} style={{ height: Number(height) }} /> :
            <Editable data={data}>
                <div className={classes.root} style={{height: Number(height)}}/>          
            </Editable>
    }
}

export default StyleInjector({}, "spacer", true)(Spacer);
import { Grid } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory, useParams } from "react-router-dom";
import PrintPalFormatsOptions, { PrintPalFormatOptionsForm } from "../components/printPal/PrintPalFormatsOptions";
import PrintPalFormatsPreview from "../components/printPal/PrintPalFormatsPreview";
import { AppState } from "../store";
import { myMenuActions } from "../store/myMenu";
import useGeneratePdf from "../utils/useGeneratePdf";
import { printPalOperations } from "../store/printpal";
import { usePlaceOrderWithFormatsStyles } from "../styles";
import LoadingSpinner from "../components/POS/LoadingSpinner";
import IMyMenu from "../interfaces/IMyMenu";
import { setMenu } from "../store/menu/actions";

export interface GetFormatsBySizesRequest {
    formats: string[]
}

export interface FormatModelPartial {
    id: number
    title: string
    description: string
    imgUrl: string
    size: string //<- key
    sheet: string
    isNew: boolean
    isPopular: boolean
    isActive: boolean
    width: number
    height: number
    widthPx: number
    heightPx: number
    price: number
    formatKey: string
    availableInMultipleOf: number,
    showInProductCarousel: boolean,
    allowPurchase: boolean
}

export interface PlaceOrderSharedProps {
    formState: PrintPalFormatOptionsForm
    availableFormatsData?: FormatModelPartial[]
    totalPrice: number
}

type UrlParams = {
    id: string | undefined
}

const PreviewFormats: React.FC = () => {
    const { global, menu, myMenu, printPal } = useSelector((state: AppState) => state);
    const { paperData, loading } = printPal;
    const { menuData } = menu;
    const { getTemplate } = useGeneratePdf();
    const classes = usePlaceOrderWithFormatsStyles();

    const dispatch = useDispatch();
    const printPalPage = (printPalPage: boolean) => dispatch(myMenuActions.printPalPage(printPalPage));
    const getConfig = () => dispatch(printPalOperations.getConfig());
    const history = useHistory();
    const [isMobileApp, setIsMobileApp] = useState(false);
    const [showFormats, setShowFormats] = useState(false);
    const [form, setForm] = React.useState<PrintPalFormatOptionsForm>({
        dealOptions: {
            onlyDrinksFromRange: false,
            extendedSpaceForDrinks: false,
            additionalDrinks: false,
            firstTimeUsedInCalenderYear: false,
            IPApproval: false
        },
        voucher: "",
        quantities: [],
        inTheDealStickers: 0,
        magic8Stickers: false,
        wobblerArmsAmount: 0
    });
    const [availableFormats, setAvailableFormats] = useState<FormatModelPartial[]>();
    const [shippingCost, setShippingCost] = useState<number>(0);
    const [menuPreviewData, setMenuPreviewData] = useState<IMyMenu | null>(null)
    const { id } = useParams<UrlParams>();

    const price = useMemo(() => {
        if(form?.magic8Stickers){
            return 0
        }else{
            return form ? form.quantities.reduce((prev, curr, i) => {
                if (availableFormats) {
                    const formatData = availableFormats.find((f) => f.id == curr.formatId);
                    return formatData ? prev + (curr.quantity * formatData.price) : prev;
                } else { return prev; }
            }, 0) : 0;
        }
    }, [form, availableFormats]);

    const totalPrice = useMemo(() => {
        if(form?.magic8Stickers){
            return shippingCost ?? 0;
        }else{
            return price ? price + shippingCost : 0;
        }
    }, [price, shippingCost, form?.magic8Stickers]);

    const placeOrderProps: PlaceOrderSharedProps = {
        totalPrice,
        availableFormatsData: availableFormats,
        formState: form
    }

    const setResponsiveness = () => {
        return setIsMobileApp(window.innerWidth < 600 ? true : false);
    }

    useEffect(() => {
        printPalPage(true);
        getConfig();
        setTimeout(() => setShowFormats(true), 300)
        window.addEventListener("resize", setResponsiveness);

        fetch("/api/variables/shippingcost").then( async (response) => {
            setShippingCost(parseFloat(await response.text()));
        });

        fetch(`/api/MyMenus/menu/${id}`).then( async (response) => {
            let res = await response.json()
            setMenuPreviewData(res);
            dispatch(setMenu(res));
        })

        return () => {
            window.removeEventListener("resize", setResponsiveness);
        }
    }, [id])

    useEffect(() => {
        if(menuPreviewData?.availableFormats){
            setAvailableFormats(menuPreviewData?.availableFormats);
        }
    }, [menuPreviewData?.availableFormats])

    return (
        <div style={{ display: "flex", flex: 1, width: "100%" }}>
            {
                !menuPreviewData ?
                    <LoadingSpinner width={79} />
                :
                menuPreviewData?.errors ?
                <div style={{margin: "1rem auto"}}>Error while fetching the menu data.</div>
                :
                <Grid container direction={"row"} justifyContent="center">
                    <Grid item xs={5}>

                        {showFormats &&
                            menuPreviewData?.availableFormats.map((row) => {
                                return(
                                    <PrintPalFormatsPreview placeOrderProps={placeOrderProps} preview={true} format={row}/>
                                )
                            })
                        }
                    </Grid>
                </Grid>
            }
        </div>
    )
}

export default PreviewFormats;
import React from "react"
import { Grid, WithStyles } from "@material-ui/core";
import Editable from "../decorators/Editable";
import TextField from "../form/TextField";
import StyleInjector from "../decorators/StyleInjector";

interface IHorizontalLineProps {
    id: string,
    //placeholder?: IHorizontalLineProps,
    //height: number
}

class HorizontalLine extends React.Component<IHorizontalLineProps & WithStyles> {
    render() {
        const { id, classes } = this.props;

        const data = {
            id,
            name: "HorizontalLine",
            initialValues: {
                //height
            },
            fields: [
                // {
                //     label: "Increase or decrease the height of the spacer",
                //     required: true,
                //     render: (onChange: any, dataMapping: any) => {
                //         return <TextField name="height" value={dataMapping.height} placeholder={dataMapping.placeholder?.height} onChange={onChange} />
                //     }
                // }
            ]
        };

        return <Editable data={data}>
            <div className={classes.root}/>          
        </Editable>
    }
}

export default StyleInjector({}, "horizontalLine")(HorizontalLine);
import React from "react";

class PosErrorBoundary extends React.Component<PosErrorBoundaryProps, PosErrorBoundaryState> {
    constructor(props: PosErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: any) {
        // You can also log the error to an error reporting service
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <>

                <h1>An error has occurred.</h1>

                <p>You can try <a href=".">refreshing your page</a>.</p>
            </>;
        }

        return this.props.children;
    }
}

interface PosErrorBoundaryProps {

}

interface PosErrorBoundaryState {
    hasError: boolean;
}

export default PosErrorBoundary;
import { ActionCreator } from "redux";
import { SHOW_NAVIGATION, ShowNavigation, SHOW_ERROR, ShowError, IError, IUser,ISession, GET_USER,SET_SESSION, GetUser,SetSession, USER_LOADING, UserLoading } from "./types";

export const showNavigation: ActionCreator<ShowNavigation> = (showNavigation: boolean) => ({
    type: SHOW_NAVIGATION,
    showNavigation
});

export const showError: ActionCreator<ShowError> = (error: IError) => ({
    type: SHOW_ERROR,
    error
});

export const userLoading: ActionCreator<UserLoading> = (loading: boolean) => ({
    type: USER_LOADING,
    loading
});

export const getUser: ActionCreator<GetUser> = (user: IUser) => ({
    type: GET_USER,
    user
});


export const setSession: ActionCreator<SetSession> = (session: ISession) => ({
    type: SET_SESSION,
    session
});

export default { showNavigation, showError, getUser, userLoading,setSession };
import React from "react"
import { Grid, Typography, WithStyles, withTheme, FormControlLabel, Checkbox, RadioGroup, Radio, Divider } from "@material-ui/core";
import Editable from "../decorators/Editable";
import TextField from "../form/TextField";
import { IMenuItem, menuActions } from "../../store/menu";
import Components from "./";
import Droppable from "../decorators/Droppable";
import Draggable from "../decorators/Draggable";
import StyleInjector from "../decorators/StyleInjector";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import IsExporting from "../decorators/IsExporting";

const styles = ({
    container: {
        position: "absolute", 
        zIndex: 3, 
        marginTop: 0,
        left: "50%", 
        transform: "translate(-50%, -50%)",
        whiteSpace: "nowrap"        
    },
    divider: {
        zIndex: 5, 
        position: "absolute", 
    }
});

interface IDecoratedColumnsProps {
    id: string,
    title: string,
    subTitle: string,  
    footer: string, 
    showBottomCornerDecorations: boolean,
    showTopCornerDecorations: boolean,
    col1to2Divider: string,
    col2to3Divider: string, 
    hideVerticalBorders: boolean,    
    theme: any,
    items: IMenuItem[][],
    moveItem: any,
}

function Appearance(props: { onChange: any, dataMapping: any, decoration: string }) {
    const { onChange, dataMapping, decoration } = props;

    return <Grid container style={{ marginBottom: 15 }}>
        <Grid item>
            <Grid container alignItems="center">
                <Grid item>
                    <FormControlLabel name="showTopCornerDecorations" onChange={onChange} checked={dataMapping["showTopCornerDecorations"]} label="Show top corner decorations" labelPlacement="end" control={<Checkbox />} />
                </Grid>
                <Grid item>
                    <span className={decoration} /><span style={{ fontSize: 13, marginLeft: 30 }}> (Change style of decoration in 'Styles')</span>
                </Grid>
            </Grid>
        </Grid>

        <Grid item>
            <Grid container alignItems="center">
                <Grid item>
                    <FormControlLabel name="showBottomCornerDecorations" onChange={onChange} checked={dataMapping["showBottomCornerDecorations"]} label="Show bottom corner decorations" labelPlacement="end" control={<Checkbox />} />
                </Grid>                
                <Grid item>
                    <span className={decoration} /><span style={{ fontSize: 13, marginLeft: 30 }}> (Change style of decoration in 'Styles')</span>
                </Grid>
            </Grid>
        </Grid>

        <Grid item>
            <Grid container alignItems="center">
                <Grid item>
                    <FormControlLabel name="hideVerticalBorders" onChange={onChange} checked={dataMapping["hideVerticalBorders"]} label="Hide vertical borders" labelPlacement="end" control={<Checkbox />} />
                </Grid>
            </Grid>
        </Grid>

        <Grid item xs={12}>
            <Divider style={{ marginTop: 15, marginBottom: 15 }}/>
        </Grid>

        <Grid item xs={12}>
            <span style={{ fontSize: 16 }}>Choose divider to go between columns 1 and 2:</span>
        </Grid>

        <RadioGroup name="col1to2Divider" defaultValue="none" value={dataMapping["col1to2Divider"] ? dataMapping["col1to2Divider"] :  "none"} aria-label="dividers" onChange={onChange}>
            <Grid container>
                <Grid item>
                    <FormControlLabel value="none" label="No divider" control={<Radio />} labelPlacement="end" />
                </Grid>

                <Grid item>
                    <FormControlLabel value="line" label="Vertical line" control={<Radio />} labelPlacement="end" />
                </Grid>

                <Grid item>
                    <FormControlLabel value="plus" label="Plus sign" control={<Radio />} labelPlacement="end" />
                </Grid>
            </Grid>
        </RadioGroup>

        <Grid item xs={12} style={{marginTop: 15}}>
            <span style={{ fontSize: 16 }}>Choose divider to go between columns 2 and 3:</span>
        </Grid>

        <RadioGroup name="col2to3Divider" defaultValue="none" value={dataMapping["col2to3Divider"] ? dataMapping["col2to3Divider"] : "none"} aria-label="dividers" onChange={onChange}>
            <Grid container>
                <Grid item>
                    <FormControlLabel value="none" label="No divider" control={<Radio />} labelPlacement="end" />
                </Grid>

                <Grid item>
                    <FormControlLabel value="line" label="Vertical line" control={<Radio />} labelPlacement="end" />
                </Grid>

                <Grid item>
                    <FormControlLabel value="plus" label="Plus sign" control={<Radio />} labelPlacement="end" />
                </Grid>
            </Grid>
        </RadioGroup>
    </Grid>
}

class DecoratedColumns extends React.Component<IDecoratedColumnsProps & WithStyles> {
    moveItem = (item: any, droppable: any) => {
        if (item.id === droppable.id)
            return;

        this.props.moveItem(droppable, item);
    }

    showTopCornerDecorations = (classes: any) => {
        return (
            <Grid container className={classes.topCornerContainer}>
                <Grid container justify="flex-start" className={classes.topCornerLeftContainer}>
                    <div className={classes.decoration}>
                        <div className={`${classes.vertical} ${classes.borderCover} ${classes.borderCoverLeft}`}/>
                        <div className={`${classes.horizontal} ${classes.borderCover} ${classes.borderCoverTop}`}/>                            
                        <span className={classes.dot}/>
                    </div>
                </Grid>
                <Grid container justify="flex-start" className={classes.topCornerRightContainer}>
                    <div className={classes.decoration}>
                        <div className={`${classes.vertical} ${classes.borderCover} ${classes.borderCoverRight}`}/>
                        <div className={`${classes.horizontal} ${classes.borderCover} ${classes.borderCoverTop}`}/>                            
                        <span className={`${classes.dot} ${classes.topRightDot}`}/>
                    </div>
                </Grid>
            </Grid>
        )
    }

    showBottomCornerDecorations = (classes: any) => {
        return (
            <Grid container className={classes.bottomCornerContainer}>
                <Grid container justify="flex-start" className={classes.bottomCornerLeftContainer}>
                    <div className={classes.decoration}>
                        <div className={`${classes.vertical} ${classes.borderCover} ${classes.borderCoverLeft}`}/>
                        <div className={`${classes.horizontal} ${classes.borderCover} ${classes.borderCoverBottom}`}/>                          
                        <span className={`${classes.dot} ${classes.bottomLeftDot}`}/>
                    </div>
                </Grid>
                <Grid container justify="flex-start" className={classes.bottomCornerRightContainer}>
                    <div className={classes.decoration}>
                        <div className={`${classes.vertical} ${classes.borderCover} ${classes.borderCoverRight}`}/>   
                        <div className={`${classes.horizontal} ${classes.borderCover} ${classes.borderCoverBottom}`}/>                            
                        <span className={`${classes.dot} ${classes.bottomLeftDot} ${classes.topRightDot}`}/>
                    </div>
                </Grid>
            </Grid> 
        )   
    }

    showLeftDivider = (divider: string, classes: any, subTitle: string) => {
        if (divider === undefined) return <></>;

        if (divider === "plus")
            return <div className={`${classes.divider} ${classes.plusLeft}`} style={{marginTop: `${this.calcPlusDividerTopGap(subTitle, classes)}`}}><span className={classes.plus}/></div>

        if (divider === "line")
            return <div className={`${classes.divider} ${classes.line} ${classes.lineLeft}`} style={{top: `${this.calcLineDividerTopGap(subTitle, classes)}`}}/>

        return <></>
    }

    showRightDivider = (divider: string, classes: any, subTitle: string) => {
        if (divider === undefined) return <></>;

        if (divider === "plus")
            return <div className={`${classes.divider} ${classes.plusRight}`} style={{marginTop: `${this.calcPlusDividerTopGap(subTitle, classes)}`}}><span className={classes.plus}/></div>

        if (divider === "line")
            return <div className={`${classes.divider} ${classes.line} ${classes.lineRight}`} style={{ top: `${this.calcLineDividerTopGap(subTitle, classes)}`}}/> 

        return <></>
    }

    calcPlusDividerTopGap = (subTitle: any, classes: any) => {
        if (classes.editableDot) {
            return subTitle ? "55px" : "33px";        
        }

        return subTitle ? "78px" : "62px";     
    }

    calcLineDividerTopGap = (subTitle: any, classes: any) => {
        if (classes.editableDot) {
            return subTitle ? "87px" : "67px";        
        }

        return subTitle ? "107px" : "87px";     
    }

    calcGap = (title: string, subTitle: string, classes: any) => {
        if (!title && !subTitle) return "0px";

        if (subTitle) return "40px";

        return classes.editableDot ? "20px" : "25px";
    }

    render() {
        const { title, subTitle, footer, classes, id, showTopCornerDecorations, showBottomCornerDecorations, col1to2Divider, col2to3Divider, items, hideVerticalBorders } = this.props;   

        const data = (decoration: string) => ({
            id,
            name: "Decorated Columns",
            initialValues: {
                title,
                subTitle,
                footer, 
                showTopCornerDecorations, 
                showBottomCornerDecorations,
                col1to2Divider,
                col2to3Divider,
                hideVerticalBorders,
            },
            fields: [
                {
                    label: "Title text",
                    render: (onChange: any, dataMapping: any) => {
                        return <TextField fullWidth name="title" value={dataMapping["title"]} onChange={onChange} />
                    }
                }, {
                    label: "Subtitle text (or leave blank)",
                    render: (onChange: any, dataMapping: any) => {
                        return <TextField fullWidth name="subTitle" value={dataMapping["subTitle"]} onChange={onChange} />
                    }
                }, {
                    label: "Footer text (or leave blank)",
                    render: (onChange: any, dataMapping: any) => {
                        return <TextField fullWidth name="footer" value={dataMapping["footer"]} onChange={onChange} />
                    }
                }, {
                    label: "Appearance",
                    render: (onChange: any, dataMapping: any) => {
                        return <Appearance decoration={decoration} onChange={onChange} dataMapping={dataMapping} />
                    }
                }, 
            ]
        });

        const gap = this.calcGap(title, subTitle, classes);

        return <Editable data={data(classes.editableDot ?? classes.dot)}>
            <IsExporting>
                {(exporting: boolean) => (
                    <React.Fragment>
                        <div>
                            {title && title.length > 0 ? <div className={classes.container}>
                                <Typography variant="h1" className={`${classes.default} ${classes.text}`}>{title}</Typography>
                            </div> : null}
                            {subTitle && subTitle.length > 0 ? <div className={classes.container}>
                                <Typography variant="h2" className={classes.subTitle}>{subTitle}</Typography>
                            </div> : null}

                            {showTopCornerDecorations && this.showTopCornerDecorations(classes)}
                        </div>

                        {this.showLeftDivider(col1to2Divider, classes, subTitle)}
                        {this.showRightDivider(col2to3Divider, classes, subTitle)}

                        <Grid container justify="center" className={`${classes.root} ${exporting ? classes.exporting : ""} ${hideVerticalBorders ? classes.hideVerticalBorders : classes.showVerticalBorders}`} style={{ position: "relative", zIndex: 2 }}>                              
                            <Grid container style={{height: `${gap}`, zIndex: 1 }}/>
                            {items.map((column: IMenuItem[], columnIndex: number) => (
                                <Grid item key={columnIndex} className={classes.column} style={{ width: `${100 / items.length}%`, zIndex: 4 }}>
                                    {column.length > 0 ? column.map((item: IMenuItem, index: number) => {
                                        var Component = (Components as any)[item.component];

                                        return Component &&
                                            <Droppable column={columnIndex} index={index} key={item.props.id} id={id + " " + item.props.id}>
                                                {(connectDropTarget: any) => (
                                                    <Draggable column={columnIndex} index={index} id={id + " " + item.props.id} end={this.moveItem}>
                                                        {(connectDragTarget: any, connectDragPreview: any, dragging: boolean) => (
                                                            <div ref={node => connectDragTarget(connectDropTarget(node))} style={{ opacity: dragging ? 0 : 1 }}>
                                                                <Component {...item.props} />
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                )}
                                            </Droppable>
                                    }) : <Droppable column={columnIndex} index={columnIndex} key={columnIndex} id={id}>
                                            {(connectDropTarget: any) => (
                                                <div ref={node => connectDropTarget(node)} className={classes.empty}>
                                                    <span>Drag any half-width item into this column</span>
                                                </div>
                                            )}
                                        </Droppable>
                                    }
                                </Grid>
                                ))}                    
                        </Grid>

                        <div>
                            {footer && footer.length > 0 ? <div className={classes.container} style={{marginTop: 0}}>
                                <Typography variant="h2" className={`${classes.default} ${classes.text}`}><strong>{footer}</strong></Typography>
                            </div> : null}

                            {showBottomCornerDecorations && this.showBottomCornerDecorations(classes)}
                        </div>

                        <Grid container style={{ height: 20 }}></Grid>
                    </React.Fragment>
                )}
            </IsExporting>
        </Editable>
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        moveItem: (to: any, from: any) => dispatch(menuActions.moveItem(to, from))
    }
}

export default connect(null, mapDispatchToProps)(StyleInjector(styles, "decoratedColumns")(withTheme(DecoratedColumns)));
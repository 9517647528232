import React, { ReactNode, useContext } from "react"
import { Divider } from "@material-ui/core";
import { DragElementWrapper, DropTarget, DropTargetConnector, DropTargetMonitor, DndContext } from "react-dnd";

const spec = {
    canDrop(props: IDroppable, monitor: DropTargetMonitor) {
        return props.canDrop ?? true;
    },
    hover(props: IDroppable, monitor: DropTargetMonitor, component: any) {
        if (props.hover)
            props.hover(monitor.getItem(), props);
    },
    drop(props: IDroppable, monitor: DropTargetMonitor, component: any) {
        if (monitor.didDrop())
            return;

        return { id: props.id, index: props.index, column: props.column };
    }
}

const collect = (connect: DropTargetConnector, monitor: DropTargetMonitor) => {
    return {
        connectDropTarget: connect.dropTarget(),
        over: monitor.isOver({ shallow: true })
    }
}

interface IDroppable {
    id: string,
    index?: number,
    column?: number,
    over: boolean,
    connectDropTarget: DragElementWrapper<any>,
    canDrop?: boolean,
    hover?: any,
    children: (connectDropTarget: DragElementWrapper<any>, over: boolean) => ReactNode,
    renderOver?: any
}

class Droppable extends React.PureComponent<IDroppable> {
    render() {
        const { connectDropTarget, children, over, renderOver } = this.props;

        return over ? <React.Fragment>
            {renderOver ? renderOver() : <Divider style={{ height: 4, borderTop: "4px dashed rgba(230, 30, 30, 1)", backgroundColor: "transparent" }}></Divider>}
            {children(connectDropTarget, over)}
        </React.Fragment> : children(connectDropTarget, over)
    }
}

export default function CanDrop (props: any) {
    const { dragDropManager } = useContext(DndContext as any);
    const Component = dragDropManager && !props.simplified ? DropTarget("1", spec, collect)(Droppable) : (props: any) => props.children(() => { }, false);
    return <Component {...props} />
};

export const NotChecked = DropTarget("1", spec, collect)(Droppable);
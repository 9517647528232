import React, { Component } from 'react';
import { Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { Button, Dialog, Grid, CircularProgress } from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";
import { RouterProps, withRouter } from "react-router-dom";

import { connect } from "react-redux";
import { AppState } from "../../store";
import { IMyMenuState } from "../../store/myMenu";
import { IMenuState } from '../../store/menu';

const styles = (theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(0),
    },
    close: {
        position: "absolute" as "absolute",
        right: 0,
        top: 0,
        width: 52,
        height: 52,
        border: "2px solid rgba(255, 159, 28, 1)",
        borderRadius: "50%",
        backgroundColor: "#FFF",
        textAlign: "center" as "center",
        lineHeight: "52px",
        color: "rgba(255, 159, 28, 1)",
        transform: "translate(50%,-50%)",
        cursor: "pointer",
        "& svg": {
            width: 32,
            height: "100%",
        },
        "&:hover": {
            backgroundColor: "#FFF",
            color: "rgba(255, 159, 28, 1)"
        },
        [theme.breakpoints.down("sm")]: {
            width: 32,
            height: 32,
            lineHeight: "32px",
            "& svg": {
                width: 28,
            },
        }
    },
    selectButton: {
        width: 350,
        backgroundColor: "rgba(255, 159, 28, 1)",
        color: "#FFF",
        border: "1px solid rgba(255, 159, 28, 1)",
        fontWeight: "bold" as "bold",
        marginTop: theme.spacing(4),
        "&:hover": {
            backgroundColor: "rgba(255, 159, 28, 1)",
            border: "1px solid rgba(255, 159, 28, 1)",
            color: "#FFF"
        },
        [theme.breakpoints.down("sm")]: {
            width: 210,
            fontSize: 12
        }
    },
    section: {
        marginTop: theme.spacing(3),
    },
    messageBox: {
        minHeight: 300,
        maxWidth: 600,
        height: "auto",
        backgroundColor: "white",
        border: "3px solid rgba(255, 159, 28, 1)",
        [theme.breakpoints.down("sm")]: {
            maxWidth: 260,
            minHeight: 260,
        }
    },
    banner: {
        backgroundColor: "rgba(255, 159, 28, 1)",
        minHeight: 108,
        width: "100%",
        height: "auto",
        [theme.breakpoints.down("sm")]: {
            minHeight: 70,
        }
    },
    logo: {
        alignContent: "center",
        width: "100%"
    },
    paperProps: {
        backgroundImage: `url("./assets/printPal/bg.png")`,
        border: "3px solid rgba(255, 159, 28, 1)",
        minHeight: 600,
        maxWidth: 800,
        padding: 0,
        [theme.breakpoints.down("sm")]: {
            minHeight: 400,
            maxWidth: 280,
        }
    },
    text: {
        padding: "15 30 30 30",
        [theme.breakpoints.down("sm")]: {
            fontSize: 12,
        }
    },
    container: {
        marginTop: 60,
        [theme.breakpoints.down("sm")]: {
            marginTop: 30,
        }
    },
    spinner: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: theme.spacing(2),
        color: "rgba(255, 159, 28, 1)"
    }
});

interface IPopupProps {
    myMenu: IMyMenuState;
    generatePreviews: any;
    menu: IMenuState;
    closePopup: any;
    isPopupOpen: boolean;
}

class PrintPalPopup extends Component<WithStyles & IPopupProps & RouterProps, any> {
    isMobileApp: boolean;

    constructor(props: any) {
        super(props);

        this.isMobileApp = this.props.myMenu.isMobileApp;

        this.state = {
            isMobileApp: false,
            showMessage: false
        }
    }

    handleClosePopup = () => {
        this.props.closePopup();
    }

    handleGoToPrintPal = () => {
        this.setState({ showMessage: true });
        if(!this.props.menu.isMenu){
            //This is a meal deal template with multiple formats in 1
            this.props.history.push("/place-order-formats")
        } else {        
            this.props.generatePreviews().then((result: any) => {
                if (!(result instanceof Error)) {
                    this.props.closePopup();

                    //J2O style
                    if (this.props.menu.menuData?.styleId == 30) {

                        //Set some data
                        var order = {
                            menuId: this.props.menu.menuData?.id,
                            title: this.props.menu.menuData?.title,
                            qty: 50,
                            delCharge: 0,
                            vat: 0,
                            price: 0,
                            paperId: 3,
                            paperDesc: "Standard gloss (200gsm)",
                            option: "",
                            imgUrl: 0,
                            pdfPreview: this.props.menu.menuData?.pdfPreview,
                            voucherCode: "",
                            hideChange: true
                        };
                        
                        console.log("sessionStorage.order being set from this.preview", order);
                        sessionStorage.setItem('order', JSON.stringify(order));
                        this.props.history.push("order-payment");
                    }
                    else
                        this.props.history.push('/place-order');

                }
                else{
                    console.log("Error", result);
                }

                this.setState({ showMessage: false });
            });
        }
    }

    render() {
        const { classes, menu } = this.props;

        return (
            <Dialog
                fullWidth
                onClose={this.handleClosePopup}
                open={this.props.isPopupOpen}
                PaperProps={{className: classes.paperProps}}>

                <div onClick={this.handleClosePopup} className={classes.close}>
                    <CloseRounded />
                </div>

                <Grid container direction="column" alignItems="center" justify="center" spacing={0} className={classes.banner}>
                    <Grid item xs={4}>
                        <img src="./assets/printPal/white_logo.png" alt="ScreenPrint Logo" className={classes.logo} />       
                    </Grid>
                </Grid>

                <Grid container direction="column" alignContent="center" className={classes.container}>
                    <Grid container alignItems="center" justify="center" alignContent="center" className={classes.messageBox} item xs={8}>
                        <Grid item xs={10}>
                            <div className={classes.text} style={{ textAlign: "center" }}>
                                ScreenPrint is our dedicated printing service.<br/><br/>
                                To print your design with ScreenPrint, we need to transfer you to the ScreenPrint website.<br />
                                With your permission we can automatically transfer your details and artwork to that site.<br /><br />
                                {this.state.showMessage ? <b> We are preparing your artwork. This can take up to 30 seconds...</b> : null}
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                            <Grid container alignItems="center">
                            {menu.generatingPreviews ?
                                <CircularProgress className={classes.spinner} /> : 
                                <Button
                                    className={classes.selectButton}
                                    onClick={this.handleGoToPrintPal}>
                                    Take me to ScreenPrint
                                </Button>}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Dialog>
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    myMenu: state.myMenu,
    menu: state.menu
});

export default connect(mapStateToProps)(withStyles(styles)(withRouter(PrintPalPopup)));
import React, { Component, ReactElement } from "react"
import { Button, Theme, WithStyles, withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { IMenuState, IModalData, menuActions } from "../../store/menu";
import IsExporting from "./IsExporting";

const styles = (theme: Theme) => ({
    wrapper: {
        "&:hover": {
            border: "2px dashed rgba(0,125,122, 1)",
            "& > :first-child": {
            },
            "& > div:last-child": {
                opacity: 1,
                zIndex: 105
            },
            //Select the last column's first child and move the button down a bit
            "& [class*='MuiGrid-item']:last-child > :first-child $editWrapper": {
            }
        },
        border: "2px dashed transparent",
        padding: 2,
        margin: 0,
        position: "relative" as "relative",
        "&.fullHeight": {
            display: "flex",
            flex: "1 0 0"
        },
        "&.fullHeight > :first-child": {
            display: "flex",
            flex: "1 0 0"
        }
    },
    selected: {
        margin: 0,
        position: "relative" as "relative",
        border: "2px dashed rgba(0,125,122, 1)",
        "& > :first-child": {
        },
        "& > div:not(:first-child)": {
            opacity: 1,
            zIndex: 105
        }
    },
    editWrapper: {
        position: "absolute" as "absolute",
        right: 5,
        top: 5,
        opacity: 0,
        transition: "opacity 0.2s ease"
    },
    editButton: {
        color: "#FFF",
        backgroundColor: "#007D7A",
        fontWeight: "bold" as "bold",
        borderRadius: 0,
        minWidth: "auto",
        padding: "1px 8px",
        margin: 5,
        border: 0,
        "&:hover": {
            color: "#FFF",
            backgroundColor: "#007D7A",
            border: 0
        }
    },
    overlayPlaceholderWrapper: {
        position: "absolute" as "absolute",
        height: "100%",
        width: "100%",
        display: "flex"
    }
});

interface IEditableProps {
    openModal: any;
    selectSection: any;
    children: ReactElement | ReactElement[];
    data: IModalData;
    menu: IMenuState;
    fullHeight?: boolean;
    buttonText?: string;
    changed?: boolean;
    hideOnExport?: boolean;
    hideEdit?: boolean
}

/*
    overlayPlaceholder = ReactElement
    showOverlayPlaceholder = boolean
*/

class Editable extends Component<WithStyles<typeof styles> & IEditableProps> {
    render() {
        const { classes, children, openModal, data, selectSection, menu, buttonText, changed, fullHeight = false, hideOnExport, hideEdit } = this.props;
        const { selectedSection } = menu;

        ////debugger;

        return <IsExporting>
            {(exporting: boolean) => {
            return (
                <div className={`${classes.wrapper} ${fullHeight ? "fullHeight" : ""} ${!exporting && (changed === false || selectedSection === data.id) ? classes.selected : ""}`} onClick={(ev) => {
                    ev.stopPropagation();
                    selectSection(data.id);
                }}>
                    {/* This divider is required as we can't be sure what the child looks like */}
                    <div style={{
                        visibility: (exporting && hideOnExport) ? "hidden" : "unset"
                    }}>{children}</div>

                    {(!exporting && !hideEdit) && <div className={classes.editWrapper}>
                        <Button className={classes.editButton} variant="text" onClick={() => openModal(data)}>
                            {buttonText ? buttonText : "Edit"}
                        </Button>
                    </div>}
                </div>
            )}}
        </IsExporting>
    }
}

const mapStateToProps = (state: any) => {
    return {
        menu: state.menu
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        openModal: (data: IModalData) => dispatch(menuActions.openModal(data)),
        selectSection: (id: string) => dispatch(menuActions.selectSection(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Editable));
﻿import * as React from 'react';
import MultiSelectUnstyled, {
  MultiSelectUnstyledProps,
} from '@mui/base/MultiSelectUnstyled';
import { selectUnstyledClasses } from '@mui/base/SelectUnstyled';
import OptionUnstyled, { optionUnstyledClasses } from '@mui/base/OptionUnstyled';
import PopperUnstyled from '@mui/base/PopperUnstyled';
import { styled } from '@mui/system';
import { ListSubheader } from '@material-ui/core';

const blue = {
  100: '#DAECFF',
  200: '#99CCF3',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  100: '#E7EBF0',
  200: '#E0E3E7',
  300: '#CDD2D7',
  400: '#B2BAC2',
  500: '#A0AAB4',
  600: '#6F7E8C',
  700: '#3E5060',
  800: '#2D3843',
  900: '#1A2027',
};

const StyledButton = styled('button')(
  ({ theme }) => `
  font-family: Segoe UI Light;
  font-size: 0.875rem;
  box-sizing: border-box;
  max-width: 280px;
  min-height: 44px;
  background: white;
  border: 1px solid grey;
  border-radius: 3px;
  margin:0;
  padding: 10px;
  text-align: left;
  line-height: 1.5;

width: 100%;

font-size: 18px;

  &.${selectUnstyledClasses.expanded} {
    &::after {
      content: '▴';
    }
  }

  &::after {
    content: '▾';
    float: right;
  }
  `,
);

const StyledListbox = styled('ul')(
  ({ theme }) => `
  font-family: Segoe UI Light;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 5px;
  margin: 10px 0;
  min-width: 320px;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[300]};
  border-radius: 0.75em;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  overflow: auto;
  outline: 0px;
max-height: 400px;
overflow: auto;
  `,
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 0.45em;
  cursor: default;

font-size: 18px;

    margin: 10px;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionUnstyledClasses.selected} {
    background-color: rgba(245, 154, 35, 0.4) !important;
    color: ${theme.palette.mode === 'dark' ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.highlighted} {
    background-color: background-color: rgba(245, 154, 35, 0.4) !important;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  }

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: rgba(245, 154, 35, 0.4) !important;
    color: ${theme.palette.mode === 'dark' ? blue[100] : blue[900]};
  }

   &.${optionUnstyledClasses.disabled} {
    color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
  }

&:active {
    background-color: rgba(245, 154, 35, 0.4) !important;

}


  &:hover:not(.${optionUnstyledClasses.disabled}) {
    background-color: rgba(245, 154, 35, 0.2);
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  }
  `,
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const CustomMultiSelect = React.forwardRef(function CustomMultiSelect(
  props: MultiSelectUnstyledProps<number>,
  ref: React.ForwardedRef<any>,
) {
  const components: MultiSelectUnstyledProps<number>['components'] = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

    return <MultiSelectUnstyled {...props} ref={ref} components={components} />;
});

export default function UnstyledSelectsMultiple(props: UnstyledSelectsMultipleProps) {

    const [selectOptions, setSelectOptions] = React.useState([]);

    const [tagList, setTagList] = React.useState([]);

    React.useEffect(() => {
        fetch('../../api/styles/gettaggroups')
            .then(async response => {

                const jsonResponse = await response.json();

                const filterOptions = jsonResponse
                    .filter(tagGroup => tagGroup.tags.length > 0)
                    .flatMap(tagGroup =>
                    [
                        <ListSubheader key={tagGroup.id + "t"} style={{userSelect: "none", fontSize: 18, color: "#444", textTransform: "uppercase", fontFamily: "Segoe UI"}}>{tagGroup.title}</ListSubheader>,

                        ...tagGroup.tags.map(tag => <StyledOption style={{userSelect: "none"}} key={tag.id} value={tag.id}>{tag.title}</StyledOption>)
                    ]
                )

                setTagList(jsonResponse.flatMap(tagGroup => tagGroup.tags));

                setSelectOptions(filterOptions)
            })
    }, [])


    const displayValue = props.value.map(item => item.id);

    function remapValuesToObjects(ids: number[]) {

        return ids.map(id => {
            return {

                id: id,
                title: tagList.find(tag => tag.id === id).title

            }
        })
    }

  return (
      <CustomMultiSelect value={displayValue} onChange={(e) => { console.log(e); props.onChange(remapValuesToObjects(e)); }}>
          {selectOptions.length !== 0 && selectOptions }
    </CustomMultiSelect>
  );
}



interface UnstyledSelectsMultipleProps {
    value: { id: number, title: string }[];
    onChange: Function;
    disabled: boolean;
}

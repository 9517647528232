﻿import { Reducer } from "redux";
import { IMyMenuState, Actions, MENU_LOADING, GET_MENUS, FORMATS_LOADING, GET_FORMATS, OPEN_POPUP, CLOSE_POPUP, STYLES_LOADING, GET_STYLES, PRINTPAL_PAGE, SAVE_MENU, SET_MYMENU, REMOVE_MYMENU } from "./types";
import { removeSavePrompt } from "../../utils/common";

export const initialState: IMyMenuState = {
    isPopupOpen: false,
    printPalPage: false,
    loading: true,
    data: [],
    formatData: [],
    styleData: [],
    isMobileApp: window.screen.width <= 1280,
    saving: false
}

const reducer: Reducer<IMyMenuState> = (state: IMyMenuState = initialState, action): IMyMenuState => {
    switch ((action as Actions).type) {

        case MENU_LOADING: {
            return {
                ...state,
                loading: action.loading,
            }
        }

        case GET_MENUS: {
            return {
                ...state,
                data: action.data,
                loading: false
            }
        }

        case PRINTPAL_PAGE: {
            return {
                ...state,
                printPalPage: action.printPalPage
            }
        }

        case FORMATS_LOADING: {
            return {
                ...state,
                loading: action.loading
            }
        }

        case GET_FORMATS: {
            return {
                ...state,
                formatData: action.formatData,
                loading: false
            }
        }

        case STYLES_LOADING: {
            return {
                ...state,
                loading: action.loading
            }
        }

        case GET_STYLES: {
            return {
                ...state,
                styleData: action.styleData,
                loading: false
            }
        }

        case OPEN_POPUP: {
            return {
                ...state,
                isPopupOpen: true,
            }
        }

        case CLOSE_POPUP: {
            return {
                ...state,
                isPopupOpen: false,
            }
        }

        case SET_MYMENU: {
            let index = state.data.findIndex(i => i.id === action.id);
            state.data[index].title = action.title;

            return {
                ...state,
            }
        }

        case SAVE_MENU: {
            //Finished saving so remove prompt
            if (!action.saving)
                removeSavePrompt();

            return {
                ...state,
                saving: action.saving
            }
        }

        case REMOVE_MYMENU: {
            return {
                ...state,
                data: state.data.filter((menu) => menu.id !== action.id)
            }
        }

        default:
            return state;
    }
}

export default reducer;
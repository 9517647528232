import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Container, Grid, Typography, Button, makeStyles, createStyles, Theme, IconButton, Drawer, MenuItem } from "@material-ui/core";
import logo from "../../assets/britvic_black_logo.png";

import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        mainSiteButton: {
            backgroundColor: "#FFF",
            color: "#4878d6",
            border: "1px solid #4878d6",
            fontWeight: "bold" as "bold",
            "&:hover": {
                backgroundColor: "#4878d6",
                color: "#FFF",
                border: "1px solid #FFF",
            }
        },
        logo: {
            maxWidth: 160,
            width: "100%",
            height: "auto",
            [theme.breakpoints.down("sm")]: {
                padding: "5px 0",
                maxWidth: 100
            }
        },
        header: {
            fontSize: 22,
            textAlign: "left",
            marginLeft: -10,
        },
        drawerContainer: {
            padding: 0,
        },
        drawerPaper: {
            marginTop: 65,
            height: 50,
            width: 200,
            overflow: "auto"
        },
        iconButton: {
            display: "flex",
            flexDirection: "column"
        },
        menuItem: {
            color: "#000",
            fontSize: 14
        },
        wrapper: {
            padding: "25px 0"
        }
    }),
);

const Header = () => {
    const [state, setState] = useState({
        isMobileApp: false,
        drawerOpen: false
    });

    const mealDealMessaging = useSelector((state:any) => state.global.session.mealDealMessaging);
    const { isMobileApp, drawerOpen } = state;

    const classes = useStyles();

    useEffect(() => {
        let mounted: boolean = false;
        const setResponsiveness = () => {
            if (mounted)
                return window.innerWidth < 775
                    ? setState((prevState) => ({ ...prevState, isMobileApp: true }))
                    : setState((prevState) => ({ ...prevState, isMobileApp: false }));
                };
        mounted = true;
        setResponsiveness();
        window.addEventListener("resize", () => setResponsiveness());
        return () => { mounted = false; window.removeEventListener('resize', () => setResponsiveness())};     
    }, []);

    const handleGoToBritvic = () => {
        window.location.href = "https://sensationaldrinks.com";
    }

    const displayDesktop = () => {
        return <Grid container direction="row" alignItems="center" justify="center" spacing={3} className={classes.wrapper}>
            <Grid item xs={3}>
                <img src={logo} className={classes.logo} alt="" />
            </Grid>

            <Grid container item xs={6} justify="center">
                {mealDealMessaging && <Typography variant="h1" style={{ fontSize: 30, textAlign: "center" }}>MEAL DEAL DESIGNER</Typography>}
                {!mealDealMessaging && <Typography variant="h1" style={{ fontSize: 30, textAlign: "center" }}>MENU MAKER</Typography>}
            </Grid>

            <Grid container item xs={3} justify="flex-end">
{/*                <Button variant="outlined" className={classes.mainSiteButton} onClick={handleGoToBritvic}>Back to main site</Button>*/}
            </Grid>
        </Grid>
    }

    const displayMobile = () => {
        const handleDrawerOpen = () =>
            setState((prevState) => ({ ...prevState, drawerOpen: true }));

        const handleDrawerClose = () =>
            setState((prevState) => ({ ...prevState, drawerOpen: false }));

        return <Grid container direction="row" alignItems="center" justify="center">
            <Grid item xs={3}>
                <img src={logo} className={classes.logo} alt=""/>
            </Grid>

            <Grid container item xs={6} justify="center">
                <div className={classes.header}>MENU MAKER</div>
            </Grid>

            <Grid container item xs={3} justify="flex-end">
                <IconButton
                    classes={{ label: classes.iconButton }}
                    {...{
                        edge: "end",
                        color: "inherit",
                        "aria-label": "menu",
                        "aria-haspopup": "true",
                        onClick: handleDrawerOpen,
                    }}>
                    <MenuIcon style={{ fontSize: 30}} />
                    <div style={{ fontSize: 10}}>MENU</div>
                </IconButton>

                <Drawer style={{marginTop: 100}}
                    classes={{ paper: classes.drawerPaper }}
                    {...{
                        anchor: "right",
                        open: drawerOpen,
                        onClose: handleDrawerClose,
                    }}>
                    <div className={classes.drawerContainer}>{getDrawerChoices()}</div>
                </Drawer>
            </Grid>
        </Grid>
    }

    const getDrawerChoices = () => {
        return null; // <MenuItem className={classes.menuItem} onClick={handleGoToBritvic}>Back to main site</MenuItem>;
    };

    return <div style={{ background: "#FFF" }}>
        <Container maxWidth={false}>
            {isMobileApp ? displayMobile() : displayDesktop()}
        </Container>
    </div>
}

export default Header;
import { useSelector } from "react-redux";
import { AppState } from "../store";

const useHelperMethods = () => {

    const { menu } = useSelector((state: AppState) => state);
    const { menuData, configuration } = menu ?? {};
    const getScaledPosition = (scale: number, formatSize: string, translationPosition?: TranslationPosition): TranslationPosition => {
        const formatOffset = configuration?.templateConfiguration?.formatOffsets?.find((f) => f.format == formatSize);
        const { offset } = formatOffset ?? {};

        const { height, left, top, width, z } = translationPosition ?? {
            height: 0, 
            left: 0,
            top: 0,
            width: 0,
            z: 1
        };

        const newSizes = {
            height: (height + (offset?.height ?? 0)) * scale,
            left: (left + (offset?.left ?? 0)) * scale,
            top: (top + (offset?.top ?? 0)) * scale,
            width: (width + (offset?.width ?? 0)) * scale,
            z
        }
    
        return newSizes;
    }

    return {
        getScaledPosition
    }
}

export default useHelperMethods;
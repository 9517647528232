import React, { useEffect, useState } from "react";

function LiveReportsPage() {

    const googleUrl = "#";

    const [iframSrc, setIframeUrl] = useState(googleUrl);


    const getReportUrl = () => {
        fetch(`${process.env.PUBLIC_URL}/api/reporting/getReportUrl`).then(async (response) => {
            const url: string = await response.text();
            setIframeUrl(url);
            //debugger;
        })
    };

    useEffect(getReportUrl, []);
   
    return <>


        <div style={{ flexGrow: 2 }}>
            <h2 style={{ fontSize: 32, color: "#333", margin: 0, fontFamily: "Segoe UI", fontWeight: "bold" }}>Live Reports</h2>
            <p style={{ margin: "0 0 16px 0" }}></p>
        </div>

        <hr className="line-divider" />

        <iframe style={{ width: "100%", minHeight: "1600px", border: "none" }} src={iframSrc}></iframe>
      
    </>
}

export default LiveReportsPage;
import { Action } from "redux";

export const SHOW_NAVIGATION = "SHOW_NAVIGATION";
export const SHOW_ERROR = "SHOW_ERROR";
export const GET_USER = "GET_USER";
export const USER_LOADING = "USER_LOADING";
export const SET_SESSION = "SET_SESSION";

export interface IGlobalState {
    showNavigation: boolean;
    loading: boolean;
    error?: IError;
    user?: IUser,
    session?: ISession
}

export interface IError {
    title: string;
    error: string;
}

export interface IUser {
    displayName: string,
    userId: number,
    company: string,
    email: string
    role?: IRole
    britvicEmployeeId?: string
}

export interface ISession {
    useExternalLogin: boolean,
    mealDealMessaging: boolean,
    takePayment: boolean;
}

export interface IRole {
    role: "ADMIN" | "POS" | "MENU_BUILDER" | "REPORTINGUSER"
    weight: number
}

export interface ShowNavigation extends Action {
    type: typeof SHOW_NAVIGATION;
    showNavigation: boolean;
}

export interface ShowError extends Action {
    type: typeof SHOW_ERROR,
    error?: IError;
}

export interface UserLoading extends Action {
    type: typeof USER_LOADING;
    loading: boolean;
}

export interface GetUser extends Action {
    type: typeof GET_USER;
    user: IUser;
}

export interface SetSession extends Action {
    type: typeof SET_SESSION;
    session: ISession;
}

export type Actions = ShowNavigation | ShowError | GetUser | UserLoading | SetSession;

import React, { Component } from "react";
import { Button, Typography, Theme, withStyles, WithStyles, Grid, Dialog, Box, Breadcrumbs } from "@material-ui/core";
import { Link } from "react-router-dom";
import MyMenuList from "../components/myMenus/MyMenuList";
import MyMenuTitlePopup from "../components/myMenus/MyMenuTitlePopup";
import IMyMenu from '../interfaces/IMyMenu';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withRouter, RouteComponentProps } from "react-router-dom";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link as RouterLink } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";

// Store
import { connect } from "react-redux"
import { AppState } from "../store";
import { IMyMenuState, myMenuActions, myMenuOperations } from "../store/myMenu";
import { globalActions,ISession } from "../store/global";

const styles = (theme: Theme) => ({
    container: {
        background: "#f8efed",
        overflow: "hidden",
        paddingLeft: theme.spacing(7),
        paddingRight: theme.spacing(7),
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
        minHeight: theme.spacing(50),
        [theme.breakpoints.down("sm")]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            minHeight: theme.spacing(40),
        }
    },
    myMenuButton: {
        backgroundColor: "#FFF",
        color: "#007D7A",
        border: "1px solid #FFF",
        fontWeight: "bold" as "bold",
        "&:hover": {
            backgroundColor: "transparent",
            color: "#FFF",
            border: "1px solid #FFF",
        }
    },
    toolbar: {
        background: "#FFF",
        height: 100,
        paddingLeft: theme.spacing(7),
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
        [theme.breakpoints.down("sm")]: {
            height: 60,
            paddingLeft: theme.spacing(2),
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            fontSize: 10,
        }
    },
    link: {
        display: 'flex',
        color: "#007D7A",
        fontWeight: "bold" as "bold",
        [theme.breakpoints.down("sm")]: {
            fontSize: 12,
        }
    },
    stepText: {
        color: "#FFF",
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        [theme.breakpoints.down("sm")]: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2)
        }
    },
    cancel: {
        backgroundColor: "#FFF",
        color: "#007D7A",
        border: "1px solid #007D7A",
        width: 100,
        "&:hover": {
            backgroundColor: "#007D7A",
            border: "1px solid #007D7A",
            color: "#FFF"
        }
    },
    save: {
        marginLeft: theme.spacing(2),
        backgroundColor: "#007D7A",
        color: "#FFF",
        border: "1px solid #007D7A",
        width: 100,
        "&:hover": {
            backgroundColor: "#FFF",
            border: "1px solid #007D7A",
            color: "#007D7A"
        }
    },
    fullHeight: {
        minHeight: 'calc(100vh - 301px)'
    }
});

interface IProps {
    myMenu: IMyMenuState;
    session: ISession, 
    showNavigation: any;
    printPalPage: any;
    openPopup: any;
    closePopup: any;
    saveMenuTitle: any;
    deleteMenu: any;
    createMenu: any;
}

interface IState {
    open: boolean;
    isMobileApp: boolean;
}

class MyMenus extends Component<WithStyles & IProps & RouteComponentProps, IState> {
    menu: any;

    constructor(props: any) {
        super(props);
        sessionStorage.clear();
        this.state = { open: false, isMobileApp: false };
    }

    componentDidMount() {
        //Check for special QS
        const query = new URLSearchParams(this.props.location.search);

        switch (query.get("create")) {
            case "j2o":
                {
                    //this.props.createMenu(160, "My J2O menu").then(menu => {
                    //    this.props.history.push("/menu-builder");
                    //});

                    sessionStorage.setItem('styleId', '30');
                    this.props.history.push("/choose-format");
                    break;
                }

            default:
                {
                    this.props.printPalPage(false);
                    this.props.showNavigation(false);

                    this.setResponsiveness();
                    window.addEventListener("resize", this.setResponsiveness);
                }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setResponsiveness);
    }

    setResponsiveness = () => {
        return window.innerWidth < 900
            ? this.setState((prevState) => ({ ...prevState, isMobileApp: true }))
            : this.setState((prevState) => ({ ...prevState, isMobileApp: false }));
    };

    handleOpenPopup = () => {
        this.props.openPopup();
    }

    handleSaveChange = (title: string) => {
        this.props.saveMenuTitle(this.menu.id, title);
        this.props.closePopup();
    }

    handleDeleteMyMenu = (menu: IMyMenu) => {
        this.menu = menu;
        this.props.closePopup();
        this.setState({ open: true });
    }

    handleDeleteConfirmation = () => {
        this.handleCloseDeleteDialog();
        this.props.deleteMenu(this.menu.id);
    }

    handleCloseDeleteDialog = () => {
        this.setState({ open: false });
    }

    handleClosePopup = () => {
        this.props.closePopup();
    }

    handleChangeMyMenuTitle = (menu: IMyMenu) => {
        this.menu = menu;
        this.props.openPopup();
    }

    render() {
        const { open, isMobileApp } = this.state;
        const { classes, myMenu, session } = this.props;

        const { mealDealMessaging } = session;




        return <div className={classes.fullHeight}>
            <Grid container alignItems="top" className={classes.stepText}>
                <Grid item xs={12} sm={8}>
                    <Typography variant={isMobileApp ? "h2" : "h1"}>{mealDealMessaging ? "My designs" : "My Menus"}</Typography>

                    <Typography variant={this.state.isMobileApp ? "body2" : "body1"}>

                        {mealDealMessaging ? "Designs you have created previously are listed below." : "Menus you have created previously are listed below."}
                          
                    </Typography>
                </Grid>


                <Grid item xs={12} sm={4} >
                    <Box display="flex" justifyContent="flex-end">
                        <Button variant="contained" className={classes.myMenuButton} size={isMobileApp ? "small" : "medium"} component={Link} to="choose-style">{mealDealMessaging ? "Create new design" : "Create new menu"}</Button>
                    </Box>
                </Grid>
            </Grid>

            <Grid item xs={12} className={classes.toolbar}>
                <Grid container alignItems="flex-start" spacing={this.state.isMobileApp ? 1 : 5} direction="column">
                    <Grid item>
                        <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize={this.state.isMobileApp ? "small" : "default"} />}>
                            <Link color="inherit" href="/" className={classes.link}>
                                <HomeIcon className={classes.icon} />
                            </Link>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container direction="column" className={classes.container}>
                <MyMenuList
                    changeMyMenuTitle={this.handleChangeMyMenuTitle}
                    deleteMyMenu={this.handleDeleteMyMenu} />
            </Grid>

            <MyMenuTitlePopup
                menu={this.menu}
                saveChange={this.handleSaveChange}
                isPopupOpen={myMenu.isPopupOpen}
                isMobileApp={isMobileApp}
                closePopup={this.handleClosePopup} />

            <Dialog
                maxWidth="md"
                open={open}
                onClose={this.handleCloseDeleteDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">

                <DialogTitle id="alert-dialog-title">Delete design?</DialogTitle>

                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Click Confirm to delete '{this.menu !== undefined ? this.menu.title : ""}'.
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button size={isMobileApp ? "small" : "large"} className={classes.cancel} onClick={this.handleCloseDeleteDialog}>
                        Cancel
                    </Button>

                    <Button size={isMobileApp ? "small" : "large"} className={classes.save} onClick={this.handleDeleteConfirmation} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    }
}

const mapStateToProps = (state: AppState) => ({
    myMenu: state.myMenu,
    session: state.global.session
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        showNavigation: (showNavigation: boolean) => dispatch(globalActions.showNavigation(showNavigation)),
        printPalPage: (printPalPage: boolean) => dispatch(myMenuActions.printPalPage(printPalPage)),
        openPopup: () => dispatch(myMenuActions.openPopup()),
        closePopup: () => dispatch(myMenuActions.closePopup()),
        saveMenuTitle: (id: number, title: string) => dispatch(myMenuOperations.saveMenuTitle(id, title)),
        deleteMenu: (id: number) => dispatch(myMenuOperations.deleteMenu(id)),
        createMenu: (formatStyleId: number, name: string) => dispatch(myMenuOperations.createMenu(formatStyleId, name))
    }
}

export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(MyMenus)));
import React from "react";
import { connect } from "react-redux";
import { Dialog, DialogContent, Grid, List, ListItem, withStyles, WithStyles } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab"
import TextField from "../form/TextField";
import { IMenuState, menuOperations } from "../../store/menu";
import ICustomTheme from "../../interfaces/ICustomTheme";
import ILibraryImage from "../../interfaces/ILibraryImage";

import bg from "../../assets/trans_bg.png";
import logo from "../../assets/britvic_sensational_drinks_header_logo.png";
import DialogClose from "./DialogClose";

const styles = (theme: ICustomTheme) => ({
    logo: {
        width: 119
    },
    title: {
        color: theme.palette.secondary.main,
        fontSize: 18,
        fontWeight: "bold",
        marginBottom: 0
    },
    logoWrapper: {
        padding: "10px 20px 10px 20px",
        marginRight: 15,
        textAlign: "center"
    },
    list: {
        marginRight: 15,
        color: "#696969",
        "& li:hover": {
            backgroundColor: "rgba(230, 230, 230, 0.996078431372549)"
        }
    },
    selected: {
        backgroundColor: "rgba(230, 230, 230, 0.996078431372549)"
    },
    search: {
        marginTop: 15,
        width: "65%",
        "& .MuiAutocomplete-popupIndicator": {
            color: theme.palette.secondary.main,
            padding: 0,
            marginRight: 0,
            transform: "scaleX(-1)"
        },
        "& .MuiAutocomplete-popupIndicatorOpen": {
            transform: "scaleX(-1)"
        }
    },
    images: {
        overflow: "auto",
        height: 830,
        marginBottom: 50,
        width: "100%",//Fixes scrollbar being cut off
        "& p": {
            marginTop: 5
        }
    },
    bg: {
        position: "relative",
        backgroundImage: `url(${bg})`,
        backgroundRepeat: "repeat",
        backgroundPosition: "center",
        width: 189,
        height: 225,
        cursor: "pointer",
        boxSizing: "content-box",
        "&:hover": {
            border: `4px solid ${theme.palette.secondary.main}`,
            margin: -4
        },
        "& img": {
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "100%",
            height: "auto",
            maxHeight: "100%"
        }
    },
    dialog: {
        padding: 0,
        width: "100%",
        maxWidth: 1050
    }
})

interface IImageLibraryState {
    category?: string;
}

interface IImageLibraryProps {
    show: boolean,
    getImages: any,
    onClose: any,
    menu: IMenuState,
    onSelect: any
}

class ImageLibrary extends React.Component<IImageLibraryProps & WithStyles, IImageLibraryState> {
    constructor(props: any) {
        super(props);

        this.state = {
            category: undefined
        };
    }

    componentDidMount() {
        if (!this.props.menu.images)
            this.props.getImages();
    }

    handleCategory = (category?:string) => {
        this.setState({ category });
    }

    handleSelect = (image: ILibraryImage) => {
        this.props.onSelect(image);
        this.props.onClose();
    }

    filterImages = (images: ILibraryImage[]): ILibraryImage[] => {
        const { category } = this.state;
        return images.filter(i => !category || i.categories.indexOf(category) !== -1);
    }

    render() {
        const { onClose, show, menu, classes } = this.props;
        const { category } = this.state;

        return <Dialog onClose={onClose} open={show} PaperProps={{ className: classes.dialog }}>
            <DialogClose onClose={onClose} />

            <DialogContent style={{ padding: 0 }}>
                <Grid container>
                    <Grid item xs="auto">
                        <div className={classes.logoWrapper}>
                            <img alt="logo" src={logo} className={classes.logo} />
                            <h1 className={classes.title}>Image Library</h1>
                        </div>

                        <List className={classes.list}>
                            <ListItem button className={`${!category ? classes.selected : ""}`} onClick={() => this.handleCategory(undefined)}>
                                Show all
                            </ListItem>

                            {menu.imageCategories?.map((cat: string, index: number) => (
                                <ListItem button key={index} className={`${category === cat ? classes.selected : ""}`} onClick={() => this.handleCategory(cat)}>
                                    {cat}
                                </ListItem>
                            ))}
                        </List>
                    </Grid>

                    <Grid item xs>
                        <Autocomplete
                            fullWidth
                            options={menu.images ?? []}
                            getOptionLabel={(option: ILibraryImage) => option.title}
                            renderInput={(params) => <TextField {...params} className={classes.search} placeholder="Search for a product..." />}
                            popupIcon={<Search />}
                            onChange={(ev: any, value: ILibraryImage | null) => {
                                if (value)
                                    this.handleSelect(value);
                            }}
                        />

                        <Grid container spacing={2} className={classes.images} alignContent="flex-start">
                            {menu.images && menu.images.length > 0 ? this.filterImages(menu.images).map(image =>
                                <Grid key={image.imageLibraryId} item xs>
                                    <div className={classes.bg} onClick={() => this.handleSelect(image)}>
                                        <img alt={image.title} src={image.thumbPath ?? image.filePath} />
                                    </div>
                                    {image.title && <p>{image.title}</p>}
                                </Grid>
                            ) :
                                <div style={{ width: "100%", textAlign: "center" }}>No images available</div>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>;
    }
}

const mapStateToProps = (state: any) => {
    return {
        menu: state.menu
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getImages: () => dispatch(menuOperations.getImages())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles as any)(ImageLibrary));
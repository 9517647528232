import React, {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../store";
import PosButton from "../POS/posButton/PosButton";
import QuantitySelector, { QuantityButtons } from "./QuantitySelector";
import { PlaceOrderSharedProps } from "../../pages/PlaceOrderWithFormats";
import { usePrintPalFormatsOptionsStyles } from "../../styles";

interface PrintPalFormatsOptionsProps {
    isSaving: boolean | undefined,
    onSubmit: () => void,
    onChange: FormatFormCallback,
    placeOrderProps: PlaceOrderSharedProps,
    failedIPValidation: boolean,
    failedAtLeastOneValidation: boolean,
    showMagic8Option: boolean
}

interface DealOptions {
    onlyDrinksFromRange: boolean,
    extendedSpaceForDrinks: boolean,
    additionalDrinks: boolean,
    firstTimeUsedInCalenderYear: boolean,
    IPApproval: boolean
}

export interface PrintPalFormatOptionsForm {
    dealOptions: DealOptions,
    voucher: string,
    quantities: FormatQuantityOption[],
    magic8Stickers: boolean;
    inTheDealStickers: number;
    britvicEmployeeId?: string
    wobblerArmsAmount: number
}

export interface FormatQuantityOption {
    formatId: number
    formatKey: string
    price: number
    quantity: number
}

interface Magic8Format {
    formatKey: string,
    imgUrl: string,
    magic8Id: number,
    magic8PerFormat: number,
    formatId: string
}

interface FetchResult {
    response?: Response;
    error?: any;
    id?: string;
    ok: boolean;
}

export default function PrintPalFormatsOptions(props: PrintPalFormatsOptionsProps) {
    const { availableFormatsData, totalPrice, formState: form } = props.placeOrderProps;
    const [includeM8, setIncludeM8] = useState<boolean>(false);
    const [magic8Formats, setMagic8Formats] = useState<Magic8Format[]>([]);
    const setForm = props.onChange;

    // If an API gets introduced to fetch these values replace these with those calls.
    const tickBoxOptions: {
        title: string,
        key: keyof DealOptions,
        required?: boolean
    }[] = [
            { title: "Only drink(s) product(s) from current range", key: "onlyDrinksFromRange" },
            { title: "Has extended space/facings for drinks(s) currently stocked", key: "extendedSpaceForDrinks" },
            { title: "Additional drink(s) products(s) to current range through new distribution", key: "additionalDrinks" },
            { title: "Tick box if first time this outlet has used the portal this calendar year", key: "firstTimeUsedInCalenderYear" },
            { title: "Tick this box to confirm site manager has received approval for use of any branded IP in this deal", key: "IPApproval", required: true }
        ];
    const classes = usePrintPalFormatsOptionsStyles();
    const { menu, global } = useSelector((state: AppState) => state);

    const editForm = (selector: (p: PrintPalFormatOptionsForm) => any, type?: string) => {
        if (form != undefined) {
            const clone = { ...form };
            if(includeM8){
                if(type && type === "quantities"){
                    return;
                }
            }
            selector(clone);
            setForm(clone);
        } else console.log("Unable to edit form as formState is undefined");
    }

    const total = () => {
        return totalPrice.toFixed(2);
    }

    const hasSelectedItems = () => {
        return form.quantities.reduce((accum, item) => accum + item.quantity, 0) !== 0;
    }

    const extractIdFromUrl = (url: string) => {
        const match = url.match(/[?&]formatId=([^&]*)/);
        return match ? match[1] : "";
    }

    const getMagic8Format = async (urls: string[]) => {
        try {
            const responses: FetchResult[] = await Promise.all(
                urls.map(url =>
                    fetch(url)
                    .then(response => ({
                        response,
                        id: extractIdFromUrl(url),
                        ok: response.ok
                    }))
                    .catch(error => ({ error, ok: false }))
                )
            );
            const jsonData = await Promise.all(
                responses
                .filter(response => response.ok)
                .map(async ({
                    response,
                    id
                }) => {
                    if (response instanceof Response) {
                        const data = await response.json();
                        return {
                            ...data,
                            formatId: id
                        };
                    } else {
                        throw new Error('Error response received');
                    }
                })
            );
            setMagic8Formats(jsonData);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
    }

    useEffect(() => {
        if (availableFormatsData && !includeM8) {
            const newFormState: PrintPalFormatOptionsForm = {
                dealOptions: {
                    onlyDrinksFromRange: false,
                    extendedSpaceForDrinks: false,
                    additionalDrinks: false,
                    firstTimeUsedInCalenderYear: false,
                    IPApproval: false
                },
                voucher: "MM-1256-789567",
                quantities: [],
                inTheDealStickers: 0,
                magic8Stickers: includeM8,
                britvicEmployeeId: global.user?.britvicEmployeeId,
                wobblerArmsAmount: 0
            };

            for (const item of availableFormatsData) {
                let existingFormat = newFormState.quantities.find((f) => f.formatId == item.id);
                if (!existingFormat) {
                    newFormState.quantities.push({ formatId: item.id, formatKey: item.formatKey, quantity: 0, price: item.price })
                }
            }
            setForm(newFormState);
        }

        const potentialOrderData = sessionStorage.getItem("order");

        if (potentialOrderData !== null) {
            setForm(JSON.parse(potentialOrderData).form);
        }
    }, [availableFormatsData, includeM8]);

    useEffect(() => {
        let latestOrders = menu?.menuData?.latestOrderQuantities;
        let formatIdsUrl: string[] = [];
        latestOrders?.forEach((row) => {
            formatIdsUrl.push(`/api/formats/getMagic8?formatId=${row?.formatId}`);
        });
        getMagic8Format(formatIdsUrl);
    }, []);

    useEffect(() => {
        if(magic8Formats && magic8Formats.length > 0 && includeM8){
            let latestOrder = menu?.menuData?.latestOrderQuantities ?? [];
            const newFormState: PrintPalFormatOptionsForm = {
                dealOptions: {
                    onlyDrinksFromRange: false,
                    extendedSpaceForDrinks: false,
                    additionalDrinks: false,
                    firstTimeUsedInCalenderYear: false,
                    IPApproval: false
                },
                voucher: "MM-1256-789567",
                quantities: [],
                inTheDealStickers: 0,
                magic8Stickers: includeM8,
                britvicEmployeeId: global.user?.britvicEmployeeId,
                wobblerArmsAmount: 0
            };
            for (const item of latestOrder) {
                let existingFormat = newFormState.quantities.find((f) => f.formatId == item.formatId);
                if (!existingFormat) {
                    let magic8 = magic8Formats.find((row) => row?.formatId === item?.formatId.toString());;
                    if(magic8){
                        newFormState.quantities.push({ formatId: item.formatId, formatKey: magic8.formatKey, quantity: item?.quantity * magic8?.magic8PerFormat, price: 0 })
                    }else{
                        newFormState.quantities.push({ formatId: item.formatId, formatKey: "", quantity: 0, price: 0 })
                    }
                }
            }
            setForm(newFormState);
        }
    }, [magic8Formats, includeM8]);

    return <div className={classes.container}>
        <h1>{menu.menuData?.title}</h1>
        <div className={classes.tickBoxContainer}>
            <p style={{marginBottom: 5}}>Tick the below boxes where relevant - this deal includes:</p>
            <span style={{ color: "red", display: "block", height: 27 }} >                
                {
                    props.failedAtLeastOneValidation
                        && ![...tickBoxOptions].splice(0, 4)
                            .map(pair => form.dealOptions[pair.key])
                            .includes(true) ? "You must select at least one of the first four options" : null
                }
                </span>
            {
                tickBoxOptions.map(pair => <>
                    <input checked={form.dealOptions[pair.key]}
                        onChange={(e) => editForm(f => f.dealOptions[pair.key] = !f.dealOptions[pair.key])}
                        type="checkbox" /><label style={{fontWeight: pair.required ? "bold": "normal"}}>{pair.required ? <span style={{ color: "red" }}></span> : null} {pair.title}</label><br />

                    {
                        pair.key == "IPApproval" && props.failedIPValidation && !form.dealOptions[pair.key] ? <span style={{ color: "red", display: "block", marginLeft: 38}}>You must accept this option.</span> : null
                    }
                </>)
            }
        </div>
        <h3>Please make sure you have fully proof-read your design</h3>
        <p>Your <b>Cost Centre Code</b> is:</p>
        <input
            className={classes.voucherInput} readOnly type="text" value={form.britvicEmployeeId} onChange={(e) => editForm(f => f.voucher = e.target.value)} />
        <h2>Choose your quantity</h2>
        <p>Do you want to add anything to your order?</p>
        <div>
            {
                availableFormatsData?.map((quantity, index) => {
                    let quantityIndex = form.quantities.findIndex((q) => q.formatId == quantity.id);
                    if (quantityIndex > -1 && quantity?.allowPurchase) {
                        return (
                            <QuantitySelector index={index} name={quantity.title} multiples={quantity.availableInMultipleOf}
                                onChange={(n) => { editForm(f => f.quantities[quantityIndex as number].quantity = n, "quantities") }}
                                price={quantity.price} value={form.quantities[quantityIndex].quantity} />
                        )
                    }
                })
            }
        </div>
        <br />
        <div>
            <p style={{fontWeight: "bold"}}>Use the quantity selector below to add or remove products from your order.</p>
            <div style={{display: "flex", alignItems: "center", paddingLeft: 10}}>
                <QuantityButtons 
                    onClickDecrease={() => { editForm(f => f.wobblerArmsAmount = f.wobblerArmsAmount - 1)}}
                    onClickIncrease={() => { editForm(f => f.wobblerArmsAmount = f.wobblerArmsAmount + 1)}}
                    value={form.wobblerArmsAmount}
                />
                <div style={{marginLeft: 40}}>Wobbler Arms  (you need one for each Wobbler required)</div>
            </div>
        </div>
        <br/>
        <div style={{ display: "flex", alignItems: "center", paddingLeft: 10 }}>
            <QuantityButtons
                onClickDecrease={() => { editForm(f => f.inTheDealStickers = f.inTheDealStickers - 1) }}
                onClickIncrease={() => { editForm(f => f.inTheDealStickers = f.inTheDealStickers + 1) }}
                maxQuantity={10}
                value={form.inTheDealStickers}
            />
            <div style={{ marginLeft: 40 }}>Include free <b>In The Deal</b> stickers with order</div>
        </div>
        <br />
        <div className={classes.additionalQuestionContainer}>
            {props.showMagic8Option && <>
                <input type="checkbox" checked={form.magic8Stickers} onChange={() => {editForm(f => f.magic8Stickers = !f.magic8Stickers); setIncludeM8(!includeM8) }} />
                <label>Include free <b>Magic8</b> stickers with order</label><br />
            </>}
        </div>
        <br />
        <p style={{ marginBottom: 30, fontSize: "1.25rem" }}><b>Total £{total()} (Including delivery)</b></p>
        <div style={{ display: "flex", alignItems: "center"}}>
            <PosButton text={props.isSaving ? "Wait" : "Place order"} isDisabled={props.isSaving || (!hasSelectedItems() && !includeM8)} onClick={() => { props.onSubmit() }} />
            {
                !hasSelectedItems() && <span style={{fontWeight: "bold", marginLeft: 10}}>You must order prints to proceed</span>
            }
        </div>
        <br />
    </div >
};

type FormatFormCallback = (f: PrintPalFormatOptionsForm) => void;
import React, { useState } from "react";
import LoadingSpinner from "./LoadingSpinner";

function LazyIcon(props: LazyIconProps) {
    const [isLoaded, setIsLoaded] = useState(false);

    return <div style={{
        width: props.width,
        height: props.height,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }}>
        {!isLoaded ? <LoadingSpinner width={79} /> : null}
        <img
            src={props.imageUrl} style={{
                maxWidth: props.width,
                maxHeight: props.height
            }}
            onLoad={() => setIsLoaded(true)} onError={ () => setIsLoaded(true)} /> </div>
}

interface LazyIconProps {
    imageUrl: string;
    height: number | undefined;
    width: number | undefined;
}

export default LazyIcon;
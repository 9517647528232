import { Grid, Typography, WithStyles } from "@material-ui/core";
import React from "react";
import Editable from "../decorators/Editable";
import StyleInjector from "../decorators/StyleInjector";
import Image from "./shared/Image";
import TextField from "../form/TextField";
import ImageUpload from "../form/ImageUpload";
import { menuOperations } from "../../store/menu";
import { connect } from "react-redux";
import ILibraryImage from "../../interfaces/ILibraryImage";

interface ICoverProps {
    id: string,
    title: string,
    backgroundImage: string,
    logoImage: string,
    bottomText?: string,
    bottomNumber?: string,
    uploadFile: any
}

const styles = ({
    root: {
        backgroundPosition: "center",
        backgroundSize: "cover"
    },
    border: {
        position: "absolute",
        width: "100%",
        height: "100%"
    }
});

function Cover(props: ICoverProps & WithStyles) {
    const { id, logoImage, backgroundImage, title, bottomText, bottomNumber, classes, uploadFile } = props;

    const data = {
        id,
        name: "Cover page",
        initialValues: {
            logoImage,
            backgroundImage,
            title,
            bottomText,
            bottomNumber
        },
        state: {
            formData: undefined,
            logoFormData: undefined,
            error: false,
            logoError: false
        },
        fields: [
            {
                label: "Title text",
                render: (onChange: any, dataMapping: any) => {
                    return <TextField name="title" value={dataMapping["title"]} onChange={onChange} />
                }
            },
            {
                label: "Bottom text",
                render: (onChange: any, dataMapping: any) => {
                    return <TextField fullWidth multiline rows={4} name="bottomText" value={dataMapping["bottomText"]} onChange={onChange} />
                }
            },
            {
                label: "Bottom phone number",
                render: (onChange: any, dataMapping: any) => {
                    return <TextField fullWidth name="bottomNumber" value={dataMapping["bottomNumber"]} onChange={onChange} />
                }
            },
            {
                label: "Background image",
                render: (onChange: any, dataMapping: any, state: { onSetState: any, editableState: any }) => {
                    return <React.Fragment>
                        {state.editableState.error && <Typography variant="body2" gutterBottom color="error">Image upload failed, please make sure your image is under 5mb and is a jpg or png</Typography>}

                        <ImageUpload
                            imageKey="backgroundImage"
                            dataMapping={dataMapping}
                            fileUploaded={(formData: FormData) => {
                                state.onSetState({
                                    editableState: { ...state.editableState, formData, error: false }
                                });
                            }}
                            imageSelected={(image: ILibraryImage) => {
                                state.onSetState({
                                    dataMapping: { ...dataMapping, backgroundImage: image.filePath },
                                    editableState: { ...state.editableState, formData: null, error: false }
                                });
                            }} />
                    </React.Fragment>
                }
            },
            {
                label: "Logo",
                render: (onChange: any, dataMapping: any, state: { onSetState: any, editableState: any }) => {
                    return <React.Fragment>
                        {state.editableState.logoError && <Typography variant="body2" gutterBottom color="error">Image upload failed, please make sure your image is under 5mb and is a jpg or png</Typography>}

                        <ImageUpload
                            name="logo"
                            imageKey="logoImage"
                            dataMapping={dataMapping}
                            fileUploaded={(formData: FormData) => {
                                state.onSetState({
                                    editableState: { ...state.editableState, logoFormData: formData, logoError: false }
                                });
                            }}
                            imageSelected={(image: ILibraryImage) => {
                                state.onSetState({
                                    dataMapping: { ...dataMapping, logoImage: image.filePath },
                                    editableState: { ...state.editableState, logoFormData: null, logoError: false }
                                });
                            }} />
                    </React.Fragment>
                }
            }
        ],
        onSaved: (dataMapping: any, state: any) => {
            return new Promise<void>((resolve: any, reject: any) => {
                if (!state.editableState.formData) {
                    resolve();
                    return;
                }

                //On save upload the file to the server
                uploadFile(state.editableState.formData).then((file: any) => {
                    let url = window.location.origin + "/api/Files/" + file.fileId;
                    dataMapping.backgroundImage = url;
                    resolve();
                }).catch(() => {
                    console.error("File upload error");
                    reject();
                });

                //Reset form data
                state.onSetState({
                    editableState: { ...state.editableState, formData: undefined }
                });
            }).then(() => {
                return new Promise<void>((resolve: any, reject: any) => {
                    if (!state.editableState.logoFormData) {
                        resolve();
                        return;
                    }

                    //On save upload the file to the server
                    uploadFile(state.editableState.logoFormData).then((file: any) => {
                        let url = window.location.origin + "/api/Files/" + file.fileId;
                        dataMapping.logoImage = url;
                        resolve();
                    }).catch(() => {
                        console.error("File upload error");
                        reject();
                    });

                    //Reset form data
                    state.onSetState({
                        editableState: { ...state.editableState, logoFormData: undefined }
                    });
                });
            });
        }
    };

    return <Editable data={data} fullHeight>
        <Grid container className={classes.root} alignItems="center" direction="column" style={{ backgroundImage: `url(${backgroundImage})` }}>
            <img alt="border" className={classes.border}></img>

            <Grid item className={classes.image}>
                <Image border image={logoImage} />
            </Grid>

            <Grid item>
                <h1 className={classes.title}>{title}</h1>
            </Grid>

            <Grid item className={classes.bottom}>
                <p className={classes.bottomText}>{bottomText}</p>
                <p className={classes.bottomNumber}>{bottomNumber}</p>
            </Grid>
        </Grid>
    </Editable>;
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        uploadFile: (file: any) => dispatch(menuOperations.uploadFile(file))
    }
}

export default connect(null, mapDispatchToProps)(StyleInjector(styles, "cover")(Cover));
import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, InputLabel, TextField, Button, Checkbox, FormControlLabel } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { IMenuState, menuOperations } from "../../store/menu";
import { connect } from "react-redux";
import { IBritvicProduct } from "../../interfaces/IBritvicProduct";
import PriceField from "../form/PriceField";
import DialogClose from "./DialogClose";
import { IGinProduct } from "../../interfaces/IGinProduct";
import GinDropdown from "../form/GinDropdown";

interface IAddGinProduct {
    onClose: any,
    onSave: any,
    show: boolean,
    filter?: string,
    getProducts: any,
    menu: IMenuState,
    state: any
}

class AddGinProduct extends React.Component<IAddGinProduct> {
    componentDidMount() {
        if (!this.props.menu.gins) {
            this.props.getProducts(this.props.filter)
        }

    }

    render() {
        const { onSave, onClose, show, menu, state,filter } = this.props;
        const { onSetState, editableState } = state;
        const { newProduct } = editableState;

        return <Dialog onClose={onClose} fullWidth open={show}>
            <DialogClose onClose={onClose} />
            <DialogTitle>Add a new product</DialogTitle>

            <DialogContent style={{ minHeight: 300 }}>
                <Grid container>
                    <Grid item xs={12}>
                        <GinDropdown filter={filter} onChange={(ev: any, value: any) => {
                                if (menu.gins && value) {
                                    value.desc = value.flavourNote;
                                    Object.assign(newProduct, value);

                                    //Update state for below
                                    onSetState({ editableState: { ...editableState, newProduct } });
                                }
                        }} />
                    </Grid>

                    {newProduct && newProduct.name && <React.Fragment>
                        <Grid item xs={12}>
                            <InputLabel>Flavour Note</InputLabel>
                            {/* 
                            <FormControlLabel value="false" label="Use long description?" control={<Checkbox />} labelPlacement="end" onChange={(ev: any) => {
                                newProduct.desc = ev.target.checked ? newProduct.longDesc : newProduct.shortDesc;
                                onSetState({ editableState: { ...editableState, newProduct } })
                            }} />
                            */}

                            <TextField fullWidth multiline variant="filled" rows={5} disabled={true} value={newProduct.desc} />
                        </Grid>

                        <Grid item xs={12}>
                            <InputLabel>Price</InputLabel>
                            <PriceField fullWidth value={newProduct.price} onChange={(ev: any) => {
                                newProduct.price = parseFloat(ev.target.value);
                                onSetState({ editableState: { ...editableState, newProduct } })
                            }} />
                        </Grid>
                    </React.Fragment>}
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button variant="outlined" size="large" onClick={onClose}>Cancel</Button>
                <Button variant="contained" size="large" onClick={onSave}>Save</Button>
            </DialogActions>
        </Dialog>;
    }
}

const mapStateToProps = (state: any) => {
    return {
        menu: state.menu
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getProducts: (filter:string|undefined) => dispatch(menuOperations.getGinProducts(filter))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddGinProduct);
import React, { Component } from "react";
import { Grid, TextField, Typography, WithStyles, FormControlLabel, RadioGroup, Radio } from "@material-ui/core";
import Editable from "../decorators/Editable";
import StyleInjector from "../decorators/StyleInjector";
import ICustomTheme from "../../interfaces/ICustomTheme";
import PriceField from "../form/PriceField";

interface ITextProps {
    id: string,
    title: string,
    desc: any,
    textAlignment: string,
    hasTitleUnderline: boolean,
    theme: ICustomTheme,
    placeholder?: ITextProps
}

const styles = ({
    // desc: {
    //     textAlign: "center" as "center"
    // }
});

function Appearance(props: { onChange: any, dataMapping: any }) {
    const { onChange, dataMapping } = props;

    return <Grid container style={{ marginBottom: 30 }}>
        <Grid item xs={12} style={{marginTop: 15, marginBottom: 15}}>
            <span style={{ fontSize: 14 }}>Align text:</span>
        </Grid>

        <RadioGroup name="textAlignment" value={dataMapping["textAlignment"] ? dataMapping["textAlignment"] : "center"} aria-label="text alignment" onChange={onChange}>
            <Grid container>
                <Grid item>
                    <FormControlLabel value="left" label={<span style={{ fontSize: 14 }}>Left</span>}  control={<Radio />} labelPlacement="end"/>
                </Grid>

                <Grid item>
                    <FormControlLabel value="center" label={<span style={{ fontSize: 14 }}>Centre</span>}  control={<Radio />} labelPlacement="end"/>
                </Grid>

                <Grid item>
                    <FormControlLabel value="right" label={<span style={{ fontSize: 14 }}>Right</span>}  control={<Radio />} labelPlacement="end"/>
                </Grid>
            </Grid>
        </RadioGroup>
    </Grid>
}

class TextSection extends Component<WithStyles & ITextProps> {

    getTextAlignment = (textAlignment: string) => {
        return textAlignment === "left" ? "flex-start" : textAlignment === "right" ? "flex-end" : "center";
    }    

    render() {
        const { id, title, desc, classes, textAlignment, hasTitleUnderline, theme, placeholder } = this.props;
        ////debugger;
        const data = {
            id,
            name: theme.text?.priceOnly ? "Price" : "Text",
            initialValues: {
                title,
                desc,
                textAlignment,
                hasTitleUnderline
            },
            fields: [
                !theme.text?.priceOnly && {
                    label: "Appearance",
                    render: (onChange: any, dataMapping: any) => {
                        return <Appearance onChange={onChange} dataMapping={dataMapping}/>
                    }
                },
                !theme.text?.priceOnly && {
                    label: "Title text",
                    required: false,
                    render: (onChange: any, dataMapping: any) => {
                        return <TextField name="title" value={dataMapping["title"]} onChange={onChange} InputProps={{ disableUnderline: true }} variant="filled" />
                    }
                },
                {
                    label: theme.text?.priceOnly ? "Price" : "Description",
                    required: true,
                    render: (onChange: any, dataMapping: any) => {
                        return theme.text?.priceOnly ?
                            <PriceField name="desc" placeholder={placeholder ? placeholder["desc"] : undefined} fullWidth value={dataMapping["desc"]} onChange={onChange} /> :
                            <TextField multiline={true} fullWidth rows={5} name="desc" placeholder={placeholder ? placeholder["desc"] : undefined} value={dataMapping["desc"]} onChange={onChange} InputProps={{ disableUnderline: true }} variant="filled" />;
                    }
                }
            ]
        }

        return <Editable data={data} buttonText={theme.text?.priceOnly ? "Edit price" : undefined} changed={!!desc}>
            <Grid container direction="column" className={classes.root} alignItems={this.getTextAlignment(textAlignment)}>
                {title && <Grid item>
                    <Typography variant="h3">{title}</Typography>
                </Grid>}

                {title && hasTitleUnderline && <div className={classes.underline} />}

                <Grid item>
                    <Typography className={classes.desc} component="p" variant="body1">{theme.text?.priceOnly ? "£" + (desc ? desc.toFixed(2) : placeholder ? placeholder["desc"] : "") : desc ?? (placeholder ? placeholder["desc"] : "")}</Typography>
                </Grid>
            </Grid>
        </Editable>
    }
}

export default StyleInjector(styles, "text", true)(TextSection);
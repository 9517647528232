import React from "react"
import { Grid, Radio, Typography, FormControlLabel, Checkbox, RadioGroup, WithStyles } from "@material-ui/core";
import Editable from "../decorators/Editable";
import MenuImage from "./shared/Image";
import TextField from "../form/TextField";
import ImageUpload from "../form/ImageUpload";
import StyleInjector from "../decorators/StyleInjector";
import { connect } from "react-redux";
import { menuOperations } from "../../store/menu";
import ICustomTheme from "../../interfaces/ICustomTheme";
import ILibraryImage from "../../interfaces/ILibraryImage";

interface ITitleProps {
    id: string,
    title: string,
    subTitle: string,
    showDecorations: boolean,
    backgroundColour: boolean,
    backgroundCorners: boolean,
    showImage: boolean,
    showImageDecorations: boolean,
    imageCorners: boolean,
    image?: string,
    uploadFile: any,
    theme: ICustomTheme,
    placeholder?: ITitleProps
}

//Messy down there
function Appearance(props: { onChange: any, dataMapping: any, decoration: string }) {
    const { onChange, dataMapping, decoration } = props;

    return <Grid container style={{ marginBottom: 15 }}>
        <Grid item>
            <RadioGroup name="backgroundColour" defaultValue="false" value={dataMapping["backgroundColour"]?.toString() ?? "false"} aria-label="background colour" onChange={onChange}>
                <Grid container>
                    <Grid item>
                        <FormControlLabel value="false" label="No background colour" control={<Radio />} labelPlacement="end" />
                    </Grid>

                    <Grid item>
                        <FormControlLabel value="true" label="Apply background colour" control={<Radio />} labelPlacement="end" />
                    </Grid>
                </Grid>
            </RadioGroup>
        </Grid>

        <Grid item>
            <RadioGroup name="backgroundCorners" defaultValue="false" value={dataMapping["backgroundCorners"]?.toString() ?? "false"} aria-label="corners" onChange={onChange}>
                <Grid container>
                    <Grid item>
                        <FormControlLabel disabled={!dataMapping["backgroundColour"]} value="false" label="Background colour has square corners" control={<Radio />} labelPlacement="end" />
                    </Grid>

                    <Grid item>
                        <FormControlLabel disabled={!dataMapping["backgroundColour"]} value="true" label="Background colour has rounded corners" control={<Radio />} labelPlacement="end" />
                    </Grid>
                </Grid>
            </RadioGroup>
        </Grid>

        <Grid item>
            <Grid container alignItems="center">
                <Grid item>
                    <FormControlLabel name="showDecorations" onChange={onChange} checked={dataMapping["showDecorations"]} label="Show decorations" labelPlacement="end" control={<Checkbox />} />
                </Grid>

                <Grid item>
                    <img className={decoration} />
                </Grid>

                <Grid item>
                    <span style={{ fontSize: 13, marginLeft: 15 }}>(Change style of decoration in 'Styles')</span>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
}

function Image(props: { onChange: any, dataMapping: any, decoration: string, fileUploaded: any, imageSelected: any }) {
    const { onChange, dataMapping, decoration, fileUploaded, imageSelected } = props;

    return <Grid container>
        <Grid item>
            <FormControlLabel name="showImage" onChange={onChange} value={dataMapping["showImage"]} checked={dataMapping["showImage"]} label="Show image in place of right-hand decoration" labelPlacement="end" control={<Checkbox />} />
        </Grid>

        <Grid container alignItems="center">
            <Grid item>
                <FormControlLabel disabled={!dataMapping["showImage"]} name="showImageDecorations" onChange={onChange} value={dataMapping["showImageDecorations"]} checked={dataMapping["showImageDecorations"]} label="Show decorative box around image" labelPlacement="end" control={<Checkbox />} />
            </Grid>

            <Grid item>
                <img className={decoration} />
            </Grid>

            <Grid item>
                <span style={{ fontSize: 13, marginLeft: 15 }}>(Change style of decoration in 'Styles')</span>
            </Grid>
        </Grid>

        <RadioGroup name="imageCorners" defaultValue="false" value={dataMapping["imageCorners"]?.toString() ?? "false"} aria-label="corners" onChange={onChange}>
            <Grid container>
                <Grid item>
                    <FormControlLabel disabled={!dataMapping["showImage"]} value="false" label="Outline box has Square corners" control={<Radio />} labelPlacement="end" />
                </Grid>

                <Grid item>
                    <FormControlLabel disabled={!dataMapping["showImage"]} value="true" label="Outline box has rounded corners" control={<Radio />} labelPlacement="end" />
                </Grid>
            </Grid>
        </RadioGroup>

        <Grid item style={{ marginTop: 20 }}>
            <ImageUpload fileUploaded={fileUploaded} dataMapping={dataMapping} imageSelected={imageSelected} />
        </Grid>
    </Grid>
}

class Title extends React.Component<ITitleProps & WithStyles> {
    render() {
        const { title, subTitle, showDecorations, classes, id, image, backgroundColour, backgroundCorners, showImage, showImageDecorations, imageCorners, uploadFile, theme, placeholder } = this.props;

        const data = (decoration: string) => ({
            id,
            name: "Title",
            initialValues: {
                title,
                subTitle,
                showDecorations,
                showImage,
                image,
                backgroundColour,
                backgroundCorners,
                showImageDecorations,
                imageCorners,
                placeholder
            },
            state: {
                formData: undefined,
                error: false
            },
            fields: [
                {
                    label: "Title text",
                    required: true,
                    render: (onChange: any, dataMapping: any) => {
                        return <TextField name="title" value={dataMapping["title"]} placeholder={dataMapping["placeholder"]?.title} onChange={onChange} />
                    }
                }, {
                    label: "Subtitle text (or leave blank)",
                    render: (onChange: any, dataMapping: any) => {
                        return <TextField multiline fullWidth name="subTitle" value={dataMapping["subTitle"]} placeholder={dataMapping["placeholder"]?.subTitle} onChange={onChange} />
                    }
                }, {
                    label: "Appearance",
                    render: (onChange: any, dataMapping: any) => {
                        return <Appearance decoration={decoration} onChange={onChange} dataMapping={dataMapping} />
                    }
                }, {
                    label: "Image",
                    render: (onChange: any, dataMapping: any, state: any) => {
                        return <React.Fragment>
                            {state.editableState.error && <Typography variant="body2" gutterBottom color="error">Image upload failed, please make sure your image is under 5mb and is a jpg or png</Typography>}

                            <Image
                                decoration={decoration}
                                onChange={onChange}
                                dataMapping={dataMapping}
                                fileUploaded={(formData: FormData) => {
                                    state.onSetState({
                                        editableState: { ...state.editableState, formData, error: false }
                                    });
                                }}
                                imageSelected={(image: ILibraryImage) => {
                                    state.onSetState({
                                        dataMapping: { ...dataMapping, image: image.filePath },
                                        editableState: { ...state.editableState, formData: null, error: false }
                                    });
                                }}
                            />
                        </React.Fragment>
                    }
                }
            ],
            onSaved: (dataMapping: any, state: any) => {
                return new Promise<void>((resolve: any, reject: any) => {
                    if (!state.editableState.formData) {
                        resolve();
                        return;
                    }

                    //On save upload the file to the server
                    uploadFile(state.editableState.formData).then((file: any) => {
                        var url = window.location.origin + "/api/Files/" + file.fileId;
                        dataMapping.image = url;
                        resolve();
                        return false;
                    }).catch(() => {
                        reject();
                        return true;
                    }).then((error: boolean) => {
                        //Reset form data
                        state.onSetState({
                            editableState: { ...state.editableState, formData: undefined, error }
                        });
                    });
                });
            }
        });

        return <Editable data={data(classes.titleLeft)}>
            {showImage && image && theme.title?.imageOnTop && <MenuImage fit square={!imageCorners} border={showImageDecorations} image={image} />}

            <Grid container justify="center" alignItems="center" wrap="nowrap" className={`${backgroundColour ? classes.background : ""} ${backgroundColour ? backgroundCorners ? classes.rounded : classes.square : ""}`}>
                <Grid item>
                    {showDecorations && <div className={classes.titleLeft} />}
                </Grid>

                <Grid item>
                    <div className={`${classes.root} ${showDecorations ? "decorated" : ""}`}>
                        <Typography variant="h1">{title ?? placeholder?.title}</Typography>
                        <Typography variant="h2">{subTitle ?? placeholder?.subTitle}</Typography>
                    </div>
                </Grid>

                <Grid item>
                    {!showImage && showDecorations && <div className={classes.title} />}
                    {!theme.title?.imageOnTop && showImage && image && <MenuImage fit square={!imageCorners} border={showImageDecorations} image={image} />}
                </Grid>
            </Grid>
        </Editable>
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        uploadFile: (file: any) => dispatch(menuOperations.uploadFile(file))
    }
};

export default connect(null, mapDispatchToProps)(StyleInjector({}, "title", true)(Title));
import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, InputLabel, TextField, Button, Checkbox, FormControlLabel } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { IMenuState, menuOperations } from "../../store/menu";
import { connect } from "react-redux";
import { IBritvicProduct } from "../../interfaces/IBritvicProduct";
import PriceField from "../form/PriceField";
import DialogClose from "./DialogClose";

interface IAddBritvicProduct {
    onClose: any,
    onSave: any,
    show: boolean,
    getProducts: any,
    menu: IMenuState,
    state: any,
    hideDescription: boolean
}

class AddBritvicProduct extends React.Component<IAddBritvicProduct> {
    componentDidMount() {
        this.props.getProducts(this.props.menu.menuData?.styleId)            
    }

    render() {
        const { onSave, onClose, show, menu, state, hideDescription } = this.props;
        const { onSetState, editableState } = state;
        const { newProduct } = editableState;

        return <Dialog onClose={onClose} fullWidth open={show}>
            <DialogClose onClose={onClose} />
            <DialogTitle>Add a new Britvic product</DialogTitle>

            <DialogContent style={{ minHeight: 300 }}>
                <Grid container>
                    <Grid item xs={12}>
                        <InputLabel>Add a new Britvic Product</InputLabel>

                        <Autocomplete
                            fullWidth
                            options={menu.products ?? []}
                            getOptionLabel={(option: IBritvicProduct) => option.name}
                            renderInput={(params) => <TextField {...params} placeholder="Start typing the product name..." variant="filled" />}
                            onChange={(ev: any, value: any) => {
                                if (menu.products && value) {
                                    value.desc = value.shortDesc;
                                    Object.assign(newProduct, value);

                                    //Update state for below
                                    onSetState({ editableState: { ...editableState, newProduct } });
                                }
                            }}
                        />
                    </Grid>

                    {newProduct && newProduct.name && <React.Fragment>
                        {
                            !hideDescription &&
                            <Grid item xs={12}>
                                <InputLabel>Product description</InputLabel>

                                <FormControlLabel value="false" label="Use long description?" control={<Checkbox />} labelPlacement="end" onChange={(ev: any) => {
                                    newProduct.desc = ev.target.checked ? newProduct.longDesc : newProduct.shortDesc;
                                    onSetState({ editableState: { ...editableState, newProduct } })
                                }} />

                                <TextField fullWidth multiline variant="filled" rows={5} disabled={true} value={newProduct.desc} />
                            </Grid>
                        }

                        <Grid item xs={12}>
                            <InputLabel>Price</InputLabel>
                            <PriceField fullWidth value={newProduct.price} onChange={(ev: any) => {
                                newProduct.price = parseFloat(ev.target.value);
                                onSetState({ editableState: { ...editableState, newProduct } })
                            }} />
                        </Grid>
                    </React.Fragment>}
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button variant="outlined" size="large" onClick={onClose}>Cancel</Button>
                <Button variant="contained" size="large" onClick={onSave}>Save</Button>
            </DialogActions>
        </Dialog>;
    }
}

const mapStateToProps = (state: any) => {
    return {
        menu: state.menu
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getProducts: (styleId: number) => dispatch(menuOperations.getBritvicProducts(styleId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddBritvicProduct);
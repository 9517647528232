import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useMemo } from "react";
import { ModalRenderState } from "../layout/EditableModal";
import _ from "lodash";
import { ContentDisplay, ContentEditor } from "./content-panel-components";
import { IModalData } from "../../store/menu";
import EditableAbsolute from "../decorators/EditableAbsolute";
import useHelperMethods from "../../utils/useHelperMethods";
import CategorySelector from "../form/CategorySelector";
import { SharedComponentConfig, Translation, TranslationPosition } from "../../types";
import { useSelector } from "react-redux";
import { AppState } from "../../store";
import { getMainProducts } from "../../utils/common";

interface IFieldGroupsEditor {
    dataMapping: FieldGroupsDataMapping
    config: FieldGroupsConfig
    state: ModalRenderState
}

interface FieldGroupsConfig extends SharedComponentConfig {
    translations: Translation[]
    showNullField?: boolean
    fieldGroups: FieldGroupConfig[]
    typeIdentifier?: string
    textTranslations?: Translation[]
    translations2?: Translation[]
}

interface FieldGroupConfig {
    id: string
    groupImage?: string
    fields?: FieldConfig[]
}

interface FieldConfig<T = any> {
    name: string
    fieldTitle?: string
    props?: T
}

interface FieldGroupsDataMapping {
    selectedFieldGroupId: string
    fieldGroups: FieldGroupData[]
    formatOverride?: FormatOverride
}

interface FieldGroupData {
    id: string
    fields: FieldData[]
}

interface FormatOverride {
    newFormat: string,
    oldFormat: string,

    documentProperty: string,
    documentPropertyValue: string | number | boolean,
}

export interface FieldData<T = any> {
    name: string,
    component: string
    data: T,
    translations?: [],
    hideTextStroke?: boolean,
    hideColorsAndFont?: boolean
    heading?: string
}

export type OnChangeField<T = any> = (fieldName: string, data: T, overwriteProps?: FieldData[]) => void;

export type FieldEditor<T, K> = {
    config: T,
    field: FieldData<K>,
    onChangeField: OnChangeField<K>
}

export type FieldComponent<T = any, K = any> = {
    field: FieldData<T>
    config?: FieldConfig<K>
    scale?: number
    width?: number
    modalData: IModalData
    scaledPosition: TranslationPosition //Used for freetext to auto scale font size to fit
    positionIndex: number //FloatingContentPanel format's position index, most only have 1 position but Prism has 3 and different positions can do different things to the content
    format: string,
}

type ImageFieldConfig = FieldConfig<{ images: { category: string, hideCategory?: boolean, src: string }[] }>;

const FieldGroupsEditor: React.FC<IFieldGroupsEditor> = ({ config, dataMapping, state }) => {
    const [fieldGroups, setFieldGroups] = useState(dataMapping.fieldGroups);
    const [selectedFieldGroupId, setSelectedFieldGroupId] = useState(dataMapping.selectedFieldGroupId);
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const selectedFieldGroupIndex = useMemo(() => { return fieldGroups.findIndex((group) => group.id == selectedFieldGroupId) }, [selectedFieldGroupId])
    const selectedFieldGroupConfig = useMemo(() => { return config.fieldGroups.find((group) => group.id == selectedFieldGroupId) }, [selectedFieldGroupId]);
    const selectedFieldGroup = fieldGroups[selectedFieldGroupIndex];
    const menuState = useSelector((state: AppState) => state.menu);

    useEffect(() => {
        state.onSetState({
            dataMapping: {
                //...dataMapping,
                fieldGroups: fieldGroups
            }
        })
    }, [fieldGroups]);

    useEffect(() => {
        state.onSetState({
            dataMapping: {
                //...dataMapping,
                selectedFieldGroup: selectedFieldGroupId
            }
        })
    }, [selectedFieldGroupId]);

    const onChangeField: OnChangeField = (fieldName: string, data: any, overwriteFields?: FieldData[]) => {
        let _fieldGroups = JSON.parse(JSON.stringify(fieldGroups)) as FieldGroupData[];
        let fieldGroup = _fieldGroups[selectedFieldGroupIndex];
        let fieldIndex = fieldGroup.fields.findIndex((field) => field.name == fieldName);
        fieldGroup.fields[fieldIndex].data = data;

        if(overwriteFields){
            overwriteFields.forEach((overwriteField) => {
                let overwriteFieldIndex = fieldGroup.fields.findIndex((field) => field.name == overwriteField.name);
                _.merge(fieldGroup.fields[overwriteFieldIndex].data, overwriteField.data);
            })
        }
        setFieldGroups(_fieldGroups);
    }

    const shouldUseCategoryFilters = (fieldConfig: FieldConfig | undefined) => fieldConfig?.props?.images != undefined && fieldConfig.props.images[0].category != undefined; 

    // override categories when using the lockupsection component
    const categoryOverrides = selectedFieldGroup.fields[0].component == "LockupSection"
        ? selectedFieldGroup.fields[0].data.selectedInputType === "TEXT" ? "Blank" : undefined
        : undefined;

    function filterFieldProps(fieldConfig: ImageFieldConfig): any {
        if (fieldConfig.props == undefined) {
            return fieldConfig.props;
        }

        const propsClone = { ...fieldConfig.props }
        let imagesClone = [ ...propsClone.images ];

        if (imagesClone === undefined) {
            return fieldConfig.props;
        }

        imagesClone = imagesClone
            .filter(img => (categoryOverrides ?? selectedCategories).includes(img.category) || (selectedCategories.length == 0 && categoryOverrides === undefined))
            .filter(img => {
                if (img.hideCategory) {
                    if (categoryOverrides?.includes(img.category)) {
                        return true;
                    }
                    return false;
                }
                return true;
            })
        

        // If lockup image
        if (selectedFieldGroup.fields[1]?.component === "ImageSection") {
            let currentImage = selectedFieldGroup.fields[1].data.image;
            let currentFieldImage: any = {}
            if(propsClone.images && propsClone.images.length > 0){
                let propsCloneImages = propsClone.images;
                let mainProducts = getMainProducts();
                propsCloneImages = propsCloneImages.concat(mainProducts);
                currentFieldImage = propsCloneImages.find(image => {
                    if(!(image.src.indexOf("/assets/") > -1)){
                        image.src = `/assets/products/${image.src}`
                    }
                    return image.src === currentImage;
                });
            }
            if (currentFieldImage !== undefined) {
                if (!imagesClone.map(ic => ic.category).includes(currentFieldImage.category)) {
                    const newCurrentImage = imagesClone.find(i => i.category === imagesClone[0].category);
                    if (newCurrentImage !== undefined) {
                        onChangeField(selectedFieldGroup.fields[1].name, {
                            ...selectedFieldGroup.fields[1].data,
                            image: newCurrentImage.src
                        })
                    }
                }
            }
        }
        propsClone.images = imagesClone;
        return propsClone;
    }

    return(
        <div>
            {
                fieldGroups.length > 1 && <div></div>
            }
            <div>
                {
                    selectedFieldGroup.fields.map((field) => {
                        const Component = (ContentEditor as any)[field.component];
                        const fieldConfig = selectedFieldGroupConfig?.fields?.find((f) => f.name == field.name);
                        return (
                            <div style={{
                                marginBottom: "1rem"
                            }}>

                                {
                                (!fieldConfig?.props?.productAndTextComponent) &&
                                shouldUseCategoryFilters(fieldConfig) && categoryOverrides === undefined && <CategorySelector
                                    categories={
                                        (fieldConfig as ImageFieldConfig)
                                            ?.props?.images
                                            .filter(r => !r.hideCategory)
                                            .map((r: { category: string }) => r.category as string)
                                            .filter((v, i, self) => self.indexOf(v) === i) ?? []
                                    }
                                    selectedCategories={selectedCategories}
                                    onSelectionChange={(category, selected) => {
                                        if (selected) {
                                            setSelectedCategories([...selectedCategories, category])
                                        } else {
                                            const newCategories = [...selectedCategories]
                                            newCategories.splice(selectedCategories.indexOf(category), 1)
                                            setSelectedCategories(newCategories);
                                        }
                                    }}
                                />}
                                {
                                    (fieldConfig?.fieldTitle) &&
                                    <div style={{
                                        fontWeight: "bold",
                                        marginBottom: "1rem"
                                    }}>{fieldConfig?.fieldTitle}</div>
                                }
                                {
                                    Component &&
                                    <Component config={shouldUseCategoryFilters(fieldConfig) ? filterFieldProps(fieldConfig as ImageFieldConfig) : fieldConfig?.props} field={field} onChangeField={onChangeField} />
                                }
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

type IFloatingContentPanel = {
    id: string
    selectedFieldGroupId: string
    fieldGroups: FieldGroupData[]
    formatSize: string
    scale: number
    config: FieldGroupsConfig
    isHovering: boolean,
    formatOverride?: FormatOverride
};

const FloatingContentPanel: React.FC<IFloatingContentPanel> = ({ fieldGroups, id, selectedFieldGroupId, config, formatSize, scale, isHovering, formatOverride }) => {
    const menuState = useSelector((state: AppState) => state.menu);
    // Lock up Position for BARKER Template based on mealdeal1_templateConfig.json
    let barkerLockUpPosition: TranslationPosition = {
        "width": 666,
        "height": 666,
        "left": 844,
        "top": 914,
        "z": 1
    };

    const modalData: IModalData<FieldGroupsDataMapping> = useMemo(() => { return {
        id,
        name: "Content panel",
        showMandatory: false,
        editPanelTitle: config.editPanelTitle,
        editPanelSubtitle: config.editPanelSubtitle,
        initialValues: {
            fieldGroups, 
            selectedFieldGroupId
        },
        fields: [
            {
                render: (onChange, dataMapping, state) => {
                    return <FieldGroupsEditor config={config} dataMapping={dataMapping} state={state} />
                }
            }
        ]
    } }, [fieldGroups, id, selectedFieldGroupId, config]);

    const { getScaledPosition } = useHelperMethods();

    let originalPosition = config.translations.find((tran) => tran.format == formatSize)?.position;
    let scaledPositions = Array.isArray(originalPosition) ? originalPosition.map((pos) => getScaledPosition(scale, formatSize, pos)) : [getScaledPosition(scale, formatSize, originalPosition)];

    const selectedFieldGroupIndex = useMemo(() => { return fieldGroups.findIndex((group) => group.id == selectedFieldGroupId) }, [selectedFieldGroupId]);
    const selectedFieldGroupConfig = useMemo(() => { return config.fieldGroups.find((group) => group.id == selectedFieldGroupId) }, [selectedFieldGroupId]);
    const selectedFieldGroup = selectedFieldGroupIndex > -1 ? fieldGroups[selectedFieldGroupIndex] : null;
    let containsHeading3 = false;
    let containsSubHeading3 = false;
    let textOptionSelected3 = false; // floatingContentPanel3 for styleId 45(mealdeal6 json), floatingContentPanel7 for styleId 46(mealdeal7 json)
    let textOptionSelected4 = false; // floatingContentPanel4 for styleId 45(mealdeal6 json), floatingContentPanel8 for styleId 46(mealdeal7 json)

    let editablePosition = config?.editablePosition;

    if(menuState?.menu && menuState?.menu.length > 0 && menuState.menuData?.styleId === 42){
        let floatingContentPanel9 = menuState?.menu[0]?.props?.items.filter((row: {props: {[key: string]: any}}) => row?.props?.id === "floatingContentPanel9");
        let floatingContentPanel10 = menuState?.menu[0]?.props?.items.filter((row: {props: {[key: string]: any}}) => row?.props?.id === "floatingContentPanel10");
        if(floatingContentPanel9 && floatingContentPanel9.length > 0){
            let fieldsGroups = floatingContentPanel9[0]?.props?.fieldGroups;
            if(fieldsGroups && fieldsGroups.length > 0){
                let fields = fieldsGroups[0]?.fields;
                if(fields && fields.length > 0){
                    let textValue = fields[0]?.data?.textValue;
                    if(textValue){
                        containsHeading3 = true;
                    }
                }
            }
        }
        if(floatingContentPanel10 && floatingContentPanel10.length > 0){
            let fieldsGroups = floatingContentPanel10[0]?.props?.fieldGroups;
            if(fieldsGroups && fieldsGroups.length > 0){
                let fields = fieldsGroups[0]?.fields;
                if(fields && fields.length > 0){
                    let textValue = fields[0]?.data?.textValue;
                    if(textValue){
                        containsSubHeading3 = true;
                    }
                }
            }
        }
    }

    if(menuState?.menu && menuState?.menu.length > 0 && (menuState?.menuData?.styleId === 45 || menuState?.menuData?.styleId === 39)){
        let floatingContentPanel3 = menuState?.menu[0]?.props?.items.filter((row: {props: {[key: string]: any}}) => row?.props?.id === "floatingContentPanel3");
        let floatingContentPanel4 = menuState?.menu[0]?.props?.items.filter((row: {props: {[key: string]: any}}) => row?.props?.id === "floatingContentPanel4");

        if(floatingContentPanel3 && floatingContentPanel3.length > 0){
            let fieldsGroups = floatingContentPanel3[0]?.props?.fieldGroups;
            if(fieldsGroups && fieldsGroups.length > 0){
                let fields = fieldsGroups[0]?.fields;
                if(fields && fields.length > 0){
                    let selectedType = fields[0]?.data?.selectedInputType;
                    if(selectedType === "TEXT"){
                        textOptionSelected3 = true;
                        textOptionSelected4 = false;
                        originalPosition = config.textTranslations ? config.textTranslations.find((tran) => tran.format == formatSize)?.position : config.translations.find((tran) => tran.format == formatSize)?.position;;
                        scaledPositions = Array.isArray(originalPosition) ? originalPosition.map((pos) => getScaledPosition(scale, formatSize, pos)) : [getScaledPosition(scale, formatSize, originalPosition)];
                    }
                }
            }
        }
        if(floatingContentPanel4 && floatingContentPanel4.length > 0){
            let fieldsGroups = floatingContentPanel4[0]?.props?.fieldGroups;
            if(fieldsGroups && fieldsGroups.length > 0){
                let fields = fieldsGroups[0]?.fields;
                if(fields && fields.length > 0){
                    let selectedType = fields[0]?.data?.selectedInputType;
                    if(selectedType === "TEXT"){
                        textOptionSelected4 = true;
                        originalPosition = config.textTranslations ? config.textTranslations.find((tran) => tran.format == formatSize)?.position : config.translations.find((tran) => tran.format == formatSize)?.position;;
                        scaledPositions = Array.isArray(originalPosition) ? originalPosition.map((pos) => getScaledPosition(scale, formatSize, pos)) : [getScaledPosition(scale, formatSize, originalPosition)];
                    }
                }
            }
        }
    }

    if(menuState?.menu && menuState?.menu.length > 0 && (menuState?.menuData?.styleId === 46 || menuState?.menuData?.styleId === 40)){
        let floatingContentPanel7 = menuState?.menu[0]?.props?.items.filter((row: {props: {[key: string]: any}}) => row?.props?.id === "floatingContentPanel7");
        let floatingContentPanel8 = menuState?.menu[0]?.props?.items.filter((row: {props: {[key: string]: any}}) => row?.props?.id === "floatingContentPanel8");

        if(floatingContentPanel7 && floatingContentPanel7.length > 0){
            let fieldsGroups = floatingContentPanel7[0]?.props?.fieldGroups;
            if(fieldsGroups && fieldsGroups.length > 0){
                let fields = fieldsGroups[0]?.fields;
                if(fields && fields.length > 0){
                    let selectedType = fields[0]?.data?.selectedInputType;
                    if(selectedType === "TEXT"){
                        textOptionSelected3 = true;
                        textOptionSelected4 = false;
                        originalPosition = config.textTranslations ? config.textTranslations.find((tran) => tran.format == formatSize)?.position : config.translations.find((tran) => tran.format == formatSize)?.position;;
                        scaledPositions = Array.isArray(originalPosition) ? originalPosition.map((pos) => getScaledPosition(scale, formatSize, pos)) : [getScaledPosition(scale, formatSize, originalPosition)];
                    }
                }
            }
        }
        if(floatingContentPanel8 && floatingContentPanel8.length > 0){
            let fieldsGroups = floatingContentPanel8[0]?.props?.fieldGroups;
            if(fieldsGroups && fieldsGroups.length > 0){
                let fields = fieldsGroups[0]?.fields;
                if(fields && fields.length > 0){
                    let selectedType = fields[0]?.data?.selectedInputType;
                    if(selectedType === "TEXT"){
                        textOptionSelected4 = true;
                        originalPosition = config.textTranslations ? config.textTranslations.find((tran) => tran.format == formatSize)?.position : config.translations.find((tran) => tran.format == formatSize)?.position;;
                        scaledPositions = Array.isArray(originalPosition) ? originalPosition.map((pos) => getScaledPosition(scale, formatSize, pos)) : [getScaledPosition(scale, formatSize, originalPosition)];
                    }
                }
            }
        }
    }
    let showEditableAbsolute = true;
    if(menuState?.menu && menuState?.menu.length > 0 && (menuState?.menuData?.styleId === 45 || menuState?.menuData?.styleId === 39)){
        if(config?.typeIdentifier === "floatingContentPanel4"){
            if(textOptionSelected3){
                showEditableAbsolute = false;
            }
        }else if(config?.typeIdentifier === "floatingContentPanel3"){
            if(textOptionSelected4 && !textOptionSelected3){
                showEditableAbsolute = false;
            }
        }
    }

    if(menuState?.menu && menuState?.menu.length > 0 && (menuState?.menuData?.styleId === 46 || menuState?.menuData?.styleId === 40)){
        if(config?.typeIdentifier === "floatingContentPanel8"){
            if(textOptionSelected3){
                showEditableAbsolute = false;
            }
        }else if(config?.typeIdentifier === "floatingContentPanel7"){
            if(textOptionSelected4 && !textOptionSelected4){
                showEditableAbsolute = false;
            }
        }
    }

    if(menuState?.menuData?.styleId === 42 && (!containsHeading3 && !containsSubHeading3)){
        originalPosition = config.translations2 ? config.translations2.find((tran) => tran.format == formatSize)?.position : config.translations.find((tran) => tran.format == formatSize)?.position;;
        scaledPositions = Array.isArray(originalPosition) ? originalPosition.map((pos) => getScaledPosition(scale, formatSize, pos)) : [getScaledPosition(scale, formatSize, originalPosition)];
        editablePosition = config?.editablePosition2 ? config?.editablePosition2 : config?.editablePosition;
    }

    return(
        <>
            {
                showEditableAbsolute ? 
                <EditableAbsolute data={modalData} position={editablePosition}>
                    {
                        scaledPositions.map((scaledPosition, i) => {
                            // Check type of Lock up for BARKER Template: IMAGE or TEXT, if IMAGE do not show Lock up
                            if(menuState?.menu && menuState?.menu.length > 0){
                                // floatingContentPanel1 id based on mealdeal1_templateConfig.json
                                let floatingContentPanel1 = menuState?.menu[0]?.props?.items.filter((row: {props: {[key: string]: any}}) => row?.props?.id === "floatingContentPanel1");
                                if(floatingContentPanel1 && floatingContentPanel1.length > 0 && formatSize === "BARKER" && menuState?.menuData?.styleId !== 38){
                                    let fieldsGroups = floatingContentPanel1[0]?.props?.fieldGroups;
                                    if(fieldsGroups && fieldsGroups.length > 0){
                                        let fields = fieldsGroups[0]?.fields;
                                        if(fields && fields.length > 0){
                                            if(fields[0]?.data?.selectedInputType === "IMAGE"){
                                                if(JSON.stringify(originalPosition) === JSON.stringify(barkerLockUpPosition)){
                                                    return null;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            const { height, left, top, width, z } = scaledPosition;
                            return(
                                <div style={{
                                    height, 
                                    left, 
                                    top,
                                    width,
                                    zIndex: z,
                                    position: "absolute",
                                    overflow: menuState?.menuData?.styleId === 42 ? "hidden" : ""
                                }}>
                                {
                                    selectedFieldGroup != null
                                    ?
                                    <>
                                        {
                                            selectedFieldGroup.fields.map((field) => {
                                                const Component: React.FC<FieldComponent> = (ContentDisplay as any)[field.component];
                                                const fieldConfig = selectedFieldGroupConfig?.fields?.find((f) => f.name == field.name);
                                                const originalWidth = Array.isArray(originalPosition) ? originalPosition[i].width : originalPosition?.width;
                                                if(Component){
                                                        return(
                                                            <>
                                                                <Component scaledPosition={scaledPosition} field={field} config={fieldConfig} scale={scale} width={originalWidth} modalData={modalData} positionIndex={i} format={formatSize} />
                                                            </>
                                                        )
                                                }
                                            })
                                        }
                                    </>
                                    :
                                        <div>{`Selected field group is null! Could not find group with ID ${selectedFieldGroupId}`}</div>
                                    }
                                </div>
                            )
                        })
                    }
                </EditableAbsolute>
                : <></>
            }
        </>
    )
}

export default FloatingContentPanel;

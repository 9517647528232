import React from "react";
import { WithStyles } from "@material-ui/core";
import StyleInjector from "../../decorators/StyleInjector";

interface IImageProps {
    image: string,
    alt?: string,
    arrowText?: string,
    border: boolean,
    square?: boolean,
    fit?: boolean
}

class Image extends React.Component<IImageProps & WithStyles> {
    render() {
        const { classes, image, arrowText, border, square, fit } = this.props;

        return <div className={`${classes.root} ${square ? classes.square : ""}`}>
            <div className={classes.cutout}>
                <div className={`${classes.image} ${fit ? classes.fitted : ""}`} style={{ backgroundImage: `url(${image})` }} />
            </div>
            {arrowText && <span className={classes.arrowText}>{arrowText}</span>}
            {arrowText && <img alt="arrow" aria-hidden="true" className={classes.arrow} />}
            {border && <img alt="border" aria-hidden="true" className={classes.border} />}
        </div>
    }
}

export default StyleInjector({}, "image")(Image);
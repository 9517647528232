import React from "react";
import { Grid, Typography, WithStyles } from "@material-ui/core";
import Editable from "../decorators/Editable";
import StyleInjector from "../decorators/StyleInjector";
import TextField from "../form/TextField";
import EditableProducts from "../layout/EditableProducts";
import IProduct from "../../interfaces/IProduct";

const styles = ({
    root: {
        marginTop: 20,
        marginBottom: 20
    },
    title: {
        textAlign: "center" as "center"
    },
    product: {
        textAlign: "center" as "center"
    }
});

interface IHorizontalProducts {
    id: string,
    title: string,
    products?: IProduct[]
}

class HorizontalProducts extends React.Component<IHorizontalProducts & WithStyles> {
    render() {
        const { id, title, products, classes } = this.props;

        const data = {
            id,
            name: "Horizontal Products",
            initialValues: {
                title,
                products
            },
            state: {
                opened: [0],
                showAddProduct: false,
                showAddBritvicProduct: false,
                showAddGinProduct: false,
                newProduct: {} as IProduct
            },
            fields: [
                {
                    label: "Title text",
                    required: true,
                    render: (onChange: any, dataMapping: any) => {
                        return <TextField name="title" value={dataMapping["title"]} onChange={onChange} />
                    }
                },
                {
                    render: (onChange: any, dataMapping: any, state: { onSetState: any, editableState: any }) => {
                        return <EditableProducts hideFeatured productLimit={6} onChange={onChange} dataMapping={dataMapping} state={state} />
                    }
                }
            ]
        };

        return <Editable data={data}>
            <Grid container className={classes.root} justify="center">
                <Grid item xs={12} className={classes.title}>
                    <Typography variant="h3">{title}</Typography>
                </Grid>

                <Grid item>
                    <Grid container wrap="nowrap">
                        {products?.map((product: IProduct, index: number) => (
                            <React.Fragment key={index}>
                                <Grid style={{ width: `${100 / products.length}%` }} className={classes.product} item>
                                    <Grid style={{ height: "100%" }} container justify="center">
                                        <Grid item xs={12}>
                                            <Typography variant="h4">{product.name}</Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Typography component="p">{product.desc}</Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Typography component="span">£{(product.price as number).toFixed(2)}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {(index !== products.length - 1) && <div className={classes.separator}></div>}
                            </React.Fragment>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </Editable>
    }
}

export default StyleInjector(styles, "horizontalProducts")(HorizontalProducts);
import React from "react"

/*
    "id": "TEXT-1",
    "groupImage": "/assets/mealdeal1/test_list_1.png",
    "fields": [
        {
            "name": "textList1",
            "props": {
                "rootProps": {
                    "fontColors": ["#fff", "#96bfb9", "#e1b145"]
                }                                        
            }
        }
    ]


    {
        "id": "TEXT-1",
        "fields": [
            {
                "name": "textList1",
                "component": "TextList",
                "data": {
                    "root": {
                        "rootStyle": {
                            "color": "#fff"
                        }
                    },
                    "lines": [
                        {
                            "lineId": "largeText",
                            "textTitle": "Large text",
                            "textValue": "123123",
                            "textStyle": {
                                "fontSize": 72
                            }
                        },
                        {
                            "lineId": "smallText",
                            "textTitle": "Small text",
                            "textValue": "321321",
                            "textStyle": {
                                "fontSize": 24
                            }
                        }
                    ]                                        
                }
            }
        ]
    }
*/

export const TextListEditor: React.FC = () => {

    return(
        <>
        </>
    )
}

export const TextList: React.FC = () => {

    return(
        <>
        </>
    )
}
import React from "react";
import DropIn from "braintree-web-drop-in-react";
import { Grid, withStyles,  Button, TextField, FormControl, NativeSelect, Divider, Select, MenuItem } from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { myMenuActions } from "../store/myMenu";
import { printPalOperations, printPalActions } from "../store/printpal";
import { globalActions } from "../store/global";
import { getOrderDescription, getPrintPalCheckoutDetails, getPrintPalCheckoutDeliveryAddressDetails } from "../utils/common";
import { printPalPaymentStyles } from "../styles";
import { menuOperations } from "../store/menu";

const styles = printPalPaymentStyles;

class PrintPalPayment extends React.Component {
    instance;
    title;
    firstName;
    lastName;
    address1;
    address2;
    address3;
    city;
    county;
    postcode;
    order;
    mounted = false;
    address;
    addressId
    company;
    telephone;
    // START OF DELIVERY ADDRESS FIELDS
    del_firstName;
    del_lastName;
    del_address1;
    del_address2;
    del_address3;
    del_city;
    del_county;
    del_postcode;
    del_company;
    del_telephone;
    del_usingPastAddress = 0;
    contractCaterer;

    constructor(props) {
        super(props);
        const { global: { session: { mealDealMessaging } } } = this.props;
        this.state = {
            clientToken: null,
            payment: 0,
            isValidDelivery: false,
            transaction: [],
            isMobileApp: false,
            submitted: false,
            editMyDetails: true,
            delUsingPastAddressValue: mealDealMessaging ? "-1" : "-2",
        };
        this.takePayment = this.props.global.session.takePayment;
        this.props.printPalPage(true);
        this.props.showNavigation(false);
        this.props.checkout(true);

        this.handleCheckDeliveryDetails = this.handleCheckDeliveryDetails.bind(this);

        this.order = JSON.parse(sessionStorage.getItem('order'));
        if (mealDealMessaging) this.state.editMyDetails = false;
    }

    async componentDidMount() {
        await this.props.getToken();
        this.mounted = true;
        this.setResponsiveness();
        window.addEventListener("resize", () => this.setResponsiveness());

        const sessionAddress = sessionStorage.getItem("address");
        if (sessionAddress && JSON.parse(sessionAddress).customer == undefined) { //Remove broken sessionStorage address object.
            sessionStorage.removeItem("address");
        }

        if (sessionStorage.getItem("address") !== null) {
            //this.setCustomerDetails();
        }
        else {
            let possibleBillingAddress = this.props.global.user.addresses.filter(a => a.isBilling == true);
            let billingAddress;
            let customer;

            if (possibleBillingAddress.length > 0) {
                billingAddress = possibleBillingAddress[0];
            }
            if (billingAddress) {
                customer = {
                    title: billingAddress.title,
                    firstName: billingAddress.firstName,
                    lastName: billingAddress.lastName
                };
                let address = {
                    customer,
                    address: billingAddress
                    //ignore shipping
                }
                sessionStorage.setItem('address', JSON.stringify(address));
            }
        }
        this.setCustomerDetails();
    }

    componentWillUnmount() {
        this.mounted = false;
        window.removeEventListener('resize', () => this.setResponsiveness());
        this.props.checkout(false);
    }

    setResponsiveness = () => {
        if (this.mounted)
            return window.innerWidth < 800
                ? this.setState((prevState) => ({ ...prevState, isMobileApp: true }))
                : this.setState((prevState) => ({ ...prevState, isMobileApp: false }));
    };

    componentWillReceiveProps(nextProps) {
        const { printPal } = nextProps;
        this.setState({ clientToken: printPal.token });
    }

    async buy() {
        const recentValidation = this.handleCheckDeliveryDetails();
        this.setState({ submitted: true });

        var total = this.calcTotalCost();
        var nonce = "";
        if (total > 0 && this.takePayment) {
            var a = await this.instance.requestPaymentMethod();
            nonce = a.nonce;
        }

        if (!recentValidation) return;

        let del_addressID = null;
        let addressID = null;

        if (this.del_usingPastAddress) {
            if (this.del_usingPastAddress.value !== "" && this.del_usingPastAddress.value !== "-1" && this.del_usingPastAddress !== "-2") {
                del_addressID = parseInt(this.del_usingPastAddress.value)
            }
        }

        if (this.addressId && this.addressId.value && this.addressId.value != "") {
            addressID = parseInt(this.addressId.value);
        }

        let order = ({
            menu: {
                id: this.order.menuId,
                title: this.order.title,
                pDFPreview: this.order.pdfPreview
            },
            billingAddress: this.state.editMyDetails ? {
                addressID: addressID,
                address1: this.address1.value,
                address2: this.address2.value,
                address3: this.address3.value,
                city: this.city.value,
                county: this.county.value,
                country: "UK",
                postcode: this.postcode.value,
                title: this.title.value,
                firstName: this.firstName.value,
                lastName: this.lastName.value,
                email: this.props.global.user.email,
                company: this.company.value,
                telephone: this.telephone.value,
                isBilling: true
            } : null,

            deliveryAddress: this.state.usingBillingAddress ? null : {
                firstName: this.del_firstName.value,
                lastName: this.del_lastName.value,
                company: this.del_company.value,
                address1: this.del_address1.value,
                address2: this.del_address2.value,
                address3: this.del_address3.value,
                city: this.del_city.value,
                county: this.del_county.value,
                country: "UK",
                postcode: this.del_postcode.value,
                addressId: del_addressID,
                telephone: this.del_telephone.value
            },

            quantity: this.order.qty,
            deliveryCharge: this.order.delCharge,
            templates: this.order.templates,
            net: this.order.price,
            gross: total,
            paperId: this.order.paperId === undefined ? 1 : this.order.paperId,
            nonce: nonce,
            voucherCode: this.order.voucherCode,
            form: this.order.form,
            isMenu: this.order.isMenu
        });
        console.log("order.menu to be purchased being assigned from this.order", order.menu);
        await this.props.purchase(order);
        await this.props.getMenuData(this.order.menuId);
        //Only redirect if we have a target id
        if (total == 0 || (this.props.printPal && this.props.printPal.purchaseResult && this.props.printPal.purchaseResult.target) || !this.takePayment) {
            this.redirectToSuccessPage();
            sessionStorage.removeItem('order');
        }
    }

    calcTotalCost() {
        const { classes, global: { session: { mealDealMessaging } } } = this.props;
        if (!mealDealMessaging) {
            return 0;
        }

        const vat = this.order.vat / 100;
        return (this.order.price + this.order.delCharge) * (1 + vat);
    }

    setCustomerDetails() {
        const { classes, global: { session: { mealDealMessaging } } } = this.props;
        const defaultUsingPastAddress = !mealDealMessaging ? "-2" : "-1"

        var addressJson = sessionStorage.getItem('address') != null ? JSON.parse(sessionStorage.getItem('address')) : undefined;
        const defaultAddress = {
            customer: { title: "", firstName: "", lastName: "" },
            address: {
                addressId: "", address1: "", address2: "", address3: "", city: "", county: "", postcode: "", company: "", telephone: "",
                del_firstName: "", del_lastName: "", del_address1: "", del_address2: "", del_address3: "", del_city: "", del_county: "", del_postcode: "", del_company: "", del_telephone: "", del_usingPastAddress: defaultUsingPastAddress
            }
        };

        this.address = addressJson ? {
            customer: addressJson.customer,
            address: {
                ...defaultAddress.address,
                ...addressJson.address
            }
        } : defaultAddress;

        this.firstName = { value: this.address.customer.firstName };
        this.title = { value: this.address.customer.title };
        this.lastName = { value: this.address.customer.lastName };

        this.addressId = { value: this.address.address.addressId.toString() };
        this.address1 = { value: this.address.address.address1 };
        this.address2 = { value: this.address.address.address2 };
        this.address3 = { value: this.address.address.address3 };
        this.city = { value: this.address.address.city };
        this.county = { value: this.address.address.county };
        this.postcode = { value: this.address.address.postcode };
        this.company = { value: this.address.address.company };
        this.telephone = { value: this.address.address.telephone };

        this.del_firstName = { value: this.address.address.del_firstName };
        this.del_lastName = { value: this.address.address.del_lastName };

        this.del_address1 = { value: this.address.address.del_address1 };
        this.del_address2 = { value: this.address.address.del_address2 };
        this.del_address3 = { value: this.address.address.del_address3 };
        this.del_city = { value: this.address.address.del_city };
        this.del_county = { value: this.address.address.del_county };
        this.del_postcode = { value: this.address.address.del_postcode };
        this.del_company = { value: this.address.address.del_company };
        this.del_telephone = { value: this.address.address.del_telephone };
        this.del_usingPastAddress = { value: this.address.address.del_usingPastAddress || defaultUsingPastAddress };

        this.setState({ usingBillingAddress: this.del_usingPastAddress.value == "-2" });
        this.handleCheckDeliveryDetails();
    }

    handleCheckDeliveryDetails() {
        if (this.state.editMyDetails) {
            if (this.firstName !== undefined && this.firstName.value.length > 0 &&
                this.lastName && this.lastName.value.length > 0 &&
                this.address1 && this.address1.value.length > 0 &&
                this.city && this.city.value.length > 0 &&
                this.postcode && this.postcode.value.length > 0 &&
                this.postcode.value.length <= 10 &&
                this.company && this.company.value.length > 0 &&
                this.telephone && this.telephone.value.length > 4) {
                // do nothing
            } else {
                this.setState({ isValidDelivery: false });
                return false;
            }
        }

        if (!this.state.usingBillingAddress) {
            if (
                // bil validation start
                this.del_firstName && this.del_firstName !== undefined && this.del_firstName.value.length > 0 &&
                this.del_lastName && this.del_lastName.value.length > 0 &&
                this.del_address1 && this.del_address1.value.length > 0 &&
                this.del_company && this.del_company.value.length > 0 &&
                this.del_telephone && this.del_telephone.value.length > 4 &&
                this.del_city && this.del_city.value.length > 0 &&
                this.del_postcode && this.del_postcode.value.length > 0 && this.del_postcode.value.length <= 10
            )
            // bil validation end

            { this.setState({ isValidDelivery: true }); return true; }
            else { this.setState({ isValidDelivery: false }); return false; }
        } else {
            return true;
        }
    }

    handleChangeOrder = () => {
        //debugger;
        let address =
            ({
                customer: {
                    title: this.title.value,
                    firstName: this.firstName.value,
                    lastName: this.lastName.value
                },
                address: {
                    addressId: this.addressId.value.length > 0 ? this.addressId.value : null,
                    address1: this.address1.value,
                    address2: this.address2.value,
                    address3: this.address3.value,
                    company: this.company.value,
                    city: this.city.value,
                    county: this.county.value,
                    country: "UK",
                    postcode: this.postcode.value,
                    telephone: this.telephone.value,
                    del_address1: this.del_address1 != undefined ? this.del_address1.value : undefined,
                    del_address2: this.del_address2 != undefined ? this.del_address2.value : undefined,
                    del_address3: this.del_address3 != undefined ? this.del_address3.value : undefined,
                    del_company: this.del_company != undefined ? this.del_company.value : undefined,
                    del_city: this.del_city != undefined ? this.del_city.value : undefined,
                    del_county: this.del_county != undefined ? this.del_county.value : undefined,
                    del_postcode: this.del_postcode != undefined ? this.del_postcode.value : undefined,
                    del_telephone: this.del_telephone != undefined ? this.del_telephone.value : undefined,
                    del_usingPastAddress: this.del_usingPastAddress != undefined ? this.del_usingPastAddress.value : undefined,
                    del_firstName: this.del_firstName != undefined ? this.del_firstName.value : undefined,
                    del_lastName: this.del_lastName != undefined ? this.del_lastName.value : undefined,
                }
            });
        sessionStorage.setItem('address', JSON.stringify(address));

        if (this.props.menu.isMenu) {
            this.props.history.push("/place-order");
        } else {
            this.props.history.push("/place-order-formats");
        }
    }

    redirectToSuccessPage = () => {
        if (this.calcTotalCost() === 0) {
            this.props.history.push("/payment-processed");
            return;
        }

        if (!this.takePayment) {
            sessionStorage.setItem('transactionAuthCode', "00000000");

        } else {
            sessionStorage.setItem('transactionAuthCode', this.props.printPal.purchaseResult.target.id);
        }

        this.props.history.push("/payment-processed");
    }

    handleSelectedPreviousAddress(addressId) {
        this.setState({ usingBillingAddress: addressId === -2 });

        if (addressId === -2 || addressId === -1) {
          this.del_usingPastAddress.value = addressId.toString();
          this.clearForm();
          this.setState({delUsingPastAddressValue: addressId.toString()});
          return;
        }
      
        const address = this.props.global.user.addresses.find(
          (address) => address.addressId.toString() === addressId.toString()
        );
      
        if (address === undefined) {
          this.clearForm();
          this.del_usingPastAddress.value = "-1";
          this.setState({delUsingPastAddressValue: "-1"});
          return 'No address with that ID anymore.';
        }
        this.setState({ delUsingPastAddressValue: addressId.toString() }, () => {
            this.del_firstName.value = address.firstName;
            this.del_lastName.value = address.lastName;
            this.del_address1.value = address.address1;
            this.del_address2.value = address.address2;
            this.del_address3.value = address.address3;
            this.del_company.value = address.company;
            this.del_city.value = address.city;
            this.del_county.value = address.county;
            this.del_postcode.value = address.postcode;
            this.del_telephone.value = address.telephone;
            this.del_usingPastAddress.value = addressId.toString();
         });
    }

    clearForm() {
        this.del_firstName.value = '';
        this.del_lastName.value = '';
        this.del_address1.value = '';
        this.del_address2.value = '';
        this.del_address3.value = '';
        this.del_company.value = '';
        this.del_city.value = '';
        this.del_county.value = '';
        this.del_postcode.value = '';
        this.del_telephone.value = '';
    }

    formatAddress(a) {
        return `${a.company}, ${a.address1}, ${a.address2 === '' ? '' : (a.address2 + ", ")}${a.address3 === '' ? '' : (a.address3 + ", ")}${a.city}, ${a.postcode}`
    }

    render() {
        const { classes, global: { session: { mealDealMessaging } } } = this.props;
        var orderDescription = "";
        const titleOptions = [ "Mr", "Mrs", "Miss", "Ms", "Dr", "Rev"];
        const detailsData = getPrintPalCheckoutDetails();
        const deleveryDetailsData = getPrintPalCheckoutDeliveryAddressDetails();

        if (this.order.form && this.props.menu.menuData) orderDescription = getOrderDescription(this.order.form.quantities, this.order.form.wobblerArmsAmount, this.props.menu.menuData.availableFormats, this.order?.form?.magic8Stickers);

        if (!this.state.clientToken || (!this.address && !mealDealMessaging)) { //Due to how default values are done, do not render the text fields until address has been loaded from sessionStorage
            return (
                <Grid container justify="center">
                    <div style={{ display: "flex", marginTop: 100, marginBottom: 100 }}>
                        <CircularProgress className={classes.orange} />
                    </div>
                </Grid>
            )
        } else {
            return (
                <div>
                    <Grid container className={classes.content}>
                        <>
                            <Grid container alignItems="center" style={{ paddingTop: 5, paddingBottom: 5, display: mealDealMessaging ? "none" : "" }}>
                                <Grid item xs={this.state.isMobileApp ? false : 2} />
                                <Grid item xs={this.state.isMobileApp ? 12 : 2}>
                                    <div>
                                        <div className={classes.header}><span className={classes.headerSpacing}>1</span>My details</div>
                                    </div>
                                </Grid>
                                <Grid style={{ display: this.state.editMyDetails ? "flex" : "none" }} item xs={this.state.isMobileApp ? 12 : 2}>
                                    <div style={{ marginBottom: 2 }}><span className={classes.addressText}>Title</span></div>
                                </Grid>
                                <Grid style={{ display: this.state.editMyDetails ? "flex" : "none" }} item xs={3}>
                                    <div className={classes.titleBox}>
                                        <FormControl className={classes.sortControl}>
                                            <NativeSelect
                                                defaultValue={this.address ? this.address.customer.title : "Mr"}
                                                disableUnderline={true}
                                                name="titleOption"
                                                className={`${classes.selectEmpty} ${classes.normal}`}
                                                IconComponent={props => (
                                                    <i {...props} className={`material-icons ${props.className}`}>
                                                        <img className={classes.sortDown} alt="sort" />
                                                    </i>
                                                )}
                                                inputRef={ref => { this.title = ref; }}
                                                inputProps={{ 'aria-label': 'titleOption' }}
                                            >   
                                                {
                                                    titleOptions.map((row) => {
                                                        return(<option value={row} key={row}>{row}</option>)
                                                    })
                                                }
                                            </NativeSelect>
                                        </FormControl>
                                    </div>
                                </Grid>
                                <Grid item xs={4} />
                                {
                                    !this.state.editMyDetails && <span style={{ textDecoration: "underline", cursor: "pointer" }} className={classes.normal} onClick={() => this.setState({ editMyDetails: true })}>Edit my details</span>
                                }
                                {
                                    this.state.editMyDetails && <span style={{ textDecoration: "underline", cursor: "pointer" }} className={classes.normal} onClick={() => this.setState({ editMyDetails: false })}>Hide my details</span>
                                }
                            </Grid>
                            {
                                detailsData.map((row, index) => {
                                    return(
                                        <Grid container alignItems="center" style={{ paddingTop: 5, paddingBottom: 5, display: this.state.editMyDetails ? "flex" : "none" }} key={index}>
                                            <Grid item xs={this.state.isMobileApp ? false : 4} />
                                            <Grid item xs={this.state.isMobileApp ? 12 : 2}>
                                                <div style={{ marginBottom: 2 }}><span className={classes.addressText}>{row?.value} </span>{row?.required && <span className={classes.orange}>*</span>}</div>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField
                                                    FormHelperTextProps={row?.required && { className: classes.helperText }}
                                                    helperText={row?.required && this.state.submitted && this[row?.key] && this[row?.key]["value"].length < 1 && row?.helperText}
                                                    onChange={(e) => {
                                                        this[row?.key]["value"] = e.target.value; //Alternative way of setting values instead of assigning ref
                                                        this.handleCheckDeliveryDetails();
                                                    }}
                                                    className={`${classes.root} ${classes.address}`}
                                                    size="small"
                                                    variant="outlined"
                                                    defaultValue={this[row?.key] ? this[row?.key]["value"] : ""}
                                                    InputProps={{ classes: { input: classes.normal, }, }}
                                                />
                                            </Grid>
                                            {
                                                row?.key === "postcode" ?
                                                <Grid item xs={this.state.isMobileApp ? 6 : 2}>
                                                    <Grid container justify="flex-end">
                                                        <CheckCircleIcon fontSize="large" className={classes.orange} visibility={this.state.isValidDelivery ? "visible" : "hidden"} style={{ marginRight: 0 }} />
                                                    </Grid>
                                                </Grid>
                                                :
                                                <Grid item xs={4} />
                                            }
                                        </Grid>
                                    )
                                })
                            }
                        </>
                        {!mealDealMessaging && <Grid container>
                            <Grid item xs={this.state.isMobileApp ? false : 2} />
                            <Grid item xs={this.state.isMobileApp ? 12 : 7} style={{ paddingTop: 20, paddingBottom: 20 }}>
                                <Divider variant="middle" />
                            </Grid>
                        </Grid>}
                        <Grid container alignItems="center" style={{ paddingTop: 5, paddingBottom: 5 }}>
                            <Grid item xs={this.state.isMobileApp ? false : 2} />
                            <Grid item xs={this.state.isMobileApp ? 12 : 2}>
                                <div>
                                    <div className={classes.header}><span className={classes.headerSpacing}>{mealDealMessaging ? 1 : 2}</span>{mealDealMessaging ? "Retailer Details" : "Delivery Address"}</div>
                                </div>
                            </Grid>
                            <Grid item xs={this.state.isMobileApp ? 12 : 2}>
                                <div style={{ marginBottom: 2 }}><span className={classes.addressText}>{mealDealMessaging ? "Select retailer" : "Select Address"}</span></div>
                            </Grid>
                            <Grid item xs={3}>
                                <div style={{ width: 292 }} className={classes.titleBox}>
                                    <FormControl style={{ width: 292 }} className={classes.sortControl}>
                                        <Select
                                            style={{ width: 275 }}
                                            // defaultValue={this.del_usingPastAddress.value}
                                            disableUnderline={true}
                                            name="addressSelectOption"
                                            value={this.state.delUsingPastAddressValue}
                                            className={`${classes.selectEmpty} ${classes.normal} ${classes.addressSelect}`}
                                            IconComponent={props => (
                                                <i {...props} className={`material-icons ${props.className}`}>
                                                    <img className={classes.sortDown} alt="sort" />
                                                </i>
                                            )}
                                            inputRef={ref => {
                                                this.del_usingPastAddress = ref == null ? ref : {
                                                    ...ref,
                                                    value: ref.value ? ref.value.toString() : null //previously NativeSelect below returned the value as a string, this returns it as a number so a lot of conditions were failing when they shouldn't if it were a string
                                                };
                                            }}
                                            onChange={(e) => this.handleSelectedPreviousAddress(e.target.value)}
                                            inputProps={{ 'aria-label': 'addressSelectOption' }}>
                                            {!mealDealMessaging && <MenuItem value={-2}>Use Billing address</MenuItem>}
                                            <MenuItem value={-1}>New address</MenuItem>
                                            {
                                                this.props.global.user.addresses.sort((a, b) => a.company.toUpperCase() > b.company.toUpperCase() ? 1 : -1).map(address => (
                                                    <MenuItem value={address.addressId}>{this.formatAddress(address)}</MenuItem>
                                                )
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                            </Grid>
                            <Grid item xs={4} />
                        </Grid>
                        {
                            !this.state.usingBillingAddress &&
                            <Grid container className={classes.content}>
                                {/*seperator*/}
                                <Grid container alignItems="center" style={{ paddingTop: 5, paddingBottom: 5 }} />
                                {/*title (enter new address)*/}
                                <Grid container alignItems="center" style={{ paddingTop: 5, paddingBottom: 5 }}>
                                    <Grid item xs={this.state.isMobileApp ? false : 4} />
                                    <Grid item xs={this.state.isMobileApp ? 12 : 2}>
                                        <div style={{ marginBottom: 2 }}><span className={classes.addressText}><b>Or enter a new delivery address</b></span></div>
                                    </Grid>
                                    <Grid item xs={3}>
                                    </Grid>
                                    <Grid item xs={4} />
                                </Grid>
                                {
                                    deleveryDetailsData.map((row, index) => {
                                        return(
                                            <Grid container alignItems="center" style={{ paddingTop: 5, paddingBottom: 5 }} key={index}>
                                                <Grid item xs={this.state.isMobileApp ? false : 4} />
                                                <Grid item xs={this.state.isMobileApp ? 12 : 2}>
                                                    <div style={{ marginBottom: 2 }}><span className={classes.addressText}>{row?.value} </span> {row?.required && <span className={classes.orange}>*</span>} </div>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <TextField
                                                        FormHelperTextProps={{ className: classes.helperText }}
                                                        helperText={ row?.required && this.state.submitted && this[row?.key] && this[row?.key]["value"].length < 1 && row?.helperText}
                                                        onChange={this.handleCheckDeliveryDetails}
                                                        className={`${classes.root} ${classes.address}`}
                                                        disabled={(!!this.del_usingPastAddress && this.del_usingPastAddress.value !== "-1")}
                                                        size="small"
                                                        variant="outlined"
                                                        inputRef={ref => { this[row?.key] = ref; }}
                                                        defaultValue={this[row?.key] ? this[row?.key]["value"] : ""}
                                                        InputProps={{ classes: { input: classes.normal, }, }} />
                                                </Grid>
                                                {
                                                    row?.key === "del_postcode" ?
                                                    <Grid item xs={this.state.isMobileApp ? 6 : 2}>
                                                        <Grid container justify="flex-end">
                                                            <CheckCircleIcon fontSize="large" className={classes.orange} visibility={this.state.isValidDelivery ? "visible" : "hidden"} style={{ marginRight: 0 }} />
                                                        </Grid>
                                                    </Grid>
                                                    :
                                                    <Grid item xs={4} />
                                                }
                                            </Grid>
                                        )
                                    })
                                }
                                {/* contract caterer*/}
                                {mealDealMessaging &&
                                    <Grid container alignItems="center" style={{ paddingTop: 5, paddingBottom: 5 }}>
                                        <Grid item xs={this.state.isMobileApp ? false : 4} />
                                        <Grid item xs={this.state.isMobileApp ? 12 : 2}>
                                            <div style={{ marginBottom: 2 }}>
                                                <span className={classes.addressText}>Contract Caterer </span>
                                                <span style={{ whiteSpace: "pre-wrap" }} className={classes.addressText}><br />(eg Compass, Sodexo or independent)</span>
                                                {/* <span className={classes.orange}>*</span> */}
                                            </div>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                FormHelperTextProps={{ className: classes.helperText }}
                                                helperText={""}
                                                onChange={this.handleCheckDeliveryDetails}
                                                className={`${classes.root} ${classes.address}`}
                                                size="small"
                                                variant="outlined"
                                                inputRef={ref => { this.contractCaterer = ref; }}
                                                InputProps={{ classes: { input: classes.normal, }, }} />
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                        }
                        {this.calcTotalCost() > 0 && this.takePayment && <React.Fragment>
                            <Grid container>
                                <Grid item xs={this.state.isMobileApp ? false : 2} />
                                <Grid item xs={this.state.isMobileApp ? 12 : 7} style={{ paddingTop: 20, paddingBottom: 20 }}>
                                    <Divider variant="middle" />
                                </Grid>
                            </Grid>
                            {this.props.printPal.authorising && <Grid container justify="center">
                                <div style={{ display: "flex" }}>
                                    <CircularProgress className={classes.orange} />
                                </div>
                            </Grid>}
                            {!this.props.printPal.authorising && <Grid container>
                                <Grid item xs={this.state.isMobileApp ? false : 2} />
                                <Grid item xs={this.state.isMobileApp ? 12 : 2}>
                                    <div>
                                        <div className={classes.header}><span className={classes.headerSpacing}>2</span>Payment method</div>
                                    </div>
                                </Grid>
                                <Grid item xs={this.state.isMobileApp ? 12 : 5}>
                                    <div>
                                        <DropIn
                                            options={{
                                                authorization: this.state.clientToken,
                                                vaultManager: true,
                                                card: {
                                                    cardholderName: { required: true },
                                                }
                                            }}
                                            onInstance={(instance) => (this.instance = instance)} />
                                    </div>
                                </Grid>
                            </Grid>}
                            {this.props.printPal.authorisation && this.props.printPal.authorisation.message && <Grid container justify="center">
                                <Grid item>
                                    <span style={{ color: "red" }}>There was an issue while authorising your transaction ({this.props.printPal.authorisation.message}), please check your card details and try again</span>
                                </Grid>
                            </Grid>}
                        </React.Fragment>}
                        <Grid container>
                            <Grid item xs={this.state.isMobileApp ? false : 2} />
                            <Grid item xs={this.state.isMobileApp ? 12 : 7} style={{ paddingTop: 20, paddingBottom: 20 }}>
                                <Divider variant="middle" />
                            </Grid>
                        </Grid>
                        <Grid container style={{ paddingBottom: 20 }}>
                            <Grid item xs={this.state.isMobileApp ? false : 2} />
                            <Grid item xs={this.state.isMobileApp ? 12 : 2}>
                                <div>
                                    {!mealDealMessaging && <div className={classes.header}><span className={classes.headerSpacing}>{this.calcTotalCost() > 0 && this.takePayment ? "4" : "3"}</span>Review items</div>}
                                    {mealDealMessaging && <div className={classes.header}><span className={classes.headerSpacing}>{this.calcTotalCost() > 0 && this.takePayment ? "3" : "2"}</span>Review items</div>}
                                </div>
                            </Grid>
                            <Grid item xs={this.state.isMobileApp ? 12 : 5}>
                                <div className={classes.box} style={{ padding: this.state.isMobileApp ? 5 : 20 }}>
                                    <Grid container>
                                        {/*<Grid item xs={3}>*/}
                                        {/*    <img src={`./api/Files/${this.order.imgUrl}`} alt="menu page" className={classes.reviewImg} />                                           */}
                                        {/*</Grid>*/}
                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item xs={10}>
                                                    <div className={classes.bold} style={{ marginBottom: this.state.isMobileApp ? 10 : 10, fontSize: this.state.isMobileApp ? 16 : 18 }}>{this.order.title}</div>
                                                    <div className={classes.bold} style={{ marginBottom: this.state.isMobileApp ? 10 : 15 }}>{this.order.paperDesc}</div>
                                                    {this.order.delCharge > 0 && mealDealMessaging &&
                                                        <div className={classes.normal} style={{ marginBottom: this.state.isMobileApp ? 10 : 15 }}><strong>{new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP" }).format(Number(this.order.delCharge))}</strong> carriage charge per address</div>}
                                                </Grid>

                                                <Grid item xs={2}>
                                                    {!this.order.hideChange && <Button
                                                        onClick={() => { this.handleChangeOrder() }}
                                                        className={classes.change}>
                                                        CHANGE
                                                    </Button>}
                                                </Grid>
                                                {mealDealMessaging && <Grid container>
                                                    <Grid item xs={12}>
                                                        <div className={classes.normal}>
                                                            <span className={classes.bold} style={{ marginLeft: 5 }}>{orderDescription}</span> at
                                                            <span className={classes.orange} style={{ fontSize: this.state.isMobileApp ? 16 : 18, marginLeft: 5, marginRight: 5 }}>{new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP" }).format(Number(this.order.price))}</span>
                                                            (inc. VAT)
                                                        </div>
                                                    </Grid>
                                                </Grid>}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container style={{ paddingBottom: 30, paddingTop: 20 }}>
                            <Grid item xs={this.state.isMobileApp ? false : 4} />
                            <Grid item xs={this.state.isMobileApp ? 12 : 5}>
                                <div className={classes.box} style={{ paddingTop: 20, paddingBottom: 20, paddingLeft: 20, paddingRight: 10 }}>
                                    <Grid container>
                                        <Grid item xs={3}>
                                            <Button
                                                onClick={this.buy.bind(this)}
                                                disabled={this.props.printPal.authorising}
                                                className={classes.buy}>
                                                ORDER NOW
                                            </Button>
                                        </Grid>
                                        <Grid item xs={9} style={{ paddingLeft: 20 }}>
                                            <div className={`${classes.orange} ${classes.largeTotalText}`}>Order Total <span className={`${classes.orange} ${classes.smallTotalText}`}>(inc. VAT & {this.order.delCharge > 0 ? "" : "Free "} delivery)</span> : {this.calcTotalCost() <= 0 ? "FREE" : new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP" }).format(this.calcTotalCost())}</div>
                                            <span className={classes.small}>By placing your order you agree to our Conditions of Sale.</span>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => ({
    myMenu: state.myMenu,
    printPal: state.printPal,
    global: state.global,
    menu: state.menu,
});

const mapDispatchToProps = (dispatch) => {
    return {
        showNavigation: (showNavigation) => dispatch(globalActions.showNavigation(showNavigation)),
        printPalPage: (printPalPage) => dispatch(myMenuActions.printPalPage(printPalPage)),
        getToken: () => dispatch(printPalOperations.getPaymentToken()),
        purchase: (order) => dispatch(printPalOperations.buyMenus(order)),
        checkout: (checkout) => dispatch(printPalActions.checkout(checkout)),
        getMenuData: (id) => dispatch(menuOperations.getMenuData(id)),
    }
}

export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(PrintPalPayment)));
import React, { useEffect, useState } from "react";
import { CircularProgress, makeStyles } from "@material-ui/core";
import { ModalRenderState } from "../layout/EditableModal";
import { ContainerDataMapping } from "../menu/Container";
import { getBackground, getScaledImage } from "../../utils/common";
import CategorySelector from "./CategorySelector";

interface IBackgroundPicker {
    onChange: any
    dataMapping: ContainerDataMapping
    state: ModalRenderState
    backgrounds?: {src: string, category: string}[]
    formatSize: string
}

const useStyles = makeStyles({
    previewBackgroundContainer: {
        height: 350,
        display: "flex"
    },
    previewBackgroundImage: {
        height: "100%",
        objectFit: "contain",
        margin: "0 auto"
    },
    selectedProductImageWrapper:{
        height: 150,
        width: 150,
        background: "white"
    },
    selectedProductImage:{
        height: "100%",
        width: "100%",
        objectFit: "contain"
    },
    targetedProduct:{
        border: "3px solid #007d7a"
    },
    nonTargetProduct: {
        border: "2px solid #00000040"
    },
    availableProductsWrapper: {
        height: 400,
        overflowY: "scroll",
    },
    availableProductsContainer: {
        marginTop: "2rem",
        padding: "1rem",
        background: "white",
        display: "grid",
        gridTemplateColumns: "repeat(4, 1fr)",
        gap: ".5rem",
    },
    availableProductContainer: {
    },
    addGradientContainer: {
        margin: "1.5rem 0.5rem"
    },
    gradientInputs: {
        display: "flex",
        marginTop: "0.5rem",
        marginLeft: "0.2rem"
    },
    blackToWhiteGradient: {
        background: "linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(9,9,9,1) 20%, rgba(230,236,237,1) 100%)",
        display: "inline-block",
        width: "80px",
        height: "20px",
        border: "1px solid",
    },
    whiteToBlackGradient: {
        background: "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(247,243,243,1) 40%, rgba(230,236,237,1) 100%)",
        display: "inline-block",
        width: "80px",
        height: "20px",
        border: "1px solid #b3afaf",
    },
    gradientsInputs: {
        display: "flex",
        alignItems: "center"
    },
    gradientInput: {
        display: "flex",
        alignItems: "center",
        marginRight: "1rem"
    },
    gradientRadioInput: {
        accentColor: "rgb(0, 125, 122)",
        height: "18px",
        width: "18px",
        marginRight: "0.5rem",
        cursor: "pointer"
    }

});

const BackgroundPicker: React.FC<IBackgroundPicker> = ({ dataMapping, onChange, state, backgrounds, children, formatSize}) => {

    const [selectedBackground, setSelectedBackground] = useState(dataMapping.background)
    const [selectedGradient, setSelectedGradient] = useState(dataMapping.gradient);
    const classes = useStyles();

    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

    const onSelectBackground = (backgroundUrl: string) => {
        setSelectedBackground(backgroundUrl);
    }

    const gradients = ["BLK", "WHT"];
    useEffect(() => {
        state.onSetState({
            dataMapping: {
                background: selectedBackground,
                gradient: selectedGradient
            }
        });
    }, [selectedBackground, selectedGradient])
    let containsCategory = backgrounds?.some((row) => row.hasOwnProperty("category"));

    return(
        <div>
            <div className={classes.previewBackgroundContainer}>
                <img src={getScaledImage(getBackground(selectedBackground, formatSize, "PRINT"), 200)} className={classes.previewBackgroundImage} />
            </div>
            {
                containsCategory &&
                <CategorySelector
                    selectedCategories={selectedCategories}
                    categories={backgrounds?.map(background => background.category).filter((v, i, self) => self.indexOf(v) === i) ?? []}
                    onSelectionChange={(category, selected) => {
                        if (selected) {
                            setSelectedCategories([...selectedCategories, category])
                        } else {
                            const newCategories = [...selectedCategories]
                            newCategories.splice(selectedCategories.indexOf(category), 1)
                            setSelectedCategories(newCategories);
                        }
                    }}
                />
            }
            <div className={classes.addGradientContainer}>
                <span>Add gradient</span>
                <div className={classes?.gradientInputs}>
                    <div className={classes.gradientInput}>
                        <input 
                            type="radio" 
                            className={classes.gradientRadioInput} 
                            value="" 
                            checked={!selectedGradient}
                            onChange={(e) => setSelectedGradient(e?.target?.value)}
                        />
                        <label>No gradient</label>
                    </div>
                    <div className={classes.gradientsInputs}>
                        {
                            gradients.map((gradient, index) => {
                                return(
                                    <div className={classes.gradientInput} key={index}>
                                        <input 
                                            type="radio" 
                                            className={classes.gradientRadioInput}
                                            value={gradient === "BLK" ? "BLK" : "WHT"}
                                            checked={gradient === "BLK" ? "BLK" === selectedGradient : "WHT" === selectedGradient}
                                            onChange={(e) => setSelectedGradient(e?.target?.value)}
                                        />
                                        <span className={gradient === "BLK" ? classes.blackToWhiteGradient : classes.whiteToBlackGradient}></span>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <div className={classes.availableProductsWrapper}>
                <div className={classes.availableProductsContainer}>
                    {
                        backgrounds?.filter(bg => selectedCategories.includes(bg.category) || selectedCategories.length == 0).map((background) => {
                        return(
                            <div onClick={() => { onSelectBackground(background.src); }} className={`${classes.availableProductContainer} ${background.src == selectedBackground ? classes.targetedProduct : classes.nonTargetProduct}`}>
                                <img src={getScaledImage(getBackground(background.src, formatSize, "PRINT"), 200)} className={classes.selectedProductImage}/>
                            </div>
                        )
                    })
                }
                </div>
            </div>
        </div>
    )
}

export default BackgroundPicker;
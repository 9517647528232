import React from "react";
import { Grid, Theme, withStyles, WithStyles } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import DoneIcon from '@material-ui/icons/Done';
import IOrder from "../interfaces/IOrder";
import { connect } from "react-redux";
import { AppState } from "../store";
import { myMenuActions } from "../store/myMenu";
import { IPrintPalState, printPalActions } from "../store/printpal";
import { globalActions, globalOperations, IGlobalState } from "../store/global";
import { paymentProcessedStyles } from "../styles";

const styles = paymentProcessedStyles;

interface IProps {
    global: IGlobalState;
    showNavigation: any;
    printPalPage: any;
    checkout: any;
    printPal: IPrintPalState,
    getMe: () => void
}

class PaymentProcessed extends React.Component<WithStyles & IProps, any> {
    private order: IOrder;
    private transactionId: string;
    private mounted: boolean = false;

    constructor(props: any) {
        super(props);

        this.props.printPalPage(true);
        this.props.showNavigation(false);
        this.props.checkout(true)

        this.order = JSON.parse(sessionStorage.getItem('order') || '{}');
        this.transactionId = sessionStorage.getItem('transactionAuthCode') || 'e4rt67-awe89'; 

        this.state = { isMobileApp: false };
        this.props.getMe();
    }

    componentDidMount() {
        this.mounted = true;
        this.setResponsiveness();
        window.addEventListener("resize", () => this.setResponsiveness());
    }

    componentWillUnmount() {
        this.mounted = false;
        window.removeEventListener('resize', () => this.setResponsiveness());
    }

    setResponsiveness = () => {
        if (this.mounted)
            return window.innerWidth < 600
                ? this.setState((prevState: any) => ({ ...prevState, isMobileApp: true }))
                : this.setState((prevState: any) => ({ ...prevState, isMobileApp: false }));
    };

    calcVAT() {        
        const vat = this.order.vat / 100;
        return (this.order.price + this.order.delCharge) * vat;
    }

    calcTotalCost() {  

        const vat = this.order.vat / 100;
        return (this.order.price + this.order.delCharge) * (1 + vat);
    }

    render() {
        const { classes, global, printPal } = this.props;
        let mealDealMessaging: boolean = false;
        if(global && global?.session){
            mealDealMessaging = global?.session?.mealDealMessaging;
        }
        let orderDetails: {name: string, value: string}[] = [
            {name: "Design Name:", value: printPal?.purchasedOrder?.menuTitle},
            {name: "Order Number:", value: printPal?.purchasedOrder?.orderNumber},
            {name: "Description:", value: printPal?.purchasedOrder?.orderDescription},
            {name: "Delivery Address:", value: printPal?.purchasedOrder?.address},
        ];

        return (
            <div style={{ minHeight: 'calc(100vh - 210px)' }}>
                <Grid container className = { classes.content }>
                    <Grid container>
                         <Grid item xs={3}/>
                            <p className={classes.orange} style={{ fontSize: this.state.isMobileApp ? 18 : 26 }}>Thanks for your order</p>
                         <Grid item xs={6}/>
                    </Grid>                             
                    <Grid container style={{ paddingBottom: 20 }}>
                        <Grid item xs={this.state.isMobileApp ? false : 3} />
                        <Grid item xs={this.state.isMobileApp ? 12: 6}>
                            <div className={classes.box} style={{ padding: 20 }}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <div style={{ display: "flex", height: 30 }}>
                                            <DoneIcon className={classes.orange} /><span className={classes.bold} style={{ paddingLeft: 12, paddingTop: 4 }}>{global.user?.displayName}, your order has been placed.  Details are below:</span>
                                            <br /><br />
                                        </div>
{/*                                        <div className={classes.normal}style={{ paddingTop: 10 }}>Your transaction ID for this payment is: {this.transactionId}.</div>*/}
{/*                                        <div className={classes.normal} style={{ paddingTop: 20, paddingBottom: 20}}>We'll send a confirmation email to {global.user?.email}. This transaction will appear on your statement as PRINTPAL.</div>*/}
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    {/*<Grid item xs={this.state.isMobileApp ? 5 : 3}>
                                        <img src={`./api/Files/${this.order.imgUrl}`} alt="menu page" className={classes.reviewImg} />
                                    </Grid>*/}
                                    <Grid item xs={this.state.isMobileApp ? 7 : 9}>
                                        <Grid container>
                                            <Grid item xs={10}>
                                                {
                                                    orderDetails.map((row, index) => {
                                                        return(
                                                            <Grid container key={index}>
                                                                <Grid item xs={this.state.isMobileApp ? 5 : 5}>
                                                                    <span className={classes.normal}>{row?.name}</span>
                                                                </Grid>
                                                                <Grid item xs={7}>
                                                                    <span>{row?.value}</span>
                                                                </Grid>
                                                            </Grid>
                                                        )
                                                    })
                                                }
                                                {mealDealMessaging && 
                                                    <Grid container>
                                                        <Grid item xs={this.state.isMobileApp ? 5 : 5}>
                                                            <span className={classes.normal}>Price:</span>
                                                        </Grid>
                                                        <Grid item xs={7}>
                                                            <span>{new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP" }).format(Number(this.order.price))}</span>
                                                        </Grid>
                                                    </Grid>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container style={{ paddingTop: 20}}>
                                    <Grid item xs={12}>
                                        <div><RouterLink className={`${classes.normal} ${classes.orange}`} to="/">Return to Britvic Designer</RouterLink></div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state: AppState) => ({
    printPal: state.printPal,
    global: state.global,
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        showNavigation: (showNavigation: boolean) => dispatch(globalActions.showNavigation(showNavigation)),
        printPalPage: (printPalPage: boolean) => dispatch(myMenuActions.printPalPage(printPalPage)),
        checkout: (checkout: boolean) => dispatch(printPalActions.checkout(checkout)),
        getMe: () => dispatch(globalOperations.getMe()),
    }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(PaymentProcessed));
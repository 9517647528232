import React, { CSSProperties, useState } from "react";
import Field, { FormikForm } from "../field/Field";
import Validation from "../../../utils/validationFunctions"
import * as Yup from 'yup';
import { useFormik } from 'formik';
import PosButton from "../posButton/PosButton";

import { VoucherDTO, VoucherOverlayResponse, VoucherOverlayForm } from "../../../pages/POS/VouchersPage";
import { ProductDTO } from "../../../pages/POS/ProductsPage";
import LoadingSpinner from "../LoadingSpinner";
import StyleOptionEditor from "./StyleOptionEditor";

const outerContainerStyles: CSSProperties = {
    position: "fixed",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: 0,
    right: 0,
    padding: 8,
    boxSizing: "border-box",
    zIndex: 2,
    backgroundColor: "rgba(0, 0, 0, 0.6)"
}

const innerContainerStyles: CSSProperties = {
    maxWidth: 800,
    width: "100%",
    backgroundColor: "white",
    borderRadius: 10,
    boxShadow: "5px 5px 5px 0px rgba(0, 0, 0, 0.35)",
    border: "1px #797979 solid",
    boxSizing: "border-box",
    padding: 40,
    display: "flex"
}

const listStyle: CSSProperties = {

    fontFamily: "Segoe UI Light",
    width: "100%",
    padding: 6,
    marginTop: 0,
    height: 180,
    overflowY: "auto",
    listStyleType: "none"


};

function ProductOverlay(props: ProductOverlayProps) {

    let initialValues: ProductDTO = props.product;

    initialValues.options = initialValues.options ?? '';

    /*const [styleOptions, setStyleOptions] = useState(props.product.styleOptions);

    let sortedStyleOptions = styleOptions
        .sort((a, b) => a.displayOrder - b.displayOrder)
        .map((option, index) => { return { ...option, displayOrder: index } })
    //initialValues.styleOptionsString = initialValues.styleOptions.map(option => option.description).join("\n");*/

    const productUpdateSchema = Yup.object().shape({
        title: Yup.string().required(Validation.errorMessages.required),
        description: Yup.string().required(Validation.errorMessages.required),
        options: Yup.string().required(Validation.errorMessages.required),
        paperSizes: Yup.mixed(),
        filters: Yup.mixed(),
        show: Yup.bool(),
    });

    const [imageLoaded, setImageLoaded] = useState(false);
    

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: values => {
            props.save(values);
        },
        enableReinitialize: true,
        validationSchema: productUpdateSchema,
        validateOnMount: true,
        validateOnChange: true
    });

    const form: FormikForm = {
        ...formik,
        allDisabled: false
    }

    const handleMultiselect = (value: number[]) => {
        formik.setFieldValue('tags', value);
    }

    const formats = props.product.formats;

    return <div style={outerContainerStyles}>
        <div style={innerContainerStyles}>
            <div style={{ display: "flex", flexDirection: "column" } }>

                { formats.length > 0
                    && <img
                        style={{ width: 238, display: imageLoaded ? "block" : "none" }}
                        onLoad={() => { setImageLoaded(true) }}
                        src={"../../assets/styles/preview/" + props.product.formats[0].imgUrl} /> }
                

                {!imageLoaded &&
                    <div style={{ width: 211, height: 187 }} >
                        { formats.length > 0 &&  <LoadingSpinner width={187} /> }
                    </div>
                } 


                <br/>

                { formats.length > 1 &&
                    <>
                    <b>Paper sizes</b>
                    <ul style={listStyle}>
                        {
                            props.product.formats.map(format => <li>{format.title}</li>)
                        }
                    </ul>
                    </>
                }

            </div>

            <form style={{width:"100%"}} onSubmit={(e) => { console.log(formik.errors); formik.handleSubmit(e) }}>
                <table style={{ width: "100%" }}><tbody>

                    <Field title="Title:" id="title" isRequired form={form} />
                    <Field specialType="textarea" forceLabelWidth={100} title="Description:" id="description" isRequired form={form} />

                    <Field isRequired id="options" title="Options:" specialType="textarea" form={form} />
                    

                    <Field
                        title="Filters:"
                        id="tags"
                        specialType="multiselect"
                        form={form}
                        onChange={handleMultiselect} />
                    <Field title="Show:" id="isEnabled" type="checkbox" form={form} />

                    <tr>
                        <td></td>

                        <td></td>
                        <td>

                            <div style={{ float: "right", display: "flex", flexWrap: "wrap", justifyContent: "flex-end" }}>

                                <PosButton isSecondary onClick={props.cancel} text="Cancel"></PosButton>
                                <PosButton type="submit" text="Save"></PosButton>
                            </div>
                        </td>
                    </tr>

                </tbody></table>
            </form>

        </div>
    </div>
}



interface ProductOverlayProps {
    product: ProductDTO;
    cancel: () => void;
    save: (data: ProductDTO) => void;
}


export default ProductOverlay;
import { Grid } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import PrintPalFormatsOptions, { PrintPalFormatOptionsForm } from "../components/printPal/PrintPalFormatsOptions";
import PrintPalFormatsPreview from "../components/printPal/PrintPalFormatsPreview";
import { AppState } from "../store";
import { myMenuActions } from "../store/myMenu";
import useGeneratePdf from "../utils/useGeneratePdf";
import { printPalOperations } from "../store/printpal";
import { usePlaceOrderWithFormatsStyles } from "../styles";

export interface GetFormatsBySizesRequest {
    formats: string[]
}

export interface FormatModelPartial {
    id: number
    title: string
    description: string
    imgUrl: string
    size: string //<- key
    sheet: string
    isNew: boolean
    isPopular: boolean
    isActive: boolean
    width: number
    height: number
    widthPx: number
    heightPx: number
    price: number
    formatKey: string
    availableInMultipleOf: number,
    showInProductCarousel: boolean,
    allowPurchase: boolean
}

export interface PlaceOrderSharedProps {
    formState: PrintPalFormatOptionsForm
    availableFormatsData?: FormatModelPartial[]
    totalPrice: number
}

const PlaceOrderWithFormats: React.FC = () => {
    const { global, menu, myMenu, printPal } = useSelector((state: AppState) => state);
    const { paperData, loading } = printPal;
    const { menuData } = menu;
    const { getTemplate } = useGeneratePdf();
    const classes = usePlaceOrderWithFormatsStyles();

    const dispatch = useDispatch();
    const printPalPage = (printPalPage: boolean) => dispatch(myMenuActions.printPalPage(printPalPage));
    const getConfig = () => dispatch(printPalOperations.getConfig());
    const history = useHistory();
    const [isMobileApp, setIsMobileApp] = useState(false);
    const [showFormats, setShowFormats] = useState(false);
    const [form, setForm] = React.useState<PrintPalFormatOptionsForm>({
        dealOptions: {
            onlyDrinksFromRange: false,
            extendedSpaceForDrinks: false,
            additionalDrinks: false,
            firstTimeUsedInCalenderYear: false,
            IPApproval: false
        },
        voucher: "",
        quantities: [],
        inTheDealStickers: 0,
        magic8Stickers: false,
        wobblerArmsAmount: 0
    });
    const [availableFormats, setAvailableFormats] = useState<FormatModelPartial[]>();
    const [isSaving, setIsSaving] = useState<boolean>();
    const [failedIPValidation, setFailedIPValidation] = useState<boolean>(false);
    const [failedAtLeastOneValidation, setFailedAtLeastOneValidation] = useState<boolean>(false);
    const [shippingCost, setShippingCost] = useState<number>(0);

    const price = useMemo(() => {
        if(form?.magic8Stickers){
            return 0
        }else{
            return form ? form.quantities.reduce((prev, curr, i) => {
                if (availableFormats) {
                    const formatData = availableFormats.find((f) => f.id == curr.formatId);
                    return formatData ? prev + (curr.quantity * formatData.price) : prev;
                } else { return prev; }
            }, 0) : 0;
        }
    }, [form, availableFormats]);

    const totalPrice = useMemo(() => {
        if(form?.magic8Stickers){
            return shippingCost ?? 0;
        }else{
            return price ? price + shippingCost : 0;
        }
    }, [price, shippingCost, form?.magic8Stickers]);

    const placeOrderProps: PlaceOrderSharedProps = {
        totalPrice,
        availableFormatsData: availableFormats,
        formState: form
    }

    const setResponsiveness = () => {
        return setIsMobileApp(window.innerWidth < 600 ? true : false);
    }

    const onSubmit = () => {
        // Called when PLACE ORDER & PAY is pressed
        setIsSaving(true);
        setFailedIPValidation(false);
        setFailedAtLeastOneValidation(false);
        const { paperData, config } = printPal;
        //const { voucherNumber, voucherError, qty, price, option } = this.state;
        let failed = false;
        if (!form.dealOptions.IPApproval) {
            setFailedIPValidation(true);
            failed = true;
        }

        if (!(form.dealOptions.additionalDrinks ||
            form.dealOptions.extendedSpaceForDrinks ||
            form.dealOptions.firstTimeUsedInCalenderYear ||
            form.dealOptions.onlyDrinksFromRange)
            == true) {
            setFailedAtLeastOneValidation(true);
            failed = true;
        }

        if (failed) {
            setIsSaving(false);
            return;
        }

        var avFormatList = menu.menuData?.availableFormats;
        if (avFormatList) {
            var templates = avFormatList.map((a) => getTemplate(a));
            let order = {
                orderType: "MEALDEAL",
                menuId: menuData?.id ?? 0,
                title: menuData?.title ?? "",
                qty: 1,
                delCharge: config?.carriage,
                vat: config?.vatPercent,
                price: price,
                voucherCode: form?.voucher,
                form: form,
                templates: templates,
                isMenu: false
            };

            console.log("sessionStorage.order being set from this.preview", order);
            sessionStorage.setItem('order', JSON.stringify(order));
            history.push("order-payment");
        }
        else
            console.log("No current format selected, unable to export");
    }

    useEffect(() => {
        printPalPage(true);
        getConfig();
        setTimeout(() => setShowFormats(true), 300)
        window.addEventListener("resize", setResponsiveness);

        fetch("/api/variables/shippingcost").then( async (response) => {
            setShippingCost(parseFloat(await response.text()));
        });

        return () => {
            window.removeEventListener("resize", setResponsiveness);
        }
    }, [])

    useEffect(() => {
        if(menu.menuData?.availableFormats){
            setAvailableFormats(menu.menuData?.availableFormats);
        }
    }, [menu.menuData?.availableFormats])

    return (
        <div style={{ display: "flex", flex: 1 }}>
            {
                !menuData &&
                    <Redirect to="/" />
            }
            <Grid container direction={isMobileApp ? "column" : "row"}>
                <Grid item xs={isMobileApp ? 12 : 5}>
                    {showFormats &&
                        <PrintPalFormatsPreview placeOrderProps={placeOrderProps} />}
                </Grid>
                <Grid item xs={isMobileApp ? 12 : 7}>
                    <div className={classes.content}>
                        <PrintPalFormatsOptions
                            showMagic8Option={menu.menuData?.timesOrdered ? menu.menuData?.timesOrdered > 0 : false}
                            isSaving={isSaving}
                            onChange={(f) => { setForm(f) }}
                            onSubmit={onSubmit}
                            placeOrderProps={placeOrderProps}
                            failedAtLeastOneValidation={failedAtLeastOneValidation}
                            failedIPValidation={failedIPValidation} />
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default PlaceOrderWithFormats;
import React, { useEffect, useState } from "react";
import { FilterPropertiesState, FilterOption } from "../../components/POS/Filters";
import LoadingSpinner from "../../components/POS/LoadingSpinner";
import TableWrapper from "../../components/POS/table/Table";
import Filters from "../../components/POS/Filters";
import LazyIcon from "../../components/POS/LazyIcon";
import { useHistory } from "react-router-dom";
import PDFIcon from "../../assets/pdf.png";
import { MonthlyReports, MonthlyReport } from "../../types";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';


function SimpleDialog(props: any) {

    const { onClose, selectedValue, open } = props;

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} maxWidth={false }>
            {selectedValue &&
                <>

                <DialogTitle id="simple-dialog-title">Downloading '{selectedValue.reportName}'</DialogTitle>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress color="secondary"  />
                </div>
            </>
            }
        </Dialog>
    );
}


function MonthlyReportsPage() {

    const [monthlyReports, setMonthlyReports] = useState<MonthlyReports | null | undefined>(null);
    const [selectedValue, setSelectedValue] = React.useState<MonthlyReport | null>(null);
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = (item: MonthlyReport) => {
        setSelectedValue(item);
        setOpen(true);
        fetch(`${process.env.PUBLIC_URL}/api/reporting/getreport?identifier=${item.identifier}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/pdf',
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    item.filename,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                if (link.parentNode) {
                    link.parentNode.removeChild(link);
                }
                setOpen(false);

            });



    };

    const handleClose = () => {
        setOpen(false);
        setSelectedValue(null);
    };

    const getMonthlyReports = () => {
        fetch(`${process.env.PUBLIC_URL}/api/reporting/monthlyreports`).then(async (response) => {
            const reports: MonthlyReports = await response.json();
            setMonthlyReports(reports);
        })
    };

    const useStyles = makeStyles({
        table: {
            minWidth: 650,
        },
        th: {
            fontWeight: 700
        }
    });
    const classes = useStyles();

    useEffect(getMonthlyReports, []);

    return <>

        <div style={{ flexGrow: 2 }}>
            <h2 style={{ fontSize: 32, color: "#333", margin: 0, fontFamily: "Segoe UI", fontWeight: "bold" }}>Monthly Reports</h2>
            <p style={{ margin: "0 0 16px 0" }}>Download reports covering each billing month.</p>
        </div>

        <hr className="line-divider" />

        <Grid container spacing={3}>

            <Grid item xs={12}>
                <Box sx={{ paddingTop: 20, paddingBottom: 20 }} >

                    Click on <i>download</i> for the report you want to view. An Excel compatible file will be downloaded to your browser.
                </Box>

            </Grid>

            <Grid item xs={6}>
                {monthlyReports &&
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.th}>Report period</TableCell>
                                    <TableCell className={classes.th} align="center"># of lines</TableCell>
                                    <TableCell className={classes.th} align="center">Value</TableCell>
                                    <TableCell className={classes.th} align="center"></TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {monthlyReports.reports.map((row) => (
                                    <TableRow key={row.identifier}>
                                        <TableCell align="left" scope="row">
                                            {row.reportName}
                                        </TableCell>
                                        <TableCell align="center" scope="row">
                                            {row.lineItems}
                                        </TableCell>

                                        <TableCell align="center" scope="row">
                                            £{row.totalAmount.toFixed(2)}
                                        </TableCell>
                                        <TableCell align="right"><a onClick={() => handleClickOpen(row)} href="#">download</a></TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                }
            </Grid>
        </Grid>
        <SimpleDialog selectedValue={selectedValue} open={open} onClose={handleClose} />

    </>
}

export default MonthlyReportsPage;

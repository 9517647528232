import React from "react";
import { FormControlLabel, Grid, Radio, RadioGroup } from "@material-ui/core";
import { IMenuItem, menuActions } from "../../store/menu";
import Components from "./";
import Droppable from "../decorators/Droppable";
import Draggable from "../decorators/Draggable";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import WithCustomTheme from "../../interfaces/WithCustomTheme";
import StyleInjector from "../decorators/StyleInjector";
import Editable from "../decorators/Editable";
import IsExporting from "../decorators/IsExporting";
import ICustomTheme from "../../interfaces/ICustomTheme";

interface IColumnsProps {
    items: IMenuItem[][],
    id: string,
    boxed: boolean,
    border: string,
    moveItem: any,
    classes: any,
    isExporting: boolean,
    className: string,
    foldedContainer: boolean,
    rootContainer: boolean,
    gutter: number,
    background: boolean,
    boundsRef: any,
    theme: ICustomTheme,
    useThemeBorderColor: boolean
}

function Boxed(props: { children: any, boxed: boolean, columnIndex: number, theme: ICustomTheme, items: any }) {
    const { children, boxed, columnIndex, theme, items } = props; 

    return boxed ? <Grid key={columnIndex} style={{
        border: boxed ? theme.columns.a4 ? "4px solid" : "5px solid" : "none",
        borderColor: boxed ? (columnIndex + 1) / 2 === 1 ? theme.columns.color2?.borderColor : theme.columns.color1?.borderColor : "none",
        padding: boxed ? theme.columns.a4 ? 0 : 9 : 0,
        marginRight: boxed ? (columnIndex + 1) < items.length ? theme.columns.a4 ? 20 : 14 : 0 : 0,
        marginTop: boxed ? theme.columns.a4 ? 10 : 0 : 0,
        width: boxed ? `${(100 / items.length) - (theme.columns.a4 ? 2 : 1)}%` : `${100 / items.length}%`
    }}>
        {children}
    </Grid> : children;
}

function OptionalEditable(props: { data: any, enable: boolean, children: any, fullHeight: boolean }) {
    const { data, enable, children, fullHeight = false } = props;    
    return enable ? <Editable fullHeight={fullHeight} data={data}>{children}</Editable> : children;
}

class Columns extends React.Component<IColumnsProps & WithCustomTheme> {
    moveItem = (item: any, droppable: any) => {
        if (item.id === droppable.id)
            return;

        this.props.moveItem(droppable, item);
    }

    getPaddingLeft = (columnIndex: number, foldedContainer: boolean, gutter: number) => {
        if (!foldedContainer) return undefined;
        
        return columnIndex === 0 ? 0 : gutter !== undefined ? gutter : 30;
    }

    getPaddingRight = (columnIndex: number, foldedContainer: boolean, gutter: number) => {
        if (!foldedContainer) return undefined;

        return columnIndex === 0 ? gutter !== undefined ? gutter : 30 : 0;
    }

    render() {
        const { items, id, classes, boxed, border, className, background, foldedContainer, rootContainer, gutter, boundsRef, theme, useThemeBorderColor } = this.props;

        const data = {
            id,
            name: boxed ? "Boxed Columns" : "Columns",
            initialValues: {
                boxed,
                border,
                background,
                useThemeBorderColor
            },
            fields: [
                classes.background && {
                    label: "Show background?",
                    render: (onChange: any, dataMapping: any) => {
                        return <RadioGroup name="background" onChange={onChange} value={dataMapping["background"]?.toString() ?? "false"} defaultValue="false" aria-label="background colour">
                            <Grid container style={{ marginBottom: 15 }}>
                                <Grid item>
                                    <FormControlLabel value="false" label="No background colour" control={<Radio />} labelPlacement="end" />
                                </Grid>

                                <Grid item>
                                    <FormControlLabel value="true" label="Apply background colour" control={<Radio />} labelPlacement="end" />
                                </Grid>
                            </Grid>
                        </RadioGroup>
                    }
                }
            ]
        };

        const getHeight = (component: string): any => {
            //Custom list of full height stuff
            if (theme.columns?.fullHeight && theme.columns.fullHeight.indexOf(component) !== -1)
                return { flex: "1 0 0", display: "flex" };

            //Always make covers full height
            if (component === "Cover" || component === "DecoratedCover")
                return { flex: "1 0 0", display: "flex", height: "100%" };

            return undefined;
        }

        const fullHeight = theme.columns?.fullHeight ? theme.columns.fullHeight.length === 0 : false;

        return <OptionalEditable fullHeight={fullHeight} enable={!foldedContainer && !rootContainer} data={data}>
            <IsExporting>
                {(exporting: boolean) => (
                    <Grid container className={`${className} ${classes.root ?? ""} ${exporting ? classes.exporting : ""} ${background ? classes.background : theme?.columns?.backgroundImage ?? ""}`} style={{ border: border ?? "none", borderColor: useThemeBorderColor ? theme.columns.border?.borderColor : "none" }}>
                        {items.map((column: IMenuItem[], columnIndex: number) => (
                            <Boxed columnIndex={columnIndex} {...this.props}>
                                <Grid
                                    item
                                    key={columnIndex}
                                    className={classes.column}
                                    style={{ width: `${100 / (boxed ? 1 : items.length)}%`, paddingLeft: this.getPaddingLeft(columnIndex, foldedContainer, gutter), paddingRight: this.getPaddingRight(columnIndex, foldedContainer, gutter) }}
                                    ref={ref => boundsRef ? boundsRef(ref) : null}
                                >
                                    {column.length > 0 ? column.map((item: IMenuItem, index: number) => {
                                        var Component = (Components as any)[item.component];

                                        return Component &&
                                            <Droppable column={columnIndex} index={index} key={item.props.id} id={id + " " + item.props.id}>
                                                {(connectDropTarget: any) => (
                                                    <Draggable column={columnIndex} index={index} id={id + " " + item.props.id} end={this.moveItem}>
                                                    {(connectDragTarget: any, connectDragPreview: any, dragging: boolean) => (
                                                        <div ref={node => connectDragTarget(connectDropTarget(node))} style={{ opacity: dragging ? 0 : 1, ...getHeight(item.component) }}>
                                                                <Component {...item.props} />
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                )}
                                            </Droppable>
                                    }) : <Droppable column={columnIndex} index={columnIndex} key={columnIndex} id={id}>
                                            {(connectDropTarget: any) => (
                                                <div ref={node => connectDropTarget(node)} className={classes.empty}>
                                                    <span>Drag any item into this column</span>
                                                </div>
                                            )}
                                        </Droppable>
                                    }
                                </Grid>
                            </Boxed>
                        ))}
                    </Grid>
                )}
            </IsExporting>
        </OptionalEditable>
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        moveItem: (to: any, from: any) => dispatch(menuActions.moveItem(to, from))
    }
}

export default connect(null, mapDispatchToProps)(StyleInjector({}, "columns", true)(Columns));
import React from "react";
import { Typography, WithStyles } from "@material-ui/core";
import { connect } from "react-redux";
import ILibraryImage from "../../interfaces/ILibraryImage";
import { menuOperations } from "../../store/menu";
import Editable from "../decorators/Editable";
import StyleInjector from "../decorators/StyleInjector";
import ImageUpload from "../form/ImageUpload";
import ICustomTheme from "../../interfaces/ICustomTheme";

interface IImageProps {
    id: string,
    image: string,
    uploadFile: any,
    theme: ICustomTheme
}

function Image(props: IImageProps & WithStyles) {
    const { id, image, classes, uploadFile, theme } = props;

    const data = {
        id,
        name: "Image",
        initialValues: {
            image
        },
        state: {
            formData: undefined,
            error: false
        },
        fields: [
            {
                label: "Image",
                render: (onChange: any, dataMapping: any, state: any) => {
                    return <React.Fragment>
                        {state.editableState.error && <Typography variant="body2" gutterBottom color="error">Image upload failed, please make sure your image is under 5mb and is a jpg or png</Typography>}

                        <ImageUpload
                            dataMapping={dataMapping}
                            fileUploaded={(formData: FormData) => {
                                state.onSetState({
                                    editableState: { ...state.editableState, formData, error: false }
                                });
                            }}
                            imageSelected={(image: ILibraryImage) => {
                                state.onSetState({
                                    dataMapping: { ...dataMapping, image: image.filePath },
                                    editableState: { ...state.editableState, formData: null, error: false }
                                });
                            }} />
                    </React.Fragment>
                }
            }
        ],
        onSaved: (dataMapping: any, state: any) => {
            return new Promise<void>((resolve: any, reject: any) => {
                if (!state.editableState.formData) {
                    resolve();
                    return;
                }

                //On save upload the file to the server
                uploadFile(state.editableState.formData).then((file: any) => {
                    var url = window.location.origin + "/api/Files/" + file.fileId;
                    dataMapping.image = url;
                    resolve();
                    return false;
                }).catch(() => {
                    reject();
                    return true;
                }).then((error: boolean) => {
                    //Reset form data
                    state.onSetState({
                        editableState: { ...state.editableState, formData: undefined, error }
                    });
                });
            });
        }
    }

    return <Editable data={data} fullHeight={theme.rectangularImage?.fullHeight ?? false}>
        <div className={classes.root} style={{ backgroundImage: `url(${image})` }}>
            <div className={classes.border} />
        </div>
    </Editable>;
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        uploadFile: (file: any) => dispatch(menuOperations.uploadFile(file))
    }
}

export default connect(null, mapDispatchToProps)(StyleInjector({}, "rectangularImage", true)(Image));
import React, { useEffect } from 'react';
import { Grid, Typography, Checkbox, CardContent, Theme, makeStyles, createStyles, CardMedia, Card, FormControl, Button, Accordion, AccordionSummary, AccordionDetails, Box } from "@material-ui/core";
import NativeSelect from '@material-ui/core/NativeSelect';
import { usePagination } from '@material-ui/lab/Pagination';
import { withRouter } from "react-router-dom";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > * + *': {
                marginTop: theme.spacing(2)
            },
            "& .MuiPaginationItem-icon": {
                backgroundColor: "transparent",
                marginLeft: theme.spacing(10),
                marginRight: theme.spacing(10)
            }
        },
        allStyles: {
            background: "#f8efed",
            overflow: "hidden",
            paddingLeft: theme.spacing(7),
            paddingRight: theme.spacing(7),
            paddingTop: theme.spacing(5),
            paddingBottom: theme.spacing(5),
            minHeight: "700px"
        },
        allStylesMobile: {
            background: "#f8efed",
            overflow: "hidden",
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            "& h3": {
                marginBottom: theme.spacing(2)
            }
        },
        pagination: {
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(2),
            alignContent: "center",
            alignItems: "center"
        },
        checkboxIcon: {
            borderRadius: 0,
            width: 20,
            height: 20,
            backgroundColor: 'white',
        },
        checkboxIconMobile: {
            border: "1px solid gray",
            width: 20,
            height: 20,
            backgroundColor: 'white',
        },
        filterGridItem: {
            height: "30px",
            display: "flex",
            alignItems: "center"
        },
        filterHeader: {
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(1)
        },
        selectButton: {
            backgroundColor: "#FFF",
            color: "#007D7A",
            padding: "6px 16px",
            border: "1px solid #007D7A",
            fontWeight: "bold" as "bold",
            "&:hover": {
                backgroundColor: "#007D7A",
                color: "#FFF"
            }
        },
        verticalLine: {
            borderLeft: "1px solid gray",
            height: "100%",
            marginLeft: theme.spacing(5)
        },
        header: {
            paddingBottom: theme.spacing(4)
        },
        selected: {
            backgroundColor: 'white',
            color: '#007D7A',
        },
        sortControl: {
            minWidth: 120,
            border: "2px solid",
            borderColor: "white",
            backgroundColor: "white"
        },
        selectEmpty: {
            margin: theme.spacing(1)
        },
        ul: {
            listStyle: 'none',
            padding: 0,
            margin: 0,
            display: 'flex',
        },
        media: {
            height: 200,
            width: "auto"
        },
        bannerOverlay: {
            top: "185px",
            left: "15px",
            color: "black",
            backgroundColor: "transparent"
        },
        searchIconOverlay: {
            top: "6px",
            left: "87%",
            color: "white",
            backgroundColor: "transparent"
        },
        banner: {
            height: "60%",
            width: "60%",
        },
        icon: {
            fontSize: 25,
            "&:hover": {
                cursor: "pointer",
            },
        },
        pageNumberButton: {
            color: "black",
            width: 20,
            height: 25,
            marginRight: 5,
            marginLeft: 5,
            marginTop: 5,
            textAlign: "center",
            borderColor: "transparent",
            background: "transparent",
            borderBottom: "2px solid",
            "&:hover": {
                borderColor: "transparent",
                background: "transparent",
                borderBottom: "2px solid",
                cursor: "pointer",
            }
        },
        pageNumberButtonMobile: {
            color: "black",
            fontSize: 12,
            width: 18,
            height: 18,
            marginRight: 5,
            marginLeft: 5,
            marginTop: 2,
            textAlign: "center",
            borderColor: "transparent",
            background: "transparent",
            borderBottom: "2px solid",
            "&:hover": {
                borderColor: "transparent",
                background: "transparent",
                borderBottom: "2px solid",
                cursor: "pointer",
            }
        },
        pagePrevNextButton: {
            color: "black",
            width: 50,
            borderColor: "transparent",
            background: "transparent",
            marginLeft: theme.spacing(4),
            marginRight: theme.spacing(4),
            "&:hover": {
                borderColor: "transparent",
                background: "transparent",
            }
        },
        card: {
            height: 270,
            position: "relative",
            "&:hover": {
                cursor: "pointer"
            }
        },
        customLabelContainer: {
            padding: "3px 10px",
            textTransform: "uppercase",
            color: "white",
            fontWeight: 600,
            fontSize: 15
        }
    }),
);

const MOSTPOPULAR = "1";
const AZASCENDING = "2";
const AZDESCENDING = "3";
const menuStylesPerPage = 12;

interface IState {
    selectedStyleFilterIds: string[];
    selectedSuitableForFilterIds: string[];
    selectedColourFilterIds: string[];
    selectedSeasonFilterIds: string[];
    currentPage: number;
    sortOption: string;
    isMobileApp: boolean;
}

const AllStyles = (props: any) => {
    const classes = useStyles();
    const { styleData: { styles, suitableForFilters, menuTypeFilters, colourFilters, seasonFilters }, setTargetStyle } = props;
    const mealDealMessaging = useSelector((state: any) => state.global.session.mealDealMessaging);
    const [state, setState] = React.useState<IState>({
        selectedStyleFilterIds: [],
        selectedSuitableForFilterIds: [],
        selectedColourFilterIds: [],
        selectedSeasonFilterIds: [],
        currentPage: 1,
        sortOption: MOSTPOPULAR,
        isMobileApp: false
    });

    useEffect(() => {
        let mounted: boolean = false;
        const setResponsiveness = () => {
            if (mounted)
                return window.innerWidth < 900
                    ? setState((prevState) => ({ ...prevState, isMobileApp: true }))
                    : setState((prevState) => ({ ...prevState, isMobileApp: false }));
        };
        mounted = true;
        setResponsiveness();
        window.addEventListener("resize", () => setResponsiveness());

        return () => { mounted = false; window.removeEventListener('resize', () => setResponsiveness()) };
    }, []);

    let totalPages: number = 0;
    let indexOfLastMenuStyle = state.currentPage * menuStylesPerPage;
    let indexOfFirstMenuStyle = indexOfLastMenuStyle - menuStylesPerPage;
    let filteredMenuStyleData: any = [];

    filteredMenuStyleData = styles.map((obj: any) => ({ ...obj }));

    if (state.selectedStyleFilterIds.length !== 0 ||
        state.selectedSuitableForFilterIds.length !== 0 ||
        state.selectedColourFilterIds.length !== 0 ||
        state.selectedSeasonFilterIds.length !== 0) {
        for (let data of styles) {
            let found = false;
            for (let item of state.selectedStyleFilterIds) {
                if (data.menuTypeFilters.includes(item)) {
                    found = true;
                    break;
                }
            }
            for (let item of state.selectedSuitableForFilterIds) {
                if (data.suitableForFilters.includes(item)) {
                    found = true;
                    break;
                }
            }
            for (let item of state.selectedColourFilterIds) {
                if (data.colourFilters.includes(item)) {
                    found = true;
                    break;
                }
            }
            for (let item of state.selectedSeasonFilterIds) {
                if (data.seasonFilters.includes(item)) {
                    found = true;
                    break;
                }
            }
            if (!found) {
                filteredMenuStyleData = filteredMenuStyleData.filter((style: any) => style.id !== data.id);
            }
        }
    }

    switch (state.sortOption) {
        case MOSTPOPULAR: {
            filteredMenuStyleData.sort((a: any, b: any) => {
                if (a.displayOrder > b.displayOrder) {
                    return 1;
                } else if (a.displayOrder < b.displayOrder) {
                    return -1;
                } else {
                    return 0;
                }
            });
            break;
        }
        case AZASCENDING: {
            filteredMenuStyleData.sort((a: any, b: any) => {
                if (a.title > b.title) {
                    return 1;
                } else if (a.title < b.title) {
                    return -1;
                } else {
                    return 0;
                }
            });
            break;
        }
        case AZDESCENDING: {
            filteredMenuStyleData.sort((a: any, b: any) => {
                if (a.title < b.title) {
                    return 1;
                } else if (a.title > b.title) {
                    return -1;
                } else {
                    return 0;
                }
            });
            break;
        }
    }

    for (let i = 1; i <= Math.ceil(filteredMenuStyleData.length / menuStylesPerPage); i++) {
        totalPages++;
    }

    let showingHeader =
    {
        Start: indexOfFirstMenuStyle + 1,
        End: indexOfLastMenuStyle > filteredMenuStyleData.length ? filteredMenuStyleData.length : indexOfLastMenuStyle,
        Total: filteredMenuStyleData.length
    };

    const { items } = usePagination({
        count: totalPages,
        page: state.currentPage,
        onChange: (event: React.ChangeEvent<unknown>, value: number) => { setState({ ...state, currentPage: value }) }
    })

    const handleSearchIconClick = (event: any) => {
        sessionStorage.setItem('styleId', event.currentTarget.id);
        props.openPopup();
    }

    const handleStyleClicked = (event: any) => {
        sessionStorage.setItem('styleId', event.currentTarget.id);
        props.closePopup();

        const style = styles.find((s: any) => s.id == event.currentTarget.id);
        if (style) {
            if (style.formats.length > 1) {
                props.history.push('/choose-format');
            } else {
                let format = style.formats[0];
                handleFormatSelected(format.id, format.formatStylesId);
                setTargetStyle(style, () => {
                    props.openPopup();
                });
                //select first format
            }
        } else { console.log("Cant find style with id", event.currentTarget.id); }

    }

    const handleFormatSelected = (formatId: string, formatStylesId: string) => {
        sessionStorage.setItem('formatId', formatId);
        sessionStorage.setItem('formatStylesId', formatStylesId);
    }

    const handleColourFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        var id = event.target.name;
        state.selectedColourFilterIds.includes(id) ? removeIdFromColourFilterIdsState(id) : addIdToColourFilterIdsState(id);
    }

    const handleSeasonFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        var id = event.target.name;
        state.selectedSeasonFilterIds.includes(id) ? removeIdFromSeasonFilterIdsState(id) : addIdToSeasonFilterIdsState(id);
    }

    const handleTypeFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        var id = event.target.name;
        state.selectedStyleFilterIds.includes(id) ? removeIdFromTypeFilterIdsState(id) : addIdToTypeFilterIdsState(id);
    }

    const handleSuitableForFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        var id = event.target.name;
        state.selectedSuitableForFilterIds.includes(id) ? removeIdFromSuitableForFilterIdsState(id) : addIdToSuitableForFilterIdsState(id);
    };

    const handleSortOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setState({
            ...state,
            sortOption: event.target.value,
        });
    };

    const addIdToTypeFilterIdsState = (id: string) => {
        setState({
            ...state,
            selectedStyleFilterIds: state.selectedStyleFilterIds.concat(id),
            currentPage: 1
        });
    }

    const addIdToColourFilterIdsState = (id: string) => {
        setState({
            ...state,
            selectedColourFilterIds: state.selectedColourFilterIds.concat(id),
            currentPage: 1
        });
    }

    const addIdToSeasonFilterIdsState = (id: string) => {
        setState({
            ...state,
            selectedSeasonFilterIds: state.selectedSeasonFilterIds.concat(id),
            currentPage: 1
        });
    }

    const removeIdFromTypeFilterIdsState = (id: string) => {
        var index = state.selectedStyleFilterIds.indexOf(id);
        setState({
            ...state,
            selectedStyleFilterIds: state.selectedStyleFilterIds.filter((_item, j) => index !== j),
            currentPage: 1
        });
    }

    const removeIdFromSuitableForFilterIdsState = (id: string) => {
        var index = state.selectedSuitableForFilterIds.indexOf(id);
        setState({
            ...state,
            selectedSuitableForFilterIds: state.selectedSuitableForFilterIds.filter((_item, j) => index !== j),
            currentPage: 1
        });
    }

    const removeIdFromColourFilterIdsState = (id: string) => {
        var index = state.selectedColourFilterIds.indexOf(id);
        setState({
            ...state,
            selectedColourFilterIds: state.selectedColourFilterIds.filter((_item, j) => index !== j),
            currentPage: 1
        });
    }

    const removeIdFromSeasonFilterIdsState = (id: string) => {
        var index = state.selectedSeasonFilterIds.indexOf(id);
        setState({
            ...state,
            selectedSeasonFilterIds: state.selectedSeasonFilterIds.filter((_item, j) => index !== j),
            currentPage: 1
        });
    }

    const addIdToSuitableForFilterIdsState = (id: string) => {
        setState({
            ...state,
            selectedSuitableForFilterIds: state.selectedSuitableForFilterIds.concat(id),
            currentPage: 1
        });
    }

    //debugger

    if (state.isMobileApp) {
        return (
            <div className={classes.allStylesMobile}>
                <Grid container>
                    <Grid item>
                        <Typography variant="h3"><strong>All styles</strong></Typography>
                    </Grid>
                </Grid>

                <Grid container style={{ backgroundColor: "#FFF", paddingTop: 20, paddingLeft: 15, paddingBottom: 20, height: 50, alignContent: "center" }}>
                    <Grid item xs={12}>
                        <Typography style={{ fontSize: 18 }}>Filter by:</Typography>
                    </Grid>
                </Grid>

                <Grid container style={{ backgroundColor: "#FFF", alignContent: "center" }}>
                    <Accordion style={{ width: "100%" }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
                            <Typography style={{ fontSize: 16 }}><strong>Suitable for</strong></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container style={{ backgroundColor: "#FFF" }}>
                                <Grid item xs={12}>
                                    {colourFilters.map((colour: any) => (
                                        <Grid item container key={colour.id}>
                                            <Grid item xs={6} className={classes.filterGridItem}>
                                                <Typography style={{ fontSize: 16 }}>{colour.description}</Typography>
                                            </Grid>
                                            <Grid item xs={6} className={classes.filterGridItem}>
                                                <Checkbox
                                                    onChange={handleColourFilterChange}
                                                    name={colour.id}
                                                    icon={<span className={classes.checkboxIconMobile} />} />
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>





                <Grid container style={{ backgroundColor: "#FFF", alignContent: "center" }}>
                    <Accordion style={{ width: "100%" }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
                            <Typography style={{ fontSize: 16 }}><strong>Season</strong></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container style={{ backgroundColor: "#FFF" }}>
                                <Grid item xs={12}>
                                    {seasonFilters.map((season: any) => (
                                        <Grid item container key={season.id}>
                                            <Grid item xs={6} className={classes.filterGridItem}>
                                                <Typography style={{ fontSize: 16 }}>{season.description}</Typography>
                                            </Grid>
                                            <Grid item xs={6} className={classes.filterGridItem}>
                                                <Checkbox
                                                    onChange={handleSeasonFilterChange}
                                                    name={season.id}
                                                    icon={<span className={classes.checkboxIconMobile} />} />
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>



                <Grid container style={{ backgroundColor: "#FFF", alignContent: "center" }}>
                    <Accordion style={{ width: "100%" }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
                            <Typography><strong>Style</strong></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container style={{ backgroundColor: "#FFF" }}>
                                <Grid item xs={12}>
                                    {menuTypeFilters.map((type: any) => (
                                        <Grid item container key={type.id}>
                                            <Grid item xs={6} className={classes.filterGridItem}>
                                                <Typography style={{ fontSize: 16 }}>{type.description}</Typography>
                                            </Grid>
                                            <Grid item xs={6} className={classes.filterGridItem}>
                                                <Checkbox
                                                    onChange={handleTypeFilterChange}
                                                    name={type.id}
                                                    icon={<span className={classes.checkboxIconMobile} />} />
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>


                {!mealDealMessaging && <div>

                    <Grid container style={{ height: 50, paddingTop: 20, paddingBottom: 10 }}>
                        <Grid item>
                            {showingHeader.Start === showingHeader.End
                                ? <Typography style={{ fontSize: 16 }}><strong>Showing: {showingHeader.Start} of {showingHeader.Total}</strong></Typography>
                                : <Typography style={{ fontSize: 16 }}><strong>Showing: {showingHeader.Start} to {showingHeader.End}</strong> of {showingHeader.Total}</Typography>
                            }
                        </Grid>
                    </Grid>

                    <Grid container style={{ paddingTop: 10, height: 30 }}>
                        <Grid item xs={12}>
                            <Typography>Sort by:</Typography>
                        </Grid>
                    </Grid>

                    <Grid container style={{ paddingTop: 10, paddingBottom: 10 }}>
                        <Grid item xs={12}>
                            <FormControl variant="outlined" className={classes.sortControl} style={{ width: "100%" }}>
                                <NativeSelect
                                    disableUnderline={true}
                                    value={state.sortOption}
                                    onChange={handleSortOptionChange}
                                    name="sortOption"
                                    className={classes.selectEmpty}
                                    inputProps={{ 'aria-label': 'sortOption' }}>
                                    <option value={MOSTPOPULAR}>Most Popular</option>
                                    <option value={AZASCENDING}>A-Z Ascending</option>
                                    <option value={AZDESCENDING}>A-Z Descending</option>
                                </NativeSelect>
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
                }

                <Grid container justify="center" spacing={1}>
                    <Grid item xs={12}>
                        {filteredMenuStyleData.slice(indexOfFirstMenuStyle, indexOfLastMenuStyle).map((menu: any) => (
                            <Grid item xs={12} key={menu.id}>
                                <Card raised={true} id={menu.id} style={{ marginBottom: 15, height: `${mealDealMessaging ? "346px" : "270px"}` }} className={classes.card} onClick={handleSearchIconClick}>
                                    <CardMedia className={classes.media} image={`./assets/styles/${menu.imgUrl}`} style={{ height: `${mealDealMessaging ? "268px" : "200px"}`}}/>
                                    <div className={classes.searchIconOverlay} style={{ position: "absolute" }}>
                                        <img src={`./assets/search.svg`} id={menu.id} key={menu.id} alt="search" className={classes.icon} />
                                    </div>
                                    <div className={classes.bannerOverlay} style={{ position: "absolute", top: `${mealDealMessaging ? "256px" : "185px"}` }}>
                                        {menu.customLabel != null
                                            ?
                                            <div className={classes.customLabelContainer} style={{ backgroundColor: menu.customLabel.colorHex }}>{menu.customLabel.title}</div>
                                            :
                                            menu.isNew
                                                ? <img src="./assets/banners/new.png" alt="banner" className={classes.banner} />
                                                : menu.isPopular
                                                    ? <img src="./assets/banners/popular.png" alt="banner" className={classes.banner} />
                                                    : null
                                        }
                                    </div>
                                    <CardContent style={{ height: 30 }}>
                                        <Grid container direction="row" justify="space-between" alignItems="center">
                                            <Grid item xs>
                                                <Typography style={{ fontSize: 16 }}>
                                                    <strong>{menu.title ? menu.title : "Choose this design"}</strong>
                                                </Typography>
                                            </Grid>

                                            <Grid item xs>
                                                <Button
                                                    id={menu.id}
                                                    onClick={handleStyleClicked}
                                                    className={classes.selectButton}
                                                    variant="contained">Select
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>

                <Grid container justify="center" alignItems="center">
                    <Grid item xs={12}>
                        {state.currentPage === 1 && totalPages === 1
                            ? null
                            : <div style={{ marginTop: 10 }}>
                                <nav>
                                    <ul className={classes.ul}>
                                        {items.map(({ page, type, selected, ...item }, index) => {
                                            let children = null;
                                            if (type === 'start-ellipsis' || type === 'end-ellipsis') {
                                                children = '…';
                                            } else if (type === 'page') {
                                                children = (
                                                    <div
                                                        className={classes.pageNumberButtonMobile}
                                                        style={{ fontWeight: selected ? 'bold' : undefined, borderBottomColor: selected ? "aqua" : "transparent", fontSize: 14 }}
                                                        {...item}
                                                        color="secondary">{page}
                                                    </div>
                                                );
                                            } else {
                                                children = (
                                                    <Button className={classes.pagePrevNextButton} {...item}>
                                                        <div style={{ height: 35, fontSize: 13 }}>
                                                            {type === "previous" ? "< Prev" : "Next >"}
                                                        </div>
                                                    </Button>
                                                );
                                            }
                                            return <li key={index}>{children}</li>;
                                        })}
                                    </ul>
                                </nav>
                            </div>
                        }
                    </Grid>
                </Grid>
            </div>
        );
    }

    return (
        <div className={classes.allStyles}>

            {!mealDealMessaging &&
                <Grid container className={classes.header}>
                    <Grid item xs={3}>
                        <Typography variant="h2" style={{ display: "flex", alignItems: "center" }}><strong>All styles</strong></Typography>
                    </Grid>
                    <Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
                        {showingHeader.Start === showingHeader.End
                            ? <Typography variant="h3"><strong>Showing: {showingHeader.Start} of {showingHeader.Total}</strong></Typography>
                            : <Typography variant="h3"><strong>Showing: {showingHeader.Start} to {showingHeader.End}</strong> of {showingHeader.Total}</Typography>
                        }
                    </Grid>
                    <Grid item container xs={5} justify="flex-end">
                        <div className={classes.filterGridItem}>
                            <Typography style={{ paddingRight: 15 }}>Sort by:</Typography>
                            <FormControl variant="outlined" className={classes.sortControl} style={{ marginTop: 27 }}>
                                <NativeSelect
                                    disableUnderline={true}
                                    value={state.sortOption}
                                    onChange={handleSortOptionChange}
                                    name="sortOption"
                                    className={classes.selectEmpty}
                                    inputProps={{ 'aria-label': 'sortOption' }}>
                                    <option value={MOSTPOPULAR}>Most Popular</option>
                                    <option value={AZASCENDING}>A-Z Ascending</option>
                                    <option value={AZDESCENDING}>A-Z Descending</option>
                                </NativeSelect>
                            </FormControl>
                        </div>
                    </Grid>
                </Grid>
            }
            <Grid container direction="column" >
                <Grid item container>
                    {!mealDealMessaging &&
                        <Grid item xs={2}>
                            <Typography variant="h3">Filter by:</Typography>




                            <Typography className={classes.filterHeader}><strong>Suitable for</strong></Typography>
                            {suitableForFilters.map((suitable: any) => (
                                <Grid item container key={suitable.id}>
                                    <Grid item xs={1} />
                                    <Grid item xs={8} className={classes.filterGridItem}>
                                        <Typography variant="body2">{suitable.description}</Typography>
                                    </Grid>
                                    <Grid item xs={3} className={classes.filterGridItem}>
                                        <Checkbox
                                            onChange={handleSuitableForFilterChange}
                                            name={suitable.id}
                                            icon={<span className={classes.checkboxIcon} />} />
                                    </Grid>
                                </Grid>
                            ))}

                            <Typography className={classes.filterHeader}><strong>Season</strong></Typography>
                            {seasonFilters.map((season: any) => (
                                <Grid item container key={season.id}>
                                    <Grid item xs={1} />
                                    <Grid item xs={8} className={classes.filterGridItem}>
                                        <Typography variant="body2">{season.description}</Typography>
                                    </Grid>
                                    <Grid item xs={3} className={classes.filterGridItem}>
                                        <Checkbox
                                            onChange={handleSeasonFilterChange}
                                            name={season.id}
                                            icon={<span className={classes.checkboxIcon} />} />
                                    </Grid>
                                </Grid>
                            ))}

                            <Typography className={classes.filterHeader}><strong>Colours available</strong></Typography>
                            {colourFilters.map((colour: any) => (
                                <Grid item container key={colour.id}>
                                    <Grid item xs={1} />
                                    <Grid item xs={8} className={classes.filterGridItem}>
                                        <Typography variant="body2">{colour.description}</Typography>
                                    </Grid>
                                    <Grid item xs={3} className={classes.filterGridItem}>
                                        <Checkbox
                                            onChange={handleColourFilterChange}
                                            name={colour.id}
                                            icon={<span className={classes.checkboxIcon} />} />
                                    </Grid>
                                </Grid>
                            ))}


                            <Typography className={classes.filterHeader}><strong>Style</strong></Typography>
                            {menuTypeFilters.map((type: any) => (
                                <Grid item container key={type.id}>
                                    <Grid item xs={1} />
                                    <Grid item xs={8} className={classes.filterGridItem}>
                                        <Typography variant="body2">{type.description}</Typography>
                                    </Grid>
                                    <Grid item xs={3} className={classes.filterGridItem}>
                                        <Checkbox
                                            onChange={handleTypeFilterChange}
                                            name={type.id}
                                            icon={<span className={classes.checkboxIcon} />} />
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    }
                    {!mealDealMessaging &&
                    <Grid item xs={1}>                    
                        <div className={classes.verticalLine}></div> 
                    </Grid>
                    }

                    <Grid item xs={mealDealMessaging?12:9}>
                        <Box sx={{ mb: 4 }} >
                            <Typography variant="h2" style={{ display: "flex", alignItems: "center" }}><strong>Select a design type from the selection below:</strong></Typography>
                        </Box>

       
                        <Grid container spacing={3}>
                            {filteredMenuStyleData.slice(indexOfFirstMenuStyle, indexOfLastMenuStyle).map((menu: any) => (
                                <Grid item xs={12} md={4} sm={6} key={menu.id}>
                                    <Card raised={true} id={menu.id} style={{ height: `${mealDealMessaging ? "346px" : "270px"}` }} className={classes.card} onClick={handleSearchIconClick}>
                                        <CardMedia className={classes.media} image={`./assets/styles/${menu.imgUrl}`} style={{ height: `${mealDealMessaging ? "268px" : "200px"}`}}/>
                                        <div className={classes.searchIconOverlay} style={{ position: "absolute" }}>
                                            <img src={`./assets/search.svg`} id={menu.id} key={menu.id} onClick={handleSearchIconClick} alt="search" className={classes.icon} />
                                        </div>

                                        <div className={classes.bannerOverlay} style={{ position: "absolute", top: `${mealDealMessaging ? "256px" : "185px"}` }}>
                                            {menu.customLabel != null
                                                ?
                                                <div className={classes.customLabelContainer} style={{ backgroundColor: menu.customLabel.colorHex }}>{menu.customLabel.title}</div>
                                                :
                                                menu.isNew ? <img src="./assets/banners/new.png" alt="banner" className={classes.banner} /> :
                                                    menu.isPopular ? <img src="./assets/banners/popular.png" alt="banner" className={classes.banner} /> : null}
                                        </div>

                                        <CardContent style={{ height: 30 }}>
                                            <Grid container wrap="nowrap" direction="row" justify="space-between" alignItems="center">
                                                <Grid item>
                                                    <Typography>
                                                        <strong>{menu.title ? menu.title : "Choose this design"}</strong>
                                                    </Typography>
                                                </Grid>

                                                <Grid item>
                                                    <Button
                                                        id={menu.id}
                                                        onClick={handleStyleClicked}
                                                        className={classes.selectButton}
                                                        variant="contained">Select
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={3}><div style={{ width: "100%" }} /></Grid>
                    <Grid item xs={9}>
                        <Grid container direction="column" justify="center" alignItems="center">
                            <Grid item xs={12}>
                                {state.currentPage === 1 && totalPages === 1
                                    ? null
                                    : <div style={{ marginTop: 40 }}>
                                        <nav>
                                            <ul className={classes.ul}>
                                                {items.map(({ page, type, selected, ...item }, index) => {
                                                    let children = null;
                                                    if (type === 'start-ellipsis' || type === 'end-ellipsis') {
                                                        children = '…';
                                                    } else if (type === 'page') {
                                                        children = (
                                                            <div
                                                                className={classes.pageNumberButton}
                                                                style={{ fontWeight: selected ? 'bold' : undefined, borderBottomColor: selected ? "aqua" : "transparent" }}
                                                                {...item}
                                                                color="secondary">{page}
                                                            </div>
                                                        );
                                                    } else {
                                                        children = (
                                                            <Button className={classes.pagePrevNextButton} {...item}>
                                                                <div style={{ height: 45, fontSize: 16 }}>
                                                                    {type === "previous" ? "< Prev" : "Next >"}
                                                                </div>
                                                            </Button>
                                                        );
                                                    }
                                                    return <li key={index}>{children}</li>;
                                                })}
                                            </ul>
                                        </nav>
                                    </div>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

export default withRouter(AllStyles);

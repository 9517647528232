import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { AppState } from "../../store";
import { IGlobalState } from "../../store/global";

interface IPosRoute extends RouteProps{
}
const PosRoute: React.FC<IPosRoute> = ({component, ...rest}) => {

    const user = useSelector((state: AppState) => state.global.user);
    const isAuth = user && user.role?.weight != undefined && user.role.weight > 0;
    const routerComponent = isAuth && component
        ?   React.createElement(component)
        :   <Redirect to={"/"} />;

    return(
        <Route
            {...rest}
        >
            {routerComponent}
        </Route>

    )
}

export default PosRoute;
import React from 'react';
import { Theme, withStyles, WithStyles, Typography, Button, Dialog, Grid } from '@material-ui/core';
import { withRouter } from "react-router-dom";
import { CloseRounded } from "@material-ui/icons";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const styles = (theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(0),
    },
    close: {
        position: "absolute" as "absolute",
        right: 0,
        top: 0,
        width: 52,
        height: 52,
        border: "2px solid #FFF",
        borderRadius: "50%",
        backgroundColor: "#007D7A",
        textAlign: "center" as "center",
        lineHeight: "52px",
        color: "#FFF",
        transform: "translate(50%,-50%)",
        cursor: "pointer",
        "& svg": {
            width: 32,
            height: "100%"
        }
    },
    closeMobile: {
        position: "absolute" as "absolute",
        right: 30,
        top: 30,
        width: 32,
        height: 32,
        border: "2px solid #FFF",
        borderRadius: "50%",
        backgroundColor: "#007D7A",
        textAlign: "center" as "center",
        lineHeight: "32px",
        color: "#FFF",
        transform: "translate(50%,-50%)",
        cursor: "pointer",
        "& svg": {
            width: 32,
            height: "100%"
        }
    },
    selectButton: {
        backgroundColor: "#FFF",
        color: "#007D7A",
        border: "1px solid #007D7A",
        fontWeight: "bold" as "bold",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        "&:hover": {
            backgroundColor: "#007D7A",
            border: "1px solid #007D7A",
            color: "#FFF"
        }
    },
    section: {
        marginTop: theme.spacing(3),
    },
    previewImage: {
        maxWidth: 300,
        width: "100%",
        height: "auto",
    },
    previewImageMobile: {
        width: 100,
        height: "auto",
    }
});

interface IPopupState {
    currentSlide: number;
    isMobileApp: boolean;
}

class StyleInfoPopup extends React.Component<WithStyles & any, IPopupState> {
    private mounted: boolean = false;

    constructor(props: any) {
        super(props);
        //debugger;
        this.state = {
            currentSlide: 0,
            isMobileApp: false,

        };

        this.handleClosePopup();
    }

    componentDidMount() {
        this.mounted = true;
        this.setResponsiveness();
        window.addEventListener("resize", () => this.setResponsiveness());
    }

    componentWillUnmount() {
        this.mounted = false;
        window.removeEventListener('resize', () => this.setResponsiveness());
    }

    setResponsiveness = () => {
        if (this.mounted)
            return window.innerWidth < 900
            ? this.setState((prevState) => ({ ...prevState, isMobileApp: true }))
            : this.setState((prevState) => ({ ...prevState, isMobileApp: false }));
    };

    handleClosePopup = () => {
        this.setState({ currentSlide: 0 })
        this.props.closePopup();
    }

    handleSelectClicked = (event: any) => {
        sessionStorage.setItem('styleId', event.currentTarget.id);
        this.props.closePopup();

        const style = this.props.styleData.styles.find((s: any) => s.id == event.currentTarget.id);
        console.log("Found style", style, this.props.styleData);
        if(style){
            if(style.formats.length > 1){
                this.props.history.push('/choose-format');
            } else {
                let format = style.formats[0];
                this.handleFormatSelected(format.id, format.formatStylesId);
                this.props.setTargetStyle(style, () => {
                    this.props.openPopup();
                });                
                //select first format
            }
        } else { console.log("Cant find style with id", event.currentTarget.id); }
    }

    handleFormatSelected = (formatId: string, formatStylesId: string)  => {
        sessionStorage.setItem('formatId', formatId);
        sessionStorage.setItem('formatStylesId', formatStylesId);
    }

    render() {
        const { classes, session } = this.props;

        const settings = {            
            dots: true,
            infinite: false,
            speed: 700,
            slidesToShow: 1,
            slidesToScroll: 1,
            focusOnSelect: false,
            arrows: false,
            afterChange: ((current: number) => this.setState({ currentSlide: current })),
            dotsClass: this.state.isMobileApp ? "mobile_button__bar" : "popup_button__bar",
        };

        const { styleData: { styles, suitableForFilters } } = this.props;
        const style = styles.filter((style: any) => style.id.toString() === sessionStorage.getItem('styleId'))[0];

        ////debugger

        if (style === undefined) {
            return null
        }

        var { formats } = style;

        let suitableForFilterList: string[] = [];
        style.suitableForFilters.forEach((item: string) => {
            var filter = suitableForFilters.filter((suitable: any) => suitable.id === item)[0];
            suitableForFilterList.push(filter.description);
        });

        if (this.state.isMobileApp) {
            return (
                <Dialog
                    fullWidth
                    style={{ padding: '0px 0px 0px 0px' }}
                    onClose={this.handleClosePopup}
                    open={this.props.isPopupOpen}
                    PaperProps={{ style: { backgroundColor: "white" }, }}>

                    <div onClick={this.handleClosePopup} className={classes.closeMobile}>
                        <CloseRounded fontSize="small" />
                    </div>

                    <Grid container direction="column" alignItems="center" justify="center" spacing={0}>

                        <Grid item xs={12}>
                            {formats.length > 0 && <div style={{ paddingBottom: 10, paddingTop: 65 }}>
                                <div style={{ textAlign: "center", color: "rgba(90, 90, 90, 0.996078431372549)", paddingBottom: 5, fontSize: 14 }}>
                                    {formats[this.state.currentSlide].title}
                                </div>

                                <div style={{ width: 100, display: "block" }}>
                                    <Slider {...settings}>
                                        {formats.map((format: any) => (
                                            <div style={{ textAlign: "center" }} key={format.id}>
                                                <img src={`./assets/styles/preview/${format.imgUrl}`} className={classes.previewImageMobile} alt="preview" key={format.id} />
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                            </div>}
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                id={style.id}
                                variant="outlined"
                                size="small"
                                onClick={this.handleSelectClicked}
                                className={classes.selectButton}>
                                Select
                            </Button>

                            <div style={{ paddingTop: 10, fontSize: 12, whiteSpace: "pre-wrap" }}>
                                {style.description}
                            </div>
                            {style.options && <div style={{ paddingTop: 10, fontSize: 12 }}>
                                <strong>Options:</strong>
                            </div>}
                            <div style={{ fontSize: 12, whiteSpace: "pre-wrap" }}>
                                {style.options}
                            </div>
                            {suitableForFilterList && suitableForFilterList.length > 0 && <div style={{ paddingTop: 10, fontSize: 12 }}>
                                <strong>Suitable for:</strong>
                            </div>}
                            {suitableForFilterList.map((filter: string, index: any) => (
                                <div style={{ fontSize: 12 }} key={index}>
                                    {filter}
                                </div>
                            ))}
                        </Grid>
                    </Grid>
                </Dialog>
            );
        }        


        return (
            <Dialog                
                onClose={this.handleClosePopup}
                open={this.props.isPopupOpen}
                PaperProps={{ style: { backgroundColor: "white" }, }}>

                <div onClick={this.handleClosePopup} className={classes.close}>
                    <CloseRounded />
                </div>

                <Grid container direction="row" spacing={2}>

                    <Grid item xs={5}>
                        {formats.length > 0 && <div style={{ paddingBottom: 10, paddingTop: 65 }}>
                            <div style={{ textAlign: "center", color: "rgba(90,90,90, 0.996078431372549)", paddingBottom: 15 }}>
                                {formats[this.state.currentSlide].title}
                            </div>

                            <div style={{ maxWidth: 300, display: "block" }}>
                                <Slider {...settings}>
                                    {formats.map((format: any) => (
                                        <div style={{ textAlign: "center" }} key={format.id}>
                                            <img src={`./assets/styles/preview/${format.imgUrl}`} className={classes.previewImage} alt="preview" key={format.id} />
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>}
                    </Grid>

                    <Grid item xs={7}>
                        <Button
                            id={style.id}
                            variant="outlined"
                            onClick={this.handleSelectClicked}
                            className={classes.selectButton}>
                            Select
                        </Button>

                        <Typography style={{ paddingTop: 20, whiteSpace: "pre-wrap" }}>
                            {style.description}
                        </Typography>
                        {style.options &&  <Typography className={classes.section}>
                            <strong>Options:</strong>
                        </Typography>}
                       
                            <Typography style={{whiteSpace: "pre-wrap"}} >
                                {style.options}
                            </Typography>
           
                        {suitableForFilterList && suitableForFilterList.length > 0 && <Typography className={classes.section}>
                            <strong>Suitable for:</strong>
                        </Typography>}
                        {suitableForFilterList.map((filter: string, index: any) => (
                            <Typography key={index}>
                                {filter}
                            </Typography>
                        ))}
                    </Grid>

                </Grid>
            </Dialog>
        );
    }
}

export default withStyles(styles)(withRouter(StyleInfoPopup));
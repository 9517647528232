import { Grid, TextField, Typography, WithStyles } from "@material-ui/core";
import React from "react";
import Editable from "../decorators/Editable";
import StyleInjector from "../decorators/StyleInjector";
import ICustomTheme from "../../interfaces/ICustomTheme";

interface ISocialTagProps {
    id: string,
    hashTag: string,
    theme: ICustomTheme    
}

const styles = ({
    "hashtag":{
        "display":"flex",
        "color":"#FFF",
        "marginLeft":165,  
        "width":170,
        "marginBottom":22,
        "& h4":{
            "fontFamily":"\"Brandon Grotesque Regular\"",
            "fontWeight":700,
            "fontSize": 10,
            "lineHeight": "12px",
            "letterSpacing": "normal",     
        }
    },
    "logo":{
        "height":12,
        "marginRight":5,
        "marginLeft":0
    },
});

class SocialTag extends React.Component<WithStyles & ISocialTagProps> {

    render() {
        const { id, hashTag, classes, theme } = this.props;

        const data = {
            id,
            name: "Social Tag",
            initialValues: {
                hashTag, 
                theme
            },
            fields: [
                {
                    label: "Hash Tag",
                    required: true,
                    render: (onChange: any, dataMapping: any) => {
                        return <TextField multiline fullWidth rows={5} name="hashTag" value={dataMapping["hashTag"]} onChange={onChange} InputProps={{ disableUnderline: true }} variant="filled" />
                    }
                }
            ]
        }

        return <Editable data={data}>
            <Grid container direction="column" >
                <Grid item>
                    <div className={classes.hashtag}>  
                        <img src={theme.socialTag?.logo} className={classes.logo}/>
                        <Typography variant="h4">{hashTag}</Typography>
                    </div>  
                </Grid>
            </Grid>
        </Editable>
    }
}

export default StyleInjector(styles, "socialTag", true)(SocialTag);
import React, { useEffect, useRef, useState } from "react";
import Filters, { FilterOption, FilterPropertiesState } from "../../components/POS/Filters";
import ProductItem from "../../components/POS/products/ProductItem";
import LoadingSpinner from "../../components/POS/LoadingSpinner";
import { Filter } from "@material-ui/icons";

const ProductPage: React.FC = () => {

    const [styles, setStyles] = useState<ProductDTO[]>([]);

    const [filterState, setFilterState] = useState<FilterPropertiesState>({ filterSelection: 'none', searchBar: '', limit: 20});

    const [numberOfColumns, setNumberOfColumns] = useState<number>(1);

    const containerRef = useRef<HTMLDivElement | null>(null);

    const [maxWidth, setMaxWidth] = useState<number>(0);

    const [filteringOptions, setFilteringOptions] = useState<FilterOption[]>([]);


    async function onValueChangedCallback(data: ProductDTO, callback: () => void) {


        const requestResponse = await fetch("../../api/styles",

            {
                method: "PUT",
                body: JSON.stringify(data), // !value also turns null to true so covers null and boolean cases
                headers: {
                    'Content-Type': 'application/json'
                }
            }

        );

        const jsonResponse = await requestResponse.json();

        const indexOfEditedStyle = styles.findIndex(style => style.id === jsonResponse.id);

        if (indexOfEditedStyle === -1) {
            throw "Not style with that ID";
        }

        const styleCopy = [...styles];

        styleCopy[indexOfEditedStyle] = jsonResponse;

        console.log(styleCopy);

        setStyles(styleCopy);

        callback();
    }

    const updateColumns = () => {
        if (containerRef.current !== null) {
            setNumberOfColumns(Math.floor((containerRef.current.offsetWidth - 50) / 250));
            setMaxWidth(containerRef.current.offsetWidth);

        }
    }

    useEffect(() => {
        fetch("../../api/styles/getAllStyles")
            .then(async request => {

                const jsonResponse: ProductDTO[] = await request.json();

                setStyles(jsonResponse);

                updateColumns();

                window.addEventListener("resize", updateColumns);

                updateFilterOptions();
                
            }

            
        )
        

    }, []);


    useEffect(() => {
        updateFilterOptions();
    }, [styles])

    const updateFilterOptions = async () => {

        const response = await fetch('../../api/styles/gettaggroups')

        const tagGroupjsonResponse = await response.json() as TagGroup[];

        const filteredTagGroups = tagGroupjsonResponse.filter(group => group.tags.length > 0);

        const usedTags = styles.flatMap(style => style.tags.map(tag => tag.id));
        console.log(usedTags);

        const filterOptions: FilterOption[] = filteredTagGroups.flatMap(tagGroup =>
            [
                {
                    value: 't' + tagGroup.id,
                    text: tagGroup.title,
                    isTitle: true,

                },

                ...tagGroup.tags.map(tag => {
                    return {
                        value: tag.id,
                        text: tag.title,
                        isDisabled: !usedTags.includes(tag.id)
                    }
                })
            ]
        )

        filterOptions.unshift({
            value: "none",
            text: "All",
        });

        setFilteringOptions(filterOptions);
        console.log(filterOptions);
    }

    const filteredItems = styles
        .filter(style => style.displayOrder >= 0)
        .filter(style => style.tags.map(tag => tag.id.toString()).includes(filterState.filterSelection) || filterState.filterSelection === "none") // Check filter selection
        .filter(style => style.title.toLowerCase().includes(filterState.searchBar.toLowerCase())) // Check for names via search bar

    const mx = ((maxWidth ?? 0) - 5 - (numberOfColumns * 250)) / (numberOfColumns * 2)


    if (styles.length === 0) {
        return <LoadingSpinner/>
    }

    return (
        <div>

            <Filters
                filterValue={filterState.filterSelection}
                searchValue={filterState.searchBar}
                limitValue={filterState.limit}

                filterChange={(e) => { setFilterState({ ...filterState, filterSelection: e.target.value }) }}
                searchChange={(e) => { setFilterState({ ...filterState, searchBar: e.target.value }) }}
                limitChange={(e) => { setFilterState({ ...filterState, limit: parseInt(e.target.value) }) }}

                searchPlaceholder="Search for product name"

                filterOptions={filteringOptions}

                title="Products"



            />

            <hr style={{ border: "none", borderTop: "1px #797979 solid", marginTop: 16 }} />
            <div style={{ display: 'flex', justifyContent: 'center' }} ref={containerRef}>
                <div style={{ display: 'flex', flexWrap: "wrap", width: "100%" }}>

                    {filteredItems.map((style, index) =>
                        <ProductItem
                            onValueChanged={onValueChangedCallback}
                            data={style}
                            key={style.id}
                            mx={Math.floor(mx)}
                            isLast={index === filteredItems.length - 1}
                        />
                    )}

                    {(filteredItems.length === 0 && styles.length !== 0) && <><br />No results matched those search parameters<br /><br /></>}


                </div>

            </div>
            
            
            <br/>
        </div>
    );
}

interface Format {
    id: number,
    title: string,
    imgUrl: string
}


export interface Tag {
    id: string;
    title: string;
}

interface TagGroup {
    id: number;
    title: string;
    tags: Tag[]
}

export interface CustomLabel {
    title: string;
    colorHex: string;
}

export interface StyleOption {
    styleOptionId: number;
    description: string;
    displayOrder: number;
}


export interface ProductDTO {
    id: number;
    title: string;
    tagLine: string;
    imgUrl: string;
    description: string;
    featuredImgUrl: string;
    isNew: boolean;
    isPopular: boolean;
    isFeatured: boolean;
    isEnabled: boolean;
    displayOrder: number;
    tags: Tag[];
    customLabel: CustomLabel;
    menuTypeFilters: string[];
    suitableForFilters: string[];
    styleOptions: StyleOption[];
    formats: Format[];
    styleBritvicProducts?: any;
    colourFilters: string[];
    seasonFilters: string[];
}






export default ProductPage;
import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, InputLabel, TextField, Button } from "@material-ui/core";
import PriceField from "../form/PriceField";
import DialogClose from "./DialogClose";

interface IAddProduct {
    newProduct: any,
    show: boolean,
    onClose: any,
    onSave: any,
    addingSubProduct: number
}

export default function AddProduct(props: IAddProduct) {
    const { newProduct, show, onSave, onClose, addingSubProduct } = props;

    const [disabled, setDisabled] = useState(true);
    const [name, setName] = useState("");
    const [desc, setDesc] = useState("");
    const [price, setPrice] = useState("");

    const valid = () => {
        setDisabled(name !== "" || desc !== "" || price !== "");
    }

    return <Dialog onClose={onClose} fullWidth open={show}>
        <DialogClose onClose={onClose} />
        <DialogTitle>Add a new product</DialogTitle>

        <DialogContent>
            <Grid container>
                <Grid item xs={12}>
                    <InputLabel>Product name *</InputLabel>
                    <TextField error={name ? true : undefined} helperText={name} name="name" fullWidth variant="filled" InputProps={{ disableUnderline: true }}
                        onChange={(ev: any) => {
                            newProduct.name = ev.target.value;
                            setName(ev.target.value === "" ? "A product name is required" : "");
                            valid();
                        }} />
                </Grid>

                {addingSubProduct < 0 && <React.Fragment>
                    <Grid item xs={12}>
                        <InputLabel>Product description *</InputLabel>
                        <TextField error={desc ? true : undefined} helperText={desc} name="desc" rows={5} multiline fullWidth variant="filled" InputProps={{ disableUnderline: true }} onChange={(ev: any) => {
                            newProduct.desc = ev.target.value;
                            setDesc(ev.target.value === "" ? "A product description is required" : "");
                            valid();
                        }} />
                    </Grid>

                    <Grid item xs={12}>
                        <InputLabel>Price *</InputLabel>
                        <PriceField error={price ? true : undefined} helperText={price} name="price" type="number" fullWidth onChange={(ev: any) => {
                            newProduct.price = parseFloat(ev.target.value);
                            setPrice(ev.target.value === "" ? "A product price is required" : "");
                            valid();
                        }} />
                    </Grid>
                </React.Fragment>}
            </Grid>
        </DialogContent>

        <DialogActions>
            <Button variant="outlined" size="large" onClick={onClose}>Cancel</Button>
            <Button disabled={disabled} variant="contained" size="large" onClick={onSave}>Save</Button>
        </DialogActions>
    </Dialog>
}
import React from "react";
import { FormControlLabel, Grid, Radio, RadioGroup, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography, WithStyles, withTheme } from "@material-ui/core";
import Editable from "../decorators/Editable";
import MenuImage from "./shared/Image";

import StyleInjector from "../decorators/StyleInjector";
import EditableProducts from "../layout/EditableProducts";
import IProduct from "../../interfaces/IProduct";
import { connect } from "react-redux";
import { menuOperations } from "../../store/menu";
import ICustomTheme from "../../interfaces/ICustomTheme";
import { formatPrice } from "../../utils/common";

interface ITextListProps {
    id: string,
    title: string,
    productsSubtext?: string,
    products?: IProduct[],
    hasBackground?: boolean,
    uploadFile: any,
    theme: ICustomTheme,
    titleImage?: boolean
    hasBorder?: boolean,
    centered?: boolean,
    hasLineDividers?: boolean,
    hasBottomBorder?: boolean,
    backgroundImage?: string,
    hasTitleUnderline?: boolean,
    hideChangeBackground?: boolean,
    hideTitle: boolean,
    height?: number,
    ginFilter?: string,
    productLimit?: number,
    defaultHighlighted?: boolean
}

const styles = ({
    productList: {
        paddingLeft: 5,
        paddingRight: 5,
        "& p": {
            whiteSpace: "pre-line" as "pre-line"
        }
    },
    price: {
        fontSize: 18,
        fontWeight: "bold" as "bold"
    },
    column: {
        fontWeight: "bold" as "bold",
        fontSize: 14,
        whiteSpace: "nowrap" as "nowrap",
        "& + th": {
            borderLeft: "1px solid #000"
        }
    },
    columnPrice: {
        fontWeight: "bold" as "bold",
        fontSize: 12
    }
});

class TextList extends React.Component<ITextListProps & WithStyles> {
    static getCSVHeaders(): string {
        return "\"Id\",\"Title\"";
    }

    static renderToCSV(data: ITextListProps): string {
        var title = `"${data.id}","${data.title ?? ""}"\n\n`;

        //Now this is funky, start a sub table of products and offset it by one col so it looks nicer
        const productHeader = "\"\",\"Name\",\"Description\",\"Price\"\n";

        return title + productHeader + data.products?.reduce((prev: string, curr: IProduct) => {
            return prev + `"","${curr.name}","${curr.desc.replaceAll("\"", "\"\"") ?? ""}","${curr.price ?? 0}"\n`;
        }, "") + "\n";
    }

    static setFromCSV(data: ITextListProps, csv: string[], index: number): boolean {
        if (!data.products)
            return false;

        var product = data.products[index];

        if (!product)
            return false;

        product.name = csv[1];
        product.desc = csv[2];
        product.price = parseFloat(csv[3]);
        return true;
    }

    render() {
        const { id, title, productsSubtext, products, classes, hasBackground, uploadFile, theme, hasBorder, centered,
            titleImage, hasLineDividers, hasBottomBorder, backgroundImage, hasTitleUnderline, hideChangeBackground, hideTitle, height, productLimit, defaultHighlighted, ginFilter } = this.props;


        const data = {
            name: "TextList",
            id,
            editPanelTitle: "Choose Cocktails",
            initialValues: {
                title,
                products,
                hasBackground,
                titleImage,
                hasBorder,
                centered,
                hasLineDividers,
                hasBottomBorder, 
                backgroundImage,
                hasTitleUnderline,
                hideChangeBackground,
                height
            },
            state: {
                opened: [0],
                showAddProduct: false,
                showAddBritvicProduct: false,
                showAddGinProduct: false,
                newProduct: {} as IProduct,
                formData: undefined
            },
            fields: [
                !hideTitle && {
                    label: "Title text",
                    render: (onChange: any, dataMapping: any) => {
                        return <TextField name="title" value={dataMapping["title"]} onChange={onChange} InputProps={{ disableUnderline: true }} variant="filled" />
                    }
                }, !theme.textList?.isTransparent && {
                    label: "Appearance",
                    render: (onChange: any, dataMapping: any) => (
                        <React.Fragment>
                            {!theme.textList?.hideChangeBackground && <RadioGroup name="hasBackground" onChange={onChange} value={dataMapping["hasBackground"]?.toString() ?? "false"} defaultValue="false" aria-label="background colour">
                                <Grid container style={{ marginBottom: 15 }}>
                                    <Grid item>
                                        <FormControlLabel value="false" label="No background colour" control={<Radio />} labelPlacement="end" />
                                    </Grid>

                                    <Grid item>
                                        <FormControlLabel value="true" label="Apply background colour" control={<Radio />} labelPlacement="end" />
                                    </Grid>
                                </Grid>
                            </RadioGroup>}

                            {theme.textList?.centered && <RadioGroup name="centered" onChange={onChange} value={dataMapping["centered"]?.toString() ?? "false"} defaultValue="false" aria-label="border">
                                <Grid container style={{ marginBottom: 15 }}>
                                    <Grid item>
                                        <FormControlLabel value="false" label="Left aligned" control={<Radio />} labelPlacement="end" />
                                    </Grid>

                                    <Grid item>
                                        <FormControlLabel value="true" label="Centered" control={<Radio />} labelPlacement="end" />
                                    </Grid>
                                </Grid>
                            </RadioGroup>}

                            {theme.textList?.border && <RadioGroup name="hasBorder" onChange={onChange} value={dataMapping["hasBorder"]?.toString() ?? "false"} defaultValue="false" aria-label="border">
                                <Grid container style={{ marginBottom: 15 }}>
                                    <Grid item>
                                        <FormControlLabel value="false" label="No border" control={<Radio />} labelPlacement="end" />
                                    </Grid>

                                    <Grid item>
                                        <FormControlLabel value="true" label="Apply border" control={<Radio />} labelPlacement="end" />
                                    </Grid>
                                </Grid>
                            </RadioGroup>}

                            {theme.textList?.hasLineDividers && <RadioGroup name="hasLineDividers" onChange={onChange} value={dataMapping["hasLineDividers"]?.toString() ?? "false"} defaultValue="false" aria-label="border">
                                <Grid container style={{ marginBottom: 15 }}>
                                    <Grid item>
                                        <FormControlLabel value="false" label="No dividers" control={<Radio />} labelPlacement="end" />
                                    </Grid>

                                    <Grid item>
                                        <FormControlLabel value="true" label="Apply dividers" control={<Radio />} labelPlacement="end" />
                                    </Grid>
                                </Grid>
                            </RadioGroup>}

                            {theme.textList?.hasBottomBorder && <RadioGroup name="hasBottomBorder" onChange={onChange} value={dataMapping["hasBottomBorder"]?.toString() ?? "false"} defaultValue="false" aria-label="border">
                                <Grid container style={{ marginBottom: 15 }}>
                                    <Grid item>
                                        <FormControlLabel value="false" label="No bottom border" control={<Radio />} labelPlacement="end" />
                                    </Grid>

                                    <Grid item>
                                        <FormControlLabel value="true" label="Apply bottom border" control={<Radio />} labelPlacement="end" />
                                    </Grid>
                                </Grid>
                            </RadioGroup>}
                        </React.Fragment>
                    )
                }, {
                    render: (onChange: any, dataMapping: any, state: { onSetState: any, editableState: any }) => {
                        return <EditableProducts ginFilter={ginFilter} defaultHighlighted={defaultHighlighted} productsSubtext={productsSubtext} showGinProducts={theme.textList?.showGinProducts} productLimit={productLimit?productLimit:theme.textList?.showGinProducts ? 3 : undefined} hideFeatured={theme.textList?.disabledFeatured} featuredAnywhere={theme.textList?.featuredAnywhere} onChange={onChange} dataMapping={dataMapping} state={state} />
                    }
                }
            ],
            onSaved: (dataMapping: any, state: any) => {
                return new Promise<void>((resolve: any, reject: any) => {
                    if (!state.editableState.formData) {
                        resolve();
                        return;
                    }

                    //On save upload the file to the server
                    uploadFile(state.editableState.formData).then((file: any) => {
                        let url = window.location.origin + "/api/Files/" + file.fileId;

                        //Add image to the last item
                        let products = dataMapping["products"];
                        products[products.length - 1].image = url;

                        resolve();
                    }).catch(() => {
                        console.error("File upload error");
                        reject();
                    });

                    //Reset form data
                    state.onSetState({
                        editableState: { ...state.editableState, formData: undefined }
                    });
                });
            }
        };

        return <Editable data={data}>
            <div style={{ position: "relative", backgroundImage: hasBackground && backgroundImage ? backgroundImage : "",height: height?Number(height):"" }} className={`${classes.default} ${centered ? classes.centered : ""} ${hasBorder ? classes.border : ""} ${hasBackground ? classes.background : ""}`}>
                {title && <div className={titleImage ? classes.titleImage : ""}><Typography variant="h3">{title}</Typography></div>}
                {title && hasTitleUnderline && <div className={classes.underline}/>}
                <div>
                    {products && products?.map((item: IProduct, index) => {
                        //Merge placeholder for display purposes
                        item = {
                            ...item,
                            ...item.placeholder
                        };
                        let featuredProduct = item.highlighted && item.image && (index === products.length - 1 || theme.textList?.featuredAnywhere);
                        return <div key={index} className={`${classes.productList} ${featuredProduct ? "featured" : item.highlighted ? "highlighted" : ""}`}>
                            {(item?.showColumns || item?.columns && item?.columns?.length > 0) ?
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width="100%">
                                                <Typography variant="h4" style={{marginLeft: item?.columns?.length === 0 ? -10 : 0}}>{item.name}</Typography>
                                            </TableCell>

                                            {item?.columns?.map(col => (
                                                <TableCell key={col} className={classes.overrideColumn ? classes.overrideColumn : classes.column}>
                                                    {col}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {item?.subItems && item?.subItems?.map((sub: IProduct, index: number) => (
                                            <TableRow key={index}>
                                                <TableCell className={classes.subName}>
                                                    {sub.name}
                                                </TableCell>

                                                {Array.isArray(sub.price) && sub.price?.map((price, index) => (
                                                    <TableCell key={index} className={classes.overrideColumnPrice ? classes.overrideColumnPrice : classes.columnPrice}>
                                                        {theme.textList?.hideCurrencySymbol ? "" : "£"}{theme.textList?.hideTrailingZeros ? parseFloat(price.toFixed(2)) : price.toFixed(2)}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                :
                                <React.Fragment>
                                    {!(featuredProduct && theme.textList?.featuredHero) && <Grid container justify="space-between"
                                        className={hasBottomBorder && (index === products.length - 1) ? classes.bottomBorder : 
                                                    hasLineDividers && (index < products.length - 1) ? classes.lineDivider : ""}>
                                        <Grid item xs={theme.textList?.lastLinePrice ? 12 : true}>   
                                            <Typography className={classes.title} variant="h4">{item.name}{theme.textList?.priceInTitle ? <span>| {formatPrice((item?.price instanceof Array) ? 0 : item.price, theme.textList?.hideCurrencySymbol,theme.textList?.hideTrailingZeros )}</span> : ""}</Typography>{item.highlighted && theme.textList?.imageHighlight && <span className={classes.highlightedImage}/>}

                                            {(!theme.textList?.priceInTitle && theme.textList?.inlinePrice && item?.price !== undefined) && 
                                                <div className={classes.overridePrice ? classes.overridePrice : classes.price}>
                                                    {theme.textList?.hideCurrencySymbol ? "" : "£"}{theme.textList?.hideTrailingZeros ? parseFloat(!(item?.price instanceof Array) ? item?.price?.toFixed(2) : "0") : !(item?.price instanceof Array) ? item?.price?.toFixed(2) : "0"}
                                                </div>}

                                            {!featuredProduct && <p>{item.desc}</p>}
                                        </Grid>

                                        {(!theme.textList?.priceInTitle  && !theme.textList?.inlinePrice && item?.price !== undefined) && 
                                            <Grid item xs={theme.textList?.lastLinePrice ? 12 : "auto"} className={classes.overridePrice ? classes.overridePrice : classes.price}>
                                                £{!(item?.price instanceof Array) ? item?.price?.toFixed(2) : "0"}
                                            </Grid>
                                        }
                                    </Grid>}

                                    <Grid container>
                                        {featuredProduct && <Grid item xs>
                                            {theme.textList?.featuredHero && <Typography variant="h4">{item.name}</Typography>}

                                            <p>{item.desc}</p>

                                            {(theme.textList?.featuredHero && item?.price !== undefined) && 
                                                <div className={classes.overridePrice ? classes.overridePrice : classes.price}>
                                                    £{!(item?.price instanceof Array) ? item?.price?.toFixed(2) : "0"}
                                                </div>
                                            }
                                        </Grid>}

                                        {featuredProduct && <Grid item xs="auto">
                                            <div className={classes.featured}>
                                                <MenuImage border={!theme.textList?.featuredHero} image={item.image} arrowText={item.highlightedText} />
                                            </div>
                                        </Grid>}
                                    </Grid>
                                </React.Fragment>
                            }
                        </div>
                    })}
                </div>
            </div>
        </Editable>
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        uploadFile: (file: any) => dispatch(menuOperations.uploadFile(file))
    }
}

export default connect(null, mapDispatchToProps)(StyleInjector(styles, "textList", true)(TextList));
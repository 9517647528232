import { CircularProgress, makeStyles } from "@material-ui/core";
import React, { CSSProperties, useEffect, useMemo, useRef, useState } from "react";
import { IBritvicProduct } from "../../interfaces/IBritvicProduct";
import { getProductCategories, getProductsByTagName, getProductsFilter } from "../../store/menu/operations";
import { getScaledImage } from "../../utils/common";
import { ModalRenderState } from "../layout/EditableModal";
import { FloatingProductPanelConfig, FloatingProductPanelDataMapping } from "../menu/FloatingProductPanel";
import CategorySelector from "./CategorySelector";
import { useSelector } from "react-redux";
import { AppState } from "../../store";

interface IProductPicker{
    onChange: any,
    dataMapping: FloatingProductPanelDataMapping,
    state: ModalRenderState
    productTag?: string
    config: FloatingProductPanelConfig | null
}

const getProductImage = (product: string) => `/assets/products/${product}`;

const useStyles = makeStyles({
    productPanelContainer: {

    },
    selectedProductsContainer: {
        display: "flex",
        justifyContent: "space-between"
    },
    selectedProductContainer:{

    },
    selectedProductTitle:{
        textAlign: "center",
        margin: ".5rem 0",
        padding: "0 5px",
        textTransform: "uppercase",
        fontWeight: "bold"
    },
    selectedProductImageWrapper:{
        height: 150,
        width: 150
    },
    selectedProductImage:{
        height: "100%",
        width: "100%",
        objectFit: "contain"
    },
    targetedProduct:{
        border: "3px solid #007d7a"
    },
    nonTargetProduct: {
        border: "2px solid #00000040"
    },
    availableProductsWrapper: {
        height: 400,
        overflowY: "scroll",
        marginTop: "2rem"
    },
    availableProductsContainer: {
        padding: "1rem",
        display: "grid",
        gridTemplateColumns: "repeat(4, 1fr)",
        gap: ".5rem",
    },
    availableProductContainer: {
        border: "2px solid #00000040"
    },
    loadingProductsContainer: {
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
});

const ProductPicker: React.FC<IProductPicker> = ({ dataMapping, onChange, state, children, productTag, config, id}) => {

    const [availableProducts, setAvailableProducts] = useState<IBritvicProduct[]>();
    const [availableProductsCategories, setAvailableProductsCategories] = useState<string[]>([]);
    const { products } = dataMapping;
    const classes = useStyles();

    const [selectedProducts, setSelectedProducts ] = useState(products);
    const [targetedProductSlotIndex, setTargetedProductSlotIndex] = useState(0)
    const targetedProductSlot = config?.productSlots[targetedProductSlotIndex];
    const [loadingProducts, setLoadingProducts] = useState(false);

    const [loadingCategories, setLoadingCategories] = useState(true);
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

    const productsWrapperRef = useRef<HTMLDivElement>(null);
    const selectedProductRef = useRef<HTMLDivElement>(null);
    const menuState = useSelector((state: AppState) => state.menu);
    let canBeEmpty = false;

    const getProducts = (productTag: string, styleId: number) => {
        setLoadingProducts(true);
        getProductsFilter(productTag, styleId).then((_products) => {
            setAvailableProducts(_products);
        }).catch((err) => {
            console.log("Error fetching products in FloatingProductPanel", err);
        }).finally(() => {
            setLoadingProducts(false);
            if (selectedProductRef.current) {
                setTimeout(() => {
                    if (selectedProductRef.current) {
                        selectedProductRef.current.scrollIntoView();
                    }
                }, 10);
            }
        })
    }

    useEffect(() => {
        if(productTag && menuState?.menuData) {
            getProducts(productTag, menuState?.menuData?.styleId);
        }
    }, [productTag, menuState?.menuData])

    useEffect(() => {
        if(targetedProductSlot?.productType){
            //getProducts(targetedProductSlot.productType);
        }
    }, [targetedProductSlot?.productType])

    useEffect(() => {
        //Update dataMapping with new selected products
        state.onSetState({
            dataMapping: {
                products: selectedProducts
            }
        });
    }, [selectedProducts]);

    useEffect(() => {

        if (availableProducts !== undefined) {

            const presentProductCategories: string[] = [];
            availableProducts
                .map(product => product.category)
                .forEach(category => presentProductCategories.includes(category)
                    ? null : presentProductCategories.push(category));
            setAvailableProductsCategories(presentProductCategories);

        }
    }, [availableProducts])

    const onSelectProduct = (imageUrl: string | null) => {
        const _selectedProducts = JSON.parse(JSON.stringify(selectedProducts));
        _selectedProducts[targetedProductSlotIndex] = imageUrl;
        setSelectedProducts(_selectedProducts);
    }

    const RenderProductGridItem = (productImage: string | null, title: string,backgroundColour: string) => {
        const selected = productImage == selectedProducts[targetedProductSlotIndex];
        return(
            <div ref={selected ? selectedProductRef : undefined} className={``} onClick={() => { onSelectProduct(productImage) }}>
                <div className={`${classes.selectedProductImageWrapper} ${selected ? classes.targetedProduct : classes.availableProductContainer}`} style={{ backgroundColor: backgroundColour }}>
                    {
                        productImage != null &&
                        <img className={classes.selectedProductImage} src={getScaledImage(getProductImage(productImage), 200)} />
                    }
                </div>
                <div className={classes.selectedProductTitle}>{title}</div>
            </div>
        )
    }
    if(menuState?.menuData?.styleId === 43 || menuState?.menuData?.styleId === 44){
        if(id === "floatingPanelDrinksCenter6"){
            canBeEmpty = true;
        }else if(id === "floatingPanelDrinksCenter5"){
            if(menuState?.menu && menuState?.menu.length > 0){
                let items = menuState?.menu[0]?.props?.items;
                let floatingProductPanelItems = items.filter((row: {component: string}) => row?.component === "FloatingProductPanel");
                floatingProductPanelItems.forEach((element: {props: {products: string[]}}, index: number) => {
                    if(element?.props?.id === "floatingPanelDrinksCenter6"){
                        if(element?.props?.products && element?.props?.products.length > 0){
                            if(!element?.props?.products[0]){
                                canBeEmpty = true;
                            }
                        }

                    }
                });
            }
        }else if(id === "floatingPanelDrinksCenter4"){
            if(menuState?.menu && menuState?.menu.length > 0){
                let items = menuState?.menu[0]?.props?.items;
                let floatingProductPanelItems = items.filter((row: {component: string}) => row?.component === "FloatingProductPanel");
                floatingProductPanelItems.forEach((element: {props: {products: string[]}}, index: number) => {
                    if(element?.props?.id === "floatingPanelDrinksCenter5"){
                        if(element?.props?.products && element?.props?.products.length > 0){
                            if(!element?.props?.products[0]){
                                canBeEmpty = true;
                            }
                        }

                    }
                });
            }
        }else if(id === "floatingPanelDrinksCenter3"){
            if(menuState?.menu && menuState?.menu.length > 0){
                let items = menuState?.menu[0]?.props?.items;
                let floatingProductPanelItems = items.filter((row: {component: string}) => row?.component === "FloatingProductPanel");
                floatingProductPanelItems.forEach((element: {props: {products: string[]}}, index: number) => {
                    if(element?.props?.id === "floatingPanelDrinksCenter4"){
                        if(element?.props?.products && element?.props?.products.length > 0){
                            if(!element?.props?.products[0]){
                                canBeEmpty = true;
                            }
                        }

                    }
                });
            }
        }else if(id === "floatingPanelDrinksCenter2"){
            if(menuState?.menu && menuState?.menu.length > 0){
                let items = menuState?.menu[0]?.props?.items;
                let floatingProductPanelItems = items.filter((row: {component: string}) => row?.component === "FloatingProductPanel");
                floatingProductPanelItems.forEach((element: {props: {products: string[]}}, index: number) => {
                    if(element?.props?.id === "floatingPanelDrinksCenter3"){
                        if(element?.props?.products && element?.props?.products.length > 0){
                            if(!element?.props?.products[0]){
                                canBeEmpty = true;
                            }
                        }

                    }
                });
            }
        }
    }else{
        canBeEmpty = true;
    }
    return(
        <div className={classes.productPanelContainer}>
            {
                availableProducts && availableProductsCategories.length > 1
                && <CategorySelector
                    categories={availableProductsCategories}
                    selectedCategories={selectedCategories}
                    onSelectionChange={(category, selected) => {
                        if (selected) {
                            setSelectedCategories([...selectedCategories, category])
                        } else {
                            const newCategories = [...selectedCategories]
                            newCategories.splice(selectedCategories.indexOf(category), 1)
                            setSelectedCategories(newCategories);
                        }
                    } }
                />
            }

            {
                (config && config.productSlots.length > 1) &&
                <div className={classes.selectedProductsContainer}>
                    {
                            config.productSlots.map((slot, i) => {
                            const product = selectedProducts[i];
                            return(
                                <div onClick={() => { setTargetedProductSlotIndex(i); }} className={`${classes.selectedProductContainer} `}>   
                                    <div className={classes.selectedProductTitle}>{slot.title}</div>
                                    <div className={`${classes.selectedProductImageWrapper} ${targetedProductSlotIndex === i ? classes.targetedProduct : classes.nonTargetProduct}`}>
                                    {
                                        product != null &&
                                        <img src={getScaledImage(getProductImage(product), 200)} className={classes.selectedProductImage} />
                                    }
                                    </div>
                                </div>  
                            )
                        })
                    }
                </div>
            }
            <div className={classes.availableProductsWrapper} ref={productsWrapperRef}>
                {availableProducts && availableProductsCategories ?
                    <div className={classes.availableProductsContainer}>
                        {
                            canBeEmpty ?
                            RenderProductGridItem(null, "Leave Empty","#eeeeee")
                            : <></>
                        }
                        {
                            availableProducts.map((product) => {
                                if (!selectedCategories.includes(product.category) && selectedCategories.length > 0)
                                    return null;

                                return (
                                    RenderProductGridItem(product.imageUrl, product.name, product.backgroundColour)
                                )
                            })
                        }
                    </div>
                :
                    <div className={classes.loadingProductsContainer}>
                            {
                                loadingProducts || loadingCategories
                        ?
                            <CircularProgress />
                        :
                            availableProducts == undefined &&
                            <div>No available products to load.</div>
                    }
                    </div>
            }
            </div>
        </div>
    )
}

export default ProductPicker;
import { MenuItem, Paper, Grid, MenuList, Theme, WithStyles, withStyles, Divider, Typography, Radio, Button, RadioGroup, CircularProgress } from "@material-ui/core";
import React, { Component } from "react";
import { AddCircleOutlined, CancelRounded, Publish, GetApp, HelpRounded, MenuBookRounded } from "@material-ui/icons";
import { IMenuState, IMenuStyle, ISelectedStyle, menuActions } from "../../store/menu";
import { connect } from "react-redux";

//Icons
import Design from "../../assets/sidebar/design.svg";
//import Style from "../../assets/sidebar/style.svg";
import ExportPDF from "../../assets/sidebar/pdf_export.svg";
import ExportJPG from "../../assets/sidebar/jpg_export.png";
import Order from "../../assets/sidebar/order.svg";
import Data from "../../assets/sidebar/data.svg";
import useGeneratePdf from "../../utils/useGeneratePdf";
import { iconColorCorrectFilter } from "../../pages/MenuBuilder";
import { myMenuOperations } from "../../store/myMenu";
//import CalculatorIcon from "../../assets/sidebar/calculator.svg";

const CalculatorIcon = "/assets/calculator.png";

const styles = (theme: Theme) => ({
    menuPaper: {
        background: "#F8EFED",
        position: "absolute" as "absolute",
        height: "100%",
        top: 0,
        width: 453,
        transform: "translateX(-100%)",
        transition: "transform 0.3s ease",
    },
    menuButtons: {
        width: "100%"
    },
    tallerRadio: {
        height: "90px !important"
    },
    menuPaperOpen: {
        transform: "translateX(0)",
        transition: "transform 0.3s ease 0.35s",
    },
    menuItem: {
        flexDirection: "column" as "column",
        whiteSpace: "normal" as "normal",
        margin: "0 20px",
        "&:hover": {
            background: "transparent"
        },
        "& img": {
            marginBottom: 5
        },
        "& svg": {
            height: "3rem",
            width: "3rem",
            marginBottom: 5
        },
        "& span": {
            textAlign: "center",
            lineHeight: "21px",
            color: "#333",
            fontWeight: 700
        },
        "& .MuiCircularProgress-root": {
            marginBottom: 10,
            "& svg": {
                width: "auto",
                height: "auto"
            }
        }
    },
    exit: {
        position: "absolute" as "absolute",
        fontSize: "3.4rem",
        right: -22.5,
        top: -22.5,
        zIndex: 1,
        color: "#007D7A",
        opacity: 0,
        transition: "opacity 0.2s ease",
        cursor: "pointer"
    },
    exitOpen: {
        opacity: 1,
        transition: "opacity 0.2s ease 0.5s"
    },
    title: {
        paddingTop: 20,
        marginBottom: 20,
        fontWeight: "bold" as "bold",
        fontSize: 18,
        verticalAlign: "middle",
        "& hr": {
            marginTop: 20
        },
        "& svg, & img": {
            verticalAlign: "middle" as "middle",
            marginLeft: 50,
            fontSize: 40,
            marginRight: 20
        }
    },
    content: {
        paddingLeft: 50,
        paddingRight: 20,
        "& strong": {
            fontSize: 20
        },
        "& label": {
            cursor: "pointer"
        }
    },
    sectionLabel: {
        paddingLeft: 10,
        paddingRight: 20,
        fontSize: 20
    },
    sidebar: {
        position: "relative" as "relative",
        background: "#E8EBED",
        height: "100%",
        overflow: "hidden"
    },
    sidebarWrapper: {
        position: "absolute" as "absolute",
        zIndex: 100,
        top: 0,
        left: 0,
        height: "100%",
        transition: "width 0.3s ease 0s",
        width: 170,
        [theme.breakpoints.down("sm")]: {
            width: 110
        }
    },
    sidebarWrapperOpen: {
        transition: "width 0.3s ease 0.3s",
        width: 453
    },
    section: {
        padding: 0,
        marginLeft: -10,
        "& li": {
            padding: 10,
            width: "100%",
            "& span": {
                fontSize: 14
            },
            "& strong": {
                verticalAlign: "middle"
            },
            "& .MuiRadio-root": {
                marginLeft: -9
            },
            "& p": {
                whiteSpace: "normal"
            },
            "& img": {
                width: "100%"
            }
        }
    },
    cancel: {
        backgroundColor: "#FFF",
        color: "#007D7A",
        border: "1px solid #007D7A",
        fontWeight: "bold" as "bold",
        "&:hover": {
            backgroundColor: "#EEE"
        }
    },
    buttons: {
        marginTop: 50,
        marginBottom: 20,
        paddingRight: 30,
        "& button": {
            margin: "0 10px",
            width: 150,
            padding: "10px 10px"
        }
    },
    divider: {
        margin: "15px 30px"
    },
    upload: {
        backgroundColor: "#007D7A",
        color: "#FFF",
        border: "1px solid #007D7A",
        fontWeight: "bold" as "bold",
        marginBottom: "20px",
        marginTop: "5px"
    },
    box: {
        color: "rgba(184, 142, 133, 0.996078431372549)",
        border: "1px solid rgba(184, 142, 133, 0.996078431372549)",
        marginBottom: "20px",
        "& p": {
            marginLeft: "15px",
            marginRight: "15px"
        }
    },
    radioContainer: {
        height: "40px",
        display: "flex",
        alignItems: "center",
        color: "#696969",
        marginBottom: 5,
        "& span": {
            fontSize: 26,
            lineHeight: "26px",
            color: "#696969"
        },
        "& span + span": {
            fontSize: 20,
            lineHeight: "20px",
            color: "#696969"
        }
    },
    palette: {
        height: 30,
        width: 30,
        border: "1px solid black"
    },
    textBlock: {
        padding: 20,
        paddingTop: 50,
        display: "block",
        fontFamily: "Helvetica",
        lineHeight: 1.2
    }
});

interface ISidebarProps {
    addSection: any;
    openPopup: any;
    altStyles?: IMenuStyle[];
    changeStyle: any;
    menu: IMenuState;
    exportPDF: any;
    isExporting: boolean;
    generateCSV: any;
    uploadCSV: any;
    importing: boolean;
    onOpenCalculator: () => void,
    isMealDeal: boolean,
    saveMenu: (id: number) => void
}

interface ISidebarState {
    tab?: string;
    type?: string;
    asset: number;
    palette: number;
    fontGroup: number;
}

export const ExportPDFButton: React.FC<{ className: string, isSidebar?: boolean, isMobileApp?: boolean }> = ({ className, isSidebar, isMobileApp }) => {

    const { exportTemplate, isExporting } = useGeneratePdf();

    const onClickDownload = () => {
        //TODO:POS1
        exportTemplate();
    }

    return (
        isSidebar
            ?
            <MenuItem className={className} onClick={onClickDownload}>
                {isExporting ? <CircularProgress /> : <img alt="export pdf" src={ExportPDF} height={55} />}
                <span>Export pdf</span>
            </MenuItem>
            :
            <Button className={className} size={isMobileApp ? "small" : "large"} onClick={onClickDownload} startIcon={
                isExporting
                    ? <CircularProgress style={{ height: isMobileApp ? 18 : 35 }} />
                    : <img style={iconColorCorrectFilter} height={isMobileApp ? 18 : 35} src={ExportPDF} />}
                disabled={isExporting}>

                Export<br /> PDF
            </Button>
    )
}

export const ExportJPGButton: React.FC<{ className: string, isSidebar?: boolean, isMobileApp?: boolean }> = ({ className, isSidebar, isMobileApp }) => {

    const { exportTemplate, isExporting } = useGeneratePdf();

    const onClickDownload = () => {
        exportTemplate();
    }

    return (
        isSidebar
            ?
            <MenuItem className={className} onClick={onClickDownload}>
                {isExporting ? <CircularProgress /> : <img alt="export pdf" src={ExportJPG} height={55} />}
                <span>Export pdf</span>
            </MenuItem>
            :
            <Button className={className} size={isMobileApp ? "small" : "large"} onClick={onClickDownload} startIcon={
                isExporting
                    ? <CircularProgress style={{ height: isMobileApp ? 18 : 35 }} />
                    : <img style={iconColorCorrectFilter} height={isMobileApp ? 18 : 35} src={ExportJPG} />}
                disabled={isExporting}>

                Download<br /> Signage .jpg
            </Button>
    )
}

class Sidebar extends Component<WithStyles & ISidebarProps, ISidebarState> {
    private sectionTypes: any;
    private hasTitleAsset: boolean;

    constructor(props: WithStyles & ISidebarProps) {
        super(props);

        this.state = {
            tab: undefined,
            type: undefined,
            asset: props.menu.selectedStyle?.asset ?? 0,
            palette: props.menu.selectedStyle?.palette ?? 0,
            fontGroup: props.menu.selectedStyle?.fontGroup ?? 0
        };

        this.sectionTypes = props.menu.sectionTypes as { [index: string]: any };
        this.hasTitleAsset = false;
    }

    openMenu = (tab: string) => {
        this.setState({
            tab,
            asset: this.props.menu.selectedStyle?.asset ?? 0,
            palette: this.props.menu.selectedStyle?.palette ?? 0,
            fontGroup: this.props.menu.selectedStyle?.fontGroup ?? 0
        });
    }

    closeMenu = () => {
        this.setState({
            tab: undefined,
            type: undefined
        });
    }

    addToMenu = () => {
        let type = this.sectionTypes[this.state.type ?? ""];

        type.component = this.state.type;
        console.log("type name", this.state.type, "type object", type);
        this.props.addSection(type);
    }

    handleStyleChange = (ev: any) => {
        const state = this.state as any;
        state[ev.target.name] = parseInt(ev.target.value);
        this.setState(state);
        if (!this.hasTitleAsset) {
            if (ev.target.name === "palette") state["asset"] = parseInt(ev.target.value);
            if (ev.target.name === "asset") state["palette"] = parseInt(ev.target.value);
            this.setState(state);
        }
    }

    keepChanges = () => {
        this.props.changeStyle({
            asset: this.state.asset,
            palette: this.state.palette,
            fontGroup: this.state.fontGroup
        } as ISelectedStyle);

        if (this.props.menu.menuData?.id)
            this.props.saveMenu(this.props.menu.menuData?.id);
        else {
            console.log("Unable to save, missing menuData.id");
        }
        this.closeMenu();
    }

    render() {
        const { classes, openPopup, altStyles, exportPDF, isExporting, generateCSV, uploadCSV, importing, onOpenCalculator, isMealDeal } = this.props;
        const { tab, type } = this.state;

        let isSimplified = this.props?.menu?.menuData?.simplified ?? false;

        // hideAllElse will hide everything in the left hand menu other than the DecorativeStyle section.
        const hideAllElse = this.props?.menu?.menuData?.showStyleSelectorOnly ?? false;

        if (hideAllElse) {
            isSimplified = true;
        };

        const hasLayoutSection = Object.values(this.sectionTypes).find((obj: any) => { return obj.type === "container"; });

        return <div className={`${classes.sidebarWrapper} ${tab ? classes.sidebarWrapperOpen : ""}`}>

            <CancelRounded onClick={this.closeMenu} className={`${classes.exit} ${tab ? classes.exitOpen : ""}`} />

            <Grid item xs={"auto"} className={classes.sidebar}>
                <Paper square elevation={0} className={`${classes.menuPaper} ${classes.menuButtons} ${tab ? "" : classes.menuPaperOpen}`}>


                    {
                        this.props.isMealDeal ? <Typography className={classes.textBlock}>
                            Click on the EDIT buttons to select and change products, roundels and backgrounds.<br /><br />
                            Either use our handy calculator or manually enter your deal price.<br /><br />
                            Your design will be automatically formatted to create A3 and A4 posters, a three sided DL table talker, shelf barkers and shelf wobblers.<br /><br />
                            You can change the number of prints you need at the next stage when you have completed your design and clicked on 'Order Prints'.<br /><br />
                            You must complete the Terms & Conditions text at the bottom of the design.
                        </Typography>
                            : <MenuList>
                                {Object.values(this.sectionTypes).length > 0 && <div>
                                    <MenuItem className={classes.menuItem} onClick={() => this.openMenu("add")}>
                                        <AddCircleOutlined />
                                        <span>Add a section</span>
                                    </MenuItem>

                                    <Divider className={classes.divider} />
                                </div>}

                                {(!isSimplified || hideAllElse) && <MenuItem className={classes.menuItem} onClick={() => this.openMenu("design")}>
                                    <img alt="design icon" src={Design} height={50} />
                                    <span>Styles</span>
                                </MenuItem>}

                                {!isSimplified && <Divider className={classes.divider} />}

                                {!isSimplified && <MenuItem className={classes.menuItem} onClick={() => this.openMenu("data")}>
                                    <img alt="data icon" src={Data} height={50} />
                                    <span>Manage Data</span>
                                </MenuItem>}

                                {!isSimplified && <Divider className={classes.divider} />}


                                {!isSimplified && <Divider className={classes.divider} />}

                                {!isSimplified && <MenuItem className={classes.menuItem} onClick={openPopup}>
                                    <img alt="order icon" src={Order} height={40} />
                                    <span>Order<br />prints</span>
                                </MenuItem>}

                                <Divider className={classes.divider} />

                                {!isSimplified && <MenuItem className={classes.menuItem} onClick={() => this.openMenu("menuology")}>
                                    <MenuBookRounded />
                                    <span>Enhance Menu</span>
                                </MenuItem>}

                                {isMealDeal && <MenuItem className={classes.menuItem} onClick={onOpenCalculator}>
                                    <img src={CalculatorIcon} height={40} />
                                    <span>Calculator</span>
                                </MenuItem>}

                            </MenuList>
                    }
                </Paper>

                <Paper square elevation={0} className={`${classes.menuPaper} ${tab === "add" ? classes.menuPaperOpen : ""}`}>
                    <div className={classes.title}>
                        <AddCircleOutlined />
                        <span>Add a section</span>
                        <Divider />
                    </div>

                    <div className={classes.content}>
                        <strong>Add a new section to your menu</strong>
                        <p>Choose the type of section you'd like to add to your menu and select 'Add to menu'</p>

                        <MenuList className={classes.section}>
                            <RadioGroup onChange={(ev: any) => { console.log("Selected", ev.target.value); this.setState({ type: ev.target.value }) }}>

                                {hasLayoutSection && <><p className={classes.sectionLabel}>Layout Sections</p>
                                    <Divider style={{ marginLeft: 10 }} /></>}

                                {Object.keys(this.sectionTypes).map(key => {
                                    var section = this.sectionTypes[key];
                                    return section.type === "container" && <MenuItem key={section.name}>
                                        <label>
                                            <Grid container justify="space-between">
                                                <Grid item>
                                                    <Radio checked={key === type} value={key} size="small" /> <strong>{section.name}</strong> <span>{section.subText}</span>
                                                    <Typography component="p">{section.desc}</Typography>
                                                </Grid>

                                                <Grid item>
                                                    {section.image && <img alt="section icon" src={section.image} />}
                                                </Grid>
                                            </Grid>
                                        </label>
                                    </MenuItem>
                                })}

                                <p className={classes.sectionLabel}>Content Sections</p>
                                <Divider style={{ marginLeft: 10 }} />

                                {Object.keys(this.sectionTypes).map((key, i) => {
                                    var section = this.sectionTypes[key];
                                    return section.type === "widget" && <MenuItem key={`${section.name}-${i}`}>
                                        <label>
                                            <Grid container justify="space-between">
                                                <Grid item>
                                                    <Radio checked={key === type} value={key} size="small" /> <strong>{section.name}</strong> <span>{section.subText}</span>
                                                    <Typography component="p">{section.desc}</Typography>
                                                </Grid>

                                                <Grid item>
                                                    {section.image && <img alt="section icon" src={section.image} />}
                                                </Grid>
                                            </Grid>
                                        </label>
                                    </MenuItem>
                                })}
                            </RadioGroup>
                        </MenuList>
                    </div>

                    <Grid container justify="center" className={classes.buttons}>
                        <Grid item>
                            <Button onClick={this.closeMenu} className={classes.cancel} color="secondary" variant="contained">Cancel</Button>
                        </Grid>

                        <Grid item>
                            <Button disabled={type === undefined} color="secondary" variant="contained" onClick={this.addToMenu}>Add to menu</Button>
                        </Grid>
                    </Grid>
                </Paper>

                <Paper square elevation={0} className={`${classes.menuPaper} ${tab === "design" ? classes.menuPaperOpen : ""}`}>
                    <div className={classes.title}>
                        <img alt="design icon" height={50} src={Design} />
                        <span>Styles</span>
                        <Divider />
                    </div>

                    <div className={classes.content}>
                        <strong>Play with your look & feel</strong>

                        <p>Decorative style</p>
                        {/*<div style={{ fontSize: 12, marginTop: -12, paddingBottom: 8, zIndex: 2 }}>(This can be shown/hidden on individual components)</div>*/}

                        <RadioGroup name="asset" value={this.state.asset} style={{ paddingBottom: 20 }} onChange={this.handleStyleChange}>
                            {altStyles?.map(style => {
                                //Old way
                                var keys = Object.keys(style.assets);
                                var asset = style.assets["title"];
                                this.hasTitleAsset = asset ? true : false;

                                //Find the best asset to create a swatch for
                                if (!asset) {
                                    var [swatchKey] = keys.filter((key: string) => typeof (style.assets[key]) !== "string" ? (style.assets[key] as any).swatch : false);
                                    asset = style.assets[swatchKey];
                                }

                                return asset ?? style.assets[keys[0]];
                            }).map((asset, index: number) => {
                                var isSwatch = typeof asset === "string" ? false : asset.swatch ?? false;
                                var path = typeof asset === "string" ? asset : asset.data;

                                return <label key={'A' + index}>
                                    <Grid container justify="space-between">
                                        <Grid item className={`${classes.tallerRadio} ${classes.radioContainer}`}>
                                            <Radio key={'AA' + index} value={index} size="small" />

                                            {isSwatch ? <div style={{ width: 40, height: 40, background: `${path}`, backgroundSize: "cover" }}></div> : <img src={path} style={{ maxWidth: 80 }} alt={`decorative style ${index + 1}`} />}
                                        </Grid>
                                    </Grid>
                                </label>
                            }
                            )}
                        </RadioGroup>

                        {!hideAllElse && <>
                            <p>Change the colour palette:</p>

                            <RadioGroup name="palette" value={this.state.palette} style={{ paddingBottom: 20 }} onChange={this.handleStyleChange}>
                                {altStyles?.map(style => {
                                    return style.palette;
                                }).map((assets: string[], index: number) =>
                                    <label key={'P' + index}>
                                        <Grid container justify="space-between">
                                            <Grid item className={classes.radioContainer}>
                                                <Radio key={'PP' + index} value={index} size="small" />
                                                {assets.map(color => (
                                                    <div key={color} className={classes.palette} style={{ backgroundColor: `${color}` }} />
                                                ))}
                                            </Grid>
                                        </Grid>
                                    </label>
                                )}
                            </RadioGroup>
                        </>}



                        {(altStyles?.map(a => a.fontGroups).filter(a => a).length ?? 0) > 0 && <div>
                            <p>Change the font combinations:</p>

                            <RadioGroup name="fontGroup" value={this.state.fontGroup} onChange={this.handleStyleChange}>
                                {altStyles?.map(style => {
                                    return style.fontGroups;
                                }).map((fonts, index: number) =>
                                    <label key={index}>
                                        <Grid container justify="space-between">
                                            <Grid item className={classes.radioContainer} style={{ height: 70 }}>
                                                <Radio value={index} size="small" />
                                                <div>
                                                    {fonts?.map(font => (
                                                        <span key={font.name} style={{ display: "block", fontFamily: font.font, fontSize: 16 }}>{font.name}</span>
                                                    ))}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </label>
                                )}
                            </RadioGroup>
                        </div>}

                        <Grid container justify="center" className={classes.buttons}>
                            <Grid item>
                                <Button className={classes.cancel} onClick={this.closeMenu} color="secondary" variant="contained">Cancel</Button>
                            </Grid>

                            <Grid item>
                                <Button color="secondary" onClick={this.keepChanges} variant="contained" className={classes.upload}>Apply changes</Button>
                            </Grid>
                        </Grid>
                    </div>
                </Paper>

                <Paper square elevation={0} className={`${classes.menuPaper} ${tab === "data" ? classes.menuPaperOpen : ""}`}>
                    <div className={classes.title}>
                        <img alt="data icon" height={50} src={Data} />
                        <span>Manage Data</span>
                        <Divider />
                    </div>

                    <div className={classes.content}>
                        <strong>Manage your menu data in Excel</strong>

                        <p>It's easier to manage menu data if you download a spreadsheet and edit in Excel. This is an ideal way to make small changes to your menu on a daily or weekly basis, without having to think about the layout or style.</p>

                        <p>Make sure you maintain the correct data format (which is easy!) and you can then import the new data and we can update your menu in one go.</p>

                        <Divider />

                        <div style={{ paddingTop: 20 }}>
                            <strong>Download your menu data</strong>
                        </div>

                        <p>Download a spreadsheet containing your current menu data.</p>

                        <Button onClick={generateCSV} color="secondary" variant="contained" startIcon={<GetApp />} className={classes.upload}>Download</Button>

                        <Divider />

                        <div style={{ paddingTop: 20 }}>
                            <strong>Upload your menu data</strong>
                        </div>

                        <p>Once you've edited it, you can upload the spreadsheet to update your menu.</p>
                        <p>Remember to save any changes.</p>

                        {importing ? <CircularProgress /> : <Button component="label" htmlFor="fileUpload1" color="secondary" variant="contained" startIcon={<Publish />} className={classes.upload}>Upload</Button>}
                        <input style={{ visibility: "hidden" }} id="fileUpload1" name="csv" type="file" onChange={uploadCSV} />
                    </div>
                </Paper>

                <Paper square elevation={0} className={`${classes.menuPaper} ${tab === "menuology" ? classes.menuPaperOpen : ""}`}>
                    <div className={classes.title}>
                        <MenuBookRounded />
                        <span>Enhance Menu</span>
                        <Divider />
                    </div>

                    <div className={classes.content}>

                        <p>Did you know that your food and drink menu is the most important piece of paper in your outlet? Menus are the equivalent of your shop window, allowing your guests see what tempting food & drink you have to offer</p>

                        <Divider /><br />

                        <strong>Divide it up</strong>
                        <p>Think about how you currently showcase wine into sections (red, white, rose). The same rules can be applied for your other drinks categories. Guests don’t spend long looking at the menu so make their choices easy by simple navigation – for example split your soft drinks into still or sparkling, fruity, classics etc.</p>

                        <Divider /><br />

                        <strong>Hail your hero</strong>
                        <p>If you have a drink on promotion or that provides high margin for your outlet, make sure adequate focus is given on your menu. This will grab your guest’s attention.</p>

                        <Divider /><br />

                        <strong>Love low calorie</strong>
                        <p>Guests are now looking for healthier alternatives, so it's important to shout about your ranges of low and no added sugar products. Use of low-calorie icons or adding calorie content next to featured drinks can be really effective.</p>

                        <Divider /><br />

                        <strong>Frame the way</strong>
                        <p>The human eye is drawn to items that are located in boxes/frames, different colour or shading can also be really effective in creating stand-out. So if you want to promote a feature product, putting a box around the product is a simple way to really grab guests attention.</p>

                        <Divider /><br />

                        <strong>Paint a picture</strong>
                        <p>Make guests mouths water. Having a really nice descriptor will make the food or drink item sound so much more appealing, and it can also help support your pricing.</p>

                        <Divider /><br />

                        <strong>Path to premium</strong>
                        <p>We know that guests are looking for experiences they can’t get at home. This is the same for food and drink. For food, it's those added extras from the ingredients you use, to the presentation on the plate. For Drinks, make sure you have a premium selection to suit your outlet and serve them with panache.</p>

                    </div>
                </Paper>

                {/* <Paper square elevation={0} className={`${classes.menuPaper} ${tab === "help" ? classes.menuPaperOpen : ""}`}>
                    <div className={classes.title}>
                        <HelpRounded />
                        <span>Help</span>
                        <Divider />
                    </div>

                    <div className={classes.content}>
                        <strong>Edit your menu</strong>

                        <p>1. Click & edit any text on the page<br />Or<br />2. Click the edit button near each section's title to configure the look and feel of that section</p>
                    </div>
                </Paper> */}
            </Grid>
        </div>
    }
}

const mapStateToProps = (state: any) => {
    return {
        menu: state.menu
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        changeStyle: (style: IMenuStyle) => dispatch(menuActions.changeStyle(style)),
        saveMenu: (id: number) => dispatch(myMenuOperations.saveMenu(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Sidebar));
import { Grid, Typography, WithStyles, FormControlLabel, RadioGroup, Radio, Divider, Checkbox} from "@material-ui/core";
import React from "react";
import Editable from "../decorators/Editable";
import StyleInjector from "../decorators/StyleInjector";
import TextField from "../form/TextField";
import ImageUpload from "../form/ImageUpload";
import { menuOperations } from "../../store/menu";
import { connect } from "react-redux";
import ILibraryImage from "../../interfaces/ILibraryImage";
import ICustomTheme from "../../interfaces/ICustomTheme";

interface IAltCoverProps {
    id: string,
    title: string,
    logoImage: string,
    firstImage: string,
    secondImage: string,
    thirdImage: string,
    address?: string,
    contact?: string,
    desc?: string,
    hashtag?: string,
    uploadFile: any,
    coverOption: string,
    showFacebookLogo?: boolean,
    theme: ICustomTheme
}

const styles = ({   
});

function Appearance(props: { onChange: any, dataMapping: any }) {
    const { onChange, dataMapping } = props;

    return <Grid container style={{ marginBottom: 30 }}>

        <Grid item xs={12} style={{marginTop: 15, marginBottom: 15}}>
            <span style={{ fontSize: 14 }}>Select your front cover type:</span>
        </Grid>

        <RadioGroup name="coverOption" defaultValue={dataMapping.coverOption} aria-label="cover option" onChange={(ev: any) => dataMapping.coverOption = ev.target.value }>
            <Grid container>
                <Grid item>
                    <FormControlLabel value={"1"} label={<span style={{ fontSize: 14 }}>{dataMapping.theme.altCover?.menuText}</span>}  control={<Radio />} labelPlacement="end"/>
                </Grid>

                <Grid item>
                    <FormControlLabel value={"2"} label={<span style={{ fontSize: 14 }}>{dataMapping.theme.altCover?.altMenuText}</span>}  control={<Radio />} labelPlacement="end"/>
                </Grid>
            </Grid>
        </RadioGroup>

        <Grid item xs={12}>
            <FormControlLabel 
                    style={{marginBottom: 15, marginTop: 10, fontSize: 14 }}
                    name="showFacebookLogo" 
                    onChange={onChange} 
                    checked={dataMapping["showFacebookLogo"]} 
                    label={<span style={{ fontSize: 14 }}>Show Facebook logo (displayed before the hashtag text)</span>}
                    labelPlacement="end"
                    control={<Checkbox />} />   
        </Grid>

        <Grid item xs={12}>
            <Divider style={{ marginTop: 15}} />
        </Grid>
    </Grid>
}

function AltCover(props: IAltCoverProps & WithStyles) {
    const { id, logoImage, firstImage, secondImage, thirdImage, title, address, contact, classes, uploadFile, desc, hashtag, theme, coverOption, showFacebookLogo } = props;    

    const data = {
        id,
        name: "Cover page",
        initialValues: {
            logoImage,
            firstImage,
            secondImage,
            thirdImage,
            title,
            address,
            contact,
            desc,
            hashtag,
            theme,
            coverOption,
            showFacebookLogo
        },
        state: {
            firstFormData: undefined,
            secondFormData: undefined,
            thirdFormData: undefined,                        
            logoFormData: undefined,
            firstError: false,
            secondError: false,
            thirdError: false,                        
            logoError: false
        },
        fields: [
            {                    
                label: "Appearance",
                render: (onChange: any, dataMapping: any) => {
                return <Appearance onChange={onChange} dataMapping={dataMapping}/>
                }
            },
            {
                label: "Title",
                required: true,
                render: (onChange: any, dataMapping: any) => {
                    return <TextField name="title" value={dataMapping["title"]} onChange={onChange} />
                }
            },
            {
                label: "Address",
                required: true,
                render: (onChange: any, dataMapping: any) => {
                    return <TextField fullWidth multiline rows={4} name="address" value={dataMapping["address"]} onChange={onChange} />
                }
            },
            {
                label: "Contact details",
                required: true,
                render: (onChange: any, dataMapping: any) => {
                    return <TextField fullWidth name="contact" value={dataMapping["contact"]} onChange={onChange} />
                }
            },
            {
                label: "Description",
                render: (onChange: any, dataMapping: any) => {
                    return <TextField fullWidth name="desc" value={dataMapping["desc"]} onChange={onChange} />
                }
            },
            {
                label: "Facebook link or hashtag",
                render: (onChange: any, dataMapping: any) => {
                    return <TextField fullWidth name="hashtag" value={dataMapping["hashtag"]} onChange={onChange} />
                }
            },                        
            {
                label: "Logo",
                render: (onChange: any, dataMapping: any, state: { onSetState: any, editableState: any }) => {
                    return <React.Fragment>
                        {state.editableState.logoError && <Typography variant="body2" gutterBottom color="error">Image upload failed, please make sure your image is under 5mb and is a jpg or png</Typography>}

                        <ImageUpload
                            name="logo"
                            imageKey="logoImage"
                            dataMapping={dataMapping}
                            fileUploaded={(formData: FormData) => {
                                state.onSetState({
                                    editableState: { ...state.editableState, logoFormData: formData, logoError: false }
                                });
                            }}
                            imageSelected={(image: ILibraryImage) => {
                                state.onSetState({
                                    dataMapping: { ...dataMapping, logoImage: image.filePath },
                                    editableState: { ...state.editableState, logoFormData: null, logoError: false }
                                });
                            }} />
                    </React.Fragment>
                }
            },
            {
                label: "First image",
                render: (onChange: any, dataMapping: any, state: { onSetState: any, editableState: any }) => {
                    return <React.Fragment>
                        {state.editableState.firstError && <Typography variant="body2" gutterBottom color="error">Image upload failed, please make sure your image is under 5mb and is a jpg or png</Typography>}

                        <ImageUpload
                            imageKey="firstImage"
                            dataMapping={dataMapping}
                            fileUploaded={(firstFormData: FormData) => {
                                state.onSetState({
                                    editableState: { ...state.editableState, firstFormData, firstError: false }
                                });
                            }}
                            imageSelected={(image: ILibraryImage) => {
                                state.onSetState({
                                    dataMapping: { ...dataMapping, firstImage: image.filePath },
                                    editableState: { ...state.editableState, firstFormData: null, firstError: false }
                                });
                            }} />
                    </React.Fragment>
                }
            }, 
            {
                label: "Second image",
                render: (onChange: any, dataMapping: any, state: { onSetState: any, editableState: any }) => {
                    return <React.Fragment>
                        {state.editableState.secondError && <Typography variant="body2" gutterBottom color="error">Image upload failed, please make sure your image is under 5mb and is a jpg or png</Typography>}

                        <ImageUpload
                            imageKey="secondImage"
                            dataMapping={dataMapping}
                            fileUploaded={(secondFormData: FormData) => {
                                state.onSetState({
                                    editableState: { ...state.editableState, secondFormData, secondError: false }
                                });
                            }}
                            imageSelected={(image: ILibraryImage) => {
                                state.onSetState({
                                    dataMapping: { ...dataMapping, secondImage: image.filePath },
                                    editableState: { ...state.editableState, secondFormData: null, secondError: false }
                                });
                            }} />
                    </React.Fragment>
                }
            },     
            {
                label: "Third image",
                render: (onChange: any, dataMapping: any, state: { onSetState: any, editableState: any }) => {
                    return <React.Fragment>
                        {state.editableState.error && <Typography variant="body2" gutterBottom color="error">Image upload failed, please make sure your image is under 5mb and is a jpg or png</Typography>}

                        <ImageUpload
                            imageKey="thirdImage"
                            dataMapping={dataMapping}
                            fileUploaded={(thirdFormData: FormData) => {
                                state.onSetState({
                                    editableState: { ...state.editableState, thirdFormData, thirdError: false }
                                });
                            }}
                            imageSelected={(image: ILibraryImage) => {
                                state.onSetState({
                                    dataMapping: { ...dataMapping, thirdImage: image.filePath },
                                    editableState: { ...state.editableState, thirdFormData: null, thirdError: false }
                                });
                            }} />
                    </React.Fragment>
                }
            },                                  
        ],
        onSaved: (dataMapping: any, state: any) => {
            return new Promise<void>((resolve: any, reject: any) => {
                if (!state.editableState.logoFormData) {
                    resolve();
                    return;
                }

                //On save upload the file to the server
                uploadFile(state.editableState.logoFormData).then((file: any) => {
                    let url = window.location.origin + "/api/Files/" + file.fileId;
                    dataMapping.logoImage = url;
                    resolve();
                }).catch(() => {
                    console.error("File upload error");
                    reject();
                });

                //Reset form data
                state.onSetState({
                    editableState: { ...state.editableState, logoFormData: undefined }
                });
             }).then(() => {
                return new Promise<void>((resolve: any, reject: any) => {
                    if (!state.editableState.firstFormData) {
                        resolve();
                        return;
                    }

                    //On save upload the file to the server
                    uploadFile(state.editableState.firstFormData).then((file: any) => {
                        let url = window.location.origin + "/api/Files/" + file.fileId;
                        dataMapping.firstImage = url;
                        resolve();
                    }).catch(() => {
                        console.error("File upload error");
                        reject();
                    });

                    //Reset form data
                    state.onSetState({
                        editableState: { ...state.editableState, firstFormData: undefined }
                    });
                }).then(() => {
                    return new Promise<void>((resolve: any, reject: any) => {
                        if (!state.editableState.secondFormData) {
                            resolve();
                            return;
                        }
    
                        //On save upload the file to the server
                        uploadFile(state.editableState.secondFormData).then((file: any) => {
                            let url = window.location.origin + "/api/Files/" + file.fileId;
                            dataMapping.secondImage = url;
                            resolve();
                        }).catch(() => {
                            console.error("File upload error");
                            reject();
                        });
    
                        //Reset form data
                        state.onSetState({
                            editableState: { ...state.editableState, secondFormData: undefined }
                        });
                    }); 
                }).then(() => {
                    return new Promise<void>((resolve: any, reject: any) => {
                        if (!state.editableState.thirdFormData) {
                            resolve();
                            return;
                        }
    
                        //On save upload the file to the server
                        uploadFile(state.editableState.thirdFormData).then((file: any) => {
                            let url = window.location.origin + "/api/Files/" + file.fileId;
                            dataMapping.thirdImage = url;
                            resolve();
                        }).catch(() => {
                            console.error("File upload error");
                            reject();
                        });
    
                        //Reset form data
                        state.onSetState({
                            editableState: { ...state.editableState, thirdFormData: undefined }
                        });
                    });                                           
                });                
            });
        }
    }; 

    return <Editable data={data} fullHeight>
        <div className={classes.background}>
            <div className={coverOption === "2" ? classes.altBackground : ""}>
                <Grid container className={classes.root} direction="column" style={{background: coverOption === "1" ? theme.altCover?.bg?.background : theme.altCover?.altBg?.background}}>
                    <Grid container alignContent="flex-start">
                        <Grid item xs={6}>
                            <Typography variant="h1" className={classes.title}>{title}</Typography>
                            {desc && <Typography variant="h2" className={classes.desc}>{desc}</Typography>}
                        </Grid>
                        <Grid item xs={6} className={classes.logo}>
                            {logoImage ? <img alt="logo" src={logoImage} className={classes.sizedLogo}/> : <span className={classes.circle}>Logo<br/>here</span>}
                        </Grid>
                    </Grid>            

                    <Grid container alignContent="flex-start">
                        <Grid item xs={6} className={classes.addressContainer}>
                            <Typography variant="h3" className={classes.address}>{address}</Typography>
                            <Typography variant="h3" className={classes.contact}>{contact}</Typography>
                            <div className={classes.hashtag}>
                                {showFacebookLogo && theme.altCover?.facebookLogo && hashtag && <img src={theme.altCover?.facebookLogo} className={classes.facebook}/>}
                                <Typography variant="h3">{hashtag}</Typography>
                            </div>                        
                        </Grid>
                        {coverOption === "1" &&
                        <Grid item xs={6}>                        
                            <Grid container alignItems="flex-end" direction="column" className={classes.imageContainer}>
                                <Grid item xs={12}>
                                    {firstImage ? <img alt="first cover image" src={firstImage} className={classes.sizedImage}/> : <span className={classes.image}></span>}                                    
                                </Grid> 
                                <Grid item xs={12}>
                                    {secondImage ? <img alt="second cover image" src={secondImage} className={classes.sizedImage}/> : <span className={classes.image}></span>}  
                                </Grid> 
                                <Grid item xs={12}>
                                    {thirdImage ? <img alt="third cover image" src={thirdImage} className={classes.sizedImage}/> : <span className={classes.image}></span>} 
                                </Grid>
                            </Grid>                                        
                        </Grid>}
                    </Grid>
                </Grid>
            </div>
        </div>
    </Editable>;
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        uploadFile: (file: any) => dispatch(menuOperations.uploadFile(file))
    }
}

export default connect(null, mapDispatchToProps)(StyleInjector(styles, "altCover", true)(AltCover));
import { CSSProperties, ReactElement } from "react";
import { Action } from "redux";
import { IBritvicProduct } from "../../interfaces/IBritvicProduct";
import ICustomTheme from "../../interfaces/ICustomTheme";
import { IGinProduct } from "../../interfaces/IGinProduct";
import ILibraryImage from "../../interfaces/ILibraryImage";
import IMyMenu from "../../interfaces/IMyMenu";

export const MOVE_ITEM = "MOVE_ITEM";
export const CHANGE_PAGE = "CHANGE_PAGE";
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";

export const ADD_SECTION = "ADD_SECTION";
export const REMOVE_SECTION = "REMOVE_SECTION";
export const EDIT_SECTION = "EDIT_SECTION";
export const REMOVE_ALL_SECTIONS = "REMOVE_ALL_SECTIONS";
export const SELECT_SECTION = "SELECT_SECTION";

export const CHANGE_STYLE = "CHANGE_STYLE";
export const SET_MENU = "SET_MENU";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_GINS = "GET_GINS";
export const GET_IMAGELIBRARY = "GET_IMAGELIBRARY";
export const GET_PREVIEWS = "GET_PREVIEWS";
export const SET_PREVIEWS = "SET_PREVIEWS";
export const SET_PRODUCT_COUNT = "SET_PRODUCT_COUNT";
export const UPDATE_MENU = "UPDATE_MENU";
export const SET_GENERATION_ID = "SET_GENERATION_ID";

//History
export const ADD_HISTORY = "ADD_HISTORY";
export const UNDO = "UNDO";
export const REDO = "REDO";

export interface IMenuState {
    menu?: IMenuItem[];
    menuData?: IMyMenu;
    configuration?: IMenuConfiguration;
    theme: ICustomTheme;
    themeTemplate?: ICustomTheme;
    altStyles?: IMenuStyle[];
    history: IHistory[];
    currentPage: number;
    canUndo: boolean;
    canRedo: boolean;
    showModal: boolean;
    modalData?: IModalData;
    selectedSection?: string;
    selectedStyle?: ISelectedStyle;
    products?: IBritvicProduct[];
    gins?: IGinProduct[];
    images?: ILibraryImage[];
    imageCategories?: string[];
    generatingPreviews: boolean;
    sectionTypes: {};
    menuAltered: boolean;
    generationId?: number;
    isMenu: boolean;
}

export interface IMenuConfiguration {
    componentConfigurations: ComponentConfiguration[]
    templateConfiguration?: TemplateConfiguration
}

export interface TemplateConfiguration {
    formatOffsets?: FormatOffset[]
}

export interface FormatOffset {
    format: string,
    offset: Partial<TranslationPosition>
}

export interface TemplateFormat {
    format: string,
    size: TemplateSize
}

export interface TemplateSize {
    width: number,
    height: number
}

export interface IHistory {
    action: Actions;
    undone: boolean;
}

export type PreviewContext = "WMASK" | "PRINT";

//Modal data
export interface IModalField {
    label?: string,
    required?: boolean,
    render: (onChange: any, dataMapping: any, state: any) => ReactElement;
}

export interface IModalData<T = any> {
    id: string,
    name: string,
    state?: any,
    fields?: IModalField[],
    initialValues: T,
    onSaved?: (dataMapping: any, state: any) => Promise<void>,
    showMandatory?: boolean
    editPanelTitle?: string
    editPanelSubtitle?: string
}

//Common props
export interface ISelectedStyle {
    fontGroup: number,
    palette: number,
    asset: number
}

export interface IMenuStyle {
    fontGroups?: { name: string, font: any }[],
    palette: string[],
    filters?: string[],
    assets: { [name: string]: string | { data: string, style?: CSSProperties, swatch?: boolean } },
    hide?: string;
}

export interface IMenuItemProps {
    id: string;
    items?: IMenuItem[] | Array<IMenuItem[]>;
}

export interface IMenuItem {
    component: string;
    props: IMenuItemProps | any;
}

export interface IMenuPreviews {
    previewImages: number[],
    pdfPreview: number
}

//Actions
export interface HistoryAction extends Action {
    undo: Action;
    dontAdd: boolean;
    historyIndex?: number;
}

export interface MoveItem extends HistoryAction {
    type: typeof MOVE_ITEM;
    from: IMenuItem;
    to: IMenuItem;
}

export interface ChangePage extends HistoryAction {
    type: typeof CHANGE_PAGE;
    page: number;
}

export interface AddToHistory extends Action {
    type: typeof ADD_HISTORY;
    action: Action;
}

export interface Undo extends Action {
    type: typeof UNDO;
}

export interface Redo extends Action {
    type: typeof REDO;
}

export interface OpenModal extends Action {
    type: typeof OPEN_MODAL;
    data: IModalData;
}

export interface CloseModal extends Action {
    type: typeof CLOSE_MODAL;
}

//Section actions
export interface AddSection extends HistoryAction {
    type: typeof ADD_SECTION;
    itemType: any;
}

export interface RemoveSection extends HistoryAction {
    type: typeof REMOVE_SECTION;
    id: string;
}

export interface EditSection extends HistoryAction {
    type: typeof EDIT_SECTION;
    id: string;
    dataMapping: object;
}

export interface RemoveAllSections extends Action {
    type: typeof REMOVE_ALL_SECTIONS;
}

export interface SelectSection extends Action {
    type: typeof SELECT_SECTION;
    id: string;
}

export interface ChangeStyle extends HistoryAction {
    type: typeof CHANGE_STYLE;
    style: ISelectedStyle;
}

export interface SetMenu extends Action {
    type: typeof SET_MENU,
    menu: IMyMenu
}

export interface GetProducts extends Action {
    type: typeof GET_PRODUCTS,
    products: IBritvicProduct[]
}

export interface GetGins extends Action {
    type: typeof GET_GINS,
    gins: IGinProduct[]
}

export interface GetPreviews extends Action {
    type: typeof GET_PREVIEWS,
    preview: IMenuPreviews
}

export interface SetPreviews extends Action {
    type: typeof SET_PREVIEWS,
    generating: boolean
}

export interface SetProductCount extends Action {
    type: typeof SET_PRODUCT_COUNT,
    count: number
}

export interface GetImageLibrary extends Action {
    type: typeof GET_IMAGELIBRARY,
    images: ILibraryImage[]
}

export interface UpdateMenu extends Action {
    type: typeof UPDATE_MENU,
    menu: IMenuItem[]
}

export interface SetGenerationId extends Action {
    type: typeof SET_GENERATION_ID,
    id: number
}

export type Actions = SetGenerationId | MoveItem | ChangePage | AddToHistory | Undo | Redo | OpenModal | CloseModal | AddSection | RemoveSection | EditSection | RemoveAllSections | SelectSection | ChangeStyle | SetMenu | GetProducts | GetGins | GetPreviews | SetPreviews | SetProductCount | GetImageLibrary | UpdateMenu;
import { ActionCreator, Action } from "redux";
import {
    MENU_LOADING, GET_MENUS, MenuLoading, GetMenus, OPEN_POPUP, OpenPopup, CLOSE_POPUP, ClosePopup, RemoveMyMenu, REMOVE_MYMENU,
    FORMATS_LOADING, FormatsLoading, GET_FORMATS, GetFormats, STYLES_LOADING, StylesLoading, GET_STYLES, GetStyles, PRINTPAL_PAGE, PrintPalPage, SAVE_MENU, SavingMenu, SetMyMenu, SET_MYMENU
} from "./types";

export const openPopup: ActionCreator<OpenPopup> = () => ({
    type: OPEN_POPUP
});

export const closePopup: ActionCreator<ClosePopup> = () => ({
    type: CLOSE_POPUP
});

export const printPalPage: ActionCreator<PrintPalPage> = (printPalPage: boolean) => ({
    type: PRINTPAL_PAGE,
    printPalPage
});

export const menusLoading: ActionCreator<MenuLoading> = (loading: boolean) => ({
    type: MENU_LOADING,
    loading
});

export const getMenus: ActionCreator<GetMenus> = (data: []) => ({
    type: GET_MENUS,
    data
});

export const formatsLoading: ActionCreator<FormatsLoading> = (loading: boolean) => ({
    type: FORMATS_LOADING,
    loading
});

export const getFormats: ActionCreator<GetFormats> = (formatData: []) => ({
    type: GET_FORMATS,
    formatData
});

export const stylesLoading: ActionCreator<StylesLoading> = (loading: boolean) => ({
    type: STYLES_LOADING,
    loading
});

export const getStyles: ActionCreator<GetStyles> = (styleData: []) => ({
    type: GET_STYLES,
    styleData
});

export const setMyMenu: ActionCreator<SetMyMenu> = (id: number, title: string) => ({
    type: SET_MYMENU,
    id,
    title
})

export const setSavingMenu: ActionCreator<SavingMenu> = (saving: boolean) => ({
    type: SAVE_MENU,
    saving
})

export const removeMyMenu: ActionCreator<RemoveMyMenu> = (id: number) => ({
    type: REMOVE_MYMENU,
    id
})

export default { openPopup, closePopup, menusLoading, getMenus, formatsLoading, getFormats, stylesLoading, getStyles, printPalPage, setMyMenu, setSavingMenu, removeMyMenu };
import { FormikProps } from "formik";
import React from "react";
import UnstyledSelectsMultiple from "../voucher/Multiselect";
import UnstyledSelectsMultiple2 from "../products/ProductMultiselect";
import "./field.css";

function Field(props: FieldProps) {

    const error = props.form.errors[props.id];
    const value = props.form.values[props.id];

    let title = props.title;

    if (title === undefined) {

        const insertSpaces: number[] = []

        const titleChars = props.id.split('');

        for (let i = 0; i < titleChars.length; i++) {
            if (titleChars[i].toUpperCase() === titleChars[i]) {
                insertSpaces.push(i);
                titleChars[i] = titleChars[i].toLowerCase()
            }
        }

        titleChars[0] = titleChars[0].toUpperCase();

        for (let i = 0; i < insertSpaces.length; i++) {

            titleChars.splice(insertSpaces[i] + i, 0, " ");

        }

        title = titleChars.join('');
    }

    let inputField: JSX.Element;

    switch (props.specialType) {
        case "select":
            inputField = <select
                className="Field-input"
                disabled={props.form.allDisabled || props.isDisabled}
                id={props.id}
                onChange={props.form.handleChange}
                value={value}
            >
                {props.children}
            </select>
            break;
        case "textarea":
            inputField = <textarea
                className="Field-input"
                disabled={props.form.allDisabled}
                id={props.id}
                onChange={props.form.handleChange}
                value={value} />
            break;
        case "readonly":


            const isEmpty = value === '' || value === null || value === undefined;

            inputField = <span className={"Field-static" + (isEmpty ? " empty" : "")}>
                {isEmpty ? props.placeholder : (

                    props.format === "date" ? new Date(value).toLocaleDateString() : 
                        (props.format === "currency" ? value.toLocaleString("en-GB", { style: "currency", currency: "GBP", minimumFractionDigits: 2 }) : value

                    ))}
            </span>
            break;
        case "multiselect":
            inputField = <UnstyledSelectsMultiple
                disabled={props.form.allDisabled}
                value={value}
                onChange={props.onChange} />
            break;

        case "multiselect-product":
            inputField = <UnstyledSelectsMultiple2
                disabled={props.form.allDisabled}
                value={value}
                onChange={props.onChange} />
            break;
        case undefined:
            inputField = <input
                className="Field-input"
                disabled={props.form.allDisabled || props.isDisabled}
                type={props.type}
                name={props.id}
                id={props.id}
                checked={value}
                value={value}
                style={{ maxWidth: props.forceMaxWidth }}
                onChange={(e) => { if (props.onChange !== undefined) { props.onChange(e) } else { props.form.handleChange(e); }  }} />
            break;

    }

    return <>
        <tr>
            <td style={{ maxWidth: props.forceLabelWidth }} className={`Field-label-container ${props.specialType === "textarea" ? "textarea-label" : ''} ${props.leftAlignLabels === true ? "left-align" : ""}`}>
                <label>{title}</label>
            </td>

            <td className={`Field-required-container ${props.specialType === "textarea" ? "textarea-star" : ''}`}>
                {props.isRequired && "*"}
            </td>

            <td>
                {inputField}                
            </td>
        </tr>

        <tr>
            <td></td>
            <td></td>
            <td colSpan={props.specialType === "multiselect" ? 2 : 1}>{error && <p className="Field-warning">{error}</p>}</td>
        </tr>
    </>
    
}

Field.defaultProps = {
    isTextArea: false,
    type: "text",
    children: [],
    title: undefined,
    onChange: undefined,
    specialType: undefined,
    isRequired: false,
    forceMaxWidth: 350,
    leftAlignLabels: false,
    forceLabelWidth: 140,
    isDisabled: false,
    placeholder: '',
    format: undefined
}

interface FieldProps {
    id: string;
    title: string | undefined | JSX.Element;
    type: string;

    specialType: "select" | "textarea" | "readonly" | "multiselect" | "multiselect-product" | undefined;
    format: "date" | "currency" | undefined;

    isRequired: boolean;

    form: FormikForm;

    children: React.ReactNode;

    onChange: (e: any) => void | undefined;

    forceMaxWidth: number;

    leftAlignLabels: boolean;

    forceLabelWidth: number;

    isDisabled: boolean;

    placeholder: string;
}

export interface FormikForm extends FormikProps<any> {
    allDisabled: boolean;
}


export default Field;
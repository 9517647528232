﻿import { ActionCreator, Action } from "redux";
import { SetGenerationId, SET_GENERATION_ID } from ".";
import { IBritvicProduct } from "../../interfaces/IBritvicProduct";
import { IGinProduct } from "../../interfaces/IGinProduct";
import ILibraryImage from "../../interfaces/ILibraryImage";
import IMyMenu from "../../interfaces/IMyMenu";
import { MOVE_ITEM, MoveItem, ChangePage, CHANGE_PAGE, UNDO, REDO, Redo, Undo, OpenModal, CloseModal, OPEN_MODAL, CLOSE_MODAL, IModalData, ADD_SECTION, AddSection, RemoveSection, REMOVE_SECTION, EditSection, EDIT_SECTION, REMOVE_ALL_SECTIONS, RemoveAllSections, SELECT_SECTION, SelectSection, ChangeStyle, CHANGE_STYLE, SetMenu, SET_MENU, ISelectedStyle, GetProducts, GET_PRODUCTS, GetPreviews, GET_PREVIEWS, SetPreviews, SET_PREVIEWS, SetProductCount, SET_PRODUCT_COUNT, GetImageLibrary, GET_IMAGELIBRARY, IMenuPreviews, UPDATE_MENU, UpdateMenu, IMenuItem, GET_GINS, GetGins } from "./types"

export const moveItem: ActionCreator<MoveItem> = (to: any, from: any) => ({
    type: MOVE_ITEM,
    to,
    from,
    dontAdd: false,
    undo: {
        type: MOVE_ITEM,
        from: to,
        to: from
    } as Action
});

export const changePage: ActionCreator<ChangePage> = (page: number) => ({
    type: CHANGE_PAGE,
    page,
    dontAdd: false,
    undo: {
        type: CHANGE_PAGE
    }
});

export const undo: ActionCreator<Undo> = () => ({
    type: UNDO
})

export const redo: ActionCreator<Redo> = () => ({
    type: REDO
})

export const openModal: ActionCreator<OpenModal> = (data: IModalData) => ({
    type: OPEN_MODAL,
    data
})

export const closeModal: ActionCreator<CloseModal> = () => ({
    type: CLOSE_MODAL
})

export const addSection: ActionCreator<AddSection> = (itemType: any) => ({
    type: ADD_SECTION,
    itemType,
    dontAdd: false, 
    undo: {
        type: REMOVE_SECTION
    }
})

export const removeSection: ActionCreator<RemoveSection> = (id: string) => ({
    type: REMOVE_SECTION,
    id,
    dontAdd: false,
    undo: {
        type: ADD_SECTION
    }
})

export const editSection: ActionCreator<EditSection> = (id: string, dataMapping: object) => ({
    type: EDIT_SECTION,
    id,
    dataMapping,
    dontAdd: false,
    undo: {
        type: EDIT_SECTION
    }
})

export const removeAllSection: ActionCreator<RemoveAllSections> = () => ({
    type: REMOVE_ALL_SECTIONS
})

export const selectSection: ActionCreator<SelectSection> = (id: string) => ({
    type: SELECT_SECTION,
    id
})

export const changeStyle: ActionCreator<ChangeStyle> = (style: ISelectedStyle) => ({
    type: CHANGE_STYLE,
    style,
    dontAdd: false,
    undo: {
        type: CHANGE_STYLE
    }
})

export const setMenu: ActionCreator<SetMenu> = (menu: IMyMenu) => ({
    type: SET_MENU,
    menu
})

export const getProducts: ActionCreator<GetProducts> = (products: IBritvicProduct[]) => ({
    type: GET_PRODUCTS,
    products
})

export const getGins: ActionCreator<GetGins> = (gins: IGinProduct[]) => ({
    type: GET_GINS,
    gins
})

export const getImageLibrary: ActionCreator<GetImageLibrary> = (images: ILibraryImage[]) => ({
    type: GET_IMAGELIBRARY,
    images
})

export const getPreviews: ActionCreator<GetPreviews> = (preview: IMenuPreviews) => ({
    type: GET_PREVIEWS,
    preview
})

export const setPreviews: ActionCreator<SetPreviews> = (generating: boolean) => ({
    type: SET_PREVIEWS,
    generating
})

export const setBritvicProducts: ActionCreator<SetProductCount> = (count: number) => ({
    type: SET_PRODUCT_COUNT,
    count
})

export const updateMenu: ActionCreator<UpdateMenu> = (menu: IMenuItem[]) => ({
    type: UPDATE_MENU,
    menu
})

export const setGenerationId: ActionCreator<SetGenerationId> = (id: number) => ({
    type: SET_GENERATION_ID,
    id
});

export default { moveItem, changePage, undo, redo, openModal, closeModal, addSection, removeSection, editSection, removeAllSection, selectSection, changeStyle, setMenu, getProducts, getPreviews, setPreviews, setBritvicProducts, updateMenu };
import { CircularProgress, makeStyles } from "@material-ui/core"
import React, { useContext, useEffect, useRef, useState } from "react"
import { getScaledImage } from "../../../utils/common"
import { ContainerContext } from "../Container"
import { FieldComponent, FieldData, FieldEditor } from "../FloatingContentPanel"
import { IBritvicProduct } from "../../../interfaces/IBritvicProduct"
import { getProductsFilter } from "../../../store/menu/operations"
import CategorySelector from "../../form/CategorySelector"
import { useSelector } from "react-redux"
import { AppState } from "../../../store"

interface ImageSectionData {
    image: string | null,
    formatImageMappings?: {
        format: string,
        mappings: {
            from: string,
            to: string
        }[]
    }[],
}

interface ImageSectionConfig {
    images: string[] | ImageSectionImage[]
    showNullOption?: boolean
    productAndTextComponent?: boolean
    productType?: string
}

interface ImageSectionImage {
    src: string,
    overrideFieldProps?: FieldData[]
    name?: string
    backgroundColour?: string
}

interface IImagePicker extends FieldEditor<ImageSectionConfig, ImageSectionData> {

}

const useStyles = makeStyles({
    imagePickerContainer: {

    },
    imagesContainer: {
        display: "grid",
        gridTemplateColumns: "repeat(4, 1fr)",
        gap: "1rem",
    },
    image: {
        objectFit: "contain",
        width: "100%",
        height: "100%",
        aspectRatio: "1/1"
    },
    imageHolder: {
        backgroundColor: "#fff"
    },
    selected: {
        border: "3px solid #007d7a"
    },
    nonSelected: {
        border: "2px solid #00000040"
    },
    imageContainer: {
        background:"#DDD"
    },
    empty: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "1.5rem",
        cursor: "pointer",
        width: "154px",
        height: "160px"
    },
    selectedProductTitle:{
        textAlign: "center",
        margin: ".5rem 0",
        padding: "0 5px",
        textTransform: "uppercase",
        fontWeight: "bold"
    },
    selectedProductImageWrapper:{
        height: 150,
        width: 150
    },
    targetedProduct:{
        border: "3px solid #007d7a"
    },
    availableProductContainer: {
        border: "2px solid #00000040"
    },
    selectedProductImage:{
        height: "100%",
        width: "100%",
        objectFit: "contain"
    },
    availableProductsWrapper: {
        height: 400,
        overflowY: "scroll",
        marginTop: "2rem"
    },
    availableProductsContainer: {
        padding: "1rem",
        display: "grid",
        gridTemplateColumns: "repeat(4, 1fr)",
        gap: ".5rem",
    },
    loadingProductsContainer: {
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
});

const getProductImage = (product: string) => `/assets/products/${product}`;

export const ImagePicker: React.FC<IImagePicker> = ({ config, field, onChangeField }) => {

    const { image, formatImageMappings } = field.data;
    let { showNullOption } = config ?? { };
    const productsWrapperRef = useRef<HTMLDivElement>(null);
    const selectedProductRef = useRef<HTMLDivElement>(null);
    const [availableProducts, setAvailableProducts] = useState<IBritvicProduct[]>();
    const [loadingProducts, setLoadingProducts] = useState(false);
    const [availableProductsCategories, setAvailableProductsCategories] = useState<string[]>([]);
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const [loadingCategories, setLoadingCategories] = useState(true);
    const [images, setImages] = useState<any>([]);
    const [showCategories, setShowCategories] = useState<boolean>(false);
    const classes = useStyles();
    const menuState = useSelector((state: AppState) => state.menu);

    const onClickImage = (image: string | null, overrideFieldProps?: FieldData[]) => () => {
        console.log("onClickImage method");
        console.log("onClickImage with image", image);
        console.log(formatImageMappings);
        console.log("=========FORMAT IAMGE MAPPINGS========");
        onChangeField(field.name, { image: image, formatImageMappings: formatImageMappings}, overrideFieldProps);
    }

    const getProducts = (productTag: string, styleId: number) => {
        setLoadingProducts(true);
        getProductsFilter(productTag, styleId).then((_products) => {
            setAvailableProducts(_products);
            if(config?.images.length !== 2){
                setImages(_products)
            }
        }).catch((err) => {
            console.log("Error fetching products in FloatingProductPanel", err);
        }).finally(() => {
            setLoadingProducts(false);
            if (selectedProductRef.current) {
                setTimeout(() => {
                    if (selectedProductRef.current) {
                        selectedProductRef.current.scrollIntoView();
                    }
                }, 10);
            } 
        })
    }

    useEffect(() =>{
        if (selectedProductRef.current) {
            setTimeout(() => {
                if (selectedProductRef.current) {
                    selectedProductRef.current.scrollIntoView();
                }
            }, 10);
        } 
    }, []);

    useEffect(() => {
        if (config?.productType && menuState?.menuData) {
            getProducts(config?.productType, menuState.menuData.styleId);
        }
    }, [config?.productType, menuState?.menuData])

    useEffect(() => {
        if (availableProducts !== undefined) {
            const presentProductCategories: string[] = [];
            availableProducts
                .map(product => product.category)
                .forEach(category => presentProductCategories.includes(category)
                    ? null : presentProductCategories.push(category));
            setAvailableProductsCategories(presentProductCategories);

        }
    }, [availableProducts])

    useEffect(() => {
        if(config?.productAndTextComponent){
            if(config?.images && config?.images.length === 2){
                setShowCategories(false);  
            }else{
                setShowCategories(true);
            }
        }
        // For product and text components
        if(config?.productAndTextComponent){
            if(config?.images && config?.images.length === 2){
                setImages(config?.images)
            }else{
                setImages(availableProducts);
            }
        }else{
            setImages(config?.images)
        }
    }, [config]);

    return (
        <div>
            {
                (showCategories && availableProducts && availableProductsCategories.length > 1)
                && <CategorySelector
                    categories={availableProductsCategories}
                    selectedCategories={selectedCategories}
                    onSelectionChange={(category, selected) => {
                        if (selected) {
                            setSelectedCategories([...selectedCategories, category])
                        } else {
                            const newCategories = [...selectedCategories]
                            newCategories.splice(selectedCategories.indexOf(category), 1)
                            setSelectedCategories(newCategories);
                        }
                    } }
                />
            }
            {
                (images && images.length > 0) ?
                <div className={classes.imagesContainer} ref={productsWrapperRef}>
                {
                    (showNullOption) &&
                    <div className={`${classes.image} ${classes.empty} ${image == null ? classes.selected : classes.nonSelected}`} onClick={() => { 
                        onClickImage(null)();
                    }}>
                        LEAVE EMPTY
                    </div>
                }
                {
                    images.map((_image: any) => {  
                        let src = typeof _image == "string" ? _image : _image.src;
                        if(!(src.indexOf("/assets/") > -1)){
                            src = `/assets/products/${src}`
                        }
                        let overrideProps = typeof _image == "string" ? undefined : _image.overrideFieldProps;
                        let onClick = onClickImage(src, overrideProps);
                        if (!selectedCategories.includes(_image.category) && selectedCategories.length > 0){
                            return null;
                        }
                        return (
                            <>
                                {
                                  typeof _image !== "string" && _image?.name && _image?.backgroundColour ?
                                    <div ref={image == src ? selectedProductRef : undefined} className={``}>
                                        <div style={{ backgroundColor: _image?.backgroundColour ?? "white" }} className={`${image == src ? classes.selected : classes.nonSelected}`}>
                                            <img className={`${classes.image}`} onClick={onClick} src={getScaledImage(src, 200)} />                                            
                                        </div>
                                        <div className={classes.selectedProductTitle}>{_image?.name}</div>
                                    </div>
                                :
                                  <div className={classes.imageContainer}>
                                      <img className={`${classes.image} ${image == src ? classes.selected : classes.nonSelected}`} onClick={onClick} src={getScaledImage(src, 200)} />
                                  </div>
                                }
                            </>
                        )
                    })
                }
                </div>
                :
                <div className={classes.loadingProductsContainer}>
                    {
                        loadingProducts || loadingCategories ?
                            <CircularProgress />
                        :
                            availableProducts == undefined &&
                            <div>No available products to load.</div>
                    }
                </div>
            }
        </div>
    )
}

interface IImageSection extends FieldComponent<ImageSectionData> {
    
}

const useImageStyles = makeStyles({
    imageSectionContainer: {
        height: "100%",
        width: "100%",
        position: "absolute",
        top: "0",
        left: "0"
    },
    image: {
        height: "100%",
        width: "100%",
        objectFit: "contain"
    }
})

export const ImageSection: React.FC<IImageSection> = ({ field, scale, width, format}) => {

    const classes = useImageStyles();

    const {context } = useContext(ContainerContext);

    const { data } = field;

    // Barkers need different images, this is to auto swap them out.
    let mappingImage: string | undefined = undefined;

    if (data.formatImageMappings !== undefined) {
        for (const formatMapping of data.formatImageMappings) {
            if (formatMapping.format === format) {

                for (const mapping of formatMapping.mappings) {
                    if (data.image === mapping.from) {
                        mappingImage = mapping.to;
                        break;
                    }
                }

                break;
            }
        }
    }

    width = width ?? 1;

    return(
        <div className={classes.imageSectionContainer}>
            {
                data.image &&
                <img className={classes.image} src={getScaledImage(mappingImage ?? data.image, width * (scale ?? 1), (context == "PRINT" && scale == 1) ? true : false)}/>
            }
        </div>
    )
}
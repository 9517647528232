import React from "react";
import { Grid, Typography, TextField, FormControlLabel, Checkbox, Divider } from "@material-ui/core";
import Editable from "../decorators/Editable";
import StyleInjector from "../decorators/StyleInjector";
import WithCustomTheme from "../../interfaces/WithCustomTheme";

interface IRibbonProps {
    id: string,
    header: string,
    title: string,
    price: string,
    description: string,
    footer: string,
    classes: any,
    front: boolean,
    whiteText: boolean
}

function Appearance(props: { onChange: any, dataMapping: any }) {
    const { onChange, dataMapping } = props;

    return <Grid container style={{ marginBottom: 30 }}>

        <Grid item xs={12} style={{marginTop: 10}}>
            <Grid container>
                <Grid item>
                    <FormControlLabel 
                        name="whiteText" 
                        onChange={onChange} 
                        checked={dataMapping["whiteText"]} 
                        label={<span style={{ fontSize: 14 }}>Use white text</span>} 
                        labelPlacement="end"
                        control={<Checkbox />} />
                </Grid>
            </Grid>
        </Grid>

        <Grid item xs={12} style={{marginTop: 10}}>
            <Grid container>
                <Grid item>
                    <FormControlLabel 
                        name="front" 
                        onChange={onChange} 
                        checked={dataMapping["front"]} 
                        label={<span style={{ fontSize: 14 }}>Use front page ribbon</span>} 
                        labelPlacement="end"
                        control={<Checkbox />} />
                </Grid>
            </Grid>
        </Grid>

        <Grid item xs={12}>
            <Divider style={{ marginTop: 15}} />
        </Grid>
    </Grid>
}

class Ribbon extends React.Component<IRibbonProps & WithCustomTheme> {
    render() {
        const { classes, id, header, title, price, description, footer, theme, front, whiteText } = this.props;
        
        var data;

        if (theme.ribbon?.hasSolidAppearance) {
            data = {
                id,
                name: "Ribbon",
                initialValues: {
                    header,
                    title,
                    price,
                    description,
                    footer,
                    front,
                    whiteText
                },
                state: {
                    formData: undefined,
                    error: false
                },
                fields: [
                    {                    
                        label: theme.ribbon?.hasSolidAppearance ? "Appearance" : "",
                        render: (onChange: any, dataMapping: any) => {
                        return <Appearance onChange={onChange} dataMapping={dataMapping}/>
                        }
                    }, {                
                        label: "Title text (or leave blank)",
                        render: (onChange: any, dataMapping: any) => {
                            return <TextField name="title" value={dataMapping["title"]} onChange={onChange} InputProps={{ disableUnderline: true }} variant="filled" />
                        }
                    }, {
                        label: "Description text (or leave blank)",
                        render: (onChange: any, dataMapping: any) => {
                            return <TextField fullWidth name="description" value={dataMapping["description"]} onChange={onChange} InputProps={{ disableUnderline: true }} variant="filled" />
                        }
                    }, {
                        label: "Header text",
                        required: true,
                        render: (onChange: any, dataMapping: any) => {
                            return <TextField name="header" value={dataMapping["header"]} onChange={onChange} InputProps={{ disableUnderline: true }} variant="filled" />
                        }
                    }
                ]
            };
        }
        else {                    
            data = {
                id,
                name: "Ribbon",
                initialValues: {
                    header,
                    title,
                    price,
                    description,
                    footer,
                    front,
                    whiteText
                },
                state: {
                    formData: undefined,
                    error: false
                },
                fields: [
                    {
                        label: "Header text (or leave blank)",
                        render: (onChange: any, dataMapping: any) => {
                            return <TextField name="header" value={dataMapping["header"]} onChange={onChange} InputProps={{ disableUnderline: true }} variant="filled" />
                        }
                    }, {                
                        label: "Title text (or leave blank)",
                        render: (onChange: any, dataMapping: any) => {
                            return <TextField name="title" value={dataMapping["title"]} onChange={onChange} InputProps={{ disableUnderline: true }} variant="filled" />
                        }
                    }, {
                        label: "Price text",
                        required: true,
                        render: (onChange: any, dataMapping: any) => {
                            return <TextField name="price" value={dataMapping["price"]} onChange={onChange} InputProps={{ disableUnderline: true }} variant="filled"  />
                        }
                    }, {
                        label: "Description text",
                        required: true,
                        render: (onChange: any, dataMapping: any) => {
                            return <TextField fullWidth name="description" value={dataMapping["description"]} onChange={onChange} InputProps={{ disableUnderline: true }} variant="filled" />
                        }
                    }, {                
                        label: "Footer text (or leave blank)",
                        render: (onChange: any, dataMapping: any) => {
                            return <TextField fullWidth name="footer" value={dataMapping["footer"]} onChange={onChange} InputProps={{ disableUnderline: true }} variant="filled" />
                        }
                    } 
                ]
            };
        }

        return <Editable data={data}>
                {theme.ribbon?.hasSolidAppearance
                    ?
                    <div className={whiteText ? classes.whiteText : ""}>
                        <Grid container alignItems="flex-start" justify="flex-start" className={classes.title}>
                            <Grid item>
                                <Typography variant="h2">{title}</Typography>
                                <Typography variant="h4" className={classes.description}>{description}</Typography>
                            </Grid>
                        </Grid>
                        <div className={`${classes.root} ${front ? classes.front : classes.back}`}>
                            <Grid container alignItems="flex-start" justify="flex-start" className={classes.ribbonContainer}>
                                <Typography variant="h2"className={classes.header}>{header}</Typography>
                            </Grid>                       
                        </div>
                    </div>
                    :
                    <div className={classes.container}>
                        <div className={classes.root}>
                            <Grid container alignItems="flex-start" justify="flex-start" className={classes.ribbonContainer}>
                                <Grid container><Typography variant="h5" className={classes.header}>{header}</Typography></Grid>
                                <div className={classes.center}>                        
                                    <Typography variant="h1" className={classes.price}>{price}</Typography>                            
                                    <div>
                                        <Typography variant="h5" className={classes.title}>{title}</Typography>
                                        <Typography variant="h4" className={classes.description}>{description}</Typography>
                                    </div>
                                </div>
                            </Grid>
                            <Grid container alignItems="flex-start" justify="flex-start">
                                <Grid item xs={4}/>
                                <Grid item xs>
                                    <p className={classes.footer}>{footer}</p>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                }
        </Editable>
    }
}

export default StyleInjector({}, "ribbon", true)(Ribbon);



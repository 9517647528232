import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { ordersLoading, getOrders, paperOptionsLoading, getPaperOptions, printPalConfigLoading, getPrintPalConfig, getToken, tokenLoading, authorising, purchase, voucherResult, setOrderStatus } from "./actions";

const getOrderHistory = (): ThunkAction<void, null, null, Action<string>> => async dispatch => {
    dispatch(ordersLoading(true))

    fetch('/api/Orders')
        .then(response => response.json())
        .then(response => dispatch(getOrders(response)))
        .catch(error => console.error('Unable to get Orders.', error));
}

const getPaperPriceOptions = (formatId: number): ThunkAction<any, null, null, Action<string>> => async dispatch => {
    dispatch(paperOptionsLoading(true))

    return fetch('/api/Orders/' + formatId)
        .then(response => response.json())
        .then(response => dispatch(getPaperOptions(response)))
        .catch(error => console.error('Unable to get Paper Options.', error));
}

const getConfig = (): ThunkAction<void, null, null, Action<string>> => async dispatch => {
    dispatch(printPalConfigLoading(true))

    fetch('/api/PrintPalConfig')
        .then(response => response.json())
        .then(response => dispatch(getPrintPalConfig(response)))
        .catch(error => console.error('Unable to get PrintPalConfig.', error));
}

const getPaymentToken = (): ThunkAction<void, null, null, Action<string>> => async dispatch => {
    dispatch(tokenLoading(true))

    fetch('/api/payment/GetToken')
        .then(response => response.text())
        .then(response => dispatch(getToken(response)))
        .catch(error => console.error('Unable to get payment token.', error));
}

const buyMenus = (order: any): ThunkAction<any, null, null, Action> => async dispatch => {
    dispatch(authorising(true))

    return fetch('/api/payment/Purchase', {
        method: "POST",
        body: JSON.stringify(order),
        headers: {
            "Content-Type": "application/json"
        }
    })
    .then(response => {
        if (response.status === 200){
            return response.json();
        }
        else
            throw Error();
    })
    .then(response => dispatch(purchase(response)))
        .catch(error => {
            console.error("Unable make payment.", error);
            dispatch(authorising(false));
        });
}

const checkVoucher = (code: string, styleId?: number): ThunkAction<any, null, null, Action> => async dispatch => {
    return fetch('/api/payment/check', {
        method: "POST",
        body: JSON.stringify({
            Code: code,
            StyleId: styleId
        }),
        headers: {
            "Content-Type": "application/json"
        }
    })
        .then(response => {
            if (response.status === 200)
                return response.json();
            else
                throw Error();
        })
        .then((response: any) => {
            //dispatch(voucherResult(response));
            return response;
        });
}

const setStatus = (orderId: number, statusId: number): ThunkAction<any, null, null, Action> => async dispatch => {
    return fetch('/api/orders', {
        method: "POST",
        body: JSON.stringify({
            orderId,
            orderStatusId: statusId
        }),
        headers: {
            "Content-Type": "application/json"
        }
    })
        .then(response => {
            if (response.status === 200)
                return response.json();
            else
                throw Error();
        })
        .then((response: any) => {
            dispatch(setOrderStatus(orderId, statusId));
            return response;
        });
}

export default {
    getOrderHistory, getPaperPriceOptions, getConfig, getPaymentToken, buyMenus, checkVoucher, setStatus
};
import React, { ReactNode, useContext } from "react"
import { DragElementWrapper, DragSource, DragSourceConnector, DragSourceMonitor, DndContext } from "react-dnd";

const spec = {
    beginDrag(props: IDraggable, monitor: DragSourceMonitor, component: React.Component) {
        return { id: props.id, index: props.index, column: props.column };
    },
    endDrag(props: IDraggable, monitor: DragSourceMonitor, component: React.Component) {
        if (!monitor.didDrop())
            return;

        if (props.end)
            props.end(monitor.getItem(), monitor.getDropResult());
    },
    isDragging(props: IDraggable, monitor: DragSourceMonitor) {
        let item = monitor.getItem();
        return props.id === item.id;
    }
}

const collect = (connect: DragSourceConnector, monitor: DragSourceMonitor) => {
    return {
        connectDragSource: connect.dragSource(),
        connectDragPreview: connect.dragPreview(),
        dragging: monitor.isDragging()
    }
}

interface IDraggable {
    id: string,
    index: number,
    column?: number,
    connectDragSource: DragElementWrapper<any>,
    connectDragPreview: DragElementWrapper<any>,
    dragging: boolean,
    end?: any,
    children: (connectDropTarget: DragElementWrapper<any>, connectDragPreview: DragElementWrapper<any>, dragging: boolean) => ReactNode
}

class Draggable extends React.PureComponent<IDraggable> {
    render() {
        const { connectDragSource, connectDragPreview, dragging, children } = this.props;
        return children(connectDragSource, connectDragPreview, dragging);
    }
}

export default function CanDrag (props: any) {
    const { dragDropManager } = useContext(DndContext as any);
    const Component = dragDropManager && !props.simplified ? DragSource("1", spec, collect)(Draggable) : (props: any) => props.children(() => { }, false);
    return <Component {...props} />
};

export const NotChecked = DragSource("1", spec, collect)(Draggable);
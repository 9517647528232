import { Typography, Paper } from "@material-ui/core";
import React from "react"

interface ISectionProps {
    title: string;
    props: any;
}

export default class Section extends React.PureComponent<ISectionProps> {
    render() {
        const { title, props } = this.props;

        return <Paper {...props} variant="outlined">
            <Typography variant="h1">{title}</Typography>
            <Typography variant="body1">Content content Content content Content content Content content</Typography>
        </Paper>
    }
}
import React from "react"
import { Grid, Typography, WithStyles, FormControlLabel, Checkbox, RadioGroup, Radio } from "@material-ui/core";
import Editable from "../decorators/Editable";
import TextField from "../form/TextField";
import { IMenuItem, menuActions } from "../../store/menu";
import Components from ".";
import Droppable from "../decorators/Droppable";
import Draggable from "../decorators/Draggable";
import StyleInjector from "../decorators/StyleInjector";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import IsExporting from "../decorators/IsExporting";

interface IUnequalColumnsProps {
    id: string,
    title: string,
    subTitle: string,  
    theme: any,
    items: IMenuItem[][],
    columnsPercentage: string,    
    moveItem: any,
    hasBorder: boolean,
    editable: boolean,
    ignoreDrag: boolean
}

function OptionalEditable(props: { data: any, enable: boolean, children: any, fullHeight: boolean }) {
    const { data, enable, children, fullHeight = false } = props;    
    return enable ? <Editable fullHeight={fullHeight} data={data}>{children}</Editable> : children;
}

// function Appearance(props: { onChange: any, dataMapping: any }) {
//     const { onChange, dataMapping } = props;

//     if (!dataMapping.columnWidthPercentages) return null;

//     return <Grid container style={{ marginBottom: 30 }}>
//         <RadioGroup name="percentage" defaultValue={dataMapping.columnsPercentage} aria-label="column widths" onChange={(ev: any) => dataMapping.columnsPercentage = ev.target.value } style={{marginLeft: -10}}>
//             <Grid container justify="flex-start" item xs={12}>            
//                 <Grid item xs={12} style={{marginTop: 15, marginBottom: 15, marginLeft: 10}}>
//                     <span style={{ fontSize: 14 }}>Choose width sizes as a percentage (1st column, 2nd column, etc...):</span>
//                 </Grid>
//                 <Grid container style={{marginLeft: 10}}>               
//                     {dataMapping.columnWidthPercentages.map((width: string, index: number) => (  
//                         <Grid item key={index}> 
//                             <FormControlLabel key={index} value={width} label={<span style={{ fontSize: 14 }}>{width}</span>}  control={<Radio />} labelPlacement="end" />                                                          
//                         </Grid>
//                     ))}
//                 </Grid>
//             </Grid>
//         </RadioGroup>
//     </Grid>
// }

class UnequalColumns extends React.Component<IUnequalColumnsProps & WithStyles> {

    moveItem = (item: any, droppable: any) => {
        if (item.id === droppable.id)
            return;

        this.props.moveItem(droppable, item);
    }    

    calcColumnWidths = (columnIndex: number, columnsPercentage: string) => {
        if (columnsPercentage) {
            var pct = columnsPercentage.split(',');
            return columnIndex < pct.length ? `${pct[columnIndex]}%` : "100%"; 
        }

        return columnIndex === 0 ? `${33}%` : columnIndex < 2 ? `${67}%` : "100%";
    }

    getMarginLeft = (columnIndex: number, columnsPercentage: string, altInnerMargin: string) => {
        if (altInnerMargin && columnsPercentage) {
            var pct = columnsPercentage.split(',');
            if (columnIndex === 0) return (pct[0]>pct[1]) ? "" : altInnerMargin !== undefined ? -altInnerMargin : "";
            if (columnIndex === 1) return (pct[0]<pct[1]) ? "" : altInnerMargin !== undefined ? altInnerMargin : "";
        }
        return "";
    }

    getMarginRight = (columnIndex: number, columnsPercentage: string, altInnerMargin: string) => {
        if (altInnerMargin && columnsPercentage) {
            var pct = columnsPercentage.split(',');
            if (columnIndex === 0) return (pct[0]>pct[1]) ? "" : altInnerMargin !== undefined ? altInnerMargin  : "";
            if (columnIndex === 1) return (pct[0]<pct[1]) ? "" : altInnerMargin !== undefined ? -altInnerMargin  : "";
        }
        return "";
    }

    getBackgroundColor = (columnIndex: number, theme: any) => {
        return theme?.unequalColumns?.columnBackgrounds ? theme?.unequalColumns?.columnBackgrounds[columnIndex] : "transparent";        
    }

    render() {
        const { title, subTitle, classes, id, theme, items, columnsPercentage, hasBorder, editable = true, ignoreDrag } = this.props; 

        const columnWidthPercentages = theme?.unequalColumns?.columnWidthPercentages ?? undefined;
        const altInnerMargin = theme?.unequalColumns?.altInnerMargin ?? undefined;

        const data = {
            id,
            name: "Unequal Columns",
            initialValues: {
                title,
                subTitle,
                columnsPercentage,
                columnWidthPercentages,
                altInnerMargin,
                editable
            },
            fields: [
                // {                 
                //     label: theme?.unequalColumns?.columnWidthPercentages ? "Appearance" : "",
                //     render: (onChange: any, dataMapping: any) => {
                //     return <Appearance onChange={onChange} dataMapping={dataMapping}/>
                //     }
                // }, 
                {
                    label: "Title text (or leave blank)",
                    render: (onChange: any, dataMapping: any) => {
                        return <TextField name="title" value={dataMapping["title"]} onChange={onChange} />
                    }
                }, {
                    label: "Subtitle text (or leave blank)",
                    render: (onChange: any, dataMapping: any) => {
                        return <TextField multiline fullWidth name="subTitle" value={dataMapping["subTitle"]} onChange={onChange} />
                    }
                },
            ]
        };

        return <OptionalEditable data={data} enable={editable} fullHeight={false}>
            <IsExporting>
                {(exporting: boolean) => (
                    <React.Fragment>
                        {!classes.decoration && !theme?.unequalColumns?.noTitle &&
                            <Grid container justify="center" wrap="nowrap" className={title || subTitle ? classes.title : ""}>
                                <div>
                                    <Typography variant={"h1"}>{title}</Typography>
                                    <Typography variant={"h2"}>{subTitle}</Typography>
                                </div>
                            </Grid>                      
                        }

                        <Grid container justify="center" className={`${classes.root} ${exporting ? classes.exporting : ""} ${hasBorder ? classes.border : ""}`}>

                        {classes.decoration && !theme?.unequalColumns?.noTitle &&
                            <Grid container justify="center" alignItems="center" wrap="nowrap">
                                <Grid container justify="flex-start" item xs={1}><span className={classes.decoration} style={{ marginLeft: "20%", marginTop: classes.dl ? -10 : -20 }} /></Grid>
                                <Grid container justify="center" item xs={10}>
                                    <div style={{ marginTop: classes.dl ? 0 : -5 }}>
                                        <Typography variant={classes.dl ? "h3" : "h1"}>{title}</Typography>
                                        <Typography variant={"h2"}>{subTitle}</Typography>
                                    </div>
                                </Grid>
                                <Grid container justify="flex-end" item xs={1}><span className={classes.decoration} style={{ marginRight: "20%", marginTop: classes.dl ? -10 : -20 }} /></Grid>
                            </Grid>
                            }   

                        {theme?.unequalColumns?.noTitle && classes.title && title &&
                            <Grid container justify="flex-start" wrap="nowrap" className={classes.title}>
                                <div>
                                    <Typography variant={"h3"}>{title}</Typography>
                                </div>
                            </Grid>                      
                            }

                            {items.map((column: IMenuItem[], columnIndex: number) => (
                                <Grid item key={columnIndex} className={classes.column} 
                                    style={{ width: this.calcColumnWidths(columnIndex, columnsPercentage), 
                                            marginLeft: this.getMarginLeft(columnIndex, columnsPercentage, altInnerMargin),
                                            marginRight: this.getMarginRight(columnIndex, columnsPercentage, altInnerMargin),
                                            backgroundColor: this.getBackgroundColor(columnIndex, theme)}}>
                                    {column.length > 0
                                        ?
                                        column.map((item: IMenuItem, index: number) => {
                                            var Component = (Components as any)[item.component];

                                            return Component &&
                                                <>
                                                {
                                                ignoreDrag
                                                ?
                                                    <Component {...item.props} />
                                                :
                                                    <Droppable column={columnIndex} index={index} key={item.props.id} id={id + " " + item.props.id}>
                                                        {(connectDropTarget: any) => (
                                                            <Draggable column={columnIndex} index={index} id={id + " " + item.props.id} end={this.moveItem}>
                                                                {(connectDragTarget: any, connectDragPreview: any, dragging: boolean) => (
                                                                    <div ref={node => connectDragTarget(connectDropTarget(node))} style={{ opacity: dragging ? 0 : 1 }}>
                                                                        <Component {...item.props} />
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        )}
                                                    </Droppable>
                                                }
                                                </>
                                        })
                                        : <Droppable column={columnIndex} index={columnIndex} key={columnIndex} id={id}>
                                            {(connectDropTarget: any) => (
                                                <div ref={node => connectDropTarget(node)} className={classes.empty}>
                                                    <span>Drag any half-width item into this column</span>
                                                </div>
                                            )}
                                        </Droppable>
                                    }
                                </Grid>
                            ))}
                        </Grid>
                    </React.Fragment>
                )}
            </IsExporting>

        </OptionalEditable>
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        moveItem: (to: any, from: any) => dispatch(menuActions.moveItem(to, from))
    }
}

export default connect(null, mapDispatchToProps)(StyleInjector({}, "unequalColumns", true)(UnequalColumns));
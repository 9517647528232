import { Reducer } from "redux";
import {
    IPrintPalState, Actions, ORDERS_LOADING, GET_ORDERS, PAPER_OPTIONS_LOADING, GET_PAPER_OPTIONS, GET_PRINTPAL_CONFIG,
    PRINTPAL_CONFIG_LOADING, GET_TOKEN, TOKEN_LOADING, PAYMENT_AUTHORISATION, PURCHASE, CHECKOUT, VOUCHER_RESULT, SET_ORDER_STATUS
} from "./types";

export const initialState: IPrintPalState = {
    loading: true,
    orderData: undefined,
    paperData: [],
    config: undefined,
    token: "",
    authorisation: undefined,
    authorising: false,
    checkout: false,
    validVoucher: false
}

const reducer: Reducer<IPrintPalState> = (state: IPrintPalState = initialState, action): IPrintPalState => {
    switch ((action as Actions).type) {

        case ORDERS_LOADING: {
            return {
                ...state,
                loading: action.loading
            }
        }

        case GET_ORDERS: {
            return {
                ...state,
                orderData: action.orderData,
                loading: false
            }
        }

        case PAPER_OPTIONS_LOADING: {
            return {
                ...state,
                loading: action.loading
            }
        }

        case GET_PAPER_OPTIONS: {
            return {
                ...state,
                paperData: action.paperData,
                loading: false
            }
        }

        case PRINTPAL_CONFIG_LOADING: {
            return {
                ...state,
                loading: action.loading
            }
        }

        case GET_PRINTPAL_CONFIG: {
            return {
                ...state,
                config: action.config,
                loading: false
            }
        }

        case TOKEN_LOADING: {
            return {
                ...state,
                loading: action.loading
            }
        }

        case GET_TOKEN: {
            return {
                ...state,
                token: action.token,
                loading: false
            }
        }

        case PAYMENT_AUTHORISATION: {
            return {
                ...state,
                authorising: action.authorising
            }
        }

        case PURCHASE: {
            return {
                ...state,
                purchasedOrder: action.authorisation.newOrder,
                purchaseResult: action.authorisation.transaction,
                authorising: false
            }
        }   

        case CHECKOUT: {
            return {
                ...state,
                checkout: action.checkout
            }
        }

        case VOUCHER_RESULT: {
            return {
                ...state,
                validVoucher: action.valid
            }
        }

        case SET_ORDER_STATUS: {
            var orderData = state.orderData;

            if (orderData) {
                const orderIndex = orderData?.orders.findIndex(a => a.id === action.orderId);

                if (orderIndex >= 0)
                    orderData.orders[orderIndex].status = action.statusId;
            }

            return {
                ...state,
                orderData
            };
        }

        default:
            return state;
    }
}

export default reducer;
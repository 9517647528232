import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import LoadingSpinner from "../LoadingSpinner";
import { ProductDTO } from "../../../pages/POS/ProductsPage";
import PosButton from "../posButton/PosButton";
import ProductOverlay from "./ProductOverlay";

const ProductItem: React.FC<ProductItemProps> = (props) => {

    const [overlayOpen, setOverlayOpen] = useState(false);

    const [isUpdating, setIsUpdating] = useState(false);

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            container: {
                fontFamily: 'Segoe UI Light',
                padding: `30px ${props.mx}px`,
                fontSize: 16,
                borderBottom: "1px #797979 solid",
                position: "relative"
            },
            foot: {
                padding: "0 8px"
            },
            subtitle: {
                maxWidth: 130
            },
            thumbnailContainer: {
                height: 250,
                width: 250,
                display: "flex",
                justifyContent: "center"
            },
            thumbnail: {
                height: 250,
                maxWidth: 250
            }
        }),
    );

    const classes = useStyles();

    const [imageLoaded, setImageLoaded] = useState(false);

    const firstFormat = props.data.formats[0];

    let imgUrl = 'error';

    if (firstFormat !== undefined) {
        imgUrl = firstFormat.imgUrl;
    }

    return (
        <div className={classes.container} style={{ flexGrow: props.isLast ? 1 : 0 }}>

            <div style={{ width: "fit-content" }}>
                <div className={classes.thumbnailContainer}>
                    <img
                        style={{ display: imageLoaded ? "block" : "none", opacity: props.data.isEnabled ? 1 : 0.3 }} // This needs to be a CSS hide and not a conditional render because otherwise the image will never load.
                        onLoad={() => { setImageLoaded(true) }}
                        className={classes.thumbnail}
                        src={"../../assets/styles/preview/" + imgUrl} />

                    {
                        !imageLoaded && imgUrl !== "error" && <LoadingSpinner />
                    }

                    {
                        imgUrl === "error" && <span className={classes.thumbnail} style={{ display: "block" }}>No formats exist for this image, so their images cannot be displayed</span>
                    }
                </div>
               

                <div className={classes.foot}>
                    <span className={classes.subtitle}>{props.data.title}</span>
                    <br />


                    <div style={{ float: "right", display: "inline-flex", alignItems: "center" }}>
                        <LoadingSpinner width={40} isFinished={!isUpdating} />
                        <PosButton isDisabled={isUpdating} style={{ float: "right" }} text="edit" onClick={() => setOverlayOpen(true)} />

                    </div>

                </div>


                {overlayOpen &&
                    <ProductOverlay cancel={() => setOverlayOpen(false)} product={props.data} save={(data) => { props.onValueChanged(data, () => { setIsUpdating(false) }); setOverlayOpen(false); setIsUpdating(true) }} />
            }
            </div>
        </div>
    )
}

interface ProductItemProps {
    data: ProductDTO;
    onValueChanged: (data: ProductDTO, callback: () => void) => void;
    mx: number;
    isLast: boolean;
}

export default ProductItem;
import React from "react";
import { TextField as MuiTextField } from "@material-ui/core";

function TextField(props: any) {
    return <MuiTextField
        variant="filled"
        hiddenLabel={true}
        InputProps={{
            disableUnderline: true
        }}
        {...props}
    />
};

export default TextField;
import Section from "./Section";
import Container from "./Container";
import HorizontalContainer from "./HorizontalContainer";
import Title from "./Title";
import TextList from "./TextList";
import Columns from "./Columns";
import HeroProduct from "./HeroProduct";
import SimpleHeroProduct from "./SimpleHeroProduct";
import TextSection from "./TextSection";
import HorizontalProducts from "./HorizontalProducts";
import Logo from "./Logo";
import SimpleTitle from "./SimpleTitle";
import DecoratedColumns from "./DecoratedColumns";
import SimpleTextList from "./SimpleTextList";
import ImagePlaceholder from "./ImagePlaceholder";
import Ribbon from "./Ribbon";
import UnequalColumns from "./UnequalColumns";
import Cover from "./Cover";
import Image from "./Image";
import DecoratedCover from "./DecoratedCover";
import ProductList from "./ProductList";
import Spacer from "./Spacer";
import SimpleCover from "./SimpleCover";
import HorizontalLine from "./HorizontalLine";
import AltCover from "./AltCover";
import AltImgCover from "./AltImgCover";
import ExtendedTitle from "./ExtendedTitle";
import NoLogoCover from "./NoLogoCover";
import SocialTag from "./SocialTag";
import FloatingProductPanel from "./FloatingProductPanel";
import FloatingContentPanel from "./FloatingContentPanel";
import OrderNumber from "./OrderNumber"
import SubHeading from "./SubHeading";

export default {
    Section,
    Container,
    HorizontalContainer,
    Title,
    TextList,
    Columns,
    HeroProduct,
    SimpleHeroProduct,
    TextSection,
    HorizontalProducts,
    Logo,
    SimpleTitle,
    DecoratedColumns,
    SimpleTextList,
    ImagePlaceholder,
    Ribbon,
    UnequalColumns,
    Cover,
    Image,
    DecoratedCover,
    ProductList,
    Spacer,
    SimpleCover,
    HorizontalLine,
    AltCover,
    AltImgCover,
    ExtendedTitle,
    NoLogoCover,
    SocialTag,
    FloatingProductPanel,
    FloatingContentPanel,
    OrderNumber,
    SubHeading
};
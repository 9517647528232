import React, { useState, useEffect} from "react";
import { Grid, Typography, makeStyles, createStyles, Theme, Container, IconButton, Drawer, MenuItem, Divider } from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';

import { connect } from "react-redux";
import { AppState } from "../../store";
import { IPrintPalState } from "../../store/printpal";
import { IMenuState } from "../../store/menu";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            color: "#FF9F1C",
            fontSize: 18,
            textAlign: "center",
            "& a": {
                color: "#FF9F1C",
                textDecoration: "none",
                "&:hover": {
                    color: "#FF9F1C",
                    textDecoration: "underline",
                    cursor: "pointer"
                }
            }
        },
        logo: {
            maxWidth: 450,
            width: "100%",
            height: "auto",
            [theme.breakpoints.down("sm")]: {
                width: 130                
            },
        },
        checkout: {
            color: "#666666",
            fontSize: 32,
            textAlign: "left",   
        },
        header: {
            height: 100,
            display: "flex",
            alignItems: "center",
            [theme.breakpoints.down("sm")]: {
                height: 65,
            },
        },
        headerContainer: {
            background: "#FFF",
            height: 100,
            paddingLeft: 40,
            borderBottom: "3px solid rgba(255, 159, 28, 1)",
            display: "flex",
            zIndex: 1,
            [theme.breakpoints.down("sm")]: {
                height: 65,
                paddingLeft: 0,
            },
        },
        drawerContainer: {
            padding: 0,
        },
        drawerPaper: {
            marginTop: 68,
            height: 150,
            width: 240,
            overflow: "auto"
        },
        iconButton: {
            display: "flex",
            flexDirection: "column"
        },
        menuItem: {
            color: "#000",
            fontSize: 12
        },
        orange: {
            color: "#FF9F1C",
        },
        dark: {
            color: "#666666",
        },
        link: {
            textDecoration: "none",
            color: "#666666",
        }
    }),
);

interface IProps {
    printPal: IPrintPalState;
    menu: IMenuState
}

function PrintPalHeader(props: IProps) {
    const [state, setState] = useState({
        isMobileApp: false, 
        drawerOpen: false
    })

    const { isMobileApp, drawerOpen } = state;
    const classes = useStyles();
    const pathname = window.location.pathname;

    useEffect(() => {
        let mounted: boolean = false;
        const setResponsiveness = () => {
            if (mounted)
                return window.innerWidth < 600
                    ? setState((prevState) => ({ ...prevState, isMobileApp: true }))
                    : setState((prevState) => ({ ...prevState, isMobileApp: false }));
                };
        mounted = true;
        setResponsiveness();
        window.addEventListener("resize", () => setResponsiveness());
        return () => { mounted = false; window.removeEventListener('resize', () => setResponsiveness())};     
    }, []);

    const displayDesktop = () => {
        const isMealDeal = props.menu.configuration?.templateConfiguration != undefined;
        return ( 
            <Grid container alignItems="flex-start" justify="flex-start" spacing={1} className={classes.header}>
                <Grid item xs={4}>
                    <img src="./assets/printPal/orange_logo.png" alt="ScreenPrint logo" className={classes.logo} />
                </Grid>
                {               
                props.printPal.checkout
                ?
                <Grid container item xs={8}>
                    <Grid item xs={1}/>   
                    <Grid item xs={3}>
                        <Typography className={classes.checkout}>CHECKOUT</Typography>   
                    </Grid>
                    <Grid item xs={8}/>  
                </Grid> 
                :
                <Grid container item xs={8}>
                    <Grid item xs={4}/>
                    <Grid item xs={3}>
                        <Typography className={classes.title}>
                            {
                                isMealDeal 
                                ?
                                    pathname === "/place-order-formats" ? <strong>PLACE ORDER</strong> : sessionStorage.getItem("preview") ? <Link to="/place-order-formats"><span>PLACE ORDER</span></Link> : ""
                                :
                                    pathname === "/place-order" ? <strong>PLACE ORDER</strong> : sessionStorage.getItem("preview") ? <Link to="/place-order"><span>PLACE ORDER</span></Link> : ""
                            }
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className={classes.title}>
                            {pathname === "/order-history" ? <strong>ORDER HISTORY</strong> : <Link to="/order-history"><span>ORDER HISTORY</span></Link>}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography className={classes.title}>
                            {pathname === "/screenprint-terms" ? <strong>T&Cs</strong> : <Link to="/screenprint-terms"><span>T&Cs</span></Link>}
                        </Typography>
                    </Grid>
                </Grid> 
                }   
            </Grid>               
        )
    }

    const displayMobile = () => {
        const handleDrawerOpen = () =>
            setState((prevState) => ({ ...prevState, drawerOpen: true }));

        const handleDrawerClose = () =>
            setState((prevState) => ({ ...prevState, drawerOpen: false }));

            const isMealDeal = props.menu.configuration?.templateConfiguration != undefined;

        return (
            <Grid container alignItems="flex-start" justify="flex-start" spacing={1} className={classes.header}>
                <Grid item xs={5}>
                    <img src="./assets/printPal/orange_logo.png" alt="ScreenPrint logo" className={classes.logo} />
                </Grid>
                <Grid item xs={6}>
                    {props.printPal.checkout ? <span className={classes.dark}><strong>CHECKOUT</strong></span> : null}
                    {
                        isMealDeal
                        ?
                            pathname === "/place-order-formats" && sessionStorage.getItem("preview") ? <span className={classes.orange}><strong>PLACE ORDER</strong></span> : null
                        :
                            pathname === "/place-order" && sessionStorage.getItem("preview") ? <span className={classes.orange}><strong>PLACE ORDER</strong></span> : null
                    }
                    {pathname === "/order-history" ? <span className={classes.orange}><strong>ORDER HISTORY</strong></span> : null}
                    {pathname === "/screenprint-terms" ? <span className={classes.orange}><strong>T&Cs</strong></span> : null}
                </Grid>
                {
                !props.printPal.checkout 
                ?
                <Grid container item xs={1} justify="flex-end">
                    <IconButton
                        classes={{ label: classes.iconButton }}
                        {...{
                            edge: "end",
                            color: "inherit",
                            "aria-label": "menu",
                            "aria-haspopup": "true",
                            onClick: handleDrawerOpen,
                        }}>
                        <MenuIcon style={{ fontSize: 30}} />
                        <div style={{ fontSize: 10}}>MENU</div>
                    </IconButton>
                    <Drawer style={{marginTop: 100}}
                        classes={{ paper: classes.drawerPaper }}
                        {...{
                            anchor: "right",
                            open: drawerOpen,
                            onClose: handleDrawerClose,
                        }}>
                        <div className={classes.drawerContainer}>{getDrawerChoices()}</div>
                    </Drawer>
                </Grid>
                : null
                }
            </Grid>
        )
    }

    const getDrawerChoices = () => {
        const isMealDeal = props.menu.configuration?.templateConfiguration != undefined;

        return (
            <div>
                {
                    isMealDeal 
                    ?
                        pathname !== "/place-order-formats" && sessionStorage.getItem("preview") ? <><MenuItem className={classes.menuItem}><Link className={classes.link} to="/place-order-formats"><span>PLACE ORDER</span></Link></MenuItem><Divider/></> : null
                    :
                        pathname !== "/place-order" && sessionStorage.getItem("preview") ? <><MenuItem className={classes.menuItem}><Link className={classes.link} to="/place-order"><span>PLACE ORDER</span></Link></MenuItem><Divider/></> : null
                    }
                {pathname !== "/order-history" ? <><MenuItem className={classes.menuItem}><Link className={classes.link} to="/order-history"><span>ORDER HISTORY</span></Link></MenuItem><Divider/></> : null}
                {pathname !== "/screenprint-terms" ? <><MenuItem className={classes.menuItem}><Link className={classes.link} to="/screenprint-terms"><span>T&Cs</span></Link></MenuItem><Divider/></> : null}
                <MenuItem className={classes.menuItem}><Link className={classes.link} to="/">GO BACK TO MENU MAKER</Link></MenuItem>
            </div>
        );
    }

    return <div style={{ backgroundColor: "#FFF" }}>
        <Container maxWidth={false}>
            <div className={classes.headerContainer}>
                { isMobileApp ? displayMobile() : displayDesktop() }
            </div>
        </Container>
    </div>
}

const mapStateToProps = (state: AppState) => ({
    printPal: state.printPal,
    menu: state.menu
});

export default connect(mapStateToProps)(PrintPalHeader);
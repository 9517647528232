import { FormControlLabel, makeStyles, Radio, RadioGroup, TextField, Typography } from "@material-ui/core"
import React from "react"
import { CSSProperties } from "react"
import { FieldComponent, FieldEditor } from "../FloatingContentPanel"
import { menuActions } from "../../../store/menu"
import { useDispatch, useSelector } from "react-redux"
import { AppState } from "../../../store"

type InputTypes = "IMAGE" | "TEXT";
type TextStyle = "BOLD" | "OUTLINE";
type TextColor = "WHITE" | "BLACK";

interface LockupSectionData {
    selectedInputType: InputTypes
    textStyle: TextStyle;
    textValue: string,
    textColor: TextColor,
    style: CSSProperties & {
        boldFontSize: string,
        barkerBoldFontSize: string,
        outlineFontSize: string,
        barkerOutlineFontSize: string
    }
}

interface LockupSectionConfig {}

interface ILockupEditor extends FieldEditor<LockupSectionConfig, LockupSectionData> {}

export const LockupEditor: React.FC<ILockupEditor> = ({ config, field, onChangeField }) => {
    const dispatch = useDispatch();
    const menuState = useSelector((state: AppState) => state.menu);
    const onTextChange = (value: string) => {
        onChangeField(field.name, {
            ...field.data,
            textValue: value,
        });
    }

    const onSelectedInputChange = (value: InputTypes) => {
        dispatch(menuActions.editSection("document", { textLockup: value === "TEXT" }));
        onChangeField(field.name, {
            ...field.data,
            selectedInputType: value,
            textValue: value === "IMAGE" ? "" : field.data.textValue
        });
    }

    const onTextStyleChange = (value: TextStyle) => {
        onChangeField(field.name, {
            ...field.data,
            textStyle: value,
        });
    }

    const onTextColorChange = (value: TextColor) => {
        onChangeField(field.name, {
            ...field.data,
            textColor: value,
        });
    }

    const classes = makeStyles({
        textEditorContainer: {
        },
        textColorHint: {
            width: 20,
            height: 20,
            display: "inline-block",
            margin: "0 0 -4px 10px",
            border: "1px black solid",
            borderRadius: 1
        },
        firstLabel: {
            width: 150
        },
        textInput: {
            width: 300
        }
    })();

    return (
        <div className={classes.textEditorContainer}>
            <RadioGroup
                row
                value={field.data.selectedInputType}
                onChange={(e) => {
                    onSelectedInputChange(e.target.value as InputTypes)
                }}
            >
                <FormControlLabel className={classes.firstLabel} control={<Radio />} value={"IMAGE"} label={"Image"} />
                {
                    // hide Text option for MealDeal2 and MealDeal3
                    menuState?.menuData?.styleId !== 38 && menuState.menuData?.styleId !== 42 &&
                    <FormControlLabel control={<Radio />} value={"TEXT"} label={"Text"} />
                }
            </RadioGroup>
            <br/>
            {field.data.selectedInputType === "TEXT" && <>
                <RadioGroup
                    row
                    value={field.data.textStyle}
                    onChange={(e) => {
                        onTextStyleChange(e.target.value as TextStyle)
                    }}
                >
                    <FormControlLabel className={classes.firstLabel} control={<Radio />} value={"BOLD"} label={
                        <Typography style={{ fontFamily: "Futura Std"}}>Bold</Typography>
                    } />

                    <FormControlLabel control={<Radio />} value={"OUTLINE"} label={
                        <Typography style={{ fontFamily: "Core Circus Double" }}>Outline</Typography>
                    } />
                </RadioGroup>
                <RadioGroup
                    row
                    value={field.data.textColor}
                    onChange={(e) => {
                        onTextColorChange(e.target.value as TextColor)
                    }}
                >
                    <FormControlLabel className={classes.firstLabel} control={<Radio />} value={"WHITE"} label={
                        <>White<div className={classes.textColorHint} style={{backgroundColor: "white"}} /></>
                    } />
                    <FormControlLabel control={<Radio />} value={"BLACK"} label={
                        <>Black<div className={classes.textColorHint} style={{ backgroundColor: "black" }} /></>
                    } />
                </RadioGroup>
                <TextField
                    id={field.name}
                    label={"Text"}
                    defaultValue={field.data.textValue}
                    onChange={(e) => { onTextChange(e.target.value); }}
                    className={classes.textInput}
                />
            </>}
        </div>
    )
}

interface ITextSection extends FieldComponent<LockupSectionData> {
}

const useStyles = makeStyles({
    textSectionContainer: {
        width: "100%",
        position: "relative",
        zIndex: 10,
        height: "100%",
        display: "flex",
        alignItems: "center"
    }
})

export const LockupSection: React.FC<ITextSection> = ({ field, modalData, format }) => {
    const { textStyle, textValue, style, textColor } = field.data;
    const classes = useStyles();
    let color = textColor === "BLACK" ? "black" : "white";
    let accent = textColor !== "BLACK" ? "black" : "white";

    let textStyles: CSSProperties = {
        WebkitTextStroke: "0.04em " + accent,
        textShadow: "0 0.1em 0 " + accent,
        color: color,
        width: "100%",
        textAlign: "center",
        fontSize: (format === "BARKER" || format === "SIGNAGE") ? style.barkerBoldFontSize : format === "TABLETALKER" ? "1.2em" : style.boldFontSize,
        margin: "0 1em",
        marginBottom: format === "TABLETALKER" ? "1.8em" : "",
        padding: ".5em 0"
    };

    if (textStyle === "OUTLINE" && textValue !== "") {
        textStyles = {
            color: color,
            width: "100%",
            textAlign: "center",
            fontSize: format === "BARKER" ? style.barkerOutlineFontSize : style.outlineFontSize,
            borderTop: `1px ${color} solid`,
            borderBottom: `1px ${color} solid`,
            margin: format === "BARKER" ? "0 2em" : "0 1em",
            padding: ".5em 0"
        }
    }
    // now have access to format, so can dynamically style the text
    return (
        <div className={classes.textSectionContainer} style={style}>
            <div style={
                {
                    fontFamily: textStyle === "OUTLINE" ? "Core Circus Double" : 'Futura Std',
                    zIndex: 1,
                    ...textStyles
                }
            }>{textValue}</div>
        </div>
    )
}
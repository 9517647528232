import React, { Component, createRef, CSSProperties } from "react"
import ReactDOMServer from 'react-dom/server';
import { Redirect, Link as RouterLink } from "react-router-dom";
import { Grid, Box, Typography, Toolbar, Button, withStyles, Theme, WithStyles, CssBaseline, ThemeProvider, createTheme, IconButton, Breadcrumbs, Link, CircularProgress, Tooltip, GridTypeMap } from "@material-ui/core";
import { Undo, Save, Redo, PanTool, PanToolOutlined, Home, NavigateNext, DeleteForever } from "@material-ui/icons";
import moment from "moment";
import { csvToArray } from "../utils/common";

//Store
import { connect, Provider } from "react-redux"
import { AppState } from "../store";
import { IMenuItem, IMenuState, IModalData, menuActions, menuOperations, TemplateFormat, TemplateSize } from "../store/menu";
import { IMyMenuState, myMenuActions, myMenuOperations } from "../store/myMenu";
import { globalActions, IError, ISession } from "../store/global";

//Layout
import ICustomTheme from "../interfaces/ICustomTheme";
import Pages from "../components/layout/Pages";
import Zoom from "../components/layout/Zoom";
import Sidebar, { ExportJPGButton, ExportPDFButton } from "../components/layout/Sidebar";
import EditableModal from "../components/layout/EditableModal";
import PrintPalPopup from "../components/printPal/PrintPalPopup";
import MenuAnimator from "../components/decorators/MenuAnimator";
import Components from "../components/menu";

//Icons 
import Order from "../assets/sidebar/order.svg";

//Export
import Store from "../utils/store";//This allows the store to be shared
import { create } from "jss";
import { ServerStyleSheets, jssPreset } from "@material-ui/core/styles";
import Calculator from "../components/dialogs/Calculator";
import useGeneratePdf, { GenerateFormatPreviewRequest } from "../utils/useGeneratePdf";
import CreatingContentPopup from "../components/myMenus/CreatingContentPopup";

const CalculatorIcon = "/assets/calculator.png";
const TvIcon = "/assets/tv_icon.png";
export const iconColorCorrectFilter: CSSProperties = { filter: "brightness(0) invert(33%) sepia(100%) saturate(553%) hue-rotate(134deg) brightness(92%) contrast(101%)" }

const styles = (theme: Theme) => ({
    centerContainer: {
        minHeight: "1000px"
    },
    paperWrapper: {
        background: "#E8EBED",
        padding: theme.spacing(4),
        overflow: "hidden",
        position: "relative" as "relative",
        paddingLeft: 202
    },
    paperWrapperNoSideBar: {
        paddingLeft: 0
    },

    boxMargin: {
        marginLeft: -theme.spacing(7),
        marginRight: -theme.spacing(7),
        [theme.breakpoints.down("md")]: {
            marginLeft: 0,
            marginRight: 0
        }
    },
    toolbar: {
        background: "#FFF",
        minHeight: 111,
        padding: "0 20px",
        [theme.breakpoints.down("sm")]: {
            minHeight: 111,
            padding: "20px 60px",
            fontSize: 10
        }
    },
    stepText: {
        color: "#FFF",
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
        "& h1": {
            marginBottom: theme.spacing(1)
        },
        [theme.breakpoints.down("sm")]: {
            marginTop: theme.spacing(0),
            marginBottom: theme.spacing(0),
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2)
        }
    },
    breadcrumbIcon: {
        marginRight: theme.spacing(0.5),
        width: 30,
        height: 30,
        [theme.breakpoints.down("sm")]: {
            width: 20,
            height: 20
        }
    },
    breadcrumbLink: {
        display: "flex",
        color: "#007D7A",
        fontWeight: "bold" as "bold",
        fontSize: 16,
        [theme.breakpoints.down("sm")]: {
            fontSize: 12
        }
    },
    saveMenu: {
        padding: 0,
        borderRadius: 0,
        "& hr": {
            backgroundColor: "#ECECEC"
        },
        "& .MuiListItem-button": {
            fontSize: 15,
            "&:hover": {
                backgroundColor: "#007D7A",
                color: "#FFF"
            }
        }
    },
    menuStepText: {
        color: "#000",
        [theme.breakpoints.down("sm")]: {
            fontSize: 12,
            paddingBottom: theme.spacing(2),
        }
    },
    leftAlignButtonCopy: {
        textAlign: "left" as "left",
        fontWeight: "bold" as "bold",
        lineHeight: 1.2
    }
})

interface IMyMenuProps {
    myMenu: IMyMenuState;
    showNavigation: any;
    printPalPage: any;
    session: ISession;
}

interface IMenuBuilderProps {
    menu: IMenuState;
    changePage: any;
    redo: any;
    undo: any;
    closeModal: any;
    addSection: any;
    selectSection: any;
    editSection: (id: string, data: object) => void;
    openModal: (data: IModalData) => void;
    removeAllSections: any;
    saveMenu: any;
    generatePreviews: any;
    showError: any;
    updateMenu: any;
}

interface IMenuBuilderState {
    initialZoom: number,
    zoom: number,
    minZoom: number,
    maxZoom: number,
    pan: { x: number, y: number },
    dragPos: { x: number, y: number },
    panning: boolean,
    panMouseDown: boolean,
    showPrintPalPopup: boolean,
    isExporting: boolean,
    theme: ICustomTheme,
    isImportingData: boolean,
    pageOversized: number,
    oversizedPixels: number
    calculatorOpen: boolean
    selectedFormat?: TemplateFormat
    originalSize: TemplateSize
    templateScale: number
    scaledSize?: TemplateSize
    templateContainerRef: HTMLDivElement | null
    landscape: boolean
    menuComponents: MenuComponent[],
    showCreatingContentPopup: boolean,
    signageItemId: number | null,
    signangeDownloadData: { available: boolean, error: boolean, filename: string | null, message: string }
}

interface GenerateHTMLConfig {
    width?: number
    height?: number
    offset?: number
    zoom?: number,
    imageSuffix?: string
}


interface IExportPdfHOC {
    createMenuPreviewTemplate: (format?: TemplateFormat) => Promise<true | false | void | undefined>;
    getTemplate: (format?: TemplateFormat) => GenerateFormatPreviewRequest | null;
}

export const withExportPdfHOC = (Component: any) => {
    return (props: any) => {
        const { createMenuPreviewTemplate, getTemplate } = useGeneratePdf();

        return <Component createMenuPreviewTemplate={createMenuPreviewTemplate} getTemplate={getTemplate} {...props} />;
    };
};

interface MenuComponent {
    id: string
    modalId: string
    valid: boolean
}

/*
    onValidate()
        check if components are valid
        if not valid, grab components modalData and present it
*/

interface IMenuBuilderContext {
    upsertComponent: (component: MenuComponent) => void
    setIsValid: (id: string, modalId: string, valid: boolean) => void
}

export const MenuBuilderContext = React.createContext<IMenuBuilderContext>({
    upsertComponent: () => { },
    setIsValid: () => { }
});

class MenuBuilder extends Component<WithStyles & IMenuBuilderProps & IMyMenuProps & IExportPdfHOC, IMenuBuilderState> {
    saveHandle?: NodeJS.Timeout = undefined;
    previewContainerOffsetPadding: number = 70;
    pollingInterval: NodeJS.Timeout | null = null;

    constructor(props: any) {
        super(props);

        const { menu: { theme } } = props;
        // Show the black subheader
        props.printPalPage(false);
        props.showNavigation(true);

        let availableFormats = props.menu.menuData?.availableFormats;
        let selectedFormat = availableFormats && availableFormats.length > 0 ? availableFormats[0] : undefined
        let originalSize: TemplateSize = selectedFormat ? {
            width: selectedFormat.widthPx,
            height: selectedFormat.heightPx
        } : {
            width: this.props.menu.menuData?.width ?? 1,
            height: this.props.menu.menuData?.height ?? 1
        }

        let landscape: boolean = originalSize.width >= originalSize.height ? true : false
        this.state = {
            zoom: 0.5,
            initialZoom: -1,
            minZoom: 0,
            maxZoom: 1.0,
            panning: false,
            panMouseDown: false,
            pan: { x: 0, y: 0 },
            dragPos: { x: 0, y: 0 },
            showPrintPalPopup: false,
            isExporting: false,
            theme: createTheme(theme),
            isImportingData: false,
            pageOversized: -1,
            oversizedPixels: 0,
            calculatorOpen: false,
            templateScale: 0,
            selectedFormat,
            originalSize,
            landscape,
            templateContainerRef: null,
            menuComponents: [],
            showCreatingContentPopup: false,
            signageItemId: null,
            signangeDownloadData: {available: false, error: false, filename: null, message: ""}
        }
    }

    componentDidMount() {
        //Save every 5 mins

        if (process.env.NODE_ENV !== "development") {
            var component = this;
            this.saveHandle = setInterval(() => {
                if (component.props.menu.menuAltered)
                    component.handleSave(null, null);
            }, 5 * 60 * 1000);

            //Initial save
            if (this.props.menu.menuAltered)
                this.handleSave(null, null);
        }

        let scaledSize: TemplateSize = {
            height: this.state.originalSize.height * this.state.templateScale,
            width: this.state.originalSize.width * this.state.templateScale
        }

        setTimeout(() => this.props.createMenuPreviewTemplate(this.state.selectedFormat), 1000);

        this.setState({
            scaledSize
        })
        this.setupPolling();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.calculateMenuScale.bind(this));

        if (this.saveHandle)
            clearInterval(this.saveHandle);

        if (this.pollingInterval) {
            clearInterval(this.pollingInterval);
        }
    }

    componentDidUpdate(prevProps: any, prevState: IMenuBuilderState) {
        if (prevProps.menu.theme !== this.props.menu.theme) {
            this.setState({
                theme: createTheme(this.props.menu.theme)
            })
        }


        if (prevState.templateContainerRef !== this.state.templateContainerRef) {
            window.removeEventListener("resize", this.calculateMenuScale.bind(this));
            window.addEventListener("resize", this.calculateMenuScale.bind(this));
            this.calculateMenuScale();
        }

        if (prevState.templateScale !== this.state.templateScale) {
            let scaledSize: TemplateSize = {
                height: this.state.originalSize.height * this.state.templateScale,
                width: this.state.originalSize.width * this.state.templateScale
            }

            this.setState({
                scaledSize
            })
        }

        if (prevState.signageItemId !== this.state.signageItemId) {
            this.setupPolling();
          }

    }

    calculateMenuScale() {
        if (this.state.templateContainerRef) { //If it's a non-meal deal menu, don't change the scale.

            let scale = 1;
            let zoom = 1;
            if (this.props.menu.menuData?.availableFormats != undefined && this.props.menu.menuData?.availableFormats.length > 0) {

                zoom = this.props.menu.menuData.initialZoom;

                let minHeight = 800;
                let clientHeight = this.state.templateContainerRef.clientHeight;
                if (clientHeight < minHeight) clientHeight = minHeight;


                if (this.state.landscape) {
                    scale = (this.state.templateContainerRef.clientWidth) / this.state.originalSize.width
                    zoom = this.state.originalSize.height * scale > clientHeight ? zoom - (clientHeight) / (this.state.originalSize.height + 100) : zoom;

                } else {


                    scale = (clientHeight) / this.state.originalSize.height
                    zoom = this.state.originalSize.width * scale > this.state.templateContainerRef.clientWidth ? zoom - (this.state.templateContainerRef.clientWidth) / (this.state.originalSize.width + 100) : zoom;
                }
            }

            if (this.props.menu.menuData) {
                zoom = this.props.menu.menuData.initialZoom;
            }
            this.setState({
                templateScale: scale,
                zoom
            })
        }
    }

    calculateInitialZoom(ref: any) {
        const { menu: { menuData, configuration } } = this.props;

        //Must have a ref and only set once
        if (!menuData || !ref || this.state.initialZoom !== -1)
            return;

        var initialZoom = 1;
        /*
        const spacing = 70;

        const landscape = this.state.landscape;

        const scale = this.state.templateScale;

        if(landscape){ //Ensure width does not go over clientWidth
            initialZoom = (ref.clientWidth - spacing) / (menuData.width * scale);
        } else { //Ensure height does not go over clientHeight  
            initialZoom = (ref.clientHeight - spacing) / (menuData.height * scale); 
        }

        
        //The menu's width would be outside of the views width
        if (menuData.width > (ref.clientWidth - spacing))
            initialZoom = (ref.clientWidth - 170 - spacing) / (menuData.width as number ?? 0);
        else
            initialZoom = (ref.clientHeight - spacing) / (menuData.height as number ?? 0);
        */

        this.setState({
            initialZoom: menuData.initialZoom,
            zoom: initialZoom,
            minZoom: initialZoom - 0.5,
            maxZoom: initialZoom + 0.5,
        })
    }

    //Flatten the menu structure out to all components
    flattenComponents = (flatten: IMenuItem[], root: IMenuItem) => {
        root.props.items?.forEach((child: IMenuItem) => {
            if (child instanceof Array)
                child.forEach(subChild => {
                    this.flattenComponents(flatten, subChild);
                });
            else
                this.flattenComponents(flatten, child);
        })

        //Delete the children after adding them to clean up/save ram
        delete root.props.items;

        flatten.push(root);
        return flatten;
    }

    generateCSV = () => {
        const { menu, currentPage, menuData } = this.props.menu;

        if (menu) {
            //Why JS why
            let clone = JSON.parse(JSON.stringify(menu[currentPage]));

            //Copy the root so we don't accidentally alter it
            let components = this.flattenComponents([], clone);

            const sorted = components.sort((a, b) => {
                return a.component.localeCompare(b.component);
            });

            var headers = {} as { [index: string]: boolean };

            //Add a title and version
            const version = `"1","${menuData?.title} menu data export - ${moment().format("DD/MM/YYYY HH:mm")}"\n`;

            const csv = version + sorted.reduce((prev: string, curr: IMenuItem) => {
                var comp = (Components as any)[curr.component];

                //Only print the header once
                if (comp.getCSVHeaders && !headers[curr.component]) {
                    headers[curr.component] = true;
                    prev += comp.getCSVHeaders() + "\n";
                }

                if (comp.renderToCSV) {
                    var result = comp.renderToCSV(curr.props);

                    //Add a new line if there isn't one
                    var hasNL = result.indexOf("\n") !== -1;
                    prev += result + (hasNL ? "" : "\n");
                }

                return prev;
            }, "");

            var csvBlob = new Blob(["\uFEFF" + csv], { type: "text/csv" });
            var a = document.createElement("a");
            a.href = URL.createObjectURL(csvBlob);
            a.setAttribute("download", `MenuData_${(Date.now() + "").substr(6)}.csv`);
            a.click();
        }
    }

    uploadCSV = (ev: any) => {
        const { menu, currentPage } = this.props.menu;
        const { updateMenu, showError } = this.props;

        //Must upload something
        if (ev.target.files.length <= 0 && menu)
            return;

        var reader = new FileReader();

        if (menu) {


            //Same as above
            let clone = JSON.parse(JSON.stringify(menu[currentPage]));
            let components = this.flattenComponents([], clone);

            //Show spinner
            this.setState({ isImportingData: true });

            var component = this;
            reader.onload = function (e) {
                if (e.loaded && e?.target?.result) {
                    var csv = csvToArray(e.target.result as string);

                    //Formatting error
                    if (csv === null) {
                        showError({
                            title: "CSV upload error",
                            error: "There's an issue with the formatting of your CSV, please check the format and try again"
                        } as IError);
                        return;
                    }

                    //Get version etc, ignore first line of headers
                    var [[version]] = csv.splice(0, 2)[0];
                    version = parseInt(version);

                    var currentComponentId: string | null = null;
                    var compData: IMenuItem;
                    var lineCount: number = 0;
                    var errors: string[] = [];

                    csv.forEach((line: string[]) => {
                        //Ignore empty lines
                        if (line.filter((a: string) => a !== "").length === 0)
                            return;

                        if (currentComponentId === null || line[0] !== "") {
                            //Just need id, ignore nice headers for user
                            currentComponentId = line[0];
                            lineCount = 0;

                            //Only fetch the component data once
                            [compData] = components.filter(a => a.props.id === currentComponentId);

                            if (!compData)
                                errors.push(currentComponentId);
                        } else {
                            //Ignore first line and ignore if no component
                            if (lineCount > 0 && compData) {
                                var comp = (Components as any)[compData.component];

                                if (comp.setFromCSV)
                                    //Component data, csv data, current index, csv version
                                    comp.setFromCSV(compData.props, line, lineCount - 1, version);
                                else
                                    console.error("Trying to upload data to component that doesn't support it");
                            }

                            lineCount++;
                        }
                    });

                    //Errors
                    if (errors && errors.length > 0) {
                        showError({
                            title: "CSV component errors",
                            error: `There was an issue finding ${errors.length} components`
                        } as IError);
                    } else
                        updateMenu(components);
                } else {
                    showError({
                        title: "CSV upload error",
                        error: "File upload failed, please check the file format and extension and try again"
                    } as IError);
                }

                component.setState({ isImportingData: false });
            }

            reader.readAsText(ev.target.files[0]);
        }
        else {
            console.log("No Menu found in props for uploadCSV function.");
        }
    }

    generateHTML = (config: GenerateHTMLConfig): string => {
        const { menu: { menuData, menu }, selectSection } = this.props;
        const { theme } = this.state;
        //We are manipulating the root object directly (which is a state object), therefore we copy it so it does not affect the actual state.
        const root = JSON.parse(JSON.stringify(theme.root)) as React.CSSProperties;
        const { height, imageSuffix, offset, width, zoom } = config;

        const _width = (width ?? menuData?.width ?? 0);
        const _height = (height ?? menuData?.height ?? 0);

        selectSection(undefined);

        ////debugger;
        //Set renderer to null so styles are not inserted
        //https://github.com/mui-org/material-ui/issues/17850
        const jss = create({ ...jssPreset(), Renderer: null });
        const sheets = new ServerStyleSheets({ jss });

        //Render the menu with as little as possible and collect styles along the way   

        if (root) {
            if (root.backgroundImage && imageSuffix) {
                /*
                    bgImage.split(".")
                    foreach
                        if next is last 
                            newUrl = newUrl + "_" + imageSuffix + .png
                        else
                            newUrl = this + next

                */
                var newUrl = "";
                var arr = root.backgroundImage.split(".");
                for (let i = 0; i < arr.length; i++) {
                    let val = arr[i];

                    if (i + 2 == arr.length) {    //Last dot in string is next, image extension
                        newUrl = newUrl + "_" + imageSuffix + `.png?cache=${new Date().getTime()}\")`;
                        break;
                    }
                    else {   //Not image extension dot
                        newUrl = val + "." + arr[i + 1];
                    }
                }
                root.backgroundImage = newUrl;
            }
            else {
                console.log("No background image or suffix found", root.backgroundImage, imageSuffix);
            }

            var currPadding = root.padding != undefined ? Number.parseInt((root.padding.toString().match(/\d/g) ?? [""]).join("")) : 0;
            root.padding = currPadding + (offset ?? 0);
        }
        var collect = sheets.collect(<Provider store={Store}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                {menu?.map((page, index: number) => {
                    var RootComponent = (Components as any)[page.component];

                    /*
                        Example - A5 BIM 1 (148mm x 210mm)
                            w: 566px
                            h: 794px
                            padding: 28px

                            pixelsPerMmWidth = 566 / 148 = 3.824324324324324px 
                            pixelsPerMmHeight = 794 / 210 = 3.780952380952381px

                            bleed = 5mm = pixelsPerMmWidth * 5 = 19.12162162162162px
                                            pixelsPerMmHeight * 5 = 18.90476190476191px
                            
                            bleedInPixels = 19px (rounded)
                            Apply this extra spacing to Width, Height and Root Padding
                            w: 585px
                            h: 813px
                            padding: 47px 
                    */

                    const styles = {
                        root: {
                            width: _width,
                            height: _height,
                            zoom: zoom,
                            ...root,
                        }
                    } as any;

                    var FixStyle = withStyles(styles)((props: { classes: any }) =>
                        <RootComponent key={index} {...page.props} className={`${props.classes.root} page-${index}`} />
                    );
                    return <FixStyle />
                })}
            </ThemeProvider>
        </Provider>);

        var html = ReactDOMServer.renderToString(collect);

        var htmlDoc = `<!DOCTYPE html>
        <html>
            <head><style id="jss-export">${sheets.toString()}</style></head>
            <body>${html}</body>
        </html>`;

        return htmlDoc;
    }

    handleCreateMenuPreview = () => {
        this.props.createMenuPreviewTemplate(this.state.selectedFormat);

    }

    handleExport = () => {
        if (this.state.pageOversized !== -1 && this.state.oversizedPixels) {
            this.props.showError({
                title: "Unable to export",
                error: `Page ${this.state.pageOversized + 1} exceeds the available print area and cannot be printed. Please revise your design and try again.`
            } as IError);

            return false;
        }

        this.setState({ isExporting: true });

        var width = this.props.menu.menuData?.width;
        var height = this.props.menu.menuData?.height;
        var offset = 0;
        var imageSuffix;

        if (this.props.menu.menuData && this.props.menu.menuData.previewConfigurations.length > 0) {
            let config = this.props.menu.menuData.previewConfigurations[0];
            //width = config.width;
            //height = config.height;
            /*
                566 / 148 = 3.824324324324324
                * 5 = 19.12162162162162


                794 / 210 = 3.780952380952381
                * 5 = 18.9047619047619
            */

            let formatWidth = this.props.menu.menuData.formatWidth;
            let formatHeight = this.props.menu.menuData.formatHeight;

            if (formatWidth && formatHeight && width && height) {
                let bleedInMm = 5;
                let widthPixelsPerMm = width / formatWidth;
                let heightPixelsPerMm = height / formatHeight;
                let bleedInPixels = Math.round(widthPixelsPerMm * bleedInMm);

                offset = bleedInPixels;
                width = width + bleedInPixels;
                height = height + bleedInPixels;
                imageSuffix = config.imageSuffix;
            }
        }

        //Generate html
        var HTML = this.generateHTML({
            width: width,
            height: height,
            offset: offset,
            imageSuffix: imageSuffix
        });

        //Should be something
        if (!HTML || HTML.length < 10) {
            this.props.showError({
                title: "Export unsuccessful",
                error: "An error occured while exporting your menu, please try again"
            } as IError);

            this.setState({ isExporting: false });
            return false;
        }

        if (this.props.menu.menuData) {
            const { id } = this.props.menu.menuData;

            let body = JSON.stringify({
                MenuId: id,
                HTML,
                width,
                height
            });

            fetch("/api/Export", {
                cache: "no-cache",
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body
            }).then((res: Response) => {
                return res.blob().then((blob: Blob) => {
                    var header = res.headers.get("content-disposition");
                    if (header) {
                        var filename = (header.match(/filename=(.*);/) ?? [])[1];
                        return { filename, blob };
                    }
                });
            }).then((data: { filename: string, blob: Blob } | undefined) => {
                if (data) {
                    var a = document.createElement("a");
                    a.href = URL.createObjectURL(data.blob);
                    a.setAttribute("download", data.filename);
                    a.click();
                }

                this.setState({ isExporting: false });
            });
        }
    }

    handlePageChange = (page: number) => {
        this.props.changePage(page);
    }

    handleZoom = (value: number) => {
        this.setState({ zoom: value });
    }

    handleTogglePan = () => {
        this.setState({ panning: !this.state.panning });
    }

    handlePanDown = (ev: any) => {


        const { panning, pan } = this.state;

        var posX = ev.pageX;
        var posY = ev.pageY;

        //If it's a touch event, grab the first finger thing
        if (ev.touches) {
            posX = ev.touches[0].pageX;
            posY = ev.touches[0].pageY;
        }

        if (panning) {
            ev.preventDefault();
            this.setState({ panMouseDown: true, dragPos: { x: posX - pan.x, y: posY - pan.y } });
        }
    }

    handlePanUp = (ev: any) => {
        if (this.state.panning) {
            ev.preventDefault();
            this.setState({ panMouseDown: false });
        }
    }

    handlePanMove = (ev: any) => {
        const { panMouseDown, dragPos } = this.state;

        var posX = ev.pageX;
        var posY = ev.pageY;

        //If it's a touch event, grab the first finger thing
        if (ev.touches) {
            posX = ev.touches[0].pageX;
            posY = ev.touches[0].pageY;
        }

        if (panMouseDown) {
            ev.preventDefault();
            var zoomLen = 1 - (this.state.zoom - this.state.initialZoom);
            var x = (posX - dragPos.x) * zoomLen;
            var y = (posY - dragPos.y) * zoomLen;
            this.setState({ pan: { x, y } });
        }
    }

    handlePreviewGeneration = () => {
        const { menu, menuData } = this.props.menu;

        var zoom = Math.floor(menuData!.height * 0.65) / menuData!.height;
        var newWidth = Math.floor((menuData?.width ?? 0) * zoom);
        var newHeight = (menuData?.height ?? 0) * zoom;

        return this.props.saveMenu(menuData?.id).then(() => {
            let generatedPreviews: any[] = [];
            if (menuData?.previewConfigurations && menuData.previewConfigurations.length > 0) {
                menuData?.previewConfigurations.forEach((previewConfig) => {
                    let generatedPreview = {
                        previewConfigurationId: previewConfig.previewConfigurationId,
                        html: this.generateHTML({
                            width: previewConfig.width,
                            height: previewConfig.height,
                            offset: 0,
                            zoom: zoom
                        }),
                        pages: menu?.length,
                        width: previewConfig.width,
                        height: previewConfig.height
                    }
                    generatedPreviews.push(generatedPreview);
                })
            } else {
                generatedPreviews.push({
                    previewConfigurationId: -1,
                    html: this.generateHTML({
                        zoom: zoom
                    }),
                    pages: menu?.length,
                    width: newWidth,
                    height: newHeight
                });
            }

            return this.props.generatePreviews({
                menuId: menuData?.id,
                generatedPreviews: generatedPreviews
            });
        });
    }

    zoomAndPan = () => {
        const { zoom, pan, panning } = this.state;

        return {
            transform: `scale(${zoom}) translate(${pan.x}px, ${pan.y}px)`,
            transition: "transform 0.2s ease",
            pointerEvents: panning ? "none" : "all"
        } as CSSProperties;
    }

    cursor = () => {
        const { panning, panMouseDown } = this.state;

        return {
            cursor: panMouseDown ? "grabbing" : panning ? "grab" : "default"
        } as CSSProperties;
    }

    openPopup = () => {
        if (this.state.pageOversized !== -1 && this.state.oversizedPixels) {
            this.props.showError({
                title: "Unable to print",
                error: `Page ${this.state.pageOversized + 1} is oversized so it would be malformed when printed, please check your menu and try again`
            } as IError);

            return false;
        }

        let invalidations = this.validate();
        if (invalidations.length > 0) {
            let targetComp = invalidations[0];
            let editButton = document.querySelector(`#editModalButton-${targetComp.modalId}`) as any;
            if (editButton) {
                editButton.click();
            }
            return;
        }

        this.setState({ showPrintPalPopup: true });
    }

    validate = () => {
        let invalidations = [];
        for (let i = 0; i < this.state.menuComponents.length; i++) {
            let menuComponent = this.state.menuComponents[i];
            if (!menuComponent.valid) {
                invalidations.push(menuComponent);
            }
        }
        return invalidations;
    }

    upsertComponent = (menuComponent: MenuComponent) => {
        let menuComponents = JSON.parse(JSON.stringify(this.state.menuComponents)) as MenuComponent[];
        let existingMenuComponent = menuComponents.findIndex((m) => m.id == menuComponent.id);
        if (existingMenuComponent > -1) {
            //menuComponents[existingMenuComponent] = menuComponent;
        } else {
            menuComponents.push(menuComponent);
            this.setState({
                menuComponents
            });
        }
    }

    // registerModalData = (id: string, modalData: IModalData) => {
    //     console.log("Registering modalData");
    //     let menuComponents = JSON.parse(JSON.stringify(this.state.menuComponents)) as MenuComponent[];
    //     let existingMenuComponent = menuComponents.findIndex((m) => m.id == id);
    //     if(existingMenuComponent > -1){

    //     } else {
    //         console.log("This is new, pushing to stack");
    //         menuComponents.push({
    //             id,
    //             modalData,
    //             valid: true
    //         });

    //     }

    //     this.setState({
    //         menuComponents
    //     });
    // }

    setIsValid = (fieldId: string, modalId: string, valid: boolean) => {
        let menuComponents = JSON.parse(JSON.stringify(this.state.menuComponents)) as MenuComponent[];
        let existingMenuComponent = menuComponents.findIndex((m) => m.id == fieldId);
        if (existingMenuComponent > -1) {
            let menuComponent = menuComponents[existingMenuComponent]
            if (menuComponent.valid != valid) {
                menuComponent.valid = valid;
                menuComponents[existingMenuComponent] = menuComponent;
                this.setState({
                    menuComponents
                });
            }
        } else {
            let menuComponent: MenuComponent = {
                id: fieldId,
                modalId,
                valid
            };
            menuComponents.push(menuComponent);
            this.setState({
                menuComponents
            })
        }
    }

    closePopup = () => {
        this.setState({ showPrintPalPopup: false });
    }

    handleSave = (ev: any, callback?: any) => {
        this.props.saveMenu(this.props.menu.menuData?.id).then(() => {
            //storeTemplate(this.state.selectedFormat, "Hello");
            if (callback)
                callback();
        });
    }

    handleClear = () => {
        const { initialZoom } = this.state;
        this.setState({ pan: { x: 0, y: 0 }, zoom: initialZoom });
    }

    oversize = (amount: number, page: number) => {
        //Set initially
        if (this.state.pageOversized === -1 && amount > 0) {
            if (page === this.props.menu.currentPage)
                this.setState({ pageOversized: page, oversizedPixels: amount });
            return;
        }

        if (this.state.pageOversized === page) {
            //Update pixels
            if (amount !== this.state.oversizedPixels)
                this.setState({ pageOversized: page, oversizedPixels: amount });

            //Remove
            if (amount <= 0)
                this.setState({ pageOversized: -1, oversizedPixels: amount });
        }
    }

    onOpenCalculator = () => {
        this.setState({ calculatorOpen: true });
    }

    onCloseCalculator = () => {
        this.setState({ calculatorOpen: false });
    }
 
    onDownloadSignageJpg = () => {
        if(this.props.menu.menuData){
            this.setState({signangeDownloadData: {available: false, error: false, filename: null, message: ""}})
            this.setState({ showCreatingContentPopup: true });
            let format = this.props.menu?.menuData?.availableFormats.find((row) => row?.formatKey === "SIGNAGE");
            if(format){
                const request = this.props.getTemplate(format);
                const body = JSON.stringify(request);
                return fetch("/api/export/CreateSignageItem", {
                    cache: "no-cache",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body
                }).then(response => response.json())
                .then(data => this.setState({ signageItemId: data }))
                .catch(error => console.error('Error:', error));
            }
        }
    }

    setupPolling = () => {
        if (this.pollingInterval) {
            clearInterval(this.pollingInterval);
          }
    
        if (this.state.signageItemId) {
          this.pollingInterval = setInterval(this.getSignageItem, 1200);
        }
      };

    getSignageItem = () => {
        let url = `/api/export/getSignageItem?signageItemId=${this.state.signageItemId}`
        fetch(url).then(response => response.json())
        .then(data => {
            if(data?.available || data?.error){
                this.setState({ signageItemId: null });
                this.setState({signangeDownloadData: data})
                if(data?.available){
                    let fileName = data?.filename;
                    const link = document.createElement('a');
                    link.href = `/assets/signage/${fileName}`;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }
        })
        .catch(error => this.setState({ signageItemId: null}));
    }

    onClickCopyToLayout = (price: number) => {
        /*
            this.props.editSection(this.props.data?.id, JSON.parse(JSON.stringify(this.state.dataMapping)));
        */
        let transformedPrice: string = price.toFixed(Number.isInteger(price) ? 0 : 2);
        const dataMapping = {
            price: transformedPrice
        }
        this.props.editSection("document", dataMapping);
    }
    render() {
        const { classes, closeModal, addSection, myMenu, session } = this.props;
        const { zoom, minZoom, maxZoom, panning, showPrintPalPopup, isExporting, pan, initialZoom, isImportingData, pageOversized, showCreatingContentPopup } = this.state;
        const { menu, menuData, currentPage, canRedo, canUndo, theme, showModal, modalData, altStyles, configuration, isMenu } = this.props.menu;
        const { saving } = this.props.myMenu;
        const { mealDealMessaging } = session;
        const hideSidebar = isMenu && (menuData?.simplified ?? false);
        const headerHtml = menuData?.headerHtml;

        const closeModalAndPreview = (props: any) => {
            this.handleCreateMenuPreview();
            closeModal(props)
        }

        const scale = this.state.templateScale;

        if (!menuData)
            return <Redirect to="/" />

        return <Grid container className={"Fructose"}>
            <MenuBuilderContext.Provider value={{
                upsertComponent: this.upsertComponent.bind(this),
                setIsValid: this.setIsValid.bind(this)
            }}>
                <Grid item xs={12} className={classes.stepText}>
                    {menuData.menuText ? <Typography variant={myMenu.isMobileApp ? "h2" : "h1"}>
                        <div dangerouslySetInnerHTML={{ __html: menuData.menuText }}></div>
                    </Typography>
                        : <Typography variant={myMenu.isMobileApp ? "h2" : "h1"}>
                            {!isMenu ? "Create your Meal Deal" : "Create your menu"}
                        </Typography>}
                    {menuData.headerHtml ?
                        <Typography variant={myMenu.isMobileApp ? "body2" : "body1"} paragraph>
                            <div dangerouslySetInnerHTML={{ __html: menuData.headerHtml }}></div>
                        </Typography>
                        :
                        <Typography variant={myMenu.isMobileApp ? "body2" : "body1"} style={{ lineHeight: 1.2, marginTop: 10 }} paragraph>
                            {!isMenu
                                ? <>First choose a background, roundel and lock-up for your design.<br />
                                    Design your poster and any other print products will be created automatically.<br />
                                    We’ll include posters, table talkers, shelf barkers and wobblers in your pack - you can choose amounts when you order your prints.<br />
                                    Use our handy calculator tool to price your deal, or you can enter the price manually.
                                </>

                                : <>Now you can start adding your Food & Drink products to your new menu. Don’t forget to add images, we have lots for you to choose from.<br />
                                    Now you need to enter products into each of the sections. We've set up a preview using some examples.<br /><br />
                                    Remember: to get your free menus, you need to add 5 Britvic products from our selection.</>
                            }
                        </Typography>}
                </Grid>

                <Grid item xs={12}>
                    <Box className={classes.boxMargin}>
                        <Toolbar className={classes.toolbar}>
                            <Grid container alignItems="center" spacing={1} justifyContent="flex-end">
                                <Grid item style={{ flexGrow: 1 }}>
                                    <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNext fontSize="medium" />}>
                                        <Link component={RouterLink} color="inherit" to="/" className={classes.breadcrumbLink}>
                                            <Home className={classes.breadcrumbIcon} />
                                        </Link>

                                        <Link color="inherit" component={RouterLink} to="/" className={classes.breadcrumbLink}>My designs</Link>
                                        <span className={classes.menuStepText} color="textPrimary"><strong>New Menu: Create your menu</strong></span>
                                    </Breadcrumbs>
                                </Grid>
                                <Grid item>
                                    <Pages pages={menu?.length ?? 0} onPageSelected={this.handlePageChange} currentPage={currentPage} />
                                </Grid>

                                <Grid item>
                                    <Tooltip arrow title="Select the hand icon and drag your zoomed menu around">
                                        <IconButton onClick={this.handleTogglePan}>
                                            {panning ? <PanTool /> : <PanToolOutlined />}
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid item>
                                    <Zoom
                                        onMove={this.handleZoom}
                                        onReset={this.handleClear}
                                        defaultZoom={initialZoom}
                                        maxZoom={maxZoom}
                                        minZoom={minZoom} />
                                </Grid>
                                <Grid item>
                                    <Button size={myMenu.isMobileApp ? "small" : "large"} onClick={this.props.undo} startIcon={<Undo />} disabled={!canUndo}>
                                        Undo
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button size={myMenu.isMobileApp ? "small" : "large"} onClick={this.props.redo} startIcon={<Redo />} disabled={!canRedo}>
                                        Redo
                                    </Button>
                                </Grid>
                                {!isMenu && <Grid item>
                                    <Button className={classes.leftAlignButtonCopy} size={myMenu.isMobileApp ? "small" : "large"} onClick={this.onOpenCalculator} startIcon={<img style={iconColorCorrectFilter} height={myMenu.isMobileApp ? 18 : 35} src={CalculatorIcon} />}>
                                        Calculator
                                    </Button>
                                </Grid>}
                                {
                                    !isMenu &&
                                    <Grid item>
                                        <Button className={classes.leftAlignButtonCopy} size={myMenu.isMobileApp ? "small" : "large"} startIcon={<img style={iconColorCorrectFilter} height={myMenu.isMobileApp ? 18 : 35} src={TvIcon} />} onClick={this.onDownloadSignageJpg}>
                                            Download<br /> Signage.jpg
                                        </Button>
                                    </Grid>
                                }
                                {/* To be deployed at a later date. <Grid item>

                                    <ExportJPGButton className={classes.leftAlignButtonCopy} />
                                </Grid>

                                {/* To maybe be deployed at a lter date. <Grid item>

                                    <ExportPDFButton className={classes.leftAlignButtonCopy} />
                                </Grid>*/}
                                <Grid item>
                                    <Button className={classes.leftAlignButtonCopy} size={myMenu.isMobileApp ? "small" : "large"} onClick={this.openPopup} startIcon={<img style={iconColorCorrectFilter} height={myMenu.isMobileApp ? 18 : 35} src={Order} />}>
                                        Order<br /> prints
                                    </Button>
                                </Grid>
                                {/*<Grid item> Do we still need a save button? It's not on the design anymore...
                                <Button size={myMenu.isMobileApp ? "small" :"large"} startIcon={saving ? <CircularProgress size={30} /> : <Save />} onClick={this.handleSave}>
                                    Save
                                </Button>
                            </Grid>*/}
                            </Grid>
                        </Toolbar>

                        <Grid className={classes.centerContainer} style={{ position: "relative", touchAction: panning ? "none" : undefined, ...this.cursor() }} container innerRef={(ref: any) => this.calculateInitialZoom(ref)}>
                            {!hideSidebar && <Sidebar
                                openPopup={this.openPopup}
                                addSection={addSection}
                                altStyles={altStyles}
                                isExporting={isExporting}
                                exportPDF={this.handleExport}
                                generateCSV={this.generateCSV}
                                uploadCSV={this.uploadCSV}
                                importing={isImportingData}
                                onOpenCalculator={this.onOpenCalculator}
                                isMealDeal={!isMenu}
                            />}
                            <Grid item xs className={`${classes.paperWrapper} ${hideSidebar ? classes.paperWrapperNoSideBar : ""}`} onMouseLeave={this.handlePanUp} onTouchStart={this.handlePanDown} onTouchMove={this.handlePanMove} onTouchEnd={this.handlePanUp} onMouseUp={this.handlePanUp} onMouseDown={this.handlePanDown} onMouseMove={this.handlePanMove}>
                                <div ref={(node) => {
                                    if (!this.state.templateContainerRef)
                                        this.setState({ templateContainerRef: node })
                                }} style={{ height: "1600px" }}>
                                    {
                                        this.state.scaledSize != undefined &&
                                        <Grid container alignItems={"center"} justifyContent={"center"}>
                                            <Grid item style={{ height: "100%" }}>
                                                <ThemeProvider theme={this.state.theme}>
                                                    <CssBaseline />

                                                    <div style={{ ...this.zoomAndPan() }}>
                                                        {menu?.map((page, index: number) => {
                                                            if (this.state.scaledSize)
                                                                return (
                                                                    <MenuAnimator
                                                                        context={"WMASK"}
                                                                        size={this.state.scaledSize}
                                                                        scale={scale}
                                                                        key={index}
                                                                        theme={theme}
                                                                        index={index}
                                                                        currentPage={currentPage}
                                                                        data={page}
                                                                        oversized={this.oversize}
                                                                        formatSize={menuData.formatKey}
                                                                        border={pageOversized === index ? "10px solid red" : ""} />
                                                                )
                                                        })}
                                                    </div>
                                                </ThemeProvider>
                                            </Grid>
                                        </Grid>
                                    }
                                </div>
                            </Grid>
                            <EditableModal
                                open={showModal}
                                onClose={closeModalAndPreview}
                                data={modalData} />
                        </Grid>
                    </Box>
                </Grid>
                <PrintPalPopup
                    isPopupOpen={showPrintPalPopup}
                    closePopup={this.closePopup}
                    generatePreviews={this.handlePreviewGeneration}
                />
                <CreatingContentPopup 
                    isPopupOpen={this.state.showCreatingContentPopup}
                    closePopup={() => {this.setState({ showCreatingContentPopup: false })}}
                    signangeDownloadData={this.state.signangeDownloadData}
                    isMobileApp={myMenu.isMobileApp}
                    cancelDownload={()=> {
                        this.setState({signageItemId: null});
                        this.setState({showCreatingContentPopup: false})
                    }}
                />
                <Calculator
                    onClose={this.onCloseCalculator}
                    open={this.state.calculatorOpen}
                    menuId={menuData.id}
                    onClickCopyLayout={this.onClickCopyToLayout}
                />
            </MenuBuilderContext.Provider>
        </Grid>
    }
}

const mapStateToProps = (state: AppState) => ({
    menu: state.menu,
    myMenu: state.myMenu,
    session: state.global.session
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        changePage: (newPage: number) => dispatch(menuActions.changePage(newPage)),
        undo: () => dispatch(menuActions.undo()),
        redo: () => dispatch(menuActions.redo()),
        closeModal: () => dispatch(menuActions.closeModal()),
        showNavigation: (showNavigation: boolean) => dispatch(globalActions.showNavigation(showNavigation)),
        showError: (error: IError) => dispatch(globalActions.showError(error)),
        addSection: (id: string, menuItem: IMenuItem) => dispatch(menuActions.addSection(id, menuItem)),
        removeAllSections: () => dispatch(menuActions.removeAllSection()),
        printPalPage: (printPalPage: boolean) => dispatch(myMenuActions.printPalPage(printPalPage)),
        saveMenu: (id: number) => dispatch(myMenuOperations.saveMenu(id)),
        generatePreviews: (menu: any) => dispatch(menuOperations.generatePreviews(menu)),
        updateMenu: (menu: any) => dispatch(menuActions.updateMenu(menu)),
        selectSection: (id: string | undefined) => dispatch(menuActions.selectSection(id)),
        openModal: (data: IModalData) => dispatch(menuActions.openModal(data)),
        editSection: (id: string, data: object) => dispatch(menuActions.editSection(id, data))
    }
}

export default withExportPdfHOC(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(MenuBuilder)));
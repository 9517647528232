import { Button, makeStyles, Theme } from "@material-ui/core";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import { IModalData, menuActions } from "../../store/menu";
import { ContainerContext } from "../menu/Container";
import IsExporting from "./IsExporting";
import Pencil from "../../assets/pencil.png"
import Plus from "../../assets/plus_icon.png"

interface IEditableAbsolute{
    data: IModalData;
    position?: number[]
    fullHeight?: boolean;
    buttonText?: string;
    changed?: boolean;
    hideOnExport?: boolean;
    hideEdit?: boolean
}

/*
    <>
        children

        editable-wrapper with positiom
    </>
*/

const useStyles = makeStyles({
    editWrapper: {
        transition: "opacity .3s ease",
        zIndex: 999,
        opacity: 0
    },
    editWrapperVisible: {
        opacity: 1
    },
    editWrapperHidden: {
        opacity: 0
    },
    editButton: {
        color: "#FFF",
        // backgroundColor: "#007D7A",
        fontWeight: "bold" as "bold",
        borderRadius: 0,
        minWidth: "auto",
        padding: "1px 8px",
        margin: 5,
        border: 0,
        // "&:hover": {
        //     color: "#FFF",
        //     backgroundColor: "#007D7A",
        //     border: 0
        // }
    },
    addButton: {
        color: "#FFF",
        // backgroundColor: "#007D7A",
        fontWeight: "bold" as "bold",
        borderRadius: '50%',
        width: "30px",
        height: "30px",
        minWidth: "30px",
        minHeight: "30px",
        fontSize: "30px",
        lineHeight: "40px",
        padding: "1px 8px",
        margin: 5,
        border: 0,
        // "&:hover": {
        //     color: "#FFF",
        //     backgroundColor: "#007D7A",
        //     border: 0
        // }
    }
});

const EditableAbsolute: React.FC<IEditableAbsolute> = (props) => {

    const { data, position, buttonText, changed, children, fullHeight, hideEdit, hideOnExport } = props;
    const [left, top] = position ?? [0, 0];

    const classes = useStyles();

    const menu = useSelector((state: AppState) => state.menu);
    const dispatch = useDispatch();

    let allProducts = true;
    let emptyElements = 0;
    if(menu?.menu && menu?.menu.length > 0 && (menu?.menuData?.styleId === 43 || menu?.menuData?.styleId === 44)){
        let menuItems = menu?.menu[0]?.props?.items;
        let floatingProductPanelItems = menuItems.filter((row: {component: string}) => row?.component === "FloatingProductPanel");
        floatingProductPanelItems.forEach((element: {props: {products: string[]}}, index: number) => {
            if(element?.props?.products && element?.props?.products.length > 0){
                if(!element?.props?.products[0]){
                    allProducts = false;
                    emptyElements += 1;
                }
            }
        });
    }

    return(
        <IsExporting>
            {(exporting: boolean) => {
                return (
                    <>
                        {/* This divider is required as we can't be sure what the child looks like */}
                        <div id={ data.id}  style={{
                            visibility: (exporting && hideOnExport) ? "hidden" : "unset"
                        }}>{children}</div>
                        {(!exporting && !hideEdit && position) && (
                            <div className={`editableAbsoluteWrapper ${classes.editWrapper} `} style={{
                                position: "absolute",
                                left: `${left}%`,
                                top: `${top}%`
                            }}>
                                {
                                    allProducts ?
                                    <Button className={classes.editButton} variant="text" onClick={() => { 
                                        dispatch(menuActions.selectSection(data.id));
                                        dispatch(menuActions.openModal(data));                                    
                                    }} id={`editModalButton-${data.id}`}>
                                        <img src={Pencil} alt="Edit" style={{width: "42px", height: "42px"}}/>
                                    </Button>
                                    :
                                    data?.initialValues?.products && data?.initialValues?.products.length > 0 ?
                                        !data?.initialValues?.products[0] ?
                                        <Button className={classes.addButton} variant="text" onClick={() => { 
                                            dispatch(menuActions.selectSection(data.id));
                                            dispatch(menuActions.openModal(data));                                    
                                        }} id={`editModalButton-${data.id}`}>
                                            <img src={Plus} alt="Add" style={{width: "42px", height: "42px"}}/>
                                        </Button>
                                        :
                                        <Button className={classes.editButton} variant="text" onClick={() => { 
                                            dispatch(menuActions.selectSection(data.id));
                                            dispatch(menuActions.openModal(data));                                    
                                        }} id={`editModalButton-${data.id}`}>
                                               <img src={Pencil} alt="Edit" style={{width: "42px", height: "42px"}}/>
                                        </Button>
                                    :
                                    <Button className={classes.editButton} variant="text" onClick={() => { 
                                        dispatch(menuActions.selectSection(data.id));
                                        dispatch(menuActions.openModal(data));                                    
                                    }} id={`editModalButton-${data.id}`}>
                                         <img src={Pencil} alt="Edit" style={{width: "42px", height: "42px"}}/>
                                    </Button>
                                }
                            </div>
                        )}
                    </>
                )
            }}
        </IsExporting>
    )
}

export default EditableAbsolute;
import { Dialog } from "@material-ui/core";
import React, { CSSProperties } from "react";

interface ICalculator{
    onClose: () => void
    open: boolean,
    menuId: number,
    onClickCopyLayout: (price: number) => void 
}

interface ICalculatorState {
    columns: IColumnState[]
    dealPrice: number
}

interface IColumnState {
    applicationVatRate: number,
    costPerCase: number,
    unitsPerCase: number,
    unitRetailPriceIncVat: number,
    title: string
}

const divStyles: React.CSSProperties = {
    height: 30,
    padding: 5,
    textAlign: "right",
    margin: 0,
    boxSizing: "border-box",
    fontSize: 16,
    width: "100%",
    backgroundColor: "#e8e6e7"
}

const inputStyles: CSSProperties = {
    ...divStyles,
    backgroundColor: "orange",
    border: "none",
}

const subtitleStyles = {
    ...divStyles,
    width: 250,
    backgroundColor: "transparent"
}

const hrStyles: CSSProperties = {
    width: "100%",
    borderRight: "none",
    borderTop: "none",
    borderLeft: "none",
    borderBottom: "3px #d7d5d6 solid",
}

const longHr: CSSProperties = {
    ...hrStyles,
    width: "1000%"
}

const buttonStyles: CSSProperties = {
    backgroundColor: "#007c7a",
    border: "none",
    fontWeight: "bold",
    textAlign: "center",
    color: "white",
    height: 35,
    width: 130,
    margin: "0 10px 10px 10px",
    fontFamily: "inherit"
}

const summaryCellStyles: CSSProperties = {
    display: "inline-block",
    width: 90,
    textAlign: "right",
    marginRight: 10
}

const Calculator: React.FC<ICalculator> = ({ onClose, open, menuId, onClickCopyLayout }) => {

    /*
        src/components/printPal/PrintPalPopup.tsx has similar modal example
    */

    return (
        <Dialog open={open} maxWidth="lg"> <MealDealCalculator menuId={menuId} onClose={onClose} onClickCopyToLayout={onClickCopyLayout} /></Dialog>
    )
}

function MealDealCalculator(props: {menuId: number, onClose: () => void, onClickCopyToLayout: (price: number) => void }) {
    
    const headers = ["DRINK 1", "DRINK 2", "DRINK 3", "SNACK 1", "SNACK 2", "SNACK 3", "SNACK 4", "MAIN 1", "MAIN 2"]

    const [dealPrice, setDealPrice] = React.useState<number>(0);
 
    const [ls, setLs] = React.useState<Storage>();

    const defaultState = () => {
        return {
            dealPrice: 0.00,
            columns: headers.map(header => {
                return {
                    title: header,
                    applicationVatRate: 1.2,
                    costPerCase: NaN,
                    unitsPerCase: NaN,
                    unitRetailPriceIncVat: NaN
                }
            })
        }
    }

    React.useEffect(() => {
        setLs(window.localStorage);
    }, []); 

    React.useEffect(() => {
        updateParentState();
    }, [dealPrice]);

    React.useEffect(() => {
        if (!readCalculatorStateFromStorage()) { // If no state was found but a default one in
            setCalculatorState(defaultState())
        }
    }, [ls]);

    const [calculatorState, setCalculatorState] = React.useState<ICalculatorState>();

    function updateParentState(index: number | undefined = undefined, value: IColumnState | undefined = undefined) {  
        if (calculatorState === undefined) return;      
        const clone = {...calculatorState};

        if (index !== undefined && value !== undefined) {
            clone.columns[index] = value;
        }
        
        clone.dealPrice = dealPrice;    
        setCalculatorState(clone);
        writeCalculatorStateToStorage(clone);
    }

    function writeCalculatorStateToStorage(state: ICalculatorState) {
        if (ls === undefined)
            return;
        ls.setItem(`calculator${props.menuId}`, JSON.stringify(state));        
    }

    function readCalculatorStateFromStorage(): boolean {
        if (ls === undefined)
            return false;
        const state = ls.getItem(`calculator${props.menuId}`);    
        if (state !== null) {
            const storedObject = JSON.parse(state); // NaNs get stringified into nulls so we need to put those back
            for (const column of storedObject.columns) {
                for (const key in column) {
                    if (column[key] === null) {
                        column[key] = NaN;
                    }
                }
            }
            setDealPrice(storedObject.dealPrice);
            setCalculatorState(storedObject);
            return true;
        }
        return false;
        // There was no stored data;
    }

    function clearAll() {
        const newState = defaultState();
        setCalculatorState(newState);
        writeCalculatorStateToStorage(newState);
        setDealPrice(newState.dealPrice);
    }

    function onClickCopyToLayout() {
        props.onClickCopyToLayout(dealPrice);
        props.onClose();
    }

    if (calculatorState === undefined)
        return null; /// Some sort of loading spinner here

    let sigmaRetailPrice = 0;
    let sigmaRetailPriceMains = 0;    
    let sigmaCostPerUnitIncVat = 0;
    let sigmaCostPerUnitMains = 0;    

    calculatorState.columns.forEach(column => {
        if (!isNaN(column.unitRetailPriceIncVat)) {
            const a = column.unitRetailPriceIncVat;
            sigmaRetailPrice += a;
            if (column.title.startsWith("MAIN")) {
                sigmaRetailPriceMains += a; 
            }

            const b = (column.costPerCase * column.applicationVatRate) / column.unitsPerCase;            
            sigmaCostPerUnitIncVat += b;
            if (column.title.startsWith("MAIN")) {
                sigmaCostPerUnitMains += b; 
            }            
        }
    });

    return <div style={{margin: 0, padding: 0, overflow: "hidden"}}>
        
        <div style={{display: "flex", justifyContent: "space-between"}}>
            <b style={{fontSize: 30}}>CALCULATOR</b>
            <div style={{
                backgroundColor: "#007c7a",
                borderRadius: "50%",
                width: 30,
                height: 30,
                lineHeight: "24px",
                fontSize: 35,
                marginBottom: 5,
                textAlign: "center",
                color: "white",
                transform: "rotate(45deg)",
                cursor: "pointer"
            }}
                onClick={props.onClose}
            >+</div>
        </div>
        <span style={{ fontSize: 16, marginTop: 8, marginBottom: 12, display: "block" }}>Use the Meal Deal Calculator to assist in choosing the ideal price point for the deal.  Complete the Yellow boxes for those items which are part of the deal.</span>
        <div style={{display: "flex"}}>
            <div style={{display: "flex", flexDirection: "column"}}>
                <div style={{height: 50}}></div>
                <div style={subtitleStyles}><b>Applicable VAT Rate:</b></div>
                <hr style={longHr}/>
                <div style={subtitleStyles}><b>Cost Per Case (ex Vat) &#163;</b></div>
                <div style={subtitleStyles}>Cost Per Case (inc Vat) &#163;</div>
                <div style={subtitleStyles}>Cost Per unit (inc Vat) &#163;</div>
                <hr style={longHr}/>

                <div style={subtitleStyles}><b>Units per case</b></div>
                <hr style={longHr}/>

                <div style={subtitleStyles}><b>Unit Retail Price (inc VAT) &#163;</b></div>
                <div style={subtitleStyles}>POR %</div>
                <div style={subtitleStyles}>Meal Deal Unit Split (inv VAT) &#163;</div>
                <div style={subtitleStyles}>POR% as part of Meal Deal</div>

            </div>
            <div style={{display: "flex"}}>
                {calculatorState.columns.map((column, index) => <Column
                    {...column}
                    update={(state) => updateParentState(index, state)}
                    sigmaRetailPrice={sigmaRetailPrice}
                    dealPrice={dealPrice}
                    key={column.title}

                />)}
            </div>
        </div>
        
        <hr style={{...hrStyles, marginTop: -1, marginBottom: 20}}/>
        
        <div style={{display: "flex", justifyContent: "center"}}>
            <div>
                <span style={{ display: "block", height: 30, width: 300 }}></span>
                <span style={{ ...summaryCellStyles, marginBottom: 10 }}>Full Price &#163;</span><span style={{ ...divStyles, width: 100, textAlign: "right", display: "inline-block" }}>{(sigmaRetailPrice).toFixed(2)}</span>
                <br />
                <span style={summaryCellStyles}>Deal Price &#163;</span><FixedInput style={{ ...inputStyles, width: 100 }} onChange={(v) => setDealPrice(v)} value={dealPrice} />
            </div>

            <div>
                <b style={{ fontSize: 20, display: "inline-block", height: 30 }}>Profit Analysis</b><span> if customer buys</span><br />
                <span style={{ ...summaryCellStyles, marginBottom: 10 }}>Main Alone &#163;</span><div style={{ ...divStyles, display: "inline-block", width: 100 }}>{(sigmaRetailPriceMains - sigmaCostPerUnitMains).toFixed(2)}</div><br />
                <span style={summaryCellStyles}>Meal Deal &#163;</span><div style={{ ...divStyles, display: "inline-block", width: 100 }}>{(dealPrice - sigmaCostPerUnitIncVat).toFixed(2)}</div>
            </div>
           
        </div>
        <div style={{display: "flex", justifyContent: "space-between"}}>
            <button style={buttonStyles} onClick={() => clearAll()}>CLEAR ALL</button>
            <button style={buttonStyles} onClick={onClickCopyToLayout}>COPY TO LAYOUT</button>
        </div>
    </div>
}

function Column(props: IColumnState & {update: (newState: IColumnState) => void, sigmaRetailPrice: number, dealPrice: number}) {

    const costPerCaseIncVat = props.costPerCase * props.applicationVatRate;
    const costPerUnitIncVat = costPerCaseIncVat / props.unitsPerCase;

    let POR = NaN;
    let mealDealUnitSplit = NaN;
    let PORasPartOfMealDeal = NaN;

    if (!isNaN(props.unitRetailPriceIncVat)) {
        POR = props.unitRetailPriceIncVat / costPerUnitIncVat - 1;
        mealDealUnitSplit = props.unitRetailPriceIncVat * props.dealPrice / props.sigmaRetailPrice;
        PORasPartOfMealDeal = (mealDealUnitSplit / costPerUnitIncVat) - 1;
    }
    
    return <div style={{display: "flex", flexDirection: "column", backgroundColor: "#e8e6e7", margin: "0 5px 0 5px", minWidth: 80}}>
        <div style={{textAlign: "center", height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>
            <b>{props.title.split(" ")[0]}<br/>{props.title.split(" ")[1]}</b>
        </div>

        <select value={props.applicationVatRate}
            style={inputStyles}
            onChange={(e) => props.update({...props, applicationVatRate: parseFloat(e.target.value)})}>
            <option value={1.05}>5%</option>
            <option value={1.125}>12.5%</option>
            <option value={1.2}>20%</option>
        </select>

        <hr style={ hrStyles}/>
        <FixedInput style={inputStyles} onChange={(v) => props.update({...props, costPerCase: v})} value={props.costPerCase}/>

        <div style={divStyles}>{nullIfNaN(costPerCaseIncVat.toFixed(2))}</div>
        <div style={divStyles}>{nullIfNaN(costPerUnitIncVat.toFixed(2))}</div>

        <hr style={ hrStyles}/>

        <FixedInput style={{...inputStyles, textAlign: "center"}} onChange={(v) => props.update({...props, unitsPerCase: v})} value={props.unitsPerCase} precison={0}/>

        <hr style={ hrStyles}/>

        <FixedInput style={inputStyles} onChange={(v) => props.update({...props, unitRetailPriceIncVat: v})} value={props.unitRetailPriceIncVat}/>        

        <div style={divStyles}>{nullIfNaN((POR * 100).toFixed(2), "%")}</div>
        <div style={divStyles}>{nullIfNaN(mealDealUnitSplit.toFixed(2))}</div>
        <div style={divStyles}>{nullIfNaN((PORasPartOfMealDeal * 100).toFixed(2), "%")}</div>
    </div>
}

function nullIfNaN(value: string, suffix: string | undefined = undefined): string | number | null {
    if (value === "NaN") {
        return null; 
    } else {
        return value + (suffix ?? "");
    }
}

function FixedInput(props: {value: number, onChange: (v: number) => void, style: React.CSSProperties, precison: number}) {
    const [editing, setEditing] = React.useState(false);
    const [workingValue, setWorkingValue] = React.useState(generateEmpty());    

    function handleChange(v: string) {
        setWorkingValue(v);
    }

    function onBlur() {
        setEditing(false);
        props.onChange(parseFloat(workingValue));
    }

    function onFocus(e: HTMLInputElement) {
        e.select();
        setEditing(true);
        setWorkingValue(props.value.toFixed(props.precison));
    }

    function generateEmpty() {
        return "0" + (props.precison === 0 ? "" : ".".padEnd(props.precison + 1, "0"))
    }
    
    let displayedValue;
    if (props.value === null) {
        displayedValue = "";
    }
    else if (isNaN(props.value)) {
        displayedValue = "";
    } else {
        displayedValue = props.value.toFixed(props.precison)
    }

    return <input
        style={props.style}
        type="text"
        onFocus={(e) => onFocus(e.target)}
        onBlur={() => onBlur()}
        value={editing ? workingValue === "NaN" ? "" : workingValue : displayedValue}
        onChange={(e) => handleChange(e.target.value)}
    />
}

FixedInput.defaultProps = {
    precison: 2
}

export default Calculator;
import { withStyles } from "@material-ui/core";

const StyleInjector = (styles: any, styleKey: string, withTheme: boolean = false) => (Component: any) => {
    const stylesWrapper = (theme: any) => {
        if (!theme[styleKey]) {
            console.error(`Theme doesn't contain key ${styleKey}`)
            return styles;
        }

        var allStyles = {} as any;

        Object.keys(styles).forEach((key: string) => {
            allStyles[key] = { ...styles[key] };
        })

        Object.keys(theme[styleKey]).forEach((key: string) => {
            //Only merge if it's an object
            var style = theme[styleKey][key];

            //It could be something else like a switch or prop function
            if (style instanceof Object)
                allStyles[key] = { ...allStyles[key], ...style }
        })

        return allStyles;
    };

    return withStyles(stylesWrapper, { withTheme })(Component);
};

export default StyleInjector;
import React from "react";
import { Grid, Theme, withStyles, WithStyles, Divider, TextField, FormControl, Link, Select } from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
import NativeSelect from '@material-ui/core/NativeSelect';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from "moment"

import { connect } from "react-redux";
import { AppState } from "../store";
import { IMyMenuState, myMenuActions } from "../store/myMenu";
import { IPrintPalState, printPalActions, printPalOperations } from "../store/printpal";
import { IGlobalState, globalActions } from "../store/global";

const styles = (theme: Theme) => ({
    root: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent"
        },
        '& .Mui-selected': {
            backgroundColor: 'transparent',
            color: '#FF9F1C',
            fontWeight: 700
        },
    },
    header: {
        color: "#666666",
        fontSize: 32,
        fontWeight: 700,
        paddingLeft: 0,
        [theme.breakpoints.down("sm")]: {
            fontSize: 22,
        }
    },
    bold: {
        color: "#666666",
        fontSize: 16,
        fontWeight: 700,
        [theme.breakpoints.down("sm")]: {
            fontSize: 12,
        }
    },
    normal: {
        color: "#666666",
        fontSize: 16,
        fontWeight: 400,
        [theme.breakpoints.down("sm")]: {
            fontSize: 12,
        }
    },
    content: {
        position: "relative" as "relative",
        height: "100%",
        overflow: "hidden",
        paddingLeft: 0,
        paddingTop: 50,
        [theme.breakpoints.down("sm")]: {
            paddingTop: 10,    
        }
    },
    sortUp: {
        content: `url(./assets/printPal/downarrow.svg)`,
        border: 0,
        padding: 0,
        display: "block",
        height: 12,
        width: 21,
        marginTop: 5,
        cursor: "pointer",
        transform: "rotate(180deg)"
    },
    sortDown: {
        content: `url(./assets/printPal/downarrow.svg)`,
        border: 0,
        padding: 0,
        display: "block",
        height: 12,
        width: 21,
        marginTop: 5,
        cursor: "pointer"
    },
    imgContainer: {
        verticalAlign: "middle",
        marginTop: 9,
        marginLeft: 10,
        marginBottom: 6
    },
    filterGridItem: {
        height: "30px",
        display: "flex",
        alignItems: "center"
    },
    sortControl: {
        border: "2px solid",
        backgroundColor: "transparent",
        borderColor: "transparent",
        height: 1,
        alignContent: "center",
        [theme.breakpoints.down("sm")]: {
            marginTop: -3,
        }
    },
    selectEmpty: {
        margin: theme.spacing(1)
    },
    box: {
        border: "2px solid #666666",
        borderRadius: 5,
        fontWeight: 700,
        fontSize: 16,
        height: 52,
        marginTop: 30, 
        marginBottom: 15, 
        width: 530,
        [theme.breakpoints.down("sm")]: {
            fontsize: 12,
            height: 38,
            width: 340,
            marginTop: 0,
        }
    },
    ordersBox: {
        border: "2px solid #666666",
        borderRadius: 5,
        fontWeight: 700,
        fontSize: 16,
        height: 52,
        marginTop: 30, 
        marginBottom: 15, 
        width: 210,
        [theme.breakpoints.down("sm")]: {
            fontsize: 12,
            height: 38,
            width: 170,
            marginTop: 10,
            marginBottom: 10,            
        }
    },
    pageBox: {
        border: "2px solid #666666",
        borderRadius: 5,
        fontWeight: 700,
        fontSize: 16,
        height: 52,
        marginTop: 30, 
        marginBottom: 15, 
        marginLeft: 10, 
        marginRight: 10, 
        width: 90, 
        paddingLeft: 5,
        [theme.breakpoints.down("sm")]: {
            fontsize: 12,
            height: 38,
            width: 80, 
            paddingLeft: 0,
            marginLeft: 10,
            marginTop: 17, 
        }
    },
    sortBox: {
        border: "2px solid #666666",
        borderRadius: 5,
        fontWeight: 700,
        fontSize: 16,
        height: 52,
        marginTop: 0, 
        marginBottom: 15, 
        marginLeft: 10, 
        marginRight: 10, 
        width: 90, 
        paddingLeft: 5,
        [theme.breakpoints.down("sm")]: {
            fontsize: 12,
            height: 38,
            width: 130, 
            paddingLeft: 0,
            marginLeft: 12,
            marginTop: -5, 
        }
    },
    ordersImg: {
        width: 25,
        marginTop: 15, 
        marginBottom: 5, 
        paddingLeft: 10, 
        paddingRight: 5,
        [theme.breakpoints.down("sm")]: {
            width: 20,
            marginTop: 10, 
            marginBottom: 5, 
            paddingLeft: 10, 
            paddingRight: 5,
        } 
    },
    ordersContainer: {
        marginTop: 20, 
        marginLeft: 10,
        [theme.breakpoints.down("sm")]: {
            marginLeft: 0,
            marginTop: 10,
        }
    },
    findImg: {
        width: 25,
        marginTop: 15, 
        marginBottom: 5, 
        paddingLeft: 10, 
        paddingRight: 5,
        [theme.breakpoints.down("sm")]: {
            width: 20,
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: 10,
        }
    },
    findControl: {
        width: 470, 
        paddingLeft: 10,
        [theme.breakpoints.down("sm")]: {
            width: 310, 
            paddingLeft: 0,
            marginTop: 2
        }
    },
    history: {
        borderBottom: "1px solid #666666", 
        marginBottom: 10, 
        paddingTop: 40, 
        paddingBottom: 20,
        [theme.breakpoints.down("sm")]: {
            paddingTop: 10, 
        }
    },
    pagination: {
        marginBottom: 50,
        [theme.breakpoints.down("sm")]: {
            marginBottom: 20,
            marginTop: 10
        }
    }
});

interface IProps {
    myMenu: IMyMenuState;
    printPal: IPrintPalState;
    global: IGlobalState;
    showNavigation: any;
    printPalPage: any;
    getOrders: any;
    checkout: any;
    setStatus: any;
}

interface IOrderHistoryState {
    price: string;
    paper: string;
    sortStatusOption: string;
    sortColumn: string;
    ordersPerPageOption: string;
    sortAsc: {
        orderNumber: boolean;
        quantity: boolean;
        cost: boolean;
        orderDate: boolean;
        status: boolean;
        deliverTo: boolean;
    },
    orders: any[],
    showing: {
        firstOrder: number;
        lastOrder: number;
        totalOrders: number;
    }
    currentPage: number;
    totalPages: number;
    startIndex: number;
    endIndex: number;
    user: any;
    isMobileApp: boolean;
    sortDirection: number;
}

const DEFAULT_ORDERS_PER_PAGE: string = "10";

class OrderHistory extends React.Component<WithStyles & IProps, IOrderHistoryState> {

    private findRef: any;
    private statusRef: any;
    private ordersPerPageRef: any;
    private sortHistoryRef: any;
    private sortDirectionRef: any;
    private orders: any[] = [];
    private statuses: any[] = [];
    private canEdit: boolean = false;
    private mounted: boolean = false;

    constructor(props: any) {
        super(props);

        this.state = {
            price: "0.00",
            paper: "1",
            sortStatusOption: "1",
            sortColumn: "",
            ordersPerPageOption: DEFAULT_ORDERS_PER_PAGE,
            sortAsc: {
                orderNumber: false,
                quantity: true,
                cost: true,
                orderDate: true,
                status: true,
                deliverTo: true,
            },
            orders: [],
            showing: {
                firstOrder: 0,
                lastOrder: 0,
                totalOrders: 0
            },
            currentPage: 1,
            totalPages: 0,
            endIndex: Number(DEFAULT_ORDERS_PER_PAGE),
            startIndex: 0,
            user: {},
            isMobileApp: false,
            sortDirection: 1
        };

        this.props.printPalPage(true);
        this.props.showNavigation(false);
        this.props.checkout(false);

        this.sortData = this.sortData.bind(this);
    }

    componentDidMount() {
        this.props.getOrders();
        this.mounted = true;
        this.setResponsiveness();
        window.addEventListener("resize", () => this.setResponsiveness());
    }

    componentWillUnmount() {
        this.mounted = false;
        window.removeEventListener('resize', () => this.setResponsiveness());
    }

    setResponsiveness = () => {
        if (this.mounted)
            return window.innerWidth < 1200
            ? this.setState((prevState) => ({ ...prevState, isMobileApp: true }))
            : this.setState((prevState) => ({ ...prevState, isMobileApp: false }));
    };

    componentWillReceiveProps(nextProps: any) {
        const { printPal } = nextProps;
        const { orderData, loading } = printPal;

        if (!loading) {
            this.setState({ orders: orderData.orders },
                () => {
                    this.orders = orderData.orders.map((obj: any) => ({ ...obj }));
                    this.statuses = orderData.statuses.map((obj: any) => ({ ...obj }));
                    this.canEdit = orderData.canEdit;
                    this.setTotalPages();
                    this.setShowingText(); 
            });
        }
    }

    setTotalPages = () => {
        var ordersPerPage = Number(this.state.ordersPerPageOption);

        var totalPages: number = 0;
        for (let i = 1; i <= Math.ceil(this.state.orders.length / ordersPerPage); i++) {
            totalPages++;
        }

        this.setState({ totalPages: totalPages });
    }

    setShowingText = () => {
        this.setState(prevState => {
            let showing = Object.assign({}, prevState.showing);
            showing.firstOrder = this.state.startIndex + 1;
            showing.lastOrder = this.state.endIndex > this.orders.length ? this.orders.length : this.state.endIndex;
            showing.totalOrders = this.orders.length;
            return { showing };
        });
    }

    setSortState = (sortColumn: string) => {
        this.setState({ sortColumn: sortColumn });

        this.setState(prevState => {
            var sortAsc = Object.assign({}, prevState.sortAsc);            
            sortAsc.orderNumber = sortColumn === "orderNumber" ? !prevState.sortAsc.orderNumber : true;
            sortAsc.quantity = sortColumn === "quantity" ? !prevState.sortAsc.quantity : true;
            sortAsc.cost = sortColumn === "cost" ? !prevState.sortAsc.cost : true;
            sortAsc.orderDate = sortColumn === "orderDate" ? !prevState.sortAsc.orderDate : true;
            sortAsc.status = sortColumn === "status" ? !prevState.sortAsc.status : true;
            sortAsc.deliverTo = sortColumn === "deliverTo" ? !prevState.sortAsc.deliverTo : true;
            return { sortAsc };
        });

        if (this.state.sortAsc.orderNumber && this.state.sortAsc.quantity && this.state.sortAsc.cost && 
            this.state.sortAsc.orderDate && this.state.sortAsc.status && this.state.sortAsc.deliverTo)
            this.setState({ sortDirection: 1 });
        else
            this.setState({ sortDirection: 2 });

        this.sortData(sortColumn);
    }

    sortData = (sortColumn: string) => {

        switch (sortColumn) {
            case "orderNumber": {
                if (this.state.sortAsc.orderNumber)
                    this.state.orders.sort((a: any, b: any) => {
                        if (a.orderNumber > b.orderNumber) {
                            return 1;
                        } else if (a.orderNumber < b.orderNumber) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
                else
                    this.state.orders.sort((a: any, b: any) => {
                        if (a.orderNumber < b.orderNumber) {
                            return 1;
                        } else if (a.orderNumber > b.orderNumber) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
                break;
            }
            case "quantity": {
                if (this.state.sortAsc.quantity)
                    this.state.orders.sort((a: any, b: any) => {
                        if (a.quantity > b.quantity) {
                            return 1;
                        } else if (a.quantity < b.quantity) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
                else
                    this.state.orders.sort((a: any, b: any) => {
                        if (a.quantity < b.quantity) {
                            return 1;
                        } else if (a.quantity > b.quantity) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
                break;
            }
            case "cost": {
                if (this.state.sortAsc.cost)
                    this.state.orders.sort((a: any, b: any) => {
                        if (a.cost > b.cost) {
                            return 1;
                        } else if (a.cost < b.cost) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
                else
                    this.state.orders.sort((a: any, b: any) => {
                        if (a.cost < b.cost) {
                            return 1;
                        } else if (a.cost > b.cost) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
                break;
            }
            case "orderDate": {
                if (this.state.sortAsc.orderDate)
                    this.state.orders.sort((a: any, b: any) => {
                        if (a.orderDate > b.orderDate) {
                            return 1;
                        } else if (a.orderDate < b.orderDate) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
                else
                    this.state.orders.sort((a: any, b: any) => {
                        if (a.orderDate < b.orderDate) {
                            return 1;
                        } else if (a.orderDate > b.orderDate) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
                break;
            }
            case "status": {
                if (this.state.sortAsc.status)
                    this.state.orders.sort((a: any, b: any) => {
                        if (a.status > b.status) {
                            return 1;
                        } else if (a.status < b.status) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
                else
                    this.state.orders.sort((a: any, b: any) => {
                        if (a.status < b.status) {
                            return 1;
                        } else if (a.status > b.status) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
                break;
            }
            case "deliverTo": {
                if (this.state.sortAsc.deliverTo)
                    this.state.orders.sort((a: any, b: any) => {
                        if (a.name > b.name) {
                            return 1;
                        } else if (a.name < b.name) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
                else
                    this.state.orders.sort((a: any, b: any) => {
                        if (a.name < b.name) {
                            return 1;
                        } else if (a.name > b.name) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
                break;
            }
        }
    }

    handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        this.filterData();
        this.setState({
            currentPage: value,
            endIndex: value * Number(this.state.ordersPerPageOption),
            startIndex: (value * Number(this.state.ordersPerPageOption)) - Number(this.state.ordersPerPageOption),
        }, () => {
            this.setShowingText();
        });
    }

    handleSort = (event: any) => {
        this.setSortState(event.currentTarget.id);
    }

    handleSortDirectionOptionChange = () => {
        this.setSortState(this.sortHistoryRef.value);
    }

    handleSortHistoryOptionChange = () => {
        this.setSortState(this.sortHistoryRef.value);
    }

    handleSortStatusOptionChange = () => {
        this.filterData();
    }

    handleOrdersPerPageOptionChange = () => {
        this.filterData();
    }

    handleFindChanged = () => {
        this.filterData();
    }

    handleOrderStatus = (ev: any, orderId: number) => {
        this.props.setStatus(orderId, parseInt(ev.target.value));
    }

    filterData = () => {
        var data = this.orders.map((obj: any) => ({ ...obj }));

        var find: string = this.findRef.value.toUpperCase();
        /*
            Status is not currently being used in any ordering and is not
            present in the table so these lines have been commented out 
            in case it is an ongoing feature. 
        */
        // var statusFilter: string = this.statusRef.value;
        var ordersPerPage: string = this.ordersPerPageRef.value;

        if (find.length > 0)
            data = data.filter(x => x.orderNumber.toUpperCase() === find
                || x.name.toUpperCase().includes(find)
                || x.address.toUpperCase().includes(find));

        // if (statusFilter !== "All orders")
        //     data = data.filter(x => x.status == statusFilter);

        this.setState({
            ...this.state,
            currentPage: 1,
            startIndex: 0,
            endIndex: Number(ordersPerPage),
            ordersPerPageOption: ordersPerPage,
            // sortStatusOption: statusFilter,
            orders: data.map((obj: any) => ({ ...obj }))
        }, () => {
            this.setTotalPages();
            this.setShowingText();
        });
    }



    render() {
        const { classes, printPal } = this.props;
        const { loading } = printPal;

        //debugger

        return (
            <div style={{ minHeight: 'calc(100vh - 210px)' }}>

                {loading
                    ?
                    <Grid container justify="center">
                        <div style={{ display: "flex",marginTop:"20px" }} >
                            <CircularProgress color="primary" />
                        </div>
                    </Grid>
                    :
                    <Grid container>
                        <Grid item xs={12}>
                            <div className={classes.content}>
                                <div className={classes.header}>Order history</div>
                            </div>
                        </Grid>

                        <Grid container alignItems="center" className={classes.ordersContainer}>
                           

                            <Grid item xs={this.state.isMobileApp ? 12 : 5}>
                                <div className={classes.box}>
                                    <TextField
                                        onChange={this.handleFindChanged}
                                        size={this.state.isMobileApp ? "small" : "medium"}
                                        defaultValue=""
                                        className={`${classes.root} ${classes.findControl} ${classes.normal}`}
                                        variant="outlined"
                                        placeholder="Search by order number or part of delivery address"
                                        inputRef={ref => { this.findRef = ref; }}
                                        InputProps={{ classes: { input: classes.normal, },}}/>
                                    <img src={`./assets/printPal/find.svg`} alt="find" className={classes.findImg} />
                                </div>
                            </Grid>

                            <Grid item xs={this.state.isMobileApp ? 12 : 2}>
                                <div style={{ paddingTop: this.state.isMobileApp ?0 : 10 }}>
                                    <span className={classes.normal}>Showing </span> 
                                    <span className={classes.bold}>{this.state.showing.firstOrder}-{this.state.showing.lastOrder}</span>
                                    <span className={classes.normal}> of </span><span className={classes.bold}>{this.state.showing.totalOrders}</span>
                                    <span className={classes.normal}> orders</span></div>
                            </Grid>

                            <Grid item xs={this.state.isMobileApp ? 12 : 2}>
                                <Grid container alignItems="center">
                                    <div style={{ marginTop: this.state.isMobileApp ? -5 : 10 }}><span className={classes.normal}>Show </span></div>
                                    <div className={classes.pageBox}>
                                        <FormControl variant="outlined" className={classes.sortControl} style={{ minWidth: 80 }}>
                                            <NativeSelect
                                                defaultValue={this.state.ordersPerPageOption}
                                                disableUnderline={true}
                                                onChange={this.handleOrdersPerPageOptionChange}
                                                name="sortPageOption"
                                                className={`${classes.selectEmpty} ${classes.normal}`}
                                                IconComponent={props => (
                                                    <i {...props} className={`material-icons ${props.className}`}>
                                                        <img className={classes.sortDown} alt="sort descending" />
                                                    </i>
                                                )}
                                                inputRef={ref => { this.ordersPerPageRef = ref; }}
                                                inputProps={{ 'aria-label': 'ordersPerPageOption' }}>
                                                <option value={DEFAULT_ORDERS_PER_PAGE}>{DEFAULT_ORDERS_PER_PAGE}</option>
                                                <option value={20}>20</option>
                                                <option value={30}>30</option>
                                            </NativeSelect>
                                        </FormControl>
                                    </div>
                                    <div style={{ marginTop: this.state.isMobileApp ? -5 : 10 }}><span className={classes.normal}> per page</span></div>
                                </Grid>
                            </Grid>
                        </Grid>

                        {
                        this.state.isMobileApp
                        ? 
                        <>                       
                        <Grid item xs={12}>
                            <div style={{ display: "flex" }}>
                                <Grid container>
                                    <div style={{ marginTop: 5, marginRight: 1}}><span className={classes.normal}>Sort: </span></div>
                                    <div className={classes.sortBox}>
                                        <FormControl variant="outlined" className={classes.sortControl}>
                                            <NativeSelect
                                                disableUnderline={true}
                                                onChange={this.handleSortHistoryOptionChange}
                                                name="sortHistoryOption"
                                                value={this.state.sortColumn}
                                                className={`${classes.selectEmpty} ${classes.normal}`}
                                                IconComponent={props => (
                                                    <i {...props} className={`material-icons ${props.className}`}>
                                                        <img className={classes.sortDown} alt="sort descending" />
                                                    </i>
                                                )}
                                                inputRef={ref => { this.sortHistoryRef = ref; }}
                                                inputProps={{ 'aria-label': 'sortHistoryOption' }}>
                                                <option value="orderNumber">Order number</option>
                                                <option value="quantity">Qty</option>
                                                <option value="cost">Cost</option>
                                                <option value="orderDate">Order date</option>
                                                <option value="status">Status</option>
                                                <option value="deliverTo">Deliver to</option>
                                            </NativeSelect>
                                        </FormControl>
                                    </div>
                                    <div className={classes.sortBox}>
                                        <FormControl variant="outlined" className={classes.sortControl}>
                                            <NativeSelect
                                                disableUnderline={true}
                                                onChange={this.handleSortDirectionOptionChange}
                                                name="sortDirectionOption"
                                                value={this.state.sortDirection}
                                                className={`${classes.selectEmpty} ${classes.normal}`}
                                                IconComponent={props => (
                                                    <i {...props} className={`material-icons ${props.className}`}>
                                                        <img className={classes.sortDown} alt="sort descending" />
                                                    </i>
                                                )}
                                                inputRef={ref => { this.sortDirectionRef = ref; }}
                                                inputProps={{ 'aria-label': 'sortDirectionOption' }}>
                                                <option value={1}>Ascending</option>
                                                <option value={2}>Descending</option>
                                            </NativeSelect>
                                        </FormControl>
                                    </div>
                                </Grid>
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <Divider style={{ backgroundColor : "#666666", marginBottom: 10 }}/> 
                        </Grid> 

                        {this.state.orders.slice(this.state.startIndex, this.state.endIndex).map((order, index) => (                     
                            <Grid container className={classes.cardContainer} style={index % 2 ? { background: "rgba(242, 242, 242, 1)" } : { background: "#FFF" }} key={index}>
                                <Grid container style={{ paddingTop: 3, paddingBottom: 4 }}>
                                    <Grid container justify="flex-start" item xs={2}>
                                        <Grid item>
                                            <Grid item xs={6}>
                                                <img src={`./assets/styles/preview/${order.imgUrl}`}  alt="preview menu" width="45px" height="80px" style={{margin: 3}}/>
                                            </Grid>
                                            <Grid item xs={6}>
                                                {order.snapshotFileId > 0 && <Link className={classes.link} href={`./api/Files/${order.snapshotFileId}`} download={order.orderNumber}>
                                                    <img src={`./assets/printPal/download_pdf.svg`} alt="download pdf" width="20px" height="24px" style={{ paddingLeft: 17 }} />
                                                </Link>}
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={10}>
                                        <Grid container>
                                            <Grid item xs={4}>
                                                <span key={order.id} className={classes.normal}>Order number:</span>
                                            </Grid>   
                                            <Grid item xs={8}>
                                                <span key={order.id} className={classes.bold}>{order.orderNumber}</span>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={4}>
                                                <span key={order.id} className={classes.normal}>Qty:</span>
                                            </Grid>   
                                            <Grid item xs={8}>
                                                <span key={order.id} className={classes.normal}>{order.quantity}</span>
                                            </Grid>  
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={4}>
                                                <span key={order.id} className={classes.normal}>Cost:</span>
                                            </Grid>   
                                            <Grid item xs={8}>
                                                <span key={order.id} className={classes.normal}>{new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP" }).format(order.cost)}</span>
                                            </Grid>                                
                                        </Grid>    
                                        <Grid container>    
                                            <Grid item xs={4}>
                                                <span key={order.id} className={classes.normal}>Order date:</span>
                                            </Grid>   
                                            <Grid item xs={8}>
                                                <span key={order.id} className={classes.normal}>{moment(order.orderDate).format("DD/MM/YY HH:mm")}</span>
                                            </Grid>  
                                        </Grid> 
                                        <Grid container>
                                            <Grid item xs={4}>
                                                <span key={order.id} className={classes.normal}>Status:</span>
                                            </Grid>   
                                            <Grid item xs={8}>
                                                <span key={order.id} className={classes.normal}>{order.status}</span>
                                            </Grid>                               
                                        </Grid> 
                                        <Grid container>
                                            <Grid item xs={4}>
                                                <span key={order.id} className={classes.normal}>Deliver to:</span>
                                            </Grid>   
                                            <Grid item xs={8}>
                                                <span key={order.id} className={classes.normal}>{order.name}, {order.address}</span>
                                            </Grid>                                
                                        </Grid>    
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Divider /> 
                                </Grid>
                            </Grid>
                        ))}
                        </>                       
                        :
                        <>
                            <Grid container className={classes.history}>
                                <Grid item xs={1}/>

                                <Grid item xs={1}>
                                    <div style={{ display: "flex" }}>
                                        <span className={classes.gridBold} style={{ paddingRight: 7 }}>Order number</span>
                                        <img id="orderNumber" className={this.state.sortAsc.orderNumber ? classes.sortDown : classes.sortUp} onClick={this.handleSort} alt="sort order number" />
                                    </div>
                                </Grid>

                                <Grid item xs={2}>
                                    <div style={{ display: "flex" }}>
                                        <span className={classes.gridBold} style={{ paddingRight: 7 }}>Name of design</span>
                                    </div>
                                </Grid>

                                <Grid item xs={2}>
                                    <div style={{ display: "flex" }}>
                                        <span className={classes.gridBold} style={{ paddingRight: 7 }}>Order description</span>
                                    </div>
                                </Grid>

                            <Grid item xs={1}>
                                <div style={{ display: "flex" }}>
                                    <span className={classes.gridBold} style={{ paddingRight: 7 }}>Total Cost</span>
                                    <img id="cost" className={this.state.sortAsc.cost ? classes.sortDown : classes.sortUp} onClick={this.handleSort} alt="sort cost" />
                                </div>
                            </Grid>
                            <Grid item xs={3}>
                                    <div style={{ display: "flex" }}>
                                        <span className={classes.gridBold} style={{ paddingRight: 7 }}>Order date</span>
                                        <img id="orderDate" className={this.state.sortAsc.orderDate ? classes.sortDown : classes.sortUp} onClick={this.handleSort} alt="sort order date" />
                                    </div>
                            </Grid>
                            <Grid item xs={this.state.isMobileApp ? 2 : 2}>
                                <div style={{ display: "flex" }}>
                                    <span className={classes.gridBold} style={{ paddingRight: 7 }}>Delivery Address</span>
                                    <img id="deliverTo" className={this.state.sortAsc.deliverTo ? classes.sortDown : classes.sortUp} onClick={this.handleSort} alt="sort deliver to" />
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container style={{ paddingBottom: 20 }}>
                            {this.state.orders.slice(this.state.startIndex, this.state.endIndex).map((order, index) => (
                                <Grid container alignItems={"center"} style={index % 2 ? { background: "rgba(242, 242, 242, 1)" } : { background: "#FFF" }} key={index}>
                                    <Grid item xs={1}>
                                        <div style={{ height: 89, display: "flex" }}>
                                            <img src={order.orderPreviewFile}   alt="preview menu" width="50px" className={classes.imgContainer} />
                                            {/* 
                                            {order.snapshotFileId > 0 && <Link className={classes.link} style={{display: "flex", alignItems: "center", justifyContent: "center", flex: "1"}} href={`./api/Files/${order.snapshotFileId}`} download={order.orderNumber}>
                                                <img src={`./assets/printPal/download_pdf.svg`} alt="download pdf" width="22px" height="26px" />
                                            </Link>}
                                            */}
                                        </div>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <div style={{ display: "flex" }}>
                                            <span key={order.id} className={classes.bold}>{order.orderNumber}</span>
                                        </div>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <div style={{ display: "flex" }}>
                                            <span>{order.menuTitle}</span>
                                        </div>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <div style={{ display: "flex" }}>
                                            <span>{order.orderDescription}</span>
                                        </div>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <div style={{ display: "flex" }}>
                                            <span key={order.id} className={classes.normal}>{new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP" }).format(order.cost)}</span>
                                        </div>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <div style={{ display: "flex" }}>
                                            <span key={order.id} className={classes.normal}>{moment(order.orderDate).format("DD/MM/YYYY HH:mm")}</span>
                                        </div>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <div style={{ display: "flex", marginRight: 10 }}>
                                            <span key={order.id} className={classes.normal}>{order.name}, {order.address}</span>
                                        </div>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                        </>
                        }
                        <Grid container justify="center" className={classes.pagination}>
                            <div className={classes.root}>
                                {
                                this.state.orders.length > 0
                                ?
                                <Pagination count={this.state.totalPages} showFirstButton showLastButton defaultPage={1} siblingCount={1} onChange={this.handlePageChange} />
                                :
                                <span className={classes.normal}>There is no data to display</span>
                                }
                            </div>
                        </Grid>
                    </Grid>
                }
            </div>
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    myMenu: state.myMenu,
    printPal: state.printPal,
    global: state.global,
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        printPalPage: (printPalPage: boolean) => dispatch(myMenuActions.printPalPage(printPalPage)),
        getOrders: () => dispatch(printPalOperations.getOrderHistory()),
        showNavigation: (showNavigation: boolean) => dispatch(globalActions.showNavigation(showNavigation)),
        checkout: (checkout: boolean) => dispatch(printPalActions.checkout(checkout)),
        setStatus: (orderId: number, statusId: number) => dispatch(printPalOperations.setStatus(orderId, statusId))
    }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(OrderHistory));
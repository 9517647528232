﻿import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { AppState } from "..";
import { getPreviews, getProducts, setMenu, setPreviews, getImageLibrary, setGenerationId, getGins } from "./actions";

const uploadFile = (file: FormData): ThunkAction<any, AppState, null, Action> => async (dispatch: any, getState: () => AppState) => {
    let menuId = getState().menu.menuData?.id;

    return fetch(`/api/Files?menuId=${menuId}`, {
        method: "POST",
        body: file
    })
        .then(response => {
            if (response.status === 200)
                return response.json();
            else
                throw Error();
        })
        .then((response: any) => {
            return response;
        });
}


const getBritvicProducts = (styleId: number): ThunkAction<any, null, null, Action> => async dispatch => {
    return fetch(`/api/Products?styleId=${styleId}`).then(response => {
        if (response.status === 200)
            return response.json();
        else
            throw Error();
    })
        .then(response => {
            dispatch(getProducts(response))
        });
};

export const getProductsFilter = (tagName: string, styleId: number) => {
    return fetch(`/api/Products/filter?tagName=${tagName}&styleId=${styleId}`)
        .then(function(response) {
            if (response.status == 200) {
                return response.json();
            } else {
                console.log(response);
            }
        })
        .catch(function(err) {
            return console.log(err);
        });
};

export const getProductsByTagName = (tagName: string) => {
    return fetch(`/api/Products/tag?name=${tagName}`).then(response => {
        if (response.status == 200)
            return response.json();
        else
            console.log(response);
    }).catch((err) => console.log(err));
}

const getGinProducts = (filter?: string): ThunkAction<any, null, null, Action> => async dispatch => {
    return fetch(filter ? `/api/Products/Gin?filter=${filter}` : "/api/Products/Gin").then(response => {
        if (response.status === 200)
            return response.json();
        else
            throw Error();
    })
        .then(response => {
            dispatch(getGins(response))
        });
}

const getImages = (): ThunkAction<any, null, null, Action> => async dispatch => {
    return fetch("/api/Products/images").then(response => {
        if (response.status === 200)
            return response.json();
        else
            throw Error();
    })
        .then(response => {
            dispatch(getImageLibrary(response))
        });
};

const generatePreviews = (menu: any): ThunkAction<any, null, null, Action> => async dispatch => {
    dispatch(setPreviews(true));

    try {
        const response = await fetch("/api/Export/previews", {
            method: "POST",
            body: JSON.stringify(menu),
            headers: {
                "Content-Type": "application/json"
            }
        })
        if (response.status === 200)
            try {
                //const data = await response.json();
                dispatch(getPreviews())
                //Not used - 7/7/22
                //dispatch(setGenerationId(data.generationId));
                //return data;
            }
            catch (err) {
                throw err;
            }
        else
            throw Error();
    }
    catch (error) {
        dispatch(setPreviews(false));
        return error;
    }
};

const getMenuData = (id: number): ThunkAction<any, null, null, Action<string>> => async dispatch => {
    return fetch(`/api/MyMenus/menu/${id}`)
        .then(response => response.json())
        .then(response => dispatch(setMenu(response)));
}

export default { uploadFile, getBritvicProducts, generatePreviews, getMenuData, getImages, getGinProducts };
import React from "react"
import { withStyles, WithStyles, Grid, Tooltip, IconButton } from "@material-ui/core";
import { ZoomOut, ZoomIn, SettingsBackupRestore } from "@material-ui/icons"

const styles = ({
    wrapper: {
        color: "#007D7A",
        padding: "0 10",
        width: "auto"
    },
    bar: {
        marginLeft: 22,
        marginRight: 22,
        width: 130,
        height: 2,
        backgroundColor: "#007D7A",
        position: "relative" as "relative"
    },
    knob: {
        width: 30,
        height: 30,
        borderRadius: "50%",
        backgroundColor: "#007D7A",
        color: "#FFF",
        position: "absolute" as "absolute",
        transform: "translate(-50%, -50%)",
        top: "50%",
        fontSize: 10,
        textAlign: "center" as "center",
        lineHeight: "32px",
        cursor: "move",
        userSelect: "none" as "none"
    },
    icon: {
        cursor: "pointer" as "pointer"
    }
});

interface IZoomProps {
    defaultZoom: number,
    minZoom: number,
    maxZoom: number,
    onMove?: any,
    onReset: any
}

interface IZoomState {
    moving: boolean;
    zoom: number;
    lastZoom?: number;
}

class Zoom extends React.Component<WithStyles & IZoomProps, IZoomState> {
    constructor(props: any) {
        super(props);

        this.state = {
            moving: false,
            zoom: -1
        }
    }

    componentDidUpdate() {
        const { defaultZoom, minZoom, maxZoom } = this.props;
        const { zoom } = this.state;

        //Catch up to the menu once it's done
        if (zoom === -1)
            this.setState({ zoom: (defaultZoom / (maxZoom - minZoom)) - minZoom });
    }

    update = (newZoom: number) => {
        //Send out
        const { minZoom, maxZoom } = this.props;

        if (this.props.onMove)
            this.props.onMove((newZoom * (maxZoom - minZoom)) + minZoom);
    }

    handleDown = (ev: any) => {
        this.setState({ moving: true });
    }

    handleUp = (ev: any) => {
        this.setState({ moving: false });
        this.update(this.state.zoom);
    }

    handleMove = (ev: any) => {
        if (this.state.moving) {
            var parent = ev.target.parentNode.getBoundingClientRect();

            //Check bounds
            if (ev.clientX > parent.left && ev.clientX < parent.right) {
                var zoom = (ev.clientX - parent.left) / parent.width;
                this.setState({ zoom });
            }
        }
    }

    zoomOut = () => {
        var { zoom } = this.state;
        var { maxZoom, minZoom, defaultZoom } = this.props;
        zoom -= 0.05;

        var midPoint = (defaultZoom / (maxZoom - minZoom)) - minZoom;
        if (zoom >= midPoint - minZoom) {
            this.setState({ zoom });
            this.update(zoom);
        }
    }

    zoomIn = () => {
        var { zoom } = this.state;
        var { maxZoom, minZoom } = this.props;
        zoom += 0.05;

        //max - min is the length of the zoom
        if (zoom <= (maxZoom - minZoom)) {
            this.setState({ zoom });
            this.update(zoom);
        }
    }

    onReset = () => {
        const { defaultZoom, maxZoom, minZoom } = this.props;
        var zoom = (defaultZoom / (maxZoom - minZoom)) - minZoom;
        this.setState({ zoom });

        //Pass on to menu for panning
        this.props.onReset();
    }

    render() {
        const { classes } = this.props;
        const { zoom } = this.state;

        return <React.Fragment>

            <Grid alignItems="center" className={classes.wrapper} container>
                <Grid item>
                    <ZoomOut className={classes.icon} onClick={() => this.zoomOut()} />
                </Grid>

                <Grid item>
                    <div className={classes.bar}>
                        <div onMouseDown={this.handleDown} onMouseLeave={this.handleUp} onMouseMove={this.handleMove} onMouseUp={this.handleUp} className={classes.knob} style={{ left: zoom * 130 }}>
                        </div>
                    </div>
                </Grid>

                <Grid item>
                    <ZoomIn className={classes.icon} onClick={() => this.zoomIn()} />
                </Grid>
            </Grid>
            {
                zoom !== 0.5 && <span onClick={this.onReset} style={{ display: "block", textAlign: "center", color: "#007D7A", cursor: "pointer", userSelect: "none", height: 0 }}>Reset zoom</span>
            }
            
        </React.Fragment>
    }
}

export default withStyles(styles)(Zoom);
import React, { useState, useEffect } from "react";
import { Container, Grid, Box, makeStyles, createStyles, Theme } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";

// Store
import { connect } from "react-redux";
import { AppState } from "../../store";
import { IGlobalState } from "../../store/global";
import { IPrintPalState } from "../../store/printpal";
import { IMenuState } from "../../store/menu";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        bar: {
            height: 53,
            backgroundColor: "#F2F2F2",
            [theme.breakpoints.down("sm")]: {
                height: 30,
            },
        },
        arrow: {
            height: 15
        },
        logout: {
            color: "#FF9F1C",
            fontWeight: 400,
            fontSize: 20,
            paddingLeft: 0,
            marginRight: 30,
            textDecoration:"none",
            [theme.breakpoints.down("sm")]: {
                fontSize: 10,
                paddingLeft: 0,
                marginRight: 10,
            },
            '&:hover': { // hover state
               textDecoration: "underline", // underline on hover
            }
        },
        who: {
            paddingLeft: 5,
            fontWeight: 700,
            fontSize: 16,
            color: "#333333",
            opacity: 1,
            [theme.breakpoints.down("sm")]: {
                fontSize: 10,
            }
        },
        hello: {
            paddingLeft: 10,
            fontWeight: 400,
            fontSize: 16,
            color: "#333333",
            [theme.breakpoints.down("sm")]: {
                fontSize: 10,
                paddingLeft: 0
            }
        },
        helloContainer: {
            paddingTop: 13,
            paddingRight: 50,
            [theme.breakpoints.down("sm")]: {
                paddingLeft: 5,
                paddingTop: 7,
            }
        },
        logoutContainer: {
            paddingTop: 13,
            paddingLeft: 5,
            [theme.breakpoints.down("sm")]: {
                paddingLeft: 0,
                paddingTop: 7,
            }
        }
    }),
);

interface IProps {
    global: IGlobalState,
    printPal: IPrintPalState;
    menu: IMenuState
}

const PrintPalSubHeader = (props: IProps) => {
    const [state, setState] = useState({
        isMobileApp: false
    })

    const { isMobileApp } = state;

    const classes = useStyles();
    const { global, printPal, menu } = props;
    const location = useLocation();

    useEffect(() => {
        let mounted: boolean = false;
        const setResponsiveness = () => {
            if (mounted)
                return window.innerWidth < 600
                    ? setState((prevState) => ({ ...prevState, isMobileApp: true }))
                    : setState((prevState) => ({ ...prevState, isMobileApp: false }));
        };
        mounted = true;
        setResponsiveness();
        window.addEventListener("resize", () => setResponsiveness());
        return () => { mounted = false; window.removeEventListener('resize', () => setResponsiveness()) };
    }, []);

    return (
        <Box style={{ backgroundColor: "#FFF" }}>
            <Container maxWidth={false}>
                <Grid container className={classes.bar}>
                    {
                        printPal.checkout
                            ? null
                            :
                            <>
                                <Grid container item xs={4} justify="flex-start"  className={classes.logoutContainer}>
                                    <Link className={classes.logout} to="/menu-builder"> <img src="../../assets/printPal/orange_arrow.svg" alt="arrow left" style={{ transform: "rotate(-90deg)", cursor: "pointer" }}  className={classes.arrow} />Back</Link>
                                    {/*{*/}
                                    {/*    (location.pathname != "/place-order-formats" && menu.menuData != undefined) && // Header also contains order history, */}
                                    {/*        <Link className={classes.logout} to="/place-order-formats">Back to Order</Link>*/}
                                    {/*}*/}
                                </Grid>
                                {
                                    isMobileApp
                                        ? null
                                        :
                                        <Grid container item xs={isMobileApp ? 12 : 8} justify="flex-end" className={classes.helloContainer}>

                                            <span className={classes.hello}>Hello</span>{global.user && <span className={classes.who}>{global.user.displayName + ", " + global.user.company}</span>}
                                        </Grid>
                                }
                            </>
                    }
                </Grid>
            </Container>
        </Box>
    );
}

const mapStateToProps = (state: AppState) => ({
    global: state.global,
    printPal: state.printPal,
    menu: state.menu
});

export default connect(mapStateToProps)(PrintPalSubHeader);
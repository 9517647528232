import { Reducer } from "redux";
import ICustomTheme from "../../interfaces/ICustomTheme";
import { IMenuState, Actions, IMenuItem, IHistory, MOVE_ITEM, CHANGE_PAGE, ADD_HISTORY, UNDO, REDO, CLOSE_MODAL, OPEN_MODAL, ADD_SECTION, EDIT_SECTION, REMOVE_SECTION, REMOVE_ALL_SECTIONS, SELECT_SECTION, HistoryAction, ChangePage, AddSection, RemoveSection, EditSection, CHANGE_STYLE, SET_MENU, GET_PRODUCTS, GET_PREVIEWS, SET_PREVIEWS, IMenuStyle, SET_PRODUCT_COUNT, GET_IMAGELIBRARY, UPDATE_MENU, SET_GENERATION_ID, GET_GINS } from "./types";
import { randomId, enableSavePrompt, transformTheme } from "../../utils/common";
import ILibraryImage from "../../interfaces/ILibraryImage";

var themeDesignMode = false;

//On anything but development, turn off design mode
if (process.env.NODE_ENV !== "development")
    themeDesignMode = false;

export const initialState: IMenuState = {
    currentPage: 0,
    history: [],
    canRedo: false,
    canUndo: false,
    showModal: false,
    modalData: undefined,
    selectedSection: undefined,
    selectedStyle: undefined,
    menuData: undefined,
    configuration: undefined,
    menu: undefined,
    theme: {} as ICustomTheme,
    products: undefined,
    gins: undefined,
    images: undefined,
    imageCategories: undefined,
    generatingPreviews: false,
    menuAltered: true,
    themeTemplate: undefined,
    altStyles: undefined,
    sectionTypes: {},
    generationId: undefined
};


//Pull altstyles and themetemplate from state instead
if (themeDesignMode) {
    var altStyles = initialState.altStyles as IMenuStyle[];

    if (altStyles && altStyles.length > 0) {
        var initialStyle = {
            fontGroup: altStyles[0].fontGroups,
            palette: altStyles[0].palette,
            assets: altStyles[0].assets,
            filters: altStyles[0].filters
        };

        initialState.theme = transformTheme(JSON.parse(JSON.stringify(initialState.themeTemplate)), initialStyle);
    } else
        initialState.theme = initialState.themeTemplate ?? {} as ICustomTheme;
}

const recursivelyFind = (item: IMenuItem, id: string): IMenuItem | undefined => {
    var props = item.props;
    if (props) {
        //Matches the id so return it
        if (props.id === id) {
            return item
            //Search this items children
        } else if (props.items) {
            //Due to columns this can be multi-dim now
            var found = props.items?.map((item: IMenuItem | IMenuItem[]) => {
                if (item instanceof Array) {
                    var found = item.map(i => recursivelyFind(i, id)).filter((a: any) => a);

                    if (found.length > 0)
                        return found[0];

                    return undefined;
                }

                return recursivelyFind(item, id);
            }).filter((a: any) => a);

            if (found.length > 0)
                return found[0];

            return undefined;
        }
    }
}

const recursivelyFindParent = (item: IMenuItem | IMenuItem[], id: string): IMenuItem | undefined => {
    console.log("Finding parent", "item", item, "id", id);
    if (item instanceof Array || item.props) {
        var children = item instanceof Array ? item : item.props.items;

        var found = children.map((child: IMenuItem | IMenuItem[]) => {
            //It's this item
            if (!(child instanceof Array) && child.props.id === id)
                return item;//Return parent

            //Sub items
            if (child instanceof Array || child.props.items)
                return recursivelyFindParent(child, id);

            return undefined;
        }).filter((a: any) => a);

        //Feed it back upwards
        if (found.length > 0)
            return found[0] instanceof Array ? item : found[0];
    }

    return undefined;
}

//Add data from state to the action for undoing, customData for anything specific or generated like section generated ids
const addUndoData = (action: HistoryAction, state: IMenuState, customData?: any) => {
    //If the action doesn't have an undo then it's probably a undo trying to add undo data
    if (!action.undo)
        return state;

    if (action.historyIndex === undefined) {
        console.error("Trying to add undo data to no history item");
        return state;
    }

    if (!state.history[action.historyIndex]) {
        console.error("Trying to add data to an index that doesn't exist");
        return state;
    }

    //Get the item and check the type
    let historyAction = state.history[action.historyIndex].action as HistoryAction;

    //Remember the undo is the opposite action
    switch (action.type) {
        case CHANGE_PAGE:
            (historyAction.undo as ChangePage).page = state.currentPage;
            break;
        case ADD_SECTION:
            (historyAction.undo as RemoveSection).id = customData.props.id;
            break;
        case REMOVE_SECTION: {
            //TODO: Add item back with exact props
            (historyAction.undo as AddSection).itemType = {
                component: customData.component,
                defaultProps: customData.props
            }
            break;
        }
        case EDIT_SECTION: {
            (historyAction.undo as EditSection).id = customData.props.id;
            (historyAction.undo as EditSection).dataMapping = customData.props;
        }
    }

    return state;
}

const reducer: Reducer<IMenuState> = (state: IMenuState = initialState, action): IMenuState => {
    switch ((action as Actions).type) {
        case MOVE_ITEM: {
            let menu = state.menu;

            if (menu === undefined)
                return state;

            const [fromParentId] = action.from.id.split(" ");
            const [toParentId] = action.to.id.split(" ");

            let fromParent = recursivelyFind(menu[state.currentPage], fromParentId);
            let toParent = recursivelyFind(menu[state.currentPage], toParentId);

            if (fromParent && toParent) {
                var removed;

                //It's from a multi dim component
                if (action.from.column >= 0) {
                    [removed] = fromParent.props.items[action.from.column].splice(action.from.index, 1) ?? [];
                } else
                    [removed] = fromParent.props.items?.splice(action.from.index, 1) ?? [];

                //Same here
                if (action.to.column >= 0)
                    toParent.props.items[action.to.column].splice(action.to.index ?? toParent.props.items.length, 0, removed);
                else
                    toParent.props.items?.splice(action.to.index ?? toParent.props.items.length, 0, removed);
            }

            enableSavePrompt();

            return {
                ...state,
                menu: menu,
                menuAltered: true
            }
        }

        case SET_GENERATION_ID: {
            //console.log("Setting generation id", action.id);

            return {
                ...state,
                generationId: action.id
            }
        }

        case CHANGE_PAGE: {
            return {
                ...addUndoData(action as HistoryAction, state),
                currentPage: action.page
            }
        }

        case ADD_HISTORY: {
            let history = state.history;

            history.push({
                action: action.action,
                undone: false
            } as IHistory);

            return {
                ...state,
                history,
                canRedo: false,
                canUndo: true
            }
        }

        case UNDO: {
            let history = state.history.filter(a => !a.undone);
            let latest = history[history.length - 1];

            if (latest)
                latest.undone = true;

            return {
                ...state,
                history,
                canUndo: (history.length - 1) > 0,
                canRedo: true
            }
        }

        case REDO: {
            let history = state.history.filter(a => a.undone);
            let latest = history[0];

            if (latest)
                latest.undone = false;

            return {
                ...state,
                history,
                canUndo: true,
                canRedo: (history.length - 1) > 0
            }
        }

        case OPEN_MODAL: {
            return {
                ...state,
                showModal: true,
                modalData: action.data
            }
        }

        case CLOSE_MODAL: {
            return {
                ...state,
                showModal: false
            }
        }

        case ADD_SECTION: {
            if (state.menu === undefined)
                return state;

            
            const menuItem = {
                component: action.itemType.component,
                props: {
                    id: randomId(action.itemType.component),
                    ...JSON.parse(JSON.stringify(action.itemType.defaultProps))
                }
            } as IMenuItem;

            console.log("Adding section, component:", menuItem);

            let currentPage = state.menu[state.currentPage];

            //Something is selected, do the magic
            if (state.selectedSection !== undefined) {
                //Find it's parent
                let section = recursivelyFindParent(currentPage, state.selectedSection);

                if (!section) {
                    console.error("Unable to find section")

                    //Shove on the end like normal
                    currentPage.props.items.push(menuItem);
                } else {
                    //Get index
                    let colIndex = -1;
                    let index = section.props.items.findIndex((item: IMenuItem | IMenuItem[]) => {
                        if (item instanceof Array) {
                            colIndex = item.findIndex(i => i.props.id === state.selectedSection);
                            return colIndex !== -1;
                        }
                        else
                            return item.props.id === state.selectedSection;
                    });

                    //Insert differently
                    if (colIndex !== -1) {
                        //Index is the col and colIndex is the index within the col
                        section.props.items[index].splice(colIndex + 1, 0, menuItem);
                    } else
                        section.props.items.splice(index + 1, 0, menuItem);
                }
            } else {
                //Is a multi dimensional array
                if (currentPage.props.items[0] instanceof Array) {
                    currentPage.props.items[1].push(menuItem);
                } else
                    currentPage.props.items.push(menuItem);
            }

            //Update menu
            let newMenu = state.menu;
            newMenu[state.currentPage] = currentPage;

            enableSavePrompt();

            return {
                ...addUndoData(action as HistoryAction, state, menuItem),
                menu: newMenu,
                menuAltered: true
            }
        }

        case REMOVE_SECTION: {
            if (state.menu === undefined)
                return state;

            let currentPage = state.menu[state.currentPage];
            let sectionParent = recursivelyFindParent(currentPage, action.id);
            console.log("Removing section", action.id);

            //Parent must exist
            if (!sectionParent) {
                console.error("Section should have a parent to be removed")
                return state;
            }

            if (!sectionParent.props.items) {
                console.error("Parent needs to have children");
                return state;
            }

            var removed = undefined;

            //Is a multi dimensional array
            if (sectionParent.props.items[0] instanceof Array) {
                sectionParent.props.items.forEach((child: IMenuItem[]) => {
                    var [remove] = child.filter((a: IMenuItem) => {
                        return a.props.id === action.id;
                    });

                    if (remove)
                        [removed] = child.splice(child.indexOf(remove), 1);
                });
            } else {
                var items = sectionParent.props.items as IMenuItem[];
                var [remove] = items.filter((a: IMenuItem) => {
                    return a.props.id === action.id;
                });

                if(remove)
                    [removed] = items.splice(items.indexOf(remove), 1);
            }

            if (!removed)
                console.error("Remove called but nothing removed!");

            //Update menu
            let newMenu = state.menu;
            newMenu[state.currentPage] = currentPage;

            enableSavePrompt();

            return {
                ...addUndoData(action as HistoryAction, state, removed),
                menu: newMenu,
                menuAltered: true,
                selectedSection: undefined
            }
        }

        case EDIT_SECTION: {
            if (state.menu === undefined)
                return state;

            let currentPage = state.menu[state.currentPage];
            let section = recursivelyFind(currentPage, action.id);

            if (!section) {
                console.error("Section should exist to be edited")
                return state;
            }

            var state = addUndoData(action as HistoryAction, state, section);

            section.props = {
                ...section.props,
                ...action.dataMapping
            };

            //Update menu
            let newMenu = state.menu ?? [];
            newMenu[state.currentPage] = currentPage;

            enableSavePrompt();

            return {
                ...state,
                menu: newMenu,
                menuAltered: true
            }
        }

        case REMOVE_ALL_SECTIONS: {
            if (state.menu === undefined)
                return state;

            let currentPage = state.menu[state.currentPage];
            currentPage.props.items = [];

            //Update menu
            let newMenu = state.menu;
            newMenu[state.currentPage] = currentPage;

            return {
                ...state,
                menu: newMenu
            }
        }

        case SELECT_SECTION: {
            //Unselect if already selected
            console.log("Selecting Section", action.id, "already selected:", state.selectedSection);
            if (state.selectedSection === action.id)
                action.id = undefined;

            return {
                ...state,
                selectedSection: action.id
            }
        }

        case CHANGE_STYLE: {
            if (!state.altStyles) {
                console.error("Style change with no alternate styles!");
                return state;
            }

            var style = {
                fontGroup: state.altStyles[action.style.fontGroup].fontGroups,
                palette: state.altStyles[action.style.palette].palette,
                assets: state.altStyles[action.style.asset].assets,
                filters: state.altStyles[action.style.palette].filters
            };

            var theme = transformTheme(JSON.parse(JSON.stringify(state.themeTemplate)), style);

            return {
                ...state,
                selectedStyle: action.style,
                theme: theme
            }
        }

        case SET_MENU: {
            if (themeDesignMode)
                return {
                    ...state,
                    menuData: action.menu
                };

            var menuData = action.menu;
            console.log("MenuData from MyMenuService.GetMenu", action.menu);
            var menu = undefined;
            var configuration = undefined;
            var theme = undefined;
            var themeTemplate = undefined;
            var altStyles = undefined;
            var sectionTypes = undefined;
            var selectedStyle = undefined;

            //Menu can be set to nothing
            if (action.menu) {
                menu = JSON.parse(action.menu.data);
                delete menuData.data;

                configuration = JSON.parse(action.menu.configuration);
                delete menuData.configuration;

                themeTemplate = JSON.parse(action.menu.theme);
                delete menuData.theme;

                altStyles = JSON.parse(action.menu.altStyles) as IMenuStyle[];
                delete menuData.altStyles;

                //TODO: This was to test preloading assets but I didn't find that it made much difference
                altStyles.forEach(style => {
                    Object.keys(style.assets).forEach(key => {
                        const img = new Image();

                        if (typeof style.assets[key] === 'string')
                            img.src = style.assets[key] as string;
                        else
                            img.src = (style.assets[key] as { data: string }).data;
                    })
                })

                sectionTypes = JSON.parse(action.menu.sectionTypes);
                delete menuData.sectionTypes;

                var initialStyle;

                if (action.menu.selectedStyle) {
                    selectedStyle = JSON.parse(action.menu.selectedStyle);
                    delete menuData.selectedStyle;

                    initialStyle = {
                        fontGroup: altStyles[selectedStyle.fontGroup].fontGroups,
                        palette: altStyles[selectedStyle.palette].palette,
                        assets: altStyles[selectedStyle.asset].assets,
                        filters: altStyles[selectedStyle.palette].filters
                    };

                    theme = transformTheme(JSON.parse(JSON.stringify(themeTemplate)), initialStyle);
                } else {
                    if (altStyles.length > 0) {
                        //Transform the theme on load
                        initialStyle = {
                            fontGroup: altStyles[0].fontGroups,
                            palette: altStyles[0].palette,
                            assets: altStyles[0].assets,
                            filters: altStyles[0].filters
                        };

                        theme = transformTheme(JSON.parse(JSON.stringify(themeTemplate)), initialStyle);
                    } else
                        theme = themeTemplate;
                }
            }

            let isMenu = !(menuData?.availableFormats && menuData.availableFormats.length > 0);


                //console.log("MenuData being returned to state", menuData);
            return {
                ...state,
                menuData,
                menu,
                configuration,
                themeTemplate,
                theme,
                altStyles,
                selectedStyle,
                selectedSection: undefined,
                sectionTypes,
                currentPage: 0,
                isMenu
            }
        }

        case GET_PRODUCTS: {
            return {
                ...state,
                products: action.products
            }
        }

        case GET_GINS: {
            return {
                ...state,
                gins: action.gins
            }
        }

        case GET_PREVIEWS: {
            //let menu = state.menuData;

            if (menu) {
                //menu.previews = action.preview.previewImages;
                //menu.pdfPreview = action.preview.pdfPreview;
            }

            return {
                ...state,
                //menuData: menu,
                generatingPreviews: false
            }
        }

        case SET_PREVIEWS: {
            return {
                ...state,
                generatingPreviews: action.generating
            }
        }

        case SET_PRODUCT_COUNT: {
            let menu = state.menuData;

            if (menu)
                menu.britvicProducts = action.count;

            return {
                ...state,
                menuData: menu,
                menuAltered: false
            }
        }

        case GET_IMAGELIBRARY: {
            //Just deduping categories :)
            var cats = action.images.reduce((prev: string[], i: ILibraryImage) => {
                i.categories.forEach(cat => {
                    if (prev.indexOf(cat) === -1)
                        prev.push(cat);
                });

                return prev;
            }, []);

            return {
                ...state,
                images: action.images,
                imageCategories: cats
            }
        }

        case UPDATE_MENU: {
            let currentPage = state.menu[state.currentPage];

            //Go through list of flattened components and copy new props over
            action.menu.forEach(component => {
                var item = recursivelyFind(currentPage, component.props.id);

                Object.keys(component.props).forEach(key => {
                    item.props[key] = component.props[key];
                })
            });

            //Update menu
            let newMenu = state.menu;
            newMenu[state.currentPage] = currentPage;

            return {
                ...state,
                menu: newMenu
            }
        }

        default:
            return state;
    }
}

export default reducer;
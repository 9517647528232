﻿import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { AppState } from "..";
import { menusLoading, getMenus, formatsLoading, getFormats, stylesLoading, getStyles, setSavingMenu } from "./actions";
import { setMenu } from "../menu/actions";
import { setMyMenu, removeMyMenu } from "../myMenu/actions";
import { showError } from "../global/actions";
import IProduct from "../../interfaces/IProduct";
import { IMenuItem, menuActions } from "../menu";

const getMyMenus = (): ThunkAction<void, null, null, Action<string>> => async dispatch => {
    dispatch(menusLoading(true))

    fetch('/api/MyMenus')
        .then(response => response.json())
        .then(response => dispatch(getMenus(response)))
        .catch(error => console.error("Unable to get MyMenus."));
}

const getAllFormats = (styleId: string): ThunkAction<void, null, null, Action<string>> => async dispatch => {
    dispatch(formatsLoading(true))

    fetch('/api/Formats/' + styleId)
        .then(response => response.json())
        .then(response => dispatch(getFormats(response)))
        .catch(error => console.error('Unable to get Formats.'));
}

const getAllStyles = (): ThunkAction<void, null, null, Action> => async dispatch => {
    dispatch(stylesLoading(true))

    fetch('/api/Styles')
        .then(response => response.json())
        .then(response => dispatch(getStyles(response)))
        .catch(error => console.error('Unable to get Styles.'));
}

const createMenu = (formatStyleId: number, name: string): ThunkAction<any, null, null, Action> => async dispatch => {
    return fetch("/api/MyMenus", {
        method: "POST",
        body: JSON.stringify({ formatStyleId, name }),
        headers: {
            "Content-Type": "application/json"
        }
    })
        .then(response => {
            if (response.status === 200)
                return response.json();
            else
                throw Error();
        })
        .then((response: any) => {
            dispatch(setMenu(response));
            return response;
        })
        .catch(error => {
            dispatch(showError({
                title: "Unable to create menu",
                error: "Unable to create menu, please try again"
            }));

            return error;
        });
}

const countBritvicProducts = (obj: IMenuItem) => {

    if(obj.component == "FloatingProductPanel"){
        return 0;
    }

    var count = obj.props.products ? obj.props.products.filter((p: IProduct) => p.isBritvicProduct).length : 0;

    obj.props.items && obj.props.items.forEach((o: any) => {
        if (o instanceof Array)
            o.forEach(c => {
                if (c.props)
                    count += countBritvicProducts(c);
            });
        else
            if (o.props)
                count += countBritvicProducts(o);
    });

    return count;
}

const saveMenu = (id: number): ThunkAction<any, AppState, null, Action> => async (dispatch: any, getState: () => AppState) => {
    var state = getState();

    //Set product count
    var count = state.menu.menuData?.britvicProducts;

    if (state.menu.menu && state.menu.menuData) {
        //Reset
        count = 0;

        //Count per page
        state.menu.menu.forEach(page => {
            count += countBritvicProducts(page);
        });
    }

    dispatch(setSavingMenu(true));

    return fetch("/api/MyMenus/SaveMenu", {
        method: "PUT",
        body: JSON.stringify({
            id,
            json: JSON.stringify(state.menu.menu),
            britvicProducts: count,
            selectedStyle: JSON.stringify(state.menu.selectedStyle)
        }),
        headers: {
            "Content-Type": "application/json"
        }
    })
        .then(response => {
            if (response.status === 200)
                return true;
            else
                throw Error();
        })
        .then(response => {
            //Reset altered flag here instead of another call
            dispatch(menuActions.setBritvicProducts(count));

            dispatch(setSavingMenu(false));
            return response;
        });
};

const saveMenuTitle = (id: number, title: string): ThunkAction<any, AppState, null, Action> => async (dispatch: any, getState: () => AppState) => {
    return fetch("/api/MyMenus/UpdateMenuTitle", {
        method: "PUT",
        body: JSON.stringify({ id, title }),
        headers: {
            "Content-Type": "application/json"
        }
    })
        .then(response => {
            if (response.status !== 200)
                throw Error();
        })
        .then(() => {
            dispatch(setMyMenu(id, title))
        });
};

const deleteMenu = (id: number): ThunkAction<any, AppState, null, Action> => async (dispatch: any, getState: () => AppState) => {
    return fetch("/api/MyMenus/DeleteMenu", {
        method: "DELETE",
        body: JSON.stringify({ id }),
        headers: {
            "Content-Type": "application/json"
        }
    })
        .then(response => {
            if (response.status !== 200)
                throw Error();
        })
        .then(() => {
            dispatch(removeMyMenu(id))
        });
};

export default {
    getMyMenus, getAllFormats, getAllStyles, createMenu, saveMenu, saveMenuTitle, deleteMenu
};
import { Grid, Typography, WithStyles, FormControlLabel, RadioGroup, Radio, Divider, Checkbox} from "@material-ui/core";
import React from "react";
import Editable from "../decorators/Editable";
import StyleInjector from "../decorators/StyleInjector";
import TextField from "../form/TextField";
import ImageUpload from "../form/ImageUpload";
import { menuOperations } from "../../store/menu";
import { connect } from "react-redux";
import ILibraryImage from "../../interfaces/ILibraryImage";
import ICustomTheme from "../../interfaces/ICustomTheme";

interface IAltImgCoverProps {
    id: string,
    title: string,
    logoImage: string,
    coverImage: string,
    address?: string,
    contact?: string,
    desc?: string,
    uploadFile: any,
    coverOption: string,
    theme: ICustomTheme
}

const styles = ({     
});

function Appearance(props: { onChange: any, dataMapping: any }) {
    const { onChange, dataMapping } = props;

    return <Grid container style={{ marginBottom: 30 }}>

        <Grid item xs={12} style={{marginTop: 15, marginBottom: 15}}>
            <span style={{ fontSize: 14 }}>Select your front cover type:</span>
        </Grid>

        <RadioGroup name="coverOption" defaultValue={dataMapping.coverOption} aria-label="cover option" onChange={(ev: any) => dataMapping.coverOption = ev.target.value }>
            <Grid container>
                <Grid item>
                    <FormControlLabel value={"1"} label={<span style={{ fontSize: 14 }}>{dataMapping.theme.altImgCover?.menuText}</span>}  control={<Radio />} labelPlacement="end"/>
                </Grid>

                <Grid item>
                    <FormControlLabel value={"2"} label={<span style={{ fontSize: 14 }}>{dataMapping.theme.altImgCover?.altMenuText}</span>}  control={<Radio />} labelPlacement="end"/>
                </Grid>
            </Grid>
        </RadioGroup>

        <Grid item xs={12}>
            <Divider style={{ marginTop: 15}} />
        </Grid>
    </Grid>
}

function AltImgCover(props: IAltImgCoverProps & WithStyles) {
    const { id, logoImage, coverImage, title, address, contact, classes, uploadFile, desc, theme, coverOption } = props;    

    const data = {
        id,
        name: "Cover page",
        initialValues: {
            logoImage,
            coverImage,
            title,
            address,
            contact,
            desc,  
            theme,
            coverOption
        },
        state: {
            imageFormData: undefined,                       
            logoFormData: undefined,
            imageError: false,                      
            logoError: false
        },
        fields: [
            {                    
                label: "Appearance",
                render: (onChange: any, dataMapping: any) => {
                return <Appearance onChange={onChange} dataMapping={dataMapping}/>
                }
            },
            {
                label: "Title",
                required: true,
                render: (onChange: any, dataMapping: any) => {
                    return <TextField name="title" value={dataMapping["title"]} onChange={onChange} />
                }
            },
            {
                label: "Address",
                required: true,
                render: (onChange: any, dataMapping: any) => {
                    return <TextField fullWidth multiline rows={4} name="address" value={dataMapping["address"]} onChange={onChange} />
                }
            },
            {
                label: "Contact details",
                required: true,
                render: (onChange: any, dataMapping: any) => {
                    return <TextField fullWidth name="contact" value={dataMapping["contact"]} onChange={onChange} />
                }
            },
            {
                label: "Description",
                render: (onChange: any, dataMapping: any) => {
                    return <TextField fullWidth name="desc" value={dataMapping["desc"]} onChange={onChange} />
                }
            },                 
            {
                label: "Logo",
                render: (onChange: any, dataMapping: any, state: { onSetState: any, editableState: any }) => {
                    return <React.Fragment>
                        {state.editableState.logoError && <Typography variant="body2" gutterBottom color="error">Image upload failed, please make sure your image is under 5mb and is a jpg or png</Typography>}

                        <ImageUpload
                            name="logo"
                            imageKey="logoImage"
                            dataMapping={dataMapping}
                            fileUploaded={(formData: FormData) => {
                                state.onSetState({
                                    editableState: { ...state.editableState, logoFormData: formData, logoError: false }
                                });
                            }}
                            imageSelected={(image: ILibraryImage) => {
                                state.onSetState({
                                    dataMapping: { ...dataMapping, logoImage: image.filePath },
                                    editableState: { ...state.editableState, logoFormData: null, logoError: false }
                                });
                            }} />
                    </React.Fragment>
                }
            },
            {
                label: "Cover image",
                render: (onChange: any, dataMapping: any, state: { onSetState: any, editableState: any }) => {
                    return <React.Fragment>
                        {state.editableState.imageError && <Typography variant="body2" gutterBottom color="error">Image upload failed, please make sure your image is under 5mb and is a jpg or png</Typography>}

                        <ImageUpload
                            imageKey="coverImage"
                            dataMapping={dataMapping}
                            fileUploaded={(imageFormData: FormData) => {
                                state.onSetState({
                                    editableState: { ...state.editableState, imageFormData, imageError: false }
                                });
                            }}
                            imageSelected={(image: ILibraryImage) => {
                                state.onSetState({
                                    dataMapping: { ...dataMapping, coverImage: image.filePath },
                                    editableState: { ...state.editableState, imageFormData: null, imageError: false }
                                });
                            }} />
                    </React.Fragment>
                }
            }                                
        ],
        onSaved: (dataMapping: any, state: any) => {
            return new Promise<void>((resolve: any, reject: any) => {
                if (!state.editableState.logoFormData) {
                    resolve();
                    return;
                }

                //On save upload the file to the server
                uploadFile(state.editableState.logoFormData).then((file: any) => {
                    let url = window.location.origin + "/api/Files/" + file.fileId;
                    dataMapping.logoImage = url;
                    resolve();
                }).catch(() => {
                    console.error("File upload error");
                    reject();
                });

                //Reset form data
                state.onSetState({
                    editableState: { ...state.editableState, logoFormData: undefined }
                });
             }).then(() => {
                return new Promise<void>((resolve: any, reject: any) => {
                    if (!state.editableState.imageFormData) {
                        resolve();
                        return;
                    }

                    //On save upload the file to the server
                    uploadFile(state.editableState.imageFormData).then((file: any) => {
                        let url = window.location.origin + "/api/Files/" + file.fileId;
                        dataMapping.coverImage = url;
                        resolve();
                    }).catch(() => {
                        console.error("File upload error");
                        reject();
                    });

                    //Reset form data
                    state.onSetState({
                        editableState: { ...state.editableState, imageFormData: undefined }
                    });
                })                                                                     
            });
        }
    }; 

    return <Editable data={data} fullHeight>
        <div className={coverOption === "1" ? classes.background : classes.altBackground}>
        {coverOption === "1" && coverImage ? <img alt="cover image" src={coverImage} className={classes.sizedImage}/> : coverOption === "1" && <span className={classes.image}>Image<br/>here</span>}  
            <Grid container className={`${classes.root} ${coverOption === "1" && classes.border}`} direction="column">
                <Grid container alignContent="center" alignItems="center">
                    <Grid item xs={12}>
                        <Typography variant="h1" className={coverOption === "1" ? classes.title : classes.altTitle}>{title}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {desc && <Typography variant="h2" className={coverOption === "1" ? classes.desc : classes.altDesc}>{desc}</Typography>}
                    </Grid>                    
                    <Grid item xs={12} className={classes.logo}>
                        {logoImage ? <img alt="logo" src={logoImage} className={classes.sizedLogo}/> : <span className={classes.circle}>Logo<br/>here</span>}
                    </Grid>
                    <Grid item xs={12} className={coverOption === "1" ? classes.addressContainer : classes.altAddressContainer}>
                        <Typography variant="h3" className={classes.address}>{address}</Typography>
                        <Typography variant="h3" className={classes.contact}>{contact}</Typography>                      
                    </Grid>
                </Grid>            
            </Grid>                                                
        </div>                                  
    </Editable>;
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        uploadFile: (file: any) => dispatch(menuOperations.uploadFile(file))
    }
}

export default connect(null, mapDispatchToProps)(StyleInjector(styles, "altImgCover", true)(AltImgCover));
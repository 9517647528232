import { Grid, Typography, WithStyles, FormControlLabel, RadioGroup, Radio, Divider, Checkbox} from "@material-ui/core";
import React from "react";
import Editable from "../decorators/Editable";
import StyleInjector from "../decorators/StyleInjector";
import TextField from "../form/TextField";
import ImageUpload from "../form/ImageUpload";
import { menuOperations } from "../../store/menu";
import { connect } from "react-redux";
import ILibraryImage from "../../interfaces/ILibraryImage";
import ICustomTheme from "../../interfaces/ICustomTheme";

interface INoLogoCoverProps {
    id: string,
    bigImage: string,
    firstImage: string,
    secondImage: string,
    desc?: string,
    facebookHashtag?: string,
    instagramHashtag?: string,    
    uploadFile: any,
    coverOption: string,
    showFacebookLogo?: boolean,
    showInstagramLogo?: boolean,    
    theme: ICustomTheme
}

const styles = ({  
});

function Appearance(props: { onChange: any, dataMapping: any }) {
    const { onChange, dataMapping } = props;

    return <Grid container style={{ marginBottom: 30 }}>

        <Grid item xs={12} style={{marginTop: 15, marginBottom: 15}}>
            <span style={{ fontSize: 14 }}>Select your front cover type:</span>
        </Grid>

        <RadioGroup name="coverOption" defaultValue={dataMapping.coverOption} aria-label="cover option" onChange={(ev: any) => dataMapping.coverOption = ev.target.value }>
            <Grid container>
                <Grid item>
                    <FormControlLabel value={"1"} label={<span style={{ fontSize: 14 }}>{dataMapping.theme.noLogoCover?.menuText}</span>}  control={<Radio />} labelPlacement="end"/>
                </Grid>

                <Grid item>
                    <FormControlLabel value={"2"} label={<span style={{ fontSize: 14 }}>{dataMapping.theme.noLogoCover?.altMenuText}</span>}  control={<Radio />} labelPlacement="end"/>
                </Grid>
            </Grid>
        </RadioGroup>

        <Grid item xs={12}>
            <FormControlLabel 
                    style={{marginBottom: 15, marginTop: 10, fontSize: 14 }}
                    name="showFacebookLogo" 
                    onChange={onChange} 
                    checked={dataMapping["showFacebookLogo"]} 
                    label={<span style={{ fontSize: 14 }}>Show Facebook logo (displayed before the hashtag text)</span>}
                    labelPlacement="end"
                    control={<Checkbox />} />   
        </Grid>

        <Grid item xs={12}>
            <FormControlLabel 
                    style={{marginBottom: 15, marginTop: 10, fontSize: 14 }}
                    name="showInstagramLogo" 
                    onChange={onChange} 
                    checked={dataMapping["showInstagramLogo"]} 
                    label={<span style={{ fontSize: 14 }}>Show Instagram logo (displayed before the hashtag text)</span>}
                    labelPlacement="end"
                    control={<Checkbox />} />   
        </Grid>

        <Grid item xs={12}>
            <Divider style={{ marginTop: 15}} />
        </Grid>
    </Grid>
}

function NoLogoCover(props: INoLogoCoverProps & WithStyles) {
    const { id, bigImage, firstImage, secondImage, classes, uploadFile, desc, facebookHashtag, instagramHashtag, theme, coverOption, showFacebookLogo, showInstagramLogo } = props;    

    const data = {
        id,
        name: "Cover page",
        initialValues: {
            bigImage,
            firstImage,
            secondImage,
            desc,
            facebookHashtag,
            instagramHashtag,
            theme,
            coverOption,
            showFacebookLogo,
            showInstagramLogo
        },
        state: {
            firstFormData: undefined,
            secondFormData: undefined,                       
            bigFormData: undefined,
            firstError: false,
            secondError: false,                      
            bigError: false
        },
        fields: [
            {                    
                label: "Appearance",
                render: (onChange: any, dataMapping: any) => {
                return <Appearance onChange={onChange} dataMapping={dataMapping}/>
                }
            },
            {
                label: "Description",
                render: (onChange: any, dataMapping: any) => {
                    return <TextField fullWidth name="desc" value={dataMapping["desc"]} onChange={onChange} />
                }
            },
            {
                label: "Facebook link or hashtag",
                render: (onChange: any, dataMapping: any) => {
                    return <TextField fullWidth name="facebookHashtag" value={dataMapping["facebookHashtag"]} onChange={onChange} />
                }
            },   
            {
                label: "Instagram link or hashtag",
                render: (onChange: any, dataMapping: any) => {
                    return <TextField fullWidth name="instagramHashtag" value={dataMapping["instagramHashtag"]} onChange={onChange} />
                }
            },                       
            {
                label: "Image",
                render: (onChange: any, dataMapping: any, state: { onSetState: any, editableState: any }) => {
                    return <React.Fragment>
                        {state.editableState.logoError && <Typography variant="body2" gutterBottom color="error">Image upload failed, please make sure your image is under 5mb and is a jpg or png</Typography>}

                        <ImageUpload
                            name="image"
                            imageKey="bigImage"
                            dataMapping={dataMapping}
                            fileUploaded={(formData: FormData) => {
                                state.onSetState({
                                    editableState: { ...state.editableState, bigFormData: formData, bigError: false }
                                });
                            }}
                            imageSelected={(image: ILibraryImage) => {
                                state.onSetState({
                                    dataMapping: { ...dataMapping, bigImage: image.filePath },
                                    editableState: { ...state.editableState, bigFormData: null, bigError: false }
                                });
                            }} />
                    </React.Fragment>
                }
            },
            {
                label: "First image",
                render: (onChange: any, dataMapping: any, state: { onSetState: any, editableState: any }) => {
                    return <React.Fragment>
                        {state.editableState.firstError && <Typography variant="body2" gutterBottom color="error">Image upload failed, please make sure your image is under 5mb and is a jpg or png</Typography>}

                        <ImageUpload
                            imageKey="firstImage"
                            dataMapping={dataMapping}
                            fileUploaded={(firstFormData: FormData) => {
                                state.onSetState({
                                    editableState: { ...state.editableState, firstFormData, firstError: false }
                                });
                            }}
                            imageSelected={(image: ILibraryImage) => {
                                state.onSetState({
                                    dataMapping: { ...dataMapping, firstImage: image.filePath },
                                    editableState: { ...state.editableState, firstFormData: null, firstError: false }
                                });
                            }} />
                    </React.Fragment>
                }
            }, 
            {
                label: "Second image",
                render: (onChange: any, dataMapping: any, state: { onSetState: any, editableState: any }) => {
                    return <React.Fragment>
                        {state.editableState.secondError && <Typography variant="body2" gutterBottom color="error">Image upload failed, please make sure your image is under 5mb and is a jpg or png</Typography>}

                        <ImageUpload
                            imageKey="secondImage"
                            dataMapping={dataMapping}
                            fileUploaded={(secondFormData: FormData) => {
                                state.onSetState({
                                    editableState: { ...state.editableState, secondFormData, secondError: false }
                                });
                            }}
                            imageSelected={(image: ILibraryImage) => {
                                state.onSetState({
                                    dataMapping: { ...dataMapping, secondImage: image.filePath },
                                    editableState: { ...state.editableState, secondFormData: null, secondError: false }
                                });
                            }} />
                    </React.Fragment>
                }
            },                                    
        ],
        onSaved: (dataMapping: any, state: any) => {
            return new Promise<void>((resolve: any, reject: any) => {
                if (!state.editableState.bigFormData) {
                    resolve();
                    return;
                }

                //On save upload the file to the server
                uploadFile(state.editableState.bigFormData).then((file: any) => {
                    let url = window.location.origin + "/api/Files/" + file.fileId;
                    dataMapping.bigImage = url;
                    resolve();
                }).catch(() => {
                    console.error("File upload error");
                    reject();
                });

                //Reset form data
                state.onSetState({
                    editableState: { ...state.editableState, bigFormData: undefined }
                });
             }).then(() => {
                return new Promise<void>((resolve: any, reject: any) => {
                    if (!state.editableState.firstFormData) {
                        resolve();
                        return;
                    }

                    //On save upload the file to the server
                    uploadFile(state.editableState.firstFormData).then((file: any) => {
                        let url = window.location.origin + "/api/Files/" + file.fileId;
                        dataMapping.firstImage = url;
                        resolve();
                    }).catch(() => {
                        console.error("File upload error");
                        reject();
                    });

                    //Reset form data
                    state.onSetState({
                        editableState: { ...state.editableState, firstFormData: undefined }
                    });
                }).then(() => {
                    return new Promise<void>((resolve: any, reject: any) => {
                        if (!state.editableState.secondFormData) {
                            resolve();
                            return;
                        }
    
                        //On save upload the file to the server
                        uploadFile(state.editableState.secondFormData).then((file: any) => {
                            let url = window.location.origin + "/api/Files/" + file.fileId;
                            dataMapping.secondImage = url;
                            resolve();
                        }).catch(() => {
                            console.error("File upload error");
                            reject();
                        });
    
                        //Reset form data
                        state.onSetState({
                            editableState: { ...state.editableState, secondFormData: undefined }
                        });
                    });                                         
                });                
            });
        }
    }; 

    return <Editable data={data} fullHeight>
        {
        coverOption === "1"
        ?
            <Grid container direction="column">
                <Grid item>
                    {bigImage ? <img alt="cover image" src={bigImage} className={classes.sizedBigImage}/> : <span className={`${classes.bigImage} ${classes.fg}`}>Image<br/>here</span>}                 
                </Grid>
                <Grid item>
                    <Typography variant="h1" className={classes.desc}>{desc}</Typography>                 
                </Grid>
                <div className={classes.hashtag}>                
                    {showInstagramLogo && instagramHashtag && <img src={theme.noLogoCover?.instagramLogo} className={classes.instagram}/>}
                    <Typography variant="h3">{instagramHashtag}</Typography>
                </div>              
                <div className={classes.hashtag}>  
                    {showFacebookLogo && facebookHashtag && <img src={theme.noLogoCover?.facebookLogo} className={classes.facebook}/>}
                    <Typography variant="h3">{facebookHashtag}</Typography>
                </div>               
            </Grid>
        :
            <Grid container className={classes.altBackground} direction="row">           
                <Grid item xs={12}>
                    {firstImage ? <img alt="top cover image" src={firstImage} className={classes.sizedTopImage}/> : <span className={`${classes.topImage} ${classes.fg}`}>Image<br/>here</span>}                                      
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.altBackgroundImage}>                       
                    <Typography variant="h1" className={classes.altDesc}>{desc}</Typography>                 
                    </div>
                </Grid>
                <Grid item xs={12}>
                    {secondImage ? <img alt="bottom cover image" src={secondImage} className={classes.sizedBottomImage}/> : <span className={`${classes.bottomImage} ${classes.fg}`}>Image<br/>here</span>}                   
                </Grid>                               
                <div className={classes.altHashtag}> 
                    <div style={{display: "flex"}}>               
                        {showInstagramLogo && instagramHashtag && <img src={theme.noLogoCover?.altInstagramLogo} className={classes.instagram}/>}
                        <Typography variant="h3">{instagramHashtag}</Typography>
                    </div>   
                    <div style={{display: "flex", marginTop: 5}}>                   
                        {showFacebookLogo && facebookHashtag && <img src={theme.noLogoCover?.altFacebookLogo} className={classes.facebook}/>}
                        <Typography variant="h3">{facebookHashtag}</Typography>
                    </div>                       
                </div>               
            </Grid>
        }
    </Editable>;
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        uploadFile: (file: any) => dispatch(menuOperations.uploadFile(file))
    }
}

export default connect(null, mapDispatchToProps)(StyleInjector(styles, "noLogoCover", true)(NoLogoCover));
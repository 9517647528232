import { withStyles, WithStyles } from "@material-ui/core";
import React, { CSSProperties, useEffect, useRef } from "react";
import ICustomTheme from "../../interfaces/ICustomTheme";
import { IMenuItem, PreviewContext } from "../../store/menu";
import Components from "../menu";

interface IMenuAnimator {
    data: IMenuItem,
    currentPage: number,
    index: number,
    theme: ICustomTheme,
    scale: number
    size: { width: number, height: number },
    oversized?: (amount: number, page: number) => void,
    border?: string
    formatSize: string
    previewHeight?: number
    context: PreviewContext
}

const styles = (theme: any) => ({
    root: (props: any) => ({
        height: props.size.height,
        ...theme.root
    }),
    oversizeWarning: {
        marginTop: -35,
        marginBottom: 15,
        textAlign: "center"
    }
});

function MenuAnimator(props: IMenuAnimator & WithStyles) {
    const { data, currentPage, index, classes, size, oversized, border, formatSize, scale, previewHeight, context } = props;

    //Check content against the requested page height
    const element = useRef(null);

    const pageWidth = size.width;
    const pageHeight = size.height;
    const top = previewHeight ? (previewHeight - pageHeight) / 2 : 0;

    const style = {
        position: "absolute" as "absolute",
        top: top,
        left: pageWidth * (index - currentPage) - (pageWidth / 2) + (index === currentPage ? 0 : 150 * (index - currentPage)),
        transition: "opacity 0.2s ease 0.3s",
        opacity: currentPage === index ? 1 : 0,
        width: pageWidth,
        height: pageHeight,
        border
    } as CSSProperties;

    useEffect(() => {
        //Only check if this is the current page
        if (element.current !== null && oversized) {
            var component = element.current as any;

            if (component.scrollHeight > pageHeight)
                oversized(component.scrollHeight - pageHeight, index);
            else
                oversized(0, index);
        }
    });

    //Render root component inside the styled animator
    var RootComponent = (Components as any)[data.component];
    return <div style={style}>
        {border && <p className={classes.oversizeWarning}>Your content does not fit on this page. Please reduce the amount of content.</p>}

        <RootComponent
            className={`${classes.root} page-${currentPage}`}
            formatSize={formatSize}
            scale={scale}
            context={context}
            {...{ ...data.props, boundsRef: (node: any) => element.current = node }}
        />
    </div>
}

export default withStyles(styles as any)(MenuAnimator);
import React from "react";
import { Button, Grid, Theme, Dialog, WithStyles, withStyles, Typography } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = (theme: Theme) => ({
    text: {
        marginBottom: 25,
        fontSize: 20,
        maxWidth: 420
    },
    cancel: {
        backgroundColor: "#FFF",
        color: "#007D7A",
        border: "1px solid #007D7A",
        width: 164,
        "&:hover": {
            backgroundColor: "#007D7A",
            border: "1px solid #007D7A",
            color: "#FFF"
        }
    },
    save: {
        backgroundColor: "#007D7A",
        color: "#FFF",
        border: "1px solid #007D7A",
        width: 100,
        "&:hover": {
            backgroundColor: "#FFF",
            border: "1px solid #007D7A",
            color: "#007D7A"
        }
    },
    title: {
        marginBottom: 25,
        fontWeight: "bold" as "bold",
        fontSize: 20,
        [theme.breakpoints.down("sm")]: {
            marginBottom: 15,
            fontSize: 16           
        }
    },
    response: {
        fontWeight: "bold" as "bold",
        fontSize: 20,
        textAlign: "center",
        maxWidth: 520,
        width: "100%"
    }
});

interface ICreatingContentPopup {
    isPopupOpen: boolean;
    closePopup: any;
    isMobileApp: boolean;
    signangeDownloadData: {available: boolean, error: boolean, filename: string | null, message: string},
    cancelDownload: () => void
}

class CreatingContentPopup extends React.Component<ICreatingContentPopup & WithStyles> {
    constructor(props: any) {
        super(props);
    }
    handleClosePopup = () => {
        this.props.closePopup();
    };
    render(){
        const { classes, isPopupOpen, isMobileApp, signangeDownloadData, cancelDownload } = this.props;
        return(
            <Dialog open={isPopupOpen} onClose={this.handleClosePopup} maxWidth="lg">
                <Typography className={classes.title} variant="h5">Digital Signage content</Typography>
                {
                    <Grid container style={{ flexDirection: 'column' }}>
                        <Grid item xs={12} className={classes.text}>
                            The Meal deal tool is generating your digital signage file as a jpg. <br/>
                            (This can take several minutes to generate)
                        </Grid>
                        {
                            signangeDownloadData?.available ?
                                <Grid item xs={12} container justifyContent="center" className={classes.response}>
                                    {signangeDownloadData.filename} <br/>
                                    download complete
                                </Grid>
                            : signangeDownloadData?.error ?
                                <Grid item xs={12} container justifyContent="center" className={classes.response}>
                                    {signangeDownloadData.message}
                                </Grid>
                            :
                            <Grid item xs={12} container justifyContent="center">
                                <CircularProgress color="secondary" size={50} thickness={5} />
                            </Grid>
                        }
                    </Grid>
                }
                <Grid item>
                    <Grid container spacing={2} style={{ marginTop: "40px" }}>
                        <Grid item>
                            <Button size={isMobileApp ? "small" : "large" } className={classes.cancel} onClick={cancelDownload}>Cancel download</Button>
                        </Grid>
                        <Grid item>
                            <Button size={isMobileApp ? "small" : "large"} className={classes.save} onClick={this.handleClosePopup}>OK</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Dialog>
        )
    }
}

export default withStyles(styles)(CreatingContentPopup);
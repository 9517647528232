import React from "react";
import { Grid, Typography, WithStyles, RadioGroup, Radio, FormControlLabel, TextField, Checkbox } from "@material-ui/core";
import Editable from "../decorators/Editable";
import StyleInjector from "../decorators/StyleInjector";
import ImageUpload from "../form/ImageUpload";
import { menuOperations } from "../../store/menu";
import { connect } from "react-redux";
import ILibraryImage from "../../interfaces/ILibraryImage";
import ICustomTheme from "../../interfaces/ICustomTheme";

const styles = ({
    rectangle:{
        width:205,
        height:320,
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        fontSize:14,
        fontWeight:"bold",
        margin:10
    },
    sizedImage:{
        maxHeight:320,
        height:"auto",
        maxWidth:205,
        width:"auto",
    },
    block: {
        textAlign: "center", 
        display: "block", 
        marginLeft: "auto", 
        marginRight: "auto"
    },
    flex: {
        textAlign: "center", 
        display: "flex", 
        marginLeft: "auto", 
        marginRight: "auto" 
    },       
});

interface IPlaceholderProps {
    id: string,
    placeholder?: IPlaceholderProps,
    theme: ICustomTheme,
    image: string,
    small: boolean,
    tiny: boolean,
    size: string,
    classes: any,
    uploadFile: any,
    showOverlay: boolean,
    overlayText: string    
}

interface IImageSize {
    size: string;
    name: string;
}

function Appearance(props: { onChange: any, dataMapping: any }) {
    const { onChange, dataMapping } = props;

    if (dataMapping.portraitSizes.length + dataMapping.landscapeSizes.length === 1)
        return null;

    return <Grid container style={{ marginBottom: 30 }}>

    <RadioGroup name="sizes" defaultValue={dataMapping.size} aria-label="image sizes" onChange={(ev: any) => dataMapping.size = ev.target.value } style={{marginLeft: -10}}>
        <Grid container justify="flex-start" item xs={12}>            

            {dataMapping.portraitSizes.length > 0 && 
                <>
                    <Grid item xs={12} style={{marginTop: 15, marginBottom: 15, marginLeft: 10}}>
                        <span style={{ fontSize: 14 }}>Choose a portrait image size:</span>
                    </Grid>

                    <Grid container style={{marginLeft: 10}}>               
                        {dataMapping.portraitSizes.map((imageSize: IImageSize) => (  
                            <Grid item key={imageSize.name}> 
                                <FormControlLabel key={imageSize.name} value={imageSize.name} label={<span style={{ fontSize: 14 }}>{imageSize.size}</span>}  control={<Radio />} labelPlacement="end" />                                                          
                            </Grid>
                        ))}
                    </Grid>
                </>} 
                
            {dataMapping.landscapeSizes.length > 0 && 
                <>
                    <Grid item xs={12} style={{marginTop: 15, marginBottom: 15, marginLeft: 10}}>
                        <span style={{ fontSize: 14 }}>Choose a landscape image size:</span>
                    </Grid>

                    <Grid container style={{marginLeft: 10}}>               
                        {dataMapping.landscapeSizes.map((imageSize: IImageSize) => (  
                            <Grid item key={imageSize.size}>      
                                <FormControlLabel key={imageSize.name} value={imageSize.name} label={<span style={{ fontSize: 14 }}>{imageSize.size}</span>}  control={<Radio />} labelPlacement="end" />                                                          
                            </Grid>
                        ))}
                    </Grid>
                </>}   
            </Grid>
        </RadioGroup>
    </Grid>
}

class ImagePlaceholder extends React.Component<IPlaceholderProps & WithStyles> {

    getPortraitImageSizes = (classes: any) => {
        var portraitSizes: IImageSize[] = new Array();
        if (classes.smallPortrait) portraitSizes.push({size: "Small", name: "smallPortrait"});
        if (classes.mediumPortrait) portraitSizes.push({size: "Medium", name: "mediumPortrait"});
        if (classes.largePortrait) portraitSizes.push({size: "Large", name: "largePortrait"});
        return portraitSizes;
    }

    getLandscapeImageSizes = (classes: any) => {
        var landscapeSizes: IImageSize[] = new Array();
        if (classes.smallLandscape) landscapeSizes.push({size: "Small", name: "smallLandscape"});
        if (classes.mediumLandscape) landscapeSizes.push({size: "Medium", name: "mediumLandscape"});
        if (classes.largeLandscape) landscapeSizes.push({size: "Large", name: "largeLandscape"});   
        return landscapeSizes;
    }    

    render() {
        const { classes, id, image, uploadFile, small, tiny, size, overlayText, placeholder, showOverlay, theme } = this.props;                
        
        //TODO: When the DOM issue has been fixed - uncomment these lines 
        // const portraitSizes = this.getPortraitImageSizes(classes);
        // const landscapeSizes = this.getLandscapeImageSizes(classes);                 

        const data = {
            id,
            name: "Image",
            initialValues: {
                image, 
                small, 
                tiny,
                size,
                placeholder,
                overlayText,
                showOverlay,
                theme
                // portraitSizes,
                // landscapeSizes
            },
            state: {
                formData: undefined,
                error: false           
            },
            fields: [
                // {                 
                //     label: (portraitSizes.length + landscapeSizes.length) > 1 ? "Appearance" : "",
                //     render: (onChange: any, dataMapping: any) => {
                //     return <Appearance onChange={onChange} dataMapping={dataMapping}/>
                //     }
                // }, 
                    theme?.imagePlaceholder?.hasOverlay && size==="mediumPortrait" && {
                         render: (onChange: any, dataMapping: any) => {
                             return <FormControlLabel 
                                style={{marginBottom: 15, marginTop: -10}}
                                name="showOverlay" 
                                onChange={onChange} 
                                checked={dataMapping["showOverlay"]} 
                                label="Show text overlay" 
                                labelPlacement="end"
                                control={<Checkbox />} />
                            }
                        },
                    theme?.imagePlaceholder?.hasOverlay && size==="mediumPortrait" && {
                        label: "Overlay text (optional)",
                        render: (onChange: any, dataMapping: any) => {
                            return <TextField name="overlayText" value={dataMapping.overlayText} onChange={onChange} InputProps={{ disableUnderline: true }} variant="filled" disabled={!dataMapping.showOverlay}/>
                            }
                        }, 
                    {
                    label: "Image",
                    render: (onChange: any, dataMapping: any, state: { onSetState: any, editableState: any }) => {
                        return <React.Fragment>
                            {state.editableState.error && <Typography variant="body2" gutterBottom color="error">Image upload failed, please make sure your image is under 5mb and is a jpg or png</Typography>}

                            <ImageUpload
                                dataMapping={dataMapping}
                                imageSelected={(image: ILibraryImage) => {
                                    state.onSetState({
                                        dataMapping: { ...dataMapping, image: image.filePath },
                                        editableState: { ...state.editableState, formData: null, error: false }
                                    });
                                }}
                                fileUploaded={(formData: FormData) => {
                                    state.onSetState({
                                        editableState: { ...state.editableState, formData, error: false }
                                    });
                                }} />
                        </React.Fragment>;
                    }
                }
            ],
            onSaved: (dataMapping: any, state: any) => {
                return new Promise<void>((resolve: any, reject: any) => {
                    if (!state.editableState.formData) {
                        resolve();
                        return;
                    }

                    //On save upload the file to the server
                    uploadFile(state.editableState.formData).then((file: any) => {
                        var url = window.location.origin + "/api/Files/" + file.fileId;
                        dataMapping.image = url;
                        resolve();
                        return false;
                    }).catch(() => {
                        reject();
                        return true;
                    }).then((error: boolean) => {
                        //Reset form data
                        state.onSetState({
                            editableState: { ...state.editableState, formData: undefined, error }
                        });
                    });
                });
            }
        };

        return <Editable data={data}>
            <Grid container alignContent="center" alignItems="center" justify="center" className={classes.background}>
                <Grid item xs>
                    <div style={{ position: "relative", zIndex: 10}}>
                        {image 
                            ? 
                            <img src={image} className={`${classes.default} ${classes.block} ${size==="smallPortrait" ? classes.smallPortraitSized : 
                                size==="mediumPortrait" ? classes.mediumPortraitSized :
                                size==="largePortrait" ? classes.largePortraitSized :
                                size==="smallLandscape" ? classes.smallLandscapeSized :
                                size==="mediumLandscape" ? classes.mediumLandscapeSized :
                                size==="largeLandscape" ? classes.largeLandscapeSized : ""}`}/> 
                            : 
                            <span className={`${size==="smallPortrait" ? classes.smallPortrait : 
                                size==="mediumPortrait" ? classes.mediumPortrait :
                                size==="largePortrait" ? classes.largePortrait :
                                size==="smallLandscape" ? classes.smallLandscape :
                                size==="mediumLandscape" ? classes.mediumLandscape :
                                size==="largeLandscape" ? classes.largeLandscape : 
                                ""} ${classes.flex} ${classes.bg} ${classes.fg}`}>Image<br/>here</span>
                        }
                    </div>
                    {showOverlay &&
                    <div className={classes.overlay}>
                        <span>{overlayText}</span>
                    </div>}
                </Grid>
            </Grid>
        </Editable>
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        uploadFile: (file: any) => dispatch(menuOperations.uploadFile(file))
    }
}

export default connect(null, mapDispatchToProps)(StyleInjector(styles, "imagePlaceholder", true)(ImagePlaceholder));
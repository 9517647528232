import React, { PureComponent } from "react"
import { Paper, Theme, WithStyles, withStyles } from "@material-ui/core"
import Components from "./";
import WithCustomTheme from "../../interfaces/WithCustomTheme";
import Droppable from "../decorators/Droppable";
import Draggable from "../decorators/Draggable";

import { connect } from "react-redux";
import { IMenuItem, IMenuState, IModalData, menuActions, PreviewContext } from "../../store/menu";
import { Dispatch } from "redux";
import { AppState } from "../../store";
import { FloatingProductPanelConfig } from "./FloatingProductPanel";
import BackgroundPicker from "../form/BackgroundPicker";
import Editable from "../decorators/Editable";
import { getBackground, getScaledImage, getBackgroundGradient } from "../../utils/common";
import EditableAbsolute from "../decorators/EditableAbsolute";
import { SharedComponentConfig } from "../../types";

const styles = (theme: Theme) => ({
    container: {
        "&:hover .editableAbsoluteWrapper": {
            opacity: 1
        }   
    }
});

export interface ContainerDataMapping {
    background: string,
    gradient: string
}

interface IContainerContext {
    formatSize: string,
    scale: number,
    background?: string
    price: number
    context: PreviewContext,
    gradient: string
}

const defaultContainerContext: IContainerContext = {
    background: "",
    formatSize: "",
    scale: 1,
    price: 0,
    context: "WMASK",
    gradient: ""
}

//This does not work in editableModal as it's outside of the context provider
export const ContainerContext = React.createContext<IContainerContext>(defaultContainerContext);

interface IContainerProps {
    items: IMenuItem[];
    className: string;
    id: string;
    moveItem: any;
    background?: string
    price?: number
    boundsRef?: any;
    menu: IMenuState;
    formatSize: string
    scale: number
    width: number
    height: number
    rootFontSize?: number
    context: PreviewContext,
    gradient: string
}

interface FormatValueSize {
    format: string,
    size: number
}

interface IConainerConfig extends SharedComponentConfig {
    backgrounds?: { src: string, category: string}[]
    rootFontSize: FormatValueSize[]
}

interface IContainerState {
    isHovering: boolean
}

function getComponentConfig<T>(type: string, id: string, componentConfigurations?: ComponentConfiguration[]) {
    //console.log(componentConfigurations)
    if(componentConfigurations){
        let config = componentConfigurations.filter((config) => "id" in config ? config.id == id : config.type == type).reduce((prev, curr, i) => {
            //console.log(prev, curr);
            return {
                ...prev,
                ...curr.data
            }
        }, {});

        //console.log(config);
        
        return config as T;
    } else {
        return null;
    }
}

class Container extends PureComponent<WithCustomTheme & IContainerProps & WithStyles, IContainerState> {

    constructor(props: any){
        super(props);
        this.state = {
            isHovering: false
        }
    }

    moveItem = (item: any, droppable: any) => {
        if (item.id === droppable.id)
            return;

        this.props.moveItem(droppable, item);
    }

    onHoverStateChange = (isHovering: boolean) => {
        //this.setState({ isHovering });
    };

    render() {
        let { items, id, className, boundsRef, menu, formatSize, background, price, scale, height, width, classes, context, gradient } = this.props;
        const containerConfig = getComponentConfig<IConainerConfig>("Container", id, menu.configuration?.componentConfigurations);

        const formatConfig = menu.menuData?.availableFormats.find((format) => format.formatKey == formatSize);
        const originalWidth = formatConfig?.widthPx ?? 1;

        const rootFontSize = containerConfig?.rootFontSize.find((r) => r.format == formatSize)?.size;
        let containsHeading3 = false;
        let containsSubHeading3 = false;
        // Change background for Text Only template if Heading 3 and Sub-heading 3 values are empty
        if(menu?.menu && menu?.menu.length > 0 && menu.menuData?.styleId === 42){
            let floatingContentPanel9 = menu?.menu[0]?.props?.items.filter((row: {props: {[key: string]: any}}) => row?.props?.id === "floatingContentPanel9");
            let floatingContentPanel10 = menu?.menu[0]?.props?.items.filter((row: {props: {[key: string]: any}}) => row?.props?.id === "floatingContentPanel10");

            if(floatingContentPanel9 && floatingContentPanel9.length > 0){
                let fieldsGroups = floatingContentPanel9[0]?.props?.fieldGroups;
                if(fieldsGroups && fieldsGroups.length > 0){
                    let fields = fieldsGroups[0]?.fields;
                    if(fields && fields.length > 0){
                        let textValue = fields[0]?.data?.textValue;
                        if(textValue){
                            containsHeading3 = true;
                        }
                    }
                }
            }
            if(floatingContentPanel10 && floatingContentPanel10.length > 0){
                let fieldsGroups = floatingContentPanel10[0]?.props?.fieldGroups;
                if(fieldsGroups && fieldsGroups.length > 0){
                    let fields = fieldsGroups[0]?.fields;
                    if(fields && fields.length > 0){
                        let textValue = fields[0]?.data?.textValue;
                        if(textValue){
                            containsSubHeading3 = true;
                        }
                    }
                }
            }
            if(formatSize === "BARKER" || formatSize === "WOBBLER" || formatSize === "SIGNAGE"){
                if(!containsHeading3 && !containsSubHeading3){
                    background += '-2'
                }
            }
        }

        const modalData: IModalData = {
            id,
            name: "Background",
            editPanelTitle: "Change background image",
            showMandatory: false,
            initialValues: {
                background,
                price,
                gradient
            },
            fields: [
                {
                    render: (onChange, dataMapping, state) => {
                        return <BackgroundPicker onChange={onChange} dataMapping={dataMapping} state={state} backgrounds={containerConfig?.backgrounds} formatSize={formatSize} />
                    }
                }
            ]
        }

        const ContainerContent = (
            <Droppable key={id} id={id}>
                {(connectDropTarget: any) => (
                    <Paper style={{
                        backgroundImage: background ?
                            `url(${
                                getScaledImage(
                                    getBackgroundGradient(gradient, formatSize),
                                    originalWidth * scale,
                                    context == "PRINT" && scale == 1)
                                }
                            ),url(${
                                getScaledImage(
                                    getBackground(background, formatSize, context),
                                    originalWidth * scale,
                                    context == "PRINT" && scale == 1)}
                            )`
                            : undefined,
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        overflow: "hidden",
                        fontSize: rootFontSize != undefined ? rootFontSize * scale : "unset"
                    }} className={className} elevation={0} ref={node => boundsRef ? boundsRef(connectDropTarget(node)) : connectDropTarget(node)}>
                        {items.map((item: IMenuItem, index: number) => {
                            var Component = (Components as any)[item.component];
                            //TODO - componentConfig = menu.templateConfiguration.where config.id == item.id || config.type == item.component
                            const componentConfig = getComponentConfig<any>("FloatingProductPanel", item.props.id, menu.configuration?.componentConfigurations);
                            return Component && <Droppable simplified={menu.menuData?.simplified} index={index} key={item.props.id} id={id + " " + item.props.id}>
                                {(connectDropTarget: any) => (
                                    <Draggable simplified={menu.menuData?.simplified} index={index} id={id + " " + item.props.id} end={this.moveItem}>
                                        {(connectDragTarget: any, connectDragPreview: any, dragging: boolean) => (
                                            <div ref={node => connectDragTarget(connectDropTarget(node))} style={{ opacity: dragging ? 0 : 1 }}>
                                                <Component {...item.props} config={componentConfig} formatSize={formatSize} scale={scale} isHovering={this.state.isHovering} />
                                            </div>
                                        )}
                                    </Draggable>
                                )}
                            </Droppable>
                        })}
                    </Paper>
                )}            
            </Droppable>
        )

        return( 
            <ContainerContext.Provider value={{
                formatSize: formatSize,
                price: price ?? 0,
                scale,
                background,
                context,
                gradient
            }}>
                <div className={classes.container} onMouseEnter={() => {this.onHoverStateChange(true); }} onMouseLeave={() => { this.onHoverStateChange(false) }}>
                    {
                        !containerConfig 
                        ?
                            ContainerContent
                        :
                            <EditableAbsolute data={modalData} position={containerConfig.editablePosition}>
                                { ContainerContent }
                            </EditableAbsolute>
                    }
                </div>
            </ContainerContext.Provider>
        )
    }
};

const mapStateToProps = (state: AppState) => {
    return {
        menu: state.menu
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        moveItem: (to: any, from: any) => dispatch(menuActions.moveItem(to, from))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Container))
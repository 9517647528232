import { makeStyles, Theme } from "@material-ui/core";
import tick from "./assets/tick.png";

/* PlaceOrderWithFormats */
export const usePlaceOrderWithFormatsStyles = makeStyles((theme: Theme) => ({
    root: {
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    media: {
        border: "1px solid black",
        textAlign: "center",
        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        width: "auto",
        maxWidth: "100%",
        [theme.breakpoints.down("md")]: {
            width: 300
        }
    },
    orangeText: {
        color: "#FF9F1C",
        paddingLeft: 30,
        paddingRight: 30,
        [theme.breakpoints.down("sm")]: {
            fontSize: 12
        }
    },
    pageSelected: {
        borderTop: "5px solid #FF9F1C",
        borderBottom: "5px solid #FF9F1C",
        borderLeft: "1px solid #000",
        borderRight: "1px solid #000",
    },
    page: {
        opacity: 0.5,
        border: "1px solid #000",
    },
    header: {
        color: "#666666",
        fontSize: 32,
        fontWeight: 700,
        paddingLeft: 0,
        [theme.breakpoints.down("sm")]: {
            fontSize: 22,
        }
    },
    subHeader: {
        color: "#666666",
        fontSize: 22,
        fontWeight: 700,
        [theme.breakpoints.down("sm")]: {
            fontSize: 18,
        }
    },
    bold: {
        color: "#666666",
        fontSize: 16,
        fontWeight: 700,
        [theme.breakpoints.down("sm")]: {
            fontSize: 12,
        }
    },
    normal: {
        color: "#666666",
        fontSize: 16,
        fontWeight: 400,
        [theme.breakpoints.down("sm")]: {
            fontSize: 12,
        }
    },
    content: {
        position: "relative" as "relative",
        height: "100%",
        overflow: "hidden",
        paddingLeft: 50,
        paddingTop: 50,
        [theme.breakpoints.down("sm")]: {
            paddingLeft: 10,
            paddingTop: 10,
        }
    },
    box: {
        border: "2px solid rgba(255, 159, 28, 1)",
        borderRadius: 10,
        width: 384,
        height: 42,
        fontWeight: 700,
        fontSize: 16,
    },
    radioContainer: {
        height: 40,
        display: "flex",
        alignItems: "center"
    },
    imgTextContainer: {
        verticalAlign: "middle",
        marginTop: 9,
        marginLeft: 10,
        marginBottom: 6
    },
    link: {
        color: "#FF9F1C",
        fontSize: 16,
        fontWeight: 400,
        cursor: "pointer",
        [theme.breakpoints.down("sm")]: {
            fontSize: 12,
        }
    },
    placeOrder: {
        width: 218,
        height: 40,
        marginBottom: 40,
        backgroundColor: "rgba(255, 159, 28, 1)",
        color: "#FFF",
        border: "1px solid rgba(255, 159, 28, 1)",
        fontWeight: "bold" as "bold",
        marginTop: theme.spacing(4),
        "&:hover": {
            backgroundColor: "rgba(255, 159, 28, 1)",
            border: "1px solid rgba(255, 159, 28, 1)",
            color: "#FFF"
        },
        [theme.breakpoints.down("sm")]: {
            width: 180,
            fontSize: 12
        }
    },
    prices: {
        width: 480,
        height: 40,
        verticalAlign: "middle",
        paddingTop: 10,
        paddingBottom: 10,
    },
    icon: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#666666',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#666666',
        },
    },
    screen: {
        minHeight: 'calc(100vh - 210px)'
    },
    heroContainer: {
        marginTop: 50,
        marginBottom: 50,
        overflow: "hidden" as "hidden",
        boxShadow: "rgb(0,0,0,0.1) 10px 10px 10px",
        [theme.breakpoints.down("sm")]: {
            padding: 20
        }
    },
    arrow: {
        [theme.breakpoints.down("sm")]: {
            height: "10px"
        }
    },
    bigBold: {
        fontSize: 18,
        [theme.breakpoints.down("sm")]: {
            fontSize: 14,
        }
    },
    totalContainer: {
        marginTop: 10,
        [theme.breakpoints.down("sm")]: {
            marginTop: 5,
            marginLeft: -5
        }
    },
    cardcontent: {
        padding: 0,
        "&:last-child": {
            paddingBottom: 0
        }
    },
    voucher: {
        "& .MuiOutlinedInput-notchedOutline": {
            border: "2px solid #FF9F1C",
            borderRadius: 12
        },
        "& .MuiInputBase-root.Mui-error": {
            border: 0
        },
        "& input": {
            paddingTop: 15,
            paddingBottom: 15
        },
        "& .MuiOutlinedInput-root:not(.Mui-error)": {
            "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "2px solid #FF9F1C"
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "2px solid #FF9F1C"
            }
        }
    }
}));

/* PrintPalFormatsOptions */
export const usePrintPalFormatsOptionsStyles = makeStyles({
    container: {
        color: "#555"
    },

    tickBoxContainer: {
        backgroundColor: "#ffe2ba",
        borderRadius: 10,
        padding: 10,
        "& input": {
            outline: "1px #FF9F1C solid",
            width: 22,
            height: 22,
            marginRight: 10,
            appearance: "none",
            background: "white",
            "&:checked": {
                backgroundImage: "url('" + tick + "');",
                backgroundSize: "contain"
            }
        },
        "& label": {
            display: "inline-block",
            verticalAlign: "super",
        }
    },
    additionalQuestionContainer: {


        padding: 10,
        marginLeft:0,
        "& input": {
            outline: "1px #FF9F1C solid",
            width: 22,
            height: 22,
            marginRight: 10,
            appearance: "none",
            background: "white",
            cursor: "pointer",
            "&:checked": {
                backgroundImage: "url('" + tick + "');",
                backgroundSize: "contain"
            }
        },
        "& label": {
            display: "inline-block",
            verticalAlign: "super",
        }
    },
    voucherInput: {
        textAlign: "center",
        borderRadius: 4,
        border: "2px #FF9F1C solid",
        color: "#fff",
        width: 250,
        fontSize: 15,
        backgroundColor:"#FF9F1C",
        fontWeight: "bold",
        marginRight: 10,
        padding:8

    }
});

/* PlaceOrder */
export const placeOrderStyles = (theme: Theme) => ({
    root: {
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    media: {
        border: "1px solid black",
        textAlign: "center",
        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        width: "auto",
        maxWidth: "100%",
        [theme.breakpoints.down("md")]: {
            width: 300
        }
    },
    orangeText: {
        color: "#FF9F1C",
        paddingLeft: 30,
        paddingRight: 30,
        [theme.breakpoints.down("sm")]: {
            fontSize: 12
        }
    },
    pageSelected: {
        borderTop: "5px solid #FF9F1C",
        borderBottom: "5px solid #FF9F1C",
        borderLeft: "1px solid #000",
        borderRight: "1px solid #000",
    },
    page: {
        opacity: 0.5,
        border: "1px solid #000",
    },
    header: {
        color: "#666666",
        fontSize: 32,
        fontWeight: 700,
        paddingLeft: 0,
        [theme.breakpoints.down("sm")]: {
            fontSize: 22,
        }
    },
    subHeader: {
        color: "#666666",
        fontSize: 22,
        fontWeight: 700,
        [theme.breakpoints.down("sm")]: {
            fontSize: 18,
        }
    },
    bold: {
        color: "#666666",
        fontSize: 16,
        fontWeight: 700,
        [theme.breakpoints.down("sm")]: {
            fontSize: 12,
        }
    },
    normal: {
        color: "#666666",
        fontSize: 16,
        fontWeight: 400,
        [theme.breakpoints.down("sm")]: {
            fontSize: 12,
        }
    },
    content: {
        position: "relative" as "relative",
        height: "100%",
        overflow: "hidden",
        paddingLeft: 50,
        paddingTop: 50,
        [theme.breakpoints.down("sm")]: {
            paddingLeft: 10,
            paddingTop: 10,
        }
    },
    box: {
        border: "2px solid rgba(255, 159, 28, 1)",
        borderRadius: 10,
        width: 384,
        height: 42,
        fontWeight: 700,
        fontSize: 16,
    },
    redBox: {
        border: "2px solid rgba(255, 0, 0, 1)",
        borderRadius: 10,
        width: 384,
        fontWeight: 700,
        fontSize: 16,
        padding: 15,
        paddingTop:10
    },

    radioContainer: {
        height: 40,
        display: "flex",
        alignItems: "center"
    },
    imgTextContainer: {
        verticalAlign: "middle",
        marginTop: 9,
        marginLeft: 10,
        marginBottom: 6
    },
    link: {
        color: "#FF9F1C",
        fontSize: 16,
        fontWeight: 400,
        cursor: "pointer",
        [theme.breakpoints.down("sm")]: {
            fontSize: 12,
        }
    },
    placeOrder: {
        width: 218,
        height: 40,
        marginBottom: 40,
        backgroundColor: "rgba(255, 159, 28, 1)",
        color: "#FFF",
        border: "1px solid rgba(255, 159, 28, 1)",
        fontWeight: "bold" as "bold",
        marginTop: theme.spacing(4),
        "&:hover": {
            backgroundColor: "rgba(255, 159, 28, 1)",
            border: "1px solid rgba(255, 159, 28, 1)",
            color: "#FFF"
        },
        [theme.breakpoints.down("sm")]: {
            width: 180,
            fontSize: 12
        }
    },
    prices: {
        width: 480,
        height: 40,
        verticalAlign: "middle",
        paddingTop: 10,
        paddingBottom: 10,
    },
    icon: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#666666',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#666666',
        },
    },
    screen: {
        minHeight: 'calc(100vh - 210px)'
    },
    heroContainer: {
        marginTop: 50,
        marginBottom: 50,
        overflow: "hidden" as "hidden",
        boxShadow: "rgb(0,0,0,0.1) 10px 10px 10px",
        [theme.breakpoints.down("sm")]: {
            padding: 20
        }
    },
    arrow: {
        [theme.breakpoints.down("sm")]: {
            height: "10px"
        }
    },
    bigBold: {
        fontSize: 18,
        [theme.breakpoints.down("sm")]: {
            fontSize: 14,
        }
    },
    totalContainer: {
        marginTop: 10,
        [theme.breakpoints.down("sm")]: {
            marginTop: 5,
            marginLeft: -5
        }
    },
    cardcontent: {
        padding: 0,
        "&:last-child": {
            paddingBottom: 0
        }
    },
    voucher: {
        "& .MuiOutlinedInput-notchedOutline": {
            border: "2px solid #FF9F1C",
            borderRadius: 12
        },
        "& .MuiInputBase-root.Mui-error": {
            border: 0
        },
        "& input": {
            paddingTop: 15,
            paddingBottom: 15
        },
        "& .MuiOutlinedInput-root:not(.Mui-error)": {
            "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "2px solid #FF9F1C"
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "2px solid #FF9F1C"
            }
        }
    }
});

/* PrintPalPayment */
export const printPalPaymentStyles = (theme: Theme) => ({
    root: {
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(255, 159, 28, 1)",
        },
        "& .MuiInputLabel-outlined": {
            color: "rgba(255, 159, 28, 1)",
        },
        "& .Mui-disabled": {
            color: "#a8a8a8",
            backgroundColor: "#f3f3f3"
        }
    },
    selectEmpty: {
        margin: theme.spacing(1)
    },
    sortControl: {
        backgroundColor: "#FFF",
        height: 1,
        minWidth: 80,
        marginTop: -3,
        alignContent: "center",
    },
    sortDown: {
        content: `url(./assets/printPal/downarrow.svg)`,
        border: 0,
        padding: 0,
        display: "block",
        height: 10,
        width: 18,
        marginTop: 7,
        cursor: "pointer"
    },
    box: {
        border: "1px solid #D3D3D3",
        borderRadius: 4,
        fontWeight: 700,
        fontSize: 16,
    },
    titleBox: {
        border: "1px solid #D3D3D3",
        borderRadius: 4,
        fontWeight: 700,
        fontSize: 16,
        marginTop: 0,
        marginBottom: 0,
        marginLeft: 20,
        marginRight: 0,
        width: 90,
        paddingLeft: 5,
        height: 38,
        [theme.breakpoints.down("sm")]: {
            marginLeft: 10,
        }
    },
    header: {
        color: "#666666",
        fontSize: 20,
        fontWeight: 700,
        paddingLeft: 10,
        paddingRight: 5,
        [theme.breakpoints.down("sm")]: {
            paddingBottom: 20,
            fontSize: 16,
        }
    },
    content: {
        position: "relative",
        height: "100%",
        overflow: "hidden",
        paddingLeft: 0,
        paddingTop: 30,
        [theme.breakpoints.down("sm")]: {
            paddingTop: 10,
        }
    },
    buy: {
        width: 130,
        height: 40,
        backgroundColor: "rgba(255, 159, 28, 1)",
        color: "#FFF",
        border: "1px solid rgba(255, 159, 28, 1)",
        fontWeight: "bold",
        "&:hover": {
            backgroundColor: "rgba(255, 159, 28, 1)",
            border: "1px solid rgba(255, 159, 28, 1)",
            color: "#FFF"
        },
        "&:disabled": {
            backgroundColor: "#D3D3D3",
            border: "1px solid #D3D3D3",
            color: "#FFF"
        },
        [theme.breakpoints.down("md")]: {
            width: 100,
        },
        [theme.breakpoints.down("sm")]: {
            width: 70,
            fontSize: 11,
            height: 30,
        }
    },
    change: {
        width: 90,
        height: 40,
        backgroundColor: "rgba(255, 159, 28, 1)",
        color: "#FFF",
        border: "1px solid rgba(255, 159, 28, 1)",
        fontWeight: "bold",
        marginLeft: -15,
        marginTop: -5,
        "&:hover": {
            backgroundColor: "rgba(255, 159, 28, 1)",
            border: "1px solid rgba(255, 159, 28, 1)",
            color: "#FFF"
        },
        "&:disabled": {
            backgroundColor: "#D3D3D3",
            border: "1px solid #D3D3D3",
            color: "#FFF"
        },
        [theme.breakpoints.down("md")]: {
            width: 80,
        },
        [theme.breakpoints.down("sm")]: {
            width: 60,
            fontSize: 11,
            height: 30,
            marginLeft: -25,
            marginTop: 5,
        }
    },
    orange: {
        fontWeight: "bold",
        color: "rgba(255, 159, 28, 1)"
    },
    largeTotalText: {
        fontSize: 22,
        [theme.breakpoints.down("sm")]: {
            fontSize: 16
        }
    },
    smallTotalText: {
        fontSize: 14,
        [theme.breakpoints.down("sm")]: {
            fontSize: 12,
        }
    },
    headerSpacing: {
        paddingRight: theme.spacing(3),
        [theme.breakpoints.down("sm")]: {
            paddingRight: theme.spacing(2),
        }
    },
    bold: {
        color: "#666666",
        fontSize: 16,
        fontWeight: 700,
        [theme.breakpoints.down("sm")]: {
            fontSize: 14,
        }
    },
    normal: {
        color: "#666666",
        fontSize: 16,
        fontWeight: 400,
        [theme.breakpoints.down("sm")]: {
            fontSize: 14,
        }
    },
    addressText: {
        color: "#666666",
        fontSize: 16,
        fontWeight: 400,
        marginBottom: 0,
        whiteSpace: "nowrap",
        [theme.breakpoints.down("sm")]: {
            fontSize: 14,
            marginLeft: 10,
            height: 20
        }
    },
    small: {
        color: "#666666",
        fontSize: 11,
        fontWeight: 400,
        [theme.breakpoints.down("sm")]: {
            fontSize: 10
        }
    },
    address: {
        width: 300,
        marginLeft: 20,
        marginBottom: 0,
        [theme.breakpoints.down("sm")]: {
            width: 300,
            marginLeft: 10
        }
    },
    postcode: {
        width: 160,
        marginLeft: 20,
        marginBottom: 0,
        [theme.breakpoints.down("sm")]: {
            width: 120,
            marginLeft: 10
        }
    },
    reviewImg: {
        height: "auto",
        width: 110,
        border: "1px solid #D3D3D3",
        [theme.breakpoints.down("sm")]: {
            width: 65,
            margin: 5
        }
    },
    helperText: {
        color: "#c32525",
        marginLeft: 0
    },
    addressSelect: {
        "& .MuiSelect-select:focus": {
            backgroundColor: "unset"
        }
    }
});

/* PrintPalFormatsPreview */
export const usePrintPalFormatsPreviewStyles = makeStyles({
    previewWrapper: {
        display: "flex",
        height: "100%",
        flexDirection: "column"
    },
    previewMenuContainer: {
        flex: 1,
        pointerEvents: "none",
        display: "flex",
        position: "relative",
        justifyContent: "center",
        overflow: "hidden",
        border: "1px dashed #888",
        marginTop: "10px",
        marginBottom: "10px",
        backgroundColor: "#eaeaea"

    },
    previewInfoContainer: {
        display: "flex",
        flexDirection: "column"
    },
    arrow: {
        height: 20
    },
    orangeText: {
        color: "#FF9F1C",
        paddingLeft: 30,
        paddingRight: 30,
        width: "100%"
    },
    link: {
        color: "#FF9F1C",
        fontSize: 16,
        fontWeight: 400,
        cursor: "pointer"
    },
    imgTextContainer: {
        verticalAlign: "middle",
        marginTop: 9,
        marginLeft: 10,
        marginBottom: 6
    },
    previewTitle: {
        textAlign: "center",
        fontWeight: "bold",
        color: "#888888"
    },
    paginationContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        margin: "1rem 0"
    },
    downloadPreviewContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "3rem"
    },
    paginationWrapper: {
        width: "25rem",
        margin: "0 auto"
    },
    leftArrowWrapper: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "end"
    },
    rightArrowWrapper: {
        width: "100%",
        display: "flex",
        alignItems: "center",
    }
});

/* PaymentProcessed */
export const paymentProcessedStyles = (theme: Theme) => ({
    root: {
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    box: {
        border: "1px solid #D3D3D3",
        borderRadius: 4,
        fontWeight: 700,
        fontSize: 16,
        [theme.breakpoints.down("sm")]: {
            fontSize: 12,
        }
    },
    bold: {
        color: "#666666",
        fontSize: 16,
        fontWeight: 700,
        [theme.breakpoints.down("sm")]: {
            fontSize: 12,
        }
    },
    normal: {
        color: "#666666",
        fontSize: 16,
        fontWeight: 400,
        [theme.breakpoints.down("sm")]: {
           fontSize: 12,
        }
    },
    orange: {
        fontWeight: "bold",
        color: "rgba(255, 159, 28, 1)"
    },
    content: {
        position: "relative",
        height: "100%",
        overflow: "hidden",
        paddingLeft: 0,
        paddingTop: 30,
        [theme.breakpoints.down("sm")]: {
            paddingTop: 10,
        }
    },
    reviewImg: {
        height: "auto",
        width: 110,
        border: "1px solid #D3D3D3",
        [theme.breakpoints.down("sm")]: {
            width: 90,
            margin: 5
        }
    }
});
import { FormControlLabel, InputAdornment, makeStyles, Radio, RadioGroup, TextField } from "@material-ui/core"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { FieldComponent, FieldEditor } from "../FloatingContentPanel"
import { useSelector, useDispatch } from "react-redux"
import { AppState } from "../../../store"
import { EditableModalContext } from "../../layout/EditableModal"
import { menuActions } from "../../../store/menu"
import { z } from "zod";

const useStyles = makeStyles({
    textSectionContainer: {
        height: "100%",
        width: "100%"
    },
    text: {
        
    },
    label: {
        display: "block"
    },
    color: {
        display: "block",
        width: "26px",
        height: "26px",
        marginRight: "12px",
        cursor: "pointer",
        border: "1px solid",
        boxSizing: "content-box"
    }
});

interface PriceBackgroundSectionData {
    textTitle: string
    textValue: string
    textStyle: any,
    value: string,
    backgroundColor: string,
    rootStyle?: any
    textColor: string
}
interface PriceBackgroundSectionConfig {}

interface IPriceBackgroundEditor extends FieldEditor<PriceBackgroundSectionConfig, PriceBackgroundSectionData> {}

export const PriceBackgroundEditor: React.FC<IPriceBackgroundEditor> = ({ config, field, onChangeField }) => {
    const menuState = useSelector((state: AppState) => state.menu);
    const price = ((menuState.menu ? (menuState.menu[0].props.price as number | undefined) : 0) ?? 0).toString();
    const [priceText, setPriceText] = useState(price);
    const { upsertEvent: addEvent, upsertValidation: addValidation } = useContext(EditableModalContext);
    const [selectedColor, setSelectedColor] = useState<string>(field?.data?.backgroundColor);
    const [selectedTextColor, setSelectedTextColor] = useState<string>(field?.data?.textColor)
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [touched, setTouched] = useState(false);
    const classes = useStyles();
    const dispatch = useDispatch();

    let colors = ["#d7ccc6", "#b8cec1", "#eef1b5", "#f5938b", "#cddaf0", "#48484a", "#488769", "#eed32b", 
        "#c4323a", "#235f8e", "#06080d", "#2c5f48", "#e39027", "#992227", "#0a498a"
    ];
    if(menuState?.menuData?.styleId === 46){
        colors = ["#d7ccc6", "#FFFFF", "#b8cec1", "#eef1b5", "#f5938b", "#cddaf0", "#48484a", "#488769", "#eed32b", 
        "#c4323a", "#235f8e", "#06080d", "#2c5f48", "#e39027", "#992227", "#0a498a"
    ];
    }

    const onPriceTextChange = (value: string) => {
        setTouched(true);
        setPriceText(value);
    }

    const onTextApply = (value: string) => {
        /*
            '' = 0
            if whole (2.00), render as 2
            if only 1 decimal, render as 2 decimals
        */
        if(value == ""){
            value = "0";
        }
        let numValue = parseFloat(value);
        value = numValue.toFixed(Number.isInteger(numValue) ? 0 : 2);
        const dataMapping = {
            price: value
        }
        dispatch(menuActions.editSection("document", dataMapping));
    }

    useEffect(() => {
        onChangeField("mealDealPriceBackground", {
            ...field.data,
            backgroundColor: selectedColor,
            textColor: selectedTextColor,
        });
    }, [selectedColor, selectedTextColor]);

    useEffect(() => {
        let eventId = addEvent("save", "price-save", () => {
            onTextApply(priceText);           
        });

    }, [priceText]);

    useEffect(() => {
        addValidation({
            id: "price",
            currentValue: priceText,
            onInvalid: (message) => { setErrorMessage(message.issues[0].message); },
            onValid: () => {
                setErrorMessage(undefined); 
                onChangeField("mealDealPriceBackground", {
                    ...field.data,
                    textValue: priceText,
                });
            },
            touched: touched,
            schema: z.preprocess(
                (val) => { 
                    const processed = z.string().transform(Number).safeParse(val);
                    return processed.success ? processed.data : val;
                }, z.number({ invalid_type_error: "Invalid price" }).step(0.01, "Invalid price"))   //Will throw an error if price is not divisible evenly by 0.01 (i.e. 2.123)
        })
    }, [priceText, touched])

    return(
        <div>
            <span className={classes.label}>Box colour</span>
            <div style={{display: "flex", margin: "12px 0"}}>
                {
                    colors.map((row) => {
                        return(
                            <span 
                                style={{background: row, border: `${selectedColor === row ? "3px solid #ff0000" : "1px solid"}`}} 
                                key={row} 
                                className={classes.color}
                                onClick={()=> setSelectedColor(row)}
                            >
                            </span>
                        )
                    })
                }
            </div>
            <RadioGroup
                row
                value={selectedColor}
                onChange={(e) => { 
                    setSelectedColor(e.target.value as string)
                }}
            >
                <FormControlLabel control={<Radio/>} value={"transparent"} label={"Transparent"} style={{fontWeight: 900}}/>
            </RadioGroup>
            <span className={classes.label}>Text colour</span>
            <div style={{display: "flex", margin: "12px 0"}}>
                {
                    colors.map((row) => {
                        return(
                            <span 
                                style={{background: row, border: `${selectedTextColor === row ? "3px solid #ff0000" : "1px solid"}`}} 
                                key={row} 
                                className={classes.color}
                                onClick={()=> setSelectedTextColor(row)}
                            >
                            </span>
                        )
                    })
                }
            </div>
            <TextField 
                id={field.name} 
                error={errorMessage != undefined} 
                helperText={errorMessage} 
                label={"Price"} 
                value={priceText}
                fullWidth 
                onChange={(e) =>{ onPriceTextChange(e.target.value); }} 
                    InputProps={{
                        startAdornment: <InputAdornment position="start">£</InputAdornment>
                    }}
                />
        </div>
    )
}

interface IPriceBackgroundSection extends FieldComponent<PriceBackgroundSectionData> {}

export const PriceBackground: React.FC<IPriceBackgroundSection> = ({ field }) => {
    const { textValue, backgroundColor, rootStyle } = field.data;
    const classes = useStyles();
    const menuState = useSelector((state: AppState) => state.menu);
    const price = ((menuState.menu ? (menuState.menu[0].props.price as number | undefined) : 0) ?? 0).toString();

    const [whole, decimal] = useMemo(() => { return (price ?? "").split("."); }, [price]);
    let fontColor = field?.data?.textColor;
    return(
        <div style={{...rootStyle}} className={classes.textSectionContainer}>
            <div style={{
                backgroundColor: backgroundColor,
                fontFamily: "DS-DIGIT",
                textAlign: "center",
                height: "100%",
                width: "100%",
                color: fontColor
            }} className={classes.text}>
                <span style={{fontSize: "0.6em", fontStyle: "italic"}}>£</span>
                <span>{whole}</span>
                    {
                        decimal ?
                        <span>.{decimal}</span>
                        :
                        <span>.00</span>
                    }
            </div>
        </div>
    )
}
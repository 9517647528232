import { ActionCreator } from "redux";
import {
    ORDERS_LOADING, OrdersLoading, GET_ORDERS, GetOrders, PAPER_OPTIONS_LOADING, PaperOptionsLoading, GET_PAPER_OPTIONS, GetPaperOptions, Purchase, PURCHASE, Authorising,
    PRINTPAL_CONFIG_LOADING, GET_PRINTPAL_CONFIG, GetPrintPalConfig, PrintPalConfigLoading, IPrintPalConfig, GET_TOKEN, TOKEN_LOADING, GetToken, TokenLoading, PAYMENT_AUTHORISATION,
    Checkout, CHECKOUT, VoucherResult, VOUCHER_RESULT, SetOrderStatus, SET_ORDER_STATUS
} from "./types";

export const ordersLoading: ActionCreator<OrdersLoading> = (loading: boolean) => ({
    type: ORDERS_LOADING,
    loading
});

export const getOrders: ActionCreator<GetOrders> = (orderData: []) => ({
    type: GET_ORDERS,
    orderData
});

export const paperOptionsLoading: ActionCreator<PaperOptionsLoading> = (loading: boolean) => ({
    type: PAPER_OPTIONS_LOADING,
    loading
});

export const getPaperOptions: ActionCreator<GetPaperOptions> = (paperData: []) => ({
    type: GET_PAPER_OPTIONS,
    paperData
});

export const printPalConfigLoading: ActionCreator<PrintPalConfigLoading> = (loading: boolean) => ({
    type: PRINTPAL_CONFIG_LOADING,
    loading
});

export const getPrintPalConfig: ActionCreator<GetPrintPalConfig> = (config: IPrintPalConfig) => ({
    type: GET_PRINTPAL_CONFIG,
    config
});

export const tokenLoading: ActionCreator<TokenLoading> = (loading: boolean) => ({
    type: TOKEN_LOADING,
    loading
});

export const getToken: ActionCreator<GetToken> = (token: string) => ({
    type: GET_TOKEN,
    token
});

export const authorising: ActionCreator<Authorising> = (authorising: boolean) => ({
    type: PAYMENT_AUTHORISATION,
    authorising
});

export const purchase: ActionCreator<Purchase> = (authorisation: any) => ({
    type: PURCHASE,
    authorisation
});

export const checkout: ActionCreator<Checkout> = (checkout: boolean) => ({
    type: CHECKOUT,
    checkout
});

export const voucherResult: ActionCreator<VoucherResult> = (valid: boolean) => ({
    type: VOUCHER_RESULT,
    valid
});

export const setOrderStatus: ActionCreator<SetOrderStatus> = (orderId: number, statusId: number) => ({
    type: SET_ORDER_STATUS,
    orderId,
    statusId
}); 

export default { ordersLoading, getOrders, paperOptionsLoading, getPaperOptions, printPalConfigLoading, getPrintPalConfig, tokenLoading, getToken, authorising, purchase, checkout, voucherResult, setOrderStatus };
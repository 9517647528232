import React from "react";
import { Container, makeStyles, createStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        text: {
            paddingLeft: 60,
            paddingTop: 20,
            fontWeight: 400,
            fontSize: 16,
            color: "#333333",
        },
        container: {
            background: "rgba(243, 243, 243, 1)",
            borderTop: "3px solid rgba(255, 159, 28, 1)",
            height: 50,
            [theme.breakpoints.down("sm")]: {
                height: 30, 
            },
        }
    }),
);

export default function PrintPalFooter() {

    const classes = useStyles(); 

    return <div style={{ backgroundColor: "#FFF"}}>
        <Container maxWidth={false}>
            <div className={classes.container}/>
        </Container>
    </div>
}
import React, { useEffect, useRef, useState } from "react";
import TripleStack from "./TripleStack";
import LoadingSpinner from "../LoadingSpinner";
import { useFormik } from 'formik';
import * as Yup from 'yup';

import PosButton from "../posButton/PosButton";
import Validation from "../../../utils/validationFunctions";

import PDFIcon from "../../../assets/pdf.png";
import Field, { FormikForm } from "../field/Field";
import { FilterOption } from "../Filters";
import { cleanDate } from "../../../pages/POS/OrdersPage";
import { useSelector } from "react-redux";

function OrderDetails(props: OrderDetailsProps) {
    const pdfFileName = props.data.orderNumber + "_preview.pdf";

    const [exportingPdf, setExportingPdf] = useState(false);
    const [pdfText, setPdfText] = useState(pdfFileName);

    const pdfAbortController = new AbortController();
    const clearTimerRef = useRef<undefined | ReturnType<typeof setTimeout>>();
    const pollingInterval = 3000;
    const maxPolls = 20;

    const [progress, setProgress] = useState(0);

    useEffect(() => {
        let interval: undefined | ReturnType<typeof setInterval>;

        if (exportingPdf) {
            interval = setInterval(() => {
                setProgress((prevProgress) => (prevProgress + 1) % 3);
            }, 1000);
        }

        return (() => clearInterval(interval));
    }, [exportingPdf]);

    let r: CustomerOrderDTO = props.data;

    const d: CustomerOrderDTO = {
        ...props.data,
        //useBillingAddress: false,
        //id: r.id,
        firstName: r.shippingAddress.firstName ?? '',
        lastName: r.shippingAddress.lastName ?? '',
        company: r.shippingAddress.company ?? '',
        address1: r.shippingAddress.address1,
        address2: r.shippingAddress.address2,
        address3: r.shippingAddress.address3,
        city: r.shippingAddress.city,
        county: r.shippingAddress.county,
        country: 'UK',
        postcode: r.shippingAddress.postcode,
        telephone: r.shippingAddress.telephone ?? '',
        shippingDate: formatDate(r.shippingDate),
        notes: r.notes ?? '',

        /*notes: r.notes ?? '',
        orderNumber: r.orderNumber,
        orderDate: r.orderDate,
        projectTitle: '',
        stock: r.paperName,
        quantity: r.quantity,
        orderPrice: r.cost,
        status: r.status,
        voucher: r.voucheCode ?? '',
        shippingDate: r.shippingDate ?? '',
        trackingNumber: r.trackingNumber ?? '',
        datePdfGenerated: undefined,*/

    }

    const mealDealMessaging = useSelector((state: any) => state.global.session.mealDealMessaging);


    const customerDetailsSchema = Yup.object().shape({

        firstName: Yup.string().required(Validation.errorMessages.required),
        lastName: Yup.string().required(Validation.errorMessages.required),

        useBillingAddress: Yup.boolean(),
        company: Yup.string().required(Validation.errorMessages.required),

        address1: Yup.string().required(Validation.errorMessages.required),
        address2: Yup.string(),
        address3: Yup.string(),
        city: Yup.string().required(Validation.errorMessages.required),
        county: Yup.string(),
        country: Yup.string().max(2, 'Use a code shorter than 2 letters.'),
        postcode: Yup.string().matches(Validation.postCodeRegExp, Validation.errorMessages.postcode).required(Validation.errorMessages.required),
        telephone: Yup.string().matches(Validation.phoneRegExp, Validation.errorMessages.telephone),
        notes: Yup.string().nullable(),
        orderNumber: Yup.string(), // STATIC START
        orderDate: Yup.string(),
        menuTitle: Yup.string(),
        design: Yup.string(),
        stock: Yup.string(),
        quantity: Yup.number().typeError(Validation.errorMessages.number),
        cost: Yup.string(), // STATIC END
        status: Yup.string().required(Validation.errorMessages.pickone),
        voucher: Yup.string(),
        shippingDate: Yup.date().typeError(Validation.errorMessages.date),
        trackingNumber: Yup.string().nullable(),
        contractCaterer: Yup.string().nullable()

    });



    const formik = useFormik({
        initialValues: d,
        onSubmit: values => {
            props.save(values);
        },
        enableReinitialize: true,
        validationSchema: customerDetailsSchema,
        validateOnChange: true,
        validateOnMount: true,
    });

    const form: FormikForm = {
        ...formik,
        allDisabled: props.isSaving
    }

    /*
     # Implementing multiple addresses
      - IF user has billing address THEN
            Enabled use billing address
        ELSE
            Disable use billing address

      - IF billing address is selected THEN
            Disable all other fields
            Set all other fields to billing address
        ELSE
            do nothing

      - ON FORM SUBMIT
            send all data, it will be correct as the UI fills out the correct data
            update address with original provided ID.
     
     */


    useEffect(() => {

        (async () => {
            for (const key of Object.keys(props.data.shippingAddress)) {
                console.log(key);
                await formik.setFieldValue(key, props.data.shippingAddress[key] ?? '');
            }
        })();

    }, []);

    const downloadFile = (data: Blob) => {
        const utf8BOM = new Uint8Array([0xEF, 0xBB, 0xBF]); // BOM for UTF-8
        const blob = new Blob([utf8BOM, data], { type: "application/pdf;charset=UTF-8" });
        const a = document.createElement('a')
        a.download = pdfFileName
        a.href = window.URL.createObjectURL(blob)

        a.click()
        a.remove()
    }

    const handleDownloadPdf = async (orderId: number, count: number): Promise<void | Blob> => {
        if (count === 0) {
            setExportingPdf(true);
        }

        if (count === maxPolls) {
            setExportingPdf(false);
            return Promise.reject(new Error("Max retry attempts reached"));
        }

        return fetch(`/api/export/getOrderPdf?orderId=${orderId}`, {
            cache: "no-cache",
            method: "GET",
            signal: pdfAbortController.signal,
        })
            .then(response => {
                switch (response.status) {
                    case 200:
                        return response.blob();
                    case 404:
                    case 409:
                        return new Promise((resolve) => {
                            clearTimerRef.current = setTimeout(() => {
                                resolve(handleDownloadPdf(orderId, count + 1))
                            }, pollingInterval);
                        });
                    default:
                        throw new Error(`Unexpected status code: ${response.status}`);
                }
            })
            .then(data => {
                if (data instanceof Blob) {
                    downloadFile(data);
                    setExportingPdf(false);
                }
            })
            .catch(error => {
                console.error(error);
            })
    }

    useEffect(() => {
        if (exportingPdf) {
            let dots = Array(progress).fill('.').join('');
            setPdfText(`Downloading.${dots}`);
        } else {
            setPdfText(pdfFileName);
        }
    }, [exportingPdf, progress]);

    useEffect(() => {
        return () => {
            pdfAbortController.abort();
            clearTimeout(clearTimerRef.current);
        }
    }, [])

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <TripleStack>
                    <div style={{paddingBottom: 30}}>
                        <h3 style={{ margin: "2px 0" }}>Customer delivery address</h3>
                    </div>                    

                    <table style={{ width: "100%", padding: "0 30px" }}><tbody>


                    </tbody>
                        {<tbody>

                            <Field id="firstName" isRequired form={form} />
                            <Field id="lastName"  isRequired form={form} />


                            {mealDealMessaging &&
                            <Field
                                title={<div style={{ width: 500, marginLeft: "calc(-500px + 140px)" /*Text needs to backflow into previous column*/}}>Contract caterer<br />(eg Compass, Sodexo or independant)</div>}
                                id="contractCaterer"
                                form={form}
                                />}

                            <Field id="company" form={form} isRequired/>
                            <Field id="address1" isRequired form={form} />
                            <Field id="address2"  form={form} />
                            <Field id="address3"  form={form} />

                            <Field id="city" isRequired form={form} />
                            <Field id="county" form={form} />
                            <Field id="country" specialType="readonly" form={form} />
                            <Field id="postcode" isRequired form={form} />
                            <Field id="telephone" form={form} />
                        </tbody>}

                        <tbody>
                            <Field id="notes" specialType="textarea" form={form} />

                            </tbody>
                    </table>

                    <table style={{ width: "100%", padding: "0 30px" }}><tbody>

                        <Field id="orderNumber" specialType="readonly" form={form} />
                        <Field format="date" id="orderDate" specialType="readonly" form={form} />
                        <Field id="menuTitle" placeholder="No project title" specialType="readonly" form={form} />
                        <Field id="design" placeholder="No design" specialType="readonly" form={form} />
                        <Field id="paperSize" title="Stock" specialType="readonly" form={form} />
                        <Field id="quantity" specialType="readonly" form={form} />
                        <Field format="currency" id="cost" title="Order price" specialType="readonly" form={form} />
                        <Field id="status" form={form} specialType="select">
                            {
                                    props.orderStates?.map(filterOption => <option
                                        key={filterOption.value}
                                        value={filterOption.value}>{ filterOption.text === "All" ? 'None' : filterOption.text}
                                            </option>
                                    )
                            }
                        </Field>

                        <Field title="Voucher" specialType="readonly" placeholder="No voucher" id="voucheCode" form={form} />
                        <Field id="shippingDate" type="date" form={form} />
                        <Field id="trackingNumber" form={form} />

                        {d.paperName !== "Magic 8 stickers" &&
                            <tr style={{ cursor: props.data.snapshotFileId === null ? "initial" : "pointer" }}
                                onClick={() => {
                                    if (!exportingPdf) {
                                        handleDownloadPdf(d.id, 0);
                                    }
                                }}>

                                <td style={{ height: 60, filter: props.data.snapshotFileId === null ? "grayscale(100%)" : "" }}> <img style={{ float: "right" }} width={28} height={34} src={PDFIcon} /></td>
                                <td></td>

                                <td> {
                                    props.data.snapshotFileId !== null
                                    && <span style={{ color: "inherit" }}>
                                        {pdfText}<br />
                                    </span>
                                } </td>

                            </tr>
                        }

                        <tr>
                            <td></td>
                            <td></td>
                            <td>
                                <br />
                                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", maxWidth: 350 }}>
                                    <LoadingSpinner isFinished={!props.isSaving} width={45} style={{ width: "initial" }} />
                                    <PosButton isDisabled={props.isSaving} text="Cancel" style={{marginRight: 0}} isSecondary onClick={props.onCancel}></PosButton>
                                    <PosButton isDisabled={props.isSaving} onClick={() => { console.log(formik.errors); console.log(formik.values); }} text="Save" type="submit"></PosButton>
                                </div>
                                { Object.keys(formik.errors).length > 0 && formik.errors.status === undefined && <p style={{ textAlign: "center", color: "#ffa40b" }}>Correctly fill in the billing address above.</p>}
                            </td>
                        </tr>
                    </tbody></table>

                </TripleStack>
                <br/>
                <br/>
                <br />
               
            </form>


        </>
    )
}

interface OrderDetailsProps {
    data: any;
    onCancel: () => void;
    isSaving: boolean;
    save: (data) => void;
    orderStates: FilterOption[] | undefined;

}

export interface ShippingAddress {
    addressId: number;
    address1: string;
    address2: string;
    address3: string;
    city: string;
    county: string;
    country: string;
    postcode: string;
    title: string;
    firstName: string;
    lastName: string;
    isPrimaryShipping: boolean;
    isBilling: boolean;
    telephone: string;
    email?: any;
    company: string;
}

export interface CustomerOrderDTO {
    id: number;
    orderNumber: string;
    quantity: number;
    cost: number;
    orderDate: Date;
    status: string;
    orderStatus: string;
    name: string;
    address: string;
    imgUrl: string;
    snapshotFileId: number;
    paperSize: string;
    voucheCode?: any;
    paperName: string;
    trackingNumber: string;
    shippingDate: string;
    notes: string;
    userId: number;
    contractCaterer: string;
    shippingAddress: ShippingAddress;
    design: string;
}

function formatDate(date: string | null): string {

    if (date === '' || date === null)
        return '';

    const d = new Date(date);

    return `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')}`
}

export default OrderDetails;

﻿import { Action } from "redux";
import IMyMenu from "../../interfaces/IMyMenu";

export const PRINTPAL_PAGE = "PRINTPAL_PAGE";
export const MENU_LOADING = "MENU_LOADING";
export const GET_MENUS = "GET_MENUS";
export const FORMATS_LOADING = "FORMATS_LOADING";
export const GET_FORMATS = "GET_FORMATS";
export const STYLES_LOADING = "STYLES_LOADING";
export const GET_STYLES = "GET_STYLES";
export const OPEN_POPUP = "OPEN_POPUP";
export const CLOSE_POPUP = "CLOSE_POPUP";
export const SET_MYMENU = "SET_MYMENU";
export const SAVE_MENU = "SAVE_MENU";
export const REMOVE_MYMENU = "REMOVE_MYMENU";

export interface IMyMenuState {
    isPopupOpen: boolean;
    loading: boolean;
    data: IMyMenu[];
    printPalPage: boolean;
    formatData: any[];
    styleData: any[];
    isMobileApp: boolean;
    saving: boolean;
}

export interface OpenPopup extends Action {
    type: typeof OPEN_POPUP;
}

export interface ClosePopup extends Action {
    type: typeof CLOSE_POPUP;
}

export interface MenuLoading extends Action {
    type: typeof MENU_LOADING;
    loading: boolean;
}

export interface GetMenus extends Action {
    type: typeof GET_MENUS;
    data: IMyMenu[];
}

export interface PrintPalPage extends Action {
    type: typeof PRINTPAL_PAGE;
    printPalPage: boolean;
}

export interface FormatsLoading extends Action {
    type: typeof FORMATS_LOADING;
    loading: boolean;
}

export interface GetFormats extends Action {
    type: typeof GET_FORMATS;
    formatData: any[];
}

export interface StylesLoading extends Action {
    type: typeof STYLES_LOADING;
    loading: boolean;
}

export interface GetStyles extends Action {
    type: typeof GET_STYLES;
    styleData: any[];
}

export interface SavingMenu extends Action {
    type: typeof SAVE_MENU;
    saving: boolean;
}

export interface SetMyMenu extends Action {
    type: typeof SET_MYMENU;
    id: number;
    title: string;
}

export interface RemoveMyMenu extends Action {
    type: typeof REMOVE_MYMENU;
    id: number;
}

export type Actions = MenuLoading | GetMenus | FormatsLoading | GetFormats | OpenPopup | ClosePopup | StylesLoading | GetStyles | PrintPalPage | SavingMenu | SetMyMenu | RemoveMyMenu;


import { makeStyles } from "@material-ui/styles";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { IBritvicProduct } from "../../interfaces/IBritvicProduct";
import { IModalData } from "../../store/menu";
import { getProductsByTagName } from "../../store/menu/operations";
import { getScaledImage } from "../../utils/common";
import useHelperMethods from "../../utils/useHelperMethods";
import Editable from "../decorators/Editable";
import EditableAbsolute from "../decorators/EditableAbsolute";
import ProductPicker from "../form/ProductPicker";
import { ContainerContext } from "./Container";
import { useSelector } from "react-redux";
import { AppState } from "../../store";

interface ProductSlot {
    title: string
    productType?: string
    translations: Translation[]
    translations5?: Translation[]
    translations4?: Translation[]
    translations3?: Translation[]
    translations2?: Translation[]
    translations1?: Translation[]
}

export interface FloatingProductPanelConfig extends SharedComponentConfig {
    productType: string
    orientation?: string
    productSlots: ProductSlot[]
    editablePosition: []
    editablePosition5?: []
    editablePosition4?: []
    editablePosition3?: []
    editablePosition2?: []
    editablePosition1?: []
}

interface IFloatingProductPanel{
    id: string
    products: (string | null)[]
    formatSize: string
    scale: number
    config: FloatingProductPanelConfig | null
    isHovering: boolean
}

export interface FloatingProductPanelDataMapping {
    products: (string | null)[]
}

const useStyles = makeStyles({

});

const FloatingProductPanel: React.FC<IFloatingProductPanel> = ({ id, products, config, formatSize, scale, isHovering}) => {

    const classes = useStyles();    
    const menuState = useSelector((state: AppState) => state.menu);
    const targetProductGroup = useMemo(() => {
        return config?.productType
    }, [config]);   

    const { context } = useContext(ContainerContext);
    const { getScaledPosition } = useHelperMethods();

    /*

        TODO                
                <div - current selected, map over dataMapping["products"] and show each image if not null
                    space between, 
                <div - available products, fetched onload

    */  
    const modalData: IModalData<FloatingProductPanelDataMapping> = {
        id,
        name: "Product Panel",
        showMandatory: false,
        editPanelTitle: config?.editPanelTitle,
        editPanelSubtitle: "Select a product for this location, or select 'LEAVE EMPTY' to show no product.",
        initialValues: {
            products
        },
        fields: [
            {
                render: (onChange, dataMapping, state) => {
                    return <ProductPicker dataMapping={dataMapping} onChange={onChange} state={state} productTag={targetProductGroup} config={config} id={id}/>
                }
            }
        ]
    }

    const RenderProduct = (position: TranslationPosition, product: string) => {
        const { width, height, left, top, z  } = getScaledPosition(scale, formatSize, position);
        return (
            <img style={{
                width,
                height,
                left,
                top,
                zIndex: z,
                position: "absolute"
            }} src={getScaledImage(`/assets/products/${product}`, position.width * scale, (context == "PRINT" && scale == 1) ? true : false)} />
        )
    }

    if (scale === 0) {
        console.log("Scale was 0 (unset), skipping this render.");
        return null;
    } else {
        // console.log(`Scale was ${scale} (set), rendering.`);
    }
    let allProducts = true;
    let emptyElements = 0;
    // Check number of drinks for Template 4,5 -> Update positions if less drinks avaialable. 
    // translations5 - used for 5 drinks positions
    // translations4 - used for 4 drinks positions
    if(menuState?.menu && menuState?.menu.length > 0 && (menuState?.menuData?.styleId === 43 || menuState?.menuData?.styleId === 44)){
        let menuItems = menuState?.menu[0]?.props?.items;
        let floatingProductPanelItems = menuItems.filter((row: {component: string}) => row?.component === "FloatingProductPanel");
        floatingProductPanelItems.forEach((element: {props: {products: string[]}}, index: number) => {
            if(element?.props?.products && element?.props?.products.length > 0){
                if(!element?.props?.products[0]){
                    allProducts = false;
                    emptyElements += 1;
                }
            }
        });
    }
    let editablePosition = config?.editablePosition;
    if(!allProducts){
        if(emptyElements === 1){
            editablePosition = config?.editablePosition5;
        }else if(emptyElements === 2){
            editablePosition = config?.editablePosition4;
        }else if(emptyElements === 3){
            editablePosition = config?.editablePosition3;
        }else if(emptyElements === 4){
            editablePosition = config?.editablePosition2;
        }else if(emptyElements === 5){
            editablePosition = config?.editablePosition1;
        }
    }
    return (
        <EditableAbsolute position={editablePosition} data={modalData}>
            <>
            {
                products.map((product, i) => {
                    if(product){
                        if(formatSize){
                            if(config){
                                const productSlot = config.productSlots[i];
                                let translation = productSlot.translations.find((t) => t.format == formatSize);
                                if(!allProducts){
                                    if(emptyElements === 1 && productSlot?.translations5){
                                        translation = productSlot?.translations5.find((t) => t.format == formatSize);
                                    }else if(emptyElements === 2 && productSlot?.translations4){
                                        translation = productSlot?.translations4.find((t) => t.format == formatSize);
                                    }else if(emptyElements === 3 && productSlot?.translations3){
                                        translation = productSlot?.translations3.find((t) => t.format == formatSize);
                                    }else if(emptyElements === 4 && productSlot?.translations2){
                                        translation = productSlot?.translations2.find((t) => t.format == formatSize);
                                    }else if(emptyElements === 5 && productSlot?.translations1){
                                        translation = productSlot?.translations1.find((t) => t.format == formatSize);
                                    }
                                }
                                if(translation){
                                    if(Array.isArray(translation.position)){
                                        return translation.position.map((position) => {
                                            return RenderProduct(position, product);
                                        })
                                    } else {
                                        return RenderProduct(translation.position, product);   
                                    }
                                } else {
                                    console.log("Cannot find translation with formatSize of", formatSize, productSlot.translations);
                                }
                            } else {
                                console.log("config is null");
                            }
                        } else {
                            console.log("formatSize undefined");
                        }
                    }
                })
            }
            </>
        </EditableAbsolute>
    )
}

export default FloatingProductPanel;
import { useContext } from "react";
import { StylesContext } from "@material-ui/styles/StylesProvider";

interface IIsExportingProps {
    children: (exporting: boolean) => any
}

function IsExporting(props: IIsExportingProps): any {
    const styles = useContext(StylesContext) as any;
    
    var exporting = styles.jss?.options?.Renderer === null ?? false;

    //Allow window to force the exporting flag
    if ((window as any).ForceExporting)
        exporting = true;

    
    
    return props.children(exporting);
}

export default IsExporting;
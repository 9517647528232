import { Grid, Typography, WithStyles, FormControlLabel, Divider, Checkbox} from "@material-ui/core";
import React from "react";
import Editable from "../decorators/Editable";
import StyleInjector from "../decorators/StyleInjector";
import TextField from "../form/TextField";
import { menuOperations } from "../../store/menu";
import { connect } from "react-redux";
import ICustomTheme from "../../interfaces/ICustomTheme";

interface IExtendedTitleProps {
    id: string,
    title: string,
    desc: string,
    openingHours?: string,    
    address?: string,
    showClockImage?: boolean,
    showLocationImage?: boolean,    
    theme: ICustomTheme
}

const styles = ({  
});

function Appearance(props: { onChange: any, dataMapping: any }) {
    const { onChange, dataMapping } = props;

    return <Grid container style={{ marginBottom: 30 }}>

        <Grid item xs={12}>
            <FormControlLabel 
                    style={{marginBottom: 15, marginTop: 10, fontSize: 14 }}
                    name="showClockImage" 
                    onChange={onChange} 
                    checked={dataMapping["showClockImage"]} 
                    label={<span style={{ fontSize: 14 }}>Show Clock image</span>}
                    labelPlacement="end"
                    control={<Checkbox />} />   
        </Grid>

        <Grid item xs={12}>
            <FormControlLabel 
                    style={{marginBottom: 15, marginTop: 10, fontSize: 14 }}
                    name="showLocationImage" 
                    onChange={onChange} 
                    checked={dataMapping["showLocationImage"]} 
                    label={<span style={{ fontSize: 14 }}>Show Location image</span>}
                    labelPlacement="end"
                    control={<Checkbox />} />   
        </Grid>        

        <Grid item xs={12}>
            <Divider style={{ marginTop: 15}} />
        </Grid>
    </Grid>
}

function ExtendedTitle(props: IExtendedTitleProps & WithStyles) {
    const { id, title, address, desc, classes, theme, showClockImage, showLocationImage, openingHours } = props;    

    const data = {
        id,
        name: "Extended Title",
        initialValues: {
            title,
            desc,
            address,
            openingHours,
            theme,
            showClockImage,
            showLocationImage
        },
        state: {
        },
        fields: [
            {                    
                label: "Appearance",
                render: (onChange: any, dataMapping: any) => {
                return <Appearance onChange={onChange} dataMapping={dataMapping}/>
                }
            },
            {
                label: "Title",
                required: true,
                render: (onChange: any, dataMapping: any) => {
                    return <TextField name="title" value={dataMapping["title"]} onChange={onChange} />
                }
            },
            {
                label: "Description",
                render: (onChange: any, dataMapping: any) => {
                    return <TextField fullWidth name="desc" value={dataMapping["desc"]} onChange={onChange} />
                }
            },            
            {
                label: "Opening hours",
                required: true,
                render: (onChange: any, dataMapping: any) => {
                    return <TextField fullWidth name="openingHours" value={dataMapping["openingHours"]} onChange={onChange} />
                }
            },

            {
                label: "Address",
                render: (onChange: any, dataMapping: any) => {
                    return <TextField fullWidth name="address" value={dataMapping["address"]} onChange={onChange} />
                }
            }                                                       
        ]
    }; 

    return <Editable data={data} fullHeight>
            <Grid container className={classes.root} direction="column" alignContent="flex-start" alignItems="flex-start" justify="flex-start">
                <Grid item className={classes.container}>
                    <Typography variant="h1" className={classes.title}>{title}</Typography>
                    <div className={classes.underline}/>
                    <Typography variant="h2" className={classes.desc}>{desc}</Typography>
                </Grid>
                <Grid item>
                    {showClockImage && <img src={theme.extendedTitle?.clock} className={classes.clockImage}/>}
                    <p className={classes.openingHours}>{openingHours}</p>
                    {showLocationImage && <img src={theme.extendedTitle?.location} className={classes.locationImage}/>}
                    <p className={classes.address}>{address}</p>    
                </Grid>
            </Grid>

    </Editable>;
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        uploadFile: (file: any) => dispatch(menuOperations.uploadFile(file))
    }
}

export default connect(null, mapDispatchToProps)(StyleInjector(styles, "extendedTitle", true)(ExtendedTitle));
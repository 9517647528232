import React from "react";
import { Grid, Typography, Theme, withStyles, WithStyles, Breadcrumbs, Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import FeaturedStyles from "../components/chooseAStyle/FeaturedStyles";
import AllStyles from "../components/chooseAStyle/AllStyles";
import StyleInfoPopup from "../components/chooseAStyle/StyleInfoPopup";
import CircularProgress from '@material-ui/core/CircularProgress';

// Store
import { connect } from "react-redux";
import { AppState } from "../store";
import { IMyMenuState, myMenuActions, myMenuOperations } from "../store/myMenu";
import { menuActions } from "../store/menu";
import { globalActions } from "../store/global";
import FileNamePopup from "../components/chooseAFormat/FileNamePopup";


const styles = (theme: Theme) => ({
    root: {
        display: 'flex',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    container: {
        background: "#f8efed",
        overflow: "hidden",
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
        paddingTop: theme.spacing(30),
        paddingBottom: theme.spacing(60),
        [theme.breakpoints.down("sm")]: {
            paddingTop: theme.spacing(10),
            paddingBottom: theme.spacing(10),
        }
    },
    toolbar: {
        background: "#FFF",
        height: 100,
        paddingLeft: theme.spacing(7),
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
        [theme.breakpoints.down("sm")]: {
            height: 60,
            paddingLeft: theme.spacing(2),
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            fontSize: 10,
        }
    },
    stepText: {
        color: "#FFF",
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
        "& h1": {
            marginBottom: theme.spacing(1),
        },
        [theme.breakpoints.down("sm")]: {
            marginTop: theme.spacing(0),
            marginBottom: theme.spacing(0),
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2)
        }
    },
    link: {
        display: 'flex',
        color: "#007D7A",
        fontWeight: "bold" as "bold",
        [theme.breakpoints.down("sm")]: {
            fontSize: 12,
        }
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 30,
        height: 30,
        [theme.breakpoints.down("sm")]: {
            width: 20,
            height: 20,
        }
    },
    menuStepText: {
        color: "#000",
        [theme.breakpoints.down("sm")]: {
            fontSize: 12,
        }
    },
});

interface IProps {
    myMenu: IMyMenuState;
    openPopup: any;
    closePopup: any;
    showNavigation: any;
    printPalPage: any;
    getStyles: any;
    resetMenu: any;
    createMenu: (formatStyleId: number, name: string) => void
}

interface IState {
    isMobileApp: boolean;
    targetedStyle?: any
}

class ChooseAStyle extends React.Component<WithStyles & IProps, IState> {
    mounted: boolean = false;
    constructor(props: any) {
        super(props);
        //debugger;
        this.props.printPalPage(false);
        this.props.showNavigation(true);

        this.handleOpenPopup = this.handleOpenPopup.bind(this);
        this.handleClosePopup = this.handleClosePopup.bind(this);    

        this.state = { isMobileApp: false };
    }

    componentDidMount() {
        this.props.getStyles();
        this.props.resetMenu();
        this.mounted = true;
        this.setResponsiveness();
        window.addEventListener("resize", () => this.setResponsiveness());
    }

    componentWillUnmount() {
        this.mounted = false;
        window.removeEventListener('resize', () => this.setResponsiveness());
    }

    setResponsiveness = () => {
        if (this.mounted)
            return window.innerWidth < 900
            ? this.setState((prevState) => ({ ...prevState, isMobileApp: true }))
            : this.setState((prevState) => ({ ...prevState, isMobileApp: false }));
    };

    handleOpenPopup() {
        this.props.openPopup();
    }

    handleClosePopup() {
        this.setState({ targetedStyle: undefined })
        this.props.closePopup();
    }

    setTargetStyle(style: any, callback?: () => void) {
        console.log("selecting style", style);
        this.setState({ targetedStyle: style }, callback);
    }

    render() {
        const { classes, myMenu, createMenu } = this.props;
        const { styleData, loading } = myMenu;

        ////debugger

        return (
            <div style={{ minHeight: 'calc(100vh - 293px)' }}>
                <Grid container>
                    <Grid item xs={12} className={classes.stepText}>
                        <Typography variant={this.state.isMobileApp ? "h2" : "h1"}>
                            Choose your style
                        </Typography>

                        <Typography variant={this.state.isMobileApp ? "body2" : "body1"}>
                            Choose your expertly designed style based on a look that compliments your outlet and the type of customers you serve.{!this.state.isMobileApp ? <br/> : null} 
                            The correct design and layout will help you improve your sales.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} className={classes.toolbar}>
                        <Grid container alignItems="flex-start" spacing={this.state.isMobileApp ? 1 : 5} direction="column">
                            <Grid item>
                                <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize={this.state.isMobileApp ? "small" : "default"} />}>
                                    <Link color="inherit" href="/" className={classes.link}>
                                        <HomeIcon className={classes.icon} />
                                    </Link>

                                    <Link color="inherit" component={RouterLink} to="/" className={classes.link}>My designs</Link>
                                    <Typography className={classes.menuStepText} color="textPrimary"><strong>Choose your style</strong></Typography>
                                </Breadcrumbs>
                            </Grid>
                        </Grid>
                    </Grid>
                    {
                        loading || Object.keys(styleData).length === 0
                        ?
                        <Grid container justify="center" className={classes.container}>
                            <div style={{ display: "flex"}}>
                                <CircularProgress color="secondary" />
                            </div>
                        </Grid>
                        :
                        <Grid container>

                            {
                                this.state.targetedStyle != undefined 
                                ?
                                    <Grid item xs>
                                        <FileNamePopup
                                            isPopupOpen={myMenu.isPopupOpen}
                                            closePopup={this.handleClosePopup}
                                            createMenu={createMenu} />
                                    </Grid>
                                :
                                    <Grid item xs>
                                        <StyleInfoPopup
                                                {...myMenu}
                                            session={ this.props.session}
                                            setTargetStyle={this.setTargetStyle.bind(this)}
                                            openPopup={this.handleOpenPopup}
                                            closePopup={this.handleClosePopup} />
                                    </Grid>
                            }
                            
            
                            <Grid item xs={12}>       
                                <FeaturedStyles
                                    {...myMenu}
                                    setTargetStyle={this.setTargetStyle.bind(this)}
                                    openPopup={this.handleOpenPopup} />
                            </Grid>

                            <Grid item xs>       
                               <AllStyles
                                    {...myMenu}
                                    setTargetStyle={this.setTargetStyle.bind(this)}
                                    openPopup={this.handleOpenPopup}
                                    closePopup={this.handleClosePopup} />
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    myMenu: state.myMenu,
    session: state.global.session
});




const mapDispatchToProps = (dispatch: any) => {
    return {
        openPopup: () => dispatch(myMenuActions.openPopup()),
        closePopup: () => dispatch(myMenuActions.closePopup()),
        getStyles: () => dispatch(myMenuOperations.getAllStyles()),
        showNavigation: (showNavigation: boolean) => dispatch(globalActions.showNavigation(showNavigation)),
        printPalPage: (printPalPage: boolean) => dispatch(myMenuActions.printPalPage(printPalPage)),
        resetMenu: () => dispatch(menuActions.setMenu(undefined)),
        createMenu: (formatStyleId: number, name: string) => dispatch(myMenuOperations.createMenu(formatStyleId, name))
    }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ChooseAStyle));
import { ImagePicker, ImageSection } from "./ImagePicker";
import { LockupEditor, LockupSection } from "./LockupEditor";
import { PriceEditor, PriceSection } from "./PriceSection";
import { TextGapFillEditor, TextGapFillSection } from "./TextGapFill";
import { TextEditor, TextSection } from "./TextInput";
import { TextList, TextListEditor } from "./TextList";
import { MainHeadingEditor, MainHeading } from "./MainHeading";
import { SubHeadingEditor, SubHeading } from "./SubHeading";
import { BodyTextEditor, BodyText } from "./BodyText";
import { PriceBackgroundEditor, PriceBackground } from "./PriceBackground";
import { RuledLine, RuledLineEditor } from "./RuledLine";

export const ContentDisplay = {
    ImageSection,
    TextSection,
    LockupSection,
    TextList,
    PriceSection,
    TextGapFillSection,
    MainHeading,
    SubHeading,
    BodyText,
    PriceBackground,
    RuledLine
}

export const ContentEditor = {
    "ImageSection": ImagePicker,
    "TextSection": TextEditor,
    "LockupSection": LockupEditor,
    "TextList": TextListEditor,
    "PriceSection": PriceEditor,
    "TextGapFillSection": TextGapFillEditor,
    "MainHeading": MainHeadingEditor,
    "SubHeading": SubHeadingEditor,
    "BodyText": BodyTextEditor,
    "PriceBackground": PriceBackgroundEditor,
    "RuledLine": RuledLineEditor
}
import React from "react"
import { Grid, Typography, WithStyles} from "@material-ui/core";
import StyleInjector from "../decorators/StyleInjector";
import WithCustomTheme from "../../interfaces/WithCustomTheme";

interface IOrderNumberProps {
    id: string,
    content: string
}

const styles = ({
    text: {
        position: "absolute",
        bottom: 15,
        left: 15,
        fontSize: 6
    }
})

class OrderNumber extends React.Component<IOrderNumberProps & WithStyles & WithCustomTheme> {
    render() {
        const { classes, content } = this.props;

        return <Grid item>
            <Typography className={classes.text} component="p" variant="body1">[OrderNumber]</Typography>
        </Grid>
    }
}

export default StyleInjector(styles, "orderNumber", true)(OrderNumber);
import React from "react";
import { Grid, TextField, Typography, WithStyles, RadioGroup, Radio, FormControlLabel, Checkbox, Divider } from "@material-ui/core";
import Editable from "../decorators/Editable";
import StyleInjector from "../decorators/StyleInjector";
import EditableProducts from "../layout/EditableProducts";
import IProduct from "../../interfaces/IProduct";
import ICustomTheme from "../../interfaces/ICustomTheme";

interface ITextListProps {
    id: string,
    title: string,
    products?: IProduct[],
    theme: ICustomTheme,
    singleLine: boolean,
    divider: string,
    showSize: boolean,
    textAlignment: string,
    halfWidthDivider: boolean,
    hasBottomBorder: boolean
}

const styles = ({
    productList: {
        paddingLeft: 5,
        paddingRight: 5,
        "& p": {
            whiteSpace: "pre-line" as "pre-line"
        }
    },
    dashedDivider:{
        borderTop:"1px dashed",
        margin : "0 auto",
        marginTop:20,
        display: "flex",
        justifyContent: "center"
    },
    dashedDividerWithPrice:{
        borderTop:"1px dashed",
        marginRight:5,        
        marginTop:10,
    },
    inlinePrice:{
        display: "flex",
        justifyContent: "center",
        margin: "0 auto",
    },
    dashedSingleLine:{
        borderTop: "1px dashed",
        marginTop: 10,
        marginRight: 5,
        marginLeft: 5
    },
});

function Appearance(props: { onChange: any, dataMapping: any }) {
    const { onChange, dataMapping } = props;

    return <Grid container style={{ marginBottom: 30 }}>
        <Grid item xs={12} style={{marginTop: 15, marginBottom: 15}}>
            <span style={{ fontSize: 14 }}>Choose a dashed divider line colour:</span>
        </Grid>

        <Grid container justify="flex-start" item xs={12}>            
            <RadioGroup name="divider" defaultValue={dataMapping["dividerColor"]} value={dataMapping["divider"] !== undefined ? dataMapping["divider"] : dataMapping["defaultColor"]} aria-label="divider colours" onChange={onChange} style={{marginLeft: -10}}>
                <Grid container style={{height: 40}}>               
                    {dataMapping.palette.map((color: string) => (  
                        <Grid item key={color}>      
                            <div style={{display: "flex"}}>
                                <Radio value={color}/><div key={color} style={{ backgroundColor: `${color}`, height: 30, width: 30, border: "1px solid black", marginTop: 5 }}/></div>
                        </Grid>
                    ))}
                </Grid>            
            </RadioGroup>
        </Grid>

        <Grid item xs={12} style={{marginTop: 10}}>
            <Grid container>
                <Grid item>
                    <FormControlLabel 
                        name="singleLine" 
                        onChange={onChange} 
                        checked={dataMapping["singleLine"]} 
                        label={<span style={{ fontSize: 14 }}>Show as a single line list</span>} 
                        labelPlacement="end"
                        control={<Checkbox />} />
                </Grid>
            </Grid>
        </Grid>

        <Grid item xs={12} style={{marginTop: 10}}>
            <Grid container>
                <Grid item>
                    <FormControlLabel 
                        name="showSize" 
                        disabled={!dataMapping["singleLine"]}
                        onChange={onChange} 
                        checked={dataMapping["showSize"]} 
                        label={<span style={{ fontSize: 14 }}>Show size in place of product description</span>} 
                        labelPlacement="end"
                        control={<Checkbox />} />
                </Grid>
            </Grid>
        </Grid>

        <Grid item xs={12} style={{marginTop: 15, marginBottom: 15}}>
            <span style={{ fontSize: 14 }}>Align product and product description text:</span>
        </Grid>

        <RadioGroup name="textAlignment" defaultValue="center" value={dataMapping["textAlignment"] && !dataMapping["singleLine"] ? dataMapping["textAlignment"] : "left"} aria-label="text alignment" onChange={onChange}>
            <Grid container>
                <Grid item>
                    <FormControlLabel value="left" label={<span style={{ fontSize: 14 }}>Left</span>}  control={<Radio />} labelPlacement="end" disabled={dataMapping["singleLine"]}/>
                </Grid>

                <Grid item>
                    <FormControlLabel value="center" label={<span style={{ fontSize: 14 }}>Centre</span>}  control={<Radio />} labelPlacement="end" disabled={dataMapping["singleLine"]}/>
                </Grid>

                <Grid item>
                    <FormControlLabel value="right" label={<span style={{ fontSize: 14 }}>Right</span>}  control={<Radio />} labelPlacement="end" disabled={dataMapping["singleLine"]}/>
                </Grid>
            </Grid>
        </RadioGroup>

        <Grid item xs={12} style={{marginTop: 10}}>
            <Grid container>
                <Grid item>
                    <FormControlLabel 
                        name="halfWidthDivider" 
                        disabled={dataMapping["singleLine"]}
                        onChange={onChange} 
                        checked={dataMapping["halfWidthDivider"]} 
                        label={<span style={{ fontSize: 14 }}>Half width divider</span>} 
                        labelPlacement="end"
                        control={<Checkbox />} />
                </Grid>
            </Grid>
        </Grid>

        {dataMapping.theme.textList?.hasBottomBorder && <RadioGroup name="hasBottomBorder" onChange={onChange} value={dataMapping["hasBottomBorder"]?.toString() ?? "false"} defaultValue="false" aria-label="border">
            <Grid container style={{ marginBottom: 15 }}>
                <Grid item>
                    <FormControlLabel value="false" label="No bottom border" control={<Radio />} labelPlacement="end" />
                </Grid>

                <Grid item>
                    <FormControlLabel value="true" label="Apply bottom border" control={<Radio />} labelPlacement="end" />
                </Grid>
            </Grid>
        </RadioGroup>}

        <Grid item xs={12}>
            <Divider style={{ marginTop: 15}} />
        </Grid>
    </Grid>
}

class SimpleTextList extends React.Component<WithStyles & ITextListProps> {
    static getCSVHeaders(): string {
        return "\"Id\",\"Title\"";
    }

    static renderToCSV(data: ITextListProps): string {
        var title = `"${data.id}","${data.title ?? ""}"\n\n`;

        //Now this is funky, start a sub table of products and offset it by one col so it looks nicer
        const productHeader = "\"\",\"Name\",\"Description\",\"Price\"\n";

        return title + productHeader + data.products?.reduce((prev: string, curr: IProduct) => {
            return prev + `"","${curr.name}","${curr.desc?.replaceAll("\"", "\"\"") ?? ""}","${curr.price ?? 0}"\n`;
        }, "") + "\n";
    }

    static setFromCSV(data: ITextListProps, csv: string[], index: number): boolean {
        if (!data.products)
            return false;

        var product = data.products[index];

        if (!product)
            return false;

        product.name = csv[1];
        product.desc = csv[2];
        product.price = parseFloat(csv[3]);
        return true;
    }

    getTextAlignment = (textAlignment: string) => {
        return textAlignment === "left" ? "left" : textAlignment === "right" ? "right" : "center";
    }    

    render() {
        const { id, title, products, classes, divider, theme, singleLine, showSize, textAlignment, halfWidthDivider, hasBottomBorder } = this.props;

        const palette = theme.simpleTextList?.palette;
        const defaultColor = theme.simpleTextList?.palette[0];
        const dividerColor = divider ?? defaultColor;

        

        const data = {
            name: "TextList",
            id,            
            initialValues: {
                title,
                products, 
                divider,
                palette, 
                singleLine,
                defaultColor, 
                dividerColor,
                showSize,
                textAlignment,
                halfWidthDivider,
                hasBottomBorder, 
                theme
            },
            state: {
                opened: [0],
                showAddProduct: false,
                showAddBritvicProduct: false,
                showAddGinProduct: false,
                newProduct: {} as IProduct,
                formData: undefined
            },
            fields: [
                {                    
                    label: "Appearance",
                    render: (onChange: any, dataMapping: any) => {
                    return <Appearance onChange={onChange} dataMapping={dataMapping}/>
                    }
                }, {
                    label: "Title text",
                    render: (onChange: any, dataMapping: any) => {
                        //console.log("Rendering Title Text", dataMapping.title);
                        return <TextField name="title" value={dataMapping.title} onChange={onChange} InputProps={{ disableUnderline: true }} variant="filled" />
                    }
                }, {
                    render: (onChange: any, dataMapping: any, state: { onSetState: any, editableState: any }) => {
                        return <EditableProducts hideFeatured onChange={onChange} dataMapping={dataMapping} state={state}/>
                    }
                }
            ],
        };

        //console.log("SimpleTextList updated", title, data.initialValues.title);

        return <Editable data={data}>
            <div className={classes.root}>
                {title && <Typography variant="h3" style={{marginBottom: classes.noMargin ? 0 : 20}}>{title}</Typography>}

                {products && products?.map((item: IProduct, index) => {

                    return <div key={index} className={`${classes.productList} ${hasBottomBorder && (index === products.length - 1) ? classes.bottomBorder : ""}`}>
                        {singleLine
                            ?
                            showSize
                                ?
                                <React.Fragment key={index}>
                                    <Grid container justify="space-between" style={{width: classes.a4 ? "94%" : "100%"}}>
                                        <Grid item xs={6}>
                                            <Grid container justify="flex-start">
                                                <p style={{marginTop: 2, marginLeft: classes.dl ? 10 : 0, textAlign: "left" }}>{item.name}</p>
                                            </Grid>
                                        </Grid> 
                                        <Grid item xs={4}>
                                            <Grid container justify="flex-start">
                                                <div style={{display: "flex", width: "100%"}}>
                                                    <Typography variant="h6"style={{marginTop: -2, float: "left"}}>{item.size}</Typography>
                                                    <div className={classes.dashedSingleLine} style={{borderTopColor: `${divider}`, width: "100%"}}/>
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={2}>  
                                            <Grid container justify="flex-end">     
                                                <Typography variant="h5" style={{marginRight: classes.dl ? 10 : classes.a3 ? 0 : -5}}>£{!(item?.price instanceof Array) ? item?.price?.toFixed(2) : "0"}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                                :
                                <React.Fragment key={index}>
                                    <Grid container justify="space-between">
                                        <Grid item xs={classes.a4 ? 9: 10}>
                                            <Grid container justify="flex-start">
                                                <p style={{ paddingBottom: classes.smallMmargin ? 3 : 0, marginTop: classes.a4 ? 1 : -2, textAlign: classes.a4 ? "left" : "center"}}>{item.desc}</p>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <div className={classes.dashedSingleLine} style={{borderTopColor: `${divider}`}}/>
                                        </Grid>
                                        <Grid item xs={classes.a4 ? 2 : 1}>  
                                            <Grid container justify="flex-end">     
                                                <Typography variant="h5" style={{marginRight: -5}}>£{!(item?.price instanceof Array) ? item?.price?.toFixed(2) : "0"}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </React.Fragment>                       
                            :
                            <React.Fragment key={index}>
                                <Grid container justify="space-between" alignContent="flex-start">
                                    <Grid item xs>
                                        <Typography variant="h2" style={{ textAlign: this.getTextAlignment(textAlignment), marginTop: index === 0 ? 0 : 10 }}>{item.name}</Typography>

                                        <p style={{ textAlign: this.getTextAlignment(textAlignment) }}>{item.desc}</p>

                                        {item.price !== undefined && !(item?.price instanceof Array) && item?.price?.toString().length > 0
                                            ?                
                                            <div className={classes.inlinePrice}>
                                                <div className={classes.dashedDividerWithPrice} style={{borderTopColor: `${divider}`, width: halfWidthDivider ? "50%" : "80%"}}/>     
                                                <Typography variant="h5">£{item?.price?.toFixed(2)}</Typography>                                           
                                            </div>                                      
                                            :
                                            index < (products.length - 1) && <div className={classes.dashedDivider} style={{borderTopColor: `${divider}`, width: halfWidthDivider ? "50%" : "80%"}}/>
                                        }
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        }
                    </div>
                })}
            </div>
        </Editable>
    }
}

export default (StyleInjector(styles, "simpleTextList", true)(SimpleTextList));
import React, { useEffect, useMemo, useState } from "react";
import LoadingSpinner from "../../components/POS/LoadingSpinner";
import TableWrapper from "../../components/POS/table/Table";
import Filters, { FilterPropertiesState, FilterOption } from "../../components/POS/Filters";
import { useHistory } from "react-router-dom";
import { filterOptions } from "./OrdersPage";

interface CustomerDTO {
    customerId: string,
    nameAddress: string,
    email: string
    lastOrderDate: Date
    orderStatus: string,
}

interface CustomerTableRow extends CustomerDTO {
    lastOrderDateClean: string;
}

//Custom pagination - https://dev.to/inimist/react-table-server-side-pagination-with-sorting-and-search-3163

function CustomersPage(props: { "orderStates": FilterOption[] | undefined }) {

    const [customers, setCustomers] = useState<CustomerTableRow[] | undefined>();

    const [filterState, setFilterState] = useState<FilterPropertiesState>({ filterSelection: '', searchBar: '', limit: 20 });

    const navigate = useHistory();


    useEffect(() => {
        fetch("../../api/customer/")
            .then(async request => {

                const jsonResponse: CustomerDTO[] = await request.json();

                const rows: CustomerTableRow[] = jsonResponse.map(customer => {
                    return {
                        ...customer,
                        lastOrderDateClean: customer.lastOrderDate === null ? '' : cleanDate(customer.lastOrderDate.toString()),
                        customerIdClean: customer.customerId.toString().padStart(6, '0')
                    }
                })

                setCustomers(rows);
            })
    }, [])

    if (customers === undefined)
        return <LoadingSpinner/>

    return <>
        <Filters
            filterValue={filterState.filterSelection}
            searchValue={filterState.searchBar}
            limitValue={filterState.limit}

            filterChange={(e) => { setFilterState({ ...filterState, filterSelection: e.target.value }) }}
            searchChange={(e) => { setFilterState({ ...filterState, searchBar: e.target.value }) }}
            limitChange={(e) => { setFilterState({ ...filterState, limit: parseInt(e.target.value) }) }}

            searchPlaceholder="Search by customer address, name or number"

            filterOptions={props.orderStates.map(state => { if (state.text === "All") return { text: "All", value: "" }; return { text: state.text, value: state.text } })}

            title="Agent summary"

            showPageSize

            pageSizeOptions={[10, 20, 30, 50]}

        />

        <br/>
        <TableWrapper

            data={customers}

            keys={["customerId", "customerIdClean", "nameAddress", "email", "lastOrderDateClean", "orderStatus", "lastOrderDate"]}
            headings={["customer id raw","Cust. No.", "Name & address", "Email", "Last order date", "Order status", "Raw last order date"]}
            columnWidths={[0,0.5,3,1.3,1,1,0]}

            searchableColumns={["nameAddress", "customerId"]}

            searchTerm={filterState.searchBar}
            columnFilters={[{
                id: "orderStatus",
                value: filterState.filterSelection
            }]}

            pageSize={filterState.limit}

            onRowClick={(data) => navigate.push("./customers/" + data.customerId)}

            customSortValues={[
                {
                    visibleColumn: "lastOrderDateClean",
                    hiddenColumn: "lastOrderDate"
                }, {
                    visibleColumn: "customerIdClean",
                    hiddenColumn: "customerId"
                }
            ]}
    
    ></TableWrapper>
        </>

}


function cleanDate(date: string): string {
    const d = new Date(date);

    const d1 = `${d.getDate().toString().padStart(2, '0')}/${(d.getMonth() + 1).toString().padStart(2, '0')}/${d.getFullYear()}`;
    const t = `${d.getHours().toString().padStart(2, '0')}:${d.getMinutes().toString().padStart(2, '0')}`

    return `${d1} ${t}`;
}

export default CustomersPage;


import { FormControlLabel, makeStyles, Radio, RadioGroup, TextField } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { FieldComponent, FieldEditor } from "../FloatingContentPanel"
import { useSelector } from "react-redux"
import { AppState } from "../../../store"
import Futura from "../../../assets/Futura.png";
import BETM from "../../../assets/BETM.png";
import Bebas from "../../../assets/Bebas.png";

const useStyles = makeStyles({
    textSectionContainer: {
        height: "100%",
        width: "100%"
    },
    text: {
        
    },
    label: {
        display: "block"
    },
    color: {
        display: "block",
        width: "26px",
        height: "26px",
        marginRight: "12px",
        cursor: "pointer",
        border: "1px solid",
        boxSizing: "content-box"
    }
});

interface MainHeadingSectionData {
    textTitle: string
    textValue: string
    textStyle: any,
    value: string,
    textColor: string,
    fontFamily: string,
    rootStyle?: any
    fontWeight?: string
}
interface MainHeadingSectionConfig {}

interface IMainHeadingEditor extends FieldEditor<MainHeadingSectionConfig, MainHeadingSectionData> {}

export const MainHeadingEditor: React.FC<IMainHeadingEditor> = ({ config, field, onChangeField }) => {
    const [selectedColor, setSelectedColor] = useState<string>(field?.data?.textColor);
    const [selectedFont, setSelectedFont] = useState<string>(field?.data?.fontFamily);
    const menuState = useSelector((state: AppState) => state.menu);
    const classes = useStyles();
    let colors = ["#d7ccc6", "#b8cec1", "#eef1b5", "#f5938b", "#cddaf0", "#48484a", "#488769", "#eed32b", 
        "#c4323a", "#235f8e", "#06080d", "#2c5f48", "#e39027", "#992227", "#0a498a", "#FFF"
    ];
    let fonts = [
        {"name": <img src={Futura} alt="Futura"/>, value: "\"Futura Std\""},
        {"name": <img src={BETM} alt="BETM"/>, value: "\"Betm\""},
        {"name": <img src={Bebas} alt="Bebas"/>, value: "\"Bebas Neue\""},
    ];
    const onTextChange = (value: string) => {
        onChangeField("mealDealMainHeading", {
            ...field.data,
            textValue: value
        });
    };

    useEffect(() => {
        onChangeField("mealDealMainHeading", {
            ...field.data,
            textColor: selectedColor,
            fontFamily: selectedFont
        });
    }, [selectedColor, selectedFont]);

    return(
        <div>
            {
                field?.hideColorsAndFont ? <></> :
                <>
                    <span className={classes.label}>Text colour</span>
                    <div style={{display: "flex", margin: "12px 0"}}>
                        {
                            colors.map((row) => {
                                return(
                                    <span 
                                        style={{background: row, border: `${selectedColor === row ? "3px solid" : "1px solid"}`}} 
                                        key={row} 
                                        className={classes.color}
                                        onClick={()=> setSelectedColor(row)}
                                    >
                                    </span>
                                )
                            })
                        }
                    </div>
                    <span>Font</span>
                    <div style={{display: "flex", margin: "12px 0"}}>
                        <RadioGroup
                            row
                            value={selectedFont}
                            onChange={(e) => { 
                                setSelectedFont(e.target.value as any)
                            }}
                        >
                        {
                            fonts.map((row) => {
                                return(
                                    <FormControlLabel control={<Radio/>} value={row?.value} label={row?.name} style={{fontWeight: 900}}/>
                                )
                            })
                        }
                        </RadioGroup>
                    </div>
                </>
            }
            <TextField 
                id={field.name} 
                label={field.data.textTitle} 
                defaultValue={field.data.textValue} 
                placeholder="HEADER TEXT"
                fullWidth
                onChange={(e) =>{ onTextChange(e.target.value); }} 
            />
        </div>
    )
}

interface IMainHeadingSection extends FieldComponent<MainHeadingSectionData> {}

export const MainHeading: React.FC<IMainHeadingSection> = ({ field, scaledPosition, format }) => {
    const { textValue, textColor, rootStyle, fontFamily, fontWeight } = field.data;
    const classes = useStyles();
    let strokeColor = "#000000"
    if(["#0a498a", "#992227", "#2c5f48", "#06080d", "#48484a", "#488769", "#c4323a", "#235f8e"].includes(textColor)){
        strokeColor = "#FFFFFF";
    }
    const menuState = useSelector((state: AppState) => state.menu);
    let styleId = menuState?.menuData?.styleId;
    let fontSize= field?.heading ? "1.2em" : "2em";
    let webkitTextStrokeWidth = '1.4px'
    let textShadow = `8px 12px 20px #000`;
    if(format === "SIGNAGE"){
        webkitTextStrokeWidth = '1.2px' 
        textShadow = `0px 3px 4px #000`
    }else if(format === "TABLETALKER"){
        webkitTextStrokeWidth = '0.4px'
        textShadow = `0px 2px 4px #000`
    }else if(format === "BARKER" || format === "WOBBLER"){
        webkitTextStrokeWidth = '1px'
        textShadow = `0px 3px 4px #000`
    }

    if(styleId === 42){
        if(format === "A3POSTER" || format === "A4POSTER"){
            if(field?.heading){
                fontSize = "2.1em"
            }else{
                fontSize = "3.8em"
            }
        }else if(format === "A5TENTCARD"){
            if(field?.heading){
                fontSize = "1.75em"
            }else{
                fontSize = "3.55em"
            }
        }else if(format === "BARKER" || format === "WOBBLER"){
            if(field?.heading){
                fontSize = "1em"
            }else{
                fontSize = "1.8em"
            }
        }else if(format === "TABLETALKER"){
            if(field?.heading){
                fontSize = "1.6em"
            }else{
                fontSize = "3.2em"
            }
        }else{
            if(field?.heading){
                fontSize = "1.1em"
            }else{
                fontSize = "2em"
            }
        }
    }else{
        if(format === "A3POSTER" || format === "A4POSTER"){
            fontSize = "3.8em"
        }else if(format === "A5TENTCARD"){
            fontSize = "2.8em"
        }else if(format === "BARKER" || format === "WOBBLER"){
            fontSize = "2.2em"
        }else if(format === "TABLETALKER"){
            fontSize = "2.4em"
        }else if(format === "SIGNAGE"){
            fontSize = "1.7em"
        }else{
            fontSize = "2.8em"
        }
    }

    return(
        <div style={{...rootStyle}} className={classes.textSectionContainer}>
            <div style={{
                color: textColor,
                fontFamily: fontFamily,
                fontWeight: fontWeight,
                fontSize: fontSize,
                lineHeight: "100%",
                WebkitTextStrokeWidth: field?.hideTextStroke ? "none" : webkitTextStrokeWidth,
                WebkitTextStrokeColor: field?.hideTextStroke ? "none" : strokeColor,
                textAlign: ((format === "SIGNAGE") && !field?.hideTextStroke) ? "end" : "center",
                textShadow: field?.hideTextStroke ? "none" : textShadow
            }} className={classes.text}>{textValue}</div>
        </div>
    )
}
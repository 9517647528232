import React, { Component } from "react";
import { Grid, Typography, WithStyles, RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import Editable from "../decorators/Editable";
import StyleInjector from "../decorators/StyleInjector";
import ImageUpload from "../form/ImageUpload";
import { IMenuState, menuOperations } from "../../store/menu";
import { connect } from "react-redux";
import ILibraryImage from "../../interfaces/ILibraryImage";
import ICustomTheme from "../../interfaces/ICustomTheme";
import { AppState } from "../../store";

interface ILogoProps {
    id: string,
    image: string,
    classes: any,
    uploadFile: any,
    theme: ICustomTheme,
    menu: IMenuState,
    hasBackground: boolean
}

const styles = ({
    root: {
        padding: "0 10px"
    },
    image: {
        margin: "0 auto",
        "& img": {
            position: "absolute" as "absolute"
        }
    },
    circle: {
        margin: "0 auto",
        background: "#F5F5F5",
        width: 170,
        height: 170,
        borderRadius: "50%",
        display: "flex",
        alignItems: "center", 
        justifyContent: "center",
        fontSize: 18,
        fontWeight: "bold",
        color: "#A9A9A9"
    },
    squareCornerLogo: {
        borderRadius: "23%"
    },
    sizedImage: {
        maxHeight: 200, 
        height: "auto", 
        maxWidth: 500, 
        width: "auto"
    }
})

class Logo extends Component<ILogoProps & WithStyles> {
    render() {
        const { classes, id, image, uploadFile, theme, menu, hasBackground } = this.props;

        const data = {
            id,
            name: "Logo",
            showMandatory: !(menu.menuData?.simplified ?? false),
            initialValues: {
                image,
                hasBackground
            },
            state: {
                formData: undefined,
                error: false
            },
            fields: [
                {
                    label: "Image",
                    render: (onChange: any, dataMapping: any, state: { onSetState: any, editableState: any }) => {
                        return <React.Fragment>
                            {state.editableState.error && <Typography variant="body2" gutterBottom color="error">Image upload failed, please make sure your image is under 5mb and is a jpg or png</Typography>}

                            <ImageUpload
                                width={theme.logo?.width}
                                height={theme.logo?.height}
                                hideImageLibrary={menu.menuData?.simplified}
                                dataMapping={dataMapping}
                                fileUploaded={(formData: FormData) => {
                                    state.onSetState({
                                        editableState: { ...state.editableState, formData, error: false }
                                    });
                                }}
                                imageSelected={(image: ILibraryImage) => {
                                    state.onSetState({
                                        dataMapping: { ...dataMapping, image: image.filePath },
                                        editableState: { ...state.editableState, formData: null, error: false }
                                    });
                                }}
                                clearImage={() => {
                                    state.onSetState({
                                        dataMapping: { ...dataMapping, image: null },
                                        editableState: { ...state.editableState, formData: undefined, error: false }
                                    });
                                }}/>
                        </React.Fragment>;
                    }
                },
                theme.logo?.hasBackground && {
                    label: "Background",
                    render: (onChange: any, dataMapping: any, state: { onSetState: any, editableState: any }) => {
                        return <Grid container>
                            <Grid item>
                                <RadioGroup name="hasBackground" onChange={onChange} value={dataMapping["hasBackground"]?.toString() ?? "false"} defaultValue="true" aria-label="background colour">
                                    <Grid container style={{ marginBottom: 15 }}>
                                        <Grid item>
                                            <FormControlLabel value="true" label="White background" control={<Radio />} labelPlacement="end" />
                                        </Grid>

                                        <Grid item>
                                            <FormControlLabel value="false" label="Transparent background" control={<Radio />} labelPlacement="end" />
                                        </Grid>
                                    </Grid>
                                </RadioGroup>
                            </Grid>
                        </Grid>
                    }
                }
            ],
            onSaved: (dataMapping: any, state: any) => {
                return new Promise<void>((resolve: any, reject: any) => {
                    if (!state.editableState.formData) {
                        resolve();
                        return;
                    }

                    //On save upload the file to the server
                    uploadFile(state.editableState.formData).then((file: any) => {
                        var url = window.location.origin + "/api/Files/" + file.fileId;
                        dataMapping.image = url;
                        resolve();
                        return false;
                    }).catch(() => {
                        reject();
                        return true;
                    }).then((error: boolean) => {
                        //Reset form data
                        state.onSetState({
                            editableState: { ...state.editableState, formData: undefined, error }
                        });
                    });
                });
            }
        };

        return <Editable data={data} buttonText="Edit logo" hideOnExport={theme.logo?.hideDefaultIfNotUpdated && !image} changed={!!image}>
            <div className={classes.root}>
                <Grid container alignContent="center">
                    <Grid item xs className={classes.logo}>
                        {theme.logo?.hasBackground && hasBackground && image ? <div className={classes.background}>
                            <img alt="logo" src={image} className={classes.sizedImage} />
                        </div>
                            : image ? <img alt="logo" src={image} className={classes.sizedImage} /> : <span className={`${classes.circle} ${theme.logo?.defaultRound==false?classes.squareCornerLogo:""}`}>{!menu.menuData?.simplified ? <>Your <br /> logo</> : ""}</span>
                        }
                    </Grid>
                </Grid>
            </div>
        </Editable>
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        menu: state.menu
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        uploadFile: (file: any) => dispatch(menuOperations.uploadFile(file))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StyleInjector(styles, "logo", true)(Logo));
import { makeStyles, TextField } from "@material-ui/core"
import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import { z } from "zod"
import { MenuBuilderContext } from "../../../pages/MenuBuilder"
import { EditableModalContext } from "../../layout/EditableModal"
import { ContainerContext } from "../Container"
import { FieldComponent, FieldEditor } from "../FloatingContentPanel"

interface TextGapFillData {
    gaps: TextGapData[]
}
interface TextGapData {
    name: string
    value: string
}

interface TextGapFillConfig {
    gaps: TextGapField[]
    gapConfig: TextGapFillGapConfig[]
    rootStyle: TextGapStyle[]
}
interface TextGapFillGapConfig {
    gaps: (string | number)[] | (string | number)[][]
    format?: string
}

interface TextGapField {
    name: string,
    label?: string
    placeholder?: string
}

interface TextGapStyle {
    validBackgrounds: string[],
    style: any
}

interface ITextGapFillEditor extends FieldEditor<TextGapFillConfig, TextGapFillData> {

}

const useEditorStyles = makeStyles({
    previewContainer: {
        padding: "1rem",
        background: "#000",
        color:'#fff',
        border: "1px solid black",
        minHeight:"10rem"
    },
    previewTitle: {
        marginTop: "0.5rem",
        marginBottom: "0.5rem"
    },
    previewContent: {
        whiteSpace: "break-spaces"
    },
    fieldControlWrapper: {
        "& .MuiInputLabel-animated": {
            paddingLeft: 10
        },
        "& input": {
            paddingLeft: 10,
            fontSize: 13,
            paddingTop: 10
        },


        "& label": {
            textWrap: "nowrap"
        },

        "& label.Mui-error:not(.Mui-focused)": {
            transform: "translate(0, 26px) scale(0.8)"
        }
    }
});

interface FieldError {
    name: string,
    error?: string
}

export const TextGapFillEditor: React.FC<ITextGapFillEditor> = ({ config, field, onChangeField }) => {

    /*
        TODO
            config.gaps.map

    */

    const classes = useEditorStyles();
    const { upsertEvent, upsertValidation, setApplyValid } = useContext(EditableModalContext);
    const [errors, setErrors] = useState<FieldError[]>(field.data.gaps.map((gap) => ({ name: gap.name  })));

    const onChangeGapField = (name: string, value: string) => {
        let gaps = JSON.parse(JSON.stringify(field.data.gaps)) as TextGapData[];
        let updatedGapIndex = gaps.findIndex((g) => g.name == name);
        gaps[updatedGapIndex].value = value;

        onChangeField(field.name, {
            ...field.data,
            gaps
        });
    }

    const fullText = useMemo(() => {
        let gapConfig = config.gapConfig.find((g) => g.format == undefined);
        if(gapConfig){
            return (gapConfig.gaps as (string | number)[]).reduce((prev, curr, i) => {
                if(typeof(curr) == "string"){
                    prev = prev + curr;
                } else {
                    let value = field.data.gaps[curr].value;
                    prev = prev + value;
                }

                return prev;
            }, "");
        } else {
            return "";
        }
    }, [config.gapConfig, field.data.gaps]);

    useEffect(() => {
        let schema = z.string({ required_error: "Field required" }).min(1, "Field required");
        let validCount = 0;
        field.data.gaps.forEach((gap) => {
            try{
                schema.parse(gap.value);
                validCount++;
                let gapErrors = JSON.parse(JSON.stringify(errors)) as FieldError[];
                let gapI = gapErrors.findIndex((g) => g.name == gap.name);
                if(gapErrors[gapI].error != undefined){
                    gapErrors[gapI].error = undefined
                    setErrors(gapErrors); 
                }                
            } catch(err: any){
                let message = err as z.ZodError;
                //console.log(message);
                let gapErrors = JSON.parse(JSON.stringify(errors)) as FieldError[];
                let gapI = gapErrors.findIndex((g) => g.name == gap.name);
                if(gapErrors[gapI].error != message.issues[0].message){
                    gapErrors[gapI].error = message.issues[0].message;
                    setErrors(gapErrors);   
                }                
            }
        })

        setApplyValid(validCount == field.data.gaps.length ? true : false);
        /*
            
        */
    }, [field.data.gaps, errors]);

    

    return(
        <div>
            {
                config.gaps.map((gap) => {
                    let value = field.data.gaps.find((g) => g.name == gap.name);
                    let error = errors.find((e) => e.name == gap.name)?.error;
                    return (
                        <div>
                            <TextField className={classes.fieldControlWrapper} error={error != undefined} helperText={error} fullWidth id={gap.name} label={gap.label ?? gap.name} placeholder={gap.placeholder} defaultValue={value?.value} onChange={(e) => { onChangeGapField(gap.name, e.target.value); }} />
                        </div>
                    )
                })
            }
            <div className={classes.previewTitle}>Preview</div>
            <div className={classes.previewContainer}>
           
                <div className={classes.previewContent}>{fullText}</div>
            </div>
        </div>
    )
}

interface ITextGapFillSection extends FieldComponent<TextGapFillData, TextGapFillConfig> {

}

export const TextGapFillSection: React.FC<ITextGapFillSection> = ({ config, field, scale, width, modalData, positionIndex }) => {

    const { setIsValid } = useContext(MenuBuilderContext);
    const { formatSize } = useContext(ContainerContext);


    /*
        gapConfig = gapConfig.find format == formatSize, if none then get where format == undefined

        gap[] = gapConfig.gaps == multidimensional array //Need to use positionIndex to get gap[]
        else return gap[]

        fullText = gap[] reduce
    */

    useEffect(() => {
        let valid = true;
        for(let i = 0; i < field.data.gaps.length; i++){
            let gap = field.data.gaps[i];
            valid = gap.value.length > 0;
            if(!valid){
                break;
            }
        }
        setIsValid(field.name, modalData.id, valid);
    }, [field.data.gaps])

    const { background } = useContext(ContainerContext);

    const gapConfig = useMemo(() => {
        let formatCheck = config?.props?.gapConfig.find((v) => v.format == formatSize);
        return formatCheck != undefined ? formatCheck : config?.props?.gapConfig.find((v) => v.format == undefined);
    }, [config?.props?.gapConfig, formatSize])

    const gaps = useMemo(() => {
        if(gapConfig?.gaps && gapConfig.gaps[0] != undefined){ //Check if JSON is correct
            if( Array.isArray(gapConfig.gaps[0])) { //This is a multidimensional array
                return gapConfig.gaps[positionIndex]; //Return the gaps that correlate with the current index of the format position
            } else {
                return gapConfig.gaps;
            }
        } else {
            console.log("Unexpected undefined in gapConfig.gaps")
        }
    }, [gapConfig, formatSize]) as (string | number)[];

    const fullText = useMemo(() => {
        //console.log(config?.props);
        return gaps.reduce((prev, curr, i) => {
            if(typeof(curr) == "string"){
                prev = prev + curr;
            } else {
                let value = field.data.gaps[curr].value;
                //console.log(value);
                prev = prev + value;
            }

            return prev;
        }, "");
    }, [gaps]);

    const textStyle = useMemo(() => {
        if(background)
            return config?.props?.rootStyle.find((sty) => sty.validBackgrounds.includes(background));    

    }, [background, config?.props?.rootStyle]);

    return(
        <div style={{ width: "100%", height: "100%", display: "flex"}}>
            <div style={{
                ...(textStyle ? textStyle.style : undefined),
                whiteSpace: "pre-wrap", marginTop: "auto", overflowWrap: "normal"
            }}>{fullText}</div>
        </div>
    )
}
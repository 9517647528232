import React, { Component } from "react";
import { Grid, Typography, Theme, withStyles, WithStyles, Breadcrumbs, Link, Button } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import MenuFormats from "../components/chooseAFormat/MenuFormats";
import FileNamePopup from "../components/chooseAFormat/FileNamePopup";

import { connect } from "react-redux";
import { AppState } from "../store";
import { IMyMenuState, myMenuActions, myMenuOperations } from "../store/myMenu";
import { globalActions } from "../store/global";

const styles = (theme: Theme) => ({
    root: {
        '& > * + *': {
            marginTop: theme.spacing(2)
        }
    },
    container: {
        background: "#f8efed",
        overflow: "hidden",
        paddingLeft: theme.spacing(7),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
        [theme.breakpoints.down("sm")]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2)
        }
    },
    header: {
        background: "white",
        paddingLeft: theme.spacing(7),
        overflow: "hidden",
        [theme.breakpoints.down("sm")]: {
            paddingLeft: theme.spacing(2)
        }
    },
    toolbar: {
        background: "#FFF",
        height: 100,
        paddingLeft: theme.spacing(7),
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
        [theme.breakpoints.down("sm")]: {
            height: 60,
            paddingLeft: theme.spacing(2),
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            fontSize: 10
        }
    },
    stepText: {
        color: "#FFF",
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
        "& h1": {
            marginBottom: theme.spacing(1)
        },
        [theme.breakpoints.down("sm")]: {
            marginTop: theme.spacing(0),
            marginBottom: theme.spacing(0),
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2)
        }
    },
    breadcrumbLink: {
        display: 'flex',
        color: "#007D7A",
        fontWeight: "bold" as "bold",
        [theme.breakpoints.down("sm")]: {
            fontSize: 12
        }
    },
    link: {
        display: 'flex',
        color: "#FFF",
        [theme.breakpoints.down("sm")]: {
            fontSize: 14
        }
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 30,
        height: 30,
        [theme.breakpoints.down("sm")]: {
            width: 20,
            height: 20
        }
    },
    menuStepText: {
        color: "#000",
        [theme.breakpoints.down("sm")]: {
            fontSize: 12,
        }       
    },
    buildButton: {        
        backgroundColor: "#007D7A",
        color: "#FFF",
        fontWeight: "bold" as "bold",
        fontSize: 12,
        "&:hover": {
            backgroundColor: "#FFF",
            color: "#007D7A"
        }
    },
    previewText: {
        color: "rgb(0, 125, 122)",
        fontSize: 10,
        marginRight: 5
    }
});

interface IProps {
    myMenu: IMyMenuState;
    showNavigation: any;
    printPalPage: any;
    openPopup: any;
    closePopup: any;
    createMenu: any;
}

interface IChooseAFormatState {
    canBuildMenu: boolean,
    openDrawer: boolean,
    isMobileApp: boolean,
}

class ChooseAFormat extends Component<WithStyles & IProps, IChooseAFormatState> {
    mounted: boolean = false;
    constructor(props: any) {
        super(props);

        this.props.printPalPage(false);
        this.props.showNavigation(true);

        this.state = { canBuildMenu: false, openDrawer: false, isMobileApp: false };

        this.handleFormatSelected = this.handleFormatSelected.bind(this);
        this.handleOpenPopup = this.handleOpenPopup.bind(this);
        this.handleClosePopup = this.handleClosePopup.bind(this);
        this.handleOpenDrawer = this.handleOpenDrawer.bind(this);
        this.handleCloseDrawer = this.handleCloseDrawer.bind(this);
    }

    componentDidMount() {
        this.mounted = true;
        this.setResponsiveness();
        window.addEventListener("resize", () => this.setResponsiveness());
    }

    componentWillUnmount() {
        this.mounted = false;
        window.removeEventListener('resize', () => this.setResponsiveness());
    }

    setResponsiveness = () => {
        if (this.mounted)
            return window.innerWidth < 900
            ? this.setState((prevState) => ({ ...prevState, isMobileApp: true }))
            : this.setState((prevState) => ({ ...prevState, isMobileApp: false }));
    };

    handleFormatSelected(formatId: string, formatStylesId: string) {
        sessionStorage.setItem('formatId', formatId);
        sessionStorage.setItem('formatStylesId', formatStylesId);

        formatStylesId === null ? this.setState({ canBuildMenu: false }) : this.setState({ canBuildMenu: true });
    }

    handleOpenPopup() {
        this.props.openPopup();
    }

    handleClosePopup() {
        this.props.closePopup();
    }

    handleOpenDrawer() {
        if (this.state.canBuildMenu)
            this.setState({ openDrawer: true });
    }

    handleCloseDrawer() {
        this.setState({ openDrawer: false });
    }

    render() {
        const { classes, createMenu, myMenu } = this.props;

        return (
            <div style={{ minHeight: 'calc(100vh - 293px)' }}>
                <Grid container>
                    <Grid item xs={12} className={classes.stepText}>
                        <Typography variant={this.state.isMobileApp ? "h2" : "h1"}>
                            Choose a format
                        </Typography>

                        <Typography variant={this.state.isMobileApp ? "body2" : "body1"} paragraph>
                            Based on your menu requirements, choose a menu shape & size.<br />
                            Don’t worry, the menu creator will stretch / condense and re-arrange as you put more items and sections in.
                        </Typography>

                        {sessionStorage.getItem('styleId') !== '30' && <Link color="inherit" href="/choose-style" className={classes.link}>&lt; Back to 'Choose your style'</Link>}
                    </Grid>

                    <Grid item xs={12} className={classes.toolbar}>
                        <Grid container alignItems="flex-start" spacing={5} direction="column">
                            <Grid item>
                                <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize={this.state.isMobileApp ? "small" : "default"} />}>
                                    <Link color="inherit" href="/" className={classes.breadcrumbLink}>
                                        <HomeIcon className={classes.icon} />
                                    </Link>
                                    <Link color="inherit" component={RouterLink} to="/" className={classes.breadcrumbLink}>My designs</Link>
                                    <span className={classes.menuStepText} color="textPrimary"><strong>New Menu: Menu formats</strong></span>
                                </Breadcrumbs>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>                
                        <Grid container className={classes.header}>
                            {this.state.isMobileApp
                                ?
                                <Grid container alignItems="center" style={{ height: 50 }}>
                                    <Grid container item xs={9} alignItems="flex-start">
                                        <Typography style={{ paddingRight: 10 }} variant="h3"><strong>Menu formats</strong></Typography>
                                        <Button
                                            className={classes.buildButton}
                                            color="secondary"
                                            variant="contained"
                                            size="small"
                                            onClick={this.handleOpenPopup}
                                            disabled={!this.state.canBuildMenu}>
                                            Build my menu
                                        </Button>
                                    </Grid>
                                    <Grid item xs={3} container style={this.state.canBuildMenu ? { opacity: 1 } : {opacity: 0.3 }}>
                                        <span className={classes.previewText}>Preview<br />my menu</span>
                                        <img src={`./assets/preview_finger.svg`} alt="preview" style={{ height: "75%" }} onClick={this.handleOpenDrawer} />
                                    </Grid>
                                </Grid>
                                :
                                <Grid container>
                                    <Grid item sm={8} style={{ height: 60 }}>
                                        <Typography variant="h2"><strong>Menu formats</strong></Typography>
                                    </Grid>
                                    <Grid container justify="center" item sm={4}>
                                        <Typography variant="h2"><strong>My menu</strong></Typography>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>

                        <Grid container direction="column" className={classes.container}>

                            <MenuFormats
                                {...myMenu}
                                openDrawer={this.state.openDrawer}
                                formatSelected={this.handleFormatSelected}
                                closeDrawer={this.handleCloseDrawer}
                                openPopup={this.handleOpenPopup} />

                            <FileNamePopup
                                isPopupOpen={myMenu.isPopupOpen}
                                closePopup={this.handleClosePopup}
                                createMenu={createMenu} />
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    myMenu: state.myMenu
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        openPopup: () => dispatch(myMenuActions.openPopup()),
        closePopup: () => dispatch(myMenuActions.closePopup()),
        showNavigation: (showNavigation: boolean) => dispatch(globalActions.showNavigation(showNavigation)),
        printPalPage: (printPalPage: boolean) => dispatch(myMenuActions.printPalPage(printPalPage)),
        createMenu: (formatStyleId: number, name: string) => dispatch(myMenuOperations.createMenu(formatStyleId, name))
    }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ChooseAFormat));
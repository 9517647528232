import React, { useState, useEffect} from "react";
import { Container, Grid, Box, makeStyles, createStyles, Theme, CircularProgress } from "@material-ui/core";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

// Store
import { connect } from "react-redux";
import { AppState } from "../../store";
import { IMenuState } from "../../store/menu";
import { IGlobalState } from "../../store/global";
import PasswordChange from "./PasswordChange";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        nav: {
            background: "#363636",
            color: "#FFF",
            fontWeight: "bold" as "bold",
            padding: "11px 0 11px 0",
            lineHeight: "26px"
        },
        mobileNav: {
            background: "#363636",
            color: "#FFF",
            fontWeight: "bold" as "bold",
            padding: "11px 0 11px 0",
            lineHeight: "8px"
        },
        link: {
            borderLeft: "1px solid #777",
            paddingLeft: 10,
            marginLeft: 10,
            "& a": {
                color: "#FFF",
                textDecoration: "none",
                "&:hover": {
                    color: "#02C1BD",
                    textDecoration: "underline"
                }
            }
        },
        menuName: {
            paddingLeft: 10,
            color: "#02C1BD",
        },
        text: {
            fontSize: 14,
        },
        mobileText: {
            fontSize: 10,
        }
    }),
);

interface IProps {
    menu: IMenuState,
    global: IGlobalState,
    location: any
}

const SubHeader = (props: IProps & RouteComponentProps) => {
    const [state, setState] = useState({
        isMobileApp: false,
        anchorElement: null,
        open: false
    })

    const { isMobileApp } = state;

    const classes = useStyles();
    const { global, menu, location } = props;

    useEffect(() => {
        let mounted: boolean = false;
        const setResponsiveness = () => {
            if (mounted)
                return window.innerWidth < 900
                    ? setState((prevState) => ({ ...prevState, isMobileApp: true }))
                    : setState((prevState) => ({ ...prevState, isMobileApp: false }));
                };
        mounted = true;
        setResponsiveness();
        window.addEventListener("resize", () => setResponsiveness());
        return () => { mounted = false; window.removeEventListener('resize', () => setResponsiveness())};     
    }, []);

    const [loggingOut, setLoggingOut] = useState(false);

    const handleOrderHistory = () => {
        handleCloseMenu();
        props.history.push("/order-history");
    }

    const handlePos = () => {
        handleCloseMenu();
        props.history.push("/pos");
    }

    const handleReporting = () => {
        handleCloseMenu();
        props.history.push("/reporting/monthly-reports");
    }

    const handleMenuClick = (event: any) => {
        setState({ ...state, anchorElement: event.currentTarget, open: true});
    };

    const handleCloseMenu = () => {
        setState({ ...state, anchorElement: null, open: false });        
    };

    const handleLogout = async () => {
        setLoggingOut(true);
        const res = await fetch("./api/customer/logout"); // Response should clear cookie

        if (res.redirected) {
            window.location.href = res.url;
        } else {
            const jsonRes = await res.json();

            if (jsonRes === true) {
                window.location.reload();
            }
        }

        setLoggingOut(false);
    };

    return (
        <Box className={isMobileApp ? classes.mobileNav : classes.nav}>

            {
                global.user?.role?.role === "ADMIN" && process.env.NODE_ENV !== 'production' && <PasswordChange />
            }

            <Container maxWidth={false}>
                <Grid container>
                    {global.showNavigation
                        ?
                        <Grid container item xs={12} sm={6} className={isMobileApp ? classes.mobileText : classes.text}>
                            {location.pathname === "/menu-builder" && <span>Editing: <span className={classes.menuName}>{menu.menuData?.title ?? "New menu (unsaved)"}</span></span>}
                            {location.pathname === "/choose-style" && <span>Choose your style</span>}   
                            {location.pathname === "/choose-format" && <span>Choose a format</span>}                                                       
                            <span className={classes.link}><Link to="/">My designs</Link></span>
                        </Grid>
                        :
                        <Grid container item xs={12} sm={6} />
                    }

                    {(isMobileApp && global.showNavigation) || !global.user ? null : <Grid container item xs={12} sm={6} justify={isMobileApp ? "flex-start" : "flex-end"} className={isMobileApp ? classes.mobileText : classes.text}>
                        Logged in:<span style={{ display: "flex", alignItems: "center", cursor: "pointer" }} onClick={(e) => handleMenuClick(e)} > <span className={classes.menuName}>{global.user?.displayName + ", " + global.user?.company}</span><ArrowDropDownIcon fontSize="small" style={{ color: "#FFF" }} /></span>
                        </Grid>
                    }
                </Grid>
            </Container>

            <Menu
                id="menu_appbar"
                anchorEl={state.anchorElement}
                keepMounted
                open={state.open}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                onClose={handleCloseMenu}
            >
                <MenuItem id="history" data-menu="history" onClick={handleOrderHistory}>View ScreenPrint order history</MenuItem>
                {
                    (global.user?.role?.weight != undefined && global.user.role.weight > 0) &&
                    <MenuItem id="pos" data-menu="pos" onClick={handlePos}>Order management</MenuItem>
                }
                {
                    (global.user?.role?.weight != undefined && global.user.role.weight >=150) &&
                    <MenuItem id="reporting" data-menu="reporting" onClick={handleReporting}>Reporting</MenuItem>
                }
                <MenuItem id="logout" data-menu="logout" onClick={handleLogout}>Logout <span style={{marginLeft: 10, paddingTop: 5}}>{loggingOut && <CircularProgress size={20} />}</span></MenuItem>

            </Menu>
        </Box>
    );
}

const mapStateToProps = (state: AppState) => ({
    menu: state.menu,
    global: state.global
});

export default connect(mapStateToProps)(withRouter(SubHeader));
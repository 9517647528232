import React from "react";
import { Grid, Theme, withStyles, WithStyles, Button } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

// Store
import { connect } from "react-redux";
import { AppState } from "../store";
import { IMyMenuState, myMenuActions } from "../store/myMenu";
import { globalActions } from "../store/global";

const styles = (theme: Theme) => ({
    container: {
        background: "#000",
        overflow: "hidden",
        paddingLeft: theme.spacing(7),
        paddingRight: theme.spacing(7),
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
        minHeight: theme.spacing(89)
        },
    homeButton: {
        marginTop: theme.spacing(7),
        backgroundColor: "#007D7A",
        color: "#FFF",
        border: "1px solid #007D7A",
        fontWeight: "bold" as "bold",
        "&:hover": {
            backgroundColor: "#000",
            color: "#007D7A",
            border: "1px solid #007D7A",
        }
    },
});

interface IProps {
    myMenu: IMyMenuState;
    showNavigation: any;
    printPalPage: any;
}

class NotFound extends React.Component<WithStyles & IProps> {
    constructor(props: any) {
        super(props);

        this.props.printPalPage(false);
        this.props.showNavigation(false);
    }

    render() {
        const { classes } = this.props;

        return (
            <div style={{ minHeight: 'calc(100vh - 293px)'}}>
                <Grid container justify="center" className={classes.container}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", fontSize: 150, color: "gray", marginTop: 40 }}>
                        <strong>404</strong>
                        <div style={{ color: "#FFF",fontSize: 70 }}>
                            Page not found
                        </div>
                        <div style={{ color: "#FFF", fontSize: 25, marginTop: 40 }}>
                            The page you are looking for was moved, removed,<br />renamed or might never have existed
                        </div>
                        <Button variant="outlined" className={classes.homeButton} component={RouterLink} to="/">Back to the homepage</Button>
                    </div>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    myMenu: state.myMenu
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        showNavigation: (showNavigation: boolean) => dispatch(globalActions.showNavigation(showNavigation)),
        printPalPage: (printPalPage: boolean) => dispatch(myMenuActions.printPalPage(printPalPage))
    }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(NotFound));
import { Action } from "redux";

export const ORDERS_LOADING = "ORDERS_LOADING";
export const GET_ORDERS = "GET_ORDERS";
export const PAPER_OPTIONS_LOADING = "PAPER_OPTIONS_LOADING";
export const GET_PAPER_OPTIONS = "GET_PAPER_OPTIONS";
export const GET_PRINTPAL_CONFIG = "GET_PRINTPAL_CONFIG";
export const PRINTPAL_CONFIG_LOADING = "PRINTPAL_CONFIG_LOADING";
export const GET_TOKEN = "GET_TOKEN";
export const TOKEN_LOADING = "TOKEN_LOADING";
export const PAYMENT_AUTHORISATION = "PAYMENT_AUTHORISATION";
export const PURCHASE = "PURCHASE";
export const CHECKOUT = "CHECKOUT";
export const VOUCHER_RESULT = "VOUCHER_RESULT";
export const SET_ORDER_STATUS = "SET_ORDER_STATUS";

export interface IPrintPalState {
    loading: boolean;
    orderData?: {
        orders: any[],
        statuses: any[],
        canEdit: boolean
    };
    paperData: any[];
    config?: IPrintPalConfig;
    token: string;
    authorisation: any;
    authorising: boolean;
    checkout: boolean;
    purchasedOrder?: any;
    purchaseResult?: any;
    validVoucher: boolean;
}

export interface IPurchasedOrder {
    
}

export interface IPrintPalConfig {
    vatPercent: number,
    carriage: number,
}

export interface OrdersLoading extends Action {
    type: typeof ORDERS_LOADING;
    loading: boolean;
}

export interface GetOrders extends Action {
    type: typeof GET_ORDERS;
    orderData: any[];
}

export interface PaperOptionsLoading extends Action {
    type: typeof PAPER_OPTIONS_LOADING;
    loading: boolean;
}

export interface GetPaperOptions extends Action {
    type: typeof GET_PAPER_OPTIONS;
    paperData: any[];
}

export interface PrintPalConfigLoading extends Action {
    type: typeof PRINTPAL_CONFIG_LOADING;
    loading: boolean;
}

export interface GetPrintPalConfig extends Action {
    type: typeof GET_PRINTPAL_CONFIG;
    config: IPrintPalConfig;
}

export interface TokenLoading extends Action {
    type: typeof TOKEN_LOADING;
    loading: boolean;
}

export interface GetToken extends Action {
    type: typeof GET_TOKEN;
    token: string;
}

export interface Authorising extends Action {
    type: typeof PAYMENT_AUTHORISATION;
    authorising: boolean;
}

export interface Purchase extends Action {
    type: typeof PURCHASE;
    authorisation: any[];
}

export interface Checkout extends Action {
    type: typeof CHECKOUT;
    checkout: boolean;
}

export interface VoucherResult extends Action {
    type: typeof VOUCHER_RESULT;
    valid: boolean;
}

export interface SetOrderStatus extends Action {
    type: typeof SET_ORDER_STATUS;
    orderId: number;
    statusId: number;
}

export type Actions = OrdersLoading | GetOrders | PaperOptionsLoading | GetPaperOptions | GetPrintPalConfig | PrintPalConfigLoading | GetToken | TokenLoading | Purchase | Authorising | Checkout | VoucherResult | SetOrderStatus;
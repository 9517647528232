import { FormControlLabel, makeStyles, Radio, RadioGroup, TextField } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { FieldComponent, FieldEditor } from "../FloatingContentPanel"
import { useSelector } from "react-redux"
import { AppState } from "../../../store"
import Futura from "../../../assets/Futura.png";
import BETM from "../../../assets/BETM.png";
import Bebas from "../../../assets/Bebas.png";

const useStyles = makeStyles({
    textSectionContainer: {
        height: "100%",
        width: "100%"
    },
    text: {
        
    },
    label: {
        display: "block"
    },
    color: {
        display: "block",
        width: "26px",
        height: "26px",
        marginRight: "12px",
        cursor: "pointer",
        border: "1px solid",
        boxSizing: "content-box"
    }
});

interface BodyTextSectionData {
    textTitle: string
    textValue: string
    textStyle: any,
    value: string,
    textColor: string,
    fontFamily: string,
    rootStyle?: any
}
interface BodyTextSectionConfig {}

interface IBodyTextEditor extends FieldEditor<BodyTextSectionConfig, BodyTextSectionData> {}

export const BodyTextEditor: React.FC<IBodyTextEditor> = ({ config, field, onChangeField }) => {
    const [selectedColor, setSelectedColor] = useState<string>(field?.data?.textColor);
    const [selectedFont, setSelectedFont] = useState<string>(field?.data?.fontFamily);
    const menuState = useSelector((state: AppState) => state.menu);
    const classes = useStyles();
    let colors = ["#d7ccc6", "#b8cec1", "#eef1b5", "#f5938b", "#cddaf0", "#48484a", "#488769", "#eed32b", 
        "#c4323a", "#235f8e", "#06080d", "#2c5f48", "#e39027", "#992227", "#0a498a"
    ];
    let fonts = [
        {"name": <img src={Futura} alt="Futura"/>, value: "\"Futura Std\""},
        {"name": <img src={BETM} alt="BETM"/>, value: "\"Betm\""},
        {"name": <img src={Bebas} alt="Bebas"/>, value: "\"Bebas Neue\""},
    ];
    const onTextChange = (value: string) => {
        onChangeField("mealDealBodyText", {
            ...field.data,
            textValue: value
        });
    };

    useEffect(() => {
        onChangeField("mealDealBodyText", {
            ...field.data,
            textColor: selectedColor,
            fontFamily: selectedFont
        });
    }, [selectedColor, selectedFont]);
    return(
        <div>
            <span className={classes.label}>Text colour</span>
            <div style={{display: "flex", margin: "12px 0"}}>
                {
                    colors.map((row) => {
                        return(
                            <span 
                                style={{background: row, border: `${selectedColor === row ? "3px solid" : "1px solid"}`}} 
                                key={row} 
                                className={classes.color}
                                onClick={()=> setSelectedColor(row)}
                            >
                            </span>
                        )
                    })
                }
            </div>
            <span>Font</span>
            <div style={{display: "flex", margin: "12px 0"}}>
                <RadioGroup
                    row
                    value={selectedFont}
                    onChange={(e) => { 
                        setSelectedFont(e.target.value as any)
                    }}
                >
                {
                    fonts.map((row) => {
                        return(
                            <FormControlLabel control={<Radio/>} value={row?.value} label={row?.name} style={{fontWeight: 900}}/>
                        )
                    })
                }
                </RadioGroup>
            </div>
            <TextField 
                id={field.name} 
                label={field.data.textTitle} 
                defaultValue={field.data.textValue} 
                placeholder="BODY TEXT"
                fullWidth
                onChange={(e) =>{ onTextChange(e.target.value); }} 
            />
        </div>
    )
}

interface IBodyTextSection extends FieldComponent<BodyTextSectionData> {}

export const BodyText: React.FC<IBodyTextSection> = ({ field }) => {
    const { textValue, textColor, rootStyle, fontFamily } = field.data;
    const classes = useStyles();
    let strokeColor = "#000000"
    if(["#0a498a", "#992227", "#2c5f48", "#06080d", "#48484a", "#488769", "#c4323a", "#235f8e"].includes(textColor)){
        strokeColor = "#FFFFFF";
    }
    return(
        <div style={{...rootStyle}} className={classes.textSectionContainer}>
            <div style={{
                color: textColor,
                fontFamily: fontFamily,
                WebkitTextStrokeWidth: "0.03em",
                WebkitTextStrokeColor: strokeColor,
                textAlign: "center",
                textShadow: "8px 12px 20px #000"
            }} className={classes.text}>{textValue}</div>
        </div>
    )
}
import React, { useEffect, useState } from 'react';

function LoadingSpinner(props: LoadingSpinnerProps) {

    const [isHidingFinish, setIsHidingFinish] = useState(true);

    const [hasSetIsHiding, setHasSetIsHiding] = useState(false);

    useEffect(() => {
        if (props.isFinished === true && hasSetIsHiding) {
            setIsHidingFinish(false)

            const timer = setTimeout(() => {
                setIsHidingFinish(true)
            }, 1000);
            return () => clearTimeout(timer);
        }

        setHasSetIsHiding(true);

    }, [props.isFinished])

    let half = props.width / 2;
    if (props.isFinished) {
        return <div style={{ width: props.width, display: "flex", justifyContent: "center", transition: "opacity 0.1s linear", opacity: isHidingFinish ? 0 : 1 }}><svg fill="#ffa40b" focusable="false" viewBox="0 0 24 24"><path height={48} width={48} d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path></svg></div>
    }

    return (
        <div style={{ width: "100%", display: "flex", justifyContent: "center", ...props.style }}>
            <svg width={props.width} height={props.width}>
                <circle
                    cx={half}
                    cy={half}
                    fill="none"
                    stroke="#f59a23"
                    strokeWidth="3"
                    r="16"
                    strokeDasharray="75.39822368615503 27.132741228718345">
                    <animateTransform
                        attributeName="transform"
                        type="rotate"
                        repeatCount="indefinite"
                        dur="1s"
                        values={`0 ${half} ${half};360 ${half} ${half}`}
                        keyTimes="0;1"/>
                </circle>
            </svg>
        </div>
        
        )
}


interface LoadingSpinnerProps {
    width: number;
    style: React.CSSProperties;
    isFinished: boolean;
    
}

LoadingSpinner.defaultProps = {
    width: 250,
    style: {},
    isFinished: false
}

export default LoadingSpinner;
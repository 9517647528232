import React from 'react';
import { Grid, Typography, Card, CardContent, CardMedia, WithStyles, withStyles, Theme, Button, CircularProgress, Drawer, Divider } from '@material-ui/core';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { CloseRounded } from "@material-ui/icons";

import { myMenuOperations } from "../../store/myMenu";
import { connect } from 'react-redux';

const styles = (theme: Theme) => ({
    card: {
        "&:hover": {
            boxShadow: "none",
            cursor: "pointer",
        }
    },
    selectButton: {
        backgroundColor: "#FFF",
        color: "#007D7A",
        border: "1px solid #007D7A",
        fontWeight: "bold" as "bold",
        "&:hover": {
            backgroundColor: "#007D7A",
            color: "#FFF"
        }
    },
    text: {
        color: "#007D7A",
        fontWeight: "bold" as "bold",
    },
    label: {
        color: "black",
        fontWeight: "bold" as "bold",
    },
    buildButton: {
        backgroundColor: "#007D7A",
        color: "#FFF",
        fontWeight: "bold" as "bold",
        "&:hover": {
            backgroundColor: "#FFF",
            color: "#007D7A"
        }
    },
    descriptionText: {
        marginBottom: theme.spacing(2)
    },
    previewBox: {
        borderBottom: "2px solid rgba(184, 142, 133, 0.996078431372549)",
        width: "100%",
        color: "rgba(184, 142, 133, 0.996078431372549)",
        backgroundColor: "#FFF",
        padding: theme.spacing(2),
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        marginRight: theme.spacing(20),
        boxShadow: "0px 3px rgba(184, 142, 133, 0.996078431372549)"
    },
    radioIcon: {
        color: "#007D7A",
        fontSize: "50px"
    },
    selectedFormat: {
        border: "2px solid #007D7A"
    },
    unselectedFormat: {
        border: "none"
    },
    media: {
        height: "260px",
        width: "auto"
    },
    formatOverlay: {
        top: "247px",
        left: "15px",
        color: "black",
        backgroundColor: "transparent"
    },
    banner: {
        height: "60%",
        width: "60%"
    },
    previewOverlay: {
        top: "-38px",
        right: "-5px",
        zindex: 2,
    },
    blueCircleBold: {
        fontWeight: "bold" as "bold",
        color: "#FFF",
    },
    blueCirclePlain: {
        color: "#FFF",
    },
    previewImage: {
        maxWidth: 300,
        width: "100%",
        height: "auto",
        marginBottom: 30,
        zIndex: 1,
    },
    previewImageMobile: {
        maxWidth: 180,
        width: "100%",
        height: "auto",
        zIndex: 1,
    },
    drawerPaper: {
        marginTop: 100,
        height: "100%",
        width: "80%",
        overflow: "auto"
    },
    closeDrawerButton: {
        position: "absolute" as "absolute",
        right: 30,
        top: 30,
        width: 32,
        height: 32,
        border: "2px solid #FFF",
        borderRadius: "50%",
        backgroundColor: "#007D7A",
        textAlign: "center" as "center",
        lineHeight: "32px",
        color: "#FFF",
        transform: "translate(50%,-50%)",
        cursor: "pointer",
        "& svg": {
            width: 32,
            height: "100%"
        }
    },
    customLabelContainer: {
        padding: "3px 10px",
        textTransform: "uppercase",
        color: "white",
        fontWeight: 600,
        fontSize: 15
    }
});

interface IMenuFormatsProps {
    getFormats: any,
    myMenu: any,
}

interface IMenuFormatsState {
    selectedTitle: string,
    selectedDescription: string,
    selectedPreviewImage: string,
    selectedFormatId: number,
    selectedSize: string,
    selectedSheet: string,
    canBuildMenu: boolean,
    openDrawer: boolean,
    isMobileApp: boolean,
    selectedFormatStylesId: number,
    selectedStyle: any
}

class MenuFormats extends React.Component<WithStyles & IMenuFormatsProps & any, IMenuFormatsState> {
    constructor(props: any) {
        super(props);
        
        let selectedStyle = props.myMenu.styleData.styles.find((s: any) => s.id == sessionStorage.getItem('styleId'));

        this.state = {
            selectedTitle: "Please choose...",
            selectedDescription: "",
            selectedPreviewImage: "",
            selectedFormatId: -1,
            selectedSize: "",
            selectedSheet: "",
            canBuildMenu: false,
            openDrawer: true,
            isMobileApp: false,
            selectedFormatStylesId: -1,
            selectedStyle: selectedStyle
        };

        this.handleBuildMyMenuClicked = this.handleBuildMyMenuClicked.bind(this);
        this.handleCloseDrawer = this.handleCloseDrawer.bind(this);
    }

    handleMenuFormatClicked = (event: any) => {
        event.preventDefault();

        if (event.currentTarget.id == this.state.selectedFormatId) {
            this.setState({
                selectedTitle: "Please choose...",
                selectedDescription: "",
                selectedPreviewImage: "",
                selectedFormatId: -1,
                selectedSize: "",
                selectedSheet: "",
                canBuildMenu: false,
                selectedFormatStylesId: -1
            });
            this.props.formatSelected(null, null);
        } else {
            var item = this.props.myMenu.formatData.formats.filter((x: any) => x.id == event.currentTarget.id)[0];

            this.setState({
                selectedTitle: item.title,
                selectedDescription: item.description,
                selectedPreviewImage: `./assets/styles/preview/${item.previewImgUrl}`,
                selectedFormatId: event.currentTarget.id,
                selectedSize: item.size,
                selectedSheet: item.sheet,
                canBuildMenu: true,
                selectedFormatStylesId: item.formatStylesId
            });

            this.props.formatSelected(event.currentTarget.id, item.formatStylesId);
        }
    }

    handleBuildMyMenuClicked = (event: any) => {
        if (this.state.isMobileApp)
            this.props.closeDrawer();

        this.props.formatSelected(event.currentTarget.id, this.state.selectedFormatStylesId);
        this.props.openPopup();
    }

    componentDidMount() {
        this.props.getFormats(sessionStorage.getItem('styleId'));

        this.setResponsiveness();
        window.addEventListener("resize", () => this.setResponsiveness());
    }

    componentWillUnmount() {
        window.removeEventListener('resize', () => this.setResponsiveness());
    }

    setResponsiveness = () => {
        return window.innerWidth < 900
            ? this.setState((prevState) => ({ ...prevState, isMobileApp: true }))
            : this.setState((prevState) => ({ ...prevState, isMobileApp: false }));
    };

    handleCloseDrawer() {
        this.props.closeDrawer();
    }

    render() {
        const { classes, myMenu } = this.props;
        const { formatData: { formats, style }, loading, styleData } = myMenu;
        const { customLabel } = this.state.selectedStyle;

        //debugger

        if (loading || formats === undefined)
            return <Grid style={{ flex: "1" }} container justify="center" alignItems="center">
                <Grid item>
                    <CircularProgress />
                </Grid>
            </Grid>

        if (this.state.isMobileApp) {
            return <div>
                <Grid item container>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            {formats.map((format: any) => (
                                <Grid item xs={12} key={format.id}>
                                    <div className={this.state.selectedFormatId == format.id ? classes.selectedFormat : classes.unselectedFormat}>
                                        <Card raised={true} className={classes.card} onClick={this.handleMenuFormatClicked} id={format.id} style={{ position: "relative" }}>
                                            <CardMedia className={classes.media} image={`./assets/formats/${format.imgUrl}`} />

                                            <div className={classes.formatOverlay} style={{ position: "absolute" }}>
                                                {customLabel != null
                                                ?
                                                    <div className={classes.customLabelContainer} style={{backgroundColor: customLabel.colorHex}}>{customLabel.title}</div>
                                                :                                            
                                                    format.isNew
                                                        ? <img src="./assets/banners/new.png" alt="banner" className={classes.banner} />
                                                        : format.isPopular
                                                            ? <img src="./assets/banners/popular.png" alt="banner" className={classes.banner} />
                                                            : null
                                                }
                                            </div>

                                            <CardContent>
                                                <Grid container direction="row" alignItems="center">
                                                    <Grid item xs={10}>
                                                        <Typography style={{ marginLeft: "10px" }}>
                                                            <strong>{format.title}</strong>
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={2}>
                                                        {this.state.selectedFormatId == format.id
                                                            ? <RadioButtonCheckedIcon className={classes.radioIcon} />
                                                            : <RadioButtonUncheckedIcon className={classes.radioIcon} />
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>

                <div>
                    <Drawer anchor="right" open={this.props.openDrawer} onClose={this.handleCloseDrawer} classes={{ paper: classes.drawerPaper }}>
                        <Grid container style={{ height: 10, width: "90%", margin: 20 }}>
                            <Grid item xs={8}>
                                <Typography variant="h3"><strong>My menu</strong></Typography>
                            </Grid>

                            <Grid item xs={4} container justify="flex-end" >
                                <div onClick={this.handleCloseDrawer} className={classes.closeDrawerButton}>
                                    <CloseRounded fontSize="small" />
                                </div>
                            </Grid>
                        </Grid>

                        <Grid item container justify="center" style={{ width: "90%" }}>
                            <div>
                                <img src={this.state.selectedPreviewImage} alt="item" className={classes.previewImageMobile} />
                            </div>
                        </Grid>

                        <Grid item container justify="flex-start" style={{ width: "90%", height: "100%" }}>
                            <div style={{ width: "90%", marginLeft: 20 }}>
                                <Typography style={{ fontSize: 12 }}>
                                    <span className={classes.label}>Chosen size: </span>
                                    <span className={classes.text}>{this.state.selectedTitle}</span>
                                </Typography>

                                <Divider style={{ marginBottom: "0.5rem" }} />

                                <Typography style={{ fontSize: 12 }} className={classes.descriptionText}>
                                    {this.state.selectedDescription}
                                </Typography>

                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="small"
                                    id={"" + this.state.selectedFormatId}
                                    onClick={this.handleBuildMyMenuClicked}
                                    className={classes.buildButton}
                                    style={{ fontSize: 12 }}
                                    disabled={!this.state.canBuildMenu}>
                                    Build my menu
                                </Button>
                            </div>
                        </Grid>
                    </Drawer>
                </div>
            </div>
        }

        return <Grid item container>
            <Grid item xs={8}>
                <Grid container spacing={3}>
                    {formats.map((format: any) => (
                        <Grid item md={6} sm={12} key={format.id}>
                            <div className={this.state.selectedFormatId == format.id ? classes.selectedFormat : classes.unselectedFormat}>
                                <Card raised={true} className={classes.card} onClick={this.handleMenuFormatClicked} id={format.id} style={{ position: "relative" }}>
                                    <CardMedia className={classes.media} image={`./assets/formats/${format.imgUrl}`} />

                                    <div className={classes.formatOverlay} style={{ position: "absolute" }}>
                                        {customLabel != null
                                        ?
                                            <div className={classes.customLabelContainer} style={{backgroundColor: customLabel.colorHex}}>{customLabel.title}</div>
                                        :     
                                            format.isNew
                                                ? <img src="./assets/banners/new.png" alt="banner" className={classes.banner} />
                                                : format.isPopular
                                                    ? <img src="./assets/banners/popular.png" alt="banner" className={classes.banner} />
                                                    : null
                                        }
                                    </div>

                                    <CardContent>
                                        <Grid container direction="row" alignItems="center">
                                            <Grid item xs={10}>
                                                <Typography style={{ marginLeft: "10px" }}>
                                                    <strong>{format.title}</strong>
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={2}>
                                                {this.state.selectedFormatId == format.id
                                                    ? <RadioButtonCheckedIcon className={classes.radioIcon} />
                                                    : <RadioButtonUncheckedIcon className={classes.radioIcon} />
                                                }
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </Grid>

            <Grid item xs={1}>
                <Divider style={{ marginLeft: "2.5rem" }} orientation="vertical" />
            </Grid>

            <Grid item xs={3}>
                <Grid item container justify="flex-start">
                    <Grid xs={12} item>
                        <div style={{ position: "relative", marginRight: 40 }}>
                            {this.state.selectedPreviewImage !== ""
                                ?
                                <div>
                                    <img src={this.state.selectedPreviewImage} alt="item" className={classes.previewImage} />

                                    <div className={classes.previewOverlay} style={{ position: "absolute" }}>
                                        <div style={{ zIndex: 3, position: "relative" }}>
                                            <img src={`./assets/bluecircle.svg`} alt="blue circle" />
                                        </div>

                                        <div style={{ top: "-80px", zIndex: 4, position: "relative", textAlign: "center" }}>
                                            <Typography variant="h2" className={classes.blueCircleBold}>
                                                {this.state.selectedSize}
                                            </Typography>

                                            <Typography className={classes.blueCirclePlain}>
                                                ({this.state.selectedSheet})
                                        </Typography>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className={classes.previewBox}>
                                    <Typography variant="body2">Choose a menu format from the left and we'll show you a preview</Typography>
                                </div>
                            }
                        </div>

                        <div style={{ marginRight: 10 }}>
                            <Typography variant="body2">
                                <span className={classes.label}>Chosen size: </span>
                                <span className={classes.text}>{this.state.selectedTitle}</span>
                            </Typography>

                            <Divider style={{ marginBottom: "0.5rem" }} />

                            <Typography variant="body2" className={classes.descriptionText}>
                                {this.state.selectedDescription}
                            </Typography>

                            <Button
                                variant="contained"
                                color="secondary"
                                id={"" + this.state.selectedFormatId}
                                onClick={this.handleBuildMyMenuClicked}
                                className={classes.buildButton}
                                disabled={!this.state.canBuildMenu}>
                                Build my menu
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    }
}

const mapStateToProps = (state: any) => {
    return {
        myMenu: state.myMenu
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getFormats: (styleId: string) => dispatch(myMenuOperations.getAllFormats(styleId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MenuFormats));
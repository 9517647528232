import React, { useEffect, useState } from "react";
import { FilterPropertiesState, FilterOption } from "../../components/POS/Filters";
import LoadingSpinner from "../../components/POS/LoadingSpinner";
import TableWrapper from "../../components/POS/table/Table";
import Filters from "../../components/POS/Filters";
import LazyIcon from "../../components/POS/LazyIcon";
import { useHistory } from "react-router-dom";
import PDFIcon from "../../assets/pdf.png";
import { makeStyles, Theme, createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        exportButton: {
            background: "#d7d7d7",
            border: "none",
            color: "#000",
            padding: "8px 12px",
            cursor: "pointer",
            fontWeight: 600,
            minWidth: 150
        }
    }),
);

type ExportCSVParams = {
    data: string;
    fileName: string;
    fileType: string;
  };

function OrdersPage(props: { "orderStates": FilterOption[] | undefined}) {

    const [orders, setOrders] = useState<OrderTableRow[]>();
    const [exportingOrders, setExportingOrders] = useState<boolean>(false);

    const [filterState, setFilterState] = useState<FilterPropertiesState>({ filterSelection: '', searchBar: '', limit: 20 });

    const history = useHistory();
    const classes = useStyles();

    const downloadFile = ({ data, fileName, fileType }: ExportCSVParams) => {
        const utf8BOM = new Uint8Array([0xEF, 0xBB, 0xBF]); // BOM for UTF-8
        const blob = new Blob([utf8BOM, data], { type: `${fileType};charset=UTF-8` });
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    const handleExportOrders = async () => {
        setExportingOrders(true);
        try{
            const response = await fetch("/api/export/orders", {
                cache: "no-cache",
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                },
            })
        
            if (!response.ok) {
                throw new Error('Error on exporting Orders');
            }
            setExportingOrders(false);
            const data = await response.text();
            downloadFile({
                data: data,
                fileName: 'Orders.csv',
                fileType: 'text/csv',
              })
        }catch(err){
            setExportingOrders(false);
            console.log(err, "Error on exporting Orders")
        }
    }

    useEffect(() => {
        fetch('../../api/orders').then(async response => {

            const jsonResponse = await response.json();

            const cleanedData: OrderTableRow[] = jsonResponse.orders.map((order: OrderDTO) => {

                const d = order.shippingAddress;
                let cleanShippingAddress = "";

                if (d !== undefined) {
                    cleanShippingAddress = `${d.firstName} ${d.lastName} ${d.company} ${d.address1} ${d.address2} ${d.address3} ${d.country} ${d.country} ${d.postcode}`
                }

                return {
                    ...order,
                    cleanStatus: props.orderStates?.find(state => state.value === order.status)?.text,
                    icon: <LazyIcon height={100} width={100} imageUrl={order.orderPreviewFile} />,
                    orderDateClean: cleanDate(order.orderDate.toString(), false),
                    costClean: formatCost(order.cost),
                    boldOrderNumber: <b>{order.orderNumber}</b>,
                    cleanShippingAddress,
                    email: order.shippingAddress?.email
                }
            });

            console.log(cleanedData);

            setOrders(cleanedData);


        });
    }, []);

    if (orders === undefined)
        return <LoadingSpinner/>

    return <>
        <button className={classes.exportButton} onClick={() => handleExportOrders()} disabled={exportingOrders}>
            {
                exportingOrders ? "Exporting..."
                : "Export as .csv"
            }
        </button>
        <Filters
            filterValue={filterState.filterSelection}
            searchValue={filterState.searchBar}
            limitValue={filterState.limit}

            filterChange={(e) => { setFilterState({ ...filterState, filterSelection: e.target.value }) }}
            searchChange={(e) => { setFilterState({ ...filterState, searchBar: e.target.value }) }}
            limitChange={(e) => { setFilterState({ ...filterState, limit: parseInt(e.target.value) }) }}

            searchPlaceholder="Search by customer, order number or design"

            filterOptions={props.orderStates?.filter(s => s.text != "In production" && s.text != "Printed")}

            title="Orders summary"

            showFilters={false}

            showPageSize

            pageSizeOptions={[10, 20, 30, 50]}

        />
        
        <br />
        <TableWrapper

            data={orders}

            keys={["icon", "boldOrderNumber", "menuTitle", "paperSize", "quantity", "costClean", "orderDateClean", "agentDisplayName", "email", "orderDate", "orderNumber", "status"]}
            headings={["", "Order #", "Design", "Paper size", "Qty", "Price", "Order date",  "Customer", "Email", "Raw Order Date", "Raw Order Number", "raw status"]}
            columnWidths={[1, 1, 1, 2, 0.5, 0.5, 1, 1, 2, 0, 0, 0]}

            searchableColumns={["agentDisplayName", "orderNumber", "menuTitle", "email"]}

            searchTerm={filterState.searchBar}
            columnFilters={[{
                id: "status",
                value: filterState.filterSelection
            }]}

            pageSize={filterState.limit}

            noArrowColumns={["icon", "hasPDF"]}

            onRowClick={(order) => { history.push("./customers/" + order.userId + "/order/" + order.id) }}

            customSortValues={[
                {
                    visibleColumn: "orderDateClean",
                    hiddenColumn: "orderDate"
                }, {
                    visibleColumn: "statusClean",
                    hiddenColumn: "status"
                }
            ]}
            type={"ordersPage"}
        ></TableWrapper>
    </>
}


export interface OrderDTO {
    id: number;
    orderNumber: string;
    quantity: number;
    cost: number;
    orderDate: Date;
    status: string;
    orderStatus: string;
    name: string;
    address: string;
    imgUrl: string;
    snapshotFileId: number;
    paperSize: string;
    voucheCode?: any;
    paperName: string;
    trackingNumber: string;
    shippingDate?: Date;
    notes: string;
    userId: number;
    shippingAddress?: ShippingAddressDTO;
    orderPreviewFile: string;
    agentDisplayName: string;
}

export interface ShippingAddressDTO {
    address1: string;
    address2: string;
    address3: string;
    addressId: number;
    city: string;
    company: string;
    country: string;
    county: string;
    email: string;
    firstName: string;
    isBilling: boolean;
    isPrimaryShipping: boolean;
    lastName: string;
    postcode: string;
    telephone: string;
    title: string;
}

export interface OrderTableRow extends OrderDTO {
    orderDateClean: string;
    icon: JSX.Element | null;
    hasPdf: JSX.Element | null;
    costClean: string;
    boldOrder: JSX.Element | null;
    cleanShippingAddress: string;
}

export function cleanDate(date: string, includeTime: boolean = true): string {
    const d = new Date(date);

    const d1 = `${d.getDate().toString().padStart(2, '0')}/${(d.getMonth() + 1).toString().padStart(2, '0')}/${d.getFullYear()}`;
    const t = `${d.getHours().toString().padStart(2, '0')}:${d.getMinutes().toString().padStart(2, '0')}`

    if (includeTime) {
        return `${d1} ${t}`;
    } else {
        return `${d1}`;
    }

}

export function formatCost(n: number) {    

    let pence = (n % 1 * 100).toFixed().padStart(2, "0");
    let pounds = Math.floor(n).toFixed();

    return `£${pounds}.${pence}`;

}


export default OrdersPage;
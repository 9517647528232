import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles({

    container: {
        display: "flex",
        height: 50,
        alignItems: "center",
        paddingLeft: 10
    },

    buttons: {
        height: 30,
        width: 30,
        backgroundColor: "#FF9F1C",
        border: "1px #FF9F1C solid",
        color: "white",
        "&:disabled": {
            backgroundColor: "#a9a9a9",
            borderColor: "#a9a9a9"
        }
    },

    number: {
        height: 30,
        width: 35,
        display: "block",
        border: "1px #FF9F1C solid",
        borderLeft: "none",
        borderRight: "none",
        textAlign: "center",
        lineHeight: "29px",
        backgroundColor: "white"
    },

    eyeIcon: {
        height: 30,
        margin: "0 20px",
    },

    children: {
        flexGrow: 1
    }

});

export default function QuantitySelector(props: { index: number, name: string, price?: number, multiples: number, onChange: (v: number) => void, value: number }) {

    const classes = useStyles();

    return <div className={classes.container} style={{ backgroundColor: props.index % 2 === 0 ? "initial" : "initial" }}>

        <QuantityButtons
            onClickDecrease={() => { props.onChange(props.value - props.multiples) }}
            value={props.value}
            onClickIncrease={() => { props.onChange(props.value + props.multiples) }}
        />

        <span style={{ width: "20%", display: "block", marginLeft: 40 }}>{props.name}</span>

        {
            props.price &&
            <span style={{ width: "20%" }}>£{props.price.toFixed(2)}</span>
        }
        {
            props.price &&
            <span style={{ width: "20%" }}>£{(props.price * props.value).toFixed(2)}</span>
        }

        <span style={{ width: "30%" }} >Available in multiples of {props.multiples}</span>
    </div>
}

interface IQuantityButtons {
    onClickDecrease: () => void
    decreaseDisabled?: boolean
    value: number,
    maxQuantity?: number,
    onClickIncrease: () => void
    increaseDisabled?: boolean
}
export const QuantityButtons: React.FC<IQuantityButtons> = ({ decreaseDisabled, increaseDisabled, onClickDecrease, onClickIncrease, value, maxQuantity }) => {


    const classes = useStyles();

    return (
        <div style={{ display: "flex" }}>
            <button className={classes.buttons}
                disabled={decreaseDisabled || value === 0}
                onClick={onClickDecrease}
            >-</button>
            <span className={classes.number}>{value}</span>
            <button className={classes.buttons}
                disabled={increaseDisabled || (maxQuantity && value >= maxQuantity) == true}
                onClick={onClickIncrease}
            >+</button>
        </div>
    )
}
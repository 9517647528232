import { Container, Grid, makeStyles, Theme, createStyles, Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, Redirect, Route, Switch, useRouteMatch, useLocation } from "react-router-dom";
import OrdersPage from "../../pages/POS/OrdersPage";
import ProductsPage from "../../pages/POS/ProductsPage";
import CustomerPage from "../../pages/POS/CustomerPage";
import VouchersPage from "../../pages/POS/VouchersPage";

import { AppState } from "../../store";
import CustomersPage from "../../pages/POS/CustomersPage";

import { FilterOption } from "../../components/POS/Filters";
import PosErrorBoundary from "../../pages/POS/PosErrorBoundary";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            color: "#FF9F1C",
            fontSize: 18,
            textAlign: "center",
            "& a": {
                color: "#FF9F1C",
                textDecoration: "none",
                "&:hover": {
                    color: "#FF9F1C",
                    textDecoration: "underline",
                    cursor: "pointer"
                }
            }
        },
        logo: {
            maxWidth: 450,
            width: "100%",
            height: "auto",
            [theme.breakpoints.down("sm")]: {
                width: 130                
            },
        },
        checkout: {
            color: "#666666",
            fontSize: 32,
            textAlign: "left",   
        },
        header: {
            height: 100,
            display: "flex",
            alignItems: "center",
            [theme.breakpoints.down("sm")]: {
                height: 65,
            },
        },
        headerContainer: {
            background: "#FFF",
            height: 100,
            paddingLeft: 40,
            borderBottom: "3px solid rgba(255, 159, 28, 1)",
            display: "flex",
            zIndex: 1,
            [theme.breakpoints.down("sm")]: {
                height: 65,
                paddingLeft: 0,
            },
        },
        drawerContainer: {
            padding: 0,
        },
        drawerPaper: {
            marginTop: 68,
            height: 150,
            width: 240,
            overflow: "auto"
        },
        iconButton: {
            display: "flex",
            flexDirection: "column"
        },
        menuItem: {
            color: "#000",
            fontSize: 12
        },
        orange: {
            color: "#FF9F1C",
        },
        dark: {
            color: "#666666",
        },
        link: {
            textDecoration: "none",
            color: "#666666",
        },
        headerSubtitle: {
            background: "#f2f2f2",
            padding: "15px 40px",
            display: "flex",
            justifyContent: "space-between"
        },
        navButton:{
            background: "#f59a23",
            color: "white",
            width: 140,
            height: 50,
            padding: 2,
            borderWidth: 3,
            borderStyle: "solid",
            fontWeight: "bold",
            fontFamily: "Segoe UI",
            fontSize: 16,
            cursor: "pointer"
        },
        navContainer: {
            margin: "2rem -16px",
            marginBottom: "1rem",
            width: "fit-content"
        }
    }),
);

interface PosNavItem {
    title: string,
    path: string
}

const posNavItems: PosNavItem[] = [
    {
        title: "Orders",
        path: "/orders"
    },
    //{
    //    title: "Agents",
    //    path: "/customers"
    //},
    {
        title: "Products",
        path: "/products"
    },
    {
        title: "Vouchers",
        path: "/vouchers"
    }
]

const offlineStyles: React.CSSProperties = {
    position: "fixed",
    bottom: 0,
    right: 0,
    backgroundColor: '#f2f2f2',
    borderTop: '3px solid rgba(255, 159, 28, 1)',
    padding: 30,
    margin: 20,
    boxShadow: "5px 5px 5px 0px rgba(0, 0, 0, 0.35)",

}


const PosLayout: React.FC = () => {

    const { path } = useRouteMatch();
    const location = useLocation();

    const classes = useStyles();
    const globalState = useSelector((s: AppState) => s.global);

    const [isOnline, setIsOnline] = useState(true);

    window.ononline = () => setIsOnline(true);
    window.onoffline = () => setIsOnline(false);

    console.log(globalState);

    const [orderStates, setOrderStates] = useState <FilterOption[] | undefined>(undefined);

    useEffect(() => {
        fetch(`${process.env.PUBLIC_URL}/api/orders/status/list`).then(async response => {

            setOrderStates([{value: "", text: "All"}, ...(await response.json())
                .sort((a, b) => a.displayOrder - b.displayOrder)
                .map(item => {
                    return {
                        value: item.id.toString(),
                        text: item.description
                    } })])

        });
    }, []);

    if (orderStates === undefined) {
        return null;
    }

    return(
        <div>
            <div className={classes.headerContainer}>
                <Grid container alignItems="flex-start" justify="flex-start" spacing={1} className={classes.header}>
                    <Grid item xs={4}>
                        <img src="/assets/printPal/orange_logo.png" alt="ScreenPrint logo" className={classes.logo} />
                    </Grid>
                </Grid>
            </div>
            <div className={classes.headerSubtitle}>
                <div>Welcome <span style={{fontWeight: "bold"}}>{globalState.user?.displayName}</span></div>
                {
                    globalState.user?.role?.role == "ADMIN" &&
                    <div>
                        <Link to="/">Return to design tool</Link>
                    </div>
                }
            </div>
            <Grid container spacing={4} className={classes.navContainer}>
                {
                    posNavItems.map((item) => {
                        return (
                            <Grid item key={item.path}><Link to={path + item.path}><button className={classes.navButton} style={{ borderColor: (location.pathname.startsWith(path + item.path) ? "#555555" : "#f59a23") }}>{item.title}</button></Link></Grid>
                        )
                    })
                }
            </Grid>

            {!isOnline && <div style={offlineStyles}>You are offline. Changes are not being saved. </div> }

            <PosErrorBoundary>




                <Switch>
                    <Route path={`${path}/products`}><ProductsPage /></Route>
                    <Route path={`${path}/orders`}><OrdersPage orderStates={orderStates} /></Route>
                    <Route path={`${path}/vouchers`}><VouchersPage /></Route>

                    <Route path={`${path}/customers`} exact><CustomersPage orderStates={orderStates}/></Route>
                    <Route path={`${path}/customers/:customerId/order/:orderId`}><CustomerPage orderStates={orderStates} /></Route>
                    <Route path={`${path}/customers/:customerId`}><CustomerPage orderStates={orderStates} /></Route>


                    <Redirect from={path} to={`${path}/products`} exact />

                    </Switch>


                </PosErrorBoundary>

        </div>
    )
}

export default PosLayout;
import React, { useEffect, useState } from "react";
import { CssBaseline, Grid, ThemeProvider, CircularProgress, Paper, Typography, createTheme, TextField, Button, Checkbox, FormControlLabel } from "@material-ui/core";
import ICustomTheme from "../interfaces/ICustomTheme";
import { IMenuStyle, IMenuItem, menuActions } from "../store/menu";
import MenuAnimator from "../components/decorators/MenuAnimator";
import { transformTheme } from "../utils/common";
import Pages from "../components/layout/Pages";
import Zoom from "../components/layout/Zoom";
import { ChevronRight, ChevronLeft } from "@material-ui/icons";
import MenuComponents from "../components/menu";
import EditableModal from "../components/layout/EditableModal";
import { connect } from "react-redux";
import { AppState } from "../store";

interface IMenuBuilderState {
    menuWidth: number,
    menuHeight: number,
    theme?: ICustomTheme,
    altStyles?: IMenuStyle[],
    templateData?: IMenuItem[],
    styleData?: any,
    formatData?: any,
    selectedStyle: number,
    selectedFormat: number,
    selectedPage: number,
    zoom: number,
    showEdit: boolean,
    themeEditor: string,
    altStylesEditor: string,
    templateEditor: string,
    expandType: string,
    selectedAltStyle: number,
    themeTemplate: any,
    showComponents: boolean,
    isExporting: boolean,
    pageOversized: number,
    oversizedPixels: number,
    sectionTypesEditor: string,
    sectionTypesData?: any
}

function EditBox(props) {
    const { initialValue, save, rows = 5, rowsMax = 25 } = props;
    const [state, setState] = useState(initialValue);

    useEffect(() => {
        setState(initialValue);
    }, [initialValue]);

    //Change internal state
    const onChange = (ev: any) => {
        setState(ev.target.value);
    }

    //Capture tab
    const keyDown = (e: any) => {
        var element = e.target;
        if (e.key == 'Tab') {
            e.preventDefault();
            var start = element.selectionStart;
            var end = element.selectionEnd;

            //Remove a tab if shift is pressed
            if (e.shiftKey && element.value.charAt(start - 1) === "\t") {
                element.value = element.value.substring(0, start - 1) + element.value.substring(end);
                start -= 2;
            }
            else
                element.value = element.value.substring(0, start) + "\t" + element.value.substring(end);

            //Fix caret
            element.selectionStart = element.selectionEnd = start + 1;
        }

        if (e.key == 'Enter') {
            e.preventDefault();

            var start = element.selectionStart;
            var end = element.selectionEnd;

            //Last line before caret
            var lastnl = element.value.substring(0, start).split("\n");

            if (lastnl.length > 0) {
                //Count how many tabs there are
                var tabCount = 0;
                var str = lastnl[lastnl.length - 1];

                for (var i = 0; i < str.length; i++)
                    if(str.charAt(i) === '\t')
                        tabCount++;

                //Fill a string of the same amount of tabs
                var fill = new Array(tabCount).fill("\t").join("");

                //Pop it in and place caret after it
                element.value = element.value.substring(0, start) + "\n" + fill + element.value.substring(end);
                element.selectionStart = element.selectionEnd = start + tabCount + 1;
            }
        }
    }

    //Save upwards to main state
    const blur = (ev: any) => {
        save(ev.target.value);
    }

    return <TextField fullWidth onBlur={blur} variant="outlined" onKeyDown={keyDown} multiline rows={rows} rowsMax={rowsMax} value={state} style={{ marginBottom: 0 }} inputProps={{ spellcheck: "false", autoComplete: "false", style: { resize: "vertical" } }} onChange={onChange} />
}

const initialZoom = 0.7;

class MenuCreator extends React.Component<{ closeModal: any, modalData: any, showModal: boolean, state: IMenuBuilderState }, IMenuBuilderState> {
    constructor(props: any) {
        super(props);

        //Reset exporting flag
        if ((window as any).ForceExporting)
            delete (window as any).ForceExporting;

        this.state = {
            menuWidth: 0,
            menuHeight: 0,
            theme: undefined,
            altStyles: undefined,
            templateData: undefined,
            styleData: undefined,
            formatData: undefined,
            themeTemplate: undefined,
            selectedStyle: -1,
            selectedFormat: -1,
            selectedPage: 0,
            zoom: initialZoom,
            showEdit: false,
            themeEditor: "",
            altStylesEditor: "",
            templateEditor: "",
            expandType: "",
            selectedAltStyle: 0,
            showComponents: false,
            isExporting: false,
            pageOversized: -1,
            oversizedPixels: 0,
            sectionTypesEditor: "",
            sectionTypesData: undefined
        };
    }

    componentDidMount() {
        if (!this.state.styleData) {
            fetch("/api/Creator").then(response => {
                if (response.status === 200)
                    return response.json();
                else
                    throw Error();
            })
                .then(response => {
                    this.setState({ styleData: response.styles });
                });
        }
    }

    onStyleSelect = (style: any) => {
        if (this.state.selectedStyle === style.id)
            this.setState({ selectedStyle: -1, formatData: undefined });
        else
            this.setState({ selectedStyle: style.id, formatData: style.formats });
    }

    onFormatSelect = (format: any) => {
        if (this.state.selectedFormat === format.id)
            this.setState({
                selectedFormat: -1,
                theme: undefined
            });
        else {
            var altStyles = JSON.parse(format.altStyles);
            var themeTemplate = JSON.parse(format.theme);
            var templateData = JSON.parse(format.templateData);
            var sectionTypesData = JSON.parse(format.sectionTypes);

            var theme = JSON.parse(JSON.stringify(themeTemplate));

            if (altStyles.length > 0) {
                var initialStyle = {
                    fontGroup: altStyles[this.state.selectedAltStyle].fontGroups,
                    palette: altStyles[this.state.selectedAltStyle].palette,
                    assets: altStyles[this.state.selectedAltStyle].assets,
                    filters: altStyles[this.state.selectedAltStyle].filters
                };

                theme = transformTheme(theme, initialStyle);
            }

            this.setState({
                selectedFormat: format.id,
                theme: createTheme(theme),
                altStyles,
                templateData,
                themeTemplate,
                width: format.width,
                height: format.height,
                themeEditor: JSON.stringify(themeTemplate, null, "\t"),
                altStylesEditor: JSON.stringify(altStyles, null, "\t"),
                templateEditor: JSON.stringify(templateData, null, "\t"),
                sectionTypesData,
                sectionTypesEditor: JSON.stringify(sectionTypesData, null, "\t")
            });
        }
    }

    onSelectPage = (page: number) => {
        this.setState({ selectedPage: page });
    }

    handleZoom = (zoom: number) => {
        if(zoom >= 0)
            this.setState({ zoom });
    }

    onEdit = () => {
        this.setState({ showEdit: !this.state.showEdit });
    }

    expand = (type: string) => {
        if (this.state.expandType === type)
            this.setState({ expandType: "" });
        else
            this.setState({ expandType: type });
    }

    onSelect = (style: number) => {
        const { altStyles, themeTemplate } = this.state;

        var initialStyle = {
            fontGroup: altStyles[style].fontGroups,
            palette: altStyles[style].palette,
            assets: altStyles[style].assets,
            filters: altStyles[style].filters
        };

        var theme = transformTheme(JSON.parse(JSON.stringify(themeTemplate)), initialStyle);
        this.setState({ theme: createTheme(theme), selectedAltStyle: style });
    }

    updatePreview = () => {
        const { themeEditor, altStylesEditor, templateEditor, selectedAltStyle } = this.state;

        try {
            var themeTemplate = JSON.parse(themeEditor);
            var altStyles = JSON.parse(altStylesEditor);

            var theme = JSON.parse(JSON.stringify(themeTemplate));

            if (altStyles.length > 0) {
                var initialStyle = {
                    fontGroup: altStyles[selectedAltStyle].fontGroups,
                    palette: altStyles[selectedAltStyle].palette,
                    assets: altStyles[selectedAltStyle].assets,
                    filters: altStyles[selectedAltStyle].filters
                };

                theme = transformTheme(theme, initialStyle);
            }

            this.setState({
                themeTemplate,
                theme: createTheme(theme),
                altStyles,
                templateData: JSON.parse(templateEditor)
            });
        } catch (e) {
            alert("JSON issue " + e);
        }
    }

    save = () => {
        if (window.confirm("Are you sure you want to upload to the DB?\n You are uploading what's in the editor, use update preview first to make sure it's correct.")) {
            const { themeEditor, altStylesEditor, templateEditor, selectedFormat, selectedStyle, styleData, sectionTypesEditor } = this.state;

            //Update state
            var [selectedFormatObj] = styleData.filter((s: any) => s.id === selectedStyle)[0].formats.filter((f: any) => f.id === selectedFormat);

            selectedFormatObj.theme = themeEditor;
            selectedFormatObj.altStyles = altStylesEditor;
            selectedFormatObj.templateData = templateEditor;
            selectedFormatObj.sectionTypes = sectionTypesEditor;

            this.setState({ styleData });

            fetch("/api/Creator", {
                method: "POST",
                body: JSON.stringify({
                    formatStyleId: selectedFormat,
                    altStyles: altStylesEditor,
                    theme: themeEditor,
                    template: templateEditor,
                    sectionTypes: sectionTypesEditor
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            }).then(response => {
                if (response.status === 200)
                    if (response.json())
                        return true;
                    else
                        throw Error();
                else
                    throw Error();
            }).then(response => {
                alert("Saved!")
            }).catch(() => {
                alert("Save failed!")
            });
        }
    }

    onListComponents = () => {
        this.setState({ showComponents: !this.state.showComponents });
    }

    onForceExporting = () => {
        if (!this.state.isExporting)
            (window as any).ForceExporting = true;
        else
            delete (window as any).ForceExporting;

        this.forceUpdate();
        this.setState({ isExporting: !this.state.isExporting });
    }

    oversize = (amount: number, page: number) => {
        //Set initially
        if (this.state.pageOversized === -1 && amount > 0) {
            this.setState({ pageOversized: page, oversizedPixels: amount });
            return;
        }

        if (this.state.pageOversized === page) {
            //Update pixels
            if (amount !== this.state.oversizedPixels)
                this.setState({ pageOversized: page, oversizedPixels: amount });

            //Remove
            if (amount <= 0)
                this.setState({ pageOversized: -1, oversizedPixels: amount });
        }
    }

    render() {
        const { closeModal, modalData, showModal } = this.props;
        const { theme, width, height, altStyles, templateData, styleData, formatData, selectedStyle, selectedFormat, selectedPage, zoom, showEdit, themeEditor, altStylesEditor, templateEditor, expandType, selectedAltStyle, showComponents, isExporting, sectionTypesEditor, sectionTypesData } = this.state;

        if(!styleData)
            return <Grid container justify={"center"} style={{ padding: "60px 0" }}>
                <Grid item>
                    <CircularProgress style={{ color: "#fff" }} />
                </Grid>
            </Grid>

        return <Grid container spacing={2}>
            {!theme && <Grid item xs={3}>
                <Grid container spacing={4}>
                    <Grid item style={{ paddingBottom: 0, color: "#FFF" }}><Typography variant="h3">Styles</Typography></Grid>

                    {styleData.map((style: any) => (
                        <Grid item xs={12} key={style.id}>
                            <Paper onClick={() => this.onStyleSelect(style)} style={{ cursor: "pointer", border: selectedStyle === style.id ? "2px solid black" : "", padding: 20 }}>
                                <Typography variant="h3">{style.title}</Typography>
                                <img width="100%" src={"/assets/styles/" + style.imgUrl} />
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Grid>}

            {formatData && <Grid item xs={showEdit && theme ? 1 : 3}>
                <Grid container spacing={4}>
                    <Grid item style={{ paddingBottom: 0, color: "#FFF" }}><Typography variant="h3">Formats</Typography></Grid>

                    {formatData.map((style: any) => (
                        <Grid item xs={12} key={style.id}>
                            <Paper onClick={() => this.onFormatSelect(style)} style={{ cursor: "pointer", border: selectedFormat === style.id ? "2px solid black" : "", padding: 20 }}>
                                <Typography variant="h3">{style.title}</Typography>
                                <img width="100%" src={"/assets/styles/preview/" + style.imgUrl} />
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Grid>}

            <Grid item xs>
                {theme && <Paper square style={{ background: "#E8EBED", padding: 10, marginBottom: 20 }}>
                    <Grid container alignItems="center" justify="space-between" spacing={2}>
                        <Grid item style={{ marginTop: -10 }}>
                            <Pages onPageSelected={this.onSelectPage} pages={templateData?.length ?? 1} currentPage={selectedPage} />
                        </Grid>

                        <Grid item>
                            <Zoom
                                onMove={this.handleZoom}
                                showReset={false}
                                onReset={() => { }}
                                defaultZoom={initialZoom}
                                maxZoom={initialZoom + 0.5}
                                minZoom={initialZoom - 0.5} />
                        </Grid>

                        <Grid item>
                            <Grid container alignItems="center" spacing={2}>
                                {altStyles && altStyles.map((style, index) => (
                                    <Grid key={index} item style={{ border: index === selectedAltStyle ? "2px solid black" : "" }} onClick={() => this.onSelect(index)}>
                                        {style.palette.map(c => <div style={{ backgroundColor: c, width: 30, height: 30 }}></div>)}
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>

                        <Grid item>
                            <FormControlLabel control={<Checkbox checked={isExporting} onChange={this.onForceExporting} />} label="Force isExporting flag" />
                        </Grid>

                        <Grid item>
                            <Button variant="contained" onClick={this.onListComponents}>Components</Button>
                        </Grid>

                        <Grid item>
                            <Button variant="contained" onClick={this.onEdit}>Edit</Button>
                        </Grid>
                    </Grid>
                </Paper>}

                {showComponents && <Paper square style={{ background: "#E8EBED", padding: 10, marginBottom: 20 }}>
                    <Grid container justify="space-between" alignItems="center" spacing={1}>
                        {Object.keys(MenuComponents).map(c => (
                            <Grid item>{c}</Grid>
                        ))}
                    </Grid>
                </Paper>}

                {showEdit && theme && <Paper square style={{ padding: "20px 20px 15px 20px", marginBottom: 20 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={expandType === "" ? true : expandType === "theme" ? 9 : 1}>
                            <Typography style={{ whiteSpace: "nowrap" }} variant="h3">Theme <span style={{ verticalAlign: "middle" }} onClick={() => this.expand("theme")}>{expandType === "theme" ? <ChevronLeft /> : <ChevronRight />}</span></Typography>

                            <EditBox rowsMax={expandType !== "" && expandType !== "theme" ? 5 : 25} initialValue={themeEditor} save={(val: any) => this.setState({ themeEditor: val })} />
                        </Grid>

                        <Grid item xs={expandType === "" ? true : expandType === "alt" ? 9 : 1}>
                            <Typography style={{ whiteSpace: "nowrap" }} variant="h3">Alt Styles <span style={{ verticalAlign: "middle" }} onClick={() => this.expand("alt")}>{expandType === "alt" ? <ChevronLeft /> : <ChevronRight />}</span></Typography>

                            <EditBox rowsMax={expandType !== "" && expandType !== "alt" ? 5 : 25} initialValue={altStylesEditor} save={(val: any) => this.setState({ altStylesEditor: val })} />
                        </Grid>

                        <Grid item xs={expandType === "" ? true : expandType === "temp" ? 9 : 1}>
                            <Typography style={{ whiteSpace: "nowrap" }} variant="h3">Template <span style={{ verticalAlign: "middle" }} onClick={() => this.expand("temp")}>{expandType === "temp" ? <ChevronLeft /> : <ChevronRight />}</span></Typography>

                            <EditBox rowsMax={expandType !== "" && expandType !== "temp" ? 5 : 25} initialValue={templateEditor} save={(val: any) => this.setState({ templateEditor: val })} />
                        </Grid>

                        <Grid item xs={expandType === "" ? true : expandType === "section" ? 9 : 1}>
                            <Typography style={{ whiteSpace: "nowrap" }} variant="h3">Section Types <span style={{ verticalAlign: "middle" }} onClick={() => this.expand("section")}>{expandType === "section" ? <ChevronLeft /> : <ChevronRight />}</span></Typography>

                            <EditBox rowsMax={expandType !== "" && expandType !== "section" ? 5 : 25} initialValue={sectionTypesEditor} save={(val: any) => this.setState({ sectionTypesEditor: val })} />
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container justify="flex-end">
                                <Button onClick={this.updatePreview} variant="contained">Update Preview</Button>
                                <Button style={{ marginLeft: 10 }} onClick={this.save} variant="contained">Save to DB</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper> }

                <div style={{ background: "#E8EBED", overflow: "hidden", position: "relative" }}>
                    {isExporting && <div style={{ position: "absolute", top: 10, left: 10, fontWeight: "bold" }}>Exporting</div>}
                    {theme && <Grid container style={{ minHeight: 950 }} alignItems={"center"} justify={"center"}>
                        <Grid item>
                            <ThemeProvider theme={theme}>
                                <CssBaseline />

                                <div style={{ height: height, transform: `scale(${zoom})` }}>
                                    {templateData?.map((page, index: number) => (
                                        <MenuAnimator
                                            size={{ width, height }}
                                            key={index}
                                            theme={theme}
                                            index={index}
                                            currentPage={selectedPage}
                                            oversized={this.oversize}
                                            data={page}
                                            border={this.state.pageOversized == index ? `10px solid red` : undefined}
                                        />
                                    ))}
                                </div>
                            </ThemeProvider>
                        </Grid>
                    </Grid>}
                </div>

                <EditableModal
                    open={showModal}
                    onClose={closeModal}
                    data={modalData} />
            </Grid>
        </Grid>
    }
}

const mapStateToProps = (state: AppState) => ({
    showModal: state.menu.showModal,
    modalData: state.menu.modalData
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        closeModal: () => dispatch(menuActions.closeModal())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuCreator);
import React, { Component, createRef } from "react";
import { Grid, Theme, withStyles, WithStyles, RadioGroup, Radio, Button, Link, CircularProgress, TextField, ThemeProvider, CssBaseline, createTheme } from "@material-ui/core";
import { RouterProps, withRouter } from "react-router-dom";
import clsx from 'clsx';
// Store
import { connect } from "react-redux";
import { AppState } from "../store";
import { IMyMenuState, myMenuActions } from "../store/myMenu";
import { IPrintPalState, printPalOperations, printPalActions } from "../store/printpal";
import { globalActions } from "../store/global";
import { IMenuItem, IMenuState } from "../store/menu";
import MenuAnimator from "../components/decorators/MenuAnimator";
import useGeneratePdf, { GenerateFormatPreviewRequest } from "../utils/useGeneratePdf";
import { placeOrderStyles } from "../styles";

const styles = placeOrderStyles;

interface IProps {
    myMenu: IMyMenuState;
    printPal: IPrintPalState;
    showNavigation: any;
    printPalPage: any;
    getPaperOptions: any;
    menu: IMenuState;
    getConfig: any;
    checkout: any;
    checkVoucher: any;
    config: any;
}

interface IPlaceOrderState {
    price: string;
    paper: number;
    pageIndex: number;
    option: string;
    isMobileApp: boolean;
    pdfPreview: any;
    qty: number;
    voucherNumber: string;
    voucherError?: string;
    priceAlteration: number;
    voucherAmount: number;
    heroZoom: number;
    isExporting: boolean;
    mealDealMessaging: boolean;
}

export interface TemplateFormat {
    format: string,
    size: TemplateSize
}

interface IExportPdfHOC {
    createMenuPreviewTemplate: (format?: TemplateFormat) => Promise<true | false | void | undefined>;
    getTemplate: (format?: TemplateFormat) => GenerateFormatPreviewRequest | null;

}

export const withExportPdfHOC = (Component: any) => {
    return (props: any) => {
        const { createMenuPreviewTemplate, getTemplate } = useGeneratePdf();
        return <Component createMenuPreviewTemplate={createMenuPreviewTemplate} getTemplate={getTemplate} {...props} />;
    };
};

class PlaceOrder extends Component<WithStyles & IProps & RouterProps & IExportPdfHOC, IPlaceOrderState> {
    private formatId: number = Number(sessionStorage.getItem('formatId')) || 0;
    private order: any;
    private preview: any;
    private mounted: boolean = false;
    private heroRef: any;
    private isBIM: boolean;

    constructor(props: any) {
        super(props);

        var prevOrderSettings = null;
        var orderJson = sessionStorage.getItem('order');
        const { global: { session: { mealDealMessaging } } } = props;

        var defaultState = {
            price: "0.00",
            qty: 50,
            paper: 3,
            option: "",
            pdfPreview: undefined,
            voucherNumber: "",
        }

        if (orderJson) {
            prevOrderSettings = JSON.parse(orderJson);
            defaultState.price = prevOrderSettings.price;
            defaultState.qty = prevOrderSettings.qty;
            defaultState.paper = prevOrderSettings.paperId;
            defaultState.option = prevOrderSettings.option;
            defaultState.pdfPreview = prevOrderSettings.pdfPreview;
            defaultState.voucherNumber = prevOrderSettings.voucherCode;
        }

        this.state = {
            price: defaultState.price,
            qty: defaultState.qty,
            paper: defaultState.paper,
            pageIndex: 0,
            option: defaultState.option,
            isMobileApp: false,
            pdfPreview: defaultState.pdfPreview,
            voucherNumber: defaultState.voucherNumber,
            voucherError: undefined,
            priceAlteration: 0,
            voucherAmount: 0,
            heroZoom: 0,
            isExporting: false,
            mealDealMessaging: mealDealMessaging
        };

        this.heroRef = createRef();
        this.props.printPalPage(true);
        this.props.showNavigation(false);
        this.props.checkout(false);

        let bimStyleNames = ["BIM", "BIM 2"];
        this.isBIM = bimStyleNames.includes(props.menu?.menuData?.style);
        if (this.isBIM) {
            this.props.checkVoucher("BIMFREE", this.props.menu.menuData?.styleId).then((result: VoucherValidResponse) => {
                if (result.ok == true) {
                    this.setState({
                        voucherAmount: result.amount ?? 0,
                        voucherNumber: "BIMFREE"
                    })
                }
                else {
                    console.log(result.message);
                }

                this.calcPrice(this.state.qty);
                console.log("PlaceOrder state", this.state);
            });
        }
        else {
            console.log("PlaceOrder state", this.state);
        }
    }

    componentDidMount() {
        this.getData();
        this.mounted = true;
        this.setResponsiveness();
        window.addEventListener("resize", () => this.setResponsiveness());
        (window as any).ForceExporting = true;
        this.calcPrice(this.state.qty);
    }

    componentWillUnmount() {
        this.mounted = false;
        window.removeEventListener('resize', () => this.setResponsiveness());
        (window as any).ForceExporting = false;
    }

    onRefChange = (node: any) => {
        this.heroRef = node;
        this.calcZoom();
    }

    calcZoom() {
        if (this.heroRef && this.preview) {
            this.setState({
                heroZoom: Math.min(this.heroRef.clientWidth / this.preview.width, 1.0)
            });
        }
    }

    setResponsiveness = () => {
        if (this.mounted) {
            this.calcZoom();

            return window.innerWidth < 600
                ? this.setState((prevState) => ({ ...prevState, isMobileApp: true }))
                : this.setState((prevState) => ({ ...prevState, isMobileApp: false }));
        }
    };

    async getData() {
        await this.props.getPaperOptions(this.formatId);
        await this.props.getConfig();
        this.calcPrice(this.state.qty);
        this.addMenuToSessionStorage();
    }

    handleQuantitySelected = (event: any) => {
        var qty: number = Number(event.currentTarget.value);

        this.setState({ qty }, () => {
            this.calcPrice(qty);
        });
    }

    handlePaperSelected = (event: any) => {
        this.setState({ paper: Number(event.currentTarget.value) }, () => {
            this.calcPrice(this.state.qty);
        });
    }

    handleNextPage = () => {
        var pageIndex = this.state.pageIndex;
        if (this.state.pageIndex + 1 < (this.preview.menu?.length ?? 0))
            this.setState({ pageIndex: pageIndex + 1 });
    }

    handlePrevPage = () => {
        var pageIndex = this.state.pageIndex;
        if (this.state.pageIndex > 0)
            this.setState({ pageIndex: pageIndex - 1 });
    }

    handlePlaceOrder = () => {
        const { printPal: { paperData, config } } = this.props;
        const { voucherNumber, voucherError, qty, price, option } = this.state;

        const setOrder = () => {
            var paper = paperData.filter((p: any) => p.id === this.state.paper)[0];
            var templates = [this.props.getTemplate()];
            this.order = {
                menuId: this.preview?.menuId || 0,
                title: this.preview?.title || "",
                qty: qty,
                delCharge: config?.carriage,
                vat: config?.vatPercent,
                price: Number(price),
                paperId: paper.id,
                paperDesc: paper.name,
                option: option,
                voucherCode: voucherNumber,
                templates: templates,
                isMenu: true
            };

            console.log("sessionStorage.order being set from this.preview", this.order, this.preview);
            sessionStorage.setItem('order', JSON.stringify(this.order));
            this.props.history.push("order-payment");
        };

        //Check voucher before continuing
        if (voucherNumber && !voucherError) {
            this.props.checkVoucher(voucherNumber, this.props.menu.menuData?.styleId).then((result: VoucherValidResponse) => {
                if (result.ok == false) {
                    console.log("Voucher Invalid: ", result.message);
                    this.setState({
                        voucherError: result.message
                    })
                } else {
                    this.setState({
                        voucherAmount: result.amount ?? 0
                    })

                    setOrder();
                }

                /*
                if (this.isBIM ? true : (this.props.menu.menuData?.britvicProducts ?? 0) < 10)
                    this.setState({ voucherError: "Sorry, you need at least 10 Britvic products for the voucher to apply" });
                */
            });
        } else
            setOrder();
    }

    addMenuToSessionStorage() {
        const { menu: { menuData, theme, menu } } = this.props;
        if (menuData) {
            this.preview = {
                menuId: menuData?.id,
                title: menuData?.title,
                britvicProducts: menuData?.britvicProducts || 0,
                pdfPreview: menuData?.pdfPreview || 0,
                previews: menuData?.previews || [],
                theme: theme,
                menu: menu,
                width: menuData.width,
                height: menuData.height
            };
            console.log("this.preview being set in PlaceOrder.tsx", this.preview);
            sessionStorage.setItem('preview', JSON.stringify(this.preview));
        }
        else {
            this.preview = JSON.parse(sessionStorage.getItem('preview') || "");
        }

        this.preview.theme = createTheme(this.preview.theme);
    }

    calcPrice = (qty: number) => {
        const { printPal: { paperData }, menu: { menuData } } = this.props;
        const { voucherNumber, voucherError, voucherAmount, heroZoom } = this.state;

        var paper = paperData.filter((paper: any) => paper.id === this.state.paper)[0];

        if (!paper)
            return;

        var option = paper.paperOptions.filter((option: any) => Number(option.quantityFrom) === qty)[0];

        var price = option.price + paper.additionalCost;

        //Voucher takes first option off but only if paper is free
        var free = voucherNumber !== "" && !voucherError && (this.isBIM ? true : (menuData?.britvicProducts ?? 0) > 9);
        var reduction = 0;

        if (free) {
            var [reducedPaper] = paper.paperOptions.filter((a: any) => Number(a.quantityFrom) === voucherAmount);

            if (reducedPaper)
                reduction = reducedPaper.price;
        }

        this.setState({
            price: (price - reduction).toFixed(2),
            option: option.name,
            priceAlteration: -reduction + paper.additionalCost
        });
    }

    handleVoucher = (ev: any) => {
        this.setState({
            voucherNumber: ev.target.value,
            voucherError: ev.target.value.length > 7 ? "Invalid voucher entered" : undefined,
            voucherAmount: 50//need to pull this from backend
        }, () => this.calcPrice(this.state.qty));
    }

    handleExport = () => {
        this.setState({ isExporting: true });
        fetch(`/api/Export?menuId=${this.preview?.menuId}&previewName=${PreviewName.PDFPreview}`)
            .then((res: Response) => {
                return res.blob().then((blob: Blob) => {
                    var header = res.headers.get("content-disposition");
                    if (header) {
                        var filename = (header.match(/filename=(.*);/) ?? [])[1];
                        return { filename, blob };
                    }
                });
            })
            .then((data: { filename: string, blob: Blob } | undefined) => {
                if (data) {
                    var a = document.createElement("a");
                    a.href = URL.createObjectURL(data.blob);
                    a.setAttribute("download", data.filename);
                    a.click();
                }
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                this.setState({ isExporting: false });
            });
    }

    render() {
        const { classes, printPal: { paperData, loading }, menu: { menuData }, config } = this.props;
        const { voucherError, voucherNumber, pageIndex, qty, heroZoom, mealDealMessaging } = this.state;
        let showPreviews = true;
        if (menuData == null) {
            this.props.history.push("/");
        }
        let minBritvicProducts = (menuData?.minBritvicProducts)??0;
        let enoughBritvicProducts = (menuData?.britvicProducts ?? 0) >= minBritvicProducts;
        const { user: { permittedToPlaceMoreOrders, lastOrderPlaced, placeAgainIn } } = this.props.global;

        if (loading)
            return <Grid container justify="center">
                <div style={{ display: "flex", marginTop: 100, marginBottom: 100 }}>
                    <CircularProgress color="secondary" />
                </div>
            </Grid>

        return <div className={classes.screen}>
            <Grid container direction={this.state.isMobileApp ? "column" : "row"}>
                <Grid item xs={this.state.isMobileApp ? 12 : menuData?.formatId === 5 ? 7 : 5}>
                    <div>
                        <Grid ref={this.onRefChange} container direction="column" alignItems="center">
                            <div className={classes.heroContainer}>
                                {this.preview && <ThemeProvider theme={this.preview?.theme}>
                                    <CssBaseline />

                                    <div style={{ pointerEvents: "none", left: (this.preview.width / 2.0), width: this.preview.width, height: this.preview.height, position: "relative", zoom: heroZoom }}>
                                        {showPreviews && this.preview?.menu?.map((page: IMenuItem, index: number) => (
                                            <MenuAnimator
                                                size={{ width: this.preview.width, height: this.preview.height }}
                                                key={index}
                                                theme={this.preview.theme}
                                                index={index}
                                                currentPage={pageIndex}
                                                data={page}
                                            />
                                        ))}
                                    </div>
                                </ThemeProvider>}
                            </div>
                        </Grid>
                        <Grid container direction="column" alignItems="center">
                            <div>
                                <img src="../assets/printPal/orange_arrow.svg" alt="arrow left" style={{ transform: "rotate(-90deg)", cursor: "pointer" }} onClick={this.handlePrevPage} className={classes.arrow} />
                                <span className={classes.orangeText}>
                                    <strong>Page {pageIndex + 1}</strong> of {this.preview?.menu && this.preview.menu.length}
                                </span>

                                <img src="../assets/printPal/orange_arrow.svg" alt="arrow left" style={{ transform: "rotate(90deg)", cursor: "pointer" }} onClick={this.handleNextPage} className={classes.arrow} />
                            </div>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={this.state.isMobileApp ? 12 : menuData?.formatId === 5 ? 5 : 7}>
                    <div className={classes.content}>
                        <div className={classes.header}>{this.preview?.title || "MyMenu"}</div>
                        <div style={{ display: !mealDealMessaging ? "block" : "none" }}>
                            <div className={classes.normal} style={{ marginTop: 15 }}>Make sure you have fully proof-read your design.</div>
                            {minBritvicProducts > 0 && <div className={classes.normal} style={{ marginTop: 15 }}>Your menu must include at least {minBritvicProducts} Britvic product{minBritvicProducts == 1 ? "" : "s"}.</div>}
                            {!enoughBritvicProducts &&
                                <div className={classes.redBox} style={{ marginTop: 20 }}>
                                    <span className={classes.bold} style={{ verticalAlign: "middle" }}>Your menu does not include at least {minBritvicProducts } Britvic products. Return to the Menu Builder and add additional Britvic products.</span>
                                </div>
                            }
                            {!permittedToPlaceMoreOrders &&
                                <div className={classes.redBox} style={{ marginTop: 20 }}>
                                    <span className={classes.bold} style={{ verticalAlign: "middle" }}>Sorry.. This free service can only be used once a month.  You can place another order in {placeAgainIn} day{placeAgainIn == 1 ? '' : 's'}. </span>
                                </div>
                            }
                            {enoughBritvicProducts && minBritvicProducts >0 && 
                                <div className={classes.box} style={{ marginTop: 20 }}>
                                    <img src={`./assets/printPal/tick.png`} alt="tick" width="24px" height="24px" className={classes.imgTextContainer} />
                                    <span className={classes.bold} style={{ paddingLeft: 12, verticalAlign: "middle" }}>Your menu includes {menuData.britvicProducts} Britvic products</span>
                                </div>
                            }
                            <div className={classes.subHeader} style={{ marginTop: 20, marginBottom: 10 }}>Your paper stock</div>
                            <div className={classes.normal} style={{ marginTop: 15 }}>Standard satin (250gm)</div>
                            <div className={classes.subHeader} style={{ marginTop: 20, marginBottom: 10 }}>Place your order to receive 50 free menus</div>
                            <div className={classes.normal} style={{ marginTop: 15 }}>There is a minimum 5 working days lead time on all prints.</div>
                            <div className={classes.normal} style={{ marginTop: 45 }}><b>Total </b>£0.00 (inc.VAT) & FREE delivery</div>
                        </div>
                        <div style={{ display: mealDealMessaging ? "block" : "none" }}>
                            <div className={classes.normal} style={{ marginTop: 15 }}>Make sure you have fully proof-read your design.</div>
                            {(menuData && menuData.britvicProducts > 0 && !this.isBIM) && <div className={classes.box} style={{ marginTop: 30 }}>
                                <img src={`./assets/printPal/tick.png`} alt="tick" width="24px" height="24px" className={classes.imgTextContainer} />
                                <span className={classes.bold} style={{ paddingLeft: 12, verticalAlign: "middle" }}>Your menu includes {menuData.britvicProducts} Britvic products</span>
                            </div>}
                            <div style={{ display: "none" }}>
                                {
                                    !this.isBIM &&
                                    <div className={classes.normal} style={{ marginTop: 15 }}>If you menu contains 10 or more Britvic products and you have a voucher code, you are entitled to 50 FREE menus printed on Standard gloss or matte (200gsm).</div>
                                }
                                <div className={classes.normal} style={{ marginTop: 15 }}>Orders for more than 50 menus or using different card stock will be charged as shown.</div>
                                {!this.isBIM &&
                                    <>
                                        <div className={classes.normal} style={{ marginTop: 15, marginBottom: 10 }}>If you have one, please enter your voucher code in the box below.</div>
                                        <Grid container alignItems="center" spacing={2}>
                                            <Grid item xs={3}>
                                                <TextField className={classes.voucher} value={voucherNumber} margin="dense" fullWidth variant="outlined" error={voucherError !== undefined} onChange={this.handleVoucher} />
                                            </Grid>
                                            <Grid item>
                                                for 50 free menus
                                            </Grid>
                                            <Grid item style={{ marginTop: -15 }} xs={12}>
                                                {voucherError && <span style={{ color: "red", fontSize: 14 }}>{voucherError}</span>}
                                            </Grid>
                                        </Grid>
                                    </>
                                }
                            </div>
                            <div className={classes.subHeader} style={{ marginTop: 20, marginBottom: 10 }}>Choose your paper stock</div>
                            <RadioGroup
                                defaultValue={this.state.paper.toString()}
                                style={{ paddingBottom: 20 }}>
                                {paperData.map(paper => (
                                    <Grid container justify="space-between" key={paper.id}>
                                        <Grid item className={classes.radioContainer}>
                                            <Radio
                                                key={paper.id}
                                                onChange={this.handlePaperSelected}
                                                disableRipple
                                                color="default"
                                                checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                                                icon={<span className={classes.icon} />}
                                                value={paper.id.toString()}
                                                size="small" /><span className={classes.normal} style={{ paddingLeft: 10 }}>{paper.name}</span>
                                        </Grid>
                                    </Grid>
                                ))}
                            </RadioGroup>
                            <div className={classes.subHeader} style={{ marginTop: 5, marginBottom: 10 }}>Choose your quantity</div>
                            <Grid container className={`${classes.bold} ${classes.prices}`}>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={this.state.isMobileApp ? 2 : 3}>Qty</Grid>
                                <Grid item>Total price</Grid>
                            </Grid>
                            <RadioGroup
                                value={qty}
                                defaultValue={this.state.qty}>
                                {paperData.filter((paper: any) => paper.id === this.state.paper)[0] && paperData.filter((paper: any) => paper.id === this.state.paper)[0].paperOptions.map((option: any, index: number) => {
                                    var price = option.price;
                                    price += this.state.priceAlteration;
                                    return <Grid container
                                        className={classes.prices}
                                        spacing={0}
                                        key={option.id}
                                        style={index % 2 ? { background: "rgba(242, 242, 242, 1)" } : { background: "#FFF" }}>
                                        <Grid item xs={1} className={classes.bold} style={{ paddingLeft: -10, marginTop: -10 }}>
                                            <Grid item className={classes.radioContainer}>
                                                <Radio
                                                    key={option.id}
                                                    onChange={this.handleQuantitySelected}
                                                    disableRipple
                                                    color="default"
                                                    checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                                                    icon={<span className={classes.icon} />}
                                                    value={option.quantityFrom}
                                                    size="small" />
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={this.state.isMobileApp ? 2 : 3} className={classes.normal}>{option.description}</Grid>
                                        <Grid item className={classes.normal}>{price === 0 ? "FREE" : new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP" }).format(price)}</Grid>
                                    </Grid>
                                })}
                            </RadioGroup>
                            <Grid container>
                                <Grid container item>
                                    <div style={{ display: "flex", paddingTop: 10 }}>
                                        <span className={classes.totalContainer}>
                                            <span className={classes.bold}>Total</span>
                                            <span className={classes.normal}>&nbsp;{new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP" }).format(Number(this.state.price)) + " (inc. VAT)"} & {config?.carriage > 0 ? "" : "FREE "}DPD delivery</span>
                                        </span>
                                    </div>
                                </Grid>
                                {config?.carriage > 0 && <Grid container item>
                                    <div style={{ paddingLeft: 0, marginTop: 10, marginBottom: 10 }}>
                                        <span className={classes.bigBold}>{new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP" }).format(config?.carriage)}</span><span className={classes.normal}> carriage charge per address</span>
                                    </div>
                                </Grid>}
                            </Grid>
                        </div>
                        <Button
                            disabled={!!voucherError || !enoughBritvicProducts || !permittedToPlaceMoreOrders}
                            onClick={this.handlePlaceOrder}
                            className={classes.placeOrder}>
                            CONTINUE
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </div>
    }
}

const mapStateToProps = (state: AppState) => ({
    myMenu: state.myMenu,
    printPal: state.printPal,
    global: state.global,
    menu: state.menu
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        showNavigation: (showNavigation: boolean) => dispatch(globalActions.showNavigation(showNavigation)),
        printPalPage: (printPalPage: boolean) => dispatch(myMenuActions.printPalPage(printPalPage)),
        getPaperOptions: (formatId: number) => dispatch(printPalOperations.getPaperPriceOptions(formatId)),
        getConfig: () => dispatch(printPalOperations.getConfig()),
        checkout: (checkout: boolean) => dispatch(printPalActions.checkout(checkout)),
        checkVoucher: (code: string, styleId?: number) => dispatch(printPalOperations.checkVoucher(code, styleId))
    }
}

export default withRouter(withStyles(styles as any)(connect(mapStateToProps, mapDispatchToProps)(withExportPdfHOC(PlaceOrder))));
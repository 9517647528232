import React from "react";
import "./posButton.css";

function PosButton(props: PosButtonProps) {

    return <button
        className={`PosButton ${props.isSecondary ? "secondary" : ""}`}
        style={props.style}
        disabled={props.isDisabled}
        type={props.type}
        onClick={() => !props.isDisabled ? props.onClick() : null}>
        {props.text}
    </button>
}

interface PosButtonProps {
    isSecondary: boolean;
    onClick: () => void;
    text: string;
    type: "button" | "submit" | "reset"
    isDisabled: boolean;
    style: React.CSSProperties;

}

PosButton.defaultProps = {
    isSecondary: false,
    onClick: () => { },
    type: "button",
    isDisabled: false,
    style: {}
}

export default PosButton;
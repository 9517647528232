import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import CustomerDetails from "../../components/POS/customer/CustomerDetails";
import OrderDetails from "../../components/POS/customer/OrderDetails";
import LoadingSpinner from "../../components/POS/LoadingSpinner";
import Table from "../../components/POS/table/Table";
import { OrderDTO, OrderTableRow, cleanDate, formatCost } from "./OrdersPage";
import { useHistory } from "react-router-dom";
import { FilterOption } from "../../components/POS/Filters";
import { ISession } from "../../store/global";
import { AppState } from "../../store";
import { connect } from "react-redux";

function CustomerPage(props: { "orderStates": FilterOption[], session: ISession | undefined }) {

    const { customerId, orderId } = useParams<CustomerPageParameters>(); // Typescript doesn't like the use of useParams

    const history = useHistory();


    const [customerDetails, setCustomerDetails] = useState<Customer | undefined>();
    const [selectedRow, setSelectedRow] = useState(-1);

    const orderDetailsSection = useRef<HTMLHeadingElement | null>(null);

    const scrollOrderDetails = () => window.scrollTo({ top: orderDetailsSection.current !== null ? orderDetailsSection.current.offsetTop : 0, behavior: "smooth" });

    const [ isSavingDetails, setIsSavingDetails ] = useState(false);
    const [isSavingOrders, setIsSavingOrders] = useState(false);

    const [lastUpdated, setLastUpdated] = useState(0);

    const mealDealMessaging = props.session?.mealDealMessaging;


    useEffect(() => {

        fetch(`${process.env.PUBLIC_URL}/api/customer/details?customerId=` + customerId).then(async (response) => {


            const jsonResponse: Customer = await response.json();


            setCustomerDetails(jsonResponse);

            if (jsonResponse.orders === undefined)
                jsonResponse.orders = []


            const cleanedData: CustomerOrderTableRow[] = jsonResponse.orders.map((order: OrderDTO) => {
                return {
                    ...order,
                    status: order.status,
                    orderDateClean: cleanDate(order.orderDate.toString()),
                    icon: null,
                    hasPdf: null,
                    costClean: formatCost(order.cost),
                    shippingDateClean: order.shippingDate === null || order.shippingDate === undefined ? '' : cleanDate(order.shippingDate.toString()),
                    humanOrderStatus: props.orderStates?.find(state => state.value === order.status)?.text,
                    boldOrder: null,
                    nameAddress: ''
                    
                }
            });


            setOrderHistory(cleanedData);



            if (orderId !== undefined && lastUpdated === 0) {

                const findUrlOrder = jsonResponse.orders.find((order: OrderDTO) => order.id.toString() === orderId);

                if (findUrlOrder === undefined) {
                    setIsBadOrderId(true);
                } else {
                    setSelectedOrder(findUrlOrder);
                    setSelectedRow(jsonResponse.orders.findIndex((order: OrderDTO) => order.id.toString() === orderId));
                }

                scrollOrderDetails();


            }
        })

    }, [lastUpdated])

    const saveDetailsChanges = (data) => {

        data.orders = undefined;

        data.billingAddress = {
            address1: data.addressLine1,
            address2 :data.addressLine2,
            address3: data.addressLine3,
            firstName: data.firstName,
            lastName: data.lastName,
            company: data.company,
            mobile: data.mobile,
            city: data.city,
            county: data.county ?? '',
            country: data.country ?? '',
            postcode: data.postcode,
            telephone: data.telephone,
            addressId: data.billingAddress.addressId,
            title: data.title
        }

        if (customerDetails === undefined) {
            throw 'Customer details not yet set.'
        }

        setCustomerDetails({ ...customerDetails, billingAddress: data.billingAddress });


        setIsSavingDetails(true);
        setIsSavingOrders(true);   

        //setSelectedOrder(undefined);

        fetch(`${process.env.PUBLIC_URL}/api/customer/`, {
            method: 'put',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },

            body: JSON.stringify(data)
        }).then(async (result) => {
            setIsSavingOrders(false);
            setLastUpdated(lastUpdated + 1);
            //history.replace(`/pos/customers/${customerId}`);
            setIsSavingDetails(false);

        })

    }

    const saveOrderChanges = (data) => {

        setIsSavingOrders(true);        

        console.log(data);

        data = {
            ...data,
            shippingDate: data.shippingDate === '' ? null : new Date(data.shippingDate).toISOString().split('Z')[0],
            orderStatus: props.orderStates?.find(state => state.value === data.status.toString())?.text,
            shippingAddress: {
                ...data.shippingAddress,
                firstName: data.firstName,
                lastName: data.lastName,
                address1: data.address1,
                address2: data.address2,
                address3: data.address3,
                city: data.city,
                county: data.county,
                country: data.country,
                postcode: data.postcode,
                title: data.title,
                isBilling: data.isBilling,
                email: data.email,
                company: data.company,
                telephone: data.telephone
            },
        }

        console.log(data);

        fetch(`${process.env.PUBLIC_URL}/api/orders/`, {
            method: 'put',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },

            body: JSON.stringify(data)
        }).then(async (result) => {
            setIsSavingOrders(false);
            setLastUpdated(lastUpdated + 1);
        })

    }


    const [orderHistory, setOrderHistory] = useState<OrderDTO[] | undefined>();

    const [selectedOrder, setSelectedOrder] = useState<OrderDTO | undefined>();
    useEffect(() => {

        if (orderDetailsSection.current === null || selectedOrder === undefined)
            return;

        scrollOrderDetails();
    }, [selectedOrder])
    const [isBadOrderId, setIsBadOrderId] = useState<boolean>();
   
    return (
        <>
            <h1>Customer details & orders</h1>

            <hr className="line-divider" />
            <h2>{mealDealMessaging ? "Agent" : "Customer"} details</h2>

            {customerDetails === undefined ? <LoadingSpinner /> : <CustomerDetails isPending={isSavingDetails} customerDetails={customerDetails} save={saveDetailsChanges} />}
            <hr className="line-divider"/>
            <h2>Order History</h2>

            {orderHistory === undefined || isSavingOrders ? <LoadingSpinner /> :
            <Table
                    keys={["orderNumber", "orderDateClean", "title", "paperName", "quantity", "costClean", "humanOrderStatus", "voucheCode", "shippingDateClean", "trackingNumber", "orderDate", "shippingDate"]}
                    headings={["Order #", "Order date", "Title", "Stock", "Qty", "Price", "Status", "Voucher", "Shipping date", "Tracking #", "Order date raw", "Shipping date raw"]}
                    columnWidths={[0.7, 1, 1, 1.5, 0.5, 0.5, 0.7, 0.7, 1, 1, 0, 0]}

                    data={orderHistory}

                    showPagination={false}

                    onRowClick={(data, i) => { setSelectedOrder(data); console.log(i); setSelectedRow(i); history.replace({ pathname: `/pos/customers/${customerId}/order/${data.id}` }); setIsBadOrderId(false) }}

                    customSortValues={[
                        {
                            visibleColumn: "orderDateClean",
                            hiddenColumn: "orderDate"
                        },
                        {
                            visibleColumn: "shippingDateClean",
                            hiddenColumn: "shippingDate"
                        }
                    ]}

                    selectedRow={selectedRow}

                />
            }

            <br/>
            <hr className="line-divider" />
            <h2 ref={orderDetailsSection}>Order Details</h2>
            {isBadOrderId && <div style={{ color: "red", textAlign: "center", margin: 20, padding: 40, boxSizing: "border-box", backgroundColor: "rgba(255, 164, 11, 0.47)" }}>No order exists with that ID. Try another from the list.</div>}

            {orderId !== undefined && selectedOrder === undefined && !isBadOrderId && <LoadingSpinner/> }

            {selectedOrder !== undefined && <> <OrderDetails orderStates={props.orderStates} key={selectedOrder.id} isSaving={isSavingOrders} save={saveOrderChanges} onCancel={() => { setSelectedOrder(undefined); history.replace({ pathname: `/pos/customers/${customerId}` }); }} data={selectedOrder} /></>}
            {selectedOrder === undefined && orderId === undefined && <div style={{ textAlign: "center", margin: 20, padding: 40, boxSizing: "border-box" }}>No order selected. Pick one from the customer's history.<br/><br/></div> }
        </>
    )
}

interface CustomerPageParameters {
    customerId: string;
    orderId: string | undefined;
}

interface CustomerOrderTableRow extends OrderTableRow {

}

export interface ShippingAddress {
    addressId: number;
    address1: string;
    address2: string;
    address3: string;
    city: string;
    county: string;
    country: string;
    postcode: string;
    title: string;
    firstName: string;
    lastName: string;
    isPrimaryShipping: boolean;
    isBilling: boolean;
    email?: any;
}

export interface Role {
    role: string;
    weight: number;
    roleId: number;
}

export interface Customer {
    orders: OrderDTO[];
    billingAddress?: any;
    userId: number;
    email: string;
    role: Role;
    britvicUserId: string;
    company: string;
    displayName: string;
}


const mapStateToProps = (state: AppState) => ({
    session: state.global.session
});

export default connect(mapStateToProps)(CustomerPage);
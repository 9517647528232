import { Container, Grid, makeStyles, Theme, createStyles, Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, Redirect, Route, Switch, useRouteMatch, useLocation } from "react-router-dom";
import MonthlyReportsPage from "../../pages/Reporting/MonthlyReportsPage";
import LiveReportsPage from "../../pages/Reporting/LiveReportsPage";

import { AppState } from "../../store";
import CustomersPage from "../../pages/POS/CustomersPage";

import { FilterOption } from "../../components/POS/Filters";
import PosErrorBoundary from "../../pages/POS/PosErrorBoundary";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            color: "#FF9F1C",
            fontSize: 18,
            textAlign: "center",
            "& a": {
                color: "#FF9F1C",
                textDecoration: "none",
                "&:hover": {
                    color: "#FF9F1C",
                    textDecoration: "underline",
                    cursor: "pointer"
                }
            }
        },
        logo: {
            maxWidth: 450,
            width: "100%",
            height: "auto",
            [theme.breakpoints.down("sm")]: {
                width: 130
            },
        },
        checkout: {
            color: "#666666",
            fontSize: 32,
            textAlign: "left",
        },
        header: {
            height: 100,
            display: "flex",
            alignItems: "center",
            [theme.breakpoints.down("sm")]: {
                height: 65,
            },
        },
        headerContainer: {
            background: "#FFF",
            height: 100,
            paddingLeft: 40,
            borderBottom: "3px solid rgba(255, 159, 28, 1)",
            display: "flex",
            zIndex: 1,
            [theme.breakpoints.down("sm")]: {
                height: 65,
                paddingLeft: 0,
            },
        },
        drawerContainer: {
            padding: 0,
        },
        drawerPaper: {
            marginTop: 68,
            height: 150,
            width: 240,
            overflow: "auto"
        },
        iconButton: {
            display: "flex",
            flexDirection: "column"
        },
        menuItem: {
            color: "#000",
            fontSize: 12
        },
        orange: {
            color: "#FF9F1C",
        },
        dark: {
            color: "#666666",
        },
        link: {
            textDecoration: "none",
            color: "#666666",
        },
        headerSubtitle: {
            background: "#f2f2f2",
            padding: "15px 40px",
            display: "flex",
            justifyContent: "space-between"
        },
        navButton: {
            background: "#f59a23",
            color: "white",
            width: 140,
            height: 50,
            padding: 2,
            borderWidth: 3,
            borderStyle: "solid",
            fontWeight: "bold",
            fontFamily: "Segoe UI",
            fontSize: 16,
            cursor: "pointer"
        },
        navContainer: {
            margin: "2rem -16px",
            width: "fit-content"
        }
    }),
);

interface PosNavItem {
    title: string,
    path: string
}

const posNavItems: PosNavItem[] = [
    {
        title: "Monthly reports",
        path: "/monthly-reports"
    },
    {
        title: "Live reports",
        path: "/live-reports"
    }
]

const offlineStyles: React.CSSProperties = {
    position: "fixed",
    bottom: 0,
    right: 0,
    backgroundColor: '#f2f2f2',
    borderTop: '3px solid rgba(255, 159, 28, 1)',
    padding: 30,
    margin: 20,
    boxShadow: "5px 5px 5px 0px rgba(0, 0, 0, 0.35)",

}


const ReportingLayout: React.FC = () => {

    const { path } = useRouteMatch();
    const location = useLocation();

    const classes = useStyles();
    const globalState = useSelector((s: AppState) => s.global);

    console.log(globalState);
    return (
        <div>
            <div className={classes.headerContainer}>
                <Grid container alignItems="flex-start" justify="flex-start" spacing={1} className={classes.header}>
                    <Grid item xs={4}>
                        <img src="/assets/printPal/orange_logo.png" alt="ScreenPrint logo" className={classes.logo} />
                    </Grid>
                </Grid>
            </div>
            <div className={classes.headerSubtitle}>
                <div>Welcome <span style={{ fontWeight: "bold" }}>{globalState.user?.displayName}</span></div>
                {
                    globalState.user?.role?.role == "ADMIN" &&
                    <div>
                        <Link to="/">Return to design tool</Link>
                    </div>
                }
            </div>
            <Grid container spacing={4} className={classes.navContainer}>
                {
                    posNavItems.map((item) => {
                        return (
                            <Grid item key={item.path}><Link to={path + item.path}><button className={classes.navButton} style={{ borderColor: (location.pathname.startsWith(path + item.path) ? "#555555" : "#f59a23") }}>{item.title}</button></Link></Grid>
                        )
                    })
                }
            </Grid>

            <PosErrorBoundary>
                <Switch>
                    <Route path={`${path}/monthly-reports`}><MonthlyReportsPage /></Route>
                    <Route path={`${path}/live-reports`}><LiveReportsPage /></Route>
                </Switch>
            </PosErrorBoundary>

        </div>
    )
}

export default ReportingLayout;
import React from "react"
import { withStyles, WithStyles, Theme } from "@material-ui/core"; 

const styles = (theme: Theme) => ({
    wrapper: {
        marginTop: -15
    },
    label: {
        color: "#007D7A",
        fontSize: 14,
        textAlign: "center" as "center",
        marginBottom: 1,
        [theme.breakpoints.down("sm")]: {
            fontSize: 12,
            marginTop: 5
        },
    },
    page: {
        color: "#007D7A",
        border: "1px solid #007D7A",
        display: "inline-block",
        width: 40,
        height: 50,
        margin: "0 2px",
        fontSize: 20,
        textAlign: "center" as "center",
        lineHeight: "50px",
        cursor: "pointer",
        [theme.breakpoints.down("sm")]: {
            width: 23,
            height: 28,
            fontSize: 12,
            lineHeight: "28px",
        },
    },
    selectedPage: {
        backgroundColor: "#007D7A",
        color: "#FFF",
        width: 38,
        height: 48,
        margin: 2,
        lineHeight: "48px",
        [theme.breakpoints.down("sm")]: {
            width: 21,
            height: 26,
            lineHeight: "26px",
        },
    },
});

interface IPages {
    pages: number;
    currentPage: number;
    onPageSelected?: any;
}

class Pages extends React.Component<WithStyles & IPages> {
    handleSelectPage = (page: number) => {
        if (this.props.onPageSelected)
            this.props.onPageSelected(page);
    }

    render() {
        const { classes, pages, currentPage } = this.props;

        if (pages <= 1) {
            return <div className={classes.wrapper}></div>
        }
        
        return <div className={classes.wrapper}>
           <div className={classes.label}>Page</div>
            {Array.from(Array(pages).keys()).map(page => (
                <div key={page} onClick={() => this.handleSelectPage(page)} className={`${classes.page} ${currentPage === page ? classes.selectedPage : ''}`}>
                    {page + 1}
                </div>
            ))}
        </div>
    }
}

export default withStyles(styles)(Pages);
import React, { Component } from "react";
import { Button, Grid, InputLabel, Theme, Dialog, Typography, WithStyles, withStyles, TextField } from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";
import { withRouter, RouteComponentProps } from "react-router-dom";
import IMyMenu from "../../interfaces/IMyMenu";

const styles = (theme: Theme) => ({
    close: {
        position: "absolute" as "absolute",
        right: 0,
        top: 0,
        width: 52,
        height: 52,
        border: "2px solid #FFF",
        borderRadius: "50%",
        backgroundColor: "#007D7A",
        textAlign: "center" as "center",
        lineHeight: "52px",
        color: "#FFF",
        transform: "translate(50%,-50%)",
        cursor: "pointer",
        "& svg": {
            width: 32,
            height: "100%"
        },
        [theme.breakpoints.down("sm")]: {
            width: 32,
            height: 32,
            lineHeight: "32px",
        }
    },
    title: {
        marginBottom: 25,
        fontWeight: "bold" as "bold",
        fontSize: 20,
        [theme.breakpoints.down("sm")]: {
            marginBottom: 15,
            fontSize: 16,
        }
    },
    text: {
        marginBottom: theme.spacing(4),
        [theme.breakpoints.down("sm")]: {
            fontSize: 12,
            marginBottom: theme.spacing(2)
        }
    },
    cancel: {
        backgroundColor: "#FFF",
        color: "#007D7A",
        border: "1px solid #007D7A",
        "&:hover": {
            backgroundColor: "#007D7A",
            color: "#FFF"
        }
    },
    font: {
        fontSize: 16,
        [theme.breakpoints.down("sm")]: {
            fontSize: 12
        }
    }
});

interface IFileNamePopupProps {
    isPopupOpen: boolean;
    closePopup: any;
    createMenu: any;
}

interface IPopupState {
    errorMessage: string;
    isMobileApp: boolean;
}

class FileNamePopup extends Component<IFileNamePopupProps & RouteComponentProps & WithStyles, IPopupState> {
    titleRef: any;
    blankFileName: string = "A title must be entered";
    notEnoughChars: string = "A title must contain at least 6 characters";
    tooManyChars: string = "A title cannot contain more than 25 characters";
    mounted: boolean = false;

    constructor(props: any) {
        super(props);

        this.state = {
            errorMessage: "",
            isMobileApp: false
        };
    }

    componentDidMount() {
        this.mounted = true;
        this.setResponsiveness();
        window.addEventListener("resize", () => this.setResponsiveness());
    }

    componentWillUnmount() {
        this.mounted = false;
        window.removeEventListener('resize', () => this.setResponsiveness());
    }

    setResponsiveness = () => {
        if (this.mounted)
            return window.innerWidth < 900
            ? this.setState((prevState) => ({ ...prevState, isMobileApp: true }))
            : this.setState((prevState) => ({ ...prevState, isMobileApp: false }));
    };

    handleClosePopup = () => {
        this.setState({ errorMessage: "" });
        this.props.closePopup();
    };

    handleInputFocus = () => {
        this.setState({ errorMessage: "" });
    };

    handleCheckFileName = () => {
        var title: string = this.titleRef.value;

        if (title.length === 0) {
            this.setState({ errorMessage: this.blankFileName });
            return;
        }

        if (title.length < 6) {
            this.setState({ errorMessage: this.notEnoughChars });
            return;
        }

        if (title.length > 25) {
            this.setState({ errorMessage: this.tooManyChars });
            return;
        }

        var formatStylesId = parseInt(sessionStorage.getItem('formatStylesId') ?? "0");

        this.props.createMenu(formatStylesId, title).then((menu: IMyMenu) => {
            if (menu && menu.id >= 0) {
                this.handleClosePopup();
                this.props.history.push("/menu-builder");
            }
        });
    };

    render() {
        const { classes, isPopupOpen } = this.props;

        if (sessionStorage.getItem('formatStylesId') == null)
            return null;

        return (
            <Dialog open={isPopupOpen} onClose={this.handleClosePopup} fullWidth>

                <Typography className={classes.title} variant="h5">Name your design</Typography>

                <div onClick={this.handleClosePopup} className={classes.close}>
                    <CloseRounded />
                </div>

                <Grid container>
                    <Grid item xs={12}>

                        <div className={classes.text}>
                            Enter a name for your design so we can save it for you.
                        </div>

                        <InputLabel className={classes.font}>Design name *</InputLabel>

                        <TextField
                            onFocus={this.handleInputFocus}
                            InputProps={{
                                disableUnderline: true,
                                placeholder: "Enter a name for your design...",
                                classes: {
                                    input: classes.font, },
                            }}
                            variant="filled"
                            fullWidth
                            inputRef={ref => { this.titleRef = ref; }} />

                        <div className={classes.font} style={{ marginTop: -15, color: "red" }}>{this.state.errorMessage}</div>
                    </Grid>
                </Grid>

                <Grid container direction="column" justify="space-between">
                    <Grid item>
                        <Grid container spacing={2} style={{ marginTop: 20 }}>
                            <Grid item>
                                <Button size={this.state.isMobileApp ? "small" : "medium" } variant="contained" className={classes.cancel}  onClick={this.handleClosePopup}>Cancel</Button>
                            </Grid>

                            <Grid item>
                                <Button size={this.state.isMobileApp ? "small" : "medium" } variant="contained" onClick={this.handleCheckFileName}>Save & continue</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Dialog>
        );
    }
}

export default withStyles(styles)(withRouter(FileNamePopup));
import { Reducer } from "redux";
import { IGlobalState, Actions,ISession,IUser, SHOW_NAVIGATION, SHOW_ERROR, GET_USER, USER_LOADING,SET_SESSION } from "./types";

export const initialState: IGlobalState = {
    showNavigation: false,
    loading: false,
    error: undefined,
    user: undefined,
    session: undefined 
}

const reducer: Reducer<IGlobalState> = (state: IGlobalState = initialState, action): IGlobalState => {
    switch ((action as Actions).type) {

        case SHOW_NAVIGATION: {
            return {
                ...state,
                showNavigation: action.showNavigation
            }
        }

        case SHOW_ERROR: {
            return {
                ...state,
                error: action.error
            }
        }

        case USER_LOADING: {
            return {
                ...state,
                loading: action.loading
            }
        }

        case GET_USER: {
            return {
                ...state,
                user: action.user,
                loading: false
            }
        }

        case SET_SESSION: {
            return {
                ...state,
                session: action.session
            }
        }

        default:
            return state;
    }
}

export default reducer;
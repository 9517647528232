import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { userLoading, getUser, setSession } from "./actions";

const getMe = (): ThunkAction<void, null, null, Action<string>> => async (dispatch, getState) => {
    dispatch(userLoading(true))

    //document.cookie = "MenuMakerId=00000nU40JhiyuB3/BYr7WMxxOCqhy8l7VIc+r5/uA1kIlgw3naNkmDGVtLvvfTPGU/es+e8YMQp0/VRT4i9wRH2OzcFA2tZ5+SKmTJPLbIyv4/bo9d3e24d2bu50ebM7zKZ2CyMt6/+8dhyrPcAT9k3vyfSOdtJCs8weLWAIntNiETof/Km4hvIeJ/foS49J2CDr7nN20RClraJoJappkwi8MJIXlhEo1QosRykYU/dIn5qIP8tAo8+KQ3K8hjq4XOzM1iYXumkUx9Rs5GadnKeybrf9D3pQW3j9QwGrODIGXzS41HgHEyf6TBSxi9VTP0pUKRVUfqONb58Zxrh/ZQukdg==;"

    fetch('/api/customer/me')
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
        })
        .then(response => dispatch(getUser(response)));
}

const externalLogin = (): ThunkAction<void, null, null, Action<string>> => async (dispatch) => {
    fetch('/api/BritvicAuth/Login')
        .then((response) => {
            if (response.ok) {
                return response;
            }

            if (response.status === 401) {
                const loginUrl = response.headers.get('LoginUrl');
                if (loginUrl) {
                    window.location.href = loginUrl;
                }
            }
        })
        .then(() => {
            dispatch(getMe());
        });
}

const getToken = (code: string): ThunkAction<void, null, null, Action<string>> => async (dispatch) => {
    fetch('api/BritvicAuth/GetToken', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: `code=${code}`,
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error(`${response.status}: ${response.statusText}`);
            }

            return dispatch(loadSessionDetails());
        })
        .then(() => {
            window.location.replace('/'); // Replace state without query params
        });
}

const loadSessionDetails = (): ThunkAction<void, null, null, Action<string>> => async dispatch => {
    fetch('/api/customer/loadSessionDetails')
        .then((response) => {
            return response.json();
        })
        .then(response => {
            dispatch(setSession(response))
            if (response.useExternalLogin) {
                dispatch(externalLogin());
            } else {
                dispatch(getMe());
            }
        });
}

export default {
    getMe,
    loadSessionDetails,
    getToken,
};

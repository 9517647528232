import React from "react"
import { Grid, Typography, WithStyles, FormControlLabel, Checkbox } from "@material-ui/core";
import Editable from "../decorators/Editable";
import TextField from "../form/TextField";
import StyleInjector from "../decorators/StyleInjector";
import WithCustomTheme from "../../interfaces/WithCustomTheme";

interface ISimpleTitleProps {
    id: string,
    title: string,
    subTitle: string,
    placeholder?: ISimpleTitleProps,
    vertical: boolean,
    showDecorations: boolean,
    keepAppearance: boolean,
    smallTitle: boolean,
    imageTag: string
}

function Appearance(props: { onChange: any, dataMapping: any, decoration: string }) {
    const { onChange, dataMapping, decoration } = props;

    return <Grid container style={{ marginBottom: 15 }}>
        <Grid item>
            <Grid container alignItems="center">
                <Grid item>
                    <FormControlLabel name="showDecorations" onChange={onChange} checked={dataMapping["showDecorations"]} label="Show decorations" labelPlacement="end" control={<Checkbox />} />
                </Grid>

                <Grid item>
                    <span className={decoration} />
                </Grid>
            </Grid>

            <Grid container alignItems="center">
                <Grid item>
                    <FormControlLabel name="vertical" onChange={onChange} checked={dataMapping["vertical"]} label="Align Vertically" labelPlacement="end" control={<Checkbox />} />
                </Grid>
            </Grid>
        </Grid>
    </Grid>
}

const styles = ({
    root: {
        textAlign: "center"
    }
})

class SimpleTitle extends React.Component<ISimpleTitleProps & WithStyles & WithCustomTheme> {
    render() {
        const { title, subTitle, classes, id, placeholder, showDecorations, vertical, smallTitle, theme, imageTag } = this.props;

        var keepAppearance = this.props.keepAppearance;
        if (theme.simpleTitle?.keepAppearance)
            keepAppearance = theme.simpleTitle?.keepAppearance;

        const data = (decoration: string) => ({
            id,
            name: "Title",
            initialValues: {
                title,
                subTitle,
                showDecorations,
                placeholder,
                vertical,
                keepAppearance,
                smallTitle,
                imageTag
            },
            fields: [
                {
                    label: "Title text",
                    required: true,
                    render: (onChange: any, dataMapping: any) => {
                        return <TextField name="title" value={dataMapping.title} placeholder={dataMapping.placeholder?.title} onChange={onChange} />
                    }
                }, {
                    render: (onChange: any, dataMapping: any) => {
                        return <FormControlLabel 
                            style={{marginBottom: 15, marginTop: -10}}
                            name="smallTitle" 
                            onChange={onChange} 
                            checked={dataMapping["smallTitle"]} 
                            label="Use a smaller Title font" 
                            labelPlacement="end"
                            control={<Checkbox />} />
                    }
                }, {
                    label: "Subtitle text (or leave blank)",
                    render: (onChange: any, dataMapping: any) => {
                        return <TextField multiline fullWidth name="subTitle" value={dataMapping.subTitle} placeholder={dataMapping.placeholder?.subTitle} onChange={onChange} />
                    }
                }, theme?.simpleTitle?.image && {
                    label: "Image tag (or leave blank)",
                    render: (onChange: any, dataMapping: any) => {
                        return <TextField multiline fullWidth name="imageTag" value={dataMapping.imageTag} placeholder={dataMapping.placeholder?.imageTag} onChange={onChange} />
                    }                  
                },  keepAppearance && {
                    label: "Appearance",
                    render: (onChange: any, dataMapping: any) => {
                        return <Appearance decoration={decoration} onChange={onChange} dataMapping={dataMapping} />
                    }
                }                
            ]
        });

        return <Editable data={data(classes.decoration)}>
            {vertical
                ?
                <Grid container alignItems="center" wrap="wrap" direction="column" className={classes.vertical}>             
                    {showDecorations && <Grid item className={classes.decoration} style={{ marginTop: 10 }}></Grid>}

                    <Grid item style={{ maxWidth: 200, textAlign: "center" }} className={classes.root}>
                        <Typography variant={smallTitle ? "h3" : "h1"}>{title ?? placeholder?.title}</Typography>
                        <Typography variant="h2">{subTitle ?? placeholder?.subTitle}</Typography>
                    </Grid>

                    {showDecorations && <Grid item className={classes.decoration} style={{ marginBottom: 10 }}></Grid>}
                </Grid>
                :
                <Grid container justify={showDecorations ? "space-between" : "center"} alignItems="center" wrap="nowrap" className={classes.dl ? classes.dl : ""}>                
                    {showDecorations && <Grid container justify="flex-start" item xs={1}><span className={classes.decoration} style={{ marginLeft: classes.dl ? -3 : "20%", marginTop: classes.dl ? -20 : 0 }} /></Grid>}

                    <Grid container justify="center" alignItems="center" item xs={showDecorations ? 10 : theme?.simpleTitle?.image ? 9 : 12 }>
                        <div className={classes.root}>
                            <Typography variant={smallTitle ? "h3" : "h1"}>{title ?? placeholder?.title}</Typography>
                            <Typography variant="h2">{subTitle ?? placeholder?.subTitle}</Typography>
                        </div>
                    </Grid>

                    {theme?.simpleTitle?.image && <Grid container justify="flex-start" item xs className={classes.imageTag}>{imageTag ?? placeholder?.imageTag}</Grid>}
                    {theme?.simpleTitle?.image && <Grid container justify="flex-end" item xs><span className={classes.image}/></Grid>}

                    {showDecorations && <Grid container justify="flex-end" item xs={1}><span className={classes.decoration} style={{marginRight: classes.dl ? -3 : "20%", marginTop: classes.dl ? -20 : 0}}/></Grid>}
                </Grid> 
            }           
        </Editable>
    }
}

export default StyleInjector(styles, "simpleTitle", true)(SimpleTitle);
import { CircularProgress, createTheme,  CssBaseline, Link, makeStyles, ThemeProvider } from "@material-ui/core";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ICustomTheme from "../../interfaces/ICustomTheme";
import { FormatModelPartial, PlaceOrderSharedProps } from "../../pages/PlaceOrderWithFormats";
import { AppState } from "../../store";
import { IMenuItem, TemplateFormat, TemplateSize } from "../../store/menu";
import useGeneratePdf from "../../utils/useGeneratePdf";
import MenuAnimator from "../decorators/MenuAnimator";
import { PrintPalFormatOptionsForm } from "./PrintPalFormatsOptions";
import { usePrintPalFormatsPreviewStyles } from "../../styles";

/*
    TODO

    API
        availableFormatsInfo = getAvailableFormatsInfo(config.availableFormats)
            { name, width mm, height mm, price, availableInMultiplesOf }[]

        formatsOrder
            { id, quantitySelected }[]

    hooks/state/props
        formatsOrder

    methods
        onClickNextPage
        onClickPreviousPage

    layout
        div - previewMenuContainer (selectedQuantity == 0 && unavailable)
            MenuAnimator w-100 h-100
        div - previewInfoContainer
            div - paginationContainer
*/
interface IPrintPalFormatsPreview {
    placeOrderProps: PlaceOrderSharedProps
    preview?: boolean
    format?: FormatModelPartial
}

const PrintPalFormatsPreview: React.FC<IPrintPalFormatsPreview> = ({ placeOrderProps, preview, format }) => {

    const { availableFormatsData, formState } = placeOrderProps;

    const classes = usePrintPalFormatsPreviewStyles();
    const templateContainerRef = useRef<HTMLDivElement>(null);

    const { menu, myMenu } = useSelector((state: AppState) => state);
    const { theme } = menu;

    const [scaledSize, setScaledSize] = useState<TemplateSize>();
    const [temlpateScale, setTemplateScale] = useState<number>();
    const [pageSized, setPageSized] = useState<boolean>(false);
    const [currentFormat, setCurrentFormat] = useState<FormatModelPartial>();
    const [createdTheme, setCreatedTheme] = useState<ICustomTheme>();
    const availableFormats = menu.menuData?.availableFormats;

    const { exportTemplate, isExporting } = useGeneratePdf();

    const landscape = useMemo(() => {
        if (currentFormat) {
            return currentFormat.widthPx >= currentFormat.heightPx ? true : false;
        } else { return undefined; }
    }, [currentFormat]);

    const currentDesignNumber = useMemo(() => {
        if (currentFormat && availableFormats) {
            return availableFormats.findIndex((f) => f.formatKey == currentFormat.formatKey) + 1
        }
    }, [currentFormat, availableFormats]);

    const currentFormatData = useMemo(() => {
        if (currentFormat && availableFormatsData) {
            return availableFormatsData.find((f) => f.formatKey == currentFormat.formatKey);
        }
    }, [currentFormat, availableFormatsData])

    useEffect(() => {
        window.addEventListener("resize", calculateTemplateScale);

        return () => {
            window.removeEventListener("resize", calculateTemplateScale);
        }
    }, [templateContainerRef.current, landscape, currentFormat])

    useEffect(() => {
        if (theme) {
            setCreatedTheme(createTheme(theme));
        }
    }, [theme]);

    useEffect(() => {
        // Have to allow the page to render otherwise position calcs are wrong
        setTimeout(() => setPageSized(true), 50);
    }, []);

    useEffect(() => {
        if (availableFormats && availableFormats.length > 0) {
            let firstIndexWithShowInProductCarousel = availableFormats.findIndex(element => element.showInProductCarousel === true);
            if(format){
                setCurrentFormat(format);
            }else{
                setCurrentFormat(availableFormats[firstIndexWithShowInProductCarousel]);
            }
        }
    }, [availableFormats]);

    useEffect(() => {
        if (templateContainerRef.current && landscape != undefined) {
            setTimeout(() => {
                calculateTemplateScale();
            }, 100)
        }
    }, [templateContainerRef.current, landscape, currentFormat])

    const calculateTemplateScale = () => {
        if (landscape != undefined && templateContainerRef.current && currentFormat) {
            let scale = 1;
            if (landscape) {
                scale = templateContainerRef.current.clientWidth / currentFormat.widthPx;
                //Below is to ensure the scaled dimension does not exceed the opposite dimension (scaled width causes scaled height to go over clientHeight and vice versa)
                scale = currentFormat.heightPx * scale > templateContainerRef.current.clientHeight ? templateContainerRef.current.clientHeight / currentFormat.heightPx : scale;
            } else {
                scale = templateContainerRef.current.clientHeight / currentFormat.heightPx;
                scale = currentFormat.widthPx * scale > templateContainerRef.current.clientWidth ? templateContainerRef.current.clientWidth / currentFormat.widthPx : scale;
            }
            setTemplateScale(scale);
        }
    }

    useEffect(() => {
        if (temlpateScale && currentFormat) {
            setScaledSize({
                height: currentFormat.heightPx * temlpateScale,
                width: currentFormat.widthPx * temlpateScale
            });
        }
    }, [temlpateScale, currentFormat])

    const onClickLeftPage = () => {
        if (availableFormats && currentDesignNumber){
            if(availableFormats[currentDesignNumber - 2]?.showInProductCarousel){
                setCurrentFormat(availableFormats[currentDesignNumber - 2]);
            }
        }
         
    }

    const onClickRightPage = () => {
        if (availableFormats && currentDesignNumber){
            if(availableFormats[currentDesignNumber]?.showInProductCarousel){
                setCurrentFormat(availableFormats[currentDesignNumber])
            }
        }
      
    }

    const onClickDownload = () => {
        if (currentFormat)
            exportTemplate(currentFormat)
    }

    return (
        <div className={classes.previewWrapper}>
            <div ref={templateContainerRef} className={classes.previewMenuContainer}>
                {
                    createdTheme &&
                    <ThemeProvider theme={createdTheme}>
                        <CssBaseline />
                        <div style={{ position: "relative", display: `${preview ? "flex" : "block"}` }}>
                            {menu.menu?.map((page: IMenuItem, index: number) => {
                                if (temlpateScale && scaledSize && currentFormat && pageSized) {
                                    return (
                                        <MenuAnimator
                                            context="PRINT"
                                            size={scaledSize}
                                            scale={temlpateScale}
                                            key={index}
                                            theme={createdTheme}
                                            index={index}
                                            currentPage={0}
                                            data={page}
                                            formatSize={currentFormat.formatKey}
                                            previewHeight={templateContainerRef.current?.clientHeight}
                                        />
                                    )
                                }
                            })
                            }
                        </div>
                    </ThemeProvider>
                }
            </div>  
            <div className={classes.previewInfoContainer}>
                {
                    currentFormatData &&
                    <div className={classes.previewTitle}>{currentFormatData.title} {currentFormatData.width}mm x {currentFormatData.height}mm</div>
                }
                {
                    !preview ?
                    <div className={classes.paginationWrapper}>
                        <div className={classes.paginationContainer}>

                            <div className={classes.leftArrowWrapper}>
                                {
                                    (availableFormats && currentDesignNumber) &&
                                    (availableFormats[currentDesignNumber - 2] != undefined) &&
                                    <img src="../../assets/printPal/orange_arrow.svg" alt="arrow left" style={{ transform: "rotate(-90deg)", cursor: "pointer" }} onClick={onClickLeftPage} className={classes.arrow} />
                                }
                            </div>
                            <span className={classes.orangeText}>
                                <strong>Design {currentDesignNumber}</strong> of {availableFormats?.filter((row) => row?.showInProductCarousel === true)?.length}
                            </span>
                            <div className={classes.rightArrowWrapper}>
                                {
                                    (availableFormats && currentDesignNumber) &&
                                    (availableFormats[currentDesignNumber] != undefined) &&
                                    <img src="../../assets/printPal/orange_arrow.svg" alt="arrow left" style={{ transform: "rotate(90deg)", cursor: "pointer" }} onClick={onClickRightPage} className={classes.arrow} />
                                }
                            </div>
                        </div>
                    </div>
                    :
                    <div style={{height: "150px"}}></div>
                }
                {/*<div className={classes.downloadPreviewContainer}>*/}
                {/*    {*/}
                {/*        isExporting*/}
                {/*            ?*/}
                {/*            <CircularProgress />*/}
                {/*            :*/}
                {/*            <img src={`./assets/printPal/download_pdf.svg`} alt="download pdf preview" width="22px" height="26px" className={classes.imgTextContainer} />*/}
                {/*    }*/}
                {/*    <Link className={classes.link} style={{ paddingLeft: 15, verticalAlign: "middle" }} onClick={onClickDownload} download="PDFPreview">Download pdf preview</Link>*/}
                {/*</div>*/}
            </div>
        </div>
    )
}

export default PrintPalFormatsPreview;


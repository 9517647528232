import { makeStyles, TextField } from "@material-ui/core"
import React from "react"
import { CSSProperties } from "react"
import { FieldComponent, FieldEditor } from "../FloatingContentPanel"

interface TextSectionData {
    textTitle: string
    textValue: string
    textStyle: any
}

interface TextSectionConfig {

}

interface ITextEditor extends FieldEditor<TextSectionConfig, TextSectionData> {

}

export const TextEditor: React.FC<ITextEditor> = ({ config, field, onChangeField}) => {

    const onTextChange = (value: string) => {
        onChangeField(field.name, {
            ...field.data,
            textValue: value,            
        });
    }

    const classes = makeStyles({
        textEditorContainer: {

        }
    })();

    return(
        <div className={classes.textEditorContainer}>
            <TextField id={field.name} label={field.data.textTitle} defaultValue={field.data.textValue} onChange={(e) =>{ onTextChange(e.target.value); }} />
        </div>
    )
}

interface ITextSection extends FieldComponent<TextSectionData> {

}

const useStyles = makeStyles({
    textSectionContainer: {
        height: "100%",
        width: "100%"
    },
    text: {
        
    }
})

export const TextSection: React.FC<ITextSection> = ({ field }) => {

    const { textStyle, textValue } = field.data;

    const classes = useStyles();

    return(
        <div className={classes.textSectionContainer}>
            <div style={{...textStyle}} className={classes.text}>{textValue}</div>
        </div>
    )
}

import React from "react";
import { Grid, TextField, Typography, WithStyles, InputLabel, RadioGroup, Radio, FormControlLabel, Checkbox } from "@material-ui/core";
import Editable from "../decorators/Editable";
import StyleInjector from "../decorators/StyleInjector";
import PriceField from "../form/PriceField";
import ICustomTheme from "../../interfaces/ICustomTheme";
import { formatPrice } from "../../utils/common";
import EditableProducts from "../layout/EditableProducts";
import GinDropdown from "../form/GinDropdown";

interface ISimpleHeroProductProps {
    id: string,
    title: string,
    desc: string,
    price: number,
    classes: any,
    concept3: boolean,
    divider: string,
    theme: ICustomTheme,
    useGinProducts: boolean
}

const styles = ({
    root: {
        padding: "0 10px"
    },
    price: {
        fontSize: 22,
        fontWeight: "bold" as "bold"
    },
    desc: {
        fontWeight: "bold" as "bold"
    },
    title: {
        fontSize: 24,      
    },
    dashedSingleLine: {
        borderTop: "1px dashed", 
        alignContent: "center",
        paddingBottom: 0,
        marginTop: 10,
        marginRight: 15,
        marginLeft: 5       
    },
    gridContainer:{ 
        height: "100%"
    },
    gridItem:{
        display: "flex",
        flexDirection: "column"
    }
})

function Appearance(props: { onChange: any, dataMapping: any }) {
    const { onChange, dataMapping } = props;

    return <Grid container style={{ marginBottom: 30 }}>
        <Grid item xs={12}>
            <Grid container>
                <Grid item>
                    <FormControlLabel
                        name="concept3"
                        onChange={onChange}
                        checked={dataMapping["concept3"]}
                        label={<span style={{ fontSize: 14 }}>Show inline price</span>}
                        labelPlacement="end"
                        control={<Checkbox />} />
                </Grid>
            </Grid>
        </Grid>

        <React.Fragment>
            <Grid item xs={12} style={{marginTop: 15, marginBottom: 15}}>
                <span style={{ fontSize: 14 }}>Choose a dashed divider line colour:</span>
            </Grid>

            <Grid container justify="flex-start" item xs={12}>            
                <RadioGroup name="divider" defaultValue={dataMapping["dividerColor"]} value={dataMapping["divider"] !== undefined ? dataMapping["divider"] : dataMapping["defaultColor"]} aria-label="divider colours" onChange={onChange} style={{marginLeft: -10}}>
                    <Grid container style={{height: 40}}>               
                        {dataMapping.palette.map((color: string) => (  
                            <Grid item key={color}>      
                                <div style={{display: "flex"}}>
                                    <Radio value={color}/><div key={color} style={{ backgroundColor: `${color}`, height: 30, width: 30, border: "1px solid black", marginTop: 5 }}/></div>
                            </Grid>
                        ))}
                    </Grid>            
                </RadioGroup>
            </Grid>
        </React.Fragment>
    </Grid>
}

class SimpleHeroProduct extends React.Component<ISimpleHeroProductProps & WithStyles> {
    render() {
        const { classes, id, title, desc, price, concept3, divider, theme, useGinProducts } = this.props;
                
        const palette = concept3 !== undefined ? theme.simpleHeroProduct?.palette : null;
        const defaultColor = concept3 !== undefined ? theme.simpleHeroProduct?.palette[0] : null;
        const dividerColor = concept3 !== undefined ? divider ?? defaultColor : null;

        const data = {
            id,
            name: "Hero Product",
            initialValues: {
                title,
                desc,
                price, 
                divider,
                concept3,
                palette,
                dividerColor
            },
            fields: 
                useGinProducts || true
                ? 
                [
                    {
                        render: (onChange: any, dataMapping: any, state: { onSetState: any, editableState: any }) => {
                            return ( 
                                <Grid container spacing={2}> 
                                    <Grid item xs={8}>
                                        <GinDropdown onChange={(ev, value) => {
                                            let desc = value.flavourNote;
                                            let title = value.name;

                                            dataMapping["desc"] = desc;
                                            dataMapping["title"] = title;
                                            state.onSetState({
                                                editableState: state.editableState,
                                                dataMapping
                                            })
                                            console.log(onChange, dataMapping, ev, value);
                                        }} /> 
                                    </Grid>

                                    <Grid item>
                                        <InputLabel>Price *</InputLabel>
                                        <PriceField name="price" value={dataMapping["price"]} onChange={onChange} />
                                    </Grid>
                                </Grid>
                            )
                        }
                    }
                ]
                :
                [
                    {
                        render: (onChange: any, dataMapping: any) => {
                            return <Grid container spacing={2}>
                                <Grid item>
                                    <InputLabel>Product title *</InputLabel>
                                    <TextField name="title" value={dataMapping["title"]} onChange={onChange} InputProps={{ disableUnderline: true }} variant="filled" />
                                </Grid>

                                <Grid item>
                                    <InputLabel>Price *</InputLabel>
                                    <PriceField name="price" value={dataMapping["price"]} onChange={onChange} />
                                </Grid>
                            </Grid>
                        }
                    },
                    {
                        label: "Product description",
                        required: true,
                        render: (onChange: any, dataMapping: any) => {
                            return <TextField fullWidth multiline name="desc" value={dataMapping["desc"]} onChange={onChange} InputProps={{ disableUnderline: true }} variant="filled" />
                        }
                    },
                    palette && (palette?.length ?? 0) > 0 && {
                        label: "Appearance",
                        render: (onChange: any, dataMapping: any) => {
                            return <Appearance onChange={onChange} dataMapping={dataMapping} />
                        }
                    }
                ]
        };

        if (concept3)
            return <Editable data={data}>
                <div className={classes.default}>
                    <Grid container justify="flex-start">
                        <Grid item xs style={{marginLeft: 10, marginTop: 5}}>
                            <Typography variant="h2" className={classes.dl ? "" : classes.title}>{title}</Typography>
                            <Grid container justify="space-between" style={{marginTop: 10}}>
                                <Grid item xs={classes.a4 ? 8 : classes.dl ? 8 : 10}>
                                    <Grid container justify="flex-start">
                                        <p style={{marginTop: 2}}>{desc}</p>
                                    </Grid>
                                </Grid>
                                <Grid item xs={classes.a4 ? 2 : classes.dl ? 2 : 1}>
                                    <div className={classes.dashedSingleLine} style={{borderTopColor: `${divider}`}}/>
                                </Grid>
                                <Grid item xs={classes.dl ? 2 : 1}>  
                                    <Grid container justify="flex-end">     
                                        <Typography variant={classes.a4 ? "h3" : classes.dl ? "h3" : "h5"} style={{paddingRight: classes.a4 ? 10 : classes.dl ? 10 : 10 }}>£{price?.toFixed(2)}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </Editable>
        else
            if (theme?.simpleHeroProduct?.titleDescPriceOrder)
                return <Editable data={data}>
                    <div className={classes.root}>
                        <Grid container justify="space-between">
                            <Grid item xs>
                                <Typography variant="h2">{title}</Typography>
                                <Typography variant="h4">{desc}</Typography>
                                <Typography variant="h2" className={classes.large}>£{price?.toFixed(2)}</Typography>
                            </Grid>
                        </Grid>
                    </div>
                </Editable> 
            else
                return <Editable data={data}>
                    <div className={classes.default}>
                        <Grid container justify="space-between" className={classes.gridContainer}>
                            <Grid item xs className={classes.gridItem}>
                                <Typography variant="h4" className={classes.title} style={{lineHeight: 1}}>{title}</Typography>
                                {
                                    theme.simpleHeroProduct?.titleDescPriceOrderNew 
                                    ?
                                    <>
                                        <p className={classes.desc}>{desc}</p>
                                        <div className={classes.price}>
                                            {formatPrice(price, theme.simpleHeroProduct.hideCurrencySymbol, theme.simpleHeroProduct.hideTrailingZeros)}
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className={classes.price}>
                                            {formatPrice(price, theme.simpleHeroProduct?.hideCurrencySymbol, theme.simpleHeroProduct?.hideTrailingZeros)}
                                        </div>
                                        <p className={classes.desc}>{desc}</p>
                                    </>
                                }
                            </Grid>
                        </Grid>
                    </div>
                </Editable>        
    }
}

export default (StyleInjector(styles, "simpleHeroProduct", true)(SimpleHeroProduct));
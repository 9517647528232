import React from "react";
import { Grid, TextField, Typography, WithStyles } from "@material-ui/core";
import Editable from "../decorators/Editable";
import StyleInjector from "../decorators/StyleInjector";

interface ISubHeading{
    id: string,
    text: string,
    height?: number
}

const styles = ({
    menuContainer: {
        padding: "0 20px"
    },
    menuHeading: {
        fontSize: "20px",
        margin: "6px auto",
        fontFamily: "DINCONDENSEDBOLD",
        maxWidth: "220px",
        width: "100%"
    }
});

class SubHeading extends React.Component<ISubHeading & WithStyles>{
    render(){
        const {id, text, height, classes} = this.props;

        const data = {
            name: "SubHeading",
            id,
            editPanelTitle: "Sub-heading",
            initialValues: {
                text,
                height
            },
            state: {
                formData: undefined,
                error: false
            },
            fields: [
                {
                    label: "",
                    required: true,
                    render: (onChange: any, dataMapping: any) => {
                        return <TextField name="text" fullWidth value={dataMapping["text"]} placeholder={"Text"} onChange={onChange} />
                    }
                }
            ]
        }
        return(
            <Editable data={data}>
                <Grid container justifyContent="center" className={classes.menuContainer}>
                    {text && 
                    <Grid item>
                        <Typography variant="h3" className={classes?.menuHeading}>{text}</Typography>
                    </Grid>}
                </Grid>
            </Editable>
        )
    }
}
export default StyleInjector(styles, "text", true)(SubHeading);
import "core-js/stable";
import "whatwg-fetch";
import "regenerator-runtime/runtime";

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider } from "@material-ui/core";
import { Provider } from "react-redux";
import Store from "./utils/store";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from "react-dnd-html5-backend";
import { BrowserRouter, Router } from "react-router-dom";
import { persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";

const theme = createTheme({
    typography: {
        h1: {
            fontSize: 40
        },
        h2: {
            fontSize: 28
        },
        h3: {
            fontSize: 18
        }
    },
    palette: {
        secondary: {
            main: "#007D7A"
        }
    },
    overrides: {
        MuiContainer: {
            root: {
                maxWidth: 1478
            }
        },
        MuiButton: {
            root: {
                color: "#007D7A",
                borderRadius: 30
            },
            contained: {
                textTransform: "none",
                backgroundColor: "#007D7A",
                color: "#FFF",
                boxShadow: "none",
                padding: "8px 35px",
                "&:hover": {
                    backgroundColor: "#FFF",
                    color: "#007D7A",
                    boxShadow: "none",
                    border: "1px solid #007D7A"
                }
            },
            containedSecondary: {
                "&:hover": {
                    color: "#FFF"
                }
            },
            text: {
                textTransform: "none",
                fontSize: 15,
                padding: 0,
                "&:hover": {
                    backgroundColor: "transparent"
                }
            },
            outlined: {
                border: "1px solid #007D7A",
                background: "#FFF",
                textTransform: "none",
                fontSize: 15,
                transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 20ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                "&:hover": {
                    border: "2px solid #007D7A",
                    background: "#FFF",
                    boxShadow: "none"
                }
            },
            containedSizeLarge: {
                width: 163,
                height: 50,
                fontSize: 15
            },
            outlinedSizeLarge: {
                width: 163,
                height: 50,
                fontSize: 15
            },
            iconSizeLarge: {
                "& > *:first-child": {
                    fontSize: 35
                }
            }
        },
        MuiDialog: {
            paper: {
                backgroundColor: "#F8EFED",
                overflowY: "visible",
                padding: 30,
                borderRadius: 0
            },
            paperWidthSm: {
                width: 770,
                maxWidth: 770
            }
        },
        MuiFormControl: {
            root: {
                marginBottom: 25
            }
        },
        MuiFilledInput: {
            root: {
                backgroundColor: "#FFF",
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0
            },
            input: {
                padding: 15
            },
            multiline: {
                padding: "12px 12px 10px"
            },
            inputHiddenLabel: {
                paddingTop: 15,
                paddingBottom: 15
            },
            underline: {
                "&:after": {
                    display: "none"
                }
            }
        },
        MuiInputBase: {
            root: {
                "&$error": {
                    border: "1px solid red"
                }
            }
        },
        MuiInputLabel: {
            root: {
                color: "#696969",
                fontSize: 16,
                marginBottom: 12
            }
        },
        MuiCircularProgress: {
            colorPrimary: {
                color: "#007D7A"
            }
        },
        MuiAutocomplete: {
            inputRoot: {
                paddingTop: "0 !important",
                height: 50,
                "&:before, &:hover:before": {
                    borderBottom: 0
                }
            }
        },
        MuiTooltip: {
            tooltip: {
                fontSize: 14,
                color: "#FFF",
                border: "2px solid #007D7A",
                backgroundColor: "#007D7A"
            },
            arrow: {
                color: "#007D7A"
            }
        }
    } as any
});

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <Provider store={Store}>
                    <DndProvider backend={HTML5Backend}>
                        <BrowserRouter>
                            <App />
                        </BrowserRouter>
                    </DndProvider>
            </Provider>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

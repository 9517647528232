﻿import { createStore, combineReducers, applyMiddleware, Action, MiddlewareAPI } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { menuReducer, ADD_HISTORY, UNDO, REDO, HistoryAction, Actions, IHistory } from "./menu";
import { myMenuReducer } from "./myMenu";
import { printPalReducer } from "./printpal"
import { globalReducer } from "./global"
import storage from "redux-persist/es/storage/session";
import { persistReducer, persistStore } from "redux-persist";

const rootReducer = combineReducers({
    menu: menuReducer,
    myMenu: myMenuReducer,
    global: globalReducer,
    printPal: printPalReducer
});

const persistedReducer = persistReducer({
    key: "primary",
    storage,
    whitelist: ["menu", "printPal"],
    keyPrefix: ""
}, rootReducer);

export type AppState = ReturnType<typeof rootReducer>;

const isHistoryAction = (action: Actions): action is HistoryAction => {
    return (action as HistoryAction).undo !== undefined;
}

const lastHistory = (history: IHistory[], redo: boolean) => {
    var notUndone = history.filter(a => a.undone === redo);
    return redo ? notUndone[0] : notUndone[notUndone.length - 1];
}

const historyMiddleware = (storeAPI: MiddlewareAPI) => (next: any) => (action: Action) => {
    let historyAction = isHistoryAction(action);

    //For anything coming in lets add it to the history
    if ([ADD_HISTORY, UNDO, REDO].indexOf(action.type) === -1 && historyAction && !(action as HistoryAction).dontAdd) {

        //Store the index of the history item that's to be
        let store = storeAPI.getState() as AppState;
        (action as HistoryAction).historyIndex = store.menu.history.length;

        storeAPI.dispatch({
            type: ADD_HISTORY,
            action
        });
    }

    if (action.type === UNDO) {
        let store = storeAPI.getState() as AppState;
        let lastItem = lastHistory(store.menu.history, false);

        if (lastItem && isHistoryAction(lastItem.action)) {
            //Don't add to the history
            lastItem.action.dontAdd = true;
            storeAPI.dispatch(lastItem.action.undo);
        }
    }

    if (action.type === REDO) {
        let store = storeAPI.getState() as AppState;
        let lastItem = lastHistory(store.menu.history, true);

        if (lastItem && isHistoryAction(lastItem.action)) {
            //Don't add to the history
            lastItem.action.dontAdd = true;
            storeAPI.dispatch(lastItem.action);
        }
    }

    next(action);
}

export default function configureStore() {
    const middlewares = [thunkMiddleware, historyMiddleware];
    const middleWareEnhancer = applyMiddleware(...middlewares);
    const store = createStore(
        rootReducer,
        {},
        composeWithDevTools(middleWareEnhancer)
    );

    return store;
}

export const persistor = persistStore(configureStore());
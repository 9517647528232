import React, { Component, ChangeEvent, createContext } from "react"
import { Button, Grid, InputLabel, Theme, Dialog, Typography, WithStyles, withStyles, CircularProgress } from "@material-ui/core";
import { DeleteOutlined } from "@material-ui/icons"
import { IMenuState, IModalData, IModalField } from "../../store/menu/types"
import { menuActions } from "../../store/menu";
import { connect } from "react-redux";
import DialogClose from "../dialogs/DialogClose";
import { AppState } from "../../store";
import { myMenuOperations } from "../../store/myMenu";
import { ZodError, ZodType } from "zod";

const styles = (theme: Theme) => ({
    title: {
        marginBottom: 25,
        fontWeight: "bold" as "bold",
        fontSize: 20,
    },
    remove: {
        color: "#DE3E29",
        border: "1px solid #DE3E29",
        padding: "5px 10px",
        background: "#FFF",
        width: 180,
        "&:hover": {
            border: "1px solid #DE3E29",
            background: "#DE3E29",
            color: "#FFF",
        },
        "& .MuiButton-startIcon": {
            marginRight: 0
        },
        "& svg": {
            width: "auto",
            height: 40
        }
    },
    fieldWrapper: {
        padding: "0 20px",
        margin: "0 -20px",
        width: "calc(100% + 20px)",
        overflow: "hidden" as "hidden",
        overflowY: "auto" as "auto",
        //maxHeight:"400px"
    },
    buttons: {
        marginTop: 30
    }
});

interface IModalProps {
    open: boolean,
    onClose: any,
    data?: IModalData,
    removeSection: any,
    editSection: any,
    saveMenu: (id: number) => void
    menu: IMenuState
}

interface IModalState {
    dataMapping: { [index: string]: any },
    lastId: string,
    editableState: any,
    saving: boolean
    modalEvents: ModalEvent[]
    validations: ValidationObject[],
    valid: boolean
}

export interface ModalRenderState<T = any> {
    editableState: T
    onSetState: (state: Partial<IModalState>) => void
}

interface ModalEvent {
    id: string,
    type: EditableModalEventType
    listener: () => void
}

type EditableModalEventType = "save";

type EditableModalEvent = (event: EditableModalEventType, key: string, listener: () => void) => void

type AddValidationFunction = (validation: ValidationObject) => void;

type ValidationObject = {
    id: string
    schema: ZodType
    touched?: boolean
    currentValue: any
    onInvalid: (message: ZodError) => void
    onValid: () => void
}

interface IEditableModalContext {
    upsertEvent: EditableModalEvent
    upsertValidation: AddValidationFunction,
    setApplyValid: (valid: boolean) => void
}

export const EditableModalContext = createContext<IEditableModalContext>({
    upsertEvent: () => "",
    upsertValidation: () => "",
    setApplyValid: () => {}
})

class EditableModal extends Component<IModalProps & WithStyles<typeof styles>, IModalState> {
    constructor(props: IModalProps & WithStyles) {
        super(props);

        this.state = {
            dataMapping: {},
            lastId: "",
            editableState: {},
            saving: false,
            modalEvents: [],
            validations: [],
            valid: true
        }
    }

    componentDidUpdate(prevProps: IModalProps & WithStyles<typeof styles>, prevState: IModalState) {
        //This modal exists for everything so the state isn't updated magically so we do it here when the id changes
        //console.log("Component updated", this.state.lastId !== this.props.data?.id, this.state.lastId, this.props.data?.id, this.props.data?.initialValues.title);
        if (this.state.lastId !== this.props.data?.id && this.props.data?.initialValues) {
            this.setState({
                dataMapping: JSON.parse(JSON.stringify(this.props.data?.initialValues)),
                lastId: this.props.data.id,
                editableState: this.props.data?.state ?? {}
            });
        }
        if(prevProps.data?.initialValues !== this.props.data?.initialValues){
            this.setState({
                dataMapping: JSON.parse(JSON.stringify(this.props.data?.initialValues))
            });
        }        

        if(prevState.validations != this.state.validations){
            let valid = this.validate(true);
            this.setState({
                valid
            });
        }
    }

    onSave = () => {
        this.setState({ saving: true });

        let valid = this.validate();
        this.setState({
            valid
        })
        if(!valid) {
            this.setState({ saving: false})
            return;
        }        

        //This section handles the onSaved event so call it and see what the result is
        let saveEvents = this.state.modalEvents.filter((ev) => ev.type == "save");
        saveEvents.forEach(ev => {
            ev.listener();
        });

        if (this.props.data?.onSaved) {
            this.props.data?.onSaved(this.state.dataMapping, { editableState: this.state.editableState, onSetState: this.onSetState }).then(() => {
                this.props.editSection(this.props.data?.id, JSON.parse(JSON.stringify(this.state.dataMapping)));
                this.props.onClose();
                this.setState({ saving: false });
            }).catch(() => {
                this.setState({ saving: false });
            });
        } else {
            this.props.editSection(this.props.data?.id, JSON.parse(JSON.stringify(this.state.dataMapping)));
            this.props.onClose();
            this.setState({ saving: false });
        }

        if(this.props.menu.menuData?.id)
            this.props.saveMenu(this.props.menu.menuData?.id);
        else{
            console.log("Unable to save, missing menuData.id");
        }
    }

    onRemove = () => {
        this.props.removeSection(this.props.data?.id);
        this.props.onClose();
    }

    onSetState = (state: any) => {
        this.setState(state);
    }

    onAddEvent: EditableModalEvent = (type, key, listener) => {
        let events = JSON.parse(JSON.stringify(this.state.modalEvents)) as ModalEvent[];
        let eventIndex = events.findIndex((e) => e.id == key);
        let event = {
            type, 
            listener,
            id: key
        };
        if(eventIndex > -1){
            events[eventIndex] = event;
        } else {
            events = [...events, event]
        }

        this.setState({
            modalEvents: events
        });
    }

    onUpsertValidation: AddValidationFunction = (validation) => {
        
        let validations = JSON.parse(JSON.stringify(this.state.validations)) as ValidationObject[];
        let validationIndex = validations.findIndex((v) => v.id == validation.id);
        if(validationIndex > -1){
            validations[validationIndex] = validation;
        } else {
            validations = [...validations, validation];
        }

        //console.log("upserting validation", validation);
        this.setState({
            validations
        })
    }

    validate = (touchedOnly: boolean = false) => {
        //console.log(this.state.validations);
        let valid = this.state.valid;
        let validCount = 0;
        console.log(touchedOnly, this.state.validations);
        this.state.validations.forEach((validation) => {
            if((touchedOnly && validation.touched) || !touchedOnly){
                try{
                    validation.schema.parse(validation.currentValue);
                    validation.onValid();
                    validCount++;
                } catch(err: any){
                    validation.onInvalid(err);
                }
            }
        })

        if(this.state.validations.length > 0){
            return validCount == this.state.validations.length;
        } else {
            return valid;
        }
    }

    render() {
        
        const { classes, open, onClose, data, menu } = this.props;
        const { menuData, isMenu } = menu;
        const { dataMapping, editableState, saving } = this.state;
        let showRemove = (menuData?.simplified == false);

        const hideAllElse = (menuData?.showStyleSelectorOnly == true);
        if (hideAllElse) showRemove = false;

        //There should always be data to show
        return data ? <Dialog open={open} onClose={onClose} fullWidth>
            <EditableModalContext.Provider value={{
                upsertEvent: this.onAddEvent,
                upsertValidation: this.onUpsertValidation,
                setApplyValid: (valid) => { this.setState({ valid })}
            }}>
                <Typography className={classes.title} variant="h5">
                    {
                        data.editPanelTitle 
                        ?
                        data.editPanelTitle
                        :
                            <>Edit '<i>{data.name}</i>' section</>
                    }
                </Typography>
                {data.editPanelSubtitle && <span style={{ marginTop: -20, fontWeight: "bold", marginBottom: 20 }}>{data.editPanelSubtitle}</span>}
                {(data?.showMandatory ?? true) && <span style={{ marginTop: -20, marginBottom: 20 }}>(* Mandatory field)</span>}
                <DialogClose onClose={onClose} />
                {data.fields && <Grid container className={classes.fieldWrapper}>
                    {data.fields.filter(f => f).map(

                        (field: IModalField, index: number) =>
                        {
                        //Render component, passing change handler and current state
                        var FieldComponent = field.render((ev: ChangeEvent) => {
                            //console.log("rendering field component", ev);
                            //Update state with new info
                            var input = (ev.target as HTMLInputElement);
                            var parts = input.name.split(".");
                            var obj = parts.reduce((prev: any, curr: string, index: number) => {
                                if (index === parts.length - 1)
                                    return prev;
                                else
                                    return prev[curr];
                            }, dataMapping);

                            var [last] = parts.splice(parts.length - 1, 1);

                            //We're updating a value in an array
                            if (last.indexOf("[") !== -1) {
                                var arr = last.replace("]", "").split("[");
                                obj = obj[arr[0]];
                                last = arr[1];
                            }

                            if (input.type === "checkbox")
                                obj[last] = input.checked;
                            else if (input.type === "number")
                                obj[last] = parseFloat(input.value);
                            else if (["false", "true"].indexOf(input.value) !== -1)
                                obj[last] = input.value === "true";
                            else
                                obj[last] = input.value as any;

                            this.setState({ dataMapping });
                        }, dataMapping, { editableState, onSetState: this.onSetState });

                        return <Grid key={index} item xs={12}>
                            {field.label && <InputLabel>{field.label}{field.required && " *"}</InputLabel>}
                            {FieldComponent}
                        </Grid>
                    })}
                </Grid>}

                <Grid container justify="space-between" className={classes.buttons}>
                    <Grid item>
                        <Grid container spacing={2}>
                            <Grid item>
                                <Button size="large" variant="outlined" onClick={onClose}>Cancel</Button>
                            </Grid>

                            <Grid item>
                                <Button disabled={saving} startIcon={saving ? <CircularProgress style={{ marginRight: "-8px" }} /> : undefined} size="large" variant="contained" onClick={this.onSave}>{saving ? "" : "Apply"}</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    {
                        isMenu &&
                        <Grid item>
                            {showRemove && <Button className={classes.remove} size="large" onClick={this.onRemove} startIcon={<DeleteOutlined />} variant="contained">Remove section</Button>}
                        </Grid>
                    }
                </Grid>
            </EditableModalContext.Provider>
        </Dialog> : null
    }
}

const stateToProps = (state: AppState) => {
    return {
        menu: state.menu
    }
}

const dispatchToProps = (dispatch: any) => {
    return {
        removeSection: (id: string) => dispatch(menuActions.removeSection(id)),
        editSection: (id: string, data: object) => dispatch(menuActions.editSection(id, data)),
        saveMenu: (id: number) => dispatch(myMenuOperations.saveMenu(id)),
    }
};

export default connect(stateToProps, dispatchToProps)(withStyles(styles)(EditableModal));
import React from "react";

/**
 * (2) and (3) wrap underneath (1) then (3) wraps underneath (2).
 * @param props
 */
function TripleStack(props: TripleStackProps) {

    if (props.children.length !== 3)
        throw "Triple stack component requires exactly 3 children";

    return <div style={{display: "flex", flexWrap: "wrap"}}>

        <div style={{flexGrow: 1}}>
            {props.children[0]}
        </div>


        <div style={{flexGrow: 9, display: "flex", flexWrap: "wrap"}}>

            <div style={{flexGrow: 1}}>{props.children[1]}</div>

            <div style={{ flexGrow: 1}}>{props.children[2]}</div>


        </div>
    </div>
}

interface TripleStackProps {
    children: JSX.Element[]
}

export default TripleStack;
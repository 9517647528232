import { makeStyles } from '@material-ui/core';
import React from 'react';

export default function CategorySelector(props: { categories: string[], selectedCategories: string[], onSelectionChange: (category: string, selected: boolean) => void }) {

    const useStyles = makeStyles({

        categoryContainer: {
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
        },
        selectedCategory: {
            background: "#007d7a !important",
            border: "1px solid #007d7a",
            color: "white",
            padding: "0 0.5rem 0.1rem",
            fontWeight: 700
        },
        unselectedCategory: {
            padding: "0 0.5rem 0.1rem"
        },
        categorySelector: {
            cursor: "pointer",
            userSelect: "none",
            width: "18%",
            margin: "1%",
            height: "35px",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            flexDirection: "column",
            border: "1px solid #777",
            textAlign: "center",
            background: "#efefef",
            lineHeight: "1.2em"
        }
    });

    const classes = useStyles();

    const CategorySelector = (name: string, isSelected: boolean, onClick: () => void) => {
        return <span onClick={onClick} className={(classes.categorySelector) + " " + (isSelected ? classes.selectedCategory : classes.unselectedCategory)}><span >{name}</span></span>
    }

    return <div className={classes.categoryContainer}>
        {props.categories
            .map(category => CategorySelector(
                category,
                props.selectedCategories.includes(category),
                () => {
                    const index = props.selectedCategories.indexOf(category);

                    props.onSelectionChange(category, index == -1);
                })
            )}
    </div>
}
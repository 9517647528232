import { InputLabel, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IGinProduct } from "../../interfaces/IGinProduct";
import { menuOperations } from "../../store/menu";


interface IGinDropdown {
    onChange: (ev: any, value: any) => void,
    filter?: string
}

const GinDropdown: React.FC<IGinDropdown> = ({ onChange,filter }) => {

    const dispatch = useDispatch();
    const menu = useSelector<any, any>(state => state.menu);

    useEffect(() => {
        //debugger;
        dispatch(menuOperations.getGinProducts(filter))
    }, []);

    return(
        <>
            <InputLabel>Add a new Product</InputLabel>

            <Autocomplete
                fullWidth
                options={menu.gins ?? []}
                getOptionLabel={(option: IGinProduct) => option.name}
                renderInput={(params) => <TextField {...params} placeholder="Start typing the product name..." variant="filled" />}
                onChange={onChange}
            />
        </>
    )
}

export default GinDropdown;
import React from "react";
import { Grid, TextField, Typography, WithStyles, InputLabel } from "@material-ui/core";
import Editable from "../decorators/Editable";
import StyleInjector from "../decorators/StyleInjector";
import PriceField from "../form/PriceField";
import ImageUpload from "../form/ImageUpload";
import { menuOperations } from "../../store/menu";
import { connect } from "react-redux";
import ILibraryImage from "../../interfaces/ILibraryImage";
import EditableProducts from "../layout/EditableProducts";

interface IHeroProductProps {
    id: string,
    title: string,
    desc: string,
    price: number,
    image: string,
    classes: any,
    uploadFile: any,
    useGinProducts: boolean
}

const styles = ({
    root: {
        padding: "0 10px"
    },
    price: {
        fontSize: 18,
        fontWeight: "bold" as "bold"
    },
    image: {
        "& img": {
            position: "relative" as "relative"
        }
    }
})

class HeroProduct extends React.Component<IHeroProductProps & WithStyles> {
    render() {
        const { classes, id, title, desc, price, image, uploadFile, useGinProducts } = this.props;

        const data = {
            id,
            name: "Hero Product",
            initialValues: {
                title,
                desc,
                price,
                image
            },
            state: {
                formData: undefined,
                error: false
            },
            fields: [
                {
                    render: (onChange: any, dataMapping: any) => {
                        return <Grid container spacing={2}>
                            <Grid item>
                                <InputLabel>Product title *</InputLabel>
                                <TextField name="title" value={dataMapping["title"]} onChange={onChange} InputProps={{ disableUnderline: true }} variant="filled" />
                            </Grid>

                            <Grid item>
                                <InputLabel>Price *</InputLabel>
                                <PriceField name="price" value={dataMapping["price"]} onChange={onChange} />
                            </Grid>
                        </Grid>
                    }
                },
                {
                    label: "Product description",
                    required: true,
                    render: (onChange: any, dataMapping: any) => {
                        return <TextField fullWidth multiline rows={6} rowsMax={6} name="desc" value={dataMapping["desc"]} onChange={onChange} InputProps={{ disableUnderline: true }} inputProps={{ maxLength: 400 }} variant="filled" />
                    }
                },
                {
                    label: "Image",
                    render: (onChange: any, dataMapping: any, state: { onSetState: any, editableState: any }) => {
                        return <React.Fragment>
                            {state.editableState.error && <Typography variant="body2" gutterBottom color="error">Image upload failed, please make sure your image is under 5mb and is a jpg or png</Typography>}

                            <ImageUpload
                                dataMapping={dataMapping}
                                fileUploaded={(formData: FormData) => {
                                    state.onSetState({
                                        editableState: { ...state.editableState, formData, error: false }
                                    });
                                }}
                                imageSelected={(image: ILibraryImage) => {
                                    state.onSetState({
                                        dataMapping: { ...dataMapping, image: image.filePath },
                                        editableState: { ...state.editableState, formData: null, error: false }
                                    });
                                }}/>
                        </React.Fragment>;
                    }
                }
            ],
            onSaved: (dataMapping: any, state: any) => {
                return new Promise<void>((resolve: any, reject: any) => {
                    if (!state.editableState.formData) {
                        resolve();
                        return;
                    }

                    //On save upload the file to the server
                    uploadFile(state.editableState.formData).then((file: any) => {
                        var url = window.location.origin + "/api/Files/" + file.fileId;
                        dataMapping.image = url;
                        resolve();
                        return false;
                    }).catch(() => {
                        reject();
                        return true;
                    }).then((error: boolean) => {
                        //Reset form data
                        state.onSetState({
                            editableState: { ...state.editableState, formData: undefined, error }
                        });
                    });
                });
            }
        };

        return <Editable data={data}>
            <div className={classes.root}>
                <Grid container className={classes.wrapper} justify="space-between" alignItems="center">
                    <Grid item xs className={classes.image} style={{ backgroundImage: `url(${image})` }}>
                    </Grid>

                    <Grid item xs={6} className="desc">
                        <Typography variant="h3">{title}</Typography>
                        <div style={{ clear: "both" }}></div>
                        <Typography component="p">{desc}</Typography>
                        <span className={classes.overridePrice ? classes.overridePrice : classes.price}>£{price.toFixed(2)}</span>
                    </Grid>
                </Grid>
            </div>
        </Editable>
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        uploadFile: (file: any) => dispatch(menuOperations.uploadFile(file))
    }
}

export default connect(null, mapDispatchToProps)(StyleInjector(styles, "heroProduct")(HeroProduct));
import { Grid, Typography, WithStyles, Checkbox, FormControlLabel } from "@material-ui/core";
import React from "react";
import Editable from "../decorators/Editable";
import StyleInjector from "../decorators/StyleInjector";
import TextField from "../form/TextField";
import ImageUpload from "../form/ImageUpload";
import { menuOperations } from "../../store/menu";
import { connect } from "react-redux";
import ILibraryImage from "../../interfaces/ILibraryImage";

interface IDecoratedCoverProps {
    id: string,
    title: string,
    backgroundImage: string,
    logoImage: string,
    bottomText?: string,
    bottomNumber?: string,
    footer?: string,
    showImage?: boolean,
    uploadFile: any
}

const styles = ({
    noImage: {
        height: 1040,
    },
    showImage: {
        height: 520
    } 
});

function DecoratedCover(props: IDecoratedCoverProps & WithStyles) {
    const { id, logoImage, backgroundImage, title, bottomText, bottomNumber, footer, classes, uploadFile, showImage } = props;

    const data = {
        id,
        name: "Cover page",
        initialValues: {
            logoImage,
            backgroundImage,
            title,
            bottomText,
            bottomNumber,
            footer,
            showImage
        },
        state: {
            formData: undefined,
            logoFormData: undefined,
            error: false,
            logoError: false
        },
        fields: [
            {
                label: "Title text",
                render: (onChange: any, dataMapping: any) => {
                    return <TextField name="title" value={dataMapping["title"]} onChange={onChange} />
                }
            },
            {
                label: "Bottom text",
                render: (onChange: any, dataMapping: any) => {
                    return <TextField fullWidth multiline rows={4} name="bottomText" value={dataMapping["bottomText"]} onChange={onChange} />
                }
            },
            {
                label: "Bottom phone number",
                render: (onChange: any, dataMapping: any) => {
                    return <TextField fullWidth name="bottomNumber" value={dataMapping["bottomNumber"]} onChange={onChange} />
                }
            },
            {
                label: "Footer text",
                render: (onChange: any, dataMapping: any) => {
                    return <TextField fullWidth name="footer" value={dataMapping["footer"]} onChange={onChange} />
                }
            },
            {
                render: (onChange: any, dataMapping: any) => {
                    return <FormControlLabel 
                        style={{marginBottom: 15, marginTop: -10}}
                        name="showImage" 
                        onChange={onChange} 
                        checked={dataMapping["showImage"]} 
                        label="Show Image" 
                        labelPlacement="end"
                        control={<Checkbox />} />
                }
            },
            {
                label: "Image",
                render: (onChange: any, dataMapping: any, state: { onSetState: any, editableState: any }) => {
                    return <React.Fragment>
                        {state.editableState.error && <Typography variant="body2" gutterBottom color="error">Image upload failed, please make sure your image is under 5mb and is a jpg or png</Typography>}

                        <ImageUpload
                            imageKey="backgroundImage"
                            dataMapping={dataMapping}
                            fileUploaded={(formData: FormData) => {
                                state.onSetState({
                                    editableState: { ...state.editableState, formData, error: false }
                                });
                            }}
                            imageSelected={(image: ILibraryImage) => {
                                state.onSetState({
                                    dataMapping: { ...dataMapping, backgroundImage: image.filePath },
                                    editableState: { ...state.editableState, formData: null, error: false }
                                });
                            }} />
                    </React.Fragment>
                }
            },
            {
                label: "Logo",
                render: (onChange: any, dataMapping: any, state: { onSetState: any, editableState: any }) => {
                    return <React.Fragment>
                        {state.editableState.logoError && <Typography variant="body2" gutterBottom color="error">Image upload failed, please make sure your image is under 5mb and is a jpg or png</Typography>}

                        <ImageUpload
                            name="logo"
                            imageKey="logoImage"
                            dataMapping={dataMapping}
                            fileUploaded={(formData: FormData) => {
                                state.onSetState({
                                    editableState: { ...state.editableState, logoFormData: formData, logoError: false }
                                });
                            }}
                            imageSelected={(image: ILibraryImage) => {
                                state.onSetState({
                                    dataMapping: { ...dataMapping, logoImage: image.filePath },
                                    editableState: { ...state.editableState, logoFormData: null, logoError: false }
                                });
                            }} />
                    </React.Fragment>
                }
            }
        ],
        onSaved: (dataMapping: any, state: any) => {
            return new Promise<void>((resolve: any, reject: any) => {
                if (!state.editableState.formData) {
                    resolve();
                    return;
                }

                //On save upload the file to the server
                uploadFile(state.editableState.formData).then((file: any) => {
                    let url = window.location.origin + "/api/Files/" + file.fileId;
                    dataMapping.backgroundImage = url;
                    resolve();
                }).catch(() => {
                    console.error("File upload error");
                    reject();
                });

                //Reset form data
                state.onSetState({
                    editableState: { ...state.editableState, formData: undefined }
                });
            }).then(() => {
                return new Promise<void>((resolve: any, reject: any) => {
                    if (!state.editableState.logoFormData) {
                        resolve();
                        return;
                    }

                    //On save upload the file to the server
                    uploadFile(state.editableState.logoFormData).then((file: any) => {
                        let url = window.location.origin + "/api/Files/" + file.fileId;
                        dataMapping.logoImage = url;
                        resolve();
                    }).catch(() => {
                        console.error("File upload error");
                        reject();
                    });

                    //Reset form data
                    state.onSetState({
                        editableState: { ...state.editableState, logoFormData: undefined }
                    });
                });
            });
        }
    };

    const showTopCornerDecorations = (classes: any) => {
        return (
            <Grid container className={classes.topCornerContainer}>
                <Grid container justify="flex-start" className={classes.topCornerLeftContainer}>
                    <div className={classes.decoration}>
                        <div className={`${classes.vertical} ${classes.borderCover} ${classes.borderCoverLeft}`}/>
                        <div className={`${classes.horizontal} ${classes.borderCover} ${classes.borderCoverTop}`}/>                            
                        <span className={classes.dot}/>
                    </div>
                </Grid>
                <Grid container justify="flex-start" className={classes.topCornerRightContainer}>
                    <div className={classes.decoration}>
                        <div className={`${classes.vertical} ${classes.borderCover} ${classes.borderCoverRight}`}/>
                        <div className={`${classes.horizontal} ${classes.borderCover} ${classes.borderCoverTop}`}/>                            
                        <span className={`${classes.dot} ${classes.topRightDot}`}/>
                    </div>
                </Grid>
            </Grid>
        )
    }
    
    const showBottomCornerDecorations = (classes: any) => {
        return (
            <Grid container className={classes.bottomCornerContainer}>
                <Grid container justify="flex-start" className={classes.bottomCornerLeftContainer}>
                    <div className={classes.decoration}>
                        <div className={`${classes.vertical} ${classes.borderCover} ${classes.borderCoverLeft}`}/>
                        <div className={`${classes.horizontal} ${classes.borderCover} ${classes.borderCoverBottom}`}/>                          
                        <span className={`${classes.dot} ${classes.bottomLeftDot}`}/>
                    </div>
                </Grid>
                <Grid container justify="flex-start" className={classes.bottomCornerRightContainer}>
                    <div className={classes.decoration}>
                        <div className={`${classes.vertical} ${classes.borderCover} ${classes.borderCoverRight}`}/>   
                        <div className={`${classes.horizontal} ${classes.borderCover} ${classes.borderCoverBottom}`}/>                            
                        <span className={`${classes.dot} ${classes.bottomLeftDot} ${classes.topRightDot}`}/>
                    </div>
                </Grid>
            </Grid> 
        )   
    }    

    return <Editable data={data}>
        <div style={{marginTop: 10}}>{showTopCornerDecorations(classes)}</div>

        <Grid container className={showImage ? `${classes.showImage} ${classes.root}` : `${classes.noImage} ${classes.root}`} alignItems="center" direction="column">
            <Grid item className={classes.image}>
                {logoImage ? <img alt="logo" src={logoImage} className={classes.sizedLogo}/> : <span className={showImage ? `${classes.circle} ${classes.bg} ${classes.fg}`: `${classes.rectangle} ${classes.bg} ${classes.fg}`}>Logo<br/>here</span>}
            </Grid>

            <Grid item>
                <Typography variant="h1" className={classes.title}>{title}</Typography>
            </Grid>

            <Grid item className={classes.bottom}style={{ marginTop: showImage ? 20 : 520}}>
                <Typography variant="h3" className={classes.bottomText}>{bottomText}</Typography>
                <Typography variant="h2" className={classes.bottomNumber}>{bottomNumber}</Typography>
            </Grid>
        </Grid>

        <div>
            {footer && footer.length > 0 ? <div className={classes.footerContainer}>
                <Typography variant="h2" className={classes.footer}>{footer}</Typography>
            </div> : null}              
            {showBottomCornerDecorations(classes)}
        </div> 

        {showImage && <Grid container justify="center" alignItems="center">
            <Grid item>
                {backgroundImage 
                    ? 
                    <img alt="menu cover" src={backgroundImage} className={`${classes.default} ${classes.sized}`}/> 
                    : 
                    <span className={`${classes.placeholder} ${classes.bg} ${classes.fg}`}>Image<br/>here</span>
                }
            </Grid>
        </Grid>}
    </Editable>;
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        uploadFile: (file: any) => dispatch(menuOperations.uploadFile(file))
    }
}

export default connect(null, mapDispatchToProps)(StyleInjector(styles, "decoratedCover")(DecoratedCover));